var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.getInitialApiService=exports.getDefaultApiServices=exports.ASYNC_STORAGE_KEY_SERVICE=void 0;var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));var _initApp=require("@24i/nxg-sdk-smartott/src/Application/initApp");




var _renative=require("renative");function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}

var transformServiceData=function transformServiceData(serviceData){
var appId;
if(typeof serviceData.appId==='string'){
appId=serviceData.appId;
}else{

appId=serviceData.appId[_renative.Api.platform]||serviceData.appId.default;
}

return _objectSpread(_objectSpread({},
serviceData),{},{
appId:appId});

};

var getInitialApiService=function getInitialApiService(){return(
transformServiceData((0,_initApp.getRuntimeConfig)('apiService')));};exports.getInitialApiService=getInitialApiService;

var getDefaultApiServices=function getDefaultApiServices(){
var defaultServices=(0,_initApp.getRuntimeConfig)('defaultApiServices');
var transformedServices=defaultServices.map(function(defaultService){return(
transformServiceData(defaultService));}
);
var services=transformedServices.concat(getInitialApiService());
return services;
};exports.getDefaultApiServices=getDefaultApiServices;

var ASYNC_STORAGE_KEY_SERVICE='service';exports.ASYNC_STORAGE_KEY_SERVICE=ASYNC_STORAGE_KEY_SERVICE;