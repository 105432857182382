import React, { FC, useCallback, useState } from 'react';
import { isPlatformWeb } from 'renative';
import { SvgUri } from 'react-native-svg';
import { View, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { ThemeIconName } from '@24i/nxg-sdk-photon/src';

import { ProjectLocalIcon } from '../ProjectLocalIcon';
import { FallbackIcon } from '../FallbackIcon';
import { extractIconPath, getIconStyles, isURL } from '../utils';
import { BaseIconProps, IconVariant } from '../types';

type Props = BaseIconProps<string | ThemeIconName> & { variant: IconVariant };

/*
    This icon is the base for the both the Theme and Navigation icons. As per specs, the flow is as follows:

    - check if the icons path is present in the normal theme (ThemeIcon) or in the navigation-specific theme (NavigationIcon).
    - if no path is available, return the FallbackIcon to signal that this icon is not present
    - if the path is not a url, but a icon name (e.g. account.svg), check the project-specific icons via the ProjectLocalIcon component

*/
export const BaseIcon: FC<Props> = ({ iconName, size, iconStyles, variant, isActive }) => {
    const [hasErrored, setHasErrored] = useState(false);

    const { imageStyles, svgStyles } = getIconStyles(iconStyles, size);
    const { theme } = useTheme();

    const path = extractIconPath({ theme, iconName, variant, isActive });

    const handleSvgError = useCallback((error): void => {
        console.error('handleSvgError', iconName, error, path);
        setHasErrored(true);
    }, []);

    if (!path) return <FallbackIcon size={size} iconStyles={iconStyles} />;

    if (!isURL(path)) {
        return <ProjectLocalIcon iconName={path} size={size} />;
    }

    const isSvg = path.indexOf('svg') > -1;

    const svg = isPlatformWeb ? (
        <img src={path} style={imageStyles} onError={handleSvgError} alt={path} />
    ) : (
        <View style={{ width: size, height: size }}>
            {isSvg ? (
                <SvgUri
                    width="100%"
                    height="100%"
                    uri={path}
                    // @ts-expect-error: onError is missing in type declarations for this package version
                    onError={handleSvgError}
                    style={svgStyles}
                />
            ) : (
                <Image
                    source={{ uri: path }}
                    style={{
                        width: size,
                        height: size,
                    }}
                />
            )}
        </View>
    );

    return hasErrored ? <FallbackIcon size={size} iconStyles={iconStyles} /> : svg;
};
