import { CornerLogoStyles } from '../types';

const getCornerLogoStyles = (): CornerLogoStyles => ({
    holder: {
        flexDirection: 'row',
        zIndex: 999,
        alignSelf: 'center',
        marginRight: '3%',
    },
    image: { height: 50 },
});

export default getCornerLogoStyles;
