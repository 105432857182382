import { Theme } from '@24i/nxg-sdk-photon';
import { StepFlowModalStyles } from './types';

const getStyles = (theme: Theme): StepFlowModalStyles => ({
    modalContentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    modalContent: {
        backgroundColor: theme.color.primary1,
        borderRadius: 8,
    },
});

export default getStyles;
