import { isSubscription } from '@24i/nxg-sdk-photon';
import React from 'react';
import SubscriptionItem from './SubscriptionItem';
import ProductItem from './ProductItem';
import { PurchasableItemProps } from './types';

const PurchasesItem = (props: PurchasableItemProps): JSX.Element => {
    const { item } = props;
    if (isSubscription(item)) return <SubscriptionItem {...props} item={item} />;
    return <ProductItem {...props} />;
};

export default PurchasesItem;
