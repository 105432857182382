import React, { useState, useRef } from 'react';
import { Animated } from 'react-native';
import { Dimensions } from '@24i/nxg-sdk-quantum';
import PropTypes from 'prop-types';
import { View } from '@24i/nxg-sdk-quarks';

const ANIMATION_DURATION = 250;

const DrawerMenuWrapper = ({ renderMenu, renderScreen }) => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const { width: screenWidth } = Dimensions.get('window');
    const animatedRightRef = useRef(new Animated.Value(-screenWidth));

    const [width, setWidth] = useState(0);

    const openDrawerMenuAnimation = (cb) => {
        Animated.timing(animatedRightRef.current, {
            toValue: 0,
            duration: ANIMATION_DURATION,
        }).start(cb);
    };

    const closeDrawerMenuAnimation = (cb) => {
        Animated.timing(animatedRightRef.current, {
            toValue: -width,
            duration: ANIMATION_DURATION,
        }).start(cb);
    };

    const openDrawer = () => {
        setIsMenuVisible(true);
        openDrawerMenuAnimation();
    };

    const closeDrawer = () => {
        closeDrawerMenuAnimation(() => setIsMenuVisible(false));
    };

    const onLayout = ({ nativeEvent: { layout } }) => {
        setWidth(layout.width);
        if (width === 0) {
            animatedRightRef.current.setValue(-layout.width);
        }
    };

    return (
        <View style={{ flex: 1 }}>
            {renderScreen({ isMenuVisible, openDrawer, closeDrawer })}
            <Animated.View
                onLayout={onLayout}
                style={{
                    position: 'absolute',
                    height: '100%',
                    top: 0,
                    /*
                     * left needs to be animated, because
                     * tvos takes control over translateX
                     */
                    left: animatedRightRef.current,
                }}
            >
                {renderMenu({ isMenuVisible, openDrawer, closeDrawer })}
            </Animated.View>
        </View>
    );
};

DrawerMenuWrapper.defaultProps = {
    renderMenu: () => null,
    renderScreen: () => null,
};

DrawerMenuWrapper.propTypes = {
    renderMenu: PropTypes.func,
    renderScreen: PropTypes.func,
};

export default DrawerMenuWrapper;
