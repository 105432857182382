import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { FlatList } from 'react-native';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton/View';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import getStyles from './styles';
import { ChangeSubscriptionInterface, CHANGE_SUBSCRIPTION_TEST_IDS } from './types';
import SubscriptionItem from '../../PurchasesItem/SubscriptionItem';

const ChangeSubscription = ({
    currentPlan,
    availableSubscriptions,
    onSubscriptionSelect,
    onCurrentSubscriptionCancel,
}: ChangeSubscriptionInterface) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation();

    const availableSubscriptionsWithoutSelectedItem = availableSubscriptions.filter(
        (item) => item.id !== currentPlan.id
    );

    const handleOnCurrentSubscriptionCancel = () => onCurrentSubscriptionCancel(currentPlan.id);

    return (
        <View style={styles.wrapper}>
            <View style={styles.itemWrapper}>
                <SubscriptionItem
                    testID={CHANGE_SUBSCRIPTION_TEST_IDS.SUBSCRIPTION_SCREEN_ITEM_CURRENT}
                    item={currentPlan}
                    showTag
                    showBorder
                    hasTransparentBackground
                />
            </View>
            <View style={styles.cancelSubscriptionButtonWrapper}>
                <TertiaryButton
                    testID={CHANGE_SUBSCRIPTION_TEST_IDS.SUBSCRIPTION_CANCEL_BUTTON}
                    title={t('svod.cancel.cancelThis')}
                    onPress={handleOnCurrentSubscriptionCancel}
                />
            </View>
            {!!availableSubscriptions.length && (
                <>
                    <View style={styles.changeTextWrapper}>
                        <Text style={styles.changeText}>{t('svod.change.changeInto')}</Text>
                    </View>
                    <FlatList
                        data={availableSubscriptionsWithoutSelectedItem}
                        renderItem={({ item, index }) => (
                            <View style={styles.itemWrapper}>
                                <SubscriptionItem
                                    testID={`${
                                        CHANGE_SUBSCRIPTION_TEST_IDS.SUBSCRIPTION_SCREEN_ITEM
                                    }${index + 1}`}
                                    item={item}
                                    onPress={() => onSubscriptionSelect(item.id)}
                                />
                            </View>
                        )}
                        keyExtractor={(item) => item.id}
                    />
                </>
            )}
        </View>
    );
};

export default ChangeSubscription;
