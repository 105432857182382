import { ComponentProps, ReactNode } from 'react';
import { UrlObject } from 'url';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { Asset, ASSET_TYPE, PageSection } from '@24i/nxg-sdk-photon/src';
import type Footer from '../components/Footer';
import { ManageProfilesScreenModes } from '../screens/ManageProfilesScreen/types';

export type NativeScreenLink = string;

// eslint-disable-next-line @typescript-eslint/ban-types
export type WebScreenLink<TParams = void> =
    | { href: string; as?: UrlObject | string; query?: TParams }
    | {
          href: string;
          as?: UrlObject | string;
          query: TParams;
      };

// eslint-disable-next-line @typescript-eslint/ban-types
export type WebScreenDefinition<TParams = void> = {
    uniqueWebId?: string;
    getLink: ((params: TParams) => WebScreenLink<TParams>) | (() => WebScreenLink<TParams>);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type WebScreenLinkGetter<TParams = void> = {
    uniqueWebId?: string;
    getLink: ((arg?: any) => WebScreenLink<TParams>) | (() => WebScreenLink<TParams>);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type NativeScreenLinkGetter<TParams = void> = {
    getScreen: (params?: TParams) => NativeScreenLink;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export interface ScreenLinkGetter<TParams = void>
    extends NativeScreenLinkGetter<TParams>,
        WebScreenLinkGetter<TParams> {}

export interface NavigationProps extends NavigationPropsWeb, NavigationPropsNative {}

interface NavigationPropsNative {
    children?: ReactNode;
}

export interface NavigationPropsWeb {
    renderFooter?: (props: ComponentProps<typeof Footer>) => ReactNode;
    aboveHeaderContent?: ReactNode;
    children?: ReactNode;
    afterFooterContent?: ReactNode;
}

export enum NAVIGATION_TEST_IDS {
    BACK_BUTTON = 'navigation_back_button',
    BOTTOM_MENU_HOME_BUTTON = 'bottom_menu_home_button',
    BOTTOM_MENU_SEARCH_BUTTON = 'bottom_menu_search_button',
    BOTTOM_MENU_TVGUIDE_BUTTON = 'bottom_menu_tvguide_button',
    BOTTOM_MENU_LIVE_TV_BUTTON = 'bottom_menu_live-tv_button',
    BOTTOM_MENU_MORE_BUTTON = 'bottom_menu_more_button',
    MORE_BUTTON = 'more_button',
}

export enum ScreenOriginType {
    MENU = 'menu',
    PAGE = 'page',
    PLAYLIST = 'playlist',
    INTERNAL = 'internal',
    EPISODE = 'episode',
    LIVE_EVENT = 'live_event',
    MOVIE = 'movie',
    SERIES = 'series',
    CHANNEL = 'channel',
    BROADCAST = 'broadcast',
    PODCAST_SERIES = 'podcast',
}

export interface ScreenOrigin {
    type: ScreenOriginType;
    id: string;
    label: string;
}

export interface PageScreenRouteParams {
    reference?: string;
    title?: string;
    enableCastButton?: boolean;
    origin?: ScreenOrigin;
}

export interface PlaylistScreenRouteParams {
    title?: string;
    playlistId: string;
    origin?: ScreenOrigin;
}

export interface SeeAllScreenRouteParams {
    section?: PageSection;
    title?: string;
    items?: Asset[];
    origin?: ScreenOrigin;
}

export interface MyListScreenRouteParams {
    title?: string;
    origin?: ScreenOrigin;
}

export interface ManageProfilesScreenRouteParams {
    mode: ManageProfilesScreenModes;
    isEditing: boolean;
}

export interface MvpdSignInRouteParams {
    didInitialise: boolean;
    shouldSignOut: boolean;
    routeName?: string;
    isMvpdSigninPress?: boolean;
}

export interface PlaybackScreenRouteParams {
    asset?: Asset;
    id?: string;
    type?: ASSET_TYPE;
    origin?: ScreenOrigin;
    isStartOver?: boolean;
    enableCastButton?: boolean;
}

export interface DetailsScreenRouteParams {
    asset?: Asset;
    id?: string;
    type?: ASSET_TYPE;
    title?: string;
    channelId?: string;
    sectionLabel?: string;
    origin?: ScreenOrigin;
    enableCastButton?: boolean;
    episodeToPlay?: string;
}

export interface TvGuideScreenRouteParams {
    title?: string;
    enableCastButton?: boolean;
}

// Screen Params Types
// https://reactnavigation.org/docs/5.x/typescript
export type StackParamList = {
    [SOTT_DEFAULT_SCREENS.MANAGE_PROFILES]: ManageProfilesScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.ACCOUNT]: undefined;
    [SOTT_DEFAULT_SCREENS.PLAYBACK]: PlaybackScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.MY_LIST]: MyListScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.RECENTLY_WATCHED]: undefined;
    [SOTT_DEFAULT_SCREENS.DETAILS]: DetailsScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.SIGN_UP]: undefined;
    [SOTT_DEFAULT_SCREENS.SIGN_UP_SUCCESS]: undefined;
    [SOTT_DEFAULT_SCREENS.HOME]: PageScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.PAGE]: PageScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.TEXT_PAGE]: PageScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.MY_PURCHASES]: undefined;
    [SOTT_DEFAULT_SCREENS.TV_GUIDE]: TvGuideScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.SEE_ALL]: SeeAllScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.PLAYLIST]: PlaylistScreenRouteParams;
    [SOTT_DEFAULT_SCREENS.MVPD_SIGNIN]: MvpdSignInRouteParams;
};
