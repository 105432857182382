import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { Channel, EpgDataClient, Recording } from '@24i/nxg-sdk-photon';
import { DateTime } from 'luxon';
import { log } from '@24i/nxg-core-utils/src/logger';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { BackstageApiBase } from '../../base';
import { ChannelsResponseGuard, EpgResponseGuard } from './guards';
import { mapEpgResponseToEpgData, mapChannel } from './mappers';

export class BackstageEpgDataClient extends BackstageApiBase implements EpgDataClient {
    /**
     * @start Filter broadcasts within certain window (start/end time) - using ISO 8601 format - https://en.wikipedia.org/wiki/ISO_8601
     * @end Filter broadcasts within certain window (start/end time) - using ISO 8601 format - https://en.wikipedia.org/wiki/ISO_8601
     * @channelIds Filter broadcasts by channels ids separated by comma
     */
    getEpgData = async (
        start: string | null,
        end: string | null,
        channelIds: string[] = []
    ): Promise<Channel[]> => {
        const features = getRuntimeConfig('features');
        const shouldUseBasicEPG = !!features?.basicEpg;

        const startDate = start
            ? DateTime.fromISO(start).startOf('day')
            : DateTime.now().startOf('day');

        let endDate = end ? DateTime.fromISO(end).endOf('day') : DateTime.now().endOf('day');

        if (!startDate.isValid || !endDate.isValid) {
            log('Invalid dates provided to getEpgData function');
            return [];
        }

        // tvOS - Basic EPG
        // In tvOS we have a special attribute "daysToRequest" that comes from the custom JSON in backstage.
        // If we have this value defined we need to override the endDate.
        if (shouldUseBasicEPG && features?.basicEpg && features.basicEpg?.daysToRequest) {
            const { daysToRequest } = features.basicEpg;
            endDate = startDate.plus({ days: daysToRequest });
        }

        // Convert Date to UnixTimestamp
        const startFetchAt = startDate.toSeconds().toFixed();
        const endFetchAt = endDate.toSeconds().toFixed();

        try {
            const channels = await this.request({
                method: 'GET',
                path: '/media/channels',
                guard: ChannelsResponseGuard,
            });

            const channelsIdsFromReq = channels?.items?.map((i) => i.id);
            const from = startFetchAt;
            const to = endFetchAt;

            const epgData = await this.request({
                method: 'GET',
                path: '/epg',
                guard: EpgResponseGuard,
                query: {
                    ...{ from, to },
                    channels:
                        channelIds.join(',') ||
                        (channelsIdsFromReq ? channelsIdsFromReq.join(',') : ''),
                },
            });
            const mappedEpgData = mapEpgResponseToEpgData(epgData, channels);
            return mappedEpgData;
        } catch (err) {
            log(err);
            return [];
        }
    };

    getChannels = async (): Promise<Channel[]> => {
        const response = await this.request({
            method: 'GET',
            path: '/media/channels',
            guard: ChannelsResponseGuard,
        });
        return response.items.map(mapChannel);
    };

    getEpgDataInRange = async (range: { start: number; end: number }, channels: Channel[]) => {
        const from = Math.round(range.start / 1000);
        const to = Math.round(range.end / 1000);

        const epgData = await this.request({
            method: 'GET',
            path: '/epg',
            guard: EpgResponseGuard,
            query: {
                from,
                to,
                channels: channels.map((ch) => ch.id).join(','),
            },
        });

        return mapEpgResponseToEpgData(epgData, { items: channels });
    };

    getBroadcastByRecording = async (_recording: Recording) => undefined;
}

export const createBackstageEpgDataClient = (params: BaseApiParams) =>
    new BackstageEpgDataClient(params);
