import * as t from 'io-ts';
import { createGuard } from '@24i/nxg-core-utils/src/guards';
import { BroadcastGuard, ChannelGuard } from '@24i/nxg-sdk-photon';

export const EpgResponseGuard = createGuard(
    'EpgResonseGuard',
    t.record(t.string, t.array(BroadcastGuard), 'Broadcasts')
);

export const ChannelsResponseGuard = createGuard('ChannelsResponseGuard', {
    items: t.array(ChannelGuard, 'Channels'),
});
