import React from 'react';
import { isFactorMobile } from 'renative';
import { Theme } from '@24i/nxg-sdk-photon';
import { ActivityIndicator, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import PackshotGrid, {
    getPackshotGridStyles,
} from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid';
import useRoute from '@24i/nxg-core-router/src/hooks/useRoute';
import { getAnimatedHeaderScrollProps, getBottomBarHeight } from '../../../utils';
import getMyListStyles from '../styles';
import EmptyListPlaceholder from '../../EmptyListPlaceholder';
import { AppGridProps } from '../types';

const AppGrid = (props: AppGridProps) => {
    const {
        data,
        handleOnPackshotPress,
        handleOnEndReached,
        emptyListDescription,
        emptyListIcon,
        actionIcon,
        emptyListTitle = 'List is empty',
        isLoading = false,
        styles: getStyles = getMyListStyles,
    } = props;

    const route = useRoute();
    const { theme } = useTheme();

    const styles = getStyles(theme);

    const renderContent = () => {
        if (isLoading)
            return (
                <ActivityIndicator
                    color={theme.color.textPrimary}
                    size="large"
                    holderStyles={styles.activityIndicatorHolder}
                    style={[isFactorMobile && { paddingBottom: getBottomBarHeight() }]}
                    testID="app_grid_loader"
                />
            );
        if (!data?.length)
            return (
                <View
                    style={[
                        styles.emptyContainer,
                        isFactorMobile && { paddingBottom: getBottomBarHeight() },
                    ]}
                >
                    <EmptyListPlaceholder
                        iconName={emptyListIcon || 'myList'}
                        title={emptyListTitle}
                        description={emptyListDescription}
                    />
                </View>
            );
        return (
            <PackshotGrid
                items={data}
                onItemPress={handleOnPackshotPress}
                onEndReached={handleOnEndReached}
                onEndReachedThreshold={0.7}
                styles={(stylesTheme: Theme) => {
                    const defaultStyles = getPackshotGridStyles(stylesTheme);

                    return {
                        ...defaultStyles,
                        contentContainer: {
                            ...defaultStyles.contentContainer,
                            ...styles.gridContentContainer,
                            ...(isFactorMobile && { paddingBottom: getBottomBarHeight() }),
                        },
                    };
                }}
                actionIcon={actionIcon}
                {...getAnimatedHeaderScrollProps(route, 20)}
            />
        );
    };

    return <View style={styles.container}>{renderContent()}</View>;
};

export default overridable(AppGrid, 'AppGrid');
