import React, { useState, createContext, useEffect, useContext } from 'react';

export const MetadataContext = createContext();

export const MetadataProvider = ({ children }) => {
    const [mediaMetadata, setMediaMetadata] = useState([]);

    useEffect(() => {
        if (!mediaMetadata) {
            setMediaMetadata([]);
        }
    }, [mediaMetadata]);

    const dataContextValue = {
        mediaMetadata,
        setMediaMetadata,
    };

    return <MetadataContext.Provider value={dataContextValue}>{children}</MetadataContext.Provider>;
};

export const useMetadata = () => useContext(MetadataContext);

export const withMetadata = (Component) => (props) =>
    (
        <MetadataContext.Consumer>
            {(metadataContext) => <Component {...props} {...metadataContext} />}
        </MetadataContext.Consumer>
    );
