import { createContext } from 'react';
import { ThemeContextType } from '../../types';
import { mockTheme } from './mockTheme';

const mockContext: any = {
    theme: mockTheme(),
    setTheme: () => mockTheme(),
};

export const ThemeContext = createContext<ThemeContextType>(mockContext);
