import { isPlatformWeb } from 'renative';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { Breakpoint } from '../../../utils/styles/constants';
import { AccountDetailsStyles } from './types';

const getStyles = (): AccountDetailsStyles => ({
    container: {
        flex: 1,
        paddingHorizontal: isPlatformWeb ? undefined : 20,
        width: DeviceInfo.isTablet() ? Breakpoint.SM : '100%',
        alignSelf: DeviceInfo.isTablet() ? 'center' : 'auto',
    },
    scroll: {
        paddingBottom: 30,
        paddingTop: DeviceInfo.isTablet() ? 50 : 30,
    },
    buttonContainer: {
        marginTop: 10,
    },
});

export default getStyles;
