import { useCallback } from 'react';
import { useThrottleFn } from '@24i/nxg-core-utils/src';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useAppConfigQuery } from '../../../hooks/query/useAppConfigQuery';
import { PlaybackState } from './playbackState';
import { BuildPlaybackEventFunction } from './buildPlaybackEvent';

const DEFAULT_HEARTBEAT_INTERVAL = 5000;

export const useTriggerOnPlaybackHeartbeat = (buildPlaybackEvent: BuildPlaybackEventFunction) => {
    const { triggerMonitoringEvent } = useMonitoring();
    const { appSettings } = useAppConfigQuery();

    const { run: triggerOnPlaybackHeartbeat } = useThrottleFn(
        useCallback((playbackState?: PlaybackState): void => {
            const event = buildPlaybackEvent('PLAYBACK_HEARTBEAT', playbackState);
            if (!event) return;
            onAnalytics(ANALYTICS_TRIGGERS.HEARTBEAT_5, event);
            triggerMonitoringEvent({ ...event });
        }, []),
        {
            wait:
                appSettings?.features.monitoringPlayback?.heartbeatIntervalMs ||
                DEFAULT_HEARTBEAT_INTERVAL,
            leading: true,
        }
    );

    return triggerOnPlaybackHeartbeat as (playbackState?: PlaybackState) => void;
};
