import { useState, useEffect } from 'react';
import {
    RECORDING_STATUS,
    ASSET_TYPE,
    isRecordingFolder,
    RecordingsFolder,
    Recording,
    Broadcast,
} from '@24i/nxg-sdk-photon';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';
import { formatDurationText } from '@24i/nxg-core-utils';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import useRoute from '@24i/nxg-core-router/src/hooks/useRoute';
import { useRecordings } from '@24i/nxg-sdk-smartott-shared/src/context/Recordings';
import { useEpgData } from '@24i/nxg-sdk-smartott-shared/src/context/EpgData';
import {
    useMyRecordingsQuery,
    useDeleteRecordingsInFolder,
    useDeleteRecordingForBroadcast,
    useStorageInfo,
} from '../../../../hooks/query/recordings';
import { RecordingsRoute, RecordingsData } from '../../types';
import { UseRecordingsDataProps } from './types';

export const useRecordingsData = (status?: RECORDING_STATUS): UseRecordingsDataProps => {
    const [recordingsData, setRecordingsData] = useState<RecordingsData[] | Broadcast[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFolderMetadata, setSelectedFolderMetadata] = useState<RecordingsData>();

    const route = useRoute<RecordingsRoute>();
    const router = useRouter();
    const { t, i18n } = useTranslation();
    const recordingsDataClient = useRecordings();
    const epgDataClient = useEpgData();

    const isPlannedScreenSelected = status === RECORDING_STATUS.SCHEDULED;
    const recordingStatus = isPlannedScreenSelected
        ? RECORDING_STATUS.SCHEDULED
        : RECORDING_STATUS.COMPLETED;

    const getValidRecording = (recording: Recording | RecordingsFolder) =>
        isPlannedScreenSelected
            ? [RECORDING_STATUS.SCHEDULED, RECORDING_STATUS.PARTIAL].includes(recording.status)
            : recording.status === RECORDING_STATUS.COMPLETED;

    const { data, isLoading: isRecordingsLoading } = useMyRecordingsQuery();

    const { data: storageInfo = null, isLoading: isStorageLoading, refetch } = useStorageInfo();

    const deleteRecordingsInFolder = useDeleteRecordingsInFolder();
    const deleteRecordingForBroadcast = useDeleteRecordingForBroadcast();

    const hasPlannedRecordings = !!data?.find((recording) =>
        [RECORDING_STATUS.SCHEDULED, RECORDING_STATUS.PARTIAL].includes(recording.status)
    );

    const getFolderSubtitle = (count?: number, totalDuration?: number): string => {
        const title = t('recordings.manage.title').toLowerCase();
        return totalDuration
            ? `${count} ${count === 1 ? title.slice(0, -1) : title} · ${formatDurationText(
                  totalDuration
              )}`
            : `${count} ${count === 1 ? title.slice(0, -1) : title}`;
    };

    const getRecordingsSubtitle = (asset: Broadcast): string => {
        const date = new Date(asset.start).toLocaleDateString(i18n.language, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
        const hours = new Date(asset.start).toLocaleTimeString(i18n.language, {
            hour: '2-digit',
            minute: '2-digit',
        });

        if (asset.status === RECORDING_STATUS.COMPLETED) {
            return `${date} · ${formatDurationText(asset.end - asset.start)}`;
        }
        return `${date} · ${hours}`;
    };

    const createRecordingFolder = async ({
        recordingIds,
        id,
        title,
        status: assetStatus,
        still,
        totalDuration,
        channelLogo,
    }: RecordingsFolder) => {
        const folderAssets = await Promise.all(
            recordingIds.map(async (recordingId) => {
                try {
                    // Temporary solution!! it needs to be removed later
                    // @ts-ignore
                    const recording: Recording = await recordingsDataClient.recordingsMap[
                        recordingId
                    ];

                    const asset = await epgDataClient.getBroadcastByRecording(recording);
                    return {
                        ...asset,
                        folderId: id,
                        recordingId: recording.id,
                        subtitle: asset ? getRecordingsSubtitle(asset) : undefined,
                    };
                } catch (e) {
                    return null;
                }
            })
        );

        const filteredAssets = folderAssets.filter((asset) => asset !== null);

        return {
            title,
            id,
            type: ASSET_TYPE.SERIES,
            status: assetStatus,
            folderAssets: filteredAssets,
            recordingIds,
            subtitle: getFolderSubtitle(recordingIds?.length, totalDuration || 0),
            still,
            totalDuration,
            channelLogo,
        };
    };

    const createRecordingsBroadcastData = async (
        currentData: Array<Recording | RecordingsFolder>
    ) => {
        setIsLoading(true);

        const folderId = isPlatformWeb ? router.query?.fileId : route.params?.folderId;

        const recordings: RecordingsData[] = await Promise.all(
            currentData.map(async (recording) => {
                let asset;
                if (isRecordingFolder(recording)) {
                    asset = createRecordingFolder(recording);
                } else {
                    const assetRaw = await epgDataClient.getBroadcastByRecording(recording);

                    asset = {
                        ...assetRaw,
                        subtitle: assetRaw ? getRecordingsSubtitle(assetRaw) : undefined,
                    };
                }

                return asset;
            })
        );

        if (folderId) {
            const folder = recordings?.find(({ id }) => id === folderId);

            const folderAssets = folder?.folderAssets || [];
            setSelectedFolderMetadata(folder);
            setRecordingsData(folderAssets as Broadcast[]);
        } else {
            setSelectedFolderMetadata(undefined);
            setRecordingsData(recordings);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        const filteredRecordings = data?.filter(getValidRecording);
        if (filteredRecordings) {
            createRecordingsBroadcastData(filteredRecordings);
            refetch();
        }
    }, [data, router]);

    const deleteRecording = async (item: RecordingsData) => {
        const isSeries = item?.type === ASSET_TYPE.SERIES;
        const filteredFolder = recordingsData?.find((recording) => recording.id === item.id);

        if (isSeries && isRecordingFolder(filteredFolder)) {
            deleteRecordingsInFolder(filteredFolder);
        } else {
            deleteRecordingForBroadcast(item);
        }
    };

    return {
        data: recordingsData,
        selectedFolderMetadata,
        deleteRecording,
        isLoading: isLoading || isRecordingsLoading || isStorageLoading,
        storageInfo,
        isPlannedScreenSelected,
        recordingStatus,
        hasPlannedRecordings,
    };
};
