export const findBy = <T>(arr: T[], predicate: (item: T) => boolean) => {
    const idx = arr.findIndex(predicate);
    if (idx === -1) {
        return undefined;
    }

    return arr[idx];
};

export const findByField = <T, K extends keyof T>(arr: readonly T[], field: K, value: T[K]) => {
    return arr.find((item) => item[field] === value);
};

export const uniq = <T>(arr: T[]) => {
    return Array.from(new Set(arr));
};

export const uniqByField = <T>(arr: T[], field: string) => {
    const map = new Map();

    arr.forEach((item) => {
        const id = item[field];

        if (map.has(id)) {
            return;
        }

        map.set(id, item);
    });

    return Array.from(map.values()) as T[];
};

export const toggle = <T>(arr: T[], item: T, prepend = false) => {
    const idx = arr.indexOf(item);
    if (idx === -1) {
        return prepend ? [item, ...arr] : [...arr, item];
    }

    return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
};

export const toggleBy = <T>(
    arr: T[],
    item: T,
    predicate: (item: T) => boolean,
    prepend = false
) => {
    const idx = arr.findIndex(predicate);
    if (idx === -1) {
        return prepend ? [item, ...arr] : [...arr, item];
    }

    return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
};

export const removeIndex = <T>(arr: T[], index: number) => {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const addToIndex = <T>(arr: T[], index: number, item: T) => {
    return [...arr.slice(0, index), item, ...arr.slice(index)];
};

export const removeItem = <T>(arr: T[], item: T) => {
    const idx = arr.indexOf(item);
    if (idx === -1) {
        return arr;
    }

    return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
};

export const removeBy = <T>(arr: T[], predicate: (item: T) => boolean) => {
    const idx = arr.findIndex(predicate);
    if (idx === -1) {
        return [...arr];
    }

    return removeIndex(arr, idx);
};

export const splitToChunks = <T>(arr: T[], chunksSize: number): T[][] => {
    const arrRows: T[][] = [];
    for (let i = 0; i < arr.length; i += chunksSize) {
        arrRows.push(arr.slice(i, i + chunksSize));
    }
    return arrRows;
};
