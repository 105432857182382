const Detail = ({
    selectedOption = {},
    renderDetail,
    onBack,
    isLandscape,
    isDetailOpened,
    extraDetailParams,
}) =>
    selectedOption.renderDetail
        ? selectedOption.renderDetail({
              selectedOption,
              onBack,
              isLandscape,
              isDetailOpened,
              extraDetailParams,
          })
        : renderDetail({
              selectedOption,
              onBack,
              isLandscape,
              isDetailOpened,
          });

export default Detail;
