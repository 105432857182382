import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from '@24i/nxg-sdk-quarks';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import ChangeableText from '@24i/nxg-sdk-smartott/src/components/ChangeableText/View';
import ChangeModal from '@24i/nxg-sdk-smartott/src/components/ChangeableText/components/ChangeModal';
import { ChangeableTextItemProps } from '@24i/nxg-sdk-smartott/src/components/ChangeableText/types';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import { AccountDetailsProps, ACCOUNT_DETAIL_KEY } from '../types';
import getStyles from '../styles';
import { MAX_NAME_LENGTH } from '../constants';

const AccountDetailsScreen = (props: AccountDetailsProps) => {
    const {
        changeableDetails,
        errorForModal,
        isModalOpened,
        onDeleteAccountPress,
        openModal,
        closeModal,
    } = props;
    const styles = getStyles();
    const { t } = useTranslation();

    const [itemToChange, setItemToChange] = useState<ChangeableTextItemProps | undefined>(
        undefined
    );

    const handleModalOpen = (item: ChangeableTextItemProps): void => {
        setItemToChange(item);
        openModal();
    };

    useEffect(() => {
        if (errorForModal && !isModalOpened) closeModal(true);
    }, [isModalOpened]);

    return (
        <View style={styles.container}>
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scroll}>
                {changeableDetails?.map((item) => (
                    <ChangeableText
                        item={item}
                        onChangePress={(selectedItem) => handleModalOpen(selectedItem)}
                        key={item.itemKey}
                    />
                ))}
                <TertiaryButton
                    testID={ACCOUNT_DETAIL_KEY.DELETE_ACCOUNT}
                    title={t('account.delete.deleteButton')}
                    additionalContainerStyles={styles.buttonContainer}
                    onPress={onDeleteAccountPress}
                />
                <ChangeModal
                    item={itemToChange}
                    showModal={isModalOpened}
                    errorMessage={errorForModal}
                    closeModal={() => closeModal(false)}
                    maxNameLength={MAX_NAME_LENGTH}
                />
            </ScrollView>
        </View>
    );
};

export default overridable(withWebHeader(AccountDetailsScreen), 'AccountDetailsScreen');
