import React, { useState } from 'react';
import { TextInput, Text } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs/src/hooks/useTheme';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { FORGOT_PASSWORD_TEST_IDS, ViewModelReturnType } from '../../../types';
import getStyles from '../styles';

const CommonLayout = (props: ViewModelReturnType) => {
    const {
        isLoading,
        email,
        isValidEmail,
        onEmailChange,
        hasError,
        errorMessage,
        onSendLinkPress,
    } = props;

    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme, hasError);
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

    return (
        <>
            <TextInput
                testID={FORGOT_PASSWORD_TEST_IDS.FORGOT_PASSWORD_EMAIL_INPUT}
                style={[
                    styles.input,
                    hasError && styles.inputOnError,
                    (isInputFocused || email.length !== 0) && {
                        backgroundColor: theme.color.lighter1,
                    },
                ]}
                placeholder={t('account.accountDetails.emailAddress') as string}
                placeholderTextColor={`${theme.color.textPrimary}4D`}
                selectionColor={`${theme.color.textPrimary}66`}
                onChangeText={(text) => onEmailChange(text)}
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="email-address"
                blurOnSubmit={false}
                autoCompleteType="off"
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
            />
            {hasError && <Text style={styles.errorText}>{errorMessage}</Text>}
            <LoadingWrapper
                isLoading={isLoading}
                loaderProps={{
                    holderStyles: styles.loader,
                }}
            >
                <ActionButton
                    testID={FORGOT_PASSWORD_TEST_IDS.RESET_PASSWORD_BUTTON}
                    title={t('password.forgot.requestPasswordResetButton')}
                    onPress={() => onSendLinkPress(email)}
                    additionalContainerStyles={styles.sendButtonContainer}
                    disabled={isLoading || !isValidEmail || hasError}
                />
            </LoadingWrapper>
            <Text style={styles.instructionText}>{t('password.forgot.description')}</Text>
        </>
    );
};

export default CommonLayout;
