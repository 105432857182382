import { Purchase } from '@cleeng/mediastore-sdk';
import React, { FC, useEffect } from 'react';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { CleengComponentWrapper } from '../CleengComponentWrapper';
import { PurchaseSubscriptionScreenQuery } from '../../../screens/PurchaseSubscriptionScreen/types';
import { getPurchaseErrorUrl, getPurchaseSuccessUrl, setUpPaypalUrls } from '../../../utils/cleeng';
import { useAdyenConfig } from '../../../hooks/cleeng/useAdyenConfig';

export const CleengPurchase: FC<PurchaseSubscriptionScreenQuery> = ({
    offerId,
    referralAssetId,
    referralAssetType,
}) => {
    const router = useRouter();
    const { adyenConfiguration } = useAdyenConfig();

    useEffect(() => {
        const paypalErrorUrl = getPurchaseErrorUrl({
            referralAssetId,
            referralAssetType,
            currentPath: router?.asPath,
        });

        const paypalSuccessUrl = getPurchaseSuccessUrl({
            referralAssetId,
            referralAssetType,
        });

        setUpPaypalUrls({
            purpose: 'checkout',
            urls: { successUrl: paypalSuccessUrl as string, errorUrl: paypalErrorUrl },
        });
    }, [
        router?.asPath,
        referralAssetType,
        referralAssetId,
        getPurchaseErrorUrl,
        getPurchaseSuccessUrl,
        setUpPaypalUrls,
    ]);

    const onSuccess = async () => {
        const screenUrl = getPurchaseSuccessUrl({
            referralAssetId,
            referralAssetType,
            withOrigin: false,
        });

        if (screenUrl) {
            router.push(screenUrl);
        }
    };

    if (!offerId) return null;

    return (
        <CleengComponentWrapper>
            <Purchase
                offerId={offerId}
                adyenConfiguration={adyenConfiguration}
                onSuccess={onSuccess}
            />
        </CleengComponentWrapper>
    );
};
