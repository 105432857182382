import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';

interface useRegisterReturnType {
    accountRegistration: boolean;
}

export const useRegister = (): useRegisterReturnType => {
    const accountRegistration = useFeature('accountRegistration');

    return {
        accountRegistration: Boolean(accountRegistration?.enabled),
    };
};
