import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs/src';
import { WorkInProgressStyles } from './types';

const getWorkInProgressStyles = (theme: Theme): WorkInProgressStyles => {
    return {
        wrapperContainer: {
            position: 'absolute',
            zIndex: 10,
            alignItems: 'flex-start',
            top: 80,
        },
        contentContainer: {
            paddingHorizontal: 10,
            paddingVertical: 10,
            marginLeft: -1,
            backgroundColor: theme.color.lighter4,
            borderColor: 'rgba(255, 255, 255, 0.4)',
            borderWidth: 0.5,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
        },
        title: {
            ...getFont(theme, 'h5'),
            color: theme.color.textPrimary,
        },
    };
};

export default getWorkInProgressStyles;
