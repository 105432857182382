export const REMINDERS_NOTIFICATION_CHANNEL_NAME = 'reminders-notification-channel-name';
export const REMINDERS_NOTIFICATION_CHANNEL_ID = 'reminders-notification-channel-id';
export const DEFAULT_SCHEDULED_LOCAL_LOCAL_NOTIFICATION_TITLE = 'TITLE';
export const DEFAULT_SCHEDULED_LOCAL_LOCAL_NOTIFICATION_ACTION_TITLE = 'ACTION TITLE';

export interface UsePushNotifications {
    scheduleLocalPushNotification: (
        message: string,
        scheduledNotificationTime: Date,
        title: string,
        invokeApp: boolean,
        channelId: string,
        pushNotificationId?: string | number,
        userInfo?: ReminderNotificationPayload,
        thumbnailImageUrl?: string
    ) => void;
    cancelLocalPushNotification: (broadcastId: string, profileId: string) => Promise<void>;
}

export interface ReminderNotificationPayload {
    id: string;
    type: string;
    channelId: string;
    start: number;
    end: number;
    profileId: string;
}
