import React, { FC, useEffect, useState } from 'react';
import { getDeviceInfo } from '@24i/nxg-sdk-smartott-shared/src/analytics/utils';
import { useThemingForCleeng } from '../../../hooks/cleeng/useThemingForCleeng';

export const CleengStyles: FC = () => {
    const [isSafari, setIsSafari] = useState(false);

    const {
        heading2,
        heading3,
        heading4,
        heading5,
        body1,
        body2,
        buttonPrimary,
        buttonTertiary,
        generalTileRadius,
        elements,
        colors,
        textError,
    } = useThemingForCleeng();

    const backgroundColor = colors.background1;
    const buttonPrimaryColor = colors.buttonPrimary;
    const errorColor = colors.error;
    const { lighter3, textPrimary, contrast4 } = colors;

    const paymentHistoryArrowBottomMargin = isSafari ? -4 : -6;

    useEffect(() => {
        const checkIfIsSafari = async () => {
            const device = await getDeviceInfo();
            if (device.osVersion.toLowerCase().includes('safari')) {
                setIsSafari(true);
            }
        };

        checkIfIsSafari();
    }, []);

    return (
        <style global jsx>{`
            .msd__section-header,
            .msd__checkout-card-text__title,
            .msd__checkout-card-text__description,
            .msd__subscription-price__amount,
            .msd__subscription-price__currency,
            .msd__subscription-price__period,
            .msd__price-summary__label,
            .msd__price-summary__ammount,
            .msd__price-summary__total--label,
            .msd__price-summary__total--ammount,
            .msd__payment__legal,
            .msd__payment__legal p,
            .msd__footer__label--left,
            .msd__coupon-input__input,
            .msd__coupon-input__input::placeholder,
            .msd__error-page div,
            .msd__info-box__title,
            .msd__info-box__subtitle,
            .msd__checkout-card-text__description,
            .msd__payment-method__name,
            .msd__transaction__title,
            .msd__transaction__subtitle,
            .msd__transaction__id,
            .msd__transaction__date,
            .msd__payment-holder__name,
            .msd__payment-card__number,
            .msd__payment-data__label,
            .msd__payment-data__value,
            .msd__popup-content__title,
            .msd__popup-content__text,
            .msd__button--simple,
            .msd__popup-content__warning {
                color: ${textPrimary} !important;
            }

            .msd__footer,
            .msd__price-summary__ammount {
                margin: 0 !important;
            }

            .msd__price-summary__total--ammount,
            .msd__price-summary__total--label {
                margin-top: 20px !important;
            }

            .msd__header,
            .msd__checkout-wrapper,
            .msd__payment,
            .msd__footer,
            .msd__payment-details__wrapper,
            .msd__header + div,
            .msd__transactions-list__wrapper,
            .msd__popup {
                background-color: ${backgroundColor} !important;
            }

            .msd__payment-details__wrapper {
                padding: 0 !important;
            }

            .msd__popup {
                border: 1px solid rgba(255, 255, 255, 0.3) !important;
                border-radius: ${generalTileRadius} !important;
            }

            .msd__header,
            .msd__header__logo,
            .msd__header *[class],
            .msd__transactions-list__wrapper .msd__info-box__icon,
            .msd__subscription-card__icon,
            .msd__error-page svg,
            .msd__payment-details__wrapper .msd__section-header:nth-of-type(2),
            .msd__payment-details__wrapper .msd__transactions__wrapper,
            .msd__popup-content__payment-method-img svg,
            .msd__checkout-wrapper > .msd__section-header,
            .msd__payment-details__wrapper > .msd__section-header,
            .msd__transactions-list__wrapper > .msd__section-header,
            .msd__popup__title {
                display: none !important;
            }

            .msd__section-header {
                font-family: ${heading2?.family} !important;
                font-weight: ${heading2?.weight} !important;
                font-size: ${heading2?.size}px !important;
                line-height: ${heading2?.line}px !important;
                text-transform: none !important;
            }

            .msd__offer-card,
            .msd__payment-method__wrapper > .msd__info-box,
            .msd__account__card {
                border: 1px solid rgba(255, 255, 255, 0.3) !important;
                background-color: ${colors.lighter4} !important;
                border-radius: ${generalTileRadius} !important;
            }

            .msd__checkout-card-text__title,
            .msd__subscription-price__currency,
            .msd__custom-dropin-title,
            .msd__error-page div,
            .msd__info-box__title,
            .msd__payment-method__name,
            .msd__transaction__title,
            .msd__transaction__id {
                font-family: ${heading4?.family} !important;
                font-weight: ${heading4?.weight} !important;
                font-size: ${heading4?.size}px !important;
                line-height: ${heading4?.line}px !important;
            }

            .msd__checkout-card-text__description,
            .msd__subscription-price__period,
            .msd__info-box__subtitle,
            .msd__transaction__subtitle,
            .msd__transaction__date,
            .msd__payment-holder__name,
            .msd__payment-card__number,
            .msd__payment-data__label,
            .msd__payment-data__value {
                font-family: ${body2?.family} !important;
                font-weight: ${body2?.weight} !important;
                font-size: ${body2?.size}px !important;
                line-height: ${body2?.line}px !important;
            }

            .msd__button--primary,
            .msd__payment-method__button {
                font-family: ${buttonTertiary?.family} !important;
                font-weight: ${buttonTertiary?.weight} !important;
                font-size: ${buttonTertiary?.size}px !important;
                line-height: ${buttonTertiary?.line}px !important;
                background-color: ${colors.buttonSecondary} !important;
                border-radius: ${elements['buttonTertiary-cornerRadius']?.value} !important;
            }

            .msd__button--primary {
                color: ${colors.textButtonTertiary} !important;
            }

            .msd__price-summary__label,
            .msd__price-summary__ammount,
            .msd__payment__legal,
            .msd__payment__legal p,
            .msd__coupon-input__input {
                font-family: ${heading5?.family} !important;
                font-weight: ${heading5?.weight} !important;
                font-size: ${heading5?.size}px !important;
                line-height: ${heading5?.line}px !important;
            }

            .msd__info-box::after {
                box-shadow: none !important;
            }

            .msd__info-box:hover {
                opacity: 0.6 !important;
            }

            .msd__subscription-price__amount,
            .msd__price-summary__total--ammount,
            .msd__price-summary__total--label {
                font-family: ${heading3?.family} !important;
                font-weight: ${heading3?.weight} !important;
                font-size: ${heading3?.size}px !important;
                line-height: ${heading3?.line}px !important;
            }

            .msd__payment {
                border-top: 1px solid ${contrast4} !important;
                border-bottom: 1px solid ${contrast4} !important;
            }

            .msd__payment__legal,
            .msd__payment__legal p,
            .msd__info-box__subtitle,
            .msd__checkout-card-text__description,
            .msd__subscription-price__period,
            .msd__transaction__subtitle,
            .msd__transaction__date,
            .msd__payment-data__label,
            .msd__payment-data__value {
                opacity: 60% !important;
            }

            .msd__footer {
                border-top: 1px solid ${contrast4} !important;
            }

            .msd__error-page div {
                opacity: 80% !important;
            }

            .msd__coupon-input__wrapper--inner {
                background-color: ${lighter3} !important;
                border: none !important;
            }

            .msd__coupon-input__wrapper {
                max-width: 320px !important;
            }

            .msd__coupon-input__input {
                background-color: transparent !important;
                font-family: ${body1?.family} !important;
                font-weight: ${body1?.weight} !important;
                font-size: ${body1?.size}px !important;
                line-height: ${body1?.line}px !important;
            }

            .msd__coupon-input__input::placeholder {
                opacity: 40% !important;
            }

            .msd__coupon-input__close {
                background: white !important;
            }

            .msd__coupon-input__close svg path {
                fill: ${textPrimary} !important;
            }

            .msd__coupon-input__close svg {
                fill: ${lighter3} !important;
            }

            .msd__coupon-input__message,
            .msd__payment--error {
                font-family: ${textError?.family} !important;
                font-weight: ${textError?.weight} !important;
                font-size: ${textError?.size}px !important;
                line-height: ${textError?.line}px !important;
            }

            .msd__button--confirm {
                font-family: ${buttonPrimary?.family} !important;
                font-weight: ${buttonPrimary?.weight} !important;
                font-size: ${buttonPrimary?.size}px !important;
                line-height: ${buttonPrimary?.line}px !important;
                background-color: ${buttonPrimaryColor} !important;
                border-radius: ${elements['buttonPrimary-cornerRadius']?.value} !important;
            }

            .msd__payment--error {
                color: ${errorColor} !important;
            }

            .msd__info-box__icon > svg > circle {
                fill: ${buttonPrimaryColor} !important;
                stroke: ${buttonPrimaryColor} !important;
            }

            .msd__payment-method__wrapper > .msd__info-box {
                border-style: dotted !important;
            }

            .msd__button--simple:hover,
            .msd__paypal--wrapper {
                color: ${backgroundColor} !important;
            }

            .msd__checkout-card-price__badge {
                min-width: 90px !important;
                text-align: center !important;
            }

            .msd__transactions__button-text::after {
                line-height: 1 !important;
                margin-bottom: ${paymentHistoryArrowBottomMargin}px !important;
                bottom: 50% !important;
            }

            .adyen-checkout__payment-method {
                border-radius: unset !important;
                margin-bottom: 0 !important;
            }

            .adyen-checkout__payment-method:first-child {
                border-top-left-radius: 12px !important;
                border-top-right-radius: 12px !important;
            }

            .adyen-checkout__threeds2__challenge {
                background-color: white !important;
            }

            .adyen-checkout__payment-method--selected {
                margin: 0 !important;
            }

            .msd__custom-dropin--wrapper {
                border-radius: unset !important;
                border-bottom-left-radius: 12px !important;
                border-bottom-right-radius: 12px !important;
            }

            .msd__transactions__item {
                border-bottom: 1px solid ${lighter3} !important;
            }

            .msd__transactions__item:last-of-type {
                border-bottom: none !important;
            }

            .msd__transactions__item > div:nth-child(1) > div:nth-child(1) {
                background: white !important;
            }

            .msd__transactions__item div:nth-child(1),
            .msd__payment-card__type svg {
                min-width: 38px !important;
            }

            .msd__transactions__item div:nth-child(1) .msd__skeleton {
                height: 38px;
            }

            .msd__transactions__item svg {
                width: 2em !important;
            }

            @media only screen and (max-width: 768px) {
                .msd__popup-content__buttons {
                    margin-bottom: 10px !important;
                }

                .msd__popup-content__warning {
                    padding-bottom: 160px !important;
                }
            }
        `}</style>
    );
};
