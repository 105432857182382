import React from 'react';
import PropTypes from 'prop-types';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { View } from '@24i/nxg-sdk-quarks';

const Master = ({
    isLandscape,
    options,
    onSelect,
    selectedOption,
    renderWrapper: Wrapper,
    renderButton: Button,
}) => (
    <Wrapper isLandscape={isLandscape} onSelect={onSelect} selectedOption={selectedOption}>
        <View
            style={{
                flex: 1,
                marginTop: DeviceInfo.isTablet() ? 73 : null,
                width: DeviceInfo.isTablet() ? '60%' : '100%',
                alignSelf: 'center',
                justifyContent: 'center',
                flexWrap: DeviceInfo.isTablet() ? 'wrap' : 'nowrap',
                flexDirection: DeviceInfo.isTablet() ? 'row' : 'column',
            }}
        >
            {options.map((option, idx) => (
                <View
                    key={option.title}
                    style={{
                        width: DeviceInfo.isTablet() ? 212 : null,
                        height: DeviceInfo.isTablet() ? 120 : null,
                    }}
                >
                    <Button
                        key={option.title}
                        onPress={() => {
                            onSelect(option);
                        }}
                        isActive={option === selectedOption}
                        title={option.title}
                        isLandscape={isLandscape}
                        index={option.index || idx}
                        testID={option.testID}
                    />
                </View>
            ))}
        </View>
    </Wrapper>
);

Master.propTypes = {
    isLandscape: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            testID: PropTypes.string,
        })
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedOption: PropTypes.shape({
        title: PropTypes.string,
    }).isRequired,
    renderWrapper: PropTypes.func.isRequired,
    renderButton: PropTypes.func.isRequired,
};

export default Master;
