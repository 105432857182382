import React from 'react';
import { AppSettingsDataProvider } from '../AppSettingsData';
import { AppStructureDataProvider } from '../AppStructureData';
import { AnalyticsDataProvider } from '../AnalyticsData';
import { ContentDataProvider } from '../ContentData';
import { EntitlementsProvider } from '../Entitlements';
import { PlayerDataProvider } from '../PlayerData';
import { PurchaseDataProvider } from '../PurchaseData';
import { ThemeDataProvider } from '../ThemeData';
import { UserDataProvider } from '../UserData';
import { AppVersionDataProvider } from '../AppVersionData';
import { PrimetimeDataProvider } from '../PrimetimeData';
import { ProjectIconsProvider } from '../ProjectIconsProvider';

export const DefaultDataProviders = (props) => {
    const { clients, options, children } = props;
    const {
        entitlementsClient,
        themeDataClient,
        appSettingsDataClient,
        appStructureDataClient,
        analyticsDataClient,
        contentDataClient,
        playerDataClient,
        purchaseDataClient,
        userDataClient,
        appVersionClient,
        primetimeDataClient,
    } = clients;
    const { defaultTheme } = options;
    return (
        <AppSettingsDataProvider client={appSettingsDataClient}>
            <AppStructureDataProvider client={appStructureDataClient}>
                {/* This provider is used to build the final navigation config for the smartott sdk to use */}
                <AnalyticsDataProvider client={analyticsDataClient}>
                    <ContentDataProvider client={contentDataClient}>
                        <EntitlementsProvider client={entitlementsClient}>
                            <PlayerDataProvider client={playerDataClient}>
                                <PurchaseDataProvider client={purchaseDataClient}>
                                    <ThemeDataProvider
                                        client={themeDataClient}
                                        defaultTheme={defaultTheme}
                                    >
                                        <UserDataProvider client={userDataClient}>
                                            <PrimetimeDataProvider client={primetimeDataClient}>
                                                <ProjectIconsProvider>
                                                    <AppVersionDataProvider
                                                        client={appVersionClient}
                                                    >
                                                        {children}
                                                    </AppVersionDataProvider>
                                                </ProjectIconsProvider>
                                            </PrimetimeDataProvider>
                                        </UserDataProvider>
                                    </ThemeDataProvider>
                                </PurchaseDataProvider>
                            </PlayerDataProvider>
                        </EntitlementsProvider>
                    </ContentDataProvider>
                </AnalyticsDataProvider>
            </AppStructureDataProvider>
        </AppSettingsDataProvider>
    );
};
