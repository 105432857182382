/* eslint-disable @typescript-eslint/no-explicit-any */
import { extendGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ASSET_TYPE } from '../enums';
import { AssetGuard } from './asset';

export const BroadcastGuard = extendGuard(
    'Broadcast',
    AssetGuard,
    {
        channelId: t.string,
        start: t.number,
        end: t.number,
    },
    { seriesId: nullable(t.string) }
);

export type Broadcast = t.TypeOf<typeof BroadcastGuard>;
export type CatchUp = Broadcast;
export type Live = Broadcast;
export type Future = Broadcast;

export const isBroadcast = (asset: any): asset is Broadcast =>
    asset && asset.type === ASSET_TYPE.BROADCAST;

export const isCatchUp = (asset: any): asset is CatchUp =>
    isBroadcast(asset) && asset.start < Date.now() && asset.end < Date.now();

export const isLive = (asset: any): asset is Live =>
    isBroadcast(asset) && asset.start > Date.now() && asset.end < Date.now();

export const isFuture = (asset: any): asset is Future =>
    isBroadcast(asset) && asset.start > Date.now();
