import React from 'react';
import { usePinProtectedScreen } from '@24i/nxg-sdk-pin-protection/src/hooks/usePinProtectedScreen';
import View from './View';
import { useViewModel } from './viewModel';
import { AccountDetailsModelProps } from './types';

const Main = (props: AccountDetailsModelProps) => {
    const viewModelProps = useViewModel(props);
    usePinProtectedScreen({ active: true });
    return <View {...props} {...viewModelProps} />;
};

export default {
    Main,
    View,
    useViewModel,
};
