import { ASSET_TYPE, Reminder } from '@24i/nxg-sdk-photon';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useRemindersDataClient } from '@24i/nxg-sdk-smartott-shared/src/context/Reminders';
import { useTranslation } from 'react-i18next';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { log } from '@24i/nxg-core-utils/src/logger';
import { SOTT_DEFAULT_WEB_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useEffect, useState } from 'react';
import {
    brodacastHasStarted,
    getRemainingMinutesForReminder,
} from '@24i/nxg-sdk-smartott/src/utils/reminderUtils';
import { ViewModelReturnType } from '../types';

const useViewModel = (): ViewModelReturnType => {
    const router = useRouter();
    const { remindersDataClient, activeReminder } = useRemindersDataClient();
    const [modalVisible, setModalVisible] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (activeReminder) setModalVisible(true);
    }, [activeReminder]);

    function logReminderError(error: unknown): void {
        if (error instanceof Error) {
            log('Error setting reminder', error);
        }
        showToast(t('error.B00.title'));
    }

    const deleteReminder = async (reminder: Reminder): Promise<void> => {
        try {
            const { assetId, profileId } = reminder;
            await remindersDataClient.deleteReminderForBroadcast(assetId, profileId);
        } catch (error) {
            logReminderError(error);
        }
    };

    const closeModal = async (): Promise<void> => {
        if (activeReminder) await deleteReminder(activeReminder);
        setModalVisible(false);
    };

    const goToChannel = async (): Promise<void> => {
        if (activeReminder) {
            const { assetId, channelId } = activeReminder;
            await deleteReminder(activeReminder);
            setModalVisible(false);
            const detailsLink = SOTT_DEFAULT_WEB_SCREENS.Details.getLink({
                type: ASSET_TYPE.BROADCAST,
                id: assetId,
            });
            router.push({ pathname: detailsLink.href, query: { channelId } }, detailsLink.as);
        }
    };

    const remainingMinutesForReminder = getRemainingMinutesForReminder(activeReminder?.start);

    const getDescriptionText = () => {
        if (activeReminder) {
            if (brodacastHasStarted(activeReminder.start))
                return t('reminders.show.alreadyStarted', { title: activeReminder?.title || '' });
        }
        return t('reminders.show.description', {
            title: activeReminder?.title || '',
            minutes: remainingMinutesForReminder < 1 ? 0 : remainingMinutesForReminder,
            plural: remainingMinutesForReminder === 1 ? '' : 's',
        });
    };

    const modalText = {
        title: t('reminders.show.title'),
        description: getDescriptionText(),
        primaryButtonTitle: t('reminders.show.switchChannelButton'),
        secondaryButtonTitle: t('reminders.show.stayHereButton'),
    };

    return {
        modalVisible,
        closeModal,
        goToChannel,
        modalText,
    };
};

export default useViewModel;
