import React from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import { RATING_MODAL_TEST_IDS } from '../../test-utils';
import getRatingSystemStyles from './styles';
import { StarSystemProps } from './types';

const StarSystem = (props: StarSystemProps) => {
    const { withNumber, rating: currentRating, onStarPress } = props;
    const { theme } = useTheme();
    const styles = getRatingSystemStyles(theme);

    return (
        <View testID={RATING_MODAL_TEST_IDS.START_CONTAINER} style={styles.starContainer}>
            {Array.from({ length: withNumber }, (_, index) => (
                <IconButton
                    testID={`${RATING_MODAL_TEST_IDS.STAR_BUTTON}_${index}`}
                    key={index}
                    disableATVonPressLimiter
                    iconSize={48}
                    iconName={
                        currentRating && currentRating.rating > index
                            ? 'onButtonTertiary-rating'
                            : 'onButtonTertiary-ratingEmpty'
                    }
                    onPress={() => onStarPress(index + 1)}
                />
            ))}
        </View>
    );
};

export default StarSystem;
