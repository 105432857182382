import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { SettingsStreamingStyles } from '../types';

export const getStyles = (theme: Theme): SettingsStreamingStyles => ({
    safeArea: { alignItems: 'center' },
    container: isPlatformWeb
        ? {}
        : { paddingLeft: 20, paddingRight: 20, marginTop: 48, maxWidth: 500 },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: { ...getFont(theme, 'h4'), color: theme.color.textPrimary },
    descriptionContainer: {
        borderBottomColor: theme.color.contrast4,
        borderBottomWidth: 0.5,
        opacity: 0.6,
    },
    description: {
        ...getFont(theme, 'body2'),
        marginTop: 6,
        marginBottom: 16,
        color: theme.color.textPrimary,
        opacity: 0.6,
    },
});
