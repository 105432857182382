import { ViewStyle } from 'react-native';

interface FallbackImageStylesProps {
    container: ViewStyle;
}
const getFallbackImageStyles = (): FallbackImageStylesProps => ({
    container: { width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' },
});

export default getFallbackImageStyles;
