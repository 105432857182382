import { QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import { useQuery } from 'react-query';
import { useThemeData } from '@24i/nxg-sdk-smartott-shared/src/context/ThemeData';
import i18n from 'i18next';

export const useThemeQuery = () => {
    const themeDataClient = useThemeData();
    const getLanguage = () => i18n.language;
    const { isLoading, data, refetch } = useQuery(QUERY_KEYS.appTheme, themeDataClient.fetchTheme);

    return {
        isLoading,
        theme: data,
        refetch,
        language: getLanguage(),
    };
};
