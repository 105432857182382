import React from 'react';

import View from './View';
import { useViewModel } from './viewModel';
import { BannerCarouselTypes } from './types';

const Main = (props: BannerCarouselTypes) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
export { default as View } from './View';
export { useViewModel } from './viewModel';
