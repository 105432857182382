import React from 'react';
import { AppState, AppStateStatus } from 'react-native';

export default function useOnActiveState(isRequired: boolean, action: () => void) {
    const onAppStateChange = (nextAppState: AppStateStatus) => {
        if (nextAppState === 'active' && isRequired) {
            action();
        }
    };
    React.useEffect(() => {
        AppState.addEventListener('change', onAppStateChange);
        return () => AppState.removeEventListener('change', onAppStateChange);
    }, [isRequired]);
}
