import React from 'react';
import { View, Text, Interactable, TextInput } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import { useTranslation } from 'react-i18next';
import getStyles from '../styles';
import { CHANGEABLE_TEXT_TEST_IDS, ChangeableItemType, ChangeableTextProps } from '../types';

const ChangeableText = (props: ChangeableTextProps) => {
    const { item, onChangePress } = props;
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const { t } = useTranslation(['sott']);

    return (
        <View key={item.itemKey}>
            {item.type === ChangeableItemType.PIN && (
                <Text style={styles.description}>{t('pin.add.description')}</Text>
            )}
            <Text style={styles.titleText}>{item.title}</Text>
            <View style={styles.container}>
                {item?.secureText ? (
                    <TextInput
                        numberOfLines={3}
                        editable={false}
                        secureTextEntry={item.secureText}
                        style={styles.contentText}
                        value={item.value}
                    />
                ) : (
                    <Text
                        style={styles.contentText}
                        testID={`${CHANGEABLE_TEXT_TEST_IDS.TEXT}-${item.title}`}
                    >
                        {item.value}
                    </Text>
                )}

                {item.customInteractable || (
                    <Interactable onPress={() => (onChangePress ? onChangePress(item) : undefined)}>
                        <Text
                            style={styles.buttonText}
                            testID={`${CHANGEABLE_TEXT_TEST_IDS.BUTTON_CHANGE}-${item.title}`}
                        >
                            {item.buttonText}
                        </Text>
                    </Interactable>
                )}
            </View>
            <View style={styles.itemDivider} />
        </View>
    );
};

export default ChangeableText;
