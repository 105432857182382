import React from 'react';
import { WithWebHeaderProps } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader/types';
import View from './View';
import { ModularSubscriptionsElements } from '../../../../context/SubscriptionsHandlerProvider/types';

export const CleengPaymentHistoryScreen: ModularSubscriptionsElements['PaymentHistoryScreen'] = {
    Main: (props: WithWebHeaderProps) => {
        return <View {...props} />;
    },
    View,
};
