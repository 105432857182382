import React, { createContext, FC, useContext } from 'react';
import { defaultEngineFactory, usePlayerEngineResolution } from './resolution';
import { ContextType, IPlayerEngineProviderProps } from './types';

const dummyContextType: ContextType = {
    engine: undefined,
    setEngine: () => {
        // dummy isn't supposed to do anything
    },
};

const PlayerEngineContext = createContext<ContextType>(dummyContextType);

export const PlayerEngineProvider: FC<IPlayerEngineProviderProps> = ({
    engine = defaultEngineFactory,
    children,
}) => {
    const context = usePlayerEngineResolution(engine);
    return <PlayerEngineContext.Provider value={context}>{children}</PlayerEngineContext.Provider>;
};
PlayerEngineProvider.displayName = 'PlayerEngineProvider';

export const usePlayerEngine = () => useContext(PlayerEngineContext);
