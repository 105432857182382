/* eslint-disable react/display-name */
import React from 'react';

import View from './View';
import getEpgScreenStyles from './styles';
import { useViewModel } from './viewModel';
import * as prerenderFunctions from '../../pages/tvguide';

export default {
    Main: (props) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    page: {
        tvguide: prerenderFunctions,
    },
    // default styles getter
    getDefaultStyles: getEpgScreenStyles,
};
