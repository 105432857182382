import { EpgDataClient, DevicesManagementClient, RemindersClient } from '@24i/nxg-sdk-photon';
import { StubRecordingClient as RecordingClient } from '../RecordingsClient';

export interface DefaultClientStubs {
    epgDataClient: EpgDataClient;
    recordingsClient: RecordingClient;
    devicesManagementClient: DevicesManagementClient;
    remindersClient: RemindersClient;
}

export const isDefaultClientStubs = (clients: unknown): clients is DefaultClientStubs => {
    if (clients && typeof clients === 'object') {
        return (
            'epgDataClient' in clients &&
            'recordingsClient' in clients &&
            'devicesManagementClient' in clients &&
            'remindersClient' in clients
        );
    }
    return false;
};
