/*
@PRODUCT_FEATURE: H 1.9.2
*/
import React, { useState, useEffect } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import Track from '../components/Track';
import Thumb from '../components/Thumb';
import PlusMinus from '../components/PlusMinus';
import { limitX, stepifyValue } from '../utils';
import { styles } from '../styles';
import { SliderProps } from '../types';

const noop = (value: number) => value;
const plusMinus = (props) => <PlusMinus label="-" {...props} />;

const TRACK_HEIGHT = 3;
const THUMB_HEIGHT = 30;

const Slider = (props: SliderProps) => {
    const {
        style = {},
        disabled = false,
        value = 0,
        minimumValue = 0,
        maximumValue = 1,
        step = 0,
        onSlidingStart = noop,
        onSlidingComplete = noop,
        onValueChange = noop,
        minimumTrackTintColor = '#469bff',
        maximumTrackTintColor = '#c6c8c8',
        thumbTintColor = '#ffffff',
        renderPlusButton = plusMinus,
        renderMinusButton = plusMinus,
    } = props;

    const [innerValue, setInnerValue] = useState(() =>
        limitX(value, {
            minX: minimumValue,
            maxX: maximumValue,
        })
    );

    useEffect(() => {
        if (value !== innerValue) {
            setInnerValue(stepifyValue(value, step, minimumValue, maximumValue));
        }
    }, [value]);

    const onChange = (newValue) => {
        const stepifiedValue = stepifyValue(newValue, step, minimumValue, maximumValue);
        setInnerValue(stepifiedValue);
        onValueChange(stepifiedValue);
    };

    useEffect(() => {
        const limitedValue = limitX(value, {
            minX: minimumValue,
            maxX: maximumValue,
        });
        if (limitedValue !== value) onChange(limitedValue);
    }, [minimumValue, maximumValue]);

    return (
        <View style={[styles.slider, style]}>
            <Track tintColor={maximumTrackTintColor} width="100%" style={styles.track} />
            <Track
                tintColor={minimumTrackTintColor}
                width={`${(100 * (innerValue - minimumValue)) / (maximumValue - minimumValue)}%`}
                style={{ ...styles.track, marginTop: -TRACK_HEIGHT }}
            />
            <Thumb
                style={{ ...styles.thumb, ...(disabled ? styles.thumbDisabled : {}) }}
                tintColor={thumbTintColor}
                activeTintColor={minimumTrackTintColor}
                value={innerValue}
                min={minimumValue}
                max={maximumValue}
                step={step}
                height={THUMB_HEIGHT}
                onStart={(v) => onSlidingStart(stepifyValue(v, step, minimumValue, maximumValue))}
                onFinish={(v) =>
                    onSlidingComplete(stepifyValue(v, step, minimumValue, maximumValue))
                }
                onChange={onChange}
                disabled={disabled}
                renderPlusButton={renderPlusButton}
                renderMinusButton={renderMinusButton}
            />
        </View>
    );
};

export default Slider;
