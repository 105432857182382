import React from 'react';

import { SliderComponentProps } from './types';
import View from './View';
import { useViewModel } from './viewModel';

export { default as View } from './View';
export { useViewModel } from './viewModel';
export const getDefaultStyles = () => {};

const Main = (props: SliderComponentProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
