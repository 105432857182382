export default {
    fallbackLng: 'en',
    debug: false,
    ns: ['sott'],
    defaultNS: 'sott',
    nsSeparator: ':',
    keySeparator: '.',
    pluralSeparator: '-',
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
    react: {
        wait: true,
        useSuspense: false,
    },
    parseMissingKeyHandler: (key) => {
        const keys = key.split('.');
        return keys[keys.length - 1];
    },
};
