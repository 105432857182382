import React, { ReactElement } from 'react';
import { PackshotContentProps } from './types';

import View from './View';
import useViewModel from './viewModel';

const PackshotContent = (props: PackshotContentProps): ReactElement => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default PackshotContent;
