import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

const DrmDataGuard = createGuard('DrmData', { licenseUrl: t.string, certificateUrl: t.string });

export const StreamGuard = createGuard(
    'Stream',
    {
        url: t.string,
        type: t.string,
        drm: t.string,
    },
    {
        advertisement: t.string,
        data: DrmDataGuard,
        thumbnails: t.unknown,
        subtitles: nullable(t.array(t.UnknownRecord)),
    }
);

// Note: stream and drmData are expected to be there
// but are just copies of original values
export type Stream = t.TypeOf<typeof StreamGuard> & {
    stream: Stream['url'];
    drmData: Stream['data'];
};
