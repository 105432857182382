import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';

const styles = (theme: Theme) => ({
    holder: {
        height: 42,
        width: 'auto',
        borderRadius: 21,
        ...getBorderRadius(theme.elements, 'buttonTertiary-cornerRadius'),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.color.buttonTertiary,
        paddingLeft: 14,
        paddingRight: 21,
        '@media (max-width: 1200px)': {
            height: 40,
            borderRadius: 20,
            paddingLeft: 12,
            paddingRight: 18,
            ...getBorderRadius(theme.elements, 'buttonTertiary-cornerRadius'),
        },
        transition: 'opacity 0.15s ease-out',
        pressed: { opacity: 0.7 },
        hovered: { opacity: 0.8 },
    },
    holderFocused: {
        backgroundColor: theme.color.menuFocus,
    },
    text: {
        color: theme.color.textButtonTertiary,
        marginLeft: 10,
        ...getFont(theme, 'tertiaryButton'),
    },
    textFocused: {
        color: theme.color.textButtonTertiary,
    },
});

export default styles;
