/* eslint-disable no-alert */
import { useEffect, useState } from 'react';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { Image as WelcomeImage } from '@24i/nxg-sdk-photon/src/models/image';
import { SlideshowType } from '@24i/nxg-sdk-photon/src/models/welcomeScreen';
import { log } from '@24i/nxg-core-utils/src/logger';
import {
    useAppSettingsData,
    useFeature,
} from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useFirebase } from '../../../context/Firebase';
import { useStartPageNavigation } from '../../../context/AppStartContext/hooks';

type Routes = {
    [SOTT_DEFAULT_SCREENS.WELCOME]: {
        mode?: undefined;
    };
};

export interface WelcomeScreenViewModelProps {
    navigation: NavigationProp<any, any>;
    route: RouteProp<Routes, SOTT_DEFAULT_SCREENS.WELCOME>;
}

export const useViewModel = (props: WelcomeScreenViewModelProps) => {
    const { client: appSettingsDataClient } = useAppSettingsData();
    const [welcomeScreensByDevice, setWelcomeScreensByDevice] = useState<WelcomeImage[]>([]);
    const [tabletLandscapeScreen, setTabletLandscapeScreen] = useState<WelcomeImage[]>([]);
    const [slideshowType, setSlideShowType] = useState<SlideshowType>();
    const { navigateToStartPage } = useStartPageNavigation();
    const { recordError } = useFirebase();
    const welcomeScreensFeature = useFeature('welcomeScreens');
    const DEFAULT_RESOLUTION = 2;

    const getAppSettings = async () => {
        try {
            if (welcomeScreensFeature) {
                const [{ welcomeScreenId }] = welcomeScreensFeature.screens || [{}];

                if (!welcomeScreenId) return;

                const welcomeScreensAssets = await appSettingsDataClient.fetchWelcomeScreen(
                    welcomeScreenId
                );
                const setOfwelcomeScreensByDevice: Array<WelcomeImage> = [];
                const tabletLandscapeScreens: Array<WelcomeImage> = [];

                const getWelcomeScreensByPlatform = (resolution: number, platform: string) => {
                    welcomeScreensAssets.assets?.[platform]?.forEach(
                        (value) => setOfwelcomeScreensByDevice.push(value?.src?.[resolution]),
                        setWelcomeScreensByDevice(setOfwelcomeScreensByDevice)
                    );
                };

                const getLandscapeTabletScreen = (resolution: number) => {
                    welcomeScreensAssets.assets?.tabletLandscape?.forEach(
                        (value) =>
                            tabletLandscapeScreens.push(value?.src?.[resolution] as WelcomeImage),
                        setTabletLandscapeScreen(tabletLandscapeScreens)
                    );
                };

                if (DeviceInfo.isTablet()) {
                    getWelcomeScreensByPlatform(DEFAULT_RESOLUTION, 'tabletPortrait');
                    getLandscapeTabletScreen(DEFAULT_RESOLUTION);
                } else {
                    getWelcomeScreensByPlatform(DEFAULT_RESOLUTION, 'phonePortrait');
                }
                setSlideShowType(welcomeScreensAssets.slideshowType);
            }
        } catch (err) {
            alert(JSON.stringify(err));
            recordError(err);
            log(err);
        }
    };

    useEffect(() => {
        getAppSettings();
    }, []);

    const onPress = async () => {
        navigateToStartPage();
    };

    return {
        welcomeScreensByDevice,
        tabletLandscapeScreen,
        onPress,
        ...props,
        displaySingleWelcomeScreen: false,
        slideshowType,
    };
};
