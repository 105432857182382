import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';

const PageSettings = (theme) => ({
    subheaderText: {
        color: theme.color.textPrimary,
        marginTop: 15,
        marginLeft: 0,
        marginBottom: 8,
        ...getFont(theme, 'h4'),
        '@media (max-width: 596px)': {
            marginLeft: 8,
            marginTop: 8,
        },
        transition: 'opacity 0.15s ease-out',
    },
    optionIcon: {
        '@media (max-width: 596px)': {
            marginRight: 12,
        },
        transition: 'opacity 0.15s ease-out',
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '@media (max-width: 596px)': {
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
    },
    optionButton: {
        width: isPlatformWeb ? 280 : 212,
        height: isPlatformWeb ? 135 : 120,
        cursor: 'pointer',
        backgroundColor: theme.color.primary3,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        '@media (max-width: 596px)': {
            width: '100%',
            height: 64,
            alignItems: 'flex-start',
            paddingLeft: 32,
            marginBottom: 12,
        },
        ':hover': { backgroundColor: theme.color.primary4 },
        transition: 'opacity 0.15s ease-out',
    },
    buttonContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-width: 596px)': {
            flexDirection: 'row',
        },
    },
});

export default PageSettings;
