var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.ApplicationStoreProvider=void 0;var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));var _slicedToArray2=_interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react=_interopRequireWildcard(require("react"));

var _ApplicationStore=require("@24i/nxg-sdk-smartott/src/context/ApplicationStore");

var _nxgSdkQuantum=require("@24i/nxg-sdk-quantum");
var _globalSingletons=require("@24i/nxg-core-utils/src/globalSingletons");





var _utils=require("../../utils");var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/context/Store/index.js";function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=typeof e&&"function"!=typeof e)return{default:e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n.default=e,t&&t.set(e,n),n;}

var resetFocusGlobals=function resetFocusGlobals(){


_globalSingletons.LastFocusedPageTags.clear();
_globalSingletons.LayoutScrollView.clear();
_globalSingletons.TvDrawer.clear();
_globalSingletons.FocusedSectionId.set(0);
};

var ApplicationStoreProvider=function ApplicationStoreProvider(_ref)




{var defaultService=_ref.defaultService,children=_ref.children,config=_ref.runtimeConfig,setService=_ref.setService;
var _useState=(0,_react.useState)(),_useState2=(0,_slicedToArray2.default)(_useState,2),backstageUserData=_useState2[0],setBackstageUserData=_useState2[1];
var _useState3=(0,_react.useState)(config),_useState4=(0,_slicedToArray2.default)(_useState3,1),runtimeConfig=_useState4[0];
var _useState5=(0,_react.useState)(defaultService),_useState6=(0,_slicedToArray2.default)(_useState5,2),serviceData=_useState6[0],setServiceData=_useState6[1];

var _setServiceData=function _setServiceData(value){return _regenerator.default.async(function _setServiceData$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.next=2;return _regenerator.default.awrap(
_nxgSdkQuantum.Storage.setItem(_utils.ASYNC_STORAGE_KEY_SERVICE,JSON.stringify(value)));case 2:
setService(value);
setServiceData(value);case 4:case"end":return _context.stop();}},null,null,null,Promise);};


(0,_react.useEffect)(function(){
resetFocusGlobals();
},[serviceData]);

return(
_react.default.createElement(_ApplicationStore.ApplicationStoreProvider,{
runtimeConfig:config,
value:{
backstageUserData:backstageUserData,
setBackstageUserData:setBackstageUserData,
serviceData:serviceData,
setServiceData:_setServiceData,
runtimeConfig:runtimeConfig
},
key:serviceData==null?void 0:serviceData.id,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:45,columnNumber:9}},

children
));

};exports.ApplicationStoreProvider=ApplicationStoreProvider;