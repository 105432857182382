import { Theme } from '@24i/nxg-sdk-photon';
import { CreatePaginatorStyles } from './types';

const getCreatePaginatorStyles = (theme: Theme): CreatePaginatorStyles => {
    const dotSize = 10;
    return {
        paginatorWrapper: {
            position: 'absolute',
            bottom: 48,
            flexDirection: 'row',
            shadowColor: theme.color.textButtonPrimary,
            shadowOffset: { height: 1, width: 0 },
            shadowOpacity: 0.3,
            shadowRadius: 5,
        },
        positionIdicator: {
            width: dotSize,
            height: dotSize,
            borderRadius: 50,
            marginHorizontal: 5,
            elevation: 5,
        },
    };
};

export default getCreatePaginatorStyles;
