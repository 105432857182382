import { Theme } from '@24i/nxg-sdk-photon';
import { StarRatingStyles } from './types';

const getRatingSystemStyles = (_: Theme): StarRatingStyles => ({
    starContainer: {
        width: 335,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        paddingHorizontal: 32,
    },
});

export default getRatingSystemStyles;
