import React, { createContext, useContext } from 'react';

import { getDummyClient, PrimetimeDataClient } from '@24i/nxg-sdk-photon';

const defaultClient = getDummyClient<PrimetimeDataClient>();

export const PrimetimeDataContext = createContext<PrimetimeDataClient>(defaultClient);

export const PrimetimeDataProvider = ({ client = defaultClient, children }) => (
    <PrimetimeDataContext.Provider value={client}>{children}</PrimetimeDataContext.Provider>
);

export const usePrimetimeData = () => useContext(PrimetimeDataContext);
