import { getFont } from '@24i/nxg-sdk-higgs/src';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { AllItemsItemContentStyles } from '../types';

export const getAllItemsItemContentStyles = (theme: Theme): AllItemsItemContentStyles => ({
    textContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 58,
    },
    textStyle: {
        ...getFont(theme, 'h4'),
        color: theme.color.textPrimary,
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        alignSelf: 'flex-start',
    },
});
