import { Theme } from '@24i/nxg-sdk-photon';
import { ModalLayoutWithBackButtonStyles } from './types';

const getStyles = (theme: Theme): ModalLayoutWithBackButtonStyles => ({
    wrapper: {
        backgroundColor: theme.color.background1,
    },
    titleWrapper: {
        paddingVertical: 32,
        paddingHorizontal: 32,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content: {
        maxHeight: 480,
        paddingHorizontal: 16,
        paddingBottom: 16,
    },
});

export default getStyles;
