import { useEffect } from 'react';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useStore } from '../../../context/ApplicationStore';

import { useGridWithLazyLoading } from './hooks/useGridWithLazyLoading';
import { GridWithLazyLoadingProps, GridWithLazyLoadingWebReturnProps } from '../type';

const useViewModel = (props: GridWithLazyLoadingProps): GridWithLazyLoadingWebReturnProps => {
    const { fetchData } = props;
    const router = useRouter();
    const { selectedProfile } = useStore();

    const { data, isLoading, refetchData, setData } = useGridWithLazyLoading(fetchData);

    useEffect(() => {
        setData([]);
        refetchData();
    }, [router, selectedProfile]);

    return {
        data,
        isLoading,
        ...props,
    };
};

export { useViewModel };
