import React, { ReactElement } from 'react';
import { Platform } from 'react-native';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Ratio } from '@24i/nxg-sdk-higgs';

import { View, ThemeIcon, NavigationIcon } from '@24i/nxg-sdk-quarks';
import { IconContentProps } from './types';

const IconContent = (props: IconContentProps): ReactElement | null => {
    const {
        iconContainerStyle = null,
        isSelected = true,
        iconSize = Ratio(80),
        iconName,
        iconStyle,
        variant,
    } = props;

    const isNavigationIcon = variant === 'navigation';

    const svgIconSize = iconSize || (Platform.isTV ? Ratio(80) : 40);

    const icon = isNavigationIcon ? (
        <NavigationIcon size={svgIconSize} iconName={iconName} iconStyles={iconStyle} />
    ) : (
        <ThemeIcon size={svgIconSize} iconName={iconName} iconStyles={iconStyle} />
    );

    if (isSelected) {
        return <View style={iconContainerStyle}>{icon}</View>;
    }

    return null;
};

export default overridable(IconContent, 'IconContent');
