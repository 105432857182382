import React from 'react';
import { View, Text } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { ThemeIcon } from '@24i/nxg-sdk-quarks';
import { isValidThemeIconName } from '@24i/nxg-sdk-photon/src';
import { AssetLabelProps } from './types';
import getAssetLabelStyles from './styles';

const AssetLabel = (props: AssetLabelProps) => {
    const { text, iconName, testID } = props;
    const { theme } = useTheme();
    const styles = getAssetLabelStyles(theme, props);

    return (
        <View style={styles.container} testID={testID}>
            {!!iconName && isValidThemeIconName(iconName) && (
                <ThemeIcon size={styles.text.fontSize || 20} iconName={iconName} />
            )}
            {!!text && (
                <Text numberOfLines={1} style={styles.text}>
                    {text.replace(/^(.{16}).{1,}/, '$1…')}
                </Text>
            )}
        </View>
    );
};

export default AssetLabel;
