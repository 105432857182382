import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter, WebRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { RECORDING_STATUS, ASSET_TYPE, Asset, PageSection } from '@24i/nxg-sdk-photon';
import { GridProps } from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid';

import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';

import RecordingModal from '../components/RecordingModal';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../navigation/constants';
import { useRecordingsData } from './hooks/useRecordingsData';
import { RecordingViewProps, RecordingsData } from '../types';

const useViewModel = (): RecordingViewProps => {
    const [isEditing, setIsEditing] = useState(false);
    const [packshotIcon, setPackshotIcon] = useState<GridProps['actionIcon']>();
    const { t } = useTranslation();
    const { setModalChildren, closeModal } = useModal();
    const { handlePackshotPress: handlePackshotPressAction } = useAssetActions();

    const router = useRouter() as WebRouter;

    const { theme } = useTheme();

    const {
        data,
        deleteRecording,
        isLoading,
        storageInfo,
        isPlannedScreenSelected,
        recordingStatus,
        selectedFolderMetadata,
        hasPlannedRecordings,
    } = useRecordingsData(router.query?.status as RECORDING_STATUS | undefined);

    const showRecordingsCapacityIndicator =
        !!router?.asPath.startsWith(SOTT_DEFAULT_WEB_SCREENS.Recordings.getLink().href) &&
        !!storageInfo;

    const setEditingPackshotIcon = (recordingsData: RecordingsData[]) => {
        const epgRecordingElement = recordingsData.find((item) => !!item.status);
        if (epgRecordingElement) {
            setPackshotIcon(
                epgRecordingElement.status === RECORDING_STATUS.COMPLETED ? 'delete' : 'cancel'
            );
        } else {
            const seriesRecordingElement = recordingsData.find((item) => item.folderAssets);
            if (seriesRecordingElement?.folderAssets) {
                const firstElement = seriesRecordingElement.folderAssets.find(
                    (item) => !!item.status
                );
                setPackshotIcon(
                    firstElement?.status === RECORDING_STATUS.COMPLETED ? 'delete' : 'cancel'
                );
            }
        }
    };

    useEffect(() => {
        // redirect back if the folder page is empty
        if (!selectedFolderMetadata && router?.query?.fileId && !isLoading) {
            router.back();
        }
    }, [selectedFolderMetadata, isLoading]);

    useEffect(() => {
        setIsEditing(false);
    }, [router.query]);

    useEffect(() => {
        setEditingPackshotIcon(data);

        // If there is no more data to edit, automatically cancels edit mode.
        if (!data.length) setIsEditing(false);
    }, [data, router?.query?.status, router?.query?.fileId, isLoading]);

    const handlePackshotPress = ({
        asset,
        action,
    }: {
        asset: Asset;
        action?: PageSection['actionForAllItems'];
    }) => {
        const isSeries = asset?.type === ASSET_TYPE.SERIES;

        if (isEditing) {
            if (isSeries) {
                return setModalChildren(
                    <RecordingModal
                        recording={asset as RecordingsData}
                        recordingStatus={recordingStatus}
                        onDelete={() => {
                            deleteRecording(asset as RecordingsData);
                            closeModal();
                        }}
                        onClose={closeModal}
                    />
                );
            }
            return deleteRecording(asset as RecordingsData);
        }

        if (isSeries) {
            const recordingsLink = SOTT_DEFAULT_WEB_SCREENS.Recordings.getLink();

            return router.push({
                pathname: recordingsLink.href,
                query: { status: router.query?.status, fileId: asset?.id },
            });
        }

        return handlePackshotPressAction({ asset, action });
    };

    const handleNavButtonPress = () => {
        const recordingsLink = SOTT_DEFAULT_WEB_SCREENS.Recordings.getLink();

        router.push({
            pathname: recordingsLink.href,
            query: { status: RECORDING_STATUS.SCHEDULED, containingMenuTitle: 'Recordings' },
        });
    };

    const handleBackButtonPress = () => {
        // TODO: add logic to the back button
    };

    const handleEditButtonPress = () => {
        setIsEditing((prevState) => !prevState);
    };

    const { query } = router;

    const getWebHeaderTitle = (): string => {
        if (selectedFolderMetadata) return selectedFolderMetadata.title || '';
        if (query?.status === RECORDING_STATUS.SCHEDULED) return t('recordings.manage.planned');
        return t('recordings.manage.title');
    };

    const GetBackButtonTitle = (): string => {
        if (query?.status === RECORDING_STATUS.SCHEDULED && selectedFolderMetadata)
            return `${t('common.backTo')} ${t('recordings.manage.planned')}`;
        if (selectedFolderMetadata || query?.containingMenuTitle === 'Recordings')
            return `${t('common.backTo')} ${t('recordings.manage.title')}`;
        return `${t('common.backTo')} ${t('myContent.title')}`;
    };

    return {
        recordings: data,
        actionIcon: isEditing ? packshotIcon : undefined,
        emptyListTitle: t('recordings.manage.noData.title'),
        emptyListDescription: t('recordings.manage.noData.description'),
        emptyListIcon: theme.icons.myContent.recordings,
        handlePackshotPress,
        handleNavButtonPress,
        handleBackButtonPress,
        handleEditButtonPress,
        isLoading,
        isEditing,
        topBarTitle: t('recordings.manage.title'),
        navButtonTitle: t('recordings.manage.planned'),
        router,
        storageInfo,
        webHeaderTitle: getWebHeaderTitle(),
        backButtonTitle: GetBackButtonTitle(),
        showPlannedRecordingsButton:
            !isPlannedScreenSelected && !selectedFolderMetadata && hasPlannedRecordings,
        showRecordingsCapacityIndicator,
    };
};

export { useViewModel };
