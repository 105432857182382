import React from 'react';
import isPlatformWeb from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { TextContentProps } from './types';

const TextContent = (props: TextContentProps) => {
    const {
        isFocused = false,
        title = '',
        titleNumberOfLines = 1,
        titleContainerStyle,
        titleStyle,
        titleFocusedStyle,
        subtitle = null,
        subtitleNumberOfLines = 1,
        subtitleStyle,
        subtitleFocusedStyle,
        priceTag,
        currentPlanLabel,
        isCurrentPlan,
        currentPlanTextStyle,
    } = props;

    const titleText = (
        <Text
            style={[titleStyle, isFocused && titleFocusedStyle]}
            numberOfLines={titleNumberOfLines}
        >
            {title}
        </Text>
    );

    const titleContainer = isPlatformWeb ? (
        <>
            {isCurrentPlan && <Text style={currentPlanTextStyle}>{currentPlanLabel}</Text>}
            <View style={{ flexDirection: 'row' }}>
                {titleText}
                {priceTag}
            </View>
        </>
    ) : (
        titleText
    );

    return (
        <View style={titleContainerStyle}>
            {titleContainer}
            {subtitle && subtitle?.length > 0 && (
                <Text
                    style={[subtitleStyle, isFocused && subtitleFocusedStyle]}
                    numberOfLines={subtitleNumberOfLines}
                >
                    {subtitle}
                </Text>
            )}
        </View>
    );
};

export default overridable(TextContent, 'TextContent');
