import { AssocData, AssocRecord } from './types';

const id = 0;
const getId = () => `doubleRowItem${id}`;

const emptyItem = { key: '' };
const getKey = (itemOne = emptyItem, itemTwo = emptyItem) => {
    const keyOne = (itemOne && itemOne.key) || getId();
    const keyTwo = (itemTwo && itemTwo.key) || getId();
    return `${keyOne}${keyTwo}`;
};

/*
 * why ts-ignore?
 * I have no clue how this was supposed to work
 */
export const highlightAndPairUpData = (data: AssocData) => {
    const highlight: AssocData = [data[0]];
    // @ts-ignore
    highlight.key = getKey(data[0]);
    const pairedData = [highlight];
    const pairedDataLength = Math.ceil((data.length - 1) / 2);
    for (let i = 0; i < pairedDataLength; i++) {
        const pair = [data[i * 2 + 1], data[i * 2 + 2]];
        // @ts-ignore
        pair.key = getKey(pair[0], pair[1]);
        pairedData.push(pair);
    }
    return pairedData;
};

/*
 * Because data is indexed from 0 unlike human indexing,
 * even data checks for odd indexes and odd data checks for even indexes
 */
export const getOddData = (data: AssocData) =>
    data.filter((_: AssocRecord, index: number) => index % 2 === 0);
export const getEvenData = (data: AssocData) =>
    data.filter((_: AssocRecord, index: number) => index % 2 === 1);

export const pairUpData = (data: AssocData) => {
    const pairedData: AssocData[] = [];
    const pairedDataLength = Math.ceil(data.length / 2);
    for (let i = 0; i < pairedDataLength; i++) {
        const pair: AssocData = [data[i * 2], data[i * 2 + 1]];
        // @ts-ignore
        pair.key = getKey(pair[0], pair[1]);
        pairedData.push(pair);
    }
    return pairedData;
};
