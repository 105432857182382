import { useRegister } from '../../../../../../hooks/useRegister';
import { DropdownItem, TopBarMenuDropdownProps } from '../types';

const useViewModel = (props: TopBarMenuDropdownProps) => {
    const { items } = props;
    const { accountRegistration } = useRegister();

    const getItems = (): DropdownItem[] => {
        if (!accountRegistration) {
            return items.filter((item) => item.name !== 'register');
        }

        return items;
    };

    return { ...props, items: getItems() };
};

export { useViewModel };
