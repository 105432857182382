import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const usePreviousRoute = () => {
    const { asPath } = useRouter();
    const prevUrlRef = useRef<string | null>(null);
    useEffect(() => {
        const storedPrevUrl = localStorage.getItem('prevUrl');

        if (storedPrevUrl) {
            prevUrlRef.current = storedPrevUrl;
        } else {
            prevUrlRef.current = null;
        }
        localStorage.setItem('prevUrl', asPath);
    }, [asPath]);
    useEffect(() => {
        // User open app first time or coming from different WEB Url
        localStorage.removeItem('prevUrl');
    }, []);

    return prevUrlRef.current;
};
