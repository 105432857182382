import React from 'react';
import View from './View';
import { HtmlComponentProps, HtmlViewProps } from './types';
import { useViewModel } from './viewModel';

const Main = (props: HtmlComponentProps): JSX.Element => {
    const viewModelProps: HtmlViewProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
