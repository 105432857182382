import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle } from 'react-native';
import { getFont } from '@24i/nxg-sdk-higgs';

export const getStyles = (theme: Theme) => ({
    wrapper: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '5%',
    },
    logo: {
        width: 216,
        height: 126,
    },
    title: {
        color: theme.color.textPrimary,
        marginTop: '5%',
        paddingHorizontal: '7%',
        ...getFont(theme, 'h2'),
    },
    message: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        marginTop: '1%',
        paddingHorizontal: '7%',
        textAlign: 'center',
        ...getFont(theme, 'body2'),
    } as TextStyle,
    continueButtonWrapper: {
        marginTop: '3%',
    },
});
