/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */

import { AbstractLayoutClient } from '@24i/nxg-sdk-gluons';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
// import { Dimensions } from 'react-native';
import { isFactorTv, isPlatformWeb } from 'renative';

// const { width } = Dimensions.get('window');

export class LayoutClient extends AbstractLayoutClient {
    constructor(opts) {
        super(opts);
    }

    getPackshotSize = (displayType = 'portrait', isGrid = false, isHighlighted = false) => {
        const isPosterDisplay = displayType === 'portrait';
        let packshotHeight;
        let packshotWidth;
        if (isGrid) {
            if (isPosterDisplay) {
                packshotHeight = isFactorTv
                    ? 348
                    : isPlatformWeb
                    ? 260
                    : DeviceInfo.isTablet()
                    ? 192
                    : 176;
                packshotWidth = isFactorTv
                    ? 207
                    : isPlatformWeb
                    ? 174
                    : DeviceInfo.isTablet()
                    ? 128
                    : 102;
                return { packshotHeight, packshotWidth };
            }
            if (isHighlighted) {
                packshotHeight = isFactorTv ? 284 : isPlatformWeb ? 180 : 190;
                packshotWidth = isFactorTv ? 504 : isPlatformWeb ? 320 : 340;
                return { packshotHeight, packshotWidth };
            }
            packshotHeight = isFactorTv ? 213 : DeviceInfo.isTablet() || isPlatformWeb ? 140 : 90;
            packshotWidth = isFactorTv ? 378 : DeviceInfo.isTablet() || isPlatformWeb ? 250 : 160;
            return { packshotHeight, packshotWidth };
        }

        if (isPosterDisplay) {
            if (isHighlighted) {
                packshotHeight = isFactorTv ? 429 : DeviceInfo.isTablet() ? 294 : 258;
                packshotWidth = isFactorTv ? 286 : DeviceInfo.isTablet() ? 197 : 172;

                return { packshotHeight, packshotWidth };
            }
            packshotHeight = isFactorTv ? 348 : DeviceInfo.isTablet() ? 192 : 176;
            packshotWidth = isFactorTv ? 232 : DeviceInfo.isTablet() ? 128 : 117;

            return { packshotHeight, packshotWidth };
        }

        if (isHighlighted) {
            packshotHeight = isFactorTv
                ? 284
                : isPlatformWeb
                ? 325
                : DeviceInfo.isTablet()
                ? 290
                : 176;
            packshotWidth = isFactorTv
                ? 504
                : isPlatformWeb
                ? 640
                : DeviceInfo.isTablet()
                ? 516
                : 313;
            return { packshotHeight, packshotWidth };
        }

        packshotHeight = isFactorTv ? 207 : isPlatformWeb ? 139 : DeviceInfo.isTablet() ? 138 : 88;
        packshotWidth = isFactorTv ? 368 : isPlatformWeb ? 248 : DeviceInfo.isTablet() ? 246 : 156;
        return { packshotHeight, packshotWidth };
    };

    getOffsetAfter = (currentScreenWidth) =>
        currentScreenWidth <= 800
            ? 20
            : currentScreenWidth >= 801 && currentScreenWidth <= 1200
            ? 32
            : currentScreenWidth >= 1201 && currentScreenWidth <= 1600
            ? 48
            : currentScreenWidth * 0.04 + 10;
}
