import { useQuery, UseQueryOptions } from 'react-query';
import { Asset, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';

const useContinueWatchingQuery = (options?: UseQueryOptions<Asset[]>) => {
    const { fetchContinueWatchingPlaylist } = useContentData();
    const userDataClient = useUserData();
    const { data } = useQuery(QUERY_KEYS.user, userDataClient.fetchUser);

    return useQuery<Asset[]>(
        QUERY_KEYS.continueWatchingPlaylist,
        ({ signal }) => fetchContinueWatchingPlaylist(undefined, undefined, signal),
        {
            ...options,
            enabled: !!data,
        }
    );
};

export interface ContinueWatchingOffsetProps {
    continueWatchingLastTime: Asset['continueWatchingLastTime'];
    continueWatchingOffset: Asset['continueWatchingOffset'];
    duration: Asset['duration'];
}

export const getContinueWatchingOffset = (
    continueWatchingPlaylist: Asset[],
    assetId: Asset['id']
): ContinueWatchingOffsetProps | undefined => {
    const cwData = continueWatchingPlaylist?.find(
        ({ id: continueWatchingId }) => continueWatchingId === assetId
    );

    if (!cwData) {
        return undefined;
    }

    return {
        continueWatchingLastTime: cwData.continueWatchingLastTime ?? 0,
        continueWatchingOffset: cwData.continueWatchingOffset ?? 0,
        duration: cwData.duration ?? 0,
    };
};

export default useContinueWatchingQuery;
