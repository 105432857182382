/* eslint-disable camelcase */
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, ScrollResult } from '../types';
import { EVENTS } from '../constants';
import { mapBase } from './mapBase';

export const mapScroll = (triggerName: ANALYTICS_TRIGGERS, payload: Payload): ScrollResult => {
    const percent_scrolled = triggerName.split('_')[1];
    return {
        ...mapBase(payload),
        action: EVENTS.SCROLL,
        event_trigger: triggerName,
        source_page: payload.payload.pageId,
        thing_id: payload.payload.asset?.id || '',
        percent_scrolled,
    };
};
