import { ViewStyle } from 'react-native';

const styles = (): Record<string, ViewStyle> => ({
    holder: {
        flex: 1,
        backgroundColor: 'black',
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
});

export default styles;
