import { Theme } from '@24i/nxg-sdk-photon/src';
import { getFont } from '@24i/nxg-sdk-higgs/src';
import { SearchInputStyles } from '../types';

export const getSearchInputStyles = (theme: Theme): SearchInputStyles => {
    return {
        container: {
            width: '100%',
            height: 50,
            borderRadius: 5,
            marginTop: 8,
            marginBottom: 15,
            ...getFont(theme, 'h4'),
            paddingLeft: 20,
            backgroundColor: theme.color.lighter3,
            color: theme.color.textPrimary,
        },
    };
};
