export const getItem = (key) =>
    new Promise((resolve) => {
        if (typeof window === 'undefined') return resolve();
        const { document } = window;
        const name = `${key}=`;
        const cookie = document.cookie.split(';').find((c) => c.trim().indexOf(name) !== -1);
        if (!cookie) return resolve(null);
        return resolve(cookie.trim().substring(name.length, cookie.length));
    });

export const setItem = (key, value) =>
    new Promise((resolve) => {
        if (typeof window === 'undefined') return resolve();
        const { document } = window;
        document.cookie = `${key}=${value};`;
        return resolve();
    });

export const removeItem = (key) =>
    new Promise((resolve) => {
        if (typeof window === 'undefined') return resolve();
        const { document } = window;
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        return resolve();
    });

export const getAllKeys = () =>
    new Promise((resolve) => {
        if (typeof window === 'undefined') return resolve();
        const { document } = window;
        return resolve(document.cookie.split(';').map((cookie) => cookie.split('=')[0].trim()));
    });
