var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));var _extends2=_interopRequireDefault(require("@babel/runtime/helpers/extends"));var _slicedToArray2=_interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));var _objectWithoutProperties2=_interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));


var _react=_interopRequireWildcard(require("react"));
var _SigninScreen=_interopRequireDefault(require("@24i/nxg-sdk-smartott/src/screens/SigninScreen"));
var _Firebase=require("@24i/nxg-sdk-smartott/src/context/Firebase");

var _renative=require("renative");
var _logger=require("@24i/nxg-core-utils/src/logger");

var _ApplicationStore=require("@24i/nxg-sdk-smartott/src/context/ApplicationStore");
var _NextRouter=require("@24i/nxg-core-router/src/NextRouter");
var _useUserData=require("../../hooks/useUserData");var _excluded=["onSigninPress","handleEmailChange","handleEmailSubmit","handlePasswordChange","handlePasswordSubmit","handleSignInPressTv","handleForgotPasswordPress"];var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/screens/SigninScreen/index.tsx";function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=typeof e&&"function"!=typeof e)return{default:e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n.default=e,t&&t.set(e,n),n;}

var ExperienceOverridenSigninScreen=function ExperienceOverridenSigninScreen(props){var _route$params;
var route=props.route,navigation=props.navigation;
var _SignInScreen$useView=








_SigninScreen.default.useViewModel(),onSigninPress=_SignInScreen$useView.onSigninPress,handleEmailChange=_SignInScreen$useView.handleEmailChange,handleEmailSubmit=_SignInScreen$useView.handleEmailSubmit,handlePasswordChange=_SignInScreen$useView.handlePasswordChange,handlePasswordSubmit=_SignInScreen$useView.handlePasswordSubmit,handleSignInPressTv=_SignInScreen$useView.handleSignInPressTv,handleForgotPasswordPress=_SignInScreen$useView.handleForgotPasswordPress,viewModelResultRest=(0,_objectWithoutProperties2.default)(_SignInScreen$useView,_excluded);

var _useStore=(0,_ApplicationStore.useStore)(),setBackstageUserData=_useStore.setBackstageUserData;
var userDataClient=(0,_useUserData.useExperienceUserData)();
var router=(0,_NextRouter.useRouter)();
var _useFirebase=(0,_Firebase.useFirebase)(),recordError=_useFirebase.recordError,crashlyticsLog=_useFirebase.crashlyticsLog;

var _useState=(0,_react.useState)(false),_useState2=(0,_slicedToArray2.default)(_useState,2),isLoading=_useState2[0],setIsLoading=_useState2[1];
var backstage=_renative.isPlatformWeb?router.query.backstage:(_route$params=route.params)==null?void 0:_route$params.backstage;

var customOnSigninPress=function customOnSigninPress(
email,
password){var backstageUser;return _regenerator.default.async(function customOnSigninPress$(_context){while(1)switch(_context.prev=_context.next){case 0:if(!

backstage){_context.next=23;break;}
setIsLoading(true);_context.prev=2;


crashlyticsLog('Attempt to login with backstage');_context.next=6;return _regenerator.default.awrap(
userDataClient.loginWithBackstage(email,password));case 6:_context.next=8;return _regenerator.default.awrap(
userDataClient.fetchBackstageUser());case 8:backstageUser=_context.sent;
setBackstageUserData==null?void 0:setBackstageUserData(backstageUser);
setIsLoading(false);

if(_renative.isPlatformWeb){
router.back();
}else{
navigation.goBack();
}return _context.abrupt("return",
{login:true});case 15:_context.prev=15;_context.t0=_context["catch"](2);

recordError(_context.t0);
(0,_logger.log)(_context.t0);
setIsLoading(false);if(!(
_context.t0 instanceof Error)){_context.next=22;break;}return _context.abrupt("return",
{login:false,message:_context.t0.message});case 22:return _context.abrupt("return",

{login:false,message:JSON.stringify(_context.t0)});case 23:return _context.abrupt("return",


onSigninPress(email,password));case 24:case"end":return _context.stop();}},null,null,[[2,15]],Promise);};


return(
_react.default.createElement(_SigninScreen.default.View,(0,_extends2.default)({},
props,{
navigation:navigation,
route:route},
viewModelResultRest,{
onEmailChange:handleEmailChange,
onEmailSubmit:handleEmailSubmit,
onPasswordChange:handlePasswordChange,
onPasswordSubmit:handlePasswordSubmit,
onSignInPressTv:handleSignInPressTv,
onForgotPasswordPress:handleForgotPasswordPress,
onSigninPress:customOnSigninPress,
isLoading:isLoading||viewModelResultRest.isLoading,
signInToBackstage:backstage,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:70,columnNumber:9}})
));

};var _default=

ExperienceOverridenSigninScreen;exports.default=_default;