import { View } from '@24i/nxg-sdk-quarks';
import { useIsRTL } from '@24i/nxg-sdk-smartott-tv/src/hooks/useIsRTL';
import React, { ReactElement, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { isPlatformWeb } from 'renative';
import getProgressBarStyles, { getLinearGradientStyles } from '../styles';
import { ProgressBarProps, ProgressBarStyles } from '../types';

const ProgressBar = (props: ProgressBarProps): ReactElement => {
    const {
        theme,
        progress,
        isLive,
        styles: getStyles = getProgressBarStyles,
        additionalTrackStyles,
        additionalProgressStyles,
        color,
        trackColor,
        testID,
    } = props;

    const isRTL = useIsRTL();

    const styles = useMemo<ProgressBarStyles>(
        () =>
            getStyles(
                theme,
                progress,
                isLive,
                additionalTrackStyles,
                color,
                additionalProgressStyles,
                trackColor,
                isRTL()
            ),
        // allow for dynamic progress
        [progress]
    );

    const styleSheet = isPlatformWeb ? styles : StyleSheet.create(styles);

    return (
        <View testID={testID} style={styleSheet.track}>
            <View style={styleSheet.progress} />
        </View>
    );
};

ProgressBar.displayName = 'ProgressBar_Gluon';

export { getProgressBarStyles, getLinearGradientStyles };
export default ProgressBar;
