import { isPlatformAndroid } from 'renative';

const getMobileStyles = () => ({
    container: {
        backgroundColor: 'transparent',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerFocused: {},
    textStyle: {
        textAlign: 'center',
        fontSize: 20,
        lineHeight: isPlatformAndroid ? 40 : 50,
        fontWeight: 'bold',
    },
    textStyleFocused: {
        opacity: 0.75,
        textAlign: 'center',
    },
});

const PickerItem = (theme) => getMobileStyles(theme);
export default PickerItem;
