import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';

import { useStartPageNavigation } from '../../../context/AppStartContext/hooks';

import getAdditionalInfoScreenStyles from '../styles';

const useViewModel = (props) => {
    const { theme } = useTheme();
    const styles = getAdditionalInfoScreenStyles(theme);
    const { t } = useTranslation();
    const { navigateToStartPage } = useStartPageNavigation();

    return {
        ...props,
        title: t('onboarding.infoUpdate.title'),
        description: t('onboarding.infoUpdate.description'),
        buttonLabel: t('common.continue'),
        onPress: navigateToStartPage,
        styles,
    };
};

export { useViewModel };
