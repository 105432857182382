import { useEffect } from 'react';
import { ScreenViewed } from '@24i/nxg-sdk-photon/src/analytics/navigation';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useCurrentWebPageConfig } from '@24i/nxg-sdk-smartott/src/hooks/useCurrentWebPageConfig';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useTriggerOnce } from '../../hooks/useTriggerOnce';
import { useCommonNavEventParams } from '../useCommonNavEventParams';

export const useTriggerOnScreenChange = () => {
    const { currentPageConfig } = useCurrentWebPageConfig();
    const event = useCommonNavEventParams();
    const { triggerMonitoringEvent } = useMonitoring();
    const { triggerOnce } = useTriggerOnce<ScreenViewed>(
        (e) => `${e.pageId}${e.params?.id}${e.user?.id}`,
        triggerMonitoringEvent
    );
    useEffect(() => {
        if (
            event.pageId !== SOTT_DEFAULT_SCREENS.SEE_ALL &&
            event.pageId !== SOTT_DEFAULT_SCREENS.UPSERT_PROFILE &&
            event.pageId !== SOTT_DEFAULT_SCREENS.DETAILS
        )
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, event);
        triggerOnce({ ...event, type: 'SCREEN_VIEW' });
    }, [currentPageConfig, triggerMonitoringEvent]);
};
