import { useEffect, useMemo } from 'react';

import { Subscription } from '@24i/nxg-sdk-photon/src';
import { useRefreshOnFocus } from '@24i/nxg-sdk-smartott-shared/src/hooks/useRefreshOnFocus';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';

import { MySubscriptionScreenProps, MySubscriptionsViewProps } from '../types';
import useEntitledSubscriptionsQuery from '../../../hooks/query/subscriptions/useEntitledSubscriptionsQuery';
import useAvailableSubscriptionsQuery from '../../../hooks/query/subscriptions/useAvailableSubscriptions';
import useCancelSubscriptions from '../../../hooks/query/subscriptions/useCancelSubscriptions';
import { useOptionalTimedOutFunction } from '../../../hooks/useOptionalTimedOutFunction';

export const useViewModel = (props: MySubscriptionScreenProps): MySubscriptionsViewProps => {
    const router = useRouter();
    const hasSuccessfullyPurchased = useMemo(
        () => router?.query?.purchaseFlow === 'success',
        [router?.query?.purchaseFlow]
    );
    const subscriptionExclusivity = useFeature('subscriptionOptions')?.exclusivity ?? 'id';

    const {
        data: availableSubscriptions,
        isLoading: isSubscriptionsLoading,
        refetch: refetchAvailableSubscriptions,
    } = useAvailableSubscriptionsQuery();

    const {
        data: entitledSubscriptions,
        isLoading: isEntitledSubscriptionsLoading,
        refetch: refetchEntitledSubscriptions,
    } = useEntitledSubscriptionsQuery();

    const { mutate: cancelSubscription, isLoading: isCancelSubscriptionsLoading } =
        useCancelSubscriptions();

    const onSubscriptionCancelConfirm = async (subscription: Subscription) =>
        cancelSubscription(subscription.id);

    // (Not ideal) workaround to refetch entitled subs after coming from purchase screen. It takes some time for the new subscription to show up in entitlements
    // after the purchase component's onSuccess event
    useOptionalTimedOutFunction(hasSuccessfullyPurchased, refetchEntitledSubscriptions, 6000);

    useEffect(() => {
        if (availableSubscriptions) {
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, availableSubscriptions);
        }
    }, [availableSubscriptions]);

    useRefreshOnFocus(() => {
        refetchAvailableSubscriptions();
        refetchEntitledSubscriptions();
    });

    const availableSubscriptionsForUser = useMemo(() => {
        if (!availableSubscriptions?.subscriptions) return undefined;
        if (!entitledSubscriptions?.subscriptions?.length)
            return availableSubscriptions?.subscriptions;
        switch (subscriptionExclusivity) {
            case 'none':
                // don't exclude anything
                return availableSubscriptions?.subscriptions;
            case 'all':
                // a purchased subscription prevents purchasing any other subscription
                return [];
            default:
                // leave out already purchased subscriptions
                return availableSubscriptions?.subscriptions.filter(
                    (subscription) =>
                        !entitledSubscriptions?.subscriptions.some(
                            (purchasedSubscription) => purchasedSubscription.id === subscription.id
                        )
                );
        }
    }, [entitledSubscriptions, availableSubscriptions, subscriptionExclusivity]);

    return {
        ...props,
        currentPlans: entitledSubscriptions?.subscriptions,
        availableSubscriptions: availableSubscriptionsForUser,
        isLoading:
            isSubscriptionsLoading ||
            isEntitledSubscriptionsLoading ||
            isCancelSubscriptionsLoading,
        onSubscriptionCancelConfirm,
    };
};
