import { useState } from 'react';
import { webViewModelReturnType, WebViewModelProps } from '../types';

function useViewModel(props: WebViewModelProps): webViewModelReturnType {
    const { value, onValueChange } = props;

    const [isActiveFromState, setIsActive] = useState(false);
    const isActive = value ?? isActiveFromState;

    const handlePress = (): void => {
        const newValue = !isActive;
        setIsActive(newValue);
        onValueChange?.(newValue);
    };

    return { ...props, handlePress, isActiveFromState, isActive };
}

export { useViewModel };
