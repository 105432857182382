var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.getStyles=void 0;var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));var _nxgSdkHiggs=require("@24i/nxg-sdk-higgs");function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}



var getStyles=function getStyles(theme){
var activeItem=_objectSpread(_objectSpread({},
(0,_nxgSdkHiggs.getFont)(theme,'menuItem-active')),{},{
color:""+theme.color.menuItemActive});


return{
container:{
paddingTop:24,
paddingHorizontal:20
},
screenDescription:_objectSpread(_objectSpread({
textAlign:'left',
opacity:0.6},
(0,_nxgSdkHiggs.getFont)(theme,'body2')),{},{
color:theme.color.textPrimary}),

selectorContainer:{
marginTop:40,
marginBottom:300
},
backstageButtonStyles:{
marginVertical:40
},
itemContainer:{
flexDirection:'row',
alignItems:'flex-start',
flex:1,
paddingHorizontal:8,
paddingLeft:16,
marginVertical:12,
width:'100%',
zIndex:9999,
paddingVertical:10,
backgroundColor:theme.color.darker1,
':hover':{backgroundColor:theme.color.primary4}
},
itemText:_objectSpread(_objectSpread({
color:""+theme.color.menuItemInactive},
(0,_nxgSdkHiggs.getFont)(theme,'menuItem-inactive')),{},{

':hover':activeItem}),

activeItem:activeItem
};
};exports.getStyles=getStyles;