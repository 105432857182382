import React from 'react';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import SigninScreen from '@24i/nxg-sdk-smartott/src/screens/SigninScreen';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import useRoute from '@24i/nxg-core-router/src/hooks/useRoute';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../../navigation/constants';
import { BlockModalTypes } from '../../types';
import useShared from './useShared';

const useBlockedModal = () => {
    const { setModalChildren, closeModal } = useModal();
    const router = useRouter();
    const navigation = useNavigation();
    const route = useRoute();
    const { openBlockedModal } = useShared();
    const preferSignInScreen = useFeature('accounts', {})?.prefer_screen || false;

    const onBlockedModalAction = (type: BlockModalTypes) => {
        if (type === BlockModalTypes.ACCOUNT) {
            const subscriptionsListWebLink = SOTT_DEFAULT_WEB_SCREENS.SubscriptionsList.getLink({});

            router.push(subscriptionsListWebLink.href, subscriptionsListWebLink.as);
            return;
        }
        if (type === BlockModalTypes.MVPD_PROTECTION_LEVEL_ACCESS) {
            const mvpdSignInLink = SOTT_DEFAULT_WEB_SCREENS.MvpdSignIn.getLink();

            router.push(
                {
                    pathname: mvpdSignInLink.href,
                    query: { didInitialise: true, shouldSignOut: false },
                },
                mvpdSignInLink.as
            );
            return;
        }
        if (preferSignInScreen) {
            const signInLink = SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink();
            router.push(signInLink.href, signInLink.as);
            return;
        }
        setModalChildren(<SigninScreen.Main route={route} navigation={navigation} />);
    };

    const openBlockedModalWrapper = (
        type: BlockModalTypes,
        subtitle?: string,
        modalProps?: any,
        onClose?: () => void
    ) => {
        openBlockedModal(type, subtitle, modalProps, onBlockedModalAction, onClose);
    };

    return { openBlockedModal: openBlockedModalWrapper, closeBlockedModal: closeModal };
};

export default useBlockedModal;
