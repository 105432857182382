import React, { ComponentProps, FC } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { CenteredMessage } from '../CenteredMessage';
import { styles } from './styles';

export const PaymentErrorResult: FC<ComponentProps<typeof CenteredMessage>> = ({
    title,
    description,
    mainButtonText,
    onMainButtonPress,
    onSecondaryButtonPress,
    secondaryButtonText,
}) => (
    <View style={styles.wrapper}>
        <CenteredMessage
            title={title}
            description={description}
            mainButtonText={mainButtonText}
            onMainButtonPress={onMainButtonPress}
            secondaryButtonText={secondaryButtonText as string}
            onSecondaryButtonPress={onSecondaryButtonPress as () => void}
        />
    </View>
);
