import React, { useEffect } from 'react';
import { useAdobePrimetime } from '@24i/adobe-primetime/src';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import MvpdSignInScreen from '@24i/nxg-sdk-smartott/src/screens/MvpdSignInScreen';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { HomeScreenProps, HomeViewProps } from '../types';
import { useCurrentWebPageConfig } from '../../../hooks/useCurrentWebPageConfig';

const useViewModel = (props: HomeScreenProps): HomeViewProps => {
    const { currentPageConfig } = useCurrentWebPageConfig();
    const { setModalChildren, closeModal, setModalProps } = useModal();
    const {
        authState: { authenticated, initialised, initialising },
        initialiseAdobePrimetimeSDK,
        signOut,
    } = useAdobePrimetime();
    const router = useRouter();
    const { query } = router;

    const shouldSignOut = query.shouldSignOut === 'true';
    const landingScreenEnabled = useFeature('landingScreen')?.enabled;
    const adobePrimetimeBackstageConfig = useFeature('adobePrimetime');
    const adobePrimetimeEnabled = adobePrimetimeBackstageConfig?.enabled;
    const guestModeEnabled = useFeature('guestMode')?.enabled;

    useEffect(() => {
        if (adobePrimetimeEnabled && !landingScreenEnabled && !guestModeEnabled) {
            if (shouldSignOut) signOut();
            if (!initialised) initialiseAdobePrimetimeSDK(adobePrimetimeBackstageConfig);
            if (initialising && !authenticated) {
                setModalProps({
                    overrideDisplayIcon: guestModeEnabled,
                });
                setModalChildren(<MvpdSignInScreen.Main />);
            }
            if (authenticated) closeModal();
        }
    }, [authenticated, initialised, initialising]);

    const reference = currentPageConfig?.reference;

    return { reference, ...props };
};

export default useViewModel;
