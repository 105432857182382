import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { EmptyListPlaceholderStyles } from '../types';

const getEmptyListPlaceholderStyles = (theme: Theme): EmptyListPlaceholderStyles => ({
    container: { justifyContent: 'center', alignItems: 'center' },
    iconContainer: {
        backgroundColor: theme.color.darker3,
        borderRadius: 100,
    },
    icon: { margin: 20, opacity: 0.2 },
    primaryText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
        marginTop: 24,
        marginBottom: 8,
        opacity: 0.8,
    },
    secondaryText: {
        color: theme.color.textPrimary,
        opacity: 0.4,
        ...getFont(theme, 'body2'),
        maxWidth: 327,
        textAlign: 'center',
    },
});

export default getEmptyListPlaceholderStyles;
