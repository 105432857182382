import { Profile, ProfileImage, Image } from '@24i/nxg-sdk-photon';

export const useProfilePictureHelpers = () => {
    const byAvatarUrl = (imageToFindUrl: string) => (image: ProfileImage) =>
        image.images?.avatar?.some((avatar) => avatar.url === imageToFindUrl);

    const findProfileImage = (
        imageToFindUrl: string,
        allImages: ProfileImage[]
    ): ProfileImage | null => {
        return allImages.find(byAvatarUrl(imageToFindUrl)) ?? null;
    };

    const findProfilePictureIndex = (allImages: ProfileImage[], profile?: Profile) => {
        if (!profile) return 0;
        const result = allImages.findIndex(byAvatarUrl(profile.image || ''));
        return Math.max(result, 0);
    };

    const findDefaultPictureIndex = (allImages: ProfileImage[]) => {
        return Math.max(
            allImages.findIndex((image) => image.default),
            0
        );
    };

    const swapImageToFront = (images: ProfileImage[], index: number) => {
        const selectedImage = images[index];
        images.splice(index, 1);
        images.unshift(selectedImage);
        return images;
    };

    // It's not clear to me which image size we should use or which logic should we use to select the correct one. For now, I'm picking the first one.
    const findImageURL = (imageId: string, profileImages: ProfileImage[]) => {
        const profileImage = profileImages.find((image: ProfileImage) => image.id === imageId);
        if (profileImage) {
            return profileImage?.images?.avatar?.[0]?.url;
        }
        return null;
    };

    /**
     * Given a specifig height it returns the correct AVATAR/SIZE url
     * @param images image[]
     * @param layoutHeight number
     * @returns string
     */
    const getAvatarURLbyLayout = (
        images: Image[] | null | undefined,
        layoutHeight: number
    ): string => {
        if (images && images?.length > 0) {
            if (layoutHeight > 0) {
                const profileAvatar = images?.reduce((acc, current) => {
                    if (acc?.size?.height && current?.size?.height) {
                        const prevFactor = acc?.size?.height / layoutHeight;
                        const currFactor = current?.size?.height / layoutHeight;
                        const isCurrentSmaller = currFactor < prevFactor;
                        return isCurrentSmaller && currFactor >= 1 ? current : acc;
                    }
                    return {
                        url: '',
                        size: {
                            width: undefined,
                            height: undefined,
                        },
                    };
                });
                return profileAvatar?.url ? profileAvatar.url : '';
            }
        }

        return '';
    };

    return {
        findProfilePictureIndex,
        findDefaultPictureIndex,
        findProfileImage,
        swapImageToFront,
        findImageURL,
        getAvatarURLbyLayout,
    };
};
