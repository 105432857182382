import type { AdobePrimetimeAuthState } from './types';

export enum ACCESS_ENABLER_STATUS {
    SUCCESS = 1,
    ERROR = 0,
}

export enum ACCESS_ENABLER_AUTH_CODE {
    USER_AUTHENTICATED = 'User Authenticated',
    USER_NOT_AUTHENTICATED = 'User Not Authenticated Error',
    GENERIC_AUTHENTICATION_ERROR = 'Generic Authentication Error',
    AUTHENTICATION_UNINITIALIZED = 'Authentication Uninitialized',
    LOGOUT_SUCCESS = 'Logout',
}

export enum SERVICE_PROVIDERS {
    BASE_SP_URL_STAGING = 'sp.auth-staging.adobe.com/adobe-services',
    BASE_SP_URL_PRODUCTION = 'sp.auth.adobe.com/adobe-services',
}

export enum BACKSTAGE_SERVICE_PROVIDERS {
    BASE_SP_URL_STAGING = 'https://api.auth-staging.adobe.com/',
    BASE_SP_URL_PRODUCTION = 'https://api.auth.adobe.com/',
}

export enum ACCESS_ENABLER_CONSTANTS {
    BASE_SP_PATH = 'adobe-services',
    API_PRODUCTION = 'api.auth.adobe.com',
    API_STAGING = 'api.auth-staging.adobe.com',
    SP_URL_DOMAIN_NAME = 'adobe.com',
    SP_URL_PATH_ERRORS = '/errors',
    SP_URL_PATH_SERVICES = '/adobe-services',
    SP_URL_PATH_SET_REQUESTOR = '/config/',
    SP_URL_PATH_GET_AUTHENTICATION = '/authenticate/saml',
    SP_URL_PATH_GET_AUTHENTICATION_TOKEN = '/sessionDevice',
    SP_URL_PATH_GET_AUTHORIZATION_TOKEN = '/authorizeDevice',
    SP_URL_PATH_GET_SHORT_MEDIA_TOKEN = '/deviceShortAuthorize',
    SP_URL_PATH_LOGOUT = '/logout',
    SP_URL_PATH_GET_DEVICEID_METADATA = '/getMetadataDevice',
    SP_URL_PATH_GET_ENCRYPTED_USER_METADATA = '/usermetadata',
    SP_URL_IDENTIFIER_PASSIVE_AUTHN = '/completePassiveAuthentication,',
    SP_URL_PATH_CHECK_PREAUTHZ_RESOURCES = '/preauthorize',
    SP_URL_PATH_TOKEN_EXCHANGE = '/tokens',
    SP_URL_NEWPATH_VERIFICATION_CODE = '/sso/partners/Apple/providers/',
    CLIENTLESS_POLL_TIMER_INTERVAL = 10,
    CLIENTLESS_REGCODE_TTL = 1800,
    HTTP_RETRY_LIMIT = 1,
    CUSTOM_SCHEME_PREFIX = 'adbe.',
    ADOBEPASS_REDIRECT_SCHEME = 'adobepass://',
    ADOBEPASS_REDIRECT_URL = 'ios.app',
    HANDLED_USER_NOT_AUTHORIZED_ERROR = 'Handled User Not Authorized Error',
    USER_NOT_AUTHORIZED_ERROR = 'User Not Authorized Error',
    PROVIDER_NOT_SELECTED_ERROR = 'Provider Not Selected Error',
    GENERIC_AUTHENTICATION_ERROR = 'Generic Authentication Error',
    GENERIC_AUTHORIZATION_ERROR = 'Generic Authorization Error',
    SERVER_API_TOO_OLD = 'API Version too old. Please up,date your application.',
    METADATA_AUTHENTICATION = 0,
    METADATA_AUTHORIZATION = 1,
    METADATA_DEVICE_ID = 2,
    METADATA_USER_META = 3,
    METADATA_KEY_INVALID = 255,
    METADATA_OPCODE_KEY = 'opCode',
    METADATA_RESOURCE_ID_KEY = 'resource_id',
    METADATA_USER_META_KEY = 'user_metadata_name',
    TRACKING_AUTHENTICATION = 0,
    TRACKING_AUTHORIZATION = 1,
    TRACKING_MVPD_SELECTION = 2,
    TRACKING_NONE = 'None',
    TRACKING_YES = 'YES',
    TRACKING_NO = 'NO',
    OPTION_PROFILE = 'applicationProfile',
    OPTION_VISITOR_ID = 'visitorID',
    OPTION_AD_ID = 'adID',
    OPTION_DEVICE_INFO = 'device_info',
    OPTION_HANDLE_SVC = 'handleSVC',
}

const { AUTHENTICATION_UNINITIALIZED } = ACCESS_ENABLER_AUTH_CODE;

export const deafultAuthState: AdobePrimetimeAuthState = {
    initialised: 0,
    authenticated: 0,
    authenticating: false,
    initialising: false,
    authCode: AUTHENTICATION_UNINITIALIZED,
};

export enum RENDER_STATE {
    ERROR = 'ERROR',
    AUTHENTICATED = 'AUTHENTICATED',
    SHOW_LANDING_SCREEN = 'SHOW_LANDING_SCREEN',
    SHOW_MVPD_CONTENT = 'SHOW_MVPD_CONTENT',
    LOADING = 'LOADING',
}

export enum AUTHORIZATION_PROTECTION_LEVEL {
    LOGIN = 'login',
    FULL = 'full',
}

export enum AUTHORIZATION_STATUS {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}
