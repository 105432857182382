import React from 'react';
import { useTranslation } from 'react-i18next';
import { isSubscription } from '@24i/nxg-sdk-photon';
import PinInputModalContent from '@24i/nxg-sdk-pin-protection/src/components/PinInputModalContent';
import PinInputModal from '@24i/nxg-sdk-pin-protection/src/components/PinInputModal';
import { PinInputModalContentProps } from '@24i/nxg-sdk-pin-protection/src/components/PinInputModalContent/types';
import { ConfirmPurchaseViewProps } from '../types';
import SubscriptionConfirmPurchase from '../components/SubscriptionConfirmPurchase';
import ProductConfirmPurchase from '../components/ProductConfirmPurchase';
import SubscriptionItem from '../../../PurchasesItem/SubscriptionItem';
import ProductItem from '../../../PurchasesItem/ProductItem';

const ConfirmPurchase = (props: ConfirmPurchaseViewProps) => {
    const { t } = useTranslation();

    const {
        selectedItem,
        children,
        isPinControlVisible = false,
        isModalContent = false,
        closeModal,
        onSubmitPin,
    } = props;

    if (isPinControlVisible) {
        const pinControlProps: PinInputModalContentProps = {
            title: t('pin.enter.purchase.title'),
            message: t('pin.enter.purchase.description'),
            onSubmit: () => onSubmitPin?.(),
        };

        return isModalContent ? (
            <PinInputModalContent
                shouldDisplayTitle={false}
                onModalClose={closeModal}
                {...pinControlProps}
            />
        ) : (
            <PinInputModal
                isVisible={isPinControlVisible}
                onModalClose={() => closeModal?.()}
                transparent
                {...pinControlProps}
            />
        );
    }

    return (
        <>
            {isSubscription(selectedItem) ? (
                <SubscriptionConfirmPurchase {...props} selectedItem={selectedItem}>
                    {children || (
                        <SubscriptionItem
                            item={selectedItem}
                            showBorder
                            showTag
                            tagText={t('svod.newSubscriptionBadge')}
                        />
                    )}
                </SubscriptionConfirmPurchase>
            ) : (
                <ProductConfirmPurchase {...props} selectedItem={selectedItem}>
                    {children || <ProductItem item={selectedItem} showBorder />}
                </ProductConfirmPurchase>
            )}
        </>
    );
};

export default ConfirmPurchase;
