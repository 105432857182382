import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { OnboardingWebHeaderStyles } from '../types';

const getOnboardingWebHeaderStyles = (theme: Theme): OnboardingWebHeaderStyles => ({
    headerContainer: {
        top: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: '4%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 14,
    },
    webNavMenu: {
        height: 48,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 94,
    },
    headerTitle: {
        ...getFont(theme, 'h2'),
        color: theme.color.textPrimary,
    },
});

export default getOnboardingWebHeaderStyles;
