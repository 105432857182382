import React, { useState } from 'react';
import { LayoutChangeEvent } from 'react-native';
import { Text, Interactable, View } from '@24i/nxg-sdk-quarks';
import { useTheme, getFont } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { rgbaToHex } from '@24i/nxg-core-utils';
import getExpandableTextStyles from '../styles';
import { ExpandableTextPops, LineHeight } from '../types';

const ExpandableText = ({
    text = '',
    styles: getStyles = getExpandableTextStyles,
    numberOfLines = 3,
    fontName = 'body1',
    testID,
}: ExpandableTextPops) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const [state, setState] = useState({
        isExpanded: false,
        isExpandable: false,
        isOnLayoutCalculated: false,
        hasMoreThanTwoRows: false,
    });

    const toggleExpansion = () => {
        const { isExpanded } = state;
        setState((prevState) => ({ ...prevState, isExpanded: !isExpanded }));
    };

    const onLayout = (e: LayoutChangeEvent) => {
        let lineHeight: number;
        if (Array.isArray(styles.description)) {
            lineHeight =
                styles.description.length &&
                Number.parseInt(
                    (
                        Object.values<LineHeight>(styles.description?.[0])?.[0].lineHeight ?? 23
                    ).toString(),
                    10
                );
        } else {
            lineHeight = Number.parseInt(Object.values(styles.description)?.[0].lineHeight, 10);
        }

        const linesCount = Math.trunc(e.nativeEvent.layout.height / lineHeight);

        if (!state.isOnLayoutCalculated && linesCount && linesCount > 0) {
            const isExpandedLayout = linesCount <= numberOfLines;

            const hasMoreThanTwoRows = linesCount > 2;

            setState((prevState) => ({
                ...prevState,
                isExpandable: linesCount > numberOfLines,
                isExpanded: isExpandedLayout,
                isOnLayoutCalculated: true,
                hasMoreThanTwoRows,
            }));
        }
    };

    const DescriptionText = ({ textValue, ...props }) => (
        <Text
            {...props}
            style={[
                state.isOnLayoutCalculated ? styles.textOpacity : styles.invisibleTextOpacity,
                styles.description,
            ]}
            testID={testID}
        >
            {textValue}
        </Text>
    );

    const { isExpanded, isExpandable, hasMoreThanTwoRows } = state;

    return (
        <Interactable
            activeOpacity={1}
            disabled={!isExpandable}
            onPress={toggleExpansion}
            style={styles.container}
        >
            <View style={styles.itemHolder}>
                {isExpandable && !isExpanded ? (
                    <>
                        <style jsx>{`
                            div {
                                font-size: ${getFont(theme, fontName).fontSize};
                                font-family: ${getFont(theme, fontName).fontFamily};
                                background: -webkit-linear-gradient(
                                    ${rgbaToHex(theme.color.textPrimary)}E6
                                        ${hasMoreThanTwoRows ? '50%' : '100%'},
                                    ${rgbaToHex(theme.color.textPrimary)}00
                                );
                                display: -webkit-box;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                -webkit-line-clamp: ${numberOfLines};
                                line-break: anywhere;
                                -webkit-box-orient: vertical;
                                letter-spacing: ${getFont(theme, fontName).letterSpacing};
                                line-height: ${getFont(theme, fontName).lineHeight};
                                overflow: hidden;
                            }
                        `}</style>
                        <div>{text}</div>
                    </>
                ) : (
                    <DescriptionText
                        textValue={text}
                        onLayout={!state.isOnLayoutCalculated && onLayout}
                    />
                )}
            </View>
        </Interactable>
    );
};

export { getExpandableTextStyles };
export default overridable(ExpandableText, 'ExpandableText');
