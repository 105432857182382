import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { log } from '@24i/nxg-core-utils/src/logger';
import {
    AppSettings,
    AppStructureDataClient,
    createMenuItem,
    MenuItem,
    PRODUCT_SCREENS,
} from '@24i/nxg-sdk-photon';
import { isPlatformWeb } from 'renative';

import { BackstageApiBase } from '../../base';
import { BackstageMenuResponseGuard } from './guards';
import { BackstageMenu, BackstageMenuItem } from './types';

export const BACKSTAGE_MENU_ITEM_TYPES = {
    HOME: 'home',
    PAGE: 'page',
    CHANNELS: 'channels',
    EPG: 'epg',
    SEARCH: 'search',
    SUBMENU: 'menu',
    TEXT_PAGE: 'textPage',
    EXTERNAL: 'external',
    MORE: 'more',
    MANAGE_PROFILES: 'manageProfiles',
    RECORDINGS: 'recordings',
    MY_LIST: 'favourites',
    RECENTLY_WATCHED: 'recentlyWatched',
    MY_PURCHASES: 'purchases',
    MANAGE_DEVICES: 'manageDevices',
    ACCOUNT_DETAILS: 'accountDetails',
    SUBSCRIPTIONS: 'subscriptions',
    SIGN_IN: 'login',
    SIGN_UP: 'register',
    SIGN_UP_QR: 'qr-register',
    SIGN_OUT: 'signOut',
    LANGUAGE: 'languageSettings',
    TECHNICAL_INFO: 'technicalInformation',
    TABS: 'tabs',
    CATCHUP: 'catchup',
    REMINDER: 'reminders',
    DOWNLOADS: 'downloads',
    APP_VERSION: 'appVersion',
    STREAMING: 'streamingSettings',
    DOWNLOADS_SETTINGS: 'downloadSettings',
    DEVELOP_SUPPORT: 'developmentSupport',
    DEVICE_INGO: 'deviceInformation',
    SERVICE_SWITCHER: 'serviceSwitcher',
    THEME_SWITCHER: 'themeSwitcher',
    PAYMENT_HISTORY: 'paymentHistory',
    PIN: 'pin',
    PAYMENT_METHOD: 'paymentMethods',
};

// TODO: replace FallbackScreens with correct ones.
export const API_SCREEN_TYPE_TO_PRODUCT_SCREEN_MAP = {
    [BACKSTAGE_MENU_ITEM_TYPES.HOME]: PRODUCT_SCREENS.HOME,
    [BACKSTAGE_MENU_ITEM_TYPES.TEXT_PAGE]: PRODUCT_SCREENS.TEXT_PAGE,
    [BACKSTAGE_MENU_ITEM_TYPES.SEARCH]: PRODUCT_SCREENS.SEARCH,
    [BACKSTAGE_MENU_ITEM_TYPES.PAGE]: PRODUCT_SCREENS.DYNAMIC_CONTENT,
    [BACKSTAGE_MENU_ITEM_TYPES.EPG]: PRODUCT_SCREENS.EPG,
    [BACKSTAGE_MENU_ITEM_TYPES.EXTERNAL]: PRODUCT_SCREENS.EXTERNAL,
    [BACKSTAGE_MENU_ITEM_TYPES.SUBMENU]: PRODUCT_SCREENS.SUB_MENU,
    [BACKSTAGE_MENU_ITEM_TYPES.RECORDINGS]: PRODUCT_SCREENS.RECORDINGS,
    [BACKSTAGE_MENU_ITEM_TYPES.MY_LIST]: PRODUCT_SCREENS.MY_LIST,
    [BACKSTAGE_MENU_ITEM_TYPES.RECENTLY_WATCHED]: PRODUCT_SCREENS.RECENTLY_WATCHED,
    [BACKSTAGE_MENU_ITEM_TYPES.MY_PURCHASES]: PRODUCT_SCREENS.MY_PURCHASES,
    [BACKSTAGE_MENU_ITEM_TYPES.MANAGE_DEVICES]: PRODUCT_SCREENS.MANAGE_DEVICES,
    [BACKSTAGE_MENU_ITEM_TYPES.ACCOUNT_DETAILS]: PRODUCT_SCREENS.ACCOUNT_DETAILS,
    [BACKSTAGE_MENU_ITEM_TYPES.SUBSCRIPTIONS]: PRODUCT_SCREENS.MY_SUBSCRIPTIONS,
    [BACKSTAGE_MENU_ITEM_TYPES.SIGN_OUT]: PRODUCT_SCREENS.SIGN_OUT,
    [BACKSTAGE_MENU_ITEM_TYPES.SIGN_IN]: PRODUCT_SCREENS.SIGN_IN,
    [BACKSTAGE_MENU_ITEM_TYPES.SIGN_UP]: PRODUCT_SCREENS.SIGN_UP,
    [BACKSTAGE_MENU_ITEM_TYPES.SIGN_UP_QR]: PRODUCT_SCREENS.SIGN_UP_QR,
    [BACKSTAGE_MENU_ITEM_TYPES.LANGUAGE]: PRODUCT_SCREENS.LANGUAGE,
    [BACKSTAGE_MENU_ITEM_TYPES.TECHNICAL_INFO]: PRODUCT_SCREENS.TECHNICAL_INFO,
    [BACKSTAGE_MENU_ITEM_TYPES.TABS]: PRODUCT_SCREENS.FALLBACK,
    [BACKSTAGE_MENU_ITEM_TYPES.CATCHUP]: PRODUCT_SCREENS.FALLBACK,
    [BACKSTAGE_MENU_ITEM_TYPES.REMINDER]: PRODUCT_SCREENS.FALLBACK,
    [BACKSTAGE_MENU_ITEM_TYPES.DOWNLOADS]: PRODUCT_SCREENS.FALLBACK,
    [BACKSTAGE_MENU_ITEM_TYPES.APP_VERSION]: PRODUCT_SCREENS.FALLBACK,
    [BACKSTAGE_MENU_ITEM_TYPES.STREAMING]: PRODUCT_SCREENS.STREAMING,
    [BACKSTAGE_MENU_ITEM_TYPES.DOWNLOADS_SETTINGS]: PRODUCT_SCREENS.FALLBACK,
    [BACKSTAGE_MENU_ITEM_TYPES.DEVELOP_SUPPORT]: PRODUCT_SCREENS.FALLBACK,
    [BACKSTAGE_MENU_ITEM_TYPES.DEVICE_INGO]: PRODUCT_SCREENS.FALLBACK,
    [BACKSTAGE_MENU_ITEM_TYPES.SERVICE_SWITCHER]: PRODUCT_SCREENS.SERVICE,
    [BACKSTAGE_MENU_ITEM_TYPES.THEME_SWITCHER]: PRODUCT_SCREENS.THEME,
    [BACKSTAGE_MENU_ITEM_TYPES.PAYMENT_HISTORY]: PRODUCT_SCREENS.PAYMENT_HISTORY,
    [BACKSTAGE_MENU_ITEM_TYPES.PIN]: PRODUCT_SCREENS.ACCOUNT_PARENTAL_CONTROLS,
    [BACKSTAGE_MENU_ITEM_TYPES.PAYMENT_METHOD]: PRODUCT_SCREENS.PAYMENT_METHOD,
};

// We need a unique slug set in Backstage for Settings so text pages can be used for both text and settings
const SETTINGS_PAGE_SLUG = 'settings-page';
const HOME_PAGE_SLUG = 'home';
export class BackstageAppStructureDataClient
    extends BackstageApiBase
    implements AppStructureDataClient
{
    async fetchMenu(menuId?: string, config?: AppSettings): Promise<MenuItem[]> {
        try {
            const menusResponse = await this.request({
                path: '/menus',
                method: 'GET',
                guard: BackstageMenuResponseGuard,
            });

            const mainMenu = this.selectMenu(menusResponse, menuId) ?? menusResponse[0];
            // ensure no duplicates on slugs
            const slugs: string[] = [];
            const notDuplicatedMainMenu = {
                ...mainMenu,
                items: mainMenu.items.filter((item) => {
                    const present = slugs.indexOf(item.slug) > -1;
                    if (!present) {
                        slugs.push(item.slug);
                    }
                    return !present;
                }),
            };
            if (mainMenu.items.length !== notDuplicatedMainMenu.items.length) {
                console.warn(`ATTENTION menu ${mainMenu.id} has duplicated items in backstage`);
            }
            //
            const profileDropdownMenu = isPlatformWeb
                ? this.selectMenu(menusResponse, config?.features?.menus?.user)
                : null;
            const footerMenu = isPlatformWeb
                ? this.selectMenu(menusResponse, config?.features?.menus?.footer)
                : null;

            return [
                ...this.backstageMenuToMenuItems(notDuplicatedMainMenu),
                ...this.backstageMenuToMenuItems(
                    profileDropdownMenu,
                    this.backstageMenuItemToProfileDropdownItem
                ),
                ...this.backstageMenuToMenuItems(
                    footerMenu,
                    this.backstageMenuItemToFooterMenuItem
                ),
            ];
        } catch (error) {
            log('ERROR FETCHING MENU', error);
            throw error;
        }
    }

    backstageMenuToMenuItems = (
        menu: BackstageMenu | null,
        mapFunction = this.backstageMenuItemToMenuItem
    ): MenuItem[] => {
        if (!menu) return [];
        const menuScreens = menu.items
            .map(mapFunction)
            // Because we are paranoid :D
            .map(createMenuItem);

        return menuScreens;
    };

    backstageMenuItemToMenuItem = (item: BackstageMenuItem): MenuItem => {
        const { slug: name, label: title, icon, reference, items } = item;

        const screenTypeToUse = this.getScreenTypeToUse(item);

        return {
            name,
            icon: icon || 'menu',
            title,
            reference,
            screenTypeToUse,
            embeddedMenuItems: items?.map(this.backstageMenuItemToMenuItem),
        };
    };

    backstageMenuItemToProfileDropdownItem = (menuItem: BackstageMenuItem): MenuItem => {
        return {
            ...this.backstageMenuItemToMenuItem(menuItem),
            preferredWebMenuPlacement: 'profile_dropdown',
        };
    };

    backstageMenuItemToFooterMenuItem = (menuItem: BackstageMenuItem): MenuItem => {
        return {
            ...this.backstageMenuItemToMenuItem(menuItem),
            preferredWebMenuPlacement: 'footer',
        };
    };

    getScreenTypeToUse = ({ slug, type }: BackstageMenuItem) => {
        if (slug === SETTINGS_PAGE_SLUG) return PRODUCT_SCREENS.SETTINGS;
        if (slug === HOME_PAGE_SLUG) return PRODUCT_SCREENS.HOME;
        // Setting the screen type according to the type that comes from the backstage
        const screenTypeToUse = API_SCREEN_TYPE_TO_PRODUCT_SCREEN_MAP[type];

        // If not matching any type it is a custom screen
        return screenTypeToUse ?? PRODUCT_SCREENS.CUSTOM;
    };

    selectMenu = (menus: BackstageMenu[], menuId?: string | null) => {
        return menus.find(({ id }) => id === menuId) ?? null;
    };
}

export const createBackstageAppStructureDataClient = (params: BaseApiParams) => {
    return new BackstageAppStructureDataClient(params);
};
