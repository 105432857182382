/*
@PRODUCT_FEATURE: G 1.8
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, FlatList } from '@24i/nxg-sdk-quarks';
import TabButton from './TabButton';

const noop = () => {};

const Item = ({ children }) => children;

const defaultTabButtonRender = (props) => <TabButton {...props} />;

export default class TabBar extends Component {
    static getDerivedStateFromProps({ initialTab }, { activeTab, initialTab: prevInitialTab }) {
        if (initialTab && initialTab !== prevInitialTab && activeTab !== initialTab) {
            return { activeTab: initialTab, initialTab };
        }
        return null;
    }

    static propTypes = {
        initialTab: PropTypes.number,
        children: PropTypes.node,
        renderTabButton: PropTypes.func,
        tabBarStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]),
        onTabChange: PropTypes.func,
    };

    static defaultProps = {
        initialTab: 0,
        children: [],
        renderTabButton: defaultTabButtonRender,
        tabBarStyle: {},
        onTabChange: noop,
    };

    constructor(props) {
        super(props);
        const { initialTab: propInitialTab, children } = props;
        const firstChild = React.Children.toArray(children)[0] || { props: { tabName: '' } };
        const initialTab = propInitialTab || firstChild.props.tabName;
        // eslint-disable-next-line react/no-unused-state
        this.state = { activeTab: initialTab, initialTab };
    }

    onTabPress(activeTab) {
        const { activeTab: currentActiveTab } = this.state;
        if (currentActiveTab === activeTab) return;
        this.setState({ activeTab }, () => {
            const { onTabChange } = this.props;
            onTabChange(activeTab);
        });
    }

    static Item = Item;

    prepareChildren() {
        const { activeTab } = this.state;
        const { children, renderTabButton } = this.props;
        const reactChildren = React.Children.toArray(children);
        const reactChildrenLength = reactChildren.length;
        const tabs = [];
        const tabIds = [];
        reactChildren.forEach((child, index) => {
            if (child.type !== Item) throw new Error('Children of TabBar can be only TabBar.Item');
            const { tabName } = child.props;
            if (tabIds.indexOf(tabName) !== -1)
                throw new Error('TabBar.Item tabName must be unique within a TabBar');
            tabIds.push(tabName);
            tabs.push(
                renderTabButton({
                    tabName,
                    key: tabName,
                    onPress: () => this.onTabPress(tabName),
                    isActive: tabName === activeTab,
                    isFirst: index === 0,
                    isLast: index + 1 === reactChildrenLength,
                })
            );
        });
        const activeTabIndex = tabIds.indexOf(activeTab);
        const tabBody = reactChildren[activeTabIndex === -1 ? 0 : activeTabIndex] || null;
        return { tabs, tabBody };
    }

    render() {
        const { tabs, tabBody } = this.prepareChildren();
        const { tabBarStyle } = this.props;
        const { activeTab } = this.state;
        return (
            <View style={{ justifyContent: 'center', alignItems: 'stretch' }}>
                <View style={tabBarStyle}>
                    <FlatList data={tabs} renderItem={({ item }) => item} horizontal />
                </View>
                <View key={activeTab}>{tabBody}</View>
            </View>
        );
    }
}
