import { getFont } from '@24i/nxg-sdk-higgs';
import { ThemeScreenStyles } from './types';

const getThemeScreenStyles = (theme): ThemeScreenStyles => ({
    description: {
        color: theme.color.textPrimary,
        marginBottom: 32,
        opacity: 0.6,
        ...getFont(theme, 'body1'),
    },
    descriptionLandscape: {
        marginHorizontal: 32,
        width: '57%',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingVertical: 0,
        height: 'auto',
        marginBottom: 10,
    },
    buttonContainerLandscape: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingVertical: 0,
        height: 'auto',
        marginHorizontal: 32,
        marginBottom: 10,
    },
    buttonText: {
        marginHorizontal: 8,
        marginBottom: 0,
        ...getFont(theme, 'h4'),
    },
    buttonIcon: {
        fontSize: getFont(theme, 'h4').fontSize,
        marginHorizontal: 0,
        marginRight: 8,
    },
});

export default getThemeScreenStyles;
