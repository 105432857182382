import { delay } from '@24i/nxg-core-utils';

export const chance = (percentage: number) => {
    const d = Math.random() * 100;
    return d < percentage;
};

export const getRandomInt = (min: number, max: number) => {
    // eslint-disable-next-line no-param-reassign
    min = Math.ceil(min);
    // eslint-disable-next-line no-param-reassign
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const fakeBackend = async (chancePercentage: number, delayInMS = 500) => {
    await delay(getRandomInt(0, delayInMS));

    if (chance(chancePercentage)) {
        throw new Error('Something went wrong please try again later');
    }
};
