import React from 'react';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Image, Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton/View';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { getStyles } from './styles';
import { SOTT_DEFAULT_SCREENS, SOTT_DEFAULT_WEB_SCREENS } from '../../navigation/constants';
import { SIGNUP_SUCCESS_TEST_IDS } from './types';

const SignUpSuccessScreen = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const router = useRouter();

    const handleOnContinuePress = () => {
        if (isPlatformWeb) {
            const signin = SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink();
            router.push(signin.href, signin.as);

            return;
        }

        navigation.navigate(SOTT_DEFAULT_SCREENS.SIGN_IN);
    };

    return (
        <View style={styles.wrapper}>
            <Image
                source={typeof theme.logo === 'number' ? theme.logo : { uri: theme.logo }}
                style={styles.logo}
                resizeMode="contain"
            />
            <Text style={styles.title}>{t('register.addConfirm.title')}</Text>
            <Text style={styles.message}>{t('register.addConfirm.description')}</Text>
            <View style={styles.continueButtonWrapper}>
                <ActionButton
                    title={t('common.continue')}
                    onPress={handleOnContinuePress}
                    additionalContainerStyles={styles.actionButtonContainer}
                    testID={SIGNUP_SUCCESS_TEST_IDS.REGISTER_SUCCESS_CONTINUE_BUTTON}
                />
            </View>
        </View>
    );
};

export default SignUpSuccessScreen;
