import moment from 'moment';
import { Payload, BaseResult } from '../types';

const formatDeviceTimezone = (hours: number): string => {
    if (!Number.isFinite(hours)) return '+00:00';
    const sign = hours >= 0 ? '+' : '-';
    const formatted = moment.utc(Math.abs(hours) * 60 * 60 * 1000).format('HH:mm');
    return sign + formatted;
};

export const mapBase = (payload: Payload): BaseResult => ({
    session_id: payload.payload.sessionId,
    timestamp_initiated: moment.utc(payload.payload.timestamp).format('YYYY-MM-DDTHH:mm:ss+00:00'),
    device_id: payload.deviceId,
    device_type: payload.deviceType,
    device_platform: payload.platform,
    device_timezone: formatDeviceTimezone(payload.deviceTimeZone),
    service_id: payload.serviceId,
    user_id: payload.userId,
    user_profile_id: payload.userProfileId,
    ...(!payload.userId && { user_anon_id: payload.deviceId }),
});
