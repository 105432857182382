import { TextStyle, ViewStyle } from 'react-native';
import { isPlatformWeb } from 'renative';
import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';

interface ManageButtonStyle {
    wrapper: ViewStyle;
    container: ViewStyle;
    title: TextStyle;
}

const ManageButton = (theme: Theme): ManageButtonStyle => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: isPlatformWeb ? 0 : 20,
        marginTop: isPlatformWeb ? 0 : 20,
        height: 42,
        borderRadius: 30,
        backgroundColor: theme.color.buttonSecondary,
        paddingLeft: 20,
        paddingRight: 20,
    },
    wrapper: {
        height: '100%',
        justifyContent: 'flex-end',
    },
    title: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body1'),
        fontSize: 16,
        marginLeft: 5,
        lineHeight: 21,
    },
});

export default ManageButton;
