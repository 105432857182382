import { Theme } from '@24i/nxg-sdk-photon';
import { CurrentSubscriptionsStyles } from './types';

const getStyles = (theme: Theme): CurrentSubscriptionsStyles => ({
    wrapper: {
        backgroundColor: theme.color.background1,
    },
    divider: {
        height: 0.5,
        backgroundColor: theme.color.lighter3,
        opacity: 0.6,
    },
    subscriptionItemWrapper: {
        marginBottom: 24,
    },
    addSubscriptionButtonWrapper: {
        marginTop: 24,
        flexDirection: 'row',
        alignSelf: 'flex-start',
    },
    addSubscriptionButton: {
        width: 'auto',
        paddingHorizontal: 16,
        height: 52,
    },
    additionalContainerStyles: {
        width: 'auto',
        paddingHorizontal: 16,
        height: 52,
    },
    additionalTextStyles: {
        textTransform: 'uppercase',
    },
    loader: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});

export default getStyles;
