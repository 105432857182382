import { useMutation } from 'react-query';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useFirebase } from '../../../context/Firebase';

export const useValidatePinQuery = () => {
    const client = useUserData();
    const { recordError } = useFirebase();

    const { data, mutate, isLoading } = useMutation(
        (test: string | null) => client.validatePin(test),
        {
            onError: (error) => {
                log(error);
                if (recordError) recordError(error);
            },
        }
    );

    return {
        isPinCodeValid: !!data?.isPinCodeValid,
        isLoading,
        validatePinCode: mutate,
    };
};
