import { useEffect, useMemo, useRef, useState } from 'react';
import { useAdobePrimetime } from '@24i/adobe-primetime/src/shared/context/useAdobePrimetime';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { AdobePrimetimeFeatureGuard } from '@24i/adobe-primetime/src/shared/types';
import { BASE_SP_URL_PRODUCTION, REDIRECT_URL, REGISTERED_DOMAIN } from '../constants';

export const createAuthenticationUrl = (
    requestorId: string,
    domainName: string,
    redirectUrl: string,
    mvpdId: string,
    regCode: string
) => {
    return [
        `http://${BASE_SP_URL_PRODUCTION}/api/v1/authenticate`,
        `?&reg_code=${regCode}&requestor_id=${requestorId}`,
        `&domain_name=${domainName}&mso_id=${mvpdId}`,
        `&noflash=true&${encodeURIComponent(redirectUrl)}`,
    ].join();
};

export const useViewModel = (props) => {
    const {
        authState: { initialised, authenticated },
        initialiseAdobePrimetimeSDK,
        mvpds,
        mvpds: { allMvpds },
    } = useAdobePrimetime();

    const [regCode, setRegCode] = useState('');
    const [providerId, setProviderId] = useState('');

    const [seeAllProviders, setSeeAllProviders] = useState(false);
    const [searchString, setSearchString] = useState('');

    const backstageConfig = useRef<AdobePrimetimeFeatureGuard | undefined>(undefined);
    const requestorId = backstageConfig?.current?.requestorId || '';
    const filteredMvpds = useMemo(() => {
        if (searchString.length) {
            return allMvpds.filter((mvpd) =>
                mvpd.title.toLowerCase().includes(searchString.toLowerCase())
            );
        }
        return allMvpds;
    }, [allMvpds, searchString]);

    const handleSelectProvider = (mvpdId: string): void => {
        setProviderId(mvpdId);
    };

    const handleSeeAllProviders = (): void => {
        setSeeAllProviders((prevState) => !prevState);
    };

    const handleSearchStringChange = (text: string): void => {
        setSearchString(text);
    };

    const handleRegCodeChange = (text: string): void => {
        setRegCode(text.toUpperCase());
    };

    const adobePrimetimeBackstageConfig = useFeature('adobePrimetime');

    useEffect(() => {
        if (!initialised) initialiseAdobePrimetimeSDK(adobePrimetimeBackstageConfig);
    }, []);

    const handleSignInPress = () => {
        if (!providerId) {
            return;
        }
        const authenticationUrl = createAuthenticationUrl(
            requestorId,
            REGISTERED_DOMAIN,
            REDIRECT_URL,
            providerId,
            regCode
        );

        window.location.assign(authenticationUrl);
    };

    const isButtonDisabled = !regCode || !providerId;

    return {
        ...props,
        regCode,
        setRegCode,
        providerId,
        setProviderId,
        authenticated,
        handleSignInPress,
        handleSelectProvider,
        handleSeeAllProviders,
        seeAllProviders,
        handleSearchStringChange,
        handleRegCodeChange,
        searchString,
        mvpds: { ...mvpds, allMvpds: filteredMvpds },
        isButtonDisabled,
    };
};
