/* eslint-disable class-methods-use-this */
import { AnalyticsConfig } from '@24i/nxg-sdk-photon';
import Tealium from 'tealium';
import TealiumCollect from 'tealium-collect';
import { TealiumResultPayload } from '../types';
import { TealiumSdk } from './TealiumSdk';

export class TealiumCollectSdk implements TealiumSdk {
    tealium: Tealium;

    init(config: AnalyticsConfig): void {
        const { account, profile, environment, datasource: dataSource } = config.options;
        const tealiumConfig = {
            account,
            profile,
            environment,
            dataSource,
        };
        this.tealium = Tealium(tealiumConfig);
        this.tealium.addModule(TealiumCollect);
    }

    async track(result: TealiumResultPayload): Promise<void> {
        if (result.track_event_type === 'view') {
            this.tealium.track('scene_view', result);
        } else if (result.app_events) {
            this.tealium.track(result.app_events, result);
        }
    }
}
