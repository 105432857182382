import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import React from 'react';
import SearchDropdownItem from '../components/SearchDropdownItem';
import getSearchDropdownStyles from '../styles';
import { SearchDropdownProps, SEARCH_RECENT_TEST_IDS } from '../types';

const noop = () => {};

const SearchDropdown = ({
    items = [],
    onDropdownItemPress = noop,
    onRemovePress = noop,
    styles: getStyles = getSearchDropdownStyles,
}: SearchDropdownProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.holder}>
            {items.map((item, index) => (
                <SearchDropdownItem
                    testID={`${SEARCH_RECENT_TEST_IDS.SEARCH_RECENT_CONTAINER}-${String(index)}`}
                    key={`${item}-${String(index)}`}
                    item={item}
                    onDropdownItemPress={onDropdownItemPress}
                    onRemovePress={onRemovePress}
                />
            ))}
        </View>
    );
};

export { getSearchDropdownStyles };

export default overridable(SearchDropdown, 'SearchDropdown');
