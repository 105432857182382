import { ANALYTICS_TRIGGERS, ANALYTICS_EVENTS } from '@24i/nxg-sdk-smartott-shared/src/analytics';

export const TWENTY_FOUR_IQ_TRIGGERS = [
    // App
    ANALYTICS_TRIGGERS.APP_CLOSE,

    // Scenes
    ANALYTICS_TRIGGERS.SCENE_VIEW,

    // Data loading / Player buffering
    ANALYTICS_TRIGGERS.SCREEN_BUFFERING_START,
    ANALYTICS_TRIGGERS.SCREEN_BUFFERING_FINISHED,

    // Playback
    ANALYTICS_TRIGGERS.PLAYBACK_10,
    ANALYTICS_TRIGGERS.PLAYBACK_25,
    ANALYTICS_TRIGGERS.PLAYBACK_50,
    ANALYTICS_TRIGGERS.PLAYBACK_75,
    ANALYTICS_TRIGGERS.PLAYBACK_90,
    ANALYTICS_TRIGGERS.PLAYBACK_PAUSE,
    ANALYTICS_TRIGGERS.PLAYBACK_START,
    ANALYTICS_TRIGGERS.PLAYBACK_STOP,

    // Player
    ANALYTICS_TRIGGERS.PLAYER_CLOSE,
    ANALYTICS_TRIGGERS.PLAYER_OPEN,

    // Scrolling in scene
    ANALYTICS_TRIGGERS.SCROLL_25,
    ANALYTICS_TRIGGERS.SCROLL_50,
    ANALYTICS_TRIGGERS.SCROLL_75,
    ANALYTICS_TRIGGERS.SCROLL_90,

    // Ads
    ANALYTICS_TRIGGERS.AD_LOADED,
    ANALYTICS_TRIGGERS.AD_SKIPPED,
];

export enum EVENTS {
    SCENE_VIEW = 'scene_view',
    BUFFERING = 'buffering',
    VIDEO_PROGRES = 'video_progress',
    VIDEO_START = 'video_start',
    VIDEO_COMPLETE = 'video_complete',
    VIDEO_STOP = 'video_stop',
    VIDEO_PAUSE = 'video_pause',
    PLAYER_CLOSE = 'player_close',
    PLAYER_OPEN = 'player_open',
    SCROLL = 'scroll',
    AD_LOADED = 'ad_loaded',
    AD_SKIPPED = 'ad_skipped',
}

export const TRIGGER_NAME_TO_24IQ_EVENTS = {
    [ANALYTICS_TRIGGERS.SCENE_VIEW]: [EVENTS.SCENE_VIEW],
    [ANALYTICS_TRIGGERS.SCREEN_BUFFERING_START]: [EVENTS.BUFFERING],
    [ANALYTICS_TRIGGERS.SCREEN_BUFFERING_FINISHED]: [EVENTS.BUFFERING],
    [ANALYTICS_TRIGGERS.PLAYBACK_10]: [EVENTS.VIDEO_PROGRES],
    [ANALYTICS_TRIGGERS.PLAYBACK_25]: [EVENTS.VIDEO_PROGRES],
    [ANALYTICS_TRIGGERS.PLAYBACK_50]: [EVENTS.VIDEO_PROGRES],
    [ANALYTICS_TRIGGERS.PLAYBACK_75]: [EVENTS.VIDEO_PROGRES],
    [ANALYTICS_TRIGGERS.PLAYBACK_90]: [EVENTS.VIDEO_COMPLETE],
    [ANALYTICS_TRIGGERS.PLAYBACK_PAUSE]: [EVENTS.VIDEO_PAUSE],
    [ANALYTICS_TRIGGERS.PLAYBACK_START]: [EVENTS.VIDEO_START],
    [ANALYTICS_TRIGGERS.PLAYBACK_STOP]: [EVENTS.VIDEO_STOP],
    [ANALYTICS_TRIGGERS.PLAYER_CLOSE]: [EVENTS.PLAYER_CLOSE],
    [ANALYTICS_TRIGGERS.PLAYER_OPEN]: [EVENTS.PLAYER_OPEN],
    [ANALYTICS_TRIGGERS.SCROLL_25]: [EVENTS.SCROLL],
    [ANALYTICS_TRIGGERS.SCROLL_50]: [EVENTS.SCROLL],
    [ANALYTICS_TRIGGERS.SCROLL_75]: [EVENTS.SCROLL],
    [ANALYTICS_TRIGGERS.SCROLL_90]: [EVENTS.SCROLL],
    [ANALYTICS_TRIGGERS.APP_CLOSE]: [EVENTS.VIDEO_STOP, EVENTS.PLAYER_CLOSE],
    [ANALYTICS_TRIGGERS.AD_LOADED]: [EVENTS.AD_LOADED],
    [ANALYTICS_TRIGGERS.AD_SKIPPED]: [EVENTS.AD_SKIPPED],
};

export const REDUNDANT_EVENTS = [ANALYTICS_EVENTS.INSTALLED_APP, ANALYTICS_EVENTS.LOADED_APP];
