import { getFont, Ratio } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { IconButtonWebStyles } from '../../IconButton/types';

const BackButton = (theme: Theme): IconButtonWebStyles => ({
    holder: {
        backgroundColor: 'transparent',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        color: theme.color.textPrimary,
        fontSize: Ratio(20),
        ...getFont(theme, 'h1'),
    },
    textFocused: {
        color: theme.color.textPrimary,
    },
});

export default BackButton;
