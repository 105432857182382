import { useThrottleEffect, useThrottleFn } from 'ahooks';

export const useScrollingWithkeys = (
    { onUpPress, onDownPress, onLeftPress, onRightPress },
    deps: any
) => {
    const listener = (e) => {
        if (e.key === 'ArrowDown') {
            onDownPress();
        } else if (e.key === 'ArrowUp') {
            onUpPress();
        } else if (e.key === 'ArrowLeft') {
            onLeftPress();
        } else if (e.key === 'ArrowRight') {
            onRightPress();
        }
    };

    const { run: onKeyDown } = useThrottleFn(listener, { wait: 300 });

    useThrottleEffect(
        () => {
            document.addEventListener('keydown', onKeyDown);
            return () => document.removeEventListener('keydown', onKeyDown);
        },
        deps,
        { wait: 100 }
    );
};
