import { useTranslation } from 'react-i18next';
import { SubscriptionStatus } from '@24i/nxg-sdk-photon/src';
import { getCurrencySymbol, CurrencyName } from '@24i/nxg-core-utils';
import formatDate from 'date-fns/format';
import { getNextSubscriptionBillingDate } from '../../../../utils/subscriptions';
import { PurchasableItemViewProps } from '../../types';
import { SubscriptionItemProps } from '../types';

export const useSubscriptionItem = ({
    item,
    onPress,
    onSecondaryButtonPress,
    showBorder,
    showTag,
    hasTransparentBackground,
    testID,
    tagText,
}: SubscriptionItemProps): PurchasableItemViewProps => {
    const { t } = useTranslation();

    const getTagText = (): string => {
        if (!showTag) return '';
        if (tagText) return tagText;
        if (item.status === SubscriptionStatus.ACTIVE) return t('svod.currentSubscriptionBadge');
        return t('svod.newSubscriptionBadge');
    };

    const currencyText = `${getCurrencySymbol(item.currency as CurrencyName)}${item.price}`;
    const priceText = item?.billingFrequency?.unit
        ? `${currencyText} / ${item?.billingFrequency?.unit}`
        : currencyText;

    const handleOnPress = () => {
        onPress?.(item.externalAccessValue);
    };

    const handleOnSecondaryButtonPress = () => {
        onSecondaryButtonPress?.(item.externalAccessValue);
    };

    const wasCancelled = item.status === SubscriptionStatus.CANCELLED;

    const billingDateText = item.expirationDate
        ? `${t('svod.manage.nextBillingDate', {
              freeTrial: wasCancelled,
          })} ${formatDate(getNextSubscriptionBillingDate(item), 'P')}`
        : '';

    return {
        tagText: getTagText(),
        itemInfoLines: [priceText],
        onPress: onPress ? handleOnPress : undefined,
        onSecondaryButtonPress: onSecondaryButtonPress ? handleOnSecondaryButtonPress : undefined,
        showBorder,
        hasTransparentBackground,
        textAfterDescription: billingDateText,
        title: item.name,
        description: item.description || '',
        secondaryButtonText: t('common.change'),
        testID,
        wasCancelled,
        isWebPayment: !!item.isWebPayment,
        paymentMethod: !!item.paymentMethod,
    };
};
