import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { omitUndefinedOrNull } from '@24i/nxg-core-utils/src/object';
import { SOTT_DEFAULT_SCREENS } from '../../navigation/constants';
import { ScreenLinkGetter } from '../../navigation/types';

const useUniversalNavigation = () => {
    const router = useRouter();

    const goTo = <TParams = void>(
        getter: ScreenLinkGetter<TParams>,
        params: TParams,
        options?: {
            method: 'push';
            screen: SOTT_DEFAULT_SCREENS;
        }
    ) => {
        const link = getter.getLink(params);

        const { method: _method, screen: _screen, ...routerOptions } = options || {};

        router.push(
            {
                pathname: link.href,
                query: omitUndefinedOrNull(link.query ?? {}),
            },
            link.as,
            routerOptions
        );
    };

    return {
        goTo,
    };
};

export default useUniversalNavigation;
