export const omitUndefinedOrNull = <K extends string | number | symbol, V>(
    obj: Record<K, V | null | undefined>
): Record<K, V> => {
    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value === null || value === undefined) {
            return acc;
        }

        acc[key] = value;

        return acc;
    }, {} as Record<K, V>);
};
