import { Theme } from '@24i/nxg-sdk-photon/src';
import { PackshotActionButtonStyles } from '../types';

const packshotActionButtonStyles = (theme: Theme): PackshotActionButtonStyles => ({
    itemActionButtonContainer: {
        position: 'absolute',
        width: '100%',
        top: 0,
        height: '100%',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemActionButton: {
        position: 'absolute',
        backgroundColor: theme.color.tileMainButton,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        height: 44,
        width: 44,
        borderRadius: 44,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    itemActionButtonHighlighted: {
        height: 72,
        width: 72,
    },
});

export default packshotActionButtonStyles;
