import { useTranslation } from 'react-i18next';
import { PLAYLIST_TYPE } from '@24i/nxg-sdk-photon';
import usePlaylistAssetsQuery from '@24i/nxg-sdk-smartott/src/hooks/query/purchases/usePlaylistAssetsQuery';
import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import { useRoute, RouteProp } from '@react-navigation/native';
import type { StackParamList } from '@24i/nxg-sdk-smartott/src/navigation/types';
import type { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { UseSharedProps } from '../types';
import { getLabelForPlaylist } from '../../../utils/index';
import { ScreenOrigin, ScreenOriginType } from '../../../navigation/types';

const useShared = (props, playlistId: PLAYLIST_TYPE): UseSharedProps => {
    const { t } = useTranslation();
    const { isLoading, data } = usePlaylistAssetsQuery(playlistId);
    const route = useRoute<RouteProp<StackParamList, SOTT_DEFAULT_SCREENS.PLAYLIST>>();

    const originFromHere: ScreenOrigin = {
        type: ScreenOriginType.PAGE,
        id: playlistId,
        label: route?.params?.title ?? `Playlist ${playlistId}`,
    };

    const { handlePackshotPress } = useAssetActions({
        origin: originFromHere,
    });

    return {
        assets: data,
        title: getLabelForPlaylist(playlistId, t),
        isLoading,
        handlePackshotPress,
        ...props,
    };
};

export default useShared;
