import React, { useEffect } from 'react';
import { View, Text, ScrollView } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import {
    useCast,
    useRemoteMediaClient,
    useMediaStatus,
} from '@24i/nxg-sdk-smartott/src/context/Cast';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import SettingsButton from '@24i/nxg-sdk-smartott/src/components/Buttons/SettingsButton';
import useSafeAreaInsets from '@24i/nxg-sdk-gluons/src/hooks/useSafeAreaInsets';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTranslation } from 'react-i18next';
import { createAudioSubtitleModalStyles } from '../styles/types';
import getAudioSubtitleStyles from '../styles';

interface getAudioSubtitleModalProps {
    styles?: (theme: Theme) => createAudioSubtitleModalStyles;
    isVisible?: boolean;
    closeModal?: () => void;
}

type optionType = {
    contentId?: string;
    contentType?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    customData?: object;
    id: number | string;
    language?: string;
    name?: string | null;
    subtype?: string;
    type?: string;
};

const AudioSubtitlesModal = ({
    styles: getStyles = getAudioSubtitleStyles,
    isVisible = false,
    closeModal = () => {},
}: getAudioSubtitleModalProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation();
    const safeAreaDimensions = useSafeAreaInsets();
    const dynamicHeaderMargin = DeviceInfo.isTablet() ? 224 : 72 - safeAreaDimensions.top;
    const { selectedSubtitle, setSubtitle, selectedAudio, setAudio } = useCast();
    const client = useRemoteMediaClient();
    const status = useMediaStatus();
    const mediaTracks = status?.mediaInfo?.mediaTracks;
    const audio: Array<optionType> | undefined = mediaTracks
        ? mediaTracks?.filter((option) => option.type === 'audio')
        : [];
    const subtitles: Array<optionType> = mediaTracks
        ? mediaTracks?.filter((option) => option.type === 'text')
        : [];

    const subtitlesWithNoneOption: Array<optionType> = [
        { id: 'off', name: t('commmon.none') },
        ...subtitles,
    ];

    const onSelectAudio = (option: optionType) => {
        if (typeof option.id === 'string') return;
        if (selectedSubtitle === 'off' && typeof option.id === 'number') {
            setAudio(option.id);
            client?.setActiveTrackIds([option.id]);
            return;
        }
        setAudio(option.id);
        client?.setActiveTrackIds([option.id, selectedSubtitle]);
    };

    const onSelectSubtitle = (option: optionType) => {
        if (option.id !== 'off' && typeof option.id === 'number') {
            client?.setActiveTrackIds([option.id]);
            setSubtitle(option.id);
        } else {
            client?.setActiveTrackIds([]);
            setSubtitle(option.id);
        }
    };

    const setActiveMediaTrack = (option: optionType) => {
        if (option.type === 'audio') {
            onSelectAudio(option);
            return;
        }
        onSelectSubtitle(option);
    };

    useEffect(() => {
        if (audio.length === 0) return;
        if (typeof audio[0].id === 'number') {
            setAudio(audio[0].id);
        }
    }, []);

    const optionPicker = (
        options: Array<optionType> | undefined,
        selectedOption: number | undefined,
        onPress: (option: optionType) => void
    ) => {
        if (mediaTracks) {
            return (
                <ScrollView showsVerticalScrollIndicator={false}>
                    {options?.map((option) => {
                        return (
                            <>
                                <View key={option?.name} style={styles.optionContainer}>
                                    <SettingsButton
                                        iconName="onPrimary-confirm"
                                        iconSize={24}
                                        title={option?.name || option?.language}
                                        onPress={() => {
                                            onPress(option);
                                        }}
                                        additionalIconStyles={[
                                            styles.buttonIcon,
                                            { opacity: option.id === selectedOption ? 1 : 0 },
                                        ]}
                                        additionalTextStyles={[
                                            styles.buttonText,
                                            { opacity: option.id === selectedOption ? 1 : 0.6 },
                                        ]}
                                        additionalContainerStyles={styles.buttonContainer}
                                    />
                                </View>
                                <View style={styles.optionDivider} />
                            </>
                        );
                    })}
                </ScrollView>
            );
        }
        return null;
    };

    return (
        <Modal
            styles={() => styles.modal}
            visible={isVisible}
            onRequestClose={() => {
                closeModal();
            }}
        >
            <View style={[styles.container, { paddingTop: dynamicHeaderMargin }]}>
                <View style={[styles.optionPickerContainer, styles.optionPickerAudio]}>
                    <Text style={styles.title}>{t('player.audio')}</Text>
                    {optionPicker(audio, selectedAudio, setActiveMediaTrack)}
                </View>

                <View style={styles.optionPickerContainer}>
                    <Text style={styles.title}>{t('player.subtitles')}</Text>
                    {optionPicker(subtitlesWithNoneOption, selectedSubtitle, setActiveMediaTrack)}
                </View>
            </View>
        </Modal>
    );
};

export { getAudioSubtitleStyles };
export default overridable(AudioSubtitlesModal, 'AudioSubtitlesModal');
