import React, { Fragment, useRef, useState, useEffect } from 'react';
import { PanResponder, Animated, StyleSheet } from 'react-native';
import { Dimensions } from '@24i/nxg-sdk-quantum';
import PropTypes from 'prop-types';
import { View, Interactable } from '@24i/nxg-sdk-quarks';

const ANIMATION_DURATION = 250;

const DrawerMenuWrapper = ({ renderMenu, renderScreen }) => {
    const { width: screenWidth } = Dimensions.get('window');
    const animatedRightRef = useRef(new Animated.Value(-screenWidth));
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const menuWrapperRef = useRef();

    const [width, setWidth] = useState(0);
    const [panResponder, setPanResponder] = useState({ handlers: {} });

    const openDrawerMenuAnimation = () => {
        Animated.timing(animatedRightRef.current, {
            toValue: 0,
            duration: ANIMATION_DURATION,
            useNativeDriver: true,
        }).start();
    };

    const closeDrawerMenuAnimation = (onAnimationEnd) => {
        Animated.timing(animatedRightRef.current, {
            toValue: -width,
            duration: ANIMATION_DURATION,
            useNativeDriver: true,
        }).start(onAnimationEnd);
    };

    const openDrawer = () => {
        setIsMenuVisible(true);
        openDrawerMenuAnimation();
    };

    const closeDrawer = () => {
        closeDrawerMenuAnimation(() => setIsMenuVisible(false));
    };

    const onLayout = ({ nativeEvent: { layout } }) => {
        setWidth(layout.width);
        animatedRightRef.current.setValue(-layout.width);
    };

    useEffect(() => {
        setPanResponder(
            PanResponder.create({
                onMoveShouldSetPanResponder: () => true,
                onPanResponderRelease: (evt, gestureState) => {
                    const { moveX } = gestureState;
                    const thresholdOpenClose = width / 2;
                    if (moveX < thresholdOpenClose) {
                        closeDrawer();
                    } else {
                        openDrawer();
                    }
                },
                onPanResponderMove: (evt, gestureState) => {
                    const { moveX } = gestureState;
                    if (moveX <= width) {
                        animatedRightRef.current.setValue(moveX - width);
                    }
                },
            })
        );
    }, [width]);

    return (
        <View style={{ flex: 1 }}>
            <>
                {renderScreen({
                    openDrawer,
                    closeDrawer,
                    isMenuVisible,
                })}
                {isMenuVisible && (
                    <Interactable
                        onPress={closeDrawer}
                        style={StyleSheet.absoluteFillObject}
                        activeOpacity={1}
                    />
                )}
            </>
            <Animated.View
                {...panResponder.panHandlers}
                ref={menuWrapperRef}
                onLayout={onLayout}
                style={{
                    position: 'absolute',
                    transform: [{ translateX: animatedRightRef.current }],
                    height: '100%',
                    top: 0,
                    left: 0,
                }}
            >
                {renderMenu({
                    openDrawer,
                    closeDrawer,
                    isMenuVisible,
                })}
                <Animated.View
                    {...panResponder.panHandlers}
                    style={{
                        width: 20,
                        height: '100%',
                        right: -20,
                        position: 'absolute',
                        backgroundColor: 'transparent',
                    }}
                />
            </Animated.View>
        </View>
    );
};

DrawerMenuWrapper.defaultProps = {
    renderMenu: () => null,
    renderScreen: () => null,
};

DrawerMenuWrapper.propTypes = {
    renderMenu: PropTypes.func,
    renderScreen: PropTypes.func,
};

export default DrawerMenuWrapper;
