import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { WithPurchaseSubscriptionScreenQuery } from './types';
import { useSubscriptionPurchaseScreen } from '../../context/SubscriptionsHandlerProvider';
import { useViewModel } from './viewModel';

export default {
    Main: (props: WithPurchaseSubscriptionScreenQuery & Partial<ScreenProps>) => {
        const { Main } = useSubscriptionPurchaseScreen();
        const viewModelProps = useViewModel(props);
        return Main(viewModelProps);
    },

    View: () => {
        const { View } = useSubscriptionPurchaseScreen();
        return View;
    },
};
