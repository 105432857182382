import React, { useState, useEffect } from 'react';
import { Dimensions } from '@24i/nxg-sdk-quantum';
import PropTypes from 'prop-types';
import { Interactable } from '@24i/nxg-sdk-quarks';
import PortraitMode from './PortraitMode';

const detail = ({ onBack }) => <>{onBack && <Interactable onPress={onBack} />}</>;

const MasterDetail = (props) => {
    const { options, forceLandscapeMode, forcePortraitMode, extraDetailParams } = props;
    const [dimensions, setDimensions] = useState(Dimensions.get('window'));
    const { width, height } = dimensions;
    const isLandscape = (forceLandscapeMode || width > height) && !forcePortraitMode;
    const [selectedOption, setSelectedOption] = useState();
    const defaultOption = selectedOption || options[0];
    const setDimensionsCallback = (args) => {
        setDimensions(args.window);
    };

    useEffect(() => {
        Dimensions.addEventListener('change', setDimensionsCallback);
        return () => {
            Dimensions.removeEventListener('change', setDimensionsCallback);
        };
    }, []);

    return (
        <PortraitMode
            isLandscape={isLandscape}
            selectedOption={selectedOption}
            width={width}
            defaultOption={defaultOption}
            setSelectedOption={setSelectedOption}
            extraDetailParams={extraDetailParams}
            {...props}
        />
    );
};

MasterDetail.defaultProps = {
    options: [],
    renderMasterWrapper: ({ children }) => children,
    renderDetail: detail,
    forceLandscapeMode: false,
    forcePortraitMode: false,
};

MasterDetail.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
        })
    ),
    renderMasterWrapper: PropTypes.func,
    renderDetail: PropTypes.func,
    forceLandscapeMode: PropTypes.bool,
    forcePortraitMode: PropTypes.bool,
};

export default MasterDetail;
