import { DeviceInfo, useDimensions } from '@24i/nxg-sdk-quantum';
import { ORIENTATIONS } from '@24i/nxg-core-utils/src/constants';
import { SliderComponentProps } from '../types';

const useViewModel = (props: SliderComponentProps): SliderComponentProps => {
    const { isLandscape } = useDimensions();
    const orientation = isLandscape ? ORIENTATIONS.LANDSCAPE : ORIENTATIONS.PORTRAIT;
    const isPortraitPhone = !DeviceInfo.isTablet() && orientation === ORIENTATIONS.PORTRAIT;

    return {
        ...props,
        isPortraitPhone,
    };
};

export { useViewModel };
