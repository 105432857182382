import { Broadcast } from '@24i/nxg-sdk-photon/src';
import { BackstageAsset } from './clients/ContentDataClient/guards';

export const isObject = (arg0: any): arg0 is Record<string, any> => {
    return typeof arg0 === 'object' && arg0 !== null && arg0 !== undefined;
};

/**
 * Backstage API returns an empty array instead of an empty object for properties which should be an object.
 * This function sanitize those properties and assing an empty object instead of an ampty array
 * so we don't have to deal with down the road.
 */
export const replaceEmptyArraysWithEmptyObjects =
    <T>(propertiesToCheck: string[]) =>
    (responseObj: Record<string, any>): T => {
        if (!responseObj) {
            return responseObj as T;
        }

        if (!Array.isArray(responseObj) || !isObject(responseObj)) return responseObj as T;

        return Object.entries(responseObj).reduce((acc, [key, value]) => {
            if (propertiesToCheck.includes(key) && Array.isArray(value) && value.length === 0) {
                acc[key] = {};

                return acc;
            }

            if (Array.isArray(value)) {
                acc[key] = value.map(replaceEmptyArraysWithEmptyObjects(propertiesToCheck));
            } else if (isObject(value)) {
                acc[key] = replaceEmptyArraysWithEmptyObjects(propertiesToCheck)(value);
            } else {
                acc[key] = value;
            }

            return acc;
        }, {}) as T;
    };

export const getLiveAssetTimeInformation = (
    asset: BackstageAsset | Broadcast
): {
    start: number;
    end: number;
    isLive: boolean;
    duration: number;
    progress?: number;
    timeLeft?: number;
} => {
    const currentMins = Date.now();
    let progress = 0 as number;
    const start = (asset?.startsAt || 0) * 1000;
    const end = (asset?.endsAt || 0) * 1000;
    const duration = (end - start) / 1000 / 60;
    const isLive = currentMins > start && currentMins < end;
    const timeLeft = isLive ? (end - currentMins) / 1000 / 60 : 0;

    if (duration && timeLeft) {
        progress = (duration - timeLeft) / duration;
    }
    return {
        start,
        end,
        duration,
        progress,
        isLive,
        timeLeft,
    };
};
