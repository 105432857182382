import { useEffect } from 'react';
import { useComponentWillMount } from '@24i/nxg-core-utils';
import useShared from './useShared';
import { SearchScreenProps, SearchViewProps } from '../types';

const useViewModel = (props: SearchScreenProps): SearchViewProps => {
    const shared = useShared(props);

    const {
        query,
        setQuery,
        items,
        setItems,
        isSearchBarFocused,
        isLoading,
        pastSearches,
        onDropdownItemRemovePress,
        getPrevSearches,
        onSearchBarFocus,
        onSearchBarBlur,
        canSearch,
        suggestedQueries,
        setSuggestedQueries,
        onChangeText,
        onDropdownItemPress,
        prevSearchesLength = 5,
        getAssets,
        recordError,
        setIsLoading,
        updatePrevSearches,
    } = shared;

    useEffect(() => {
        getPrevSearches();
    }, []);

    const onSubmitEditing = async () => {
        if (canSearch) {
            const prevSearches = [query, ...(await getPrevSearches())];
            prevSearches.length = Math.min(prevSearches.length, prevSearchesLength);
            await updatePrevSearches(prevSearches);
        }
    };

    const onCancelPress = () => {
        setQuery('');
        setItems([]);
        setSuggestedQueries([]);
    };

    useEffect(() => {
        if (!window.location.hash.includes('search')) {
            window.location.hash += `&search=`;
        }
    }, []);

    useComponentWillMount(async () => {
        const search = new URLSearchParams(window.location.hash.replace('#', '?')).get('search');
        if (search && search.length) {
            setIsLoading(true);
            setQuery(search);
            try {
                const assets = await getAssets(search);

                setItems([...assets]);
            } catch (error) {
                recordError?.(error);
            } finally {
                setIsLoading(false);
            }
        }
    });

    return {
        ...shared,
        query,
        items,
        isSearchBarFocused,
        pastSearches,
        isLoading,
        canSearch,
        suggestedQueries,
        onDropdownItemRemovePress,
        onDropdownItemPress,
        onCancelPress,
        onSearchBarBlur,
        onSearchBarFocus,
        onChangeText,
        onSubmitEditing,
        ...props,
    };
};

export { useViewModel };
