import React from 'react';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { SOTT_HOME_FALLBACK_REFERENCE } from '../../../navigation/constants';
import DynaRowScreen from '../../DynaRowScreen';
import { HomeViewProps } from '../types';

const Main = ({ reference, ...props }: HomeViewProps) => {
    const { t } = useTranslation();

    if (reference === SOTT_HOME_FALLBACK_REFERENCE) {
        // TODO: Proper homepage fallback
        return (
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    height: '100%',
                }}
            >
                <Text
                    style={{
                        color: 'white',
                        width: '100%',
                        textAlign: 'center',
                        fontSize: 20,
                        padding: 15,
                        maxWidth: 500,
                    }}
                >
                    {t('error.B100.body')}
                </Text>
            </View>
        );
    }

    return <DynaRowScreen.Main {...props} />;
};

export default Main;
