import memoize from 'memoizee';
import React, { forwardRef, useContext } from 'react';
import { Dimensions } from 'react-native';
import StyleSheet from '../StyleSheet';
import { ThemeContext } from '../context/Theme';
import { DEFAULT_BREAKPOINTS } from '../constants';
import { isEmptyString, isInCorrectBucket, sortSizeBuckets, transformToArray } from '../utils';

const flattenAndCreateStyleSheet = (style) => {
    const result = { ...style };
    Object.keys(result).forEach((key) => {
        result[key] = StyleSheet.flatten(result[key]);
    });
    return result;
};

const createStyleSheet = (styles) => {
    if (styles instanceof Array) {
        const mergedStyles = Object.assign({}, ...styles);
        return flattenAndCreateStyleSheet(mergedStyles);
    }
    return flattenAndCreateStyleSheet(styles);
};

const createFlatArray = (...args) => {
    let result: unknown[] = [];
    args.forEach((a) => {
        if (a instanceof Array) {
            result = [...result, ...a];
        } else {
            result.push(a);
        }
    });
    return result;
};

const getSizeBucket = () => {
    const { width } = Dimensions.get('window');
    const breakPoints = DEFAULT_BREAKPOINTS;
    const sizesArray = transformToArray(breakPoints);
    const sortedArray = sortSizeBuckets(sizesArray);

    let sizeBucket = 'default';

    for (let i = 0, max = sortedArray.length; i < max; i++) {
        const size = sortedArray[i];

        if (isInCorrectBucket(width, size, sortedArray[i + 1])) {
            sizeBucket = size.key;
            break;
        }
    }

    return sizeBucket;
};

const mergeStyles = memoize(
    (theme, styles, themeNamespace) => {
        const themeStyles = theme?.components?.[themeNamespace];
        if (!styles && !themeStyles) return undefined;
        if (!styles) return createStyleSheet(themeStyles);
        if (!themeStyles) return createStyleSheet(styles);
        const ssStyles = createStyleSheet(styles);
        const ssThemeStyles = createStyleSheet(themeStyles);
        const mergedStyles = {};
        Object.keys(ssThemeStyles).forEach((key) => {
            mergedStyles[key] = createFlatArray(ssThemeStyles[key], ssStyles[key]);
        });
        return mergedStyles;
    },
    {
        primitive: true,
        normalizer: (params) =>
            `${params[0]}${JSON.stringify(params[1])}${params[2]}${getSizeBucket()}`,
    }
);

export const withTheme = <TProps extends { styles: unknown }>(
    Component: React.ComponentType<TProps>,
    themeNamespace: string | string[]
) => {
    const ComponentWithTheme = forwardRef((props: TProps, ref) => {
        if (isEmptyString(themeNamespace))
            throw new Error(`Theme namespace is missing for ${Component}`);

        const { theme, setTheme } = useContext(ThemeContext);

        // eslint-disable-next-line
        const styles = mergeStyles(theme, props.styles, themeNamespace);

        return <Component {...props} styles={styles} setTheme={setTheme} theme={theme} ref={ref} />;
    });

    ComponentWithTheme.displayName = `withTheme(${
        Component.displayName || Component.name || 'Component'
    }+${themeNamespace})`;

    return ComponentWithTheme;
};
