import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const DecodedTokenGuard = createGuard('DecodeToken', {
    id: t.union([t.string, t.number]),
    username: t.string,
    profileId: t.string,
    token: t.string,
    externalToken: nullable(t.string),
    refreshToken: t.string,
});

export type DecodedToken = t.TypeOf<typeof DecodedTokenGuard>;

export const createDecodedToken = (data: unknown): DecodedToken | never => {
    return decodeModelWith(DecodedTokenGuard, data, 'DecodedToken');
};
