import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks/src';
import React, { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import getDefaultStyles from '../styles/index.web';
import { webViewModelReturnType } from '../types';

function Toggle(props: webViewModelReturnType): ReactElement {
    const {
        handlePress,
        size = 'M',
        thumbColor,
        trackColor,
        isActive,
        styles: getStyles = getDefaultStyles,
        ...rest
    } = props;

    const { theme } = useTheme();

    const styles = getStyles(theme, {
        size,
        isActive,
        thumbColor,
        trackColor,
    });

    const { style } = rest;

    return (
        <TouchableOpacity onPress={handlePress} {...rest} style={[styles.wrapper, style]}>
            <View style={styles.thumb} />
        </TouchableOpacity>
    );
}

export default Toggle;
