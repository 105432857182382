import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs/src';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { ShareDropdownStyles } from '../types';

const getStyles = (theme: Theme): ShareDropdownStyles => ({
    container: {
        width: 224,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            width: 'auto',
            alignItems: 'flex-start',
        },
        backgroundColor: theme.color.darker1,
        position: 'absolute',
        top: 70,
        left: 4,
        borderRadius: 4,
        paddingVertical: 4,
    },
    itemContainer: {
        width: 'auto',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginRight: 16,
        },
        height: 41,
        paddingLeft: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
    optionImage: { marginRight: 16, width: 18, alignSelf: 'center' },
    text: {
        color: theme.color.contrast5,
        ...getFont(theme, 'h5'),
    },
});
// primary4
export default getStyles;
