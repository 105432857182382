import { useTranslation } from 'react-i18next';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { AdyenConfiguration } from '../../utils/cleeng/types';
import { useThemingForCleeng } from './useThemingForCleeng';
import { getLocaleFromLanguageCode } from '../../utils/locales';

export const useAdyenConfig = () => {
    const { colors, body1 } = useThemingForCleeng();
    const subscriptionsProvider = useFeature('subscriptionsProvider');
    const cleengSettings =
        subscriptionsProvider?.name === 'cleeng' ? subscriptionsProvider.settings : undefined;
    const { i18n, t } = useTranslation();
    const selectedLocale = getLocaleFromLanguageCode(i18n.language);

    const adyenStyleObject = {
        base: {
            color: colors.primary1,
            fontSize: `${body1?.size}px`,
            fontSmoothing: 'antialiased',
        },
        placeholder: {
            opacity: '40%',
        },
    };

    const adyenConfiguration: AdyenConfiguration = {
        analytics: {
            enabled: cleengSettings?.adyenSettings?.analytics?.enabled ?? false,
        },

        paymentMethodsConfiguration: {
            card: {
                ...cleengSettings?.adyenSettings?.paymentMethodsConfiguration?.card,
                name: t('account.purchase.credit-card'),
                styles: adyenStyleObject, // use app styles and ignore possible styles coming from config,
                data: {
                    holderName: ' ',
                },
            },
            ...(cleengSettings?.adyenSettings?.paymentMethodsConfiguration?.googlePay
                ? {
                      googlePay: cleengSettings.adyenSettings.paymentMethodsConfiguration.googlePay,
                  }
                : {}),
        },

        locale: selectedLocale,
        ...(cleengSettings?.adyenSettings?.translations
            ? {
                  translations: cleengSettings.adyenSettings.translations,
              }
            : {}),
    };

    return { adyenConfiguration };
};
