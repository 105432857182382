import { Subscription } from '@24i/nxg-sdk-photon/src';

export interface ChangeSubscriptionConfirmInterface {
    currentSubscription: Subscription;
    newSubscription: Subscription;
    onPaymentConfirm: () => void;
}

export enum CHANGE_SUBSCRIPTION_CONFIRM {
    CHANGE_SUBSCRIPTION_SCREEN_ITEM_CURRENT = 'change_subscription_screen_item_current',
    CHANGE_SUBSCRIPTION_SCREEN_ITEM_NEW = 'change_subscription_screen_screen_item_new',
    CHANGE_SUBSCRIPTION_CONFIRM_PAY_BUTTON = 'change_subscription_screen_confirm_pay_button',
}
