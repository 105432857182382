import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { RemainingTimeStyles } from './types';

const getRemainingTimeStyles = (theme: Theme): RemainingTimeStyles => {
    const bigSquare = {
        width: 6,
        height: 6,
        marginRight: 8,
        marginBottom: 2,
    };

    return {
        container: {
            marginBottom: 10,
            marginTop: 10,
            borderBottomWidth: 0.5,
            borderColor: theme.color.lighter3,
        },
        spaceBar: {
            flexDirection: 'row',
            height: 6,
            width: '100%',
            marginBottom: 10,
            backgroundColor: theme.color.contrast4,
            maxWidth: '100%',
            overflow: 'hidden',
        },
        textContainer: {
            flexDirection: 'row',
            marginBottom: '1.6%',
            justifyContent: 'flex-start',
        },
        centerRow: {
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 32,
        },
        textStyle: {
            ...getFont(theme, 'h5'),
            color: `${theme.color.textPrimary}`,
            opacity: 0.6,
        },
        usedSpaceIndicator: {
            ...bigSquare,
            backgroundColor: theme.color.contrast1,
        },
        plannedSpaceIndicator: {
            ...bigSquare,
            backgroundColor: theme.color.warning,
        },
        freeSpaceIndicator: {
            ...bigSquare,
            backgroundColor: theme.color.contrast4,
        },
        usedTimeBar: {
            height: 6,
            backgroundColor: theme.color.contrast1,
        },
        plannedTimeBar: {
            height: 6,
            backgroundColor: theme.color.warning,
        },
        smallSquare: {
            height: 3,
            width: 3,
            backgroundColor: theme.color.textPrimary,
            opacity: 0.6,
            marginHorizontal: 6,
        },
    };
};

export default getRemainingTimeStyles;
