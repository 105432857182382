import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle, TextStyle } from 'react-native';
import { getFont } from '@24i/nxg-sdk-higgs';
import { Breakpoint } from '@24i/nxg-sdk-gluons/src/utils/styles/constants';

interface OnboardingIntroStylesProps {
    container: ViewStyle;
    buttonContainer: ViewStyle;
    title: TextStyle;
    description: TextStyle;
    buttonLabel: TextStyle;
}
const getOnboardingIntroStyles = (theme: Theme): OnboardingIntroStylesProps => ({
    container: {
        flex: 1,
        alignItems: 'center',
        paddingTop: 94,
    },
    title: {
        textAlign: 'center',
        paddingVertical: 10,
        color: theme.color.textPrimary,
        width: '80%',
        ...getFont(theme, 'h2'),
    },
    description: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        paddingVertical: 10,
        textAlign: 'center',
        width: '35%',
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: '80%',
        },
        ...getFont(theme, 'body2'),
    },
    buttonContainer: {
        marginTop: 38,
        width: 335,
        height: 52,
        backgroundColor: theme.color.buttonPrimary,
    },
    buttonLabel: {
        marginLeft: 0,
        color: theme.color.textButtonPrimary,
    },
});

export default getOnboardingIntroStyles;
