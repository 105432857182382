import React from 'react';
import { ScreenProps } from 'packages/sdk@gluons/src/clients/AppNavigationClient';
import View from './View';
import { PurchaseSubscriptionScreenProps } from '../../../../screens/PurchaseSubscriptionScreen/types';
import { ModularSubscriptionsElements } from '../../../../context/SubscriptionsHandlerProvider/types';

export const CleengPurchaseScreen: ModularSubscriptionsElements['PurchaseScreen'] = {
    Main: (props: PurchaseSubscriptionScreenProps & Partial<ScreenProps>) => {
        return <View {...props} />;
    },
    View,
};
