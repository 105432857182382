import React from 'react';

const FirebaseContext = React.createContext();

export const mockContext = {
    collectData: () => {},
    logEvent: () => {},
    recordError: () => {},
    crash: () => {},
    crashlyticsLog: () => {},
};

export default FirebaseContext;
