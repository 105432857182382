import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ImageGuard } from './image';

export const ProfileImageGuard = createGuard('ProfileImage', {
    id: t.string,
    name: t.string,
    default: t.boolean,
    images: nullable(
        createGuard('Images', undefined, {
            avatar: nullable(t.array(ImageGuard)),
        })
    ),
});

export type ProfileImage = t.TypeOf<typeof ProfileImageGuard>;

export const isProfileImage = (data: unknown): data is ProfileImage => {
    try {
        return !!decodeModelWith(ProfileImageGuard, data, 'ProfileImage', {
            disableErrorLog: true,
        });
    } catch {
        return false;
    }
};

export const createProfileImage = (data: unknown): ProfileImage | never => {
    return decodeModelWith(ProfileImageGuard, data, 'ProfileImage');
};
