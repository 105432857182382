// Maybe move this elsewhere?
export enum WEB_SCREEN_SIZES {
    XL = 'XL',
    L = 'L',
    M = 'M',
    S = 'S',
    XS = 'XS',
}

export enum WEB_SCREEN_MAX_WIDTH {
    L = 1920,
    M = 1440,
    S = 1025,
    XS = 800,
}
