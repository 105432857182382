/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import { MonitoringDataClient, MonitorConfig } from '@24i/nxg-sdk-photon';
import * as Sentry from '@sentry/browser';
import { getEnvironment } from './utils';

export class SentryMonitoringDataClient implements MonitoringDataClient {
    constructor(props) {
        this.env = props?.monitoringEnvironment ? props?.monitoringEnvironment : 'debug';
        this.version = props?.appVersion;
        this.props = props;
    }

    name = 'sentry';

    env = 'debug';

    version = '0.1.0';

    props: any;

    async init(config: MonitorConfig): Promise<void> {
        // DSN from BS
        const { id, enabled } = config;

        if (enabled) {
            // DSN from renative.json
            const currentDsn = Sentry.getCurrentHub().getClient()?.getOptions()?.dsn;

            // Override Sentry client if needed.
            // Display a warning about a mismatch between BS and renative.json configurations.
            if (currentDsn !== id) {
                console.warn(
                    'Sentry DSN mismatch between renative.json and backstage. DSN will be overriden with: ',
                    id
                );
                const appEnv = getEnvironment(this.env);
                Sentry.close();
                Sentry.init({
                    dsn: id,
                    environment: appEnv,
                });
            }
        } else {
            // Extra close in case there is a Sentry instance.
            Sentry.close();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    async logEvent() {}

    // eslint-disable-next-line class-methods-use-this
    async logException() {}
}

export const createSentryMonitoringDataClient = (runtimeConfig: unknown) =>
    new SentryMonitoringDataClient(runtimeConfig);
