import React from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getStyles from '../styles';
import BasicLayout from '../components/layout/BasicLayout';
import StatusLayout from '../components/layout/StatusLayout';
import { PurchasesModalViewProps } from './types';
import { isStepStatusStep } from '../viewModel';

const PurchasesModal = ({
    isVisible,
    currentStepData,
    isBackButtonVisible,
    handleOnBackPress,
    onModalClose,
}: PurchasesModalViewProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <Modal visible={isVisible} onRequestClose={onModalClose}>
            <View style={styles.modalContentContainer}>
                <View style={styles.modalContent}>
                    {isStepStatusStep(currentStepData) ? (
                        <StatusLayout
                            title={currentStepData.title}
                            actionButton={currentStepData.actionButton}
                            cancelButton={currentStepData.cancelButton}
                        >
                            {currentStepData.content}
                        </StatusLayout>
                    ) : (
                        <BasicLayout
                            title={currentStepData.title}
                            subtitle={currentStepData.subTitle}
                            isBackButtonVisible={isBackButtonVisible}
                            onBackPress={handleOnBackPress}
                        >
                            {currentStepData.content}
                        </BasicLayout>
                    )}
                </View>
            </View>
        </Modal>
    );
};

export default PurchasesModal;
