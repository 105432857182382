import { Theme } from '@24i/nxg-sdk-photon/src';
import { getFont } from '@24i/nxg-sdk-higgs/src';
import { IsTablet } from '@24i/nxg-core-utils/src/globalSingletons';
import { SearchInputStyles } from '../types';

export const getSearchInputStyles = (theme: Theme): SearchInputStyles => {
    const isTablet = IsTablet.get();
    return {
        container: {
            width: isTablet ? 450 : 350,
            height: 50,
            borderRadius: 5,
            marginTop: 8,
            marginBottom: 15,
            ...getFont(theme, 'body1'),
            paddingLeft: 20,
            backgroundColor: theme.color.lighter3,
            color: theme.color.textSecondary,
        },
    };
};
