import { isPlatformWeb } from 'renative';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { AssetLabelStyles, AssetLabelProps } from '../types';

const AssetLabel = <TTheme extends Theme>(
    theme: TTheme,
    props: AssetLabelProps
): AssetLabelStyles => {
    const { backgroundColor, textStyles } = props;
    const fontStyles = textStyles || getFont(theme, 'h6');
    return {
        text: {
            color: textStyles?.color || `${theme?.color?.textPrimary}` || 'white',
            ...fontStyles,
            textAlign: 'center',
        },
        container: {
            flexDirection: 'row',
            backgroundColor: backgroundColor || theme?.color?.labelPrimary || '#00addd',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: isPlatformWeb ? 2 : 1,
            paddingBottom: isPlatformWeb ? 0 : 1,
            ...getBorderRadius(theme.elements, 'label-cornerRadius'),
        },
    };
};

export default AssetLabel;
