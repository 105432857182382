import { getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { isPlatformWeb } from 'renative';
import { ViewStyle } from 'react-native';
import { ProgressBarStyles, ProgressBarLinearGradient } from '../types';

const getProgressBarStyles = (
    theme: Theme,
    progress = 0,
    isLive = false,
    additionalTrackStyles?: ViewStyle,
    color?: string,
    additionalProgressStyles?: ViewStyle,
    trackColor?: string
): ProgressBarStyles => ({
    track: {
        width: '90%',
        height: 5,
        position: 'absolute',
        bottom: 10,
        borderRadius: 2,
        ...getBorderRadius(theme.elements, 'progressBar-cornerRadius'),
        alignSelf: 'center',
        zIndex: 2,
        backgroundColor: trackColor || theme.color.progressBarBackground,
        ...(additionalTrackStyles && additionalTrackStyles),
    },
    progress: {
        zIndex: 2,
        borderRadius: 2,
        ...getBorderRadius(theme.elements, 'progressBar-cornerRadius'),
        maxWidth: '100%',
        height: '100%',
        width: `${progress * 100}%`,
        backgroundColor:
            color || (isLive ? theme.color.progressBarLive : theme.color.progressBarAccent),
        ...(additionalProgressStyles && additionalProgressStyles),
    },
});

const getLinearGradientStyles = (theme: Theme): ProgressBarLinearGradient => ({
    linearGradient: {
        position: 'absolute',
        backgroundColor: 'transparent',
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        height: isPlatformWeb ? '67%' : 48,
        width: '100%',
        bottom: 0,
        left: 0,
    },
});

export { getLinearGradientStyles };
export default getProgressBarStyles;
