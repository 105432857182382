import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { PinInputModalAndContentProps } from '../types';
import PinInputModalContent from '../../PinInputModalContent';

const PinInputModal = (props: PinInputModalAndContentProps) => {
    const {
        onModalClose,
        isVisible,
        dismissible = true,
        animationType = 'fade',
        transparent = false,
    } = props;

    return (
        <Modal
            visible={isVisible}
            onRequestClose={dismissible ? onModalClose : undefined}
            animationType={animationType}
            transparent={transparent}
        >
            <PinInputModalContent {...props} />
        </Modal>
    );
};

export default overridable(PinInputModal, 'PinControl');
