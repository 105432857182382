import React from 'react';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { EpisodeInfoProps } from '../types';
import EpisodeInfoStyles from '../styles';

const EpisodeInfo = (props: EpisodeInfoProps): JSX.Element => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const { asset, styles: getStyles = EpisodeInfoStyles } = props;
    const { seasonNumber, episodeNumber, episodeTitle } = asset;

    const getEpisodeTitleText = (): string => {
        if ((seasonNumber || episodeNumber) && episodeTitle) return `- ${episodeTitle}`;
        if (episodeTitle) return episodeTitle;
        return '';
    };

    const seasonNumberText = seasonNumber
        ? `${t('asset.series.seasonInitial')}${seasonNumber} `
        : '';
    const episodeNumberText = episodeNumber
        ? `${t('asset.series.episode.initial')}${episodeNumber} `
        : '';
    const episodeTitleText = getEpisodeTitleText();

    const styles = getStyles(theme);

    return (
        <View style={styles.container}>
            <Text style={styles.text}>
                {`${seasonNumberText}${episodeNumberText}${episodeTitleText}`}
            </Text>
        </View>
    );
};

export default overridable(EpisodeInfo, 'EpisodeInfo');
