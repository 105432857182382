import React from 'react';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import View from './View';
import { useViewModel } from './viewModel';
import WorkInProgress from '../../components/WorkInProgress/index';

const Main = (props: ScreenProps): JSX.Element => {
    const viewModelProps = useViewModel(props);
    return (
        <WorkInProgress>
            <View {...viewModelProps} />
        </WorkInProgress>
    );
};

export default {
    Main,
};
