import React, { useState } from 'react';
import { Interactable, View } from '@24i/nxg-sdk-quarks';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { TopBarMenuItem } from '../../../../../../../components/MenuItem';
import {
    getProfileItemStyles,
    getTopBarMenuItemStyles,
} from '../../../../../../../components/MenuItem/styles/index.web';
import { getListItemStyles } from './styles';
import { ListItemProps } from './types';

export const ListItem = (props: ListItemProps) => {
    const {
        index,
        styles: getStyles = getListItemStyles,
        onToggle,
        onItemPress,
        menuItem,
        calculateScrollBarHeight,
        isValidImageUrl,
    } = props;
    const [isHovered, setIsHovered] = useState(false);
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { asPath } = useRouter();
    const { title, name: itemName, displayDividerUnder } = menuItem;
    const itemIcon = menuItem.icon ? menuItem.icon : menuItem.image;

    const styleDecider = (validImageUrl: boolean) => {
        if (validImageUrl) return getProfileItemStyles;
        return getTopBarMenuItemStyles;
    };

    return (
        <>
            <Interactable
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                key={`topmenu-dropdown-link-${index}`}
                style={[styles.itemContainer, styles.menuItemHover]}
                onPress={() => {
                    onToggle();
                    onItemPress(menuItem);
                }}
                onLayout={(e) => {
                    calculateScrollBarHeight(e.nativeEvent.layout.height);
                }}
            >
                <View key={`topmenu-link-${itemName}`} style={styles.titleContainer}>
                    <TopBarMenuItem
                        isHovered={isHovered}
                        key={`topmenu-${title}`}
                        title={title}
                        icon={itemIcon}
                        index={index}
                        active={asPath.split(/[/?]/).includes(itemName)}
                        spatial
                        onPress={() => {
                            onItemPress(menuItem);
                            onToggle();
                        }}
                        styles={styleDecider(isValidImageUrl(itemIcon))}
                    />
                </View>
            </Interactable>
            {displayDividerUnder && (
                <View
                    onLayout={(e) => {
                        calculateScrollBarHeight(e.nativeEvent.layout.height);
                    }}
                    style={styles.lineDivider}
                />
            )}
        </>
    );
};
