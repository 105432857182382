/*
@PRODUCT_FEATURE: G 1.7
*/
import View from './View';

export { default as View } from './View';

export const headerDefaultHeight = 64;

export const useViewModel = (props) => props;
export default View;
