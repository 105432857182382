import React, { useLayoutEffect, useMemo } from 'react';
import { isPlatformWeb } from 'renative';
import { timeText } from '@24i/nxg-core-utils';
import Icon from '../../../../components/Icon';
import { Text, View, Interactable } from '@24i/nxg-sdk-quarks';
import { timeElapsed } from '../../../../../utils/DateHelpers';
import { DefaultCellProps } from '../types';
import deepmerge from 'deepmerge';
import getStyles from '../styles';

const DefaultCell = (props: DefaultCellProps) => {
    const {
        isFocused = false,
        timelinePosition = -1,
        elapsed = -1,
        setTimelinePosition,
        data,
        isPlayingNow,
        onPressCell,
        is12HourClock,
        currentTime,
        lockedContentTitle,
        theme,
        newTimelinePosition,
        setElapsedValue,
        handleFocus,
        handleBlur,
        style,
        forceUpdate,
    } = props;

    const cellTheme = data.getLandscapeTheme() ? deepmerge(theme, data.getLandscapeTheme()) : theme;
    const styles = getStyles(theme, style, isFocused);

    const genre = data.getGenre();
    const genreString = genre ? ` | ${genre}` : '';
    const start = new Date(data.getStart());

    useLayoutEffect(() => {
        const end = new Date(data.getEnd());
        const ended = currentTime && currentTime > end;
        const newElapsed = !ended && isPlayingNow ? timeElapsed(start, end) : 0;

        if (!timelinePosition || newTimelinePosition !== timelinePosition) {
            setTimelinePosition(timelinePosition);
            if (!elapsed || timelinePosition) {
                setElapsedValue(newElapsed);
            }
        }
    }, [timelinePosition, newTimelinePosition, elapsed, data]);

    const renderTitleBlock = () => {
        const title = (
            <Text
                style={{
                    ...cellTheme.title,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...(isFocused && {
                        width: cellTheme?.title?.width || 'auto',
                    }),
                    textAlign: 'justify',
                    ...(data.isLocked() && cellTheme.lockedTitle),
                }}
                numberOfLines={1}
            >
                {data.isLocked() ? lockedContentTitle || data.getTitle() : data.getTitle()}
            </Text>
        );

        return data.getIcons() && data.getIcons().length > 0 ? (
            <div style={cellTheme.titleContainerWithIcon}>
                {title}
                {data.getIcons().length > 0 &&
                    data
                        .getIcons()
                        .map((icon) => (
                            <Icon
                                name={icon}
                                key={`${icon}${data.getId()}`}
                                style={cellTheme.icon}
                            />
                        ))}
            </div>
        ) : (
            title
        );
    };

    return useMemo(
        () => (
            <Interactable
                onMouseEnter={isPlatformWeb ? () => handleFocus() : undefined}
                onFocus={isPlatformWeb ? () => handleFocus() : undefined}
                onMouseLeave={isPlatformWeb ? () => handleBlur() : undefined}
                onBlur={isPlatformWeb ? () => handleBlur() : undefined}
                style={{
                    ...styles.container,
                    backgroundColor: isPlayingNow
                        ? cellTheme.activeContainer.backgroundColor
                        : cellTheme.container.backgroundColor,
                    ...(isFocused && {
                        zIndex: 20,
                        width: 'auto',
                        backgroundColor: isPlatformWeb
                            ? isPlayingNow
                                ? '#2d3037' // Color not added in Backstage theme section
                                : '#10141a' // Color not added in Backstage theme section
                            : cellTheme.selectedContainer.backgroundColor,
                    }),
                }}
                onPress={() => onPressCell(data)}
            >
                {isPlayingNow && !isFocused && (
                    <View
                        style={{
                            ...cellTheme.activePassedContainer,
                            backgroundColor: cellTheme.activePassedContainer.backgroundColor,
                            width: `${elapsed}%`,
                        }}
                    />
                )}
                <View style={styles.epgItem}>
                    {renderTitleBlock()}
                    {isPlatformWeb && (
                        <Text style={styles.description} numberOfLines={1}>
                            {`${timeText(
                                new Date(data.getStart()),
                                new Date(data.getEnd()),
                                is12HourClock
                            )}${genreString}`}
                        </Text>
                    )}
                </View>
            </Interactable>
        ),
        [
            style.left,
            style.top,
            style.width,
            data.getTitle(),
            forceUpdate,
            isPlayingNow,
            isFocused,
            timelinePosition,
        ]
    );
};

export default DefaultCell;
