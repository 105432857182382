// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Ratio, useTheme } from '@24i/nxg-sdk-higgs';
import { ThemeIcon, Interactable, Text, View, ImageBackground } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { isFactorMobile } from 'renative';
import getProfileItemStyles from '../styles';
import { ProfileItemProps } from '../types';

const DEFAULT_EDIT_ICON_SIZE = Ratio(32);

// TODO: Refactor!
const ProfileItem = (props: ProfileItemProps) => {
    const {
        editing,
        isFocused,
        testID,
        noBorders = false,
        adjustedDimensions,
        isActive = false,
        name = '',
        isNewProfileHandler = false,
        icon = null,
        iconSize,
        index = -1,
        avatar = null,
        onPress = () => null,
        styles: getStyles = getProfileItemStyles,
    } = props;
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const styles = getStyles(theme, adjustedDimensions);

    const addActiveBorder = !noBorders && isActive;

    return (
        <>
            <Interactable
                testID={testID || (name ? `profile_manage_${index}` : 'add_new_profile_button')}
                style={isFactorMobile ? styles.profileItemContainer : {}}
                activeOpacity={0.2}
                onPress={onPress}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <View style={styles.container}>
                    <View
                        style={[
                            styles.profileAvatarContainer,
                            !isFactorMobile &&
                                (isFocused || isHovered
                                    ? styles.focusedAvatarContainer
                                    : styles.unfocusedAvatarContainer),
                            styles.adjustedDimensions,
                            addActiveBorder && styles.activeBorderWidth,
                        ]}
                    >
                        {isNewProfileHandler ? (
                            <View
                                style={[
                                    styles.newProfileHandlerIconContainer,
                                    styles.adjustedDimensions,
                                ]}
                            >
                                {icon && (
                                    <ThemeIcon
                                        iconName={icon}
                                        size={iconSize || DEFAULT_EDIT_ICON_SIZE}
                                    />
                                )}
                            </View>
                        ) : (
                            avatar && (
                                <ImageBackground
                                    style={styles.profileImageContainerStyles}
                                    imageStyle={{
                                        opacity: isActive || isNewProfileHandler ? 1 : 0.8,
                                    }}
                                    source={{ uri: avatar }}
                                >
                                    {icon && (
                                        <View style={styles.editIconContainer}>
                                            <ThemeIcon
                                                iconName={icon}
                                                size={iconSize || DEFAULT_EDIT_ICON_SIZE}
                                            />
                                        </View>
                                    )}
                                </ImageBackground>
                            )
                        )}
                    </View>
                    {/* TODO: This is always present even though this component is used in the UpsertProfileScreen where it should not be displayed... Refactor this component! */}
                    <Text
                        numberOfLines={1}
                        style={[
                            styles.userName,
                            { opacity: isActive || editing ? 1 : 0.6 },
                            !isFactorMobile && {
                                opacity: isFocused || isHovered ? 1 : 0.5,
                            },
                        ]}
                    >
                        {isNewProfileHandler ? t('profiles.add.addButton') : name}
                    </Text>
                </View>
            </Interactable>
        </>
    );
};

export { getProfileItemStyles };
export default overridable(ProfileItem, 'ProfileItem');
