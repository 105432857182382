import { PaymentGateResultQuery } from './types';

// Most complete solution extracted from stackoverflow: https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
export const queryToObject = (url: string): Record<string, any> => {
    const query = url.substring(url.indexOf('?') + 1).replace('?', '&');

    const regex = /([^&=]+)=?([^&]*)/g;
    const decodeRE = /\+/g;

    const decode = (str) => {
        return decodeURIComponent(str.replace(decodeRE, ' '));
    };

    const params = {};
    let e;

    // eslint-disable-next-line no-cond-assign
    while ((e = regex.exec(query))) {
        let key = decode(e[1]);
        const value = decode(e[2]);
        if (key.substring(key.length - 2) === '[]') {
            key = key.substring(0, key.length - 2);
            (params[key] || (params[key] = [])).push(value);
        } else params[key] = value;
    }

    const assign = (obj, keyPath, val) => {
        const lastKeyIndex = keyPath.length - 1;
        for (let i = 0; i < lastKeyIndex; ++i) {
            const key = keyPath[i];
            // eslint-disable-next-line no-param-reassign
            if (!(key in obj)) obj[key] = {};
            // eslint-disable-next-line no-param-reassign
            obj = obj[key];
        }
        // eslint-disable-next-line no-param-reassign
        obj[keyPath[lastKeyIndex]] = val;
    };

    Object.keys(params).forEach((param) => {
        const structure = param.split('[');
        if (structure.length > 1) {
            const levels: string[] = [];
            structure.forEach((item) => {
                const key = item.replace(/[?[\]\\ ]/g, '');
                levels.push(key);
            });
            assign(params, levels, params[param]);
            delete params[param];
        }
    });

    return params;
};

/**
 *
 * @param url Current url path from router
 * @returns PaymentGateResultQuery | undefined:  Payment gate inserts a result object in the query of the provided redirectUri
 */
export const getPaymentGateResultFromUrl = (url: string): PaymentGateResultQuery | undefined => {
    const decodedUrl = decodeURIComponent(url);

    const queryObject = queryToObject(decodedUrl);

    if (!queryObject.result) return undefined;

    const result = JSON.parse(queryObject.result);

    if (queryObject.onboarding) result.onboarding = queryObject.onboarding === 'true';

    return result;
};
