import { QUERY_KEYS, UpdateUserRequest } from '@24i/nxg-sdk-photon/src';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useMutation, useQueryClient } from 'react-query';

/*
    Implementation of these mutation doesn't follow optimistic update route as it would cause navigation out of the page and/or
    API expected behavior (i.e., returning an object --> currently returning void)
 */
export const useUpdateUserMutation = () => {
    const client = useUserData();
    const queryClient = useQueryClient();

    const { mutate } = useMutation(
        QUERY_KEYS.user,
        (body: UpdateUserRequest) => client.updateUser(body),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEYS.user);
            },
        }
    );

    return {
        mutate,
    };
};

export const useUpdateUserEmailMutation = () => {
    const client = useUserData();
    const queryClient = useQueryClient();

    const { mutate } = useMutation(
        QUERY_KEYS.user,
        (email: string) => client.updateUserEmail(email),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEYS.user);
            },
        }
    );

    return {
        mutate,
    };
};
