import { Config, Auth } from '@cleeng/mediastore-sdk';
import { Storage } from '@24i/nxg-sdk-quantum';
import { ASSET_TYPE, CleengSettings, OfferType } from '@24i/nxg-sdk-photon';
import { CLEENG_OPERATION_BUFFER_TIME } from '@24i/nxg-core-utils/src/constants';
import { FlowUrlConstructionParams, SetUpPaypalUrlsParams } from './types';
import {
    getAssetDetailsScreenLink,
    getMySubscriptionsScreenLink,
    getPaymentMethodScreenLink,
    getPaymentResultScreenLink,
} from '../navigation';

export const areValidCleengSettings = (settings: any): settings is CleengSettings =>
    settings != null && 'publisherId' in settings;

export const initialiseCleeng = (settings: CleengSettings) => {
    const { environment, publisherId, termsUrl, myAccountUrl } = settings;

    if (!publisherId) {
        throw new Error(
            'Publisher id is required for Cleeng components to work. Make sure to pass the right settings in the Backstage custom JSON. Documentation: https://24imedia.atlassian.net/wiki/spaces/PRJ001NXG/pages/3373891585/Cleeng+MediaStore+SDK+integration+inside+24i'
        );
    }

    Config.setPublisher(publisherId.toString());
    Config.setEnvironment(environment || 'sandbox');

    if (myAccountUrl) {
        Config.setMyAccountUrl(myAccountUrl);
    }

    if (termsUrl) {
        Config.setTermsUrl(termsUrl);
    }
};

export const checkCleengTokenTimeValidity = (tokenTimestamp: number) => {
    const currentCleengTokenExpiry = tokenTimestamp * 1000;
    const now = Date.now();

    return now + CLEENG_OPERATION_BUFFER_TIME <= currentCleengTokenExpiry;
};

/**
 * It looks like calling Auth.logout does not remove all Cleeng data from Redux store and LocalStorage.
 * On some occasions, the entitlements of a user get leaked over different sessions (in Network tab, the component
 * can be seen calling https://mediastoreapi-sandbox.cleeng.com/offers/S696995203_US/customers/[email-of-user-who-has-logged-out]).
 * Hence the manual adding and removing of the CLEENG data from local storage.
 *
 * See in docs: https://24imedia.atlassian.net/wiki/spaces/PRJ001NXG/pages/3373891585/Cleeng+MediaStore+SDK+integration+inside+24i#Known-issues
 */

const CLEENG_CUSTOMER_EMAIL = 'CLEENG_CUSTOMER_EMAIL';
const CLEENG_OFFER_TYPE = 'CLEENG_OFFER_TYPE';
const CLEENG_OFFER_ID = 'CLEENG_OFFER_ID';
const CLEENG_ENVIRONMENT = 'CLEENG_ENVIRONMENT';
const CLEENG_PUBLISHER_ID = 'CLEENG_PUBLISHER_ID';
const CLEENG_ORDER_ID = 'CLEENG_ORDER_ID';

export const loadCleengToken = (token: string) => {
    Config.setJWT(token);
};

const loadCleengUserEmail = (email: string) => {
    Storage.setItem(CLEENG_CUSTOMER_EMAIL, email);
};

export const loadCleengTokenAndUserEmail = (token: string, email: string) => {
    loadCleengToken(token);
    loadCleengUserEmail(email);
};

export const cleanUpCleengOfferData = () => {
    [CLEENG_OFFER_TYPE, CLEENG_OFFER_ID, CLEENG_ORDER_ID, CLEENG_CUSTOMER_EMAIL].forEach(
        (cleengOfferData) => Storage.removeItem(cleengOfferData)
    );
};

const cleanUpOtherCleengData = () => {
    [CLEENG_ENVIRONMENT, CLEENG_PUBLISHER_ID].forEach((cleengData) =>
        Storage.removeItem(cleengData)
    );
};

// FIXME: bug on Cleeng side doesn't clean up component status, even on Auth.logout
export const cleengLogout = () => {
    cleanUpCleengOfferData();
    cleanUpOtherCleengData();
    Auth.logout();
};

export const setUpPaypalUrls = ({ urls, purpose }: SetUpPaypalUrlsParams) => {
    const cancelUrl = window?.location?.href;

    if (purpose === 'checkout') {
        Config.setCheckoutPayPalUrls({ cancelUrl, ...urls });
    }

    Config.setMyAccountPayPalUrls({ cancelUrl, ...urls });
};

export const isValidReferralAssetType = (value: any): value is ASSET_TYPE => {
    return Object.values(ASSET_TYPE).includes(value);
};

export const getBackToSourceUrl = ({
    referralAssetId,
    referralAssetType,
}: FlowUrlConstructionParams) => {
    const isComingFromAssetPage =
        typeof referralAssetId === 'string' && isValidReferralAssetType(referralAssetType);

    const backToSourceUrl = isComingFromAssetPage
        ? getAssetDetailsScreenLink({
              referralAssetType: referralAssetType as ASSET_TYPE,
              referralAssetId: referralAssetId as string,
          }).as
        : getMySubscriptionsScreenLink().as;

    return backToSourceUrl;
};

export const getPurchaseSuccessUrl = ({
    referralAssetId,
    referralAssetType,
    withOrigin = true,
}: FlowUrlConstructionParams & { withOrigin?: boolean }) => {
    const onContinueUrl = getBackToSourceUrl({ referralAssetId, referralAssetType });
    const screenPath = getPaymentResultScreenLink({
        onContinueUrl: onContinueUrl as string,
    });

    return withOrigin ? window?.origin + screenPath.as : screenPath.as;
};

export const getPurchaseErrorUrl = ({
    referralAssetId,
    referralAssetType,
    currentPath,
}: FlowUrlConstructionParams & { currentPath: string }) => {
    const onCancelUrl = getBackToSourceUrl({ referralAssetId, referralAssetType });
    const onTryAgainUrl = currentPath;
    const screenPath = getPaymentResultScreenLink({
        onCancelUrl: onCancelUrl as string,
        onTryAgainUrl,
    });

    return window?.origin + screenPath.as;
};

export const getPaymentMethodSuccessUrl = () => {
    const onContinueUrl = getPaymentMethodScreenLink().as;
    const screenPath = getPaymentResultScreenLink({
        offerType: OfferType.PAYMENT_METHOD,
        onContinueUrl: onContinueUrl as string,
    });

    return window?.origin + screenPath.as;
};

export const getPaymentMethodErrorUrl = () => {
    const onCancelUrl = '/';
    const onTryAgainUrl = getPaymentMethodScreenLink().as;
    const screenPath = getPaymentResultScreenLink({
        offerType: OfferType.PAYMENT_METHOD,
        onCancelUrl,
        onTryAgainUrl: onTryAgainUrl as string,
    });

    return window?.origin + screenPath.as;
};
