import React from 'react';
import PropTypes from 'prop-types';
import ButtonGluon from '../../../buttons/Button';

export const DROPDOWN_PICKER_TEST_ID = 'dropdown-picker-test-id';

const Button = ({ selectedLabel, setDropdownOpen, children }) => {
    if (children) {
        return React.cloneElement(children, { onPress: () => setDropdownOpen(true) });
    }
    return (
        <ButtonGluon
            title={selectedLabel}
            testID={DROPDOWN_PICKER_TEST_ID}
            onPress={() => setDropdownOpen(true)}
        />
    );
};

Button.defaultProps = {
    selectedLabel: '',
    setDropdownOpen: () => {},
    children: null,
};

Button.propTypes = {
    selectedLabel: PropTypes.string,
    setDropdownOpen: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Button;
