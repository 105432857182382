export const selectedStyles = {
    borderWidth: 2,
    borderColor: '#fabc0b',
};

export const defaultLockStyle = {
    float: 'right',
    position: 'absolute',
    zIndex: 2,
    right: '5%',
    bottom: 32,
};
