import React, { FC } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { getStyles } from './styles';

export const MiniPlayerControlsContainer: FC = ({ children }) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return <View style={styles.controlsContainer}>{children}</View>;
};
