import React from 'react';
import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';

const getNavBarMenuStyles = (theme: Theme) => ({
    container: {
        position: 'sticky',
        top: 0,
        zIndex: 99,
    } as React.CSSProperties,
    containerWithTransparency: {
        width: '100%',
    } as ViewStyle,
    containerWithoutTransparency: {
        backgroundImage: `linear-gradient(${theme.color.primaryBlur1Fallback}, rgba(0,0,0,0))`,
    },
    scrolledMenuContainer: {
        backgroundColor: theme.color.background1,
        transition: '500ms linear',
    },
});

export { getNavBarMenuStyles };
