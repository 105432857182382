import React, { FC } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { PaymentResultProps } from '../types';
import StatusLayout from '../../PurchasesModal/components/layout/StatusLayout';
import { usePaymentResultsElements } from '../../../hooks/paymentResults/usePaymentResultsElements';
import { PaymentErrorResult } from '../../PaymentErrorResult';

export const PaymentResultView: FC<PaymentResultProps> = (props) => {
    const { onCancelUrl, onTryAgainUrl } = props;
    const { title, description, actionButton, cancelButton } = usePaymentResultsElements(props);

    if (onCancelUrl && onTryAgainUrl) {
        return (
            <PaymentErrorResult
                title={title}
                description={description}
                mainButtonText={actionButton.title}
                onMainButtonPress={actionButton.onPress}
                secondaryButtonText={cancelButton?.title as string}
                onSecondaryButtonPress={cancelButton?.onPress as () => void}
            />
        );
    }

    return (
        <View>
            <StatusLayout
                title={title}
                actionButton={actionButton}
                cancelButton={cancelButton}
                isModalContent={false}
            >
                {description}
            </StatusLayout>
        </View>
    );
};
