import { TextStyle } from 'react-native';
import { ViewStyleWeb } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import { Theme } from '@24i/nxg-sdk-photon';
import { DropdownItem } from '../../TopBarMenu/components/TopBarMenuDropdown/types';
import { OnboardingHeaderProps } from '../types';

export interface OnboardingWebHeaderStyles {
    webNavMenu: ViewStyleWeb;
    headerContainer: ViewStyleWeb;
    headerTitle: TextStyle;
}

export interface OnboardingWebHeaderProps extends OnboardingHeaderProps {
    items: DropdownItem[];
    onListItemPress: (item: DropdownItem) => void;
    styles?: (theme: Theme) => OnboardingWebHeaderStyles;
}

export enum OnboardingHeaderItemsEnum {
    Signout = 'signout',
}
