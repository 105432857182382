import React, { FC } from 'react';
import { View, Text, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { formatFullReleaseDate, isPlatformWeb, isValidDateString } from '@24i/nxg-core-utils';
import { getStyles } from './styles';

type Props = {
    title: string;
    podcastName: string;
    imageSource?: string;
    date?: string;
};

export const EpisodeDetails: FC<Props> = ({ imageSource, date, title, podcastName }) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { i18n } = useTranslation();

    return (
        <View style={styles.container}>
            {imageSource != null && (
                <View style={styles.imageHolder}>
                    <Image source={imageSource} style={styles.image} />
                </View>
            )}

            <View style={styles.content}>
                {isPlatformWeb && !!date && isValidDateString(date) && (
                    <Text style={styles.date} numberOfLines={1}>
                        {formatFullReleaseDate(date, i18n.language)}
                    </Text>
                )}

                <Text style={styles.title} numberOfLines={1}>
                    {title}
                </Text>

                <Text style={styles.podcastName} numberOfLines={1}>
                    {podcastName}
                </Text>
            </View>
        </View>
    );
};
