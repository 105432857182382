import { useRef, useEffect } from 'react';
import { isPlatformIos, isPlatformAndroid, isPlatformWeb } from 'renative';
import { useIsFocused } from '@react-navigation/native';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import useBlockedModal from '@24i/nxg-sdk-smartott/src/components/BlockedModal/hooks/useBlockedModal';
import { BlockModalTypes } from '@24i/nxg-sdk-smartott/src/components/BlockedModal/types';
import { usePinProtection } from '../../context/PinProtection';
import { triggerPinControlParams, UseProtectedScreenParams } from '../types';
import { PinInputModalAndContentProps } from '../../components/PinInputModal/types';

const isNativePlatform = isPlatformIos || isPlatformAndroid;

export const usePinProtectedScreen = ({
    scopes = [],
    active = true,

    goBackAfterActivation = false,
    goBackAfterDismiss = true,
    modalProps,
}: UseProtectedScreenParams) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigation = useNavigation();
    const router = useRouter();
    const { userData } = useStore();
    const { openPinControl, closePinControl, arePinScopesValidated } = usePinProtection();
    const { openBlockedModal, closeBlockedModal } = useBlockedModal();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isFocused = isNativePlatform && useIsFocused();
    const activeRef = useRef<boolean>(true);

    const goBack = () => {
        if (router) {
            router.back();
        } else if (navigation) {
            navigation.goBack();
        }
    };
    const onModalClose = (shouldGoBack = true) => {
        if (shouldGoBack) goBack();
        // Adding timeout here prevents user to briefly
        // see the protected screen before navigation happens.
        setTimeout(() => closePinControl?.());
    };
    const onPinActivated = () => {
        // have to check for the query so this doesn't execute on dynamic routes. Forces pin
        // control to navigate back on account details screen after dimissing the pin control.
        // still should be used for manage profiles
        if (!router?.query && !goBackAfterActivation) {
            // If we get to this page again by going back in history and pin is already
            // verified updating the url to skip verification means we don't ask for it again.
            router?.replace({
                pathname: router.pathname,
            });
        }
        onModalClose(goBackAfterActivation);
    };
    const activatePinControl = async (
        activeModalProps: PinInputModalAndContentProps | undefined = modalProps
    ) => {
        const result = await openPinControl?.(null, {
            // providing this will prevent pin modal from autoclosing
            onModalClose: () => onModalClose(goBackAfterDismiss),
            ...activeModalProps,
        });
        onPinActivated();

        return result;
    };

    const onSignInModalClose = () => {
        closeBlockedModal();
        goBack();
    };

    const triggerPinControl = async (
        triggerPinControlProps: triggerPinControlParams | undefined = {
            triggerScopes: [],
            activeModalProps: undefined,
        }
    ): Promise<number | undefined> => {
        const { triggerScopes, activeModalProps } = triggerPinControlProps;

        if (!triggerScopes) return;

        if (!arePinScopesValidated?.(triggerScopes)) {
            if (!userData) {
                openBlockedModal(
                    BlockModalTypes.ACCESS,
                    '',
                    {
                        transparent: false,
                        animationType: 'none',
                        onModalClose: () => onModalClose(goBackAfterDismiss),
                        blockModalClose: isPlatformWeb,
                    },
                    onSignInModalClose
                );
                return;
            }
            const result = await activatePinControl(activeModalProps);
            // eslint-disable-next-line consistent-return
            return result;
        }
    };

    useEffect(() => {
        if (activeRef.current) {
            if (arePinScopesValidated?.(scopes)) return;
            if (isNativePlatform && !isFocused) return;
            if (active) {
                triggerPinControl({ triggerScopes: scopes });
                activeRef.current = false;
            }
        }
    });

    return { triggerPinControl };
};
