import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { Toggle } from '@24i/nxg-sdk-gluons';
import defaultStyles from '../styles';
import { ToggleItemProps, TOGGLE_ITEM_TEST_ID } from '../types';

const ToggleItem = ({
    title,
    description,
    defaultValue = true,
    testID = TOGGLE_ITEM_TEST_ID.TOGGLE_ITEM,
    withDivider,
    onValueChange,
    thumbColor,
    styles: getStyles = defaultStyles,
}: ToggleItemProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    const styles = getStyles(theme);

    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const onChange = (val: boolean) => {
        setValue(val);
        onValueChange(val);
    };

    return (
        <View style={styles.mainContainer}>
            <View style={styles.settingsContainer}>
                <View style={styles.textContainer}>
                    <Text style={styles.title}>{title}</Text>
                </View>
                <View style={styles.toggleContainer}>
                    <Text style={styles.toggleText}>
                        {value ? t('common.on') : t('common.off')}
                    </Text>
                    <Toggle
                        thumbColor={
                            value === false ? thumbColor ?? theme.color.textPrimary : 'white'
                        }
                        trackColor={{
                            false: theme.color.lighter2,
                            true: theme.color.success,
                        }}
                        value={value}
                        onValueChange={onChange}
                        testID={testID}
                    />
                </View>
            </View>
            {!!description && (
                <View style={styles.descriptionContainer}>
                    <Text style={styles.description} testID={TOGGLE_ITEM_TEST_ID.DESCRIPTION_TEXT}>
                        {description}
                    </Text>
                </View>
            )}
            {withDivider && <View style={styles.divider} testID={TOGGLE_ITEM_TEST_ID.DIVIDER} />}
        </View>
    );
};

export default ToggleItem;
