import {
    NavScreenConfigWeb,
    NavConfig,
    NavConfigWeb,
    RouteToCheck,
} from '../../clients/AppNavigationClient';

export const isWebNavConfig = (navigationConfig: NavConfig): navigationConfig is NavConfigWeb => {
    return Boolean((navigationConfig as NavConfigWeb).allNavigationItems);
};

interface FindPageContext {
    navigationConfig: NavConfigWeb;
}

/**
 * We have a special "allNavigationItems" property to search through.
 * But to preserve the nested structure of embedded screens (not flattening them), we need to also search through any embedded screens for a match.
 * This function takes care of that.
 */
const deepFind = (
    screens: NavScreenConfigWeb[],
    route: RouteToCheck
): NavScreenConfigWeb | null => {
    // Find shorter and more readable version of this, I dare you
    // eslint-disable-next-line no-restricted-syntax
    for (const screen of screens) {
        if (screen.matchesWebRoute(route, screen.screenTypeToUse)) return screen;

        // As the root screen is a web screen, it is quite safe to assume that we are working with web configs
        const webSubScreens = screen.embeddedScreens as NavScreenConfigWeb[] | undefined;

        // We also need to check all the embedded screens of the current screen as they are not flattened into all navigation items
        const screenFromDeepSearch = deepFind(webSubScreens || [], route);
        if (screenFromDeepSearch) return screenFromDeepSearch;
    }

    return null;
};

export const findPageInNavConfigByMatchingRoute = (
    route: RouteToCheck,
    { navigationConfig }: FindPageContext
): NavScreenConfigWeb | null => {
    return deepFind(navigationConfig.allNavigationItems, route);
};
