import { createTestIDGetter } from '@24i/nxg-core-utils';

export enum PACKSHOT_TEST_IDS {
    CONTAINER_BUTTON = 'container_button',
    IMAGE = 'image',
    CHANNEL_OVERLAY = 'channel_overlay',
    ACTION_BUTTON = 'action_button',
    LIVE_BADGE = 'live_badge',
    PROGRESS_BAR = 'progress_bar',
    TITLE = 'title',
    METADATA = 'metadata',
}

export const getPackshotTestID = createTestIDGetter('packshot');
