import { NavScreenConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useTranslation } from 'react-i18next';
import { Step } from '../types';

export const findParentSubmenuPageByMenuTitle = (
    screens: (NavScreenConfigWeb & { title?: string })[],
    screenTitle?: string | string[]
): NavScreenConfigWeb | null | undefined => {
    if (!screenTitle || typeof screenTitle !== 'string') return null;

    const parentScreen = screens.find((screen) => screen.title === screenTitle);

    return parentScreen;
};

export const mapPrevStep: Record<Step, Step | undefined> = {
    [Step.Current]: undefined,
    [Step.AddSelection]: Step.Current,
    [Step.AddConfirm]: Step.AddSelection,
    [Step.ChangeSelection]: Step.Current,
    [Step.ChangeConfirm]: Step.ChangeSelection,
    [Step.Cancel]: Step.ChangeSelection,
    [Step.CancelSuccess]: Step.Current,
    [Step.Success]: Step.Current,
    [Step.UnableChange]: Step.UnableChange,
};

export const getStepTitle = (step: Step, t: (label: string) => string): string | undefined => {
    switch (step) {
        case Step.Current:
            return t('svod.manage.title');
        case Step.AddSelection:
            return t('svod.add.title');
        case Step.AddConfirm:
            return t('svod.add.confirmSubscription.title');
        case Step.ChangeSelection:
        case Step.ChangeConfirm:
            return t('svod.change.title');
        case Step.Cancel:
            return t('svod.cancel.cancelConfirm.title');
        default:
            return undefined;
    }
};

export const GetBackButtonTitle = (step: any) => {
    const { t } = useTranslation();
    const previousStep = mapPrevStep[step];

    if (step === Step.CancelSuccess) {
        return undefined;
    }
    if (previousStep !== undefined) {
        return `${t('common.backTo')} ${getStepTitle(previousStep, t)}`;
    }
    return `${t('common.backTo')} ${t('account.title')}`;
};
