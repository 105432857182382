// Error Code Map
export enum ErrorCodes {
    GEO_BLOCKING = 'GEO_BLOCKING',
    IMAGE_NOT_FOUND = 'IMAGE_NOT_FOUND',
    ILLEGAL_ACCESS = 'ILLEGAL_ACCESS',
    UNEXPECTED_FORMAT = 'UNEXPECTED_FORMAT',
    RESTRICTED_AGE = 'RESTRICTED_AGE',
    RESTRICTED_ACCESS = 'RESTRICTED_ACCESS',
    STREAM_BLOCKED = 'STREAM_BLOCKED',
    ITEM_NOT_FOUND = 'ITEM_NOT_FOUND',
    UNCONFIRMED_USER = 'UNCONFIRMED_USER',
    USER_PROFILE_NOT_FOUND = 'USER_PROFILE_NOT_FOUND',
    NOT_SUPPORTED_BY_PROVIDER = 'NOT_SUPPORTED_BY_PROVIDER',
    CUSTOMER_PAYMENT_ERROR = 'CUSTOMER_PAYMENT_ERROR',
    CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
    CUSTOMER_ALREADY_REGISTERED = 'CUSTOMER_ALREADY_REGISTERED',
    USER_EMAIL_ALREADY_EXISTS = 'USER_EMAIL_ALREADY_EXISTS',
    NON_REMOVABLE_PROFILE = 'NON_REMOVABLE_PROFILE',
    BAD_PIN_CODE = 'BAD_PIN_CODE',
    BAD_CREDENTIALS = 'BAD_CREDENTIALS',
    USER_NOT_AUTHENTICATED = 'USER_NOT_AUTHENTICATED',
    INVALID_AUTHENTICATION_TOKEN = 'INVALID_AUTHENTICATION_TOKEN',
    INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',
    DEVICE_OUT_OF_LIMIT = 'DEVICE_OUT_OF_LIMIT',
    MAX_CONCURRENT_STREAMS_REACHED = 'MAX_CONCURRENT_STREAMS_REACHED',
    CONNECT_CODE_NOT_VALID = 'CONNECT_CODE_NOT_VALID',
    CONNECT_CODE_NOT_SUPPORTED = 'CONNECT_CODE_NOT_SUPPORTED',
    PROVIDER_NOT_CONFIGURED = 'PROVIDER_NOT_CONFIGURED',
    PROVIDER_NOT_SUPPORTED_ACTION = 'PROVIDER_NOT_SUPPORTED_ACTION',
    UNABLE_TO_CREATE_PROFILE = 'UNABLE_TO_CREATE_PROFILE',
    UNABLE_TO_UPDATE_PROFILE = 'UNABLE_TO_UPDATE_PROFILE',
    SUBSCRIPTION_PROVIDER_NOT_CONFIGURED = 'SUBSCRIPTION_PROVIDER_NOT_CONFIGURED',
    IDENTITY_PROVIDER_NOT_CONFIGURED = 'IDENTITY_PROVIDER_NOT_CONFIGURED',
    VENDOR_NOT_CONFIGURED = 'VENDOR_NOT_CONFIGURED',
    SERVICE_NOT_FOUND = 'SERVICE_NOT_FOUND',
    USER_INVALID_EMAIL = 'USER_INVALID_EMAIL',
    INSUFFICIENT_PASSWORD = 'INSUFFICIENT_PASSWORD',
    USER_INVALID_NAME = 'USER_INVALID_NAME',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export interface ErrorCodeProps {
    errorCode: ErrorCodes;
    defaultErrorMessage: string | undefined;
    httpCode: number;
    errorMessageCode: string | undefined;
}

// Error Mapper
export interface ErrorCodeMapperProps {
    errorCode: string;
    options?: ErrorCodeMapperOptions;
}
export interface ErrorCodeMapperOptions {
    returnMode: RETURN_MODE;
}
export enum RETURN_MODE {
    PLAIN_ERROR_MESSAGE_CODE, // return only errorMessageCode
    WHOLE_MAP_OBJECT, // return all ErrorCodeProps
}
