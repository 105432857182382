import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DeviceItemStyles } from './types';

export const getStyles = (theme: Theme): DeviceItemStyles => {
    return {
        deviceContainer: {
            flexDirection: 'row',
            paddingTop: 20,
            paddingBottom: 13,
            alignItems: 'flex-start',
            borderBottomWidth: 1,
            borderColor: theme.color.lighter3,
        },
        textsContainer: {
            top: -5,
            marginLeft: 16,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexGrow: 1,
        },
        name: {
            color: theme.color.textPrimary,
            ...getFont(theme, 'h4'),
        },
        added: {
            color: theme.color.textPrimary,
            opacity: 0.6,
            ...getFont(theme, 'h5'),
        },
        remove: {
            color: theme.color.textPrimary,
            ...getFont(theme, 'h6'),
            textTransform: 'uppercase',
            paddingRight: 10,
        },
    };
};
