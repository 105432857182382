export enum CHROMECAST_PLAYER_CONTROLS_TEST_IDS {
    PREVIOS_EPISODE = 'previous_episode',
    SEEK_FORWARD = 'seek_forward',
    SEEK_BACKWARD = 'seek_backward',
    ACTION_BUTTON = 'action_button',
    NEXT_EPISODE = 'next_episode',
    PROGRESS_SLIDER = 'progress_slider',
    VOLUME_SLIDER = 'volume_slider',
    VOLUME_BUTTON = 'volume_button',
    VOLUME_SLIDER_HIDE_BUTTON = 'volume_slider_hide_button',
    SUBTITLES_BUTTON = 'subtitles_button',
}
