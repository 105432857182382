import React, { FC, createContext, useContext } from 'react';
import { ProjectIconsProps } from './types';
import { defaultIconsMap } from './defaultIconsMap';

const contextDefaults: ProjectIconsProps = { projectIcons: {} };

const ProjectIconsContext = createContext<ProjectIconsProps>(contextDefaults);

export const ProjectIconsProvider: FC<ProjectIconsProps> = ({ children, projectIcons = {} }) => {
    const mergedIcons = { ...defaultIconsMap, ...projectIcons };

    return (
        <ProjectIconsContext.Provider value={{ projectIcons: mergedIcons }}>
            {children}
        </ProjectIconsContext.Provider>
    );
};

export const useProjectIcons = () => useContext(ProjectIconsContext);
