import React from 'react';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { useTheme } from '@24i/nxg-sdk-higgs';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import { getTextPagesStyles } from '../styles';
import { TextPageScreenProps } from '../types';
import HtmlComponent from '../../../components/HtmlComponent';

const TextPageScreen = (props: TextPageScreenProps) => {
    const { content = null, isLoading = false, styles: getStyles = getTextPagesStyles } = props;
    const dimensions = useDimensions();
    const { theme } = useTheme();
    const styles = getStyles({ theme, dimensions });

    if (isLoading || content === null) {
        return (
            <Loader additionalStyles={styles.loader} color={theme.color.textPrimary} size="large" />
        );
    }

    const html = content?.content?.text || '<p></p>';
    const title = content?.content?.title || '';

    return (
        <View style={styles.wrapper}>
            <View style={styles.textContainer}>
                <Text style={styles.title}>{title}</Text>
                <HtmlComponent html={html} />
            </View>
        </View>
    );
};

export default TextPageScreen;
