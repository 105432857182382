import { BaseApi, BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { log } from '@24i/nxg-core-utils/src/logger';
import {
    AnalyticsDataClient,
    AnalyticsEventAdditionalInfo,
    AnalyticsEventProps,
} from '@24i/nxg-sdk-photon';
import { getDeviceId } from '@24i/nxg-core-utils/src';

const resolveDeviceId = async (
    generatedId: string | undefined,
    propDeviceId: string | undefined
) => {
    const deviceId = generatedId ?? propDeviceId ?? (await getDeviceId());

    if (!deviceId) {
        throw new Error(
            "Generated device ID is required if device id wasn't previously stored in the session"
        );
    }

    return deviceId;
};

export class BackstageAnalyticsDataClient extends BaseApi implements AnalyticsDataClient {
    // Sending analytics events to Backstage
    async logAnalyticsEventToBackstage(
        eventName: string,
        eventProps: AnalyticsEventProps,
        additionalInfo: AnalyticsEventAdditionalInfo
    ) {
        const { key, generatedId, deviceId: propDeviceId } = additionalInfo;

        const deviceId = await resolveDeviceId(generatedId, propDeviceId);

        try {
            const body = key
                ? {
                      eventName,
                      properties: eventProps,
                      key,
                  }
                : { eventName, properties: eventProps };

            const response = await this.request<string>({
                path: '/analytics/events',
                method: 'POST',
                headers: {
                    'X-Device-ID': deviceId,
                },
                body,
            });

            // Console logging is here to prove request are being sent (a requirement for this issue)
            log('RESPONSE', response);
        } catch (err) {
            log('ERROR SENDING ANALYTICS EVENT', err);

            throw err;
        }
    }
}

export const createBackstageAnalyticsDataClient = (params: BaseApiParams) => {
    return new BackstageAnalyticsDataClient(params);
};
