import React, { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { isPlatformWeb } from 'renative';
import getBlockedModalStyles from '../BlockedModal/styles';
import { DIALOG_MODAL_TEST_IDS } from '../BlockedModal/types';

export type PinRequiredCause = 'adult' | 'age-limit';

interface Props {
    onClose?: () => void;
    triggerPinControl: () => void;
    closeModal: () => void;
    cause: PinRequiredCause;
}

const PodcastPinBlockedModal: FC<Props> = ({
    onClose,
    triggerPinControl,
    closeModal,
    cause = 'adult',
}) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getBlockedModalStyles(theme);
    const { setModalProps } = useModal();

    const onContinue = () => {
        triggerPinControl();
        closeModal();
    };

    useEffect(() => {
        setModalProps({
            onModalClose: onClose,
        });
    }, []);

    return (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <View style={styles.container} testID={DIALOG_MODAL_TEST_IDS.MODAL_WINDOW}>
                <Text style={styles.title} testID={DIALOG_MODAL_TEST_IDS.TITLE}>
                    {t('error.E09.title')}
                </Text>
                <Text style={styles.subtitle} testID={DIALOG_MODAL_TEST_IDS.SUBTITLE}>
                    {cause === 'adult'
                        ? t('pin.enter.contentLockedAdult.description')
                        : t('pin.enter.ageRestriction.description')}
                </Text>
                <View style={styles.buttonsContainer}>
                    <SubactionButton
                        title={t(`player.unlock`)}
                        onPress={onContinue}
                        disableATVonPressLimiter
                        additionalTextStyles={styles.actionButtonText}
                        additionalContainerStyles={styles.actionButtonContainer}
                        testID={DIALOG_MODAL_TEST_IDS.BUTTON_CONFIRM}
                    />

                    <SubactionButton
                        title={t('common.cancel')}
                        onPress={onClose}
                        disableATVonPressLimiter
                        additionalContainerStyles={[
                            styles.closeButtonContainer,
                            {
                                alignSelf: isPlatformWeb ? 'flex-end' : null,
                            },
                            !isPlatformWeb && { marginTop: 24 },
                        ]}
                        additionalTextStyles={!isPlatformWeb && { marginLeft: 0 }}
                        testID={DIALOG_MODAL_TEST_IDS.BUTTON_CANCEL}
                    />
                </View>
            </View>
        </View>
    );
};

export default PodcastPinBlockedModal;
