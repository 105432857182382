import View, {
    defaultRenderSingleRow,
    defaultRenderPackshotGrid,
    defaultRenderSlider,
    defaultRenderBannerCarousel,
} from './View';

export {
    defaultRenderSingleRow,
    defaultRenderPackshotGrid,
    defaultRenderSlider,
    defaultRenderBannerCarousel,
};

export default View;
