import React from 'react';

import View from './view';
import { useViewModel } from './viewModel';
import getStyles from './styles';
import { ConfirmPurchaseProps } from './types';

/** ConfirmPurchase component can be used as a step for purchases inside a modal, but can also bue used
 *  outside a Modal. When it is used inside a Modal, don't forget to add the flag isModalContent={true}
 *  to avoid nested modals.
 */
const Main = (props: ConfirmPurchaseProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export { View, useViewModel, getStyles };

export default Main;
