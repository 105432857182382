import React, { createContext, useContext, ComponentType } from 'react';
import { logWarn } from '@24i/nxg-core-utils/src/logger';

// Global flag to stop recursions so overridable component
// Can be used in componentOverrides directly without need
// to export also withoutOverride version out of the sdk.
let looping = false;

export type ComponentOverridesContextType = { components: Record<string, ComponentType<unknown>> };

const ComponentOverridesContext = createContext<ComponentOverridesContextType>({
    components: {},
});

export const ComponentOverridesProvider = ({ children, components }) => (
    <ComponentOverridesContext.Provider value={{ components }}>
        {children}
    </ComponentOverridesContext.Provider>
);

export const useComponentOverrides = () => useContext(ComponentOverridesContext);

// Typescript has issues with ForwardRefExoticComponent when the TProps extends anything other than {} or any
// eslint-disable-next-line @typescript-eslint/ban-types
export const overridable = <TProps extends {}, TForwardedRef>(
    Component: ComponentType<TProps> | ComponentType<TProps & React.RefAttributes<TForwardedRef>>,
    componentId: string
) =>
    // Default props is a false positive https://github.com/yannickcr/eslint-plugin-react/issues/2856
    // eslint-disable-next-line react/require-default-props, react/display-name
    React.forwardRef<TForwardedRef, TProps & { disableOverride?: boolean }>((props, ref) => {
        const { disableOverride, ...propsToSend } = props;
        const { components } = useComponentOverrides();
        const Override = components?.[componentId];

        // Looping flag is all I need to stop recursions.
        // If I get into a recursion it can always be only terminated by returning
        // the original component without the override.

        // JavaScript is single threaded language by design,
        // so there is no way that some other recursion
        // can be executing at the same time.

        // Even the children of the components are not
        // touched in any way. It never gets to them until
        // the recursion is resolved. So there is no way
        // some child that has the override too can interfere with
        // this flag.
        if (looping && !disableOverride) {
            logWarn(`[overridable] WARNING! you are either dangerously setting
        [disableOverride] to false or not passing original props for
        overridable[${componentId}]`);
        }

        if (Override && !looping && !props.disableOverride) {
            looping = true;

            // * We actually do not care what props does the component accept, so we can cast to any here
            return <Override ref={ref} {...(propsToSend as any)} disableOverride />;
        }

        // There is either no override set or we are at the end
        // of a recursion
        looping = false;

        // * We actually do not care what props does the component accept, so we can cast to any here
        return <Component ref={ref} {...(propsToSend as any)} />;
    });

export default ComponentOverridesContext;
