import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Breakpoint } from '../../../../../utils/styles/constants';
import { SettingsOptionPageStyles } from '../types';

const SettingsOptionPage = (theme): SettingsOptionPageStyles => ({
    subheaderText: {
        color: theme.color.textPrimary,
        opacity: 0.8,
        ...getFont(theme, 'h5'),
    },
    descriptionText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
        width: '70%',
    },
    container: {
        borderBottomWidth: 0.5,
        borderBottomColor: theme.color.lighter3,
    },
    itemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
    },
    dropdownToggler: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    icon: {
        marginLeft: 8,
    },
    dropdownContainer: {
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: 'auto',
            right: 0,
            minWidth: 0,
            maxWidth: 112,
        },
        position: 'absolute',
        alignSelf: 'flex-end',
        backgroundColor: theme.color.darker1,
        borderRadius: 4,
        top: 32,
        minWidth: 224,
        paddingVertical: 12,
        zIndex: 9999,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
});

export default SettingsOptionPage;
