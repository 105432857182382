import React, { ReactElement, useMemo } from 'react';
import { View, Text } from 'react-native';
import { getAllItemsItemContentStyles } from './styles';
import { AllItemsItemContentProps } from './types';

export const AllMvpdsItemContent = (props: AllItemsItemContentProps): ReactElement => {
    const { mvpd, theme } = props;
    const style = useMemo(() => getAllItemsItemContentStyles(theme), [theme]);
    return (
        <View style={style.textContainer}>
            <Text style={style.textStyle} numberOfLines={1}>
                {mvpd.title}
            </Text>
        </View>
    );
};
