export enum UPSERT_PROFILE_MODES {
    EDIT = 'EDIT',
    CREATE = 'CREATE',
}

export const DESKTOP_MAX_WIDTH = 590;
export const MOBILE_MAX_WIDTH = 480;

export const DEFAULT_CONTENT_RATINGS = [0, 6, 9, 16, 18];

export const KNOWN_CONTENT_RATINGS: Record<string, number[]> = {
    NICAM: [0, 6, 9, 12, 14, 16, 18, 22],
    PEGI: [3, 7, 12, 16, 18],
    MPAA: [0, 13, 18],
};

export const MAX_PROFILE_NAME_LENGTH = 15;
