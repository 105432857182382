import React from 'react';

import { View, Text } from '@24i/nxg-sdk-quarks';

import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { OnboardingPageProps } from '../types';
import OnboardingHeader from '../../../navigation/components/OnboardingHeader';

const OnboardingPage = ({
    title,
    description,
    buttonLabel,
    styles,
    onPress,
}: OnboardingPageProps) => {
    return (
        <>
            <OnboardingHeader />
            <View style={styles.container}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.description}>{description}</Text>
                <SubactionButton
                    title={buttonLabel}
                    onPress={onPress}
                    additionalContainerStyles={styles.buttonContainer}
                    additionalTextStyles={styles.buttonLabel}
                    disableATVonPressLimiter
                />
            </View>
        </>
    );
};

export default OnboardingPage;
