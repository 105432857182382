/* eslint-disable max-len */
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Platform } from '@24i/nxg-sdk-quantum';
import { Interactable, Text } from '@24i/nxg-sdk-quarks';
import React, { useState } from 'react';
import { ButtonProps } from './types';

const isTV = { Platform };

const defaultStyles = () => ({
    container: {
        borderRadius: 50,
        borderWidth: 1,
        backgroundColor: 'transparent',
    },
    containerFocused: {},
    textStyle: {
        textAlign: 'center',
        fontSize: 20,
        lineHeight: isTV ? 60 : 50,
        height: isTV ? 60 : 50,
        fontWeight: '700',
    },
    active: {
        backgroundColor: 'rgba(100,100,170, 0.5)',
    },
    textStyleFocused: {
        color: 'white',
    },
});

const Button = (props: ButtonProps) => {
    const [focused, setFocused] = useState(false);

    const onFocus = () => {
        if (props.onFocus) props.onFocus();
        setFocused(true);
    };

    const onBlur = () => {
        if (props.onBlur) props.onBlur();
        setFocused(false);
    };

    const {
        color = '#20486c',
        onPress = () => {},
        isActive = false,
        testID = '',
        styles: getStyles = defaultStyles,
        title = '',
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <Interactable
            activeOpacity={isTV ? 1 : 0.2}
            onFocus={onFocus}
            onBlur={onBlur}
            onPress={onPress}
            style={[
                styles.container,
                focused && {
                    ...styles.containerFocused,
                    ...{ borderColor: color, backgroundColor: color },
                },
                isActive && styles.active,
                { borderColor: color },
            ]}
            testID={testID}
        >
            <Text style={[{ ...styles.textStyle, color }, focused && styles.textStyleFocused]}>
                {title}
            </Text>
        </Interactable>
    );
};

export default overridable(Button, 'Button');
