import { createEnum } from '@24i/nxg-core-utils/src/guards';

export enum ROW_TYPES {
    SINGLE_ROW = 'single_row',
    SINGLE_HIGHLIGHT = 'single_highlight',
    DOUBLE_ROW = 'double_row',
    DOUBLE_HIGHLIGHTED_ROW = 'double_highlighted_row',
    GRID = 'grid',
    SLIDER = 'slider',
    HERO_SLIDER = 'hero_slider',
    HEADER = 'header',
}

export enum BLOCKING_REASON_TYPES {
    PRIMETIME_AUTHZ_ERR = 'PRIMETIME_AUTHZ_ERR',
    GEO_TYPE = 'GEO',
    SUBSCRIPTION_TYPE = 'SUBSCRIPTION',
    MVPD_TYPE = 'MVPD',
    BLOCKED_WINDOW = 'BLOCKED_WINDOW',
    ADULT = 'ADULT',
    AGE = 'AGE',
    REQUIRES_PURCHASE = 'REQUIRES PURCHASE',
    AUTHENTICATION = 'AUTHENTICATION',
}

export enum BLOCKING_REASON_MESSAGES {
    GEO = 'E05',
    AGE = 'E09',
    ADULT = 'F10',
    SUBSCRIPTION = 'E21',
}

export const BLOCKING_REASON_GUARD = createEnum<BLOCKING_REASON_TYPES>(
    BLOCKING_REASON_TYPES,
    'BlockingReasonTypes'
);

export const ROW_TYPES_GUARD = createEnum<ROW_TYPES>(ROW_TYPES, 'RowTypes');

export enum ACCESS_MODE {
    /**
     * Mode ALL is used for the screens that are accessible for non-authenticated users
     * even when the guest mode is disabled. E.g. sign in, sign up, landing.
     */
    ALL = 0,
    /**
     * Mode GUEST is used for the screens that are accessible for non-authenticated users.
     * To show a such screens, the guest mode must be anabled. E.g. home, details, epg.
     */
    GUEST = 10,
    /**
     * Mode USER is used for the screens that are accessible only for authenticated users.
     * To show a such screens, an user must be logged in. Otherwise the authentication flow (appStart sequence)
     * should be triggered and after a successful authentication the user should be redirected
     * back to the original screens.
     */
    USER = 20,
    /**
     * Mode ANONYMOUS_ONLY is used for the screens that are accessible only for non-authenticated users.
     * To show a such screens, an user mustn't be logged in (e.g. welcome, landing, sign in/up, etc.).
     * Otherwise the user should be redirected to Home Screen directly.
     */
    ANONYMOUS_ONLY = 30,
}

/**
 *
 * An enum of all screen types supported by the product.
 */
export enum PRODUCT_SCREENS {
    SUB_MENU = 'SubMenu',
    HOME = 'Home',
    DYNAMIC_CONTENT = 'DynamicContent',
    SETTINGS = 'Settings',
    EPG = 'Epg',
    SEARCH = 'Search',
    EXTERNAL = 'External',
    GENRE = 'Genre',
    PLAYBACK = 'Playback',
    SIGN_IN = 'SignIn',
    MVPD_SIGNIN = 'MvpdSignIn',
    SIGN_UP = 'SignUp',
    SIGN_UP_QR = 'QR-SignUp',
    FORGOT_PASS = 'ForgotPassword',
    MORE = 'More',
    MANAGE_PROFILES = 'ManageProfiles',
    WHO_IS_WATCHING = 'WhoIsWatching',
    DETAILS = 'Details',
    ACCOUNT = 'Account',
    MY_CONTENT = 'MyContent',
    MY_PURCHASES = 'MyPurchases',
    MY_LIST = 'MyList',
    MY_SUBSCRIPTIONS = 'MySubscriptions',
    RECORDINGS = 'Recordings',
    RECENTLY_WATCHED = 'RecentlyWatched',
    NOT_FOUND = 'NotFound',
    LANDING = 'Landing',
    WELCOME = 'Welcome',
    TERMS_AND_CONDITIONS = 'TermsAndConditions',
    UPSERT_PROFILE = 'UpsertProfile',
    CUSTOM = 'Custom',
    TEXT_PAGE = 'TextPage',
    REMOVE_DEVICE = 'RemoveDevice',
    ONBOARDING_INTRO = 'OnboardingInfo',
    ADDITIONAL_INFO = 'AdditionalInfo',
    SEE_ALL = 'SeeAll',
    ADD_SUBSCRIPTION = 'AddSubscription',
    PURCHASE_SUBSCRIPTION = 'PurchaseSubscription',
    PAYMENT_METHOD = 'PaymentMethod',
    PAYMENT_HISTORY = 'PaymentHistory',
    PAYMENT_RESULT = 'PaymentResult',
    SUBSCRIPTIONS_LIST = 'SubscriptionsList',
    CREATE_PIN = 'CreatePin',
    ACCOUNT_DETAILS = 'AccountDetails',
    DELETE_ACCOUNT = 'DeleteAccount',
    DELETE_ACCOUNT_SUCCESS = 'DeleteAccountSuccess',
    ACCOUNT_SUBSCRIPTION = 'AccountSubscription',
    ACCOUNT_PARENTAL_CONTROLS = 'AccountParentalControls',
    SIGN_OUT = 'SignOut',
    FALLBACK = 'Fallback',
    PLAYLIST = 'Playlist',
    LANGUAGE = 'Language',
    TECHNICAL_INFO = 'TechnicalInfo',
    THEME = 'Theme',
    STREAMING = 'Streaming',
    SERVICE = 'Service',
    MODAL = 'Modal',
    TERMS_OF_USE = 'TermsOfUse',
    MANAGE_DEVICES = 'ManageDevices',
    SIGN_UP_SUCCESS = 'SignUpSuccess',
    PAGE = 'Page',
    TV_GUIDE = 'TvGuide',
    SECOND_SCREEN_ACTIVATION = 'SecondScreenActivation',
}

export const SECTION_ITEMS_ACTION = {
    NAVIGATE_TO_PLAYER: 'player',
    NAVIGATE_TO_DETAILS: 'detail',
    NAVIGATE_TO_LANDING: 'landing',
};

export enum SINGLE_ROW_TYPE {
    EPISODE_LIST = 'episode_list',
    LIVE_CHANNEL = 'live_channel',
}

export enum ASSET_TYPE {
    IMAGE = 'image',
    EPG = 'epg',
    EPISODE = 'episode',
    LIVE_EVENT = 'live_event',
    TEXT_PAGE = 'textpage',
    MOVIE = 'movie',
    SERIES = 'series',
    CHANNEL = 'channel',
    BROADCAST = 'broadcast',
    HLS = 'hls',
    MP3 = 'mp3',
    MP4 = 'mp4',
    DASH = 'dash',
    CLIP = 'clip',
    CUSTOM = 'custom_item',
    EXTERNAL = 'external',
    PAGE = 'page',
    PODCAST_SERIES = 'podcast',
}

export enum RECORDING_STATUS {
    SCHEDULED = 'scheduled',
    PARTIAL = 'partial',
    COMPLETED = 'completed',
}

export enum PLAYLIST_TYPE {
    PURCHASED = 'purchased',
    RENTALS = 'rentals',
    EXPIRED_RENTALS = 'expired-rentals',
}

export enum AGE_CLASSIFICATION_TYPE {
    NICAM = 'NICAM',
    PEGI = 'PEGI',
}

export const AGE_CLASSIFICATION_TYPE_GUARD = createEnum<AGE_CLASSIFICATION_TYPE>(
    AGE_CLASSIFICATION_TYPE,
    'AgeClassificationType'
);

export const ASSET_TYPE_GUARD = createEnum<ASSET_TYPE>(ASSET_TYPE, 'AssetType');

export const RECORDING_STATUS_GUARD = createEnum<RECORDING_STATUS>(
    RECORDING_STATUS,
    'RecordingStatus'
);

export enum ELEMENT_PLACEMENT {
    OVER = 'over',
    BELLOW = 'bellow',
    ABOVE = 'above',
}

export enum QUERY_KEYS {
    appTheme = 'appTheme',
    config = 'config',
    user = 'user',
    profiles = 'profiles',
    welcomeScreens = 'welcomeScreens',
    termsAndConditions = 'termsAndConditions',
    menu = 'menu',
    navigationConfig = 'navigationConfig',
    continueWatchingPlaylist = 'ContinueWatchingPlaylist',
    playlists = 'playlists',
    sectionsStructure = 'sectionsStructure',
    lastWatchPlaylist = 'lastWatchPlaylist',
    recordings = 'recordings',
    storageInfo = 'storageInfo',
    pinValidation = 'pinValidation',
    myFavoritesPlaylist = 'MyFavoritesPlaylist',
    myFavorites = 'MyFavorites',
    mySubscriptions = 'MySubscriptions',
    availableSubscriptions = 'AvailableSubscriptions',
    entitledSubscriptions = 'EntitledSubscriptions',
    assets = 'assets',
    assetExtras = 'extras',
    assetRelated = 'related',
    assetSeasons = 'seasons',
    assetRating = 'rating',
    epg = 'epg',
    channels = 'channels',
    editions = 'editions',
    blockers = 'blockers',
    isPurchased = 'isPurchased',
}

export enum RATING_SYSTEM {
    FIVESTARS = '5stars',
    FOURSTARS = '4stars',
    THUMBS = 'thumbs',
    ONEHUNDRED = '100',
    ONETEN = '10',
}

export enum EPG_TYPE {
    NONE = 0,
    PAST = 1,
    FUTURE = 2,
}

export const RATING_SYSTEM_GUARD = createEnum<RATING_SYSTEM>(RATING_SYSTEM, 'RatingSystem');

export enum TIMELINE_EVENT_TYPE {
    CREDITS = 'credits',
    RECAP = 'recap',
    INTRO = 'intro',
}

export const TIMELINE_EVENT_TYPE_GUARD = createEnum<TIMELINE_EVENT_TYPE>(
    TIMELINE_EVENT_TYPE,
    'TimelineEventType'
);

// Limit difference defined here: https://docs.unified-streaming.com/documentation/live/streaming.html?highlight=dvr_window_length#pure-live
export const PURE_LIVE_DVR = 30000;

export enum OfferType {
    SUBSCRIPTION = 'subscription',
    PURCHASE = 'purchase',
    PAYMENT_METHOD = 'paymentMethod',
}
