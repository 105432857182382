import { Platform } from '@24i/nxg-sdk-quantum';

import { useFeature } from '../../context/AppSettingsData';

export const useArePurchasesEnabled = (): boolean => {
    const inAppPurchasesFeature = useFeature('inAppPurchases');
    return Boolean(
        inAppPurchasesFeature &&
            inAppPurchasesFeature.enabled &&
            !inAppPurchasesFeature.disabledPlatforms?.includes?.(Platform.OS)
    );
};
