import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { isPlatformWeb } from 'renative';
import { DESKTOP_MAX_WIDTH } from '../../UpsertProfileScreen/constants';

const getStyles = () => ({
    container: {
        flex: 1,
        marginTop: DeviceInfo.isTablet() ? 50 : 30,
        paddingHorizontal: isPlatformWeb ? undefined : 20,
        width: DeviceInfo.isTablet() || isPlatformWeb ? DESKTOP_MAX_WIDTH : '100%',
        alignSelf: 'center',
    },
});

export default getStyles;
