import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View, ScrollView, SafeAreaView, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { Toggle } from '@24i/nxg-sdk-gluons';
import { isFactorMobile } from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import BackButton from '@24i/nxg-sdk-gluons/src/components/buttons/BackButton';
import RemoveDevice from '../components/RemoveDevice';
import { getBottomBarHeight } from '../../../../../utils';
import getSettingsDeviceManagementStyles from '../styles';

const SettingsDeviceManagement = (props) => {
    const {
        confirmText,
        cancelText,
        removeTitle,
        removeSubtitle,
        deviceData,
        styles: getStyles,
        iconColor,
        title,
        onBack,
        isLandscape,
        trackerTrue,
        trackerFalse,
        canToggle,
        canRemove,
        enabledConfirmation,
    } = props;

    const [devices, setDevices] = useState(deviceData);
    const [selectedDevice, setSelectedDevice] = useState();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const updateDevices = (dev) => setDevices(dev);

    const toggleSelected = (deviceId) => {
        const { onTogglePress } = props;

        // Send custom function here
        if (onTogglePress) return onTogglePress(deviceId, devices, updateDevices);

        return Alert.alert('Switch toggled!');
    };

    const removePress = (deviceId) => {
        const { onRemovePress } = props;

        // Send custom function here
        setSelectedDevice();

        if (onRemovePress) {
            return onRemovePress(deviceId, devices, updateDevices);
        }

        return Alert.alert('Remove pressed!');
    };

    const cancelPress = () => {
        setSelectedDevice();
    };

    // map Array with list of all devices sended from props
    const Devices =
        deviceData &&
        devices.map((item, i) => {
            const { active, os, name, registered, isDisabled } = item;
            let icon = null;

            switch (os) {
                case 1:
                    icon = 'onPrimary-device_smartphone';
                    break;
                case 2:
                    icon = 'onPrimary-device_laptop';
                    break;
                case 3:
                    icon = 'onPrimary-device_tv';
                    break;
                case 4:
                    icon = 'onPrimary-device_tablet';
                    break;
                case 5:
                    icon = 'onPrimary-device_laptop';
                    break;
                default:
                    icon = 'onPrimary-device_laptop';
            }

            return (
                <View
                    key={item.name}
                    style={isLandscape ? styles.DevicesContainerLandscape : styles.DevicesContainer}
                >
                    <ThemeIcon
                        iconName={icon}
                        size={24}
                        iconStyles={[styles.deviceIcon, isDisabled && { opacity: 0.4 }]}
                    />
                    <View style={isDisabled && { opacity: 0.4 }}>
                        <Text style={styles.title}>{name}</Text>
                        {registered && (
                            <Text style={styles.text}>
                                {`Added ${registered?.toLocaleDateString('en-US', {
                                    minute: '2-digit',
                                    hour: 'numeric',
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                })}`}
                            </Text>
                        )}
                    </View>
                    <View style={styles.controlContainer}>
                        {canToggle && !isDisabled && (
                            <Toggle
                                onValueChange={() => toggleSelected(i)}
                                value={active}
                                trackColor={{
                                    true: trackerTrue,
                                    false: trackerFalse,
                                }}
                                ios_backgroundColor="rgb(72,72,72)"
                                style={styles.switch}
                                disabled={isDisabled}
                            />
                        )}
                        {canRemove && !isDisabled && (
                            <ThemeIcon
                                icon={{
                                    iconFont: 'materialIcons',
                                    iconName: 'close',
                                }}
                                size={24}
                                color={iconColor}
                                onPress={
                                    enabledConfirmation
                                        ? () => setSelectedDevice(i)
                                        : () => removePress(i)
                                }
                                additionalContainerStyles={styles.closeIcon}
                                disabled={isDisabled}
                            />
                        )}
                    </View>
                </View>
            );
        });

    return (
        <SafeAreaView style={[styles.container, isLandscape && { flex: 2 }]}>
            <ScrollView
                style={isLandscape ? styles.scrollContainerLandscape : styles.scrollContainer}
                contentContainerStyle={[
                    (isFactorMobile || DeviceInfo.isTablet()) && {
                        paddingBottom: getBottomBarHeight(),
                    },
                ]}
            >
                {!isLandscape ? (
                    <BackButton
                        onPress={onBack}
                        iconName="onPrimary-back"
                        iconSize={40}
                        title={title}
                        additionalContainerStyles={styles.headerContainer}
                        additionalTextStyles={styles.headerText}
                        additionalIconStyles={styles.headerIcon}
                    />
                ) : (
                    <BackButton
                        title={title}
                        additionalContainerStyles={styles.headerContainerLandscape}
                        additionalTextStyles={styles.headerTextLandscape}
                    />
                )}
                {/* List of devices generated here */}
                {Devices}
                <Modal visible={selectedDevice !== undefined}>
                    <RemoveDevice
                        iconColor={iconColor}
                        isLandscape={isLandscape}
                        confirmText={confirmText}
                        cancelText={cancelText}
                        removeTitle={removeTitle}
                        removeSubtitle={removeSubtitle}
                        onRemovePress={removePress}
                        onCancelPress={cancelPress}
                        selectedDevice={devices[selectedDevice]}
                        deviceId={selectedDevice}
                    />
                </Modal>
            </ScrollView>
        </SafeAreaView>
    );
};

SettingsDeviceManagement.defaultProps = {
    confirmText: 'Yes, remove',
    cancelText: 'Go back',
    removeTitle: 'Do you want to remove this device?',
    removeSubtitle: 'Too many people are using your account right now.',
    canToggle: true,
    canRemove: true,
    deviceData: null,
    styles: getSettingsDeviceManagementStyles,
    iconColor: '#FFFFFF96',
    trackerTrue: 'rgba(255,255,255,0.5)',
    trackerFalse: 'rgba(72,72,72,0.8)',
    enabledConfirmation: false,
};

SettingsDeviceManagement.propTypes = {
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    removeTitle: PropTypes.string,
    removeSubtitle: PropTypes.string,
    deviceData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    canToggle: PropTypes.bool,
    canRemove: PropTypes.bool,
    styles: PropTypes.func,
    trackerTrue: PropTypes.string,
    trackerFalse: PropTypes.string,
    onTogglePress: PropTypes.func.isRequired,
    onRemovePress: PropTypes.func.isRequired,
    enabledConfirmation: PropTypes.bool,
    iconColor: PropTypes.string,
};

export { getSettingsDeviceManagementStyles };
const LocalizedDeviceManagement = overridable(SettingsDeviceManagement, 'SettingsDeviceManagement');
export default LocalizedDeviceManagement;
