import { Broadcast } from '@24i/nxg-sdk-photon';
import IntervalTree from '@flatten-js/interval-tree';

export type Range = {
    start: number;
    end: number;
};

export class EpgCollection {
    loadedProgramsRangeIndex = {};
    tree = new IntervalTree();
    idIndex: Record<string, boolean> = {};

    addTreePrograms = (
        channelId: string,
        programs: Broadcast[],
        range: [start: number, end: number]
    ) => {
        programs.forEach((program) => {
            const index = this.getIndex(program);
            if (!this.idIndex[index]) {
                this.tree.insert([program.start, program.end], program);
                this.idIndex[index] = true;
            }
        });
        this.updateLoadedProgramsRangeIndex(channelId, { start: range[0], end: range[1] });
    };

    getIndex = (program: Broadcast) => {
        return `${program.id}${program.channelId}`;
    };

    getPrograms = (channelId: string, range: [start: number, end: number]) => {
        // @ts-ignore
        return this.tree.search(range).filter((result) => result.channelId === channelId);
    };

    mergeRanges(ranges: Range[]): Range[] {
        const result: Range[] = [];
        let last: Range;

        ranges.sort((a, b) => a.start - b.start);

        ranges.forEach((r) => {
            if (!last || r.start > last.end) result.push((last = r));
            else if (r.end > last.end) last.end = r.end;
        });
        return result;
    }

    /**
     * Records an index information about what data has been loaded for a give channel
     * @param channelId - ID of channel to be retrieved
     * @param range - range of start end of a given fetched program slice
     */
    updateLoadedProgramsRangeIndex(channelId: string, range: Range): void {
        if (!this.loadedProgramsRangeIndex[channelId])
            this.loadedProgramsRangeIndex[channelId] = [];
        this.loadedProgramsRangeIndex[channelId].push(range);
        this.loadedProgramsRangeIndex[channelId] = this.mergeRanges(
            this.loadedProgramsRangeIndex[channelId]
        );
    }

    /**
     * Checks if a given range is fully indexed in the loaded programs range index
     * @param channelId - ID of channel to be retrieved
     * @param range - range of start end of a given fetched program slice
     * @returns if the given range was indexed before or not
     */
    isLoadedInRange(channelId: string, range: Range): boolean {
        if (!this.loadedProgramsRangeIndex[channelId]) return false;
        for (const fetchedRange of this.loadedProgramsRangeIndex[channelId]) {
            if (range.start >= fetchedRange.start && range.end <= fetchedRange.end) {
                return true;
            }
        }
        return false;
    }
}
