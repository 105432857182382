import React, { ReactElement } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import getBackButtonStyles from '../styles';
import { IconButtonProps } from '../../IconButton/types';

const BackButton = (props: IconButtonProps): ReactElement => (
    <IconButton styles={getBackButtonStyles} testID="back_button" {...props} />
);

export { getBackButtonStyles };
export default overridable(BackButton, 'BackButton');
