import { getFont, getMargins, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { isPlatformAndroid } from 'renative';

const PageSettings = (theme) => ({
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        ...getMargins(),
    },
    scrollContainer: {
        flex: 1,
        width: 576,
        '@media (max-width: 596px)': {
            width: '100%',
        },
    },
    headerContainerLandscape: {
        flexDirection: 'column',
        marginTop: isPlatformAndroid ? 32 : 16,
        marginBottom: 64,
        paddingTop: isPlatformAndroid ? 16 : 8,
        paddingBottom: 8,
    },
    headerText: {
        marginTop: 8,
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h1'),
    },
    optionHeaderText: {
        marginTop: 8,
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h2'),
    },
    backButtonText: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        marginLeft: 4,
        ...getFont(theme, 'h5'),
    },
    subheaderText: {
        color: theme.color.textPrimary,
        marginTop: 15,
        marginLeft: 0,
        marginBottom: 8,
        ...getFont(theme, 'h4'),
        '@media (max-width: 596px)': {
            marginLeft: 8,
            marginTop: 8,
        },
    },
    optionIcon: {
        '@media (max-width: 596px)': {
            marginRight: 12,
        },
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '@media (max-width: 596px)': {
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
    },
    optionButton: {
        width: 280,
        height: 135,
        marginBottom: 16,
        cursor: 'pointer',
        backgroundColor: theme.color.primary3,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        '@media (max-width: 596px)': {
            width: '100%',
            height: 64,
            alignItems: 'flex-start',
            paddingLeft: 32,
            marginBottom: 12,
        },
        ':hover': { backgroundColor: theme.color.primary4 },
        transition: 'all 0.2s ease-in',
    },
    buttonContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-width: 596px)': {
            flexDirection: 'row',
        },
    },
});

export default PageSettings;
