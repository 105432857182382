import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, ThemeIcon, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton/View';
import { isPlatformWeb } from 'renative';
import { getStyles } from './styles';

interface Props {
    onAddSubscriptionPress: () => void;
}

const NoSubscriptions = ({ onAddSubscriptionPress }: Props) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const iconSize = isPlatformWeb ? 80 : 72;

    return (
        <View style={styles.wrapper}>
            <View style={styles.cartIconWrapper}>
                <ThemeIcon
                    iconName="onPrimary-subscription"
                    size={iconSize}
                    iconStyles={{ opacity: 0.2 }}
                />
            </View>
            <View style={styles.infoWrapper}>
                <Text style={styles.noSubscriptionsTitle}>{t('svod.manage.noData.title')}</Text>
                <View style={styles.subscriptionsShowUpHereLabelWrapper}>
                    <Text style={styles.subscriptionsShowUpHereLabel}>
                        {t('svod.manage.noData.description')}
                    </Text>
                </View>
            </View>
            <View style={styles.addSubscriptionButtonWrapper}>
                <ActionButton
                    onPress={onAddSubscriptionPress}
                    additionalContainerStyles={styles.buttonWrapper}
                    additionalTextStyles={styles.buttonText}
                    title={t('svod.add.title')}
                />
            </View>
        </View>
    );
};

export default NoSubscriptions;
