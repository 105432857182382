import { MenuItem, QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useAppStructureData } from '@24i/nxg-sdk-smartott-shared/src/context/AppStructureData';

import { useAppConfigQuery } from './useAppConfigQuery';

const INITIAL_STALE_TIME = 10 * 10000;

const getMenuConfigQueryKey = (language: string, menuId?: string) => [
    QUERY_KEYS.menu,
    language,
    menuId,
];

export const useMenuConfigQuery = () => {
    const client = useAppStructureData();
    const { appSettings } = useAppConfigQuery();
    const { i18n } = useTranslation();
    const { language } = i18n;
    const menuId = appSettings?.features?.menus?.main || undefined;

    const { isLoading, data, refetch } = useQuery<MenuItem[]>(
        getMenuConfigQueryKey(language, menuId),
        () => client.fetchMenu(menuId, appSettings),
        {
            enabled: Boolean(appSettings),
            staleTime: INITIAL_STALE_TIME,
        }
    );

    return {
        isLoading,
        menuConfig: data,
        refetch,
    };
};
