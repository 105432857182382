import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { LiveBadgeSharedStyles } from '../types';

export const getSharedStyles = (theme: Theme): LiveBadgeSharedStyles => ({
    liveBadgeText: {
        ...getFont(theme, 'h6'),
        fontWeight: 'bold',
        color: theme.color.textPrimary,
    },
    liveBadgePosition: {
        bottom: 11,
    },
});
