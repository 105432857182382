import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const ProductGuard = createGuard(
    'Product',
    {
        active: t.boolean,
        currency: t.string,
        description: nullable(t.string),
        externalAccessType: t.string,
        externalAccessValue: t.string,
        id: t.string,
        name: t.string,
        price: t.number,
        type: t.string,
        isHidden: t.boolean,
        paymentMethod: nullable(t.string),
        billingFrequency: t.type({
            unit: t.string,
            count: t.number,
        }),
    },
    { options: t.unknown }
);

export type Product = t.TypeOf<typeof ProductGuard>;

export const isProduct = (data: unknown): data is Product => {
    try {
        return !!decodeModelWith(ProductGuard, data, 'Product', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createProduct = (data: unknown): Product | never =>
    decodeModelWith(ProductGuard, data, 'Product');
