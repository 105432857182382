import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { View, Image, ImageBackground } from '@24i/nxg-sdk-quarks';
import { Theme } from '@24i/nxg-sdk-photon';
import { useTheme } from '@24i/nxg-sdk-higgs';
import GetCreateCarouselItemStyles from './styles';
import { CreateCarouselItemStyles } from './styles/types';

interface CarouselItemProps {
    styles?: (theme: Theme) => CreateCarouselItemStyles;
    item?: { url: string };
}

const CarouselItem = ({
    styles: getStyles = GetCreateCarouselItemStyles,
    item,
}: CarouselItemProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { width, height } = useDimensions();

    return (
        <View style={[styles.itemWrapper, { width, height }]}>
            {item?.url ? (
                <ImageBackground style={styles.welcomeImage} source={{ uri: item?.url }} />
            ) : (
                <Image
                    style={styles.placeholderImage}
                    source={typeof theme.logo === 'number' ? theme.logo : { uri: theme.logo }}
                />
            )}
        </View>
    );
};

export default overridable(CarouselItem, 'CarouselItem');
