import React, { useEffect, useState } from 'react';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { View, Text } from '@24i/nxg-sdk-quarks';
import GenericModal from '@24i/nxg-sdk-smartott/src/components/GenericModal';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import SubActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import { validatePin } from '@24i/nxg-core-utils';
import getStyles from './styles';
import {
    CHANGEABLE_TEXT_TEST_IDS,
    ChangeableItemType,
    ChangeableTextModalProps,
} from '../../types';
import ChangeModalTextInput from '../ChangeModalTextInput';
import ChangeModalPinInput from '../ChangeModalPinInput';

const ChangeModal = (props: ChangeableTextModalProps): JSX.Element => {
    const { item, showModal, closeModal, errorMessage, shouldCheckPinLength, maxNameLength } =
        props;
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const [textInputValue, setTextInputValue] = useState('');

    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (showModal && item?.type === ChangeableItemType.INPUT) {
            setTextInputValue(item?.value || '');
        }
    }, [showModal, item]);

    const isConfirmEnabled =
        item?.type === ChangeableItemType.INPUT || !validatePin(textInputValue);

    const resetModal = (): void => {
        setIsFocused(false);
        closeModal();
    };

    const confirmInput = (): void => {
        if (!isConfirmEnabled) return;
        if (textInputValue === item?.value) {
            resetModal();
            return;
        }
        if (textInputValue !== item?.value && item?.confirmationHandler) {
            setIsFocused(false);
            item?.confirmationHandler(textInputValue, item?.itemKey);
        }
    };

    const acceptButtonStyle = () => {
        if (isPlatformWeb) {
            if (shouldCheckPinLength) {
                if (!validatePin(textInputValue)) {
                    return undefined;
                }
                return styles.inactiveButton;
            }
            return undefined;
        }

        return styles.acceptButton;
    };

    return (
        <GenericModal
            hasUpperTitle={!isPlatformWeb}
            requiresModalWrapper
            isVisible={showModal}
            title={{
                text: `${t('common.change')} ${
                    item?.type === ChangeableItemType.PIN ? item?.title : item?.title.toLowerCase()
                }`,
            }}
            closeModal={resetModal}
            customContainerStyle={isPlatformWeb ? undefined : { flex: 1 }}
            lightBackground
        >
            <View style={styles.wrapper}>
                {isPlatformWeb && item?.type === ChangeableItemType.PIN && (
                    <Text style={styles.description}>{t('pin.add.formatValidation')}</Text>
                )}
                <View
                    style={[
                        styles.textInputContainer,
                        {
                            backgroundColor: isFocused
                                ? theme.color.lighter1
                                : theme.color.lighter3,
                        },
                        !!errorMessage && styles.textInputContainerErrorState,
                    ]}
                >
                    {item?.type === ChangeableItemType.INPUT && (
                        <ChangeModalTextInput
                            item={item}
                            isFocused={isFocused}
                            setIsFocused={setIsFocused}
                            textInputValue={textInputValue}
                            setTextInputValue={setTextInputValue}
                            maxNameLength={maxNameLength}
                        />
                    )}
                    {item?.type === ChangeableItemType.PIN && (
                        <ChangeModalPinInput
                            item={item}
                            isFocused={isFocused}
                            setIsFocused={setIsFocused}
                            textInputValue={textInputValue}
                            setTextInputValue={setTextInputValue}
                        />
                    )}
                </View>
                {!!errorMessage && (
                    <Text
                        style={styles.textInputErrorText}
                        testID={CHANGEABLE_TEXT_TEST_IDS.ERROR_MESSAGE}
                    >
                        {errorMessage}
                    </Text>
                )}
                <View style={styles.buttonsContainer}>
                    {isPlatformWeb && (
                        <SubActionButton
                            onPress={resetModal}
                            title={t('common.cancel')}
                            testID={CHANGEABLE_TEXT_TEST_IDS.BUTTON_CANCEL}
                        />
                    )}
                    {!isPlatformWeb && item?.type === ChangeableItemType.PIN && (
                        <Text style={styles.description}>{t('pin.add.formatValidation')}</Text>
                    )}
                    <ActionButton
                        onPress={confirmInput}
                        disabled={shouldCheckPinLength && validatePin(textInputValue)}
                        title={t('common.confirm')}
                        additionalContainerStyles={[acceptButtonStyle()]}
                        testID={CHANGEABLE_TEXT_TEST_IDS.BUTTON_CONFIRM}
                    />
                </View>
            </View>
        </GenericModal>
    );
};

export default ChangeModal;
