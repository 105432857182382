import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { Asset, RECORDING_STATUS } from '@24i/nxg-sdk-photon';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import GenericModal from '../GenericModal';
import getRecordModalStyles from './styles';

type SetModalProps = (arg: { [key: string]: unknown }) => void;

interface Props {
    asset: Asset;
    recordingStatus: RECORDING_STATUS | false;
    onSetSingle: () => void;
    onRemoveSingle: () => void;
    onSetMultiple: () => void;
    onRemoveMultiple: () => void;
    closeModal: () => void;
    setModalProps?: SetModalProps;
    seriesId?: string | null;
}

const RecordModal = ({
    recordingStatus,
    asset,
    onSetSingle,
    onRemoveSingle,
    onSetMultiple,
    onRemoveMultiple,
    closeModal,
    setModalProps,
    seriesId,
}: Props) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getRecordModalStyles(theme);

    const dimensions = useDimensions();

    const isLandscape = useRef(dimensions.isLandscape);
    const areTwoSubactionButtons =
        (asset.series && !recordingStatus) ||
        (!!seriesId && recordingStatus !== RECORDING_STATUS.COMPLETED);

    // update modal width and height if orientation changes
    useEffect(() => {
        if (isLandscape.current !== dimensions.isLandscape) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            setModalProps &&
                setModalProps({
                    style: {
                        width: dimensions.width,
                        height: dimensions.height,
                    },
                });
            isLandscape.current = dimensions.isLandscape;
        }
    }, [dimensions.isLandscape, dimensions.viewportWidth, dimensions.viewportHeight]);

    return useMemo(() => {
        const modalTextElements = (): { title: string; description: string } => {
            switch (recordingStatus) {
                case RECORDING_STATUS.PARTIAL:
                case RECORDING_STATUS.SCHEDULED:
                    return {
                        title: t('recordings.cancel.cancelButton', { count: 1 }),
                        description: t('recordings.series.cancel.description'),
                    };
                case RECORDING_STATUS.COMPLETED:
                    return {
                        title: t('recordings.delete.deleteButton', { count: 1 }),
                        description: t('recordings.delete.description'),
                    };
                default:
                    return {
                        title: `${t('recordings.series.add.title')} "${asset?.title}"`,
                        description: t('recordings.series.add.description'),
                    };
            }
        };

        const customButtonLayout = (): JSX.Element => (
            <>
                <SubactionButton
                    title={t('recordings.series.thisEpisodeButton')}
                    additionalContainerStyles={styles.thisButtonContainer}
                    additionalTextStyles={{ textTransform: 'uppercase' }}
                    onPress={recordingStatus ? onRemoveSingle : onSetSingle}
                />
                <SubactionButton
                    title={t('recordings.series.allEpisodesButton')}
                    additionalContainerStyles={styles.futureButtonContainer}
                    additionalTextStyles={{ textTransform: 'uppercase' }}
                    onPress={recordingStatus ? onRemoveMultiple : onSetMultiple}
                />
            </>
        );

        return (
            <GenericModal
                requiresModalWrapper={false}
                closeModal={closeModal}
                title={{
                    text: modalTextElements().title,
                    numberOfLines: 3,
                    customStyle: styles.title,
                }}
                description={{
                    text: modalTextElements().description,
                    numberOfLines: 2,
                    customStyle: styles.description,
                }}
                primaryButton={{
                    title: modalTextElements().title,
                    additionalContainerStyles: styles.deleteButtonContainer,
                    additionalTextStyles: { textTransform: 'uppercase' },
                    onPress: () => (recordingStatus ? onRemoveSingle() : onSetSingle()),
                }}
                tertiaryButton={{
                    title: t('common.cancel'),
                    additionalContainerStyles: styles.cancelButtonContainer,
                    onPress: closeModal,
                }}
            >
                {areTwoSubactionButtons ? customButtonLayout() : undefined}
            </GenericModal>
        );
    }, [recordingStatus]);
};

export default RecordModal;
