import type { Playlist } from '@24i/nxg-sdk-photon';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { useQuery, UseQueryOptions } from 'react-query';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import { queryKeys } from '../queryKeys';

const useMyPurchasesQuery = (options?: UseQueryOptions<Playlist>) => {
    const { fetchMyPurchases } = usePurchaseData();
    const reactQuery = getRuntimeConfig('reactQuery');

    return useQuery<Playlist>(queryKeys.myPurchases, fetchMyPurchases, {
        staleTime: reactQuery.staleTime.short,
        ...options,
    });
};

export default useMyPurchasesQuery;
