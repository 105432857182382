/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Slider } from '@24i/nxg-sdk-gluons';
import { View, Text, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { ASSET_TYPE, ThemeIconName } from '@24i/nxg-sdk-photon';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import {
    useRemoteMediaClient,
    useMediaStatus,
    useStreamPosition,
    MediaPlayerState,
} from '../../../../../../context/Cast';
import type { CastMediaInfo } from '../../../../../../context/Cast/types';
import AudioSubtitlesModal from '../../AudioSubtitlesModal';
import getPlayerControlsStyles from '../styles';
import { getCurrentTime, getTotalTime } from '../../../../utils';
import { CHROMECAST_PLAYER_CONTROLS_TEST_IDS } from '../types';

const VOLUME_THRESHOLD_VALUE = 35;

const PlayerControls = ({
    styles: getStyles = getPlayerControlsStyles,
    onNextEpisode = () => undefined,
    onPreviousEpisode = () => undefined,
    setAudioSubtitleModalvisible = (value) => value,
    audioSubtitleModalVisible = false,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { color, icons } = theme;
    // const { t } = useTranslation();
    const client = useRemoteMediaClient();
    const status = useMediaStatus();
    const streamPosition = useStreamPosition() || 0;
    const [isSliderVisible, setSliderVisibility] = useState(false);
    const [volumeIcon, setVolumeIcon] = useState<ThemeIconName>('onDark-control_volume_mute');
    const [volume, setVolume] = useState((status?.volume || 0) * 100);
    const [time, setTime] = useState({ duration: 0, progress: 0 });
    const mediaInfo = status?.mediaInfo as CastMediaInfo;
    const type = mediaInfo?.customData?.type;
    const duration = mediaInfo?.streamDuration || 0;
    const isBroadcast = type === ASSET_TYPE.BROADCAST;
    // const isMovie = type === ASSET_TYPE.MOVIE;
    const [isSeeking, setIsSeeking] = useState(false);
    const [seekingSliderValue, setSeekingSliderValue] = useState(0);
    const sliderValue = isSeeking ? seekingSliderValue : streamPosition;
    const seekForward = streamPosition ? streamPosition + 10 : 0;
    const seekBackward = streamPosition ? streamPosition - 10 : 0;
    const disableSlider = mediaInfo?.customData?.disableSeek || false;
    const isPlaying = status?.playerState === MediaPlayerState.PLAYING;
    const isLive = mediaInfo?.streamType === 'live';

    useEffect(() => {
        setTime({ duration, progress: streamPosition });
        if (seekingSliderValue === Math.round(streamPosition)) {
            setIsSeeking(false);
        }
    }, [streamPosition]);

    useEffect(() => {
        if (status) {
            setVolume(status?.volume * 100);
        }
    }, [mediaInfo?.contentId]);

    const getVolumeIcon = () => {
        if (volume > VOLUME_THRESHOLD_VALUE) return 'onDark-control_volume_up';
        if (volume <= 0) {
            return 'onDark-control_volume_mute';
        }
        return 'onDark-control_volume_down';
    };

    useEffect(() => {
        setVolumeIcon(getVolumeIcon());
    }, [volume]);

    const onSeek = (value) => {
        setSeekingSliderValue(value);
        client?.seek({ position: value, relative: false });
        const newTime = { ...time, ...{ progress: value } };
        setTime(newTime);
        setIsSeeking(true);
    };

    const onPlay = () => {
        if (isPlaying) client?.pause();
        else client?.play();
    };

    const getTime = () => {
        if (streamPosition) {
            return {
                currentTime: getCurrentTime({ progress: streamPosition }),
                totalTime: getTotalTime({ duration }),
            };
        }

        return { currentTime: '00:00', totalTime: '00:00' };
    };

    const onVolumeChange = (value) => {
        setVolume(value);
        client?.setStreamVolume(value / 100);
    };

    return (
        <View style={styles.controllerContainer}>
            <View style={styles.pauseContainer}>
                {/* <IconButton
                    color={color.textPrimary}
                    iconSize={32}
                    icon={icons.player.previousEpisode}
                    onPress={onPreviousEpisode}
                    additionalContainerStyles={{ opacity: isBroadcast || isMovie ? 0 : 1 }}
                    testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.PREVIOS_EPISODE}
                /> */}
                {!isLive && (
                    <IconButton
                        color={color.textPrimary}
                        iconSize={32}
                        iconName="onDark-control_replay10"
                        additionalContainerStyles={[
                            styles.seekButton,
                            { opacity: isBroadcast ? 0 : 1 },
                        ]}
                        onPress={() => {
                            onSeek(seekBackward);
                        }}
                        testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.SEEK_BACKWARD}
                    />
                )}
                <IconButton
                    color={color.textPrimary}
                    iconSize={56}
                    iconName={isPlaying ? 'onDark-control_pause' : 'onDark-control_play'}
                    onPress={onPlay}
                    testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.ACTION_BUTTON}
                />
                {!isLive && (
                    <IconButton
                        color={color.textPrimary}
                        iconSize={32}
                        iconName="onDark-control_forward10"
                        additionalContainerStyles={[
                            styles.seekButton,
                            { opacity: isBroadcast ? 0 : 1 },
                        ]}
                        onPress={() => {
                            onSeek(seekForward);
                        }}
                        testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.SEEK_FORWARD}
                    />
                )}
                {/* <IconButton
                    color={color.textPrimary}
                    iconSize={32}
                    icon={icons.player.nextEpisode}
                    onPress={onNextEpisode}
                    additionalContainerStyles={{ opacity: isBroadcast || isMovie ? 0 : 1 }}
                    testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.NEXT_EPISODE}
                /> */}
            </View>
            <View style={[styles.timelineContainer, { opacity: isBroadcast ? 0 : 1 }]}>
                <Text style={styles.timeTextStyles}>{getTime().currentTime}</Text>
                <Slider
                    disabled={disableSlider}
                    style={{ flex: 1 }}
                    minimumValue={0}
                    maximumValue={mediaInfo?.streamDuration}
                    minimumTrackTintColor={color.progressBarAccent}
                    maximumTrackTintColor={color.progressBarBackground}
                    step={1}
                    value={sliderValue}
                    onSlidingComplete={onSeek}
                    testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.PROGRESS_SLIDER}
                />
                <Text style={styles.timeTextStyles}>{getTime().totalTime}</Text>
            </View>
            <View style={styles.secondaryControlsContainer}>
                {isSliderVisible ? (
                    <View style={styles.visibleVolumeContainer}>
                        <ThemeIcon iconName={volumeIcon} size={24} />
                        <Slider
                            style={styles.additionalVisibleVolumeContainerStyles}
                            minimumValue={0}
                            maximumValue={100}
                            minimumTrackTintColor={color.progressBarAccent}
                            maximumTrackTintColor={color.progressBarBackground}
                            step={5}
                            value={volume}
                            onValueChange={onVolumeChange}
                            testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.VOLUME_SLIDER}
                        />
                        <View style={{ opacity: 0.4 }}>
                            <IconButton
                                color={color.textPrimary}
                                iconName="onPrimary-close"
                                iconSize={24}
                                onPress={() => {
                                    setSliderVisibility(false);
                                }}
                                testID={
                                    CHROMECAST_PLAYER_CONTROLS_TEST_IDS.VOLUME_SLIDER_HIDE_BUTTON
                                }
                            />
                        </View>
                    </View>
                ) : (
                    <>
                        <IconButton
                            color={color.textPrimary}
                            iconName={volumeIcon}
                            iconSize={24}
                            additionalContainerStyles={styles.volumeIcon}
                            onPress={() => {
                                setSliderVisibility(true);
                            }}
                            testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.VOLUME_BUTTON}
                        />
                        {/* <View style={styles.relatedContainer}>
                            <IconButton
                                color={color.textPrimary}
                                icon={icons.chromecast.related}
                                iconSize={24}
                            />
                            <Text style={styles.relatedText}>{t('asset.related')}</Text>
                        </View> */}
                        <IconButton
                            color={color.textPrimary}
                            iconName="onDark-subtitles"
                            iconSize={24}
                            additionalContainerStyles={styles.subtitleIcon}
                            onPress={() => {
                                setAudioSubtitleModalvisible(true);
                            }}
                            testID={CHROMECAST_PLAYER_CONTROLS_TEST_IDS.SUBTITLES_BUTTON}
                        />
                    </>
                )}
            </View>
            <AudioSubtitlesModal
                isVisible={audioSubtitleModalVisible}
                closeModal={() => {
                    setAudioSubtitleModalvisible(false);
                }}
            />
        </View>
    );
};

export { getPlayerControlsStyles };
export default overridable(PlayerControls, 'PlayerControls');
