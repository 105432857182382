/*
@PRODUCT_FEATURE: B 1.4
*/
import React, { createContext, useContext } from 'react';
import { EntitlementsClient, getDummyClient } from '@24i/nxg-sdk-photon';

const defaultClient = getDummyClient<EntitlementsClient>();

export const EntitlementsContext = createContext<EntitlementsClient>(defaultClient);

export const EntitlementsProvider = ({ client = defaultClient, children }) => (
    <EntitlementsContext.Provider value={client}>{children}</EntitlementsContext.Provider>
);

export const useEntitlements = () => useContext(EntitlementsContext);
