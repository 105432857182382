export * from '@24i/nxg-sdk-smartott/src/navigation/constants';

/**
 * An enum of all screen types supported by the TVs.
 */
export enum TV_SCREENS {
    GENRE = 'Genre',
    SUB_MENU = 'SubMenu',
    HOME = 'Home',
    DYNAMIC_CONTENT = 'DynamicContent',
    SETTINGS = 'Settings',
    SEARCH = 'Search',
    EXTERNAL = 'External',
    PLAYBACK = 'Playback',
    SIGN_IN = 'SignIn',
    SIGN_IN_ADOBE_PRIMETIME = 'SignInAdobePrimetime',
    SIGN_UP = 'SignUp',
    SIGN_UP_QR = 'QR-SignUp',
    FORGOT_PASS = 'ForgotPassword',
    DETAILS = 'Details',
    MY_PURCHASES = 'MyPurchases',
    MY_LIST = 'MyList',
    MY_SUBSCRIPTIONS = 'MySubscriptions',
    RECENTLY_WATCHED = 'RecentlyWatched',
    LANDING = 'Landing',
    TERMS_AND_CONDITIONS = 'TermsAndConditions',
    TEXT_PAGE = 'TextPage',
    SEE_ALL = 'SeeAll',
    ACCOUNT_DETAILS = 'AccountDetails',
    SIGN_OUT = 'SignOut',
    FALLBACK = 'Fallback',
    PLAYLIST = 'Playlist',
    LANGUAGE = 'Language',
    TECHNICAL_INFO = 'TechnicalInfo',
    THEME = 'Theme',
    SERVICE = 'Service',
    MODAL = 'Modal',
    SIGN_UP_SUCCESS = 'SignUpSuccess',
    PAGE = 'Page',
    SIGN_IN_BACKSTAGE = 'SignInBackstage',
    DELETE_ACCOUNT = 'DeleteAccount',
    DELETE_ACCOUNT_SUCCESS = 'DeleteAccountSuccess',
    ACCOUNT = 'appstage-screen-account',
    MVPD_SIGNIN = 'appstage-screen-mvpd-signin',
    EPG = 'Epg',
}

export enum TV_SLUGS {
    SIGN_IN_ADOBE_PRIMETIME = 'sign-in-adobe',
    SIGN_OUT_ADOBE_PRIMETIME = 'sign-out-adobe',
}
