import { useTriggerOnce } from '../useTriggerOnce';

// storing these IDs until app reset.
const ids = {};

export const useTriggerOncePersistent = <T>(
    getEventId: (event: T) => number | string,
    triggerFunction: (event: T) => void,
    initialIds: Record<number | string, boolean> = {},
    enabled = true
) => {
    // we don't want to break the object reference here.
    Object.keys(initialIds).forEach((id) => {
        ids[id] = initialIds[id];
    });
    return useTriggerOnce<T>(getEventId, triggerFunction, ids, enabled);
};
