import React, { useRef, useEffect, useState } from 'react';
import { Animated, StyleSheet, PanResponder } from 'react-native';
import PropTypes from 'prop-types';
import Detail from './Detail';
import Master from './Master';
import { DETAIL_VIEW_TEST_ID, MASTER_VIEW_TEST_ID, ANIMATION_DURATION } from './constants';

const styles = StyleSheet.create({
    detailWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
});

const PortraitMode = ({
    isLandscape,
    selectedOption,
    width,
    defaultOption,
    setSelectedOption,
    options,
    renderMasterWrapper,
    renderMasterButton,
    renderDetail,
    disableGesture,
    extraDetailParams,
}) => {
    const animatedRightRef = useRef(new Animated.Value(width));

    const [isDetailOpened, setIsDetailOpened] = useState(false);
    const openDetail = () => setIsDetailOpened(true);
    const closeDetail = () => {
        setIsDetailOpened(false);
        setSelectedOption(undefined);
    };

    const openDetailAnimation = () => {
        Animated.timing(animatedRightRef.current, {
            toValue: 0,
            duration: ANIMATION_DURATION,
            useNativeDriver: true,
        }).start();
    };

    const closeDetailAnimation = () => {
        Animated.timing(animatedRightRef.current, {
            toValue: width,
            duration: ANIMATION_DURATION,
            useNativeDriver: true,
        }).start();
    };

    useEffect(() => {
        if (isDetailOpened) {
            openDetailAnimation();
        } else {
            closeDetailAnimation();
        }
    }, [isDetailOpened]);

    const panResponderRef = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: (evt) => {
                const isOnLeftSide = evt.nativeEvent.pageX < Math.floor(width * 0.25);
                return isOnLeftSide;
            },
            onPanResponderRelease: (evt, gestureState) => {
                const { moveX } = gestureState;
                const isBehindHalfOfTheScreen = moveX >= width / 2;
                if (isBehindHalfOfTheScreen) {
                    closeDetail();
                } else {
                    openDetailAnimation();
                }
            },
            onPanResponderMove: (evt, gestureState) => {
                const { moveX } = gestureState;
                animatedRightRef.current.setValue(moveX);
            },
        })
    );

    return (
        <>
            <Master
                isLandscape={isLandscape}
                options={options}
                onSelect={(option) => {
                    openDetail();
                    setSelectedOption(option);
                }}
                selectedOption={defaultOption}
                renderWrapper={renderMasterWrapper}
                renderButton={renderMasterButton}
                renderDetail={renderDetail}
                testID={MASTER_VIEW_TEST_ID}
            />
            <Animated.View
                style={[
                    {
                        transform: [{ translateX: animatedRightRef.current }],
                    },
                    styles.detailWrapper,
                ]}
                {...(!disableGesture ? panResponderRef.current.panHandlers : {})}
            >
                <Detail
                    testID={DETAIL_VIEW_TEST_ID}
                    isLandscape={isLandscape}
                    renderDetail={renderDetail}
                    selectedOption={selectedOption}
                    onBack={closeDetail}
                    isDetailOpened={isDetailOpened}
                    extraDetailParams={extraDetailParams}
                />
            </Animated.View>
        </>
    );
};

PortraitMode.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
        })
    ).isRequired,
    renderMasterWrapper: PropTypes.func.isRequired,
    renderMasterButton: PropTypes.func.isRequired,
    renderDetail: PropTypes.func.isRequired,
    isLandscape: PropTypes.bool.isRequired,
    selectedOption: PropTypes.shape({}).isRequired,
    defaultOption: PropTypes.shape({}).isRequired,
    width: PropTypes.number.isRequired,
    setSelectedOption: PropTypes.func.isRequired,
    disableGesture: PropTypes.bool,
};

PortraitMode.defaultProps = {
    disableGesture: false,
};

export default PortraitMode;
