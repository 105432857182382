import React from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { RatingModalProps } from './types';
import { RATING_MODAL_TEST_IDS } from './test-utils';
import RatingSystemComponent from './components/RateSystem';
import GenericModal from '../GenericModal';
import getRatingModalStyles from './styles';

const RatingModal = ({
    isVisible,
    title,
    description,
    onClose,
    onRate,
    onRemove,
    rating,
}: RatingModalProps) => {
    const { theme } = useTheme();
    const styles = getRatingModalStyles(theme);

    return (
        <GenericModal
            requiresModalWrapper
            isVisible={isVisible}
            closeModal={onClose}
            title={{
                text: title,
                testID: RATING_MODAL_TEST_IDS.TITLE,
                customStyle: styles.title,
            }}
            description={{
                text: description,
                testID: RATING_MODAL_TEST_IDS.DESCRIPTION,
                customStyle: styles.description,
            }}
        >
            <RatingSystemComponent onRate={onRate} onRemove={onRemove} rating={rating} />
        </GenericModal>
    );
};

export default RatingModal;
