import { Profile, ProfileImage } from '@24i/nxg-sdk-photon';
import { Dispatch } from 'react';
import { SOTT_DEFAULT_SCREENS } from '../../navigation/constants';
import { UPSERT_PROFILE_MODES } from './constants';
import { UpsertProfileScreenStyleGetter } from './styles';

export type ProfileAge = Exclude<Profile['age'], undefined>;

export enum UPSERT_PROFILE_TEST_IDS {
    PROFILE_IMAGE = 'profile_image',
    VIEWING_RESTRICTIONS_SWITCH = 'viewing_restrictions_switch',
    PIN_PROTECTION_SWITCH = 'pin_protection_switch',
    NEW_PROFILE_SAVE_BUTTON = 'new_profile_save_button',
    EDIT_PROFILE_SAVE_BUTTON = 'edit_profile_save_button',
    EDIT_PROFILE_DELETE_BUTTON = 'edit_profile_delete_button',
    DELETE_PROFILE_DIALOG_CANCEL_BUTTON = 'delete_profile_dialog_cancel_button',
    DELETE_PROFILE_DIALOG_DELETE_BUTTON = 'delete_profile_dialog_delete_button',
}

export interface CreateProfileScreenProps {
    contentRatings: Array<number>;
    userProfile: Profile | null;
    isLoading: boolean;
    primaryButtonText: string;
    mode: UPSERT_PROFILE_MODES;
    profileImages: Array<ProfileImage>;
    profileImageId: string;
    shouldDisplayDeleteButton?: boolean;
    isButtonDisabled: boolean;
    isOnboarding: boolean;
    screenTitle: string;
    styles?: UpsertProfileScreenStyleGetter;
    onPinProtectedChange: Dispatch<boolean>;
    onProfileNameChange: (name: string) => void;
    onProfileImageChange: (id: string) => void;
    onDeleteProfile: (id: string) => void;
    onCreateProfile: () => void;
    onUpdateProfile: () => void;
    onViewingRestrictionChange: (age: ProfileAge) => void;
    onViewingRestrictionsToggle: () => void;
}

export interface CreateProfileWebScreenProps extends CreateProfileScreenProps {
    CustomHeader?: JSX.Element;
    onBack: () => void;
}

export type Routes = {
    [SOTT_DEFAULT_SCREENS.UPSERT_PROFILE]: {
        mode?: UPSERT_PROFILE_MODES.CREATE | UPSERT_PROFILE_MODES.EDIT;
        profileId?: string;
        onboarding?: boolean;
    };
};

export interface UpsertScreenViewModelProps {
    mode?: UPSERT_PROFILE_MODES;
    contentRatings?: number[];
}
