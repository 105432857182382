import { createDecodedToken, DecodedToken } from '@24i/nxg-sdk-photon';
import { decodeBase64Token } from './utils';

export const decodedToken = (token: string): DecodedToken => {
    const firstDecodedResult = decodeBase64Token(token);
    const secondDecodedResult = firstDecodedResult?.token
        ? decodeBase64Token(firstDecodedResult.token)
        : {};
    return createDecodedToken({ ...firstDecodedResult, ...secondDecodedResult });
};
