import React from 'react';
import { View, Text, ScrollView } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Loader } from '@24i/nxg-sdk-gluons/src';
import { useTranslation } from 'react-i18next';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { isPlatformWeb } from 'renative';
import { ManageDevicesScreenProps } from '@24i/nxg-sdk-smartott/src/screens/ManageDevicesScreen/types';
import DeviceItem from '@24i/nxg-sdk-smartott/src/screens/ManageDevicesScreen/components/DeviceItem';
import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import OnboardingHeader from '@24i/nxg-sdk-smartott/src/navigation/components/OnboardingHeader';
import RemoveModal from '@24i/nxg-sdk-smartott/src/screens/ManageDevicesScreen/components/RemoveModal';
import getCreateManageDevicesScreenStyles from '@24i/nxg-sdk-smartott/src/screens/ManageDevicesScreen/styles';

const ManageDevicesScreen = (props: ManageDevicesScreenProps) => {
    const {
        onboarding,
        devices,
        formatDate,
        deviceToRemove,
        onRemoveDevicePress,
        onModalRemoveDevicePress,
        deviceLimit,
        isLoading,
        isModalVisible,
        onModalClose,
        currentDevice,
        navigation,
        webHeaderTitle,
    } = props;
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const styles = getCreateManageDevicesScreenStyles(theme, dimensions);
    const { t } = useTranslation(['sott']);

    if (isLoading) {
        return <Loader additionalStyles={styles.indicator} color={theme.color.textPrimary} />;
    }

    return (
        <View style={{ flex: 1 }}>
            {onboarding && (
                <OnboardingHeader
                    title={t('error.E01.title')}
                    wrapperStyle={styles.onboardingWrapper}
                />
            )}
            {!isPlatformWeb && !onboarding && (
                <TopBarPrimary navigation={navigation} title={webHeaderTitle || ''} previous />
            )}
            <View style={styles.screenContainer}>
                <ScrollView>
                    <Text style={styles.description} testID="manageDevicesDescription">
                        {onboarding && deviceLimit && deviceLimit <= devices.length
                            ? t('error.E01.body', { count: deviceLimit })
                            : t('manageDevices.delete.description', { count: deviceLimit })}
                    </Text>
                    {devices?.map((device) => {
                        return (
                            <DeviceItem
                                key={`${device.name}${device.registrationDate}`}
                                device={device}
                                formatDate={formatDate}
                                onRemoveDevicePress={onRemoveDevicePress}
                                isCurrent={device.deviceId === currentDevice?.deviceId}
                            />
                        );
                    })}
                </ScrollView>
                <RemoveModal
                    deviceToRemove={deviceToRemove}
                    formatDate={formatDate}
                    isVisible={isModalVisible}
                    onModalClose={() => onModalClose()}
                    onModalRemoveDevicePress={onModalRemoveDevicePress}
                />
            </View>
        </View>
    );
};

export default withWebHeader(ManageDevicesScreen);
