/*
@PRODUCT_FEATURE: G 1.5
*/
import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Animated } from 'react-native';
import { Platform, Dimensions } from '@24i/nxg-sdk-quantum';
import { ScrollView, View } from '@24i/nxg-sdk-quarks';

const isAndroidTV = Platform.isTV && Platform.OS === 'android';
const relativeSize = (size) => (isAndroidTV ? Math.floor(size) / 2 : size);
const bottomMenuStyle = {
    width: Dimensions.get('screen').width,
    flexDirection: 'row',
};

const ANIMATION_DURATION = 250;

const BottomMenu = ({
    renderScreen,
    renderMenuItem,
    menuItems,
    style,
    overflowIcon,
    overflowStyle,
    visibleItemsCount,
}) => {
    const { height: screenHeight } = Dimensions.get('window');
    const animatedBottomRef = useRef(new Animated.Value(-screenHeight));
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const [height, setHeight] = useState(0);

    const openOverflowMenuAnimation = () => {
        Animated.timing(animatedBottomRef.current, {
            toValue: 0,
            duration: ANIMATION_DURATION,
            useNativeDriver: true,
        }).start();
    };

    const closeOverflowMenuAnimation = (onAnimationEnd) => {
        Animated.timing(animatedBottomRef.current, {
            toValue: -height,
            duration: ANIMATION_DURATION,
            useNativeDriver: true,
        }).start(onAnimationEnd);
    };

    const openOverflow = () => {
        setIsMenuVisible(true);
        openOverflowMenuAnimation();
    };

    const closeOverflow = () => {
        closeOverflowMenuAnimation(() => setIsMenuVisible(false));
    };

    const onLayout = ({ nativeEvent: { layout } }) => {
        setHeight(layout.height);
        animatedBottomRef.current.setValue(-layout.height);
    };

    const renderMenu = () => {
        if (menuItems.length < visibleItemsCount) {
            return (
                <View style={[style, bottomMenuStyle]}>
                    <View style={{ flexDirection: 'row' }}>{menuItems.map(renderMenuItem)}</View>
                </View>
            );
        }
        const visibleMenuItems = menuItems.slice(0, visibleItemsCount - 1);
        const overflowMenuItems = menuItems.slice(visibleItemsCount - 1, menuItems.length);
        const overflowMenuAction = { action: isMenuVisible ? closeOverflow : openOverflow };

        visibleMenuItems.push({ ...overflowIcon, ...overflowMenuAction });
        return (
            <View>
                <Animated.View
                    onLayout={onLayout}
                    style={[
                        overflowStyle,
                        {
                            position: 'absolute',
                            transform: [{ translateY: animatedBottomRef.current }],
                            width: '100%',
                            top: 0,
                            left: 0,
                        },
                    ]}
                >
                    {isMenuVisible && (
                        <View style={overflowStyle}>
                            {overflowMenuItems.map(({ action, ...props }) => {
                                const extendedAction = () => {
                                    action();
                                    closeOverflow();
                                };
                                return renderMenuItem({ ...props, action: extendedAction });
                            })}
                        </View>
                    )}
                </Animated.View>
                <View style={[style, bottomMenuStyle]}>
                    <View style={{ flexDirection: 'row' }}>
                        {visibleMenuItems.map(renderMenuItem)}
                    </View>
                </View>
            </View>
        );
    };

    return (
        <>
            <ScrollView>{renderScreen()}</ScrollView>
            {renderMenu()}
        </>
    );
};

BottomMenu.propTypes = {
    renderMenuItem: PropTypes.func,
    renderScreen: PropTypes.func,
    visibleItemsCount: PropTypes.number,
    menuItems: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]),
    overflowIcon: PropTypes.shape({}),
    overflowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]),
};

BottomMenu.defaultProps = {
    renderMenuItem: Function.prototype,
    renderScreen: Function.prototype,
    visibleItemsCount: 5,
    menuItems: [],
    style: {
        backgroundColor: '#F5FCFF',
        justifyContent: 'space-between',
        paddingHorizontal: relativeSize(50),
    },
    overflowIcon: {
        icon: 'dots-vertical',
        title: '',
        subtitle: '',
    },
    overflowStyle: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
};

export default BottomMenu;
