import { getFont } from '@24i/nxg-sdk-higgs/src';
import { Theme } from '@24i/nxg-sdk-photon/src';
import getDefaultTertiaryButtonStyles from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton/styles';
import getDefaultActionButtonStyles from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton/styles';
import { SecondScreenActivationStyles } from '../types';

export const getSecondScreenActivationStyles = (theme: Theme): SecondScreenActivationStyles => {
    const allProvidersButton = getDefaultTertiaryButtonStyles(theme);
    const defaultButtonStyles = getDefaultActionButtonStyles(theme);

    return {
        cornerLogo: {
            position: 'absolute',
            left: 48,
            top: 14,
            zIndex: 1000,
        },
        outerContainer: {
            height: '100%',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 999,
            width: 576,
            height: '100%',
            paddingTop: 150,
            paddingBottom: 150,
        },
        headerSection: {
            flexDirection: 'row',
            marginTop: 24,
            marginBottom: 24,
        },
        headerTitle: {
            color: theme.color.textPrimary,
            fontWeight: 'bold',
            textAlign: 'center',
            flexGrow: 1,
            ...getFont(theme, 'h2'),
        },
        blockSection: {
            alignItems: 'flex-start',
            flexDirection: 'column',
            marginTop: 24,
            marginBottom: 24,
            width: '100%',
        },
        blockTitle: {
            color: theme.color.textPrimary,
            fontWeight: 'bold',
            textAlign: 'left',
            flexGrow: 1,
            ...getFont(theme, 'h4'),
        },
        blockDescriptionText: {
            ...getFont(theme, 'body2'),
            color: theme.color.contrast3,
            textAlign: 'left',
        },
        pageDescriptionText: {
            ...getFont(theme, 'body2'),
            color: theme.color.contrast3,
            textAlign: 'center',
            padding: 4,
        },
        pageDescriptionContainer: {
            minHeight: 50,
            width: '100%',
            marginTop: 16,
        },
        allProvidersButtonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 50,
            width: '100%',
            marginBottom: 24,
            marginTop: 16,
        },
        buttonSection: {
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
        signInButton: {
            ...defaultButtonStyles,
            holder: {
                ...defaultButtonStyles.holder,
                marginRight: 0,
                width: 335,
            },
        },
        allProvidersButton: {
            ...allProvidersButton,
            holder: {
                ...allProvidersButton.holder,
                backgroundColor: 'transparent',
                width: 160,
                height: 42,
                alignSelf: 'center',
            },
        },
        textInputContainer: {
            width: '100%',
            height: 50,
            borderRadius: 5,
            marginTop: 8,
            marginBottom: 15,
            ...getFont(theme, 'h4'),
            paddingLeft: 20,
            backgroundColor: theme.color.lighter3,
            color: theme.color.textPrimary,
        },
    };
};
