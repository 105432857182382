import React, { useEffect, useState } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { WEB_SCREEN_SIZES, WEB_SCREEN_MAX_WIDTH } from '@24i/nxg-sdk-gluons/src/utils/constants';
import { StylesGetter } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import { Theme } from '@24i/nxg-sdk-photon';
import { isPlatformWeb } from 'renative';
import { NavConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { TopBarMenu } from '../../TopBarMenu/View/index.web';
import BottomTabBar from '../../BottomTabBar/index.web';
import { getNavBarMenuStyles } from '../styles';
import { useNavigationConfigQuery } from '../../../../hooks/query/useNavigationConfigQuery';

const { XS, S, M, L, XL } = WEB_SCREEN_SIZES;

interface NavBarMenuProps {
    styles?: StylesGetter<Theme, ReturnType<typeof getNavBarMenuStyles>>;
}

const NavBarMenu = ({ styles: getStyles = getNavBarMenuStyles }: NavBarMenuProps) => {
    const { navigationConfig } = useNavigationConfigQuery();
    const { theme } = useTheme();
    const { isNavBarTransparent } = useStore();
    const [isMenuScrolled, setIsMenuScrolled] = useState(false);
    const { width } = useDimensions();

    const styles = getStyles(theme);

    const navBarVisibility = () => {
        const scrollOffsetY = window.pageYOffset;
        setIsMenuScrolled(scrollOffsetY > 0);
    };
    useEffect(() => {
        window.addEventListener('scroll', navBarVisibility);

        return () => {
            window.removeEventListener('scroll', navBarVisibility);
        };
    });

    // TODO: Loader?
    if (!navigationConfig) return null;

    if (!isPlatformWeb) {
        throw new Error(`The nav bar menu currently supports only web platforms!`);
    }

    // The cast is OK because of the condition above
    const webNavConfig = navigationConfig as NavConfigWeb;

    const renderTopBarMenu = (metric: number) => {
        let size = XL;

        if (metric < WEB_SCREEN_MAX_WIDTH.S) {
            size = S;
        } else if (metric < WEB_SCREEN_MAX_WIDTH.M) {
            size = M;
        } else if (metric < WEB_SCREEN_MAX_WIDTH.L) {
            size = L;
        }

        return (
            <TopBarMenu
                menuLeft={webNavConfig[size].topMenu.left}
                menuRight={webNavConfig[size].topMenu.right}
                nativeID={`topnavbar-${size}`}
            />
        );
    };

    const navBarMenuStyles = () => {
        if (isMenuScrolled) {
            return [styles.container, styles.scrolledMenuContainer];
        }
        return [
            styles.container,
            isNavBarTransparent
                ? styles.containerWithTransparency
                : styles.containerWithoutTransparency,
        ];
    };
    return (
        <View style={navBarMenuStyles()}>
            {width > WEB_SCREEN_MAX_WIDTH.XS ? (
                renderTopBarMenu(width)
            ) : (
                <BottomTabBar menuItems={webNavConfig[XS].bottomMenu} />
            )}
        </View>
    );
};

export default NavBarMenu;
