import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle, ViewStyle } from 'react-native';
import { getFont } from '@24i/nxg-sdk-higgs';
import { breakpoints } from '../../../../constants';
import { ViewStyleWithExtraProps } from '../../../../types';

type EpisodeDetailsStyles = {
    container: ViewStyleWithExtraProps;
    imageHolder: ViewStyle;
    image: ViewStyle;
    content: ViewStyle;
    date: TextStyle;
    title: TextStyle;
    podcastName: TextStyle;
};

export const getStyles = (theme: Theme): EpisodeDetailsStyles => {
    const borderRadiusFromTheme = theme.elements['generalTile-cornerRadius']?.value;
    const borderRadius = borderRadiusFromTheme ? +borderRadiusFromTheme : 8;
    const { textPrimary } = theme.color;

    return {
        container: {
            flexDirection: 'row',
            columnGap: 16,
            alignItems: 'center',
            maxWidth: '100%',
        },

        imageHolder: {
            width: 89,
            height: 50,
            backgroundColor: theme.color.darker1,
            borderRadius,
            shadowColor: '#0d0d10',
            shadowRadius: 6,
            shadowOffset: {
                width: 0,
                height: 4,
            },
            [`@media (max-width: ${breakpoints.md})`]: {
                display: 'none',
            },
        },

        image: {
            width: '100%',
            height: '100%',
            borderRadius,
        },

        content: {
            flexShrink: 1,
        },

        date: {
            color: textPrimary,
            ...getFont(theme, 'h5'),
            letterSpacing: -0.16,
            opacity: 0.6,
            marginBottom: 1,
        },

        title: {
            ...getFont(theme, 'h4'),
            color: textPrimary,
            letterSpacing: -0.19,
            marginBottom: 2,
        },
        podcastName: {
            color: 'inherit',
            ...getFont(theme, 'h5'),
            letterSpacing: -0.16,
            opacity: 0.8,
        },
    };
};
