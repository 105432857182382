import { useEffect } from 'react';
import { WebRouter, useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useTranslation } from 'react-i18next';
import { useRefreshOnFocus } from '@24i/nxg-sdk-smartott-shared/src/hooks/useRefreshOnFocus';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import useAvailableSubscriptionsQuery from '../../../hooks/query/subscriptions/useAvailableSubscriptions';
import { SubscriptionsListScreenProps, WithSubscriptionsListScreenQuery } from '../types';
import { goToAssetDetailsPage, goToMySubscriptionsPage } from '../../../utils/navigation';
import { useAppNavigation } from '../../../context/AppNavigation';

export const useViewModel = (
    props: WithSubscriptionsListScreenQuery
): SubscriptionsListScreenProps => {
    const router = useRouter();
    const { t } = useTranslation();
    const { data, isLoading, refetch } = useAvailableSubscriptionsQuery();
    const { previousRoute } = useAppNavigation();
    useRefreshOnFocus(refetch);

    useEffect(() => {
        if (data) {
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, data);
        }
    }, [data]);

    const navigateBack = (navigation: WebRouter, previousURL?: string | null) => {
        if (previousURL) {
            navigation.push(previousURL);
        } else {
            goToMySubscriptionsPage(navigation, 'back');
        }
    };

    const onBackClick = () => {
        if (props.query?.referralAssetId && props.query.referralAssetType) {
            goToAssetDetailsPage({
                router: router as WebRouter,
                referralAssetType: props.query.referralAssetType,
                referralAssetId: props.query.referralAssetId,
            });
        } else {
            navigateBack(router as WebRouter, previousRoute);
        }
    };

    return {
        webHeaderTitle: t('svod.add.title'),
        backButtonTitle: t('common.backGo'),
        onBackPressOverride: onBackClick,
        forceShowHeader: true,
        query: props.query,
        isLoading,
        availableSubscriptions: data?.subscriptions,
    };
};
