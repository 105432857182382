import React, { ReactElement } from 'react';
import { MvpdSignIn } from './View';
import { useViewModel } from './viewModel';

export default {
    Main: (): ReactElement => {
        const viewModelProps = useViewModel();
        return <MvpdSignIn {...viewModelProps} />;
    },
    View: MvpdSignIn,
    useViewModel,
};
