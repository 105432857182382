/* eslint-disable global-require */
import React from 'react';
import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import { func, shape, string, number, any } from 'prop-types';

const styles = StyleSheet.create({
    container: {
        width: 20 * 2,
        height: 20 * 2,
    },
    containerIn: {
        width: '100%',
        height: '100%',
    },
});

/**
 * @typedef {{ top: number, bottom: number, left: number, right: number }} HitSlop
 * @typedef {(e: import('react-native').GestureResponderEvent) => void} PressEvent
 * @typedef {{ name: keyof typeof iconMap; onPress: PressEvent; style: Object, tintColor: string, hitSlop: HitSlop }} Props
 * @param {Props} props
 */
const Icon = ({ onPress, style, tintColor, name, hitSlop }) => {
    if (onPress) {
        return (
            <TouchableOpacity
                style={[styles.container, style]}
                onPress={(v) => onPress(v)}
                hitSlop={hitSlop}
            >
                <Image
                    style={[styles.containerIn, tintColor ? { tintColor } : {}]}
                    source={name}
                    resizeMode="stretch"
                />
            </TouchableOpacity>
        );
    }
    return <Image style={[styles.container, style]} source={name} resizeMode="stretch" />;
};

Icon.propTypes = {
    onPress: func,
    style: any, // eslint-disable-line
    tintColor: string,
    name: string.isRequired,
    hitSlop: shape({ top: number, bottom: number, left: number, rigght: number }),
};

Icon.defaultProps = {
    onPress: null,
    style: {},
    tintColor: undefined,
    hitSlop: { top: 10, bottom: 10, left: 10, right: 10 },
};

export default Icon;
