import React, { ReactElement } from 'react';
import View from './View/index.web';
import { useViewModel } from './viewModel';
import { SingleRowWebProps } from './View/types';

const Main = (props: SingleRowWebProps): ReactElement => {
    const viewModelProps = useViewModel<SingleRowWebProps>(props);
    return <View {...viewModelProps} />;
};

export default Main;
