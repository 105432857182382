import useInterval from '@24i/nxg-core-utils/src/hooks/useInterval';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { getDummyClient, Reminder, RemindersClient } from '@24i/nxg-sdk-photon';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import {
    brodacastHasEnded,
    brodacastStartsInFiveMinsOrLess,
    webPoleRemindersFrequency,
} from '../../utils/reminderUtils';

interface RemindersProviderContext {
    remindersDataClient: RemindersClient;
    activeReminder: Reminder | undefined;
    setActiveReminder: React.Dispatch<Reminder>;
}

const dummyClient = getDummyClient<RemindersClient>();

export const RemindersContext = createContext<RemindersProviderContext>({
    remindersDataClient: dummyClient,
    activeReminder: undefined,
    setActiveReminder: () => undefined,
});

export const useRemindersDataClient = () => useContext(RemindersContext);

const WithRemindersEnabled = ({ children }) => {
    const { selectedProfile } = useStore();
    const { t } = useTranslation();
    const { remindersDataClient, setActiveReminder } = useRemindersDataClient();

    const deleteStaleReminders = async (reminders: Reminder[]): Promise<void> => {
        const toDelete = reminders.filter((reminder) => {
            return brodacastHasEnded(reminder.end);
        });

        const deletePromises = (toDelete || []).map((reminder) => {
            return remindersDataClient.deleteReminderForBroadcast(
                reminder.assetId,
                reminder.profileId
            );
        });

        await Promise.all(deletePromises);
    };

    const selectActiveReminder = (reminders: Reminder[], profileId: string): void => {
        const toRemind = reminders.find((reminder) => {
            return (
                reminder.profileId === profileId &&
                !brodacastHasEnded(reminder.end) &&
                brodacastStartsInFiveMinsOrLess(reminder.start)
            );
        });

        if (toRemind) {
            setActiveReminder(toRemind);
        }
    };

    const pollReminders = async (profileId: string | undefined): Promise<void> => {
        if (!profileId) {
            return;
        }

        try {
            const reminders = await remindersDataClient.getReminders();

            if (!reminders) {
                return;
            }

            await deleteStaleReminders(reminders);

            selectActiveReminder(reminders, profileId);
        } catch (error) {
            showToast(t('error.B00.title'));
        }
    };

    useInterval(() => pollReminders(selectedProfile?.id), webPoleRemindersFrequency);

    return <>{children}</>;
};

export const RemindersProvider = ({ client: remindersDataClient = dummyClient, children }) => {
    const remindersFeature = useFeature('reminders');
    const { selectedProfile } = useStore();
    const [activeReminder, setActiveReminder] = useState<Reminder | undefined>(undefined);

    // NOTE: This solution has side effect that it can mount/unmount the whole children tree (at least) two times
    // depending on the remindersFeature?.enabled and selectedProfile.
    // When the componenet is mounted again all useEffects even with dep. array = [] are called again.
    const renderContent = () => {
        if (!remindersFeature?.enabled || !selectedProfile) {
            return children;
        }

        return <WithRemindersEnabled>{children}</WithRemindersEnabled>;
    };

    return (
        <RemindersContext.Provider
            value={{ remindersDataClient, activeReminder, setActiveReminder }}
        >
            {renderContent()}
        </RemindersContext.Provider>
    );
};
