import { SottScreenConfigBuilders } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/types';
import { ImageServiceClient } from '../ImageServiceClient';
import { createSottNavigationClient } from '../AppNavigationClient';
import { LayoutClient } from '../LayoutClient';
import { createSentryMonitoringDataClient } from '../SentryMonitoringDataClient';
// import { createDataPlatformClient } from '../DataPlatformClient';
import { createGoogleAnalyticsClient } from '../GoogleAnalyticsClient';
import { createTwentyFourIQClient } from '../TwentyFourIQClient';
import { createTealiumClient } from '../TealiumClient';
import { DefaultSDKClients } from './types';

export const getDefaultSDKClients = (
    CUSTOM_SCREEN_BUILDERS: SottScreenConfigBuilders,
    runtimeConfig
): DefaultSDKClients => {
    if (!runtimeConfig)
        throw new Error(
            `Not possible to create default SDK clients. Please specify a valid runtime config.`
        );
    const appNavigationClient = createSottNavigationClient(CUSTOM_SCREEN_BUILDERS);
    const imageServiceClient = new ImageServiceClient();
    const layoutClient = new LayoutClient();
    const sentryMonitoringDataClient = createSentryMonitoringDataClient(runtimeConfig);
    // Disable for now
    // const dataPlatformClient = createDataPlatformClient(runtimeConfig);
    const googleAnalyticsClient = createGoogleAnalyticsClient();
    const twentyFourIQClient = createTwentyFourIQClient();
    const tealiumClient = createTealiumClient();

    return {
        appNavigationClient,
        imageServiceClient,
        layoutClient,
        sentryMonitoringDataClient,
        // dataPlatformClient,
        googleAnalyticsClient,
        twentyFourIQClient,
        tealiumClient,
    };
};
