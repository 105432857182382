/* eslint-disable @typescript-eslint/naming-convention */
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { isPlatformTvos } from 'renative';
import { Payload, VideoStartResult } from '../types';
import { EVENTS } from '../constants';
import { mapTagsParams } from './mapTagsParams';

export const mapVideoStart = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): VideoStartResult => {
    let video_percent = 0;
    if (payload.payload.asset?.continueWatchingOffset && payload.payload.asset?.duration) {
        video_percent = Math.round(
            (payload.payload.asset?.continueWatchingOffset / payload.payload.asset?.duration) * 100
        );
    }
    const video_episode_number = payload.payload.asset?.episodeNumber?.toString();
    const video_season_number = payload.payload.asset?.seasonNumber?.toString();
    const video_series_title =
        payload.payload.asset?.seriesTitle || payload.payload.seriesData?.series?.title;
    const channel_number = payload.payload.asset?.channel?.number?.toString();
    const channel_title = payload.payload.asset?.channel?.label;
    return {
        ...(isPlatformTvos ? { ...mapTagsParams(payload) } : {}),
        language: payload.language,
        session_id: payload.payload.sessionId,
        is_authenticated: payload.userId ? 1 : 0,
        event_trigger: triggerName,
        event: EVENTS.VIDEO_START,
        video_current_time: payload.payload.asset?.continueWatchingOffset || 0,
        video_duration: payload.payload.duration / 1000 || payload.payload.asset?.duration || 0,
        video_percent,
        video_title: payload.payload.asset?.title as string,
        video_url: payload.payload.source?.url as string,
        video_asset_id: payload.payload.asset?.id,
        video_external_id: payload.payload.asset?.externalId as string,
        content_type: payload.payload.asset?.type,
        ...(video_episode_number ? { video_episode_number } : {}),
        ...(video_season_number ? { video_season_number } : {}),
        ...(video_series_title ? { video_series_title } : {}),
        ...(channel_number ? { channel_number } : {}),
        ...(channel_title ? { channel_title } : {}),
    };
};
