import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text, Interactable } from '@24i/nxg-sdk-quarks';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import useEntitledSubscriptionsQuery from '@24i/nxg-sdk-smartott-tv/src/hooks/query/subscriptions/useEntitledSubscriptionsQuery';
import { DeleteAccountViewProps, DELETE_ACCOUNT_TEST_ID } from '../types';
import defaultStyles from '../styles';
import DeleteAccountModal from '../components/DeleteAccountModal';
import withErrorModal, {
    WithErrorModalProps,
} from '../../../components/GenericModal/hoc/ErrorModal';

const DeleteAccountScreen = ({
    styles: getStyles = defaultStyles,
    isModalVisible,
    closeModal,
    onPressDeleteAccount,
    openErrorModal,
}: DeleteAccountViewProps & WithErrorModalProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation();
    const { data: currentSubscriptions } = useEntitledSubscriptionsQuery();

    const descriptionLabels = [
        t('account.delete.description1'),
        t('account.delete.description2'),
        t('account.delete.description3'),
    ];

    const unableStrings = {
        title: t('account.delete.unable.title'),
        description: `${t('account.delete.unable.description1')}\n\n${t(
            'account.delete.unable.description2'
        )}`,
    };

    const handleOnPressDeleteAccount = useCallback(() => {
        const hasSubscriptions =
            currentSubscriptions?.subscriptions && currentSubscriptions.subscriptions.length > 0;
        if (hasSubscriptions) {
            openErrorModal(
                unableStrings.title,
                unableStrings.description,
                false,
                t('common.close') as string,
                theme.color.textPrimary
            );

            return;
        }

        onPressDeleteAccount();
    }, [currentSubscriptions]);

    return (
        <View style={styles.mainContainer}>
            {descriptionLabels.map(
                (description) =>
                    !!description && <Text style={styles.descriptionText}>{description}</Text>
            )}
            <Interactable
                testID={DELETE_ACCOUNT_TEST_ID.DELETE_BUTTON}
                onPress={handleOnPressDeleteAccount}
                style={styles.deleteButton}
            >
                <Text style={styles.buttonText} numberOfLines={1}>
                    {t('account.delete.deleteButton')}
                </Text>
            </Interactable>
            <DeleteAccountModal.Main
                isVisible={isModalVisible}
                onCloseModal={closeModal}
                openErrorModal={openErrorModal}
            />
        </View>
    );
};

export default withErrorModal(withWebHeader(DeleteAccountScreen));
