import React from 'react';
import { View, Text, Image } from '@24i/nxg-sdk-quarks';
import { Profile } from '@24i/nxg-sdk-photon/src';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import getStyles from './styles';

type ProfileItemProps = {
    profile: Profile;
};

const ProfileItem = ({ profile }: ProfileItemProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.container}>
            <View style={styles.imageContainer}>
                <Image
                    style={styles.profileImage}
                    source={{
                        uri: profile?.image,
                    }}
                />
            </View>
            <Text style={styles.profileText}>{profile?.name}</Text>
        </View>
    );
};

export default ProfileItem;
