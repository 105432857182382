import { PodcastEpisodePlaybackStatus } from '@24i/nxg-sdk-photon';

export const getPodcastEpisodeActionIcon = ({
    isEpisodeInPlayer,
    playbackStatus,
}: {
    isEpisodeInPlayer: boolean;
    playbackStatus: PodcastEpisodePlaybackStatus;
}): 'play' | 'pause' => {
    if (isEpisodeInPlayer && playbackStatus === 'playing') return 'pause';

    return 'play';
};
