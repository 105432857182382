import {
    ScreenConfigBuilderDisableResult,
    ScreenConfigBuilderResult,
    SottNavScreenConfsExtended,
} from './types';

export const DISABLE_SCREEN_RESULT: ScreenConfigBuilderDisableResult = {
    disable: true,
} as const;

export const isDisableResult = (
    result: ScreenConfigBuilderResult
): result is ScreenConfigBuilderDisableResult => {
    return Boolean((result as ScreenConfigBuilderDisableResult).disable);
};

export const isNondisabledResult = (
    result: ScreenConfigBuilderResult
): result is SottNavScreenConfsExtended => {
    return Boolean(!(result as ScreenConfigBuilderDisableResult).disable);
};
