import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from '@24i/nxg-sdk-quarks';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { useTheme } from '@24i/nxg-sdk-higgs';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { getStyles } from './styles';

interface Props {
    onCancel: () => void;
}

const SubscriptionUnableChange = ({ onCancel }: Props) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <Modal visible onRequestClose={onCancel}>
            <View style={styles.modalContentContainer}>
                <View style={styles.modalContent}>
                    <View style={styles.wrapper}>
                        <View style={styles.titleWrapper}>
                            <Text style={styles.title}>{t('error.H04.title')}</Text>
                        </View>
                        <View style={styles.contentWrapper}>
                            <Text style={styles.content}>{t('error.H04.body')}</Text>
                        </View>
                        <View style={styles.buttonsWrapper}>
                            <ActionButton
                                title={t('common.close')}
                                additionalTextStyles={styles.actionButtonText}
                                additionalContainerStyles={styles.actionButtonContainer}
                                onPress={onCancel}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

export default SubscriptionUnableChange;
