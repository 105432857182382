import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const PurchaseGuard = createGuard(
    'Purchase',
    {
        active: t.boolean,
        type: t.literal('purchase'),
        id: t.string,
        name: t.string,
        price: t.number,
        currency: t.string,
        description: nullable(t.string),
        externalAccessValue: t.string,
        options: nullable(t.unknown),
        externalAccessType: t.string,
    },
    {
        createdAt: t.string,
        updatedAt: t.string,
        expiresAt: t.number,
        applicableTaxRate: t.number,
        tags: t.array(t.string),
    }
);

export type Purchase = t.TypeOf<typeof PurchaseGuard>;

export const isPurchase = (data: unknown): data is Purchase => {
    try {
        return !!decodeModelWith(PurchaseGuard, data, 'Purchase', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createPurchase = (data: unknown): Purchase | never => {
    return decodeModelWith(PurchaseGuard, data, 'Purchase');
};
