import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { EpisodeInfoStyles } from './types';

export const getEpisodeInfoStyles = (theme: Theme): EpisodeInfoStyles => ({
    container: {
        marginTop: 16,
    },
    text: {
        color: `${theme.color.textPrimary}99`,
        fontWeight: 'bold',
        ...getFont(theme, 'h4'),
    },
});

export default getEpisodeInfoStyles;
