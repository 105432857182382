import { useCallback } from 'react';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { extractCleengDataFromBackstageToken } from '@24i/nxg-core-utils';
import { loadCleengTokenAndUserEmail, checkCleengTokenTimeValidity } from '../../utils/cleeng';

export const useCleengTokenHandling = () => {
    const { refetchBackstageToken, getDecodedCleengToken } = useUserData();

    const refreshToken = useCallback(async (): Promise<string | null> => {
        try {
            const refreshBackstageResponse = await refetchBackstageToken();
            const newBackstageToken = refreshBackstageResponse?.token;

            if (newBackstageToken) {
                const tokenData = extractCleengDataFromBackstageToken(newBackstageToken);
                if (tokenData.cleengToken) {
                    loadCleengTokenAndUserEmail(tokenData.cleengToken, tokenData.email);
                    return tokenData.cleengToken;
                }
            }

            return null;
        } catch (_err) {
            return null;
        }
    }, [refetchBackstageToken, extractCleengDataFromBackstageToken, loadCleengTokenAndUserEmail]);

    type HandleCleengComponentTokenParams = { purpose: 'initialisation' | 'periodic-check' };
    const handleCleengComponentToken = useCallback(
        async ({ purpose }: HandleCleengComponentTokenParams): Promise<string | null> => {
            try {
                const currentCleengTokenData = await getDecodedCleengToken?.();

                if (!currentCleengTokenData) {
                    return null;
                }

                const thereIsEnoughTime = checkCleengTokenTimeValidity(
                    currentCleengTokenData.expirationTimestamp
                );

                if (!thereIsEnoughTime) {
                    const refreshedToken = await refreshToken();
                    return refreshedToken;
                }

                if (purpose === 'initialisation') {
                    loadCleengTokenAndUserEmail(
                        currentCleengTokenData.cleengToken,
                        currentCleengTokenData.email
                    );
                }

                return currentCleengTokenData.cleengToken;
            } catch (_err) {
                return null;
            }
        },
        [
            getDecodedCleengToken,
            checkCleengTokenTimeValidity,
            refreshToken,
            loadCleengTokenAndUserEmail,
        ]
    );

    return { handleCleengComponentToken, refreshToken };
};
