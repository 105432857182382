import React, { useMemo } from 'react';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text } from '@24i/nxg-sdk-quarks';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButtonWithoutBg';
import { RECORDING_STATUS } from '@24i/nxg-sdk-photon';
import getRecordingModalStyles from '../styles';

import { RecordingsData } from '../../../types';

interface Props {
    recording: RecordingsData;
    recordingStatus: RECORDING_STATUS | false;
    onDelete: () => void;
    onClose: () => void;
}

const RecordingModal = ({ recordingStatus, recording, onDelete, onClose }: Props) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getRecordingModalStyles(theme);

    return useMemo(() => {
        const modalTextElements = (): {
            title: string;
            description: string;
            button: string;
            cancelButton: string;
        } => {
            const assetsLength = recording?.folderAssets?.length || 1;
            switch (recordingStatus) {
                case RECORDING_STATUS.PARTIAL:
                case RECORDING_STATUS.SCHEDULED:
                    return {
                        title: t('recordings.series.cancelConfirm.title'),
                        description: t('recordings.series.cancelConfirm.description', {
                            assetTitle: recording.title,
                        }),
                        button: t('recordings.cancel.cancelButton', {
                            count: assetsLength,
                        }),
                        cancelButton: t('common.backGo'),
                    };
                default:
                    return {
                        title: t('recordings.delete.deleteConfirm.title', {
                            count: assetsLength,
                        }),
                        description: t('recordings.delete.deleteConfirm.description', {
                            assetTitle: recording.title,
                        }),
                        button: t('recordings.delete.deleteButton', {
                            count: assetsLength,
                        }),
                        cancelButton: isPlatformWeb ? t('common.close') : t('common.cancel'),
                    };
            }
        };

        return isPlatformWeb ? (
            <View style={styles.container}>
                <View style={styles.childContainer}>
                    <Text numberOfLines={3} style={styles.title}>
                        {modalTextElements().title}
                    </Text>
                    <Text numberOfLines={2} style={styles.description}>
                        {modalTextElements().description}
                    </Text>
                    <View style={styles.buttonsContainer}>
                        <ActionButton
                            title={modalTextElements().cancelButton}
                            additionalContainerStyles={styles.cancelButtonContainer}
                            additionalTextStyles={styles.cancelButtonText}
                            onPress={onClose}
                        />
                        <ActionButton
                            title={modalTextElements().button}
                            additionalContainerStyles={styles.deleteButtonContainer}
                            additionalTextStyles={{ textTransform: 'uppercase' }}
                            onPress={onDelete}
                        />
                    </View>
                </View>
            </View>
        ) : (
            <View style={styles.container}>
                <Text numberOfLines={3} style={styles.title}>
                    {modalTextElements().title}
                </Text>
                <Text numberOfLines={2} style={styles.description}>
                    {modalTextElements().description}
                </Text>

                <ActionButton
                    title={modalTextElements().button}
                    additionalContainerStyles={styles.deleteButtonContainer}
                    additionalTextStyles={{ textTransform: 'uppercase' }}
                    onPress={onDelete}
                />
                <TertiaryButtonWithoutBg
                    title={modalTextElements().cancelButton}
                    additionalContainerStyles={styles.cancelButtonContainer}
                    onPress={onClose}
                />
            </View>
        );
    }, [recordingStatus]);
};

export { getRecordingModalStyles };
export default RecordingModal;
