import { Dimensions, PixelRatio } from 'react-native';
import { MonitoringDataClient } from '@24i/nxg-sdk-photon';
import {
    ScreenScrollMilestone,
    ContentEventType,
} from '@24i/nxg-sdk-photon/src/analytics/navigation';
import { AnalyticsEventType } from '@24i/nxg-sdk-photon/src/analytics';
import { PlaybackMilestoneReached } from '@24i/nxg-sdk-photon/src/analytics/player';
import { log } from '@24i/nxg-core-utils/src/logger';
import { AppVersion } from '@24i/nxg-core-utils/src/globalSingletons';
import { getCurrentDevice } from '@24i/nxg-core-utils';
import { v4 as uuid } from 'uuid';
import { encode } from 'base-64';
import axios from 'axios';
import { isPlatformWeb } from 'renative';

const { height, width } = Dimensions.get('window');

const getvideoResolution = height * PixelRatio.get();

const aspectRatio = width / height;

const getTimeZone = async () => {
    const timeZoneOffset = new Date().getTimezoneOffset() / 60;

    if (timeZoneOffset > 0) {
        return `UTC - ${timeZoneOffset - timeZoneOffset * 2}`;
    }
    return `UTC + ${timeZoneOffset - timeZoneOffset * 2}`;
};

// Additional properties, navigation specific
const pageIdName = 'page_id';
const screenScrollPercentageName = 'screen_scroll_percentage';
// Additional properties, player properties
const playerSessionIdName = 'player_session_id';
const assetIdName = 'asset_id';
const assetTypeName = 'asset_type';
const streamUrlName = 'stream_url';
const videoResolutionName = 'video_resolution';
const aspectRatioName = 'aspect_ratio';
const streamingTypeName = 'streaming_type';
const drmTypeName = 'drm_type';
const audiotypeName = 'audio_type';
const bitrateName = 'bitrate';
const percentOfPlaybackName = 'percent_of_playback';
const currentPlaybackTimeMsName = 'current_playback_time_ms';
const milestonePercentageName = 'milestone_percentage';

const eventProperties = async (
    event: AnalyticsEventType,
    eventId: string,
    serviceId: string,
    appId: string,
    title: string,
    userTimeZone: string
) => {
    // Standard properties for every event
    const properties = {
        timestamp_epoch: Math.floor(event.timestamp / 1000),
        device_type: await getCurrentDevice(),
        app_id: appId,
        user_timezone: userTimeZone,
        service_id: serviceId,
        app_title: title,
        application_version: AppVersion.get(),
        user: event.user,
        profile: event.profile,
        screen_resolution: `${width.toFixed(2)} 'X' ${height.toFixed(2)}`,
    };

    // switch to switch through all possible event types
    switch (event.type) {
        case 'SCREEN_VIEW':
        case 'APP_CLOSE': {
            const pageIdVal = (event as ContentEventType).pageId;

            properties[pageIdName] = pageIdVal;

            const body = {
                event_name: event.type,
                session_id: event.sessionId,
                event_id: eventId,
                properties,
            };

            if (event.type === 'APP_CLOSE') {
                body.session_id = '0';
            }
            return body;
        }

        case 'SCREEN_SCROLL_MILESTONE': {
            // Needed to cast the correct type and don't get ts issues
            const { value, pageId } = <ScreenScrollMilestone>event;

            const pageIdVal = pageId;
            const screenScrollPercentageVal = value;

            properties[pageIdName] = pageIdVal;
            properties[screenScrollPercentageName] = screenScrollPercentageVal;

            const body = {
                event_name: event.type,
                session_id: event.sessionId,
                event_id: eventId,
                properties,
            };
            return body;
        }

        case 'PLAYBACK_HEARTBEAT':
        case 'PLAYBACK_START':
        case 'PLAYBACK_PAUSE':
        case 'PLAYBACK_ENDED':
        case 'PLAYBACK_BUFFERING_FINISHED':
        case 'PLAYBACK_BUFFERING_START':
        case 'PLAYBACK_MILESTONE_REACHED':
        case 'SESSION_START':
        case 'SESSION_CLOSE': {
            // Needed to cast the correct type and don't get ts issues
            const {
                value,
                playerSessionId,
                asset,
                source,
                percentOfPlayback,
                currentPlaybackTimeMs,
            } = <PlaybackMilestoneReached>event;

            const finalProperties = {
                ...properties,
                [playerSessionIdName]: playerSessionId,
                [assetIdName]: asset?.id,
                [assetTypeName]: asset?.type,
                [streamUrlName]: source?.url,
                [videoResolutionName]: getvideoResolution?.toFixed(2),
                [aspectRatioName]: aspectRatio.toFixed(2),
                [streamingTypeName]: asset?.stream?.type,
                [drmTypeName]: source?.drm?.certificate,
                [audiotypeName]: null,
                [bitrateName]: null,
                [currentPlaybackTimeMsName]: currentPlaybackTimeMs,
            };

            // If milestone event is fired, percent of playback is not needed
            if (event.type === 'PLAYBACK_MILESTONE_REACHED') {
                finalProperties[milestonePercentageName] = value;
            } else {
                finalProperties[percentOfPlaybackName] = percentOfPlayback?.toFixed(2);
            }
            const body = {
                event_name: event.type,
                session_id: event.sessionId,
                event_id: eventId,
                properties: finalProperties,
            };
            return body;
        }

        default: {
            // If events are added, but the client is not updated this will fire
            const body = {
                event_name: `NONE_SPECIFIED_EVENT_${event.type}`,
                session_id: event.sessionId,
                properties: {},
            };
            return body;
        }
    }
};

export class DataPlatformClient implements MonitoringDataClient {
    constructor(props) {
        this.env = props?.monitoringEnvironment ? props?.monitoringEnvironment : 'Development';
        this.props = props;
    }

    name = 'DataPlatform';

    env = 'Development';

    props: any;

    // eslint-disable-next-line class-methods-use-this
    async init() {}

    // eslint-disable-next-line class-methods-use-this
    async logEvent(event: AnalyticsEventType) {
        if (!event.type) {
            throw new Error('Event name is required in order to send an event!');
        }
        const eventId = uuid();
        const userTimeZone = await getTimeZone();

        const { id, appId, title } = this.props.apiService;
        const { username, password, ingestUrl, ingestPort, proxyUrl } =
            this.props.features.dataPlatform;

        const body = await eventProperties(event, eventId, id, appId.default, title, userTimeZone);

        const dataIngestUrl = `${ingestUrl}:${ingestPort}`;

        const token = `${username}:${password}`;
        const encodedToken = `Basic ${encode(token)}`;

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Target-URL': `${dataIngestUrl}`,
                Authorization: encodedToken,
            },
        };

        try {
            if (isPlatformWeb) {
                await axios.post(proxyUrl, body, axiosConfig).catch((error) => {
                    log('Web: Sending anaytics event error: ', error);
                });
            } else {
                await axios.post(dataIngestUrl, body, axiosConfig).catch((error) => {
                    log('Sending anaytics event error: ', error);
                });
            }
        } catch (err) {
            log('ERROR SENDING ANALYTICS EVENT', err);

            throw err;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    async logException(exception: unknown) {
        log(exception);
    }
}

export const createDataPlatformClient = (runtimeConfig: unknown) =>
    new DataPlatformClient(runtimeConfig);
