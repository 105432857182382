import { useState, useRef } from 'react';
import { isPlatformWeb } from 'renative';
import { Asset } from '@24i/nxg-sdk-photon';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { log } from '@24i/nxg-core-utils/src/logger';
import { UseGridWithLazyLoadingInput, UseGridWithLazyLoadingOutput } from '../../type';

export const useGridWithLazyLoading = (
    fetchData: UseGridWithLazyLoadingInput
): UseGridWithLazyLoadingOutput => {
    const [data, setData] = useState<Asset[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const offsetRef = useRef(0);
    const { mobile, desktop } = getRuntimeConfig('defaultLazyLoading');

    const size = isPlatformWeb ? desktop : mobile;

    const getData = async () => {
        if (fetchData) {
            try {
                const fetchedData = await fetchData(offsetRef.current, size);

                // if empty list is returned (it means favorite list is empty or user reached end of the list when scrolling)
                // set offsetRef to -1 so it wouldn't trigger onEndReached function anymore because there is no more data to load (all of the data is already loaded)
                if (!fetchedData.length) offsetRef.current = -1;
                else setData(fetchedData);
            } catch (error) {
                log(error);
            } finally {
                setIsLoading(false);
            }
            return;
        }
        offsetRef.current = -1;
    };

    const refetchData = () => {
        setIsLoading(true);
        offsetRef.current = 0;
        getData();

        return () => {
            setData([]);
        };
    };

    const handleOnEndReached = () => {
        if (offsetRef.current >= 0) {
            offsetRef.current += size;
            getData();
        }
    };

    return { data, isLoading, getData, handleOnEndReached, refetchData, setData };
};
