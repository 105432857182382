import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src/models/theme';
import { MenuItemStylesWeb, MenuItemVariant, ProfileItemStyles } from '../types';

const iconSizeS = 20;
const iconSizeL = 24;
const iconSizeXL = 30;

const MenuItem = (theme: Theme): MenuItemStylesWeb => ({
    title: {
        color: theme.color.textPrimary,
        fontSize: 16,
        fontWeight: 'bold',
        ...getFont(theme, 'h3'),
    },
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '80%',
        height: '100%',
        alignItems: 'center',
        marginLeft: 32,
        marginRight: 32,
    },
    titleContainer: {
        marginLeft: 12,
    },
    icon: {
        color: theme.color.textPrimary,
        fontSize: iconSizeL,
        alignItems: 'center',
        justifyContent: 'center',
    },
    holder: {
        height: 70,
        width: '100%',
    },
    iconImage: {
        color: theme.color.textPrimary,
        width: iconSizeL,
        height: iconSizeL,
        alignItems: 'center',
        justifyContent: 'center',
    },
    enlargeIcon: {
        width: iconSizeXL,
        height: iconSizeL,
    },
});

const getMenuItemFontProps =
    (theme: Theme, isBottomItem: boolean) => (state: 'active' | 'inactive') => {
        const themeElementName = `menuItem-${state}`;
        return isBottomItem ? theme.fonts.S?.[themeElementName] : getFont(theme, themeElementName);
    };

const getTopBarMenuItemStyles = (theme: Theme, variant?: MenuItemVariant): MenuItemStylesWeb => {
    // Since getFont wasn't returning the right media-query for the bottom menu, I had to force it to use S fonts for it
    const isBottomMenuItem = variant === 'bottom-menu';
    const getFontProps = getMenuItemFontProps(theme, isBottomMenuItem);
    const inactiveFontProps = getFontProps('inactive');
    const activeFontProps = getFontProps('active');

    return {
        title: {
            padding: 0,
            ...inactiveFontProps,
            color: theme.color.menuItemInactive,
        },
        titleActive: {
            color: theme.color.menuItemActive,
            opacity: 1,
            ...activeFontProps,
        },
        icon: {
            fontSize: iconSizeS,
            marginRight: 8,
            marginLeft: 4,
            paddingLeft: 1,
            color: theme.color.contrast5,
            // type of breakpoint does not exist
            // @ts-ignore
            '@media (max-width: 800px)': {
                fontSize: iconSizeL,
                marginRight: 0,
                marginLeft: 0,
                marginBottom: 4,
            },
        },
        iconActive: {
            color: theme.color.menuItemActive,
            opacity: 1,
        },
        iconImage: {
            width: iconSizeS,
            height: iconSizeS,
            marginRight: 8,
            marginLeft: 4,
            opacity: 0.6,
            // type of breakpoint does not exist
            // @ts-ignore
            '@media (max-width: 800px)': {
                width: iconSizeL,
                height: iconSizeL,
                marginRight: 0,
                marginBottom: 4,
            },
        },
        itemContainer: {
            borderColor: 'transparent',
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden',
            color: `${theme.color.menuItemInactive}99`,
            marginVertical: 10,
            // type of breakpoint does not exist
            // @ts-ignore
            '@media (max-width: 800px)': {
                marginTop: 4,
                marginBottom: 4,
                flexDirection: 'column',
                marginRight: 0,
            },
            ':hover': { color: `${theme.color.menuItemActive}` },
        },
        titleContainer: {
            flex: 1,
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'row',
        },
        profileAvatarImageContainer: {
            width: 36,
            height: 36,
            marginRight: 10,
            borderRadius: 4,
            overflow: 'hidden',
        },
        plusBackground: {
            width: 36,
            height: 36,
            paddingLeft: 3,
            marginRight: 9,
            borderRadius: 4,
            backgroundColor: 'rgba(255,255,255,0.2)',
            justifyContent: 'center',
        },
        lineDivider: {
            width: 220,
            height: 1,
            backgroundColor: 'rgba(62, 61, 64, 0.8)',
            top: 52,
            left: -19,
            position: 'absolute',
        },
        holder: {
            flex: 1,
        },
        enlargeIcon: {
            width: iconSizeXL,
            height: iconSizeL,
        },
    };
};

const getProfileItemStyles = (theme: Theme): ProfileItemStyles => {
    const defaultStyle = getTopBarMenuItemStyles(theme);
    return {
        ...defaultStyle,
        itemContainer: {
            ...defaultStyle.itemContainer,
            marginVertical: 12,
        },
    };
};

const getDividerUnderStyles = (theme: Theme): ProfileItemStyles => {
    const defaultStyle = getTopBarMenuItemStyles(theme);
    return {
        ...defaultStyle,
        itemContainer: {
            ...defaultStyle.itemContainer,
            overflow: 'visible',
            marginVertical: 12,
        },
    };
};

export { getTopBarMenuItemStyles, getProfileItemStyles, getDividerUnderStyles };
export default MenuItem;
