import { Subscription } from '@24i/nxg-sdk-photon';

export interface ChangeSubscriptionInterface {
    currentPlan: Subscription;
    availableSubscriptions: Subscription[];
    onSubscriptionSelect: (itemId: string) => void;
    onCurrentSubscriptionCancel: (itemId: string) => void;
}

export enum CHANGE_SUBSCRIPTION_TEST_IDS {
    SUBSCRIPTION_SCREEN_ITEM_CURRENT = 'subscription_screen_item_current',
    SUBSCRIPTION_SCREEN_ITEM = 'subscription_screen_item_',
    SUBSCRIPTION_CANCEL_BUTTON = 'subscription_cancel_button',
}
