import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';
import { breakpoints } from '../../../../constants';

export type ExpandedPlayerContainerStyles = {
    container: ViewStyle;
    inner: ViewStyle;
    closeButton: ViewStyle;
    centeredContainer: ViewStyle;
};

export const getStyles = (
    theme: Theme,
    screenHeight: number,
    isTablet: boolean
): ExpandedPlayerContainerStyles => ({
    container: {
        height: screenHeight,
        backgroundColor: theme.color.background1,
        paddingBottom: isTablet ? 160 : 64,
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'flex-end',

        [`@media (max-width: ${breakpoints.sm})`]: {
            paddingTop: 170,
        },
    },
    centeredContainer: {
        justifyContent: 'center',
    },
    inner: {
        maxWidth: 740,
        minWidth: 312,
        width: '100%',
        margin: 'auto',
    },
    closeButton: {
        position: 'absolute',
        top: 32,
        left: 32,
        [`@media (max-width: ${breakpoints.sm})`]: {
            top: 20,
            left: 20,
        },
        width: 50,
        height: 50,
    },
});
