import { createGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { TIMELINE_EVENT_TYPE_GUARD } from '../enums';

export const TimelineEventGuard = createGuard(
    'Timeline',
    {
        start: t.number,
        end: t.number,
        type: TIMELINE_EVENT_TYPE_GUARD,
        name: t.string,
    },
    {}
);

export type TimelineEvent = t.TypeOf<typeof TimelineEventGuard>;
