import { useEffect, useState } from 'react';
import {
    isPurchase,
    isRent,
    isSubscription,
    Product,
    Purchase,
    Rent,
    Subscription,
} from '@24i/nxg-sdk-photon/src';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useTranslation } from 'react-i18next';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import { ConfirmPurchaseProps } from '../types';
import usePaymentPinProtection from '../../../../hooks/usePaymentsPinProtection';
import { useAppConfigQuery } from '../../../../hooks/query/useAppConfigQuery';

export const getOfferId = (selectedItem: Purchase | Rent | Product | Subscription) => {
    if (isSubscription(selectedItem)) return selectedItem.externalAccessValue;
    if (isRent(selectedItem)) return selectedItem.externalAccessValue;
    if (isPurchase(selectedItem)) return selectedItem.externalAccessValue;
    return '';
};

export const useShared = ({
    selectedItem,
    closeModal,
    onPinValidationVisible,
}: ConfirmPurchaseProps) => {
    const { isActive: isPinProtectionActive } = usePaymentPinProtection();
    const { appSettings } = useAppConfigQuery();
    const { t } = useTranslation();
    const [isPinControlVisible, setPinControlVisible] = useState(false);
    const { sessionId } = useSessionId();

    const handleOpenPinControl = (value: boolean) => {
        setPinControlVisible(value);
        onPinValidationVisible?.(value);
    };

    const onModalClose = () => {
        closeModal?.();
        setPinControlVisible(false);
    };

    const isPinVerificationActive =
        isPinProtectionActive && appSettings?.features?.pinProtection?.enabled;

    useEffect(() => {
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
            screen: t('analytics.documentTitle.purchase.step2'),
            sessionId,
        });
        onAnalytics(ANALYTICS_TRIGGERS.PURCHASE_START, selectedItem);
    }, []);

    return {
        offerId: getOfferId(selectedItem),
        isPinControlVisible,
        isPinVerificationActive,
        setPinControlVisible: handleOpenPinControl,
        closeModal: onModalClose,
    };
};
