import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';
import { isPlatformIos, isPlatformAndroid } from 'renative';
import { ModalStyles } from '../../../types';

const getStyles = (theme: Theme): ModalStyles => ({
    textInputContainer: {
        height: 48,
        backgroundColor: 'rgba(97, 98, 100, 0.6)',
        ...getBorderRadius(theme.elements, 'searchField-cornerRadius'),
    },
    textInputContainerErrorState: {
        borderColor: theme.color.error,
        borderWidth: 1,
    },
    textInput: {
        flex: 1,
        color: theme.color.textPrimary,
        marginHorizontal: 16,
        textAlign: 'left',
        backgroundColor: 'transparent',
        lineHeight: isPlatformIos ? 0 : 20,
        ...getFont(theme, 'body1', isPlatformAndroid),
    },
    textInputErrorText: {
        marginTop: 8,
        textAlign: 'center',
        color: theme.color.error,
        ...getFont(theme, 'body2'),
    },
    buttonsContainer: {
        justifyContent: 'flex-end',
        flexDirection: 'row',
        width: '100%',
        marginTop: 24,
    },
    description: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        ...getFont(theme, 'body2'),
        marginTop: 12,
        marginBottom: 26,
    } as ViewStyle,
    inactiveButton: {
        opacity: 0.4,
    },
});

export default getStyles;
