export const DATE = 'date';
export const TIME = 'time';
export const noop = () => {};
export const DEFAULT = 'default';
export const SPINNER = 'spinner';
export const CALENDAR = 'calendar';
export const CLOCK = 'clock';
export const TIME_DIFFS = {
    P_YEAR: 31536000000,
    M_YEAR: -31536000000,
    P_MONTH: 2592000000,
    M_MONTH: -2592000000,
    P_DAY: 86400000,
    M_DAY: -86400000,
    P_HOUR: 3600000,
    M_HOUR: -3600000,
    P_MINUTE: 60000,
    M_MINUTE: -60000,
};
