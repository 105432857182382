import { extendGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ASSET_TYPE, BLOCKING_REASON_TYPES } from '../enums';
import { AssetGuard } from './asset';

const SeriesTypeGuard = t.keyof({
    podcast: null,
    series: null,
});

export const EpisodeGuard = extendGuard(
    'Episode',
    AssetGuard,
    {
        episodeNumber: t.number,
        seasonNumber: t.number,
        series: t.string,
        seriesType: SeriesTypeGuard,
    },
    {
        seriesId: t.string,
        nextEpisodeId: nullable(t.string),
        fullReleaseDate: t.string,
        podcastName: nullable(t.string),
        seriesName: nullable(t.string),
    }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEpisode = (asset: any): asset is Episode =>
    asset && asset.type === ASSET_TYPE.EPISODE;

export const isAnnouncedEpisode = (asset: any): asset is Episode => {
    return (
        isEpisode(asset) &&
        (asset.blocked || []).some((block) => block.reason === BLOCKING_REASON_TYPES.BLOCKED_WINDOW)
    );
};

export type Episode = t.TypeOf<typeof EpisodeGuard>;
export type SeriesType = t.TypeOf<typeof SeriesTypeGuard>;
export type isAnnouncedEpisode = Episode;
