// AWAIT REVISION ON toWatchContent

import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Interactable, ActivityIndicator } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import AccountDetailsScreen from '@24i/nxg-sdk-smartott/src/screens/AccountDetails';
import getAccountSubscriptionStyles from '../styles';

const AccountSubscriptionScreen = ({
    currentPlan,
    styles: getStyles,
    plans,
    isLoading,
    onAddSubscriptionPress,
}) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const isSubscriptionActive = currentPlan >= 0;

    const accountDetails = [
        {
            title: plans[currentPlan]?.name,
            data: <>{plans[currentPlan]?.description}</>,
            customButton: (
                <Interactable onPress={onAddSubscriptionPress}>
                    <Text style={styles?.backButtonText}>{t('common.change')}</Text>
                </Interactable>
            ),
        },
    ];

    if (isLoading) return <ActivityIndicator color={theme.color.textPrimary} size="large" />;
    if (isSubscriptionActive) return <AccountDetailsScreen accountDetails={accountDetails} />;
    return (
        <View style={styles.container}>
            <Text
                numberOfLines={2}
                style={[styles.explanationText, { opacity: 0.6, marginBottom: 48 }]}
            >
                {`${t('svod.manage.noData.title')}. ${t('subscription.toWatchContent')}.`}
            </Text>
            <Interactable
                style={[styles.modalButton, { width: 234 }]}
                onPress={onAddSubscriptionPress}
            >
                <Text style={styles.modalButtonText}>{t('svod.add.title')}</Text>
            </Interactable>
        </View>
    );
};

AccountSubscriptionScreen.propTypes = {
    styles: PropTypes.func,
    plans: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
        })
    ).isRequired,
    currentPlan: PropTypes.number,
    isLoading: PropTypes.bool,
};

AccountSubscriptionScreen.defaultProps = {
    currentPlan: 0,
    isLoading: true,
    styles: getAccountSubscriptionStyles,
};

export { getAccountSubscriptionStyles };
export default overridable(AccountSubscriptionScreen, 'AccountSubscriptionScreen');
