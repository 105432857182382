import Packshot from '@24i/nxg-sdk-gluons/src/components/ui/Packshot/index.web';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import React from 'react';
import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import getGridStyles from '../styles';
import type { GridItem, GridProps } from '../types';
import { getSubtitle, getTimeTextForAsset } from './utils';

const PackshotGrid = ({
    styles: getStyles = getGridStyles,
    onItemPress = () => {},
    items = [],
    displayType = 'landscape',
    actionIcon,
    isHighlighted,
    title,
    textColor = '',
    testId,
    is12HourClock = false,
    actionForAllItems,
    showMetadata,
}: GridProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation(['sott']);
    const styles = typeof getStyles === 'function' ? getStyles(theme, displayType) : getStyles;

    const { getActionIcon } = useAssetActions();

    const getProgress = (item: GridItem) => {
        if (item.progress) return item.progress;
        if (item?.continueWatchingOffset && item?.duration) {
            return item.continueWatchingOffset / item.duration;
        }

        return 0;
    };

    return (
        <>
            <View style={styles.outerContainer}>
                <View style={styles.titleContainer}>
                    <Text style={[styles.title, { color: textColor || theme.color.textPrimary }]}>
                        {title}
                    </Text>
                </View>
                {items?.length > 0 && (
                    <View style={styles.container}>
                        {items?.map((item, index) => {
                            const { type } = item;
                            const showTime =
                                type === ASSET_TYPE.BROADCAST ||
                                type === ASSET_TYPE.LIVE_EVENT ||
                                type === ASSET_TYPE.CHANNEL;

                            return (
                                <View
                                    style={styles.gridPackshotItem}
                                    key={item.editionId || item.id}
                                >
                                    <Packshot
                                        key={item.editionId || item.id}
                                        index={index}
                                        item={item}
                                        isGrid
                                        title={item.title || ''}
                                        subtitle={getSubtitle(item, t)}
                                        showMetadata={showMetadata}
                                        time={
                                            showTime
                                                ? getTimeTextForAsset(item, is12HourClock)
                                                : undefined
                                        }
                                        testID={`${testId}_asset_${index + 1}`}
                                        image={
                                            displayType === 'portrait'
                                                ? item.poster ||
                                                  item.heroPortrait ||
                                                  item.backgroundPortrait ||
                                                  item.still ||
                                                  ''
                                                : item.packshotLandscape ||
                                                  item.still ||
                                                  item.heroLandscape ||
                                                  item.background ||
                                                  ''
                                        }
                                        onPress={(_e) =>
                                            onItemPress({
                                                asset: item,
                                                action: actionForAllItems,
                                            })
                                        }
                                        progress={getProgress(item)}
                                        channelLogo={item.channelLogo || ''}
                                        displayType={displayType}
                                        isHighlighted={isHighlighted}
                                        actionIcon={
                                            actionIcon ?? getActionIcon(item, actionForAllItems)
                                        }
                                    />
                                </View>
                            );
                        })}
                    </View>
                )}
            </View>
        </>
    );
};

export default overridable(PackshotGrid, 'PackshotGrid');
