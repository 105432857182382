import { Theme } from '@24i/nxg-sdk-photon/src';
import { MoreScreenStyles } from '../types';

const getStyles = (theme: Theme): MoreScreenStyles => ({
    scrollViewStyle: {
        flex: 0,
        backgroundColor: theme.color.background1,
    },
    contentContainerStyle: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    backgroundImage: {
        flex: 1,
        backgroundColor: theme.color.background1,
    },
});

export default getStyles;
