import { GestureResponderEvent } from 'react-native';

const useViewModel = (props) => {
    const onLinkPress = (_event: GestureResponderEvent, url: string) => {
        window.open(url, '_blank');
    };
    return {
        ...props,
        onLinkPress,
    };
};

export { useViewModel };
