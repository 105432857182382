/* eslint-disable camelcase */
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { isPlatformTvos } from 'renative';
import { Payload, SelectContentResult } from '../types';
import { EVENTS } from '../constants';
import { mapTagsParams } from './mapTagsParams';

export const mapSelectContent = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): SelectContentResult => ({
    ...(isPlatformTvos ? { ...mapTagsParams(payload) } : {}),
    session_id: payload.payload.sessionId,
    is_authenticated: payload.userId ? 1 : 0,
    event: EVENTS.SELECT_CONTENT,
    event_trigger: triggerName,
    content_type: payload.payload.asset?.type,
    item_id: payload.payload.asset?.id,
    language: payload.language,
});
