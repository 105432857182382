// More information: https://reactnavigation.org/docs/stack-actions/#poptotop
import { StackActions } from '@react-navigation/native';

const popToTop = (navigation) => {
    const canGoBack = navigation.canGoBack();

    // See more: https://github.com/24i/prd-nxg-sdk-ui/pull/174
    if (canGoBack) {
        const pop = StackActions.popToTop();
        navigation.dispatch(pop);
    }

    return null;
};

export default popToTop;
