import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs/src/StyleSheet';
import { SubscriptionsUnavailableStyles } from '../types';

const getStyles = (theme: Theme): SubscriptionsUnavailableStyles => ({
    container: { flex: 1 },
    wrapper: { flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
    icon: {
        width: 120,
        height: 120,
        backgroundColor: theme.color.darker3,
        borderRadius: 60,
        padding: 20,
        marginBottom: 24,
    },
    title: {
        ...getFont(theme, 'h4'),
        textAlign: 'center',
        color: theme.color.textPrimary,
        marginBottom: 8,
    },
    description: {
        ...getFont(theme, 'body2'),
        textAlign: 'center',
        color: theme.color.textPrimary,
        opacity: 0.4,
        marginBottom: 8,
    },
    backButtonWrapper: {
        marginTop: 40,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default getStyles;
