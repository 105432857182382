import { useCallback, useEffect } from 'react';
import { NavigationContainerRef } from '@react-navigation/native';
import { v4 as uuid } from 'uuid';
import { PlaybackEventType } from '@24i/nxg-sdk-photon/src/analytics/player';
import { StackParamList } from '@24i/nxg-sdk-smartott/src/navigation/types';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useScreenChangeListener } from '../../../hooks/useScreenChangeListener';

let playerSessionId: string | undefined;
let playerSessionStarted = false;
let playerSessionAllowed = false;
let playerSessionEvent: Omit<PlaybackEventType, 'type'> | undefined;

export const usePlayerSessionId = (navigation?: NavigationContainerRef<StackParamList>) => {
    const { currentPage } = useScreenChangeListener(navigation);
    const { triggerMonitoringEvent } = useMonitoring();
    const isOnPlaybackScreen = currentPage?.name === SOTT_DEFAULT_SCREENS.PLAYBACK;

    useEffect(() => {
        if (isOnPlaybackScreen) {
            playerSessionAllowed = true;
            if (!playerSessionStarted) {
                if (!playerSessionId) playerSessionId = uuid();
                if (playerSessionEvent) {
                    triggerMonitoringEvent({
                        ...playerSessionEvent,
                        playerSessionId,
                        type: 'SESSION_START',
                    });
                    playerSessionStarted = true;
                }
            }
        } else {
            playerSessionAllowed = false;
            if (playerSessionStarted && playerSessionId && playerSessionEvent) {
                triggerMonitoringEvent({
                    ...playerSessionEvent,
                    playerSessionId,
                    type: 'SESSION_CLOSE',
                });
                playerSessionId = undefined;
                playerSessionStarted = false;
            }
        }
    }, [isOnPlaybackScreen]);

    const getPlayerSessionIdFor = useCallback(
        (event: Omit<PlaybackEventType, 'type'>): string | undefined => {
            playerSessionEvent = event;
            if (playerSessionId && playerSessionStarted) return playerSessionId;
            if (!playerSessionAllowed) return undefined;
            if (!playerSessionId) playerSessionId = uuid();
            triggerMonitoringEvent({
                ...playerSessionEvent,
                playerSessionId,
                type: 'SESSION_START',
            });
            playerSessionStarted = true;
            return playerSessionId;
        },
        []
    );

    return { playerSessionId, getPlayerSessionIdFor };
};
