import { createTestIDGetter } from '@24i/nxg-core-utils';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon/src';

export enum BANNER_CAROUSEL_TEST_IDS {
    CONTAINER = 'container',
    SLIDE_CONTAINER = 'slide_container',
    SLIDE_CONTAINER_PRESSABLE = 'slide_container_pressable',
    SLIDE_IMAGE = 'slide_image',
    SLIDE_PLAY_BUTTON = 'slide_play_button',
    SLIDE_INFO_BUTTON = 'slide_info_button',
}

export const getBannerCarouselTestID = createTestIDGetter('banner_carousel');

export enum HERO_BANNER_TEST_IDS {
    RIGHT_ARROW = 'rigth_arrow',
    LEFT_ARROW = 'left_arrow',
    TAG = 'tag',
    TITLE = 'title',
    METADATA = 'metadata',
    PLAY_BUTTON = 'play_button',
    INFO_BUTTON = 'info_button',
    CONTINUE_WATCHING_BUTTON = 'continue_watching_button',
    UNAVAILABLE_BUTTON = 'unavailable_button',
    WATCH_LIVE_BUTTON = 'watch_live_button',
    PURCHASE_BUTTON = 'purchase_button',
    PAGINATION_BUTTON = 'pagination_button',
}

export const getHeroBannerTestID = createTestIDGetter('hero_banner');

export const getPlayButtonTestID = (assetType: ASSET_TYPE, index?: number) => {
    switch (assetType) {
        case ASSET_TYPE.BROADCAST:
        case ASSET_TYPE.LIVE_EVENT:
            return getHeroBannerTestID(HERO_BANNER_TEST_IDS.WATCH_LIVE_BUTTON, {
                scopeIndex: index,
            });
        default:
            return getHeroBannerTestID(HERO_BANNER_TEST_IDS.PLAY_BUTTON, { scopeIndex: index });
    }
};
