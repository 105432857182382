import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const RentGuard = createGuard(
    'Rent',
    {
        active: t.boolean,
        type: t.literal('rental'),
        id: t.string,
        name: t.string,
        price: t.number,
        currency: t.string,
        description: nullable(t.string),
        externalAccessValue: t.string,
        options: nullable(t.unknown),
        externalAccessType: t.string,
    },
    {
        createdAt: t.string,
        updatedAt: t.string,
        expiresAt: t.number,
        applicableTaxRate: t.number,
        tags: t.array(t.string),
    }
);

export type Rent = t.TypeOf<typeof RentGuard>;

export const isRent = (data: unknown): data is Rent => {
    try {
        return !!decodeModelWith(RentGuard, data, 'Rent', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createRent = (data: unknown): Rent | never => {
    return decodeModelWith(RentGuard, data, 'Rent');
};
