import getDefaultModalStyles from '@24i/nxg-sdk-gluons/src/components/containers/Modal/styles';
import { Platform, ViewStyle } from 'react-native';
import { getFont } from '@24i/nxg-sdk-higgs';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { Theme } from '@24i/nxg-sdk-photon';
import { createAudioSubtitleModalStyles } from './types';

const styles = (theme: Theme): createAudioSubtitleModalStyles => {
    const defaultModalStyles = getDefaultModalStyles(theme);
    const containerHeight = DeviceInfo.isTablet() ? 362 : 282;
    const containerWidth = DeviceInfo.isTablet() ? 280 : 264;
    const containerPosition = DeviceInfo.isTablet() ? { justifyContent: 'center' } : null;
    return {
        container: {
            flex: 1,
            paddingLeft: DeviceInfo.isTablet() ? 0 : 40,
            ...containerPosition,
            flexDirection: DeviceInfo.isTablet() ? 'row' : 'column',
        } as ViewStyle,
        optionPickerContainer: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: containerWidth,
            height: containerHeight,
        },
        optionPickerAudio: {
            marginBottom: 40,
        },
        title: {
            color: theme.color.textPrimary,
            ...getFont(theme, 'h3'),
            marginBottom: 16,
            marginLeft: 40,
        },
        buttonIcon: {
            marginTop: 8,
            marginRight: 8,
            color: theme.color.textPrimary,
            width: 24,
            height: 24,
        },
        buttonText: {
            marginHorizontal: 0,
            padding: 0,
        },
        buttonContainer: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingVertical: 0,
            paddingLeft: 0,
            height: 40,
            width: '100%',
            marginBottom: 0,
            backgroundColor: 'transparent',
            marginHorizontal: 0,
        },
        optionContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        optionDivider: {
            backgroundColor: 'transparent',
            borderBottomColor: theme.color.lighter4,
            borderBottomWidth: 0.5,
            marginLeft: 40,
        },
        optionContainerScroll: {
            flex: 1,
        },
        modal: {
            ...defaultModalStyles,
            fixedBackground: {
                backgroundColor: Platform.OS === 'android' ? 'black' : 'transparent',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
            blurBackground: {
                backgroundColor: 'transparent',
            },
        },
    };
};

export default styles;
