// TODO implement
import { useState, useEffect } from 'react';

const useViewModel = (props) => {
    const [isFocused, setIsFocused] = useState(props?.isFocused);
    useEffect(() => {
        setIsFocused(false);
    }, []);
    return {
        ...props,
        isFocused,
    };
};

export { useViewModel };
