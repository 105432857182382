import React from 'react';
import { View, Text } from '@24i/nxg-sdk-quarks';
import Head from 'next/head';

/**
 * TODO: This needs to be a proper healthcheck where we verify our App is good to run and can be
 * considered "healthy".
 */
// eslint-disable-next-line react/prop-types, react/display-name

const NextHealthScreen = ({ response }) => (
    <>
        <Head>
            <meta name="robots" content="none" />
        </Head>
        <View>
            <Text style={{ color: 'white' }}>{response}</Text>
        </View>
    </>
);

export default NextHealthScreen;
