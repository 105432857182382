/* eslint-disable react/display-name */
import React from 'react';
import View from './View';
import { useViewModel } from './viewModel';
import * as seeAllPrerenderFunctions from '../../pages/see-all/[pageId]/[sectionId]';
import getSeeAllScreenStyles from './styles/index.web';
import { SeeAllScreenProps } from './types';

export default {
    Main: (props: SeeAllScreenProps) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    page: {
        'see-all': {
            '[pageId]': {
                '[sectionId]': seeAllPrerenderFunctions,
            },
        },
    },
    // default styles getter
    getDefaultStyles: getSeeAllScreenStyles,
};
