import { DropdownPickerStylesTypes } from '../types';

const pickerDropdownStyles = (): DropdownPickerStylesTypes => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        zIndex: 999,
    },
});
export default pickerDropdownStyles;
