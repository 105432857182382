import React from 'react';
import View from './View';
import { useViewModel } from './viewModel';

export default {
    Main: () => {
        const viewModelProps = useViewModel();
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
};
