import { useEffect } from 'react';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { useCommonNavEventParams } from '../useCommonNavEventParams';

export const useTriggerOnAppClose = () => {
    const event = useCommonNavEventParams();
    const { triggerMonitoringEvent } = useMonitoring();

    const handleAppStateChange = () => {
        triggerMonitoringEvent({ ...event, type: 'APP_CLOSE' });
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleAppStateChange);
        return () => window.removeEventListener('beforeunload', handleAppStateChange);
    }, []);
};
