import {
    RECORDING_STATUS,
    Theme,
    IconProps,
    RATING_SYSTEM,
    Rating,
    ThemeIconName,
} from '@24i/nxg-sdk-photon';
import type { TFunction } from 'i18next';

/* Rating Utils */
export type StarRating = {
    rating: number;
    system: RATING_SYSTEM;
};

const titleForRatingState = (assetRating: Rating | undefined, t: TFunction): string => {
    switch (assetRating?.ratingSystem) {
        case RATING_SYSTEM.FIVESTARS:
            return assetRating.rating ? assetRating.rating.toString() : t('asset.rate.addButton');
        default:
            return t('asset.rate.addButton');
    }
};

const iconForRatingState = (assetRating: Rating | undefined): ThemeIconName => {
    switch (assetRating?.ratingSystem) {
        case RATING_SYSTEM.FIVESTARS:
            return assetRating.rating ? 'onButtonTertiary-rating' : 'onButtonTertiary-ratingEmpty';
        default:
            return 'onButtonTertiary-ratingEmpty';
    }
};

/* Recording Utils */
const titleForRecordingState = (t: TFunction, recording: RECORDING_STATUS | undefined): string => {
    switch (recording) {
        case RECORDING_STATUS.PARTIAL:
        case RECORDING_STATUS.SCHEDULED:
            return t('recordings.cancel.cancelButton', { count: 1 });
        case RECORDING_STATUS.COMPLETED:
            return t('recordings.delete.deleteButton', { count: 1 });
        default:
            return t('recordings.add.addButton');
    }
};

const iconForRecordingState = (
    theme: Theme,
    recording: RECORDING_STATUS | undefined
): IconProps => {
    switch (recording) {
        case RECORDING_STATUS.PARTIAL:
        case RECORDING_STATUS.SCHEDULED:
            return theme.icons.details.cancelRecording;
        case RECORDING_STATUS.COMPLETED:
            return theme.icons.details.deleteRecording;
        default:
            return theme.icons.details.record;
    }
};

export { titleForRecordingState, iconForRecordingState, titleForRatingState, iconForRatingState };
