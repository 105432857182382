import React from 'react';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Image as WelcomeImage } from '@24i/nxg-sdk-photon/src/models/image';
import { Theme } from '@24i/nxg-sdk-photon';
import { SlideshowType } from '@24i/nxg-sdk-photon/src/models/welcomeScreen';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { useTheme } from '@24i/nxg-sdk-higgs';
import SingleImageWelcomeScreen from '../components/SingleImageWelcomeScreen';
import getCreateSingleImageWelomeScreenStyles from '../components/SingleImageWelcomeScreen/styles';
import WelcomeScreenCarousel from '../components/Carousel';
import getCreateWelomeScreenStyles from '../styles';
import { createWelcomeScreenStyles } from '../styles/types';

export interface WelcomeScreenProps {
    displaySingleWelcomeScreen: boolean;
    welcomeScreensByDevice: Array<WelcomeImage>;
    tabletLandscapeScreen: Array<WelcomeImage>;
    styles?: (theme: Theme) => createWelcomeScreenStyles;
    onPress: () => void;
    slideshowType?: SlideshowType;
}

const WelcomeScreen = ({
    welcomeScreensByDevice,
    tabletLandscapeScreen,
    onPress,
    styles: getStyles = getCreateWelomeScreenStyles,
    slideshowType,
    displaySingleWelcomeScreen = false,
}: WelcomeScreenProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation(['sott']);

    return (
        <>
            {displaySingleWelcomeScreen ? (
                <SingleImageWelcomeScreen
                    styles={getCreateSingleImageWelomeScreenStyles}
                    onPress={onPress}
                    welcomeScreens={welcomeScreensByDevice}
                />
            ) : (
                <>
                    <WelcomeScreenCarousel
                        slideshowType={slideshowType}
                        welcomeScreens={welcomeScreensByDevice}
                        tabletLandscapeScreen={tabletLandscapeScreen}
                        onPress={onPress}
                    />
                    <SubactionButton
                        title={t('common.skip')}
                        onPress={onPress}
                        additionalTextStyles={styles.skipButtonText}
                        additionalContainerStyles={styles.skipButtonContainer}
                    />
                </>
            )}
        </>
    );
};

export default overridable(WelcomeScreen, 'WelcomeScreen');
