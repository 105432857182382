import React from 'react';
import View from './View/index.web';
import { useViewModel } from './viewModel';
import { MySubscriptionsViewProps } from '../../types';

export default {
    Main: (props: MySubscriptionsViewProps) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
};
