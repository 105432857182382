var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;exports.reportWebVitals=reportWebVitals;var _extends2=_interopRequireDefault(require("@babel/runtime/helpers/extends"));var _react=_interopRequireDefault(require("react"));
var _logger=require("@24i/nxg-core-utils/src/logger");
var _reactI18next=require("react-i18next");
require("@adyen/adyen-web/dist/adyen.css");
require("react-loading-skeleton/dist/skeleton.css");

var _app=_interopRequireDefault(require("../app"));var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/pages/_app.js";

var vitals=[];
function reportWebVitals(metric){
vitals.push(metric);
if(process.browser){
(0,_logger.log)("reportWebVitals: '"+JSON.stringify(metric)+"'");
}
}


var footerIcons=[
{
font:'fontAwesome',
name:'twitter',
href:'https://twitter.com/24i_media'
},
{
font:'fontAwesome',
name:'instagram',
href:'https://www.instagram.com/24imedia/'
},
{
font:'fontAwesome',
name:'facebook',
href:'https://www.facebook.com/twentyfouri/'
},
{
font:'fontAwesome',
name:'home',
href:'https://www.24i.com/'
}];


var WebApp=function WebApp(props){
var _useTranslation=(0,_reactI18next.useTranslation)(['sott']),t=_useTranslation.t;

var footerLinks=[
{title:t('footer.contactUs'),href:'https://www.24i.com/contact/'},
{title:t('footer.news'),href:'https://www.24i.com/articles/'}];


return _react.default.createElement(_app.default,(0,_extends2.default)({footerLinks:footerLinks,footerIcons:footerIcons},props,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:49,columnNumber:12}}));
};var _default=

WebApp;exports.default=_default;