import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { View } from '@24i/nxg-sdk-quarks';
import {
    getPosition,
    getLabelValueMap,
    getDefaultLabel,
    createButtonComponent,
    getTransformedChildren,
} from './utils';
import { didMount, setMenuButtonDimensionsOnMount, notifyOnOpen } from './pickerHooks';
import DropDownMenuWrapper from './DropdownMenuWrapper';
import ModalWrapper from './ModalWrapper';
import PickerMenuContent from './PickerMenuContent';
import Item from './Item';
import Button from './Button';

const MODE_DIALOG = 'dialog';

const Picker = (props) => {
    const {
        selectedValue,
        onValueChange,
        children,
        backgroundColor,
        menuContainerStyle,
        withArrow,
        data,
        mode,
        onOpen,
        onClose,
    } = props;

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [layout, onLayout] = useState({});
    const [buttonDimensions, setButtonDimensions] = useState(null);
    const [menuDimensions, setMenuDimensions] = useState(null);
    const pickerRef = useRef();

    setMenuButtonDimensionsOnMount(pickerRef, setButtonDimensions, layout, isDropdownOpen);

    didMount(() => setIsMounted(true));

    notifyOnOpen(isMounted, isDropdownOpen, onOpen, setMenuDimensions, onClose);

    const dropDownOnLayout = (dropDownDimensions) => {
        setMenuDimensions(dropDownDimensions);
    };

    const childrenArray = React.Children.toArray(children);

    const labelValueMap = getLabelValueMap(data, childrenArray);

    const selectedLabel = labelValueMap[selectedValue] || getDefaultLabel(props);
    const ButtonComponent = createButtonComponent(childrenArray, selectedLabel, setDropdownOpen);

    const transformedChildren = getTransformedChildren(
        { onValueChange, ...props },
        childrenArray,
        setDropdownOpen
    );

    const WrappedComponent =
        mode === MODE_DIALOG ? (
            <ModalWrapper animationType="slide" visible menuContainerStyle={menuContainerStyle}>
                <PickerMenuContent transformedChildren={transformedChildren} />
            </ModalWrapper>
        ) : (
            <DropDownMenuWrapper
                backgroundColor={backgroundColor}
                menuContainerStyle={menuContainerStyle}
                menuPosition={getPosition({ buttonDimensions, menuDimensions })}
                withArrow={withArrow}
                onLayout={dropDownOnLayout}
                closeDropDown={() => {
                    setDropdownOpen(false);
                }}
            >
                <PickerMenuContent transformedChildren={transformedChildren} />
            </DropDownMenuWrapper>
        );

    return (
        <View onLayout={onLayout} ref={pickerRef}>
            {ButtonComponent}
            {isDropdownOpen ? WrappedComponent : null}
        </View>
    );
};

Picker.Item = Item;

Picker.Button = Button;

Picker.defaultProps = {
    selectedValue: '',
    children: null,
    backgroundColor: '#1260d7',
    menuContainerStyle: {},
    withArrow: false,
    data: null,
    mode: 'dialog',
    onOpen: () => {},
    onClose: () => {},
};

Picker.propTypes = {
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onValueChange: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string,
    children: ({ children }) => {
        if (!children) return false;
        const invalidChild = children.find((child) => {
            if (typeof child.find === 'function') {
                return child.find((deeperChild) => deeperChild.type !== Item);
            }

            return child.type !== Item && child.type !== Button;
        });

        if (invalidChild) {
            return new Error(`Invalid child component of type ${invalidChild.type} provided`);
        }
        return false;
    },
    menuContainerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]),
    withArrow: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ),
    mode: PropTypes.oneOf(['dropdown', 'dialog']),
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
};

export default Picker;
