import { ExtendedViewStyle } from '@24i/nxg-sdk-gluons';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import React, { forwardRef, useRef, useState } from 'react';
import StyleSheet from 'react-native-media-query';
import { useIsomorphicLayoutEffect } from 'swiper/cjs/react/use-isomorphic-layout-effect';
import { uniqueClasses } from 'swiper/cjs/react/utils';
import { ChildrenRenderFunction, SingleRowSwiperSlideProps } from './types';

const SingleRowSwiperSlide =
    // eslint-disable-next-line react/display-name
    forwardRef<Element, SingleRowSwiperSlideProps>(
        (
            {
                tag: Tag = 'div',
                children,
                className = '',
                swiper,
                zoom,
                style,
                ...rest
            } = {} as SingleRowSwiperSlideProps,
            externalRef
        ) => {
            const { ids, styles }: { ids: string[]; styles: ExtendedViewStyle } =
                StyleSheet.process(style);

            const slideElRef = useRef<HTMLElement | null>(null);
            const [slideClasses, setSlideClasses] = useState('swiper-slide');
            useIsomorphicLayoutEffect(() => {
                if (typeof externalRef === 'function') {
                    externalRef(slideElRef.current);
                } else if (externalRef) {
                    // @NOTE: Keeping this reassignment to be sure everything works
                    // eslint-disable-next-line no-param-reassign
                    externalRef.current = slideElRef.current;
                }

                if (!slideElRef.current || !swiper) return;
                if (swiper.destroyed) {
                    if (slideClasses !== 'swiper-slide') {
                        setSlideClasses('swiper-slide');
                    }
                }
            });

            let slideData;
            const renderChildren = () =>
                typeof children === 'function'
                    ? (children as ChildrenRenderFunction)(slideData)
                    : children;

            return (
                <Tag
                    ref={(node: HTMLElement) => {
                        slideElRef.current = node;
                    }}
                    className={uniqueClasses(`${slideClasses}${className ? ` ${className}` : ''}`)}
                    style={styles}
                    data-media={ids}
                    {...rest}
                >
                    {zoom ? (
                        <div
                            className="swiper-zoom-container"
                            data-swiper-zoom={typeof zoom === 'number' ? zoom : undefined}
                        >
                            {renderChildren()}
                        </div>
                    ) : (
                        renderChildren()
                    )}
                </Tag>
            );
        }
    );

SingleRowSwiperSlide.displayName = 'SwiperSlide';

const OverridableSwiperSlide = overridable(SingleRowSwiperSlide, 'SingleRowSwiperSlide');

export { SingleRowSwiperSlide as SwiperSlide };
export default OverridableSwiperSlide;
