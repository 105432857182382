import { Platform } from '@24i/nxg-sdk-quantum';
import { DialogStyles } from './types';

const HEADING_FONTSIZE = Platform.isTV ? 55 : 30;
const DESCRIPTION_FONTSIZE = Platform.isTV ? 30 : 15;
const BUTTON_MARGIN = 5;
const CONTAINER_HEIGHT = Platform.isTV ? '60%' : '70%';
const CONTAINER_WIDTH = Platform.isTV ? '25%' : '80%';

const getDialogStyles = (): DialogStyles => ({
    flex1: {
        flex: 1,
    },
    flex2: {
        flex: 2,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    dismissOverlay: {
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    contentContainer: {
        flex: 3,
        padding: 20,
    },
    heading: {
        fontSize: HEADING_FONTSIZE,
        color: 'white',
        fontWeight: 'bold',
    },
    description: {
        fontSize: DESCRIPTION_FONTSIZE,
        marginTop: 10,
        marginBottom: 10,
        color: 'white',
    },
    buttonsContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        margin: -BUTTON_MARGIN,
    },
    buttonRowContainer: {
        flexDirection: 'row',
    },
    button: {
        paddingVertical: 12,
        borderRadius: 10,
        margin: BUTTON_MARGIN,
        borderWidth: 2,
    },
    buttonText: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '500',
    },
});

const getContainerStyle = {
    height: CONTAINER_HEIGHT,
    width: CONTAINER_WIDTH,
    backgroundColor: CONTAINER_HEIGHT,
};

export { getDialogStyles };
export { getContainerStyle };
