import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import Theme from './Theme';
import { addToSchema, schemaFromDefaultStyles } from './schema';
import Ratio from './Ratio';
import StyleSheet, { initialize, getFont, getMargins, getBorderRadius } from './StyleSheet';

export * from './context/Theme';
export * from './hooks/useTheme';
export * from './HOCs/withTheme';
export * from './constants';
export {
    addToSchema,
    schemaFromDefaultStyles,
    Theme,
    Ratio,
    getFont,
    getMargins,
    getBorderRadius,
    initialize,
    StyleSheet,
};

export interface WithThemeProps<TStyles = StyleMap, TTheme = unknown> {
    styles: TStyles;
    theme: TTheme;
}

export type StyleMap = Record<string, AnyStyle>;
export type AnyStyle = StyleProp<ViewStyle | TextStyle>;

export interface Gradient {
    colors: Array<string>;
    percentages: Array<number>;
}
