import { Theme } from '@24i/nxg-sdk-photon';
import getDefaultActionButtonStyles from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton/styles';
import { IconButtonStyles } from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton/types';

const getOpenModalLinkStyles = (theme: Theme): IconButtonStyles => {
    const defaultButtonStyles = getDefaultActionButtonStyles(theme);
    return {
        ...defaultButtonStyles,
        holder: {
            ...defaultButtonStyles.holder,
            minWidth: 89,
            height: 38,
            maxHeight: 38,
            marginLeft: 30,
        },
        textFocused: {
            color: theme.color.textButtonPrimary,
        },
        text: {
            ...defaultButtonStyles.text,
            color: theme.color.textButtonPrimary,
            textTransform: 'uppercase',
            fontSize: 18,
        },
    };
};

export default getOpenModalLinkStyles;
