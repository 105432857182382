import { Broadcast, Channel } from '@24i/nxg-sdk-photon';
import {
    ChannelAndBroadcasts,
    ChannelAndPrograms,
    CustomFilterAdapter,
    DataProcessingResult,
    Filters,
} from '../src/types';
import CellModel from '../models/Cell';
import { cutOffCells, isOnThisDay, midnights } from './DateHelpers';

export const filterByDate = (data: Channel[], date: Date): ChannelAndBroadcasts[] => {
    const { todayMidnight, tomorrowMidnight } = midnights(date);

    const filteredData: ChannelAndBroadcasts[] = [];
    for (let i = 0; i < data.length; i++) {
        const filteredPrograms: Broadcast[] =
            data[i]?.programs?.filter((program) =>
                isOnThisDay(program.start, program.end, todayMidnight, tomorrowMidnight)
            ) || [];

        filteredData.push({
            channel: data[i],
            programs: filteredPrograms,
        });
    }
    return filteredData;
};

// TODO: This is not how to do a filter system. Why is there a special condition
// just for the date filter? How is date filter different from any other filter?
export const filter = (
    data: Channel[],
    dayData: ChannelAndBroadcasts[],
    filters: Filters,
    filterDate: (data: Channel[], filterValue: any) => ChannelAndBroadcasts[],
    customFilterAdapter: CustomFilterAdapter | null
): { filteredData: ChannelAndBroadcasts[]; selectedDate?: Date; scrollTop: boolean } => {
    let filteredData = dayData;
    let selectedDate: Date | undefined;
    let scrollTop = false;
    for (let f = 0; f < filters.length; f++) {
        if (filters[f].key === 'date') {
            filteredData = filterDate(data, filters[f].value);
            selectedDate = filters[f].value;
        } else {
            scrollTop = true;
        }
    }
    if (customFilterAdapter) {
        filteredData = customFilterAdapter(filters, filteredData);
    } else {
        for (let f = 0; f < filters.length; f++) {
            for (let i = 0; i < filteredData.length; i++) {
                if (
                    filters[f].key !== 'date' &&
                    filters[f].value.includes(filteredData[i].channel[filters[f].key])
                ) {
                    filteredData = filteredData.filter((el) =>
                        filters[f].value.includes(el.channel[filters[f].key])
                    );
                }
            }
        }
    }

    return { filteredData, selectedDate, scrollTop };
};

type ProcessingParams = {
    selectedDate?: Date;
    filter?: Boolean;
    landscapeCellHeight: number;
    pixelsPerMin: number;
    shouldBeLocked?: Boolean;
};

export const processData = (
    filteredData: ChannelAndBroadcasts[],
    {
        selectedDate = new Date(),
        filter,
        landscapeCellHeight,
        pixelsPerMin,
        shouldBeLocked,
    }: ProcessingParams
): DataProcessingResult => {
    const channels: string[] = [];
    const cells: CellModel[] = [];
    const matrix: CellModel[][] = [];
    const processedData: ChannelAndPrograms[] = [];
    let cellsArr: CellModel[] | undefined = [];
    let earliestProgram: CellModel | undefined;
    let earliestProgramX: number | null = null;
    let latestProgram: CellModel | undefined;
    let latestProgramX: number | null = null;

    for (let i = 0; i < filteredData.length; i++) {
        channels.push(filteredData[i].channel.id);
        const y: CellModel[] = [];

        cellsArr = filteredData[i].programs?.map((program, j) => {
            const { start, mins, startFromMid, endTillMid } = cutOffCells(
                selectedDate,
                program.start,
                program.end
            );
            const cell = new CellModel({
                data: {
                    ...program,
                    channelLogo: filteredData.find((item) => program.channelId === item.channel.id)
                        ?.channel.channelLogo,
                    startFromMid,
                    endTillMid,
                    selector: `${i}.${j}`,
                },
                modelType: program.type,
                height: landscapeCellHeight,
                width: mins * pixelsPerMin,
                x: start * pixelsPerMin,
                y: i * landscapeCellHeight,
                selector: null,
                isLocked: !!shouldBeLocked && !!program.isAdult,
            });

            y.push(cell);
            return cell;
        });

        matrix.push(y);
        if (cellsArr) cells.push(...cellsArr);
        processedData[i] = {
            channel: { ...filteredData[i].channel },
            programs: cellsArr ? cellsArr : [],
        };
    }

    processedData.forEach((datum) => {
        if (
            !!datum?.programs?.[0] &&
            (datum.programs[0].x || 0) >= (earliestProgramX || Infinity)
        ) {
            earliestProgramX = datum.programs[0].x;
            earliestProgram = datum.programs[0];
        }

        if (
            !!datum?.programs?.[datum.programs.length - 1] &&
            (datum.programs[datum.programs.length - 1].x || 0) +
                (datum.programs[datum.programs.length - 1].width || 0) <=
                (latestProgramX || 0)
        ) {
            latestProgramX =
                (datum.programs[datum.programs.length - 1].x || 0) +
                (datum.programs[datum.programs.length - 1].width || 0);
            latestProgram = datum.programs[datum.programs.length - 1];
        }
    });

    const { tomorrowMidnight, todayMidnight } = midnights(selectedDate);
    return {
        state: {
            channels,
            matrix,
            data: cells,
            lastDateChange: +new Date(),
            ...(filter && { lastFilter: +new Date() }),
            processedData,
            selectedDate,
            tomorrowMidnight,
            todayMidnight,
        },
        limits: {
            earliestProgram,
            earliestProgramX,
            latestProgram,
            latestProgramX,
        },
    };
};
