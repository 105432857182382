import { useEffect, useState } from 'react';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useCurrentWebPageConfig } from '@24i/nxg-sdk-smartott/src/hooks/useCurrentWebPageConfig';
import { Result } from './types';

export const getRouteName = () => {};

export const useScreenChangeListener = () => {
    const [currentPage, setCurrentPage] = useState<Result | null>(null);
    const pageConfigResult = useCurrentWebPageConfig();
    const router = useRouter();
    useEffect(() => {
        setCurrentPage({
            name: pageConfigResult.currentPageConfig?.name || '',
            key: '',
            params: router?.query,
        });
    }, [pageConfigResult?.currentPageConfig?.name, setCurrentPage]);

    return { currentPage };
};
