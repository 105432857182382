import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@24i/nxg-sdk-quarks';
import { withTheme, schemaFromDefaultStyles } from '@24i/nxg-sdk-higgs';
import Native from './Native';

const defaultStyles = {
    track: {},
    progress: {},
};

const ProgressBar = ({
    progress,
    color,
    trackColor,
    style,
    testID,
    styles,
    additionalTrackStyles,
    additionalProgressStyles,
}) => (
    <View
        style={[
            styles.track,
            Object.keys(additionalTrackStyles).length ? additionalTrackStyles : style,
            {
                backgroundColor: trackColor,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            },
        ]}
        testID={testID}
    >
        <View
            style={[
                styles.progress,
                additionalProgressStyles,
                {
                    width: `${progress * 100}%`,
                    height: '100%',
                    backgroundColor: color,
                },
            ]}
            testID={`${testID}-progress-${progress * 100}`}
        />
    </View>
);
ProgressBar.styles = schemaFromDefaultStyles(defaultStyles);

const styleProp = PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]);

ProgressBar.propTypes = {
    progress: PropTypes.number,
    color: PropTypes.string,
    trackColor: PropTypes.string,
    style: styleProp,
    styles: PropTypes.shape(ProgressBar.styles),
    additionalTrackStyles: styleProp,
    additionalProgressStyles: styleProp,
    testID: PropTypes.string,
};
ProgressBar.defaultProps = {
    progress: 0,
    color: '#b91e2d',
    trackColor: '#999999',
    style: { height: 10, width: 200 },
    testID: '',
    styles: defaultStyles,
    additionalTrackStyles: {},
    additionalProgressStyles: {},
};
export default withTheme(ProgressBar, 'ProgressBar');
export { ProgressBar, Native as NativeProgressBar };
