import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import {
    ImageGuard,
    ProfileImageGuard,
    UserGuard,
    RatingResponseGuard,
    UserCodeResponseDefRequired,
    UserCodeResponseDefPartial,
} from '@24i/nxg-sdk-photon';
import * as t from 'io-ts';

export const LoginResponseGuard = createGuard('LoginResponse', {
    token: t.string,
});

export const SelectUserProfileResponseGuard = createGuard('SelectUserProfileResponse', {
    token: t.string,
});

const ProfileResponseDefinition = {
    id: t.union([t.number, t.string]),
    userId: nullable(t.string),
    name: nullable(t.string),
    age: nullable(t.number),
    image: nullable(
        createGuard('Image', {
            id: t.string,
            name: t.string,
            default: t.boolean,
            images: nullable(
                createGuard('Images', {
                    avatar: nullable(t.array(ImageGuard)),
                })
            ),
        })
    ),
    defaultProfile: nullable(t.boolean),
    isPinRequired: nullable(t.boolean),
    selected: nullable(t.boolean),
};

export const FetchProfileResponseGuard = createGuard(
    'FetchProfileResponse',
    ProfileResponseDefinition
);

export const UpdateProfileResponseGuard = createGuard(
    'UpdateProfileResponse',
    ProfileResponseDefinition
);

export const CreateProfileResponseGuard = createGuard(
    'CreateProfileResponse',
    ProfileResponseDefinition
);

export const FetchProfilesResponseGuard = t.array(
    FetchProfileResponseGuard,
    'FetchProfilesResponse'
);

export const FetchUserResponseGuard = createGuard('FetchUserResponse', {
    user: UserGuard,
});

export const FetchProfileImagesResponseGuard = t.array(ProfileImageGuard);

export const AddToFavoriteResponseGuard = createGuard('AddToFavoriteResponse', {
    id: t.string,
    entityType: t.string,
    entityId: t.string,
    userId: t.string,
    profileId: t.string,
});

export const FetchFavoritesResponseGuard = t.array(
    AddToFavoriteResponseGuard,
    'FetchFavoritesResponse'
);

export const GetAssetRatingForUserResponseGuard = createGuard('GetAssetRatingForUser', {
    items: t.array(RatingResponseGuard),
    total: t.number,
    size: t.number,
    offset: t.number,
});

export const GetOAuthUrlResponseGuard = createGuard('GetOAuthUrl', {
    redirectUrl: t.string,
});

export const GetUserOauthTokenResponseGuard = createGuard('GetUserOauthToken', {
    token: t.string,
});

export const GetUserCodeResponseGuard = createGuard(
    'GetUserCodeResponse',
    UserCodeResponseDefRequired,
    UserCodeResponseDefPartial
);

export const GetUserCodeVerificationResponseGuard = createGuard('GetUserCodeVerificationResponse', {
    token: t.string,
});
