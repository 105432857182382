import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import React from 'react';
import getSectionStyles from './styles';
import { SectionStyleGetter } from './styles/types';

export interface CreateProfileSectionProps {
    styles?: SectionStyleGetter;
    children?: React.ReactNode;
}

const Section = ({
    styles: getStyles = getSectionStyles,
    children = <View />,
}: CreateProfileSectionProps) => {
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const styles = getStyles({ theme, dimensions });

    return (
        <View style={styles.container}>
            {children}
            <View style={styles.divider} />
        </View>
    );
};

export default overridable(Section, 'Section');
