import { useState, useEffect } from 'react';

import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';

const useViewModel = (_props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [redirectSigninUrl, setRedirectSigninUrl] = useState('');
    const userDataClient = useUserData();

    useEffect(() => {
        const fetchServiceURL = async () => {
            const { redirectUrl } = await userDataClient.getOAuthUrl();
            setRedirectSigninUrl(redirectUrl);
            setIsLoading(false);
        };
        fetchServiceURL();
    }, []);

    return {
        isLoading,
        redirectSigninUrl,
    };
};

export { useViewModel };
