import { useState } from 'react';
import { useDebounceFn, useEventListener } from '@24i/nxg-core-utils/src';

export const UP = 'UP';
export const DOWN = 'DOWN';

/**
 * Custom hook to return window scroll direction. Scroll direction is required
 * on web to stop data fetching occuring when the bottom of the page has been
 * reached and the user scrolls up.
 * @returns {string} returns either UP or DOWN as string
 */
export function useScrollDirection() {
    const [scrollDir, setScrollDir] = useState(DOWN);

    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
        const scrollY = window.pageYOffset;

        if (Math.abs(scrollY - lastScrollY) < threshold) {
            ticking = false;
            return;
        }
        setScrollDir(scrollY > lastScrollY ? DOWN : UP);
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
    };

    const onScroll = () => {
        if (!ticking) {
            window.requestAnimationFrame(updateScrollDir);
            ticking = true;
        }
    };

    const { run: onScrollDebounced } = useDebounceFn(onScroll, { wait: 200, leading: true });

    useEventListener('scroll', onScrollDebounced);

    return scrollDir;
}
