import { getFont } from '@24i/nxg-sdk-higgs';
import { isPlatformAndroid } from 'renative';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';

const SettingsWrapper = (theme) => ({
    container: {
        flex: 1,
        backgroundColor: theme.color.background1,
        alignItems: 'baseline',
    },
    scrollViewContainer: {
        flex: 1,
        alignSelf: 'center',
        width: DeviceInfo.isTablet() ? '54%' : '100%',
    },
    scrollViewContainerPortrait: {
        paddingHorizontal: 20,
    },
    scrollViewContentContainer: {
        paddingTop: 28,
        alignItems: 'center',
    },
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: isPlatformAndroid ? 16 : 8,
        marginBottom: 32,
    },
    headerContainerLandscape: {
        marginTop: isPlatformAndroid ? 32 : 16,
        marginHorizontal: 32,
    },
    headerText: {
        color: theme.color.textPrimary,
    },
    headerTextPortrait: {
        marginLeft: 8,
    },
    headerIcon: {
        ...getFont(theme, 'h1'),
        fontSize: 27,
        marginRight: 8,
        marginLeft: 0,
    },
});

export default SettingsWrapper;
