import React from 'react';
import { useTranslation } from 'react-i18next';
import formatDate from 'date-fns/format';

import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Subscription } from '@24i/nxg-sdk-photon';
import { getNextSubscriptionBillingDate } from '@24i/nxg-sdk-smartott/src/utils/subscriptions';

import getStyles from './styles';
import SubscriptionItem from '../../PurchasesItem/SubscriptionItem';

interface Props {
    subscription: Subscription;
    onCancel: () => void;
}

const CancelSubscription = ({ subscription, onCancel }: Props) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation();

    return (
        <View style={styles.wrapper}>
            <View style={styles.informationTextWrapper}>
                <Text style={styles.informationText}>
                    {t('svod.cancel.cancelConfirm.description')}
                </Text>
            </View>
            <SubscriptionItem item={subscription} showTag hasTransparentBackground showBorder />
            {!!subscription.expirationDate && (
                <View style={styles.informationTextWrapper}>
                    <Text style={styles.informationText}>
                        {t('svod.cancel.cancelConfirm.warning', {
                            endDate: formatDate(getNextSubscriptionBillingDate(subscription), 'P'),
                        })}
                    </Text>
                </View>
            )}
            <View style={styles.buttonWrapper}>
                <ActionButton
                    title={t('svod.cancel.cancelConfirm.title')}
                    onPress={onCancel}
                    additionalContainerStyles={{
                        width: 'auto',
                        height: 52,
                        paddingHorizontal: 16,
                    }}
                    additionalTextStyles={{
                        marginLeft: 0,
                    }}
                />
            </View>
        </View>
    );
};

export default CancelSubscription;
