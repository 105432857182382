import { View } from '@24i/nxg-sdk-quarks';
import Image from '@24i/nxg-sdk-quarks/src/components/Image/index.web';
import React, { ReactElement, memo, useRef } from 'react';
import { ChannelOverlayPropsWeb, ChannelOverlayStylesWeb } from './types';
import channelOverlayStyles from './styles/index.web';
import { getViewStyle } from './utils';

const ChannelOverlay = (props: ChannelOverlayPropsWeb): ReactElement => {
    const {
        hasBigChannelLogo,
        styles: getStyles = channelOverlayStyles,
        channelLogo,
        testID = '',
        theme,
        orientation = 'landscape',
        progressBarDisplayed,
    } = props;

    const styles = useRef<ChannelOverlayStylesWeb>(getStyles(theme)).current;

    return (
        <View
            testID={testID}
            style={[
                hasBigChannelLogo ? styles.bigChannelLogoWrapper : styles.smallChannelLogoWrapper,
                getViewStyle(hasBigChannelLogo, orientation, !!progressBarDisplayed, styles),
            ]}
        >
            <Image
                source={{ uri: channelLogo }}
                resizeMode="contain"
                style={hasBigChannelLogo ? styles.channelLogo : styles.bigPackshotChannelLogo}
            />
        </View>
    );
};

export default memo(ChannelOverlay);
