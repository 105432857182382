import { getFont } from '@24i/nxg-sdk-higgs';
import { TextStyle } from 'react-native';
import { Theme } from '@24i/nxg-sdk-photon';
import { createWelcomeScreenStyles } from './types';

const getCreateWelomeScreenStyles = (theme: Theme): createWelcomeScreenStyles => {
    return {
        skipButtonText: {
            color: theme.color.textPrimary,
            ...getFont(theme, 'h3'),
            textTransform: 'uppercase',
        } as TextStyle,
        skipButtonContainer: {
            position: 'absolute',
            height: 41,
            top: 57,
            right: 20,
            backgroundColor: 'transparent',
            justifyContent: 'center',
            shadowColor: 'transparent',
            zIndex: 1,
            width: 'auto',
        },
    };
};

export default getCreateWelomeScreenStyles;
