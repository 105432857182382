/* eslint-disable @typescript-eslint/naming-convention */
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, VideoProgressResult } from '../types';
import { EVENTS } from '../constants';
import { mapVideoStart } from './mapVideoStart';

export const mapVideoProgress = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): VideoProgressResult => {
    const video_percent = triggerName.split('_')[1];
    return {
        ...mapVideoStart(triggerName, payload),
        event: EVENTS.VIDEO_PROGRES,
        video_percent,
        video_current_time: Math.round((payload.payload.currentPlaybackTimeMs || 0) / 1000),
    };
};
