import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const UserGuard = createGuard('User', undefined, {
    id: nullable(t.union([t.string, t.number])),
    email: nullable(t.string),
    displayName: nullable(t.string),
    firstName: nullable(t.string),
    lastName: nullable(t.string),
    currency: nullable(t.string),
    locale: nullable(t.string),
    country: nullable(t.string),
    isPinSet: nullable(t.boolean),
    pending: nullable(t.boolean),
    externalId: nullable(t.string),
    age: nullable(t.number),
});

export type User = t.TypeOf<typeof UserGuard>;

export const isUser = (data: unknown): data is User => {
    try {
        return !!decodeModelWith(UserGuard, data, 'User', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createUser = (data: unknown): User | never => {
    return decodeModelWith(UserGuard, data, 'User');
};
