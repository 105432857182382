import React from 'react';
import View from './View/index.web';
import { useViewModel } from './viewModel';
import { ProfileDropdownProps } from './types';

const Main = (props: ProfileDropdownProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
