import React from 'react';
import View from './View';
import { useViewModel } from './viewModel';

export default {
    Main: Object.assign(
        (props) => {
            const viewModelProps = useViewModel(props);
            return <View {...viewModelProps} />;
        },
        { displayName: 'FallbackScreen' }
    ),
    View,
    useViewModel,
};
