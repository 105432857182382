import Toast, { ToastProps } from 'react-native-toast-message';

export const showToast = (message: string, options?: Partial<ToastProps>) => {
    Toast.show({
        type: options?.type || 'success',
        text1: message,
        position: 'bottom',
        autoHide: true,
        ...options,
        bottomOffset: 48,
    });
};
