var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.CUSTOM_SCREEN_BUILDERS=void 0;var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));var _extends2=_interopRequireDefault(require("@babel/runtime/helpers/extends"));



var _index=require("@24i/nxg-sdk-smartott/src/navigation/components/TopBarMenu/components/OpenModalLink/index.web");
var _screenConfigBuilders=require("@24i/nxg-sdk-smartott/src/navigation/navigationConfig/screenConfigBuilders");
var _react=_interopRequireDefault(require("react"));




var _TopBarPrimary=_interopRequireDefault(require("@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary"));
var _renative=require("renative");
var _SigninScreen=_interopRequireDefault(require("../screens/SigninScreen"));
var _SettingsScreen=_interopRequireDefault(require("../screens/SettingsScreen"));
var _SettingsService=_interopRequireDefault(require("../components/Settings/SettingsService"));
var _MvpdSignInScreen=_interopRequireDefault(require("../screens/MvpdSignInScreen"));var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/config/customScreens.tsx";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}

var header=function header(headerProps){return _react.default.createElement(_TopBarPrimary.default,(0,_extends2.default)({},headerProps,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:19,columnNumber:33}}));};

var CUSTOM_SCREEN_BUILDERS=_objectSpread(_objectSpread({},
_screenConfigBuilders.SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS),{},{
buildSignInScreen:function buildSignInScreen(menuItem,context){
var originalBuild=_screenConfigBuilders.SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSignInScreen(
menuItem,
context
);

var _context$features=context==null?void 0:context.features,adobePrimetime=_context$features.adobePrimetime;
var adobePrimetimeEnabled=adobePrimetime==null?void 0:adobePrimetime.enabled;

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:_SigninScreen.default
},
renderWebLink:function renderWebLink(_ref){var linkMenuItem=_ref.menuItem;return(
_react.default.createElement(_index.OpenModalLink,{
menuItem:linkMenuItem,

ModalComponent:
adobePrimetimeEnabled?
_MvpdSignInScreen.default:
_SigninScreen.default,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:38,columnNumber:17}}

));}});


},
buildSettingsScreen:function buildSettingsScreen(menuItem,context){
var originalBuild=_screenConfigBuilders.SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSettingsScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:_SettingsScreen.default
}});

},
buildServiceScreen:function buildServiceScreen(menuItem,context){
var originalBuild=_screenConfigBuilders.SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildServiceScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:_SettingsService.default
},
options:{
header:header,
headerShown:_renative.isFactorMobile
}});

}});exports.CUSTOM_SCREEN_BUILDERS=CUSTOM_SCREEN_BUILDERS;