import { useTranslation } from 'react-i18next';
import { isRent } from '@24i/nxg-sdk-photon';
import { getCurrencySymbol, CurrencyName } from '@24i/nxg-core-utils';
import { PurchasableItemViewProps } from '../../types';
import { ProductItemProps } from '../types';

export const useProductItem = ({
    item,
    onPress,
    onSecondaryButtonPress,
    showBorder,
    hasTransparentBackground,
    testID,
}: ProductItemProps): PurchasableItemViewProps => {
    const { t } = useTranslation();

    const messageBeforePrice = isRent(item) ? t('tvod.add.rentFor') : t('tvod.add.buyFor');

    const priceText = `${messageBeforePrice} ${getCurrencySymbol(item.currency as CurrencyName)}${
        item.price
    }`;

    const handleOnPress = () => {
        onPress?.(item.id);
    };

    const handleOnSecondaryButtonPress = () => {
        onSecondaryButtonPress?.(item.id);
    };

    return {
        tagText: '',
        itemInfoLines: [priceText],
        onPress: onPress ? handleOnPress : undefined,
        onSecondaryButtonPress: onSecondaryButtonPress ? handleOnSecondaryButtonPress : undefined,
        showBorder,
        hasTransparentBackground,
        textAfterDescription: '',
        title: item.name,
        description: item.description || '',
        secondaryButtonText: '',
        testID,
    };
};
