import { AppNavigationClient } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { SottMenuConfig } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/navConfigBuilder/types';
import { LayoutClient } from '../LayoutClient';
import { ImageServiceClient } from '../ImageServiceClient';
import { SentryMonitoringDataClient } from '../SentryMonitoringDataClient';
// import { DataPlatformClient } from '../DataPlatformClient';
import { GoogleAnalyticsClient } from '../GoogleAnalyticsClient';
import { TwentyFourIQClient } from '../TwentyFourIQClient';
import { TealiumClient } from '../TealiumClient';

export interface DefaultSDKClients {
    appNavigationClient: AppNavigationClient<SottMenuConfig>;
    imageServiceClient: ImageServiceClient;
    layoutClient: LayoutClient;
    sentryMonitoringDataClient: SentryMonitoringDataClient;
    // Disable for now
    // dataPlatformClient: DataPlatformClient;
    googleAnalyticsClient: GoogleAnalyticsClient;
    twentyFourIQClient: TwentyFourIQClient;
    tealiumClient: TealiumClient;
}

export const isDefaultSDKClients = (clients: unknown): clients is DefaultSDKClients => {
    if (clients && typeof clients === 'object') {
        return (
            'appNavigationClient' in clients &&
            'imageServiceClient' in clients &&
            'layoutClient' in clients &&
            'sentryMonitoringDataClient' in clients &&
            // 'dataPlatformClient' in clients &&
            'googleAnalyticsClient' in clients &&
            'twentyFourIQClient' in clients &&
            'tealiumClient' in clients
        );
    }
    return false;
};
