import { TransactionList } from '@cleeng/mediastore-sdk';
import React, { FC } from 'react';
import { CleengComponentWrapper } from '../CleengComponentWrapper';

export const CleengPaymentHistory: FC = () => {
    return (
        <CleengComponentWrapper>
            <TransactionList />
        </CleengComponentWrapper>
    );
};
