import React from 'react';
import { View, Modal, Interactable, Text, Image } from '@24i/nxg-sdk-quarks';
import { Platform } from '@24i/nxg-sdk-quantum';
import { splitToChunks } from '@24i/nxg-core-utils/src/array';
import defaultRenderButton from './defaultRenderButton';
import { DialogProps } from './types';
import { getDialogStyles, getContainerStyle } from './styles';

const Dialog = ({
    animationType = 'fade',
    visible = false,
    image = null,
    description,
    heading,
    containerStyle = getContainerStyle,
    buttons,
    numOfButtonsInRow = 2,
    overlayColor = 'rgba(240,240,240,0.93)',
    dismiss,
    renderButton = defaultRenderButton,
    styles: getStyles = getDialogStyles,
}: DialogProps) => {
    const styles = getStyles();
    const imageSource = typeof image === 'string' ? { uri: image } : image;
    const buttonRows = buttons && splitToChunks(buttons, numOfButtonsInRow);

    return (
        <Modal animationType={animationType} transparent visible={visible}>
            <View style={[styles.container, { backgroundColor: overlayColor }]}>
                {!Platform.isTV && <Interactable onPress={dismiss} style={styles.dismissOverlay} />}
                <View style={containerStyle}>
                    {imageSource && <Image style={styles.flex2} source={imageSource} />}
                    <View style={styles.contentContainer}>
                        <View style={styles.flex2}>
                            {typeof heading === 'string' ? (
                                <Text style={styles.heading}>{heading}</Text>
                            ) : (
                                heading
                            )}
                            {typeof description === 'string' ? (
                                <Text style={styles.description}>{description}</Text>
                            ) : (
                                description
                            )}
                        </View>
                        <View style={styles.buttonsContainer}>
                            {buttonRows &&
                                buttonRows.map((buttonRow, row) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <View style={styles.buttonRowContainer} key={`row-${row}`}>
                                        {buttonRow.map((button) =>
                                            renderButton({
                                                ...button,
                                            })
                                        )}
                                    </View>
                                ))}
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

export default Dialog;
