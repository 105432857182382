import React from 'react';

import View from './View';
import { useViewModel } from './viewModel';
import { PurchasesModalProps } from './types';

export { default as View } from './View';
export { useViewModel } from './viewModel';
export type { PurchasesModalProps } from './types';

const Main = (props: PurchasesModalProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
