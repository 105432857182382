import { Classification } from '@24i/nxg-sdk-photon';
import { BackstageAsset } from './guards';

export const determineAgeClassification = (asset: BackstageAsset): Classification | undefined => {
    if (typeof asset.ageClassification?.rating !== 'number') {
        if (typeof asset.ageClassification?.rating === 'string') {
            const ageRating = Number(asset.ageClassification?.rating);
            const isValidNumber = !Number.isNaN(ageRating);

            return isValidNumber
                ? { ...asset.ageClassification, rating: ageRating }
                : { ...asset.ageClassification, rating: null };
        }
        return { ...asset.ageClassification, rating: null };
    }
    return undefined;
};
