import React from 'react';
import { Text, View } from '@24i/nxg-sdk-quarks';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { getStyles } from './styles';
import { ChangeSubscriptionConfirmInterface, CHANGE_SUBSCRIPTION_CONFIRM } from './types';
import SubscriptionItem from '../../PurchasesItem/SubscriptionItem';

const ChangeSubscriptionConfirm = ({
    currentSubscription,
    newSubscription,
    onPaymentConfirm,
}: ChangeSubscriptionConfirmInterface) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View>
            <View style={styles.space}>
                <SubscriptionItem
                    testID={CHANGE_SUBSCRIPTION_CONFIRM.CHANGE_SUBSCRIPTION_SCREEN_ITEM_CURRENT}
                    item={currentSubscription}
                    showTag
                    showBorder
                    hasTransparentBackground
                />
            </View>
            <View style={styles.space}>
                <Text style={styles.informationText}>
                    {t('svod.change.changeConfirm.willBeChangedInto')}
                </Text>
            </View>
            <View style={styles.space}>
                <SubscriptionItem
                    testID={CHANGE_SUBSCRIPTION_CONFIRM.CHANGE_SUBSCRIPTION_SCREEN_ITEM_NEW}
                    item={newSubscription}
                    showTag
                    showBorder
                />
            </View>
            <View style={styles.buttonWrapper}>
                <ActionButton
                    testID={CHANGE_SUBSCRIPTION_CONFIRM.CHANGE_SUBSCRIPTION_CONFIRM_PAY_BUTTON}
                    title={t('vod.add.confirmAndPayButton')}
                    onPress={onPaymentConfirm}
                    additionalContainerStyles={{
                        width: 'auto',
                        height: 52,
                        paddingHorizontal: 16,
                    }}
                    additionalTextStyles={{
                        marginLeft: 0,
                    }}
                />
            </View>
        </View>
    );
};

export default ChangeSubscriptionConfirm;
