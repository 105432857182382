import { Ratio, getFont } from '@24i/nxg-sdk-higgs';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { isFactorTv, isPlatformAndroid, isPlatformWeb } from 'renative';

const isAndroidPhone = !DeviceInfo.isTablet() && isPlatformAndroid;
const isAndroidTablet = DeviceInfo.isTablet() && isPlatformAndroid;
const isiPad = DeviceInfo.isTablet() && !isAndroidTablet;
const isIphone = !DeviceInfo.isTablet() && !isAndroidPhone;

enum ORIENTATIONS {
    LANDSCAPE = 'LANDSCAPE',
    PORTRAIT = 'PORTRAIT',
}

interface Margins {
    top: number;
    bottom: number;
}

/**
 * So here we need to add different marginTop and Bottom on the first and last item depending on device type
 * so far those are the spacings for devices
 * iPad: {top:120, bottom: 160}
 * ios/android/ android tablet : {top: 28, bottom: 56 }
 */

export const itemMagins = (): Margins => {
    if (isiPad) return { top: 120, bottom: 160 };
    if (isIphone || isAndroidTablet || isAndroidPhone) return { top: 28, bottom: 56 };
    return { top: 0, bottom: 0 };
};

const scrollContentSpacing = (orientation: string): number => {
    if (isAndroidPhone) return 180;
    if (orientation === ORIENTATIONS.LANDSCAPE && !isPlatformWeb) return 100;
    if (isAndroidTablet) return 256;
    if (!isPlatformWeb) return 140;
    return 0;
};

const getPickerStyles = (theme, orientation) => ({
    container: {
        flex: 1,
    },
    selectedItem: {
        fontSize: isFactorTv ? Ratio(44) : 34,
        fontWeight: 'bold',
        ...getFont(theme, 'h3'),
        color: theme.color.textPrimary,
    },
    gradient: {
        position: 'absolute',
        height: '30%',
        left: 0,
        right: 0,
        zIndex: 9,
        flex: 1,
        top: isAndroidTablet ? -50 : 0,
    },
    gradientBottom: {
        position: 'absolute',
        height: '30%',
        bottom: isAndroidTablet ? -50 : 0,
        left: 0,
        right: 0,
        zIndex: 9,
        flex: 1,
    },
    wrapper: {
        backgroundColor: 'transparent',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    optionButton: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemIcon: {
        marginLeft: 2,
        marginRight: 13,
    },
    scrollTrack: {
        position: 'absolute',
        paddingTop: 9,
        paddingBottom: 9,
        top: 0,
        bottom: 0,
        right: 2,
        height: '100%',
        width: 5,
    },
    scrollIndicator: {
        width: 3,
        borderRadius: 1.5,
        backgroundColor: theme.color.contrast3,
        opacity: 0.5,
    },
    additionalContainerStyles: {
        paddingVertical: scrollContentSpacing(orientation),
    },
    dropdownContainer: {
        backgroundColor: theme.color.darker1,
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
    },
    pickerItem: {
        fontSize: 20,
        alignItems: 'center',
        lineHeight: Ratio(50),
        fontWeight: '300',
        ...getFont(theme, 'h3'),
        flexDirection: 'row',
        justifyContent: 'center',
        height: 68,
        backgroundColor: theme.color.darker1,
    },
    itemText: {
        color: `${theme.color.textPrimary}`,
        fontSize: 20,
        lineHeight: Ratio(50),
        fontWeight: '300',
        ...getFont(theme, 'h3'),
        marginHorizontal: 20,
    },
});

export default getPickerStyles;
