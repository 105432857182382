import { useQuery, UseQueryOptions } from 'react-query';
import { Asset, QUERY_KEYS, ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useAppConfigQuery } from '../useAppConfigQuery';

const useAssetRelatedQuery = (
    {
        assetId,
        assetType,
    }: {
        assetId?: string;
        assetType?: ASSET_TYPE;
    },
    options?: UseQueryOptions<Asset[]>
) => {
    const isOptionsEnabled = options?.enabled !== undefined ? options.enabled : true;
    const { fetchRecommended } = useContentData();
    const { appSettings } = useAppConfigQuery();

    const pageSize = appSettings?.features?.recommendedRow?.pageSize;

    return useQuery<Asset[]>(
        [QUERY_KEYS.assets, QUERY_KEYS.assetRelated, assetId],
        () => fetchRecommended(assetId, assetType, pageSize),
        {
            staleTime: 5 * 60 * 1000,
            ...options,
            enabled: !!assetId && !!assetType && isOptionsEnabled,
        }
    );
};

export default useAssetRelatedQuery;
