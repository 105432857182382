import { isPlatformWeb } from 'renative';
import { Ratio, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DimensionsInfo } from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions/types';
import { GRID_EXTRA_LARGE_BREAKPOINT } from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/constants';
import { ManageProfilesScreenStyles } from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/types';

const getStyles = (theme: Theme, dimensions: DimensionsInfo): ManageProfilesScreenStyles => ({
    screenContainer: {
        backgroundColor: theme.color.background1,
        flex: 1,
        minHeight:
            dimensions.width < GRID_EXTRA_LARGE_BREAKPOINT && isPlatformWeb
                ? dimensions.height
                : '100%',
    },
    title: {
        ...getFont(theme, 'h1'),
        color: theme.color.textPrimary,
        textAlign: 'center',
        marginTop: '5%',
        marginBottom: 40,
    },
    scrollViewContentContainer: {
        height: '90%',
        flexGrow: 1,
    },
    contentContainer: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: isPlatformWeb ? 0 : 90,
    },
    flatGridContainer: {
        alignItems: 'center',
        flex: 1,
        flexGrow: 1,
        justifyContent: 'center',
    },
    manageBtn: {
        marginTop: Ratio(40),
        marginBottom: Ratio(48),
        paddingVertical: Ratio(15),
        height: Ratio(80),
        paddingHorizontal: Ratio(300),
        backgroundColor: theme.color.buttonTertiary,
    },
    manageBtnIcon: {
        width: Ratio(45),
    },
    manageBtnText: { color: theme.color.textPrimary },
    doneButton: {
        marginBottom: 150,
        height: 'auto',
        paddingTop: 14,
        paddingBottom: 14,
    },
    logo: {
        position: 'absolute',
        width: 72,
        height: 42,
        top: 14,
        left: 48,
    },
    loader: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default getStyles;
