import { Subscription, Theme } from '@24i/nxg-sdk-photon';
import { AddSubscriptionScreenViewStyles } from '../styles/types';

export enum OnboardingSubscriptionSteps {
    Initial,
    Selection,
    Confirm,
    Success,
    Failure,
}

export interface AddSubscriptionViewProps {
    isLoading?: boolean;
    isOnboarding?: boolean;
    availableSubscriptions?: Subscription[];
    onMaybeLaterPress: () => Promise<void>;
    openSubscriptionModal?: (subscriptions: Subscription[], isOnboarding?: boolean) => void;
    styles?: (theme: Theme) => AddSubscriptionScreenViewStyles;
}
