import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle, ViewStyle } from 'react-native';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { TextPageChildContent } from '@24i/nxg-sdk-photon/src/models/textPageContent';
import { WithErrorModalProps } from '../../components/GenericModal/hoc/ErrorModal';

export interface TermsAndConditionsScreenStyle {
    loaderContainer: ViewStyle;
    wrapper: ViewStyle;
    title: TextStyle;
    button: ViewStyle;
    buttonContainer: ViewStyle;
    contentContainer: ViewStyle;
    onboardingHeaderWrapper: ViewStyle;
    // Styles for mobile/tablets
    scrollViewContentStyle: ViewStyle;
    bottomGradient: ViewStyle;
}

export type TermsAndConditionsStylesGetter = (theme: Theme) => TermsAndConditionsScreenStyle;

export interface TermsAndConditionsModelProps extends ScreenProps {
    isLoading: boolean;
    content: TextPageChildContent | null | undefined;
    isButtonDisabled: boolean;
    hasError: boolean;
    styles?: TermsAndConditionsStylesGetter;
    enableAcceptButton: () => void;
    onAcceptTermsAndConditions: () => void;
}

export type TermsAndConditionsViewProps = WithErrorModalProps & TermsAndConditionsModelProps;

export enum TERMS_AND_CONDITIONS_TEST_IDS {
    CONFIRM_BUTTON = 'terms_and_conditions_confirm_button',
    SCROLL_VIEW = 'terms_and_conditions_scroll_view',
}
