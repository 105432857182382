import React from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import {
    Asset,
    isBroadcast,
    isEpisode,
    isMovie,
    isSeries,
    PageSection,
    Season,
    isClip,
    isAnnouncedEpisode,
    isAnnouncedMovie,
    isAnnouncedSeries,
    isChannel,
    isLiveEvent,
    isCatchUp,
    isFuture,
    isLive,
    getIsPodcastSeries,
    getIsPodcastEpisode,
    Episode,
    ASSET_TYPE,
    QUERY_KEYS,
} from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';

import { getRuntimeConfig } from '../../Application/initApp';
import { SOTT_SCREENS } from '../../navigation/constants';
import useContinueWatchingQuery from '../query/continueWatching/useContinueWatchingQuery';
import useUniversalNavigation from '../useUniversalNavigation';
import useAssetBlockersValidation from '../useAssetBlockersValidation';
import { usePodcastPlayer } from '../../context/PodcastPlayerProvider';
import {
    DetailsScreenRouteParams,
    PlaybackScreenRouteParams,
    ScreenOrigin,
} from '../../navigation/types';

export type EpisodeReleaseOrder = 'first' | 'latest';

const isTypeFns = {
    clip: isClip,
    movie: isMovie,
    series: isSeries,
    episode: isEpisode,
    channel: isChannel,
    broadcast: isBroadcast,
    catchup: isCatchUp,
    future: isFuture,
    live: isLive,
    live_event: isLiveEvent,
    announced_movie: isAnnouncedMovie,
    announced_series: isAnnouncedSeries,
    announced_episode: isAnnouncedEpisode,
};
const assetTypes = Object.keys(isTypeFns) as Array<keyof typeof isTypeFns>;

export interface AssetActionsProps {
    origin?: ScreenOrigin;
}

export const EmptyAssetActionsProps: AssetActionsProps = {};

const useShared = ({ origin }: AssetActionsProps = EmptyAssetActionsProps) => {
    const queryClient = useQueryClient();
    const { data: continueWatchingPlaylist } = useContinueWatchingQuery();
    const { goTo } = useUniversalNavigation();
    const { handleBlockersCheck } = useAssetBlockersValidation({});
    const assetTypesConfig = getRuntimeConfig('assetTypes');
    const contentDataClient = useContentData();
    const { t } = useTranslation(['sott']);
    const {
        playPodcastEpisode,
        startLoading: startLoadingPodcastPlayer,
        closePlayer: closePodcastPlayer,
        getIsEpisodeInPlayer: getIsPodcastEpisodeInPlayer,
    } = usePodcastPlayer();

    const [isCanGoToDetail, isCanGoToPlayer] = React.useMemo(() => {
        const cantGoToDetailTypes = assetTypes.filter(
            (type) => !(assetTypesConfig?.[type]?.action.detail ?? true)
        );
        const cantGoToPlayerTypes = assetTypes.filter(
            (type) => !(assetTypesConfig?.[type]?.action.player ?? true)
        );

        const cantGoToDetailCheckFns = cantGoToDetailTypes.map((type) => isTypeFns[type]);
        const cantGoToPlayerCheckFns = cantGoToPlayerTypes.map((type) => isTypeFns[type]);

        const canGoToDetailMemo = (asset: Asset) =>
            !cantGoToDetailCheckFns.some((isTypeFn) => isTypeFn(asset));

        const canGoToPlayerMemo = (asset: Asset) =>
            !cantGoToPlayerCheckFns.some((isTypeFn) => isTypeFn(asset));

        return [canGoToDetailMemo, canGoToPlayerMemo];
    }, [assetTypesConfig]);

    const findEpisodeInProgress = (episodeAvailable: Asset): Asset | null => {
        const serieId = episodeAvailable.seriesId || episodeAvailable.series;
        // Check if there are episodes in progress
        const filterBySerie = (cwEpi: Asset) => cwEpi.series === serieId;
        const episodesInProgress = continueWatchingPlaylist?.filter((cwAsset) =>
            filterBySerie(cwAsset)
        );
        if (!isEmpty(episodesInProgress) && episodesInProgress) {
            // Get the last watched episode
            return episodesInProgress.reduce((acc: Asset, item: Asset) => {
                if (acc.continueWatchingLastTime && item.continueWatchingLastTime) {
                    return acc.continueWatchingLastTime > item.continueWatchingLastTime
                        ? acc
                        : item;
                }
                return acc;
            });
        }

        return null;
    };

    /**
     * Returns the last watched episode in progress, Otherwise returns the first episode.
     * @param seasons Season
     * @param releaseOrder EpisodeReleaseOrder
     * @returns Episode
     */
    const getEpisodeToWatch = (
        seasons: Season[],
        releaseOrder?: EpisodeReleaseOrder
    ): Asset | undefined => {
        if (isEmpty(seasons)) return undefined;

        let defaultEpisode: Episode;

        if (releaseOrder === 'latest') {
            const lastSeason = seasons[seasons.length - 1];
            defaultEpisode = lastSeason.episodes[lastSeason.episodes.length - 1];
        } else {
            const firstSeason = seasons[0];
            [defaultEpisode] = firstSeason.episodes;
        }

        const episodeInProgress = findEpisodeInProgress(defaultEpisode);

        return episodeInProgress ?? defaultEpisode;
    };

    /**
     * Checks if the user has viewed the asset recently.
     * @param asset Asset
     * @returns Boolean
     */
    const assetHasProgress = (asset: Asset): boolean => {
        if (isSeries(asset) || getIsPodcastSeries(asset)) {
            // Check if there are episodes in progress
            const filterBySerie = (cwEpi: Asset) => cwEpi.series === asset.id;
            const episodesInProgress = continueWatchingPlaylist?.filter((cwAsset) =>
                filterBySerie(cwAsset)
            );
            return !isEmpty(episodesInProgress);
        }
        if (
            (isMovie(asset) || isEpisode(asset)) &&
            asset?.continueWatchingOffset &&
            asset?.duration
        ) {
            return asset.continueWatchingOffset > 0 && asset.duration > 0;
        }
        if (isBroadcast(asset)) {
            return !(asset.progress == null) && asset.progress >= 0;
        }
        return false;
    };

    const resolvePackshotAction = (
        asset: Asset,
        action: PageSection['actionForAllItems']
    ): PageSection['actionForAllItems'] => {
        const canGoToPlayer = isCanGoToPlayer(asset);
        const canGoToDetails = isCanGoToDetail(asset);

        if (action) {
            if (action === 'player' && canGoToPlayer) {
                return action;
            }

            if (action === 'detail' && canGoToDetails) {
                return action;
            }
        }

        if (!canGoToDetails) {
            return 'player';
        }

        return 'detail';
    };

    const handlePackshotPress = async ({
        asset,
        action,
    }: {
        asset: Asset;
        action?: PageSection['actionForAllItems'];
    }) => {
        const resolvedAction = resolvePackshotAction(asset, action);

        if (resolvedAction === 'player') {
            const isPodcastEpisode = getIsPodcastEpisode(asset);

            if (isPodcastEpisode && getIsPodcastEpisodeInPlayer(asset.id)) return;

            const { hasBlocker } = await handleBlockersCheck({
                asset,
            });

            if (hasBlocker) return;

            if (isPodcastEpisode) {
                closePodcastPlayer();
                startLoadingPodcastPlayer();
                playPodcastEpisode(asset);
                return;
            }

            const payload: PlaybackScreenRouteParams = {
                id: asset.id,
                type: asset.type,
                asset,
                origin,
            };
            goTo(SOTT_SCREENS.Playback, payload);
        } else {
            const payload: DetailsScreenRouteParams = {
                id: asset.id,
                type: asset.type,
                asset,
                origin,
            };
            goTo(SOTT_SCREENS.Details, payload);
        }
    };

    const getActionIcon = (
        asset: Asset,
        action?: PageSection['actionForAllItems']
    ): 'play' | 'pause' | undefined => {
        const resolvedAction = resolvePackshotAction(asset, action);
        if (resolvedAction !== 'player') return undefined;

        return 'play';
    };

    const fetchEpisodeToReproduce = async (
        asset: Asset,
        releaseOrder?: EpisodeReleaseOrder
    ): Promise<Asset | undefined> => {
        if (!asset) return undefined;
        try {
            const seasons = await (queryClient.fetchQuery({
                queryKey: [QUERY_KEYS.assets, QUERY_KEYS.assetSeasons, asset.id],
                queryFn: () => contentDataClient.fetchEpisodes(asset.id, t),
            }) as Promise<Season[]>);

            if (!seasons) return undefined;

            return getEpisodeToWatch(seasons, releaseOrder);
        } catch (error) {
            console.log(error);
            return undefined;
        }
    };

    const prewarmPackshotPress = async (asset: Asset) => {
        let targetAsset: Asset = asset;
        const isSeriesType = [ASSET_TYPE.SERIES, ASSET_TYPE.PODCAST_SERIES].includes(asset.type);
        if (isSeriesType) {
            const target = ASSET_TYPE.PODCAST_SERIES ? 'latest' : 'first';
            targetAsset = (await fetchEpisodeToReproduce(asset, target)) ?? asset;
        }
        if (!targetAsset) return;
        try {
            await handleBlockersCheck({
                asset: targetAsset,
                noop: true,
            });
        } catch (error) {
            console.error('prewarm failed for', isSeriesType ? asset.title : targetAsset.title);
        }
        // Do nothing with the result, we just want it to be precalled to be added to the cache
    };

    return {
        getEpisodeToWatch,
        assetHasProgress,
        handlePackshotPress,
        prewarmPackshotPress,
        isCanGoToDetail,
        isCanGoToPlayer,
        getActionIcon,
        fetchEpisodeToReproduce,
    };
};

export default useShared;
