import { ViewStyle } from 'react-native';
import { AvatarPickerStylesGetter } from './types';
import { DESKTOP_VERSION_BREAKPOINT } from '../../../../../utils/styles/helpers';

export const getStyles: AvatarPickerStylesGetter = ({
    theme,
    dimensions,
    shouldDisplayExtended,
}) => {
    let wrapper: ViewStyle = {
        marginTop: 5,
    };

    if (shouldDisplayExtended) {
        wrapper = {
            ...wrapper,
            width: '100%',
        };
    }

    return {
        wrapper,
        opacityGradientLeft: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            pointerEvents: 'none',
            background: `linear-gradient(90deg, ${theme.color.background1} 1%, ${theme.color.background1}E6 2%, ${theme.color.background1}CC 5%, ${theme.color.background1}80 8%, rgba(0,0,0,0) 18%, rgba(0,0,0,0) 100%)`,
        },
        opacityGradientRight: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: -24,
            zIndex: 100,
            pointerEvents: 'none',
            background: `linear-gradient(270deg, ${theme.color.background1} 1%, ${theme.color.background1}E6 2%,${theme.color.background1}CC 5%, ${theme.color.background1}80 8%, rgba(0,0,0,0) 18%, rgba(0,0,0,0) 100%)`,
        },
        opacityGradient: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            pointerEvents: 'none',
            background: `linear-gradient(90deg, ${theme.color.background1} 0%, rgba(0,0,0,0) 15%, rgba(0,0,0,0) 23%, ${theme.color.background1} 51%)`,
        },
        itemsWrapper: {
            marginLeft: 0,
        },
        itemWrapper: {
            marginRight: dimensions.width > DESKTOP_VERSION_BREAKPOINT ? 20 : 13,
        },
        navigationContainer: {
            flex: 1,
            position: 'absolute',
            flexDirection: 'row',
            width: '100%',
            top: '40%',
            justifyContent: 'space-between',
            zIndex: 101,
        },
        arrowWrapper: {
            zIndex: 101,
            position: 'absolute',
            width: 48,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        navigationLeftContainer: {
            left: 0,
        },
        navigationRightContainer: {
            right: 0,
        },
        swiperSlide: { width: 'auto', paddingRight: shouldDisplayExtended ? 8 : 10 },
        swiper: { width: shouldDisplayExtended ? '100%' : undefined },
    };
};
