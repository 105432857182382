import { IconButtonStyles, IconButtonWebStyles } from '../types';

const baseDefaultStyles = {
    text: {
        color: '',
    },
};

export const defaultStyles: () => IconButtonStyles = () => ({
    textFocused: {
        color: '',
    },
    iconSelectedContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ...baseDefaultStyles,
});

export const defaultWebStyles: () => IconButtonWebStyles = () => ({
    ...baseDefaultStyles,
});
