import React, { useState, useEffect } from 'react';
import { ThemeIconName } from '@24i/nxg-sdk-photon';
import { View, Text, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import {
    useCastDevice,
    useRemoteMediaClient,
    useMediaStatus,
    CastContext,
    MediaPlayerState,
} from '@24i/nxg-sdk-smartott/src/context/Cast';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import CastMediaDetail from '../components/CastMediaDetail';
import CastVolumeController from '../components/CastVolumeController';

import getChromecastStopCastingStyles from '../styles';
import { STOP_CASTING_TEST_IDS } from '../types';

const VOLUME_THRESHOLD_VALUE = 35;

const ChromecastStopCasting = ({ styles: getStyles = getChromecastStopCastingStyles }) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const device = useCastDevice();
    const client = useRemoteMediaClient();
    const status = useMediaStatus();
    const { closeModal, setModalProps } = useModal();

    const isPlaying = status?.playerState === MediaPlayerState.PLAYING;
    const metaData = status?.mediaInfo?.metadata || null;

    const [volume, setVolume] = useState((status?.volume || 0) * 100);
    const [volumeIcon, setVolumeIcon] = useState<ThemeIconName>('onDark-control_volume_mute');

    // @ts-ignore: Property 'getSessionManager' does not exist in dummy impl, but it exist in index.mobile
    const sessionManager = CastContext?.getSessionManager();
    const shouldShowImage = metaData?.images?.[0] && status;

    const getVolumeIcon = () => {
        if (volume > VOLUME_THRESHOLD_VALUE) return 'onDark-control_volume_up';
        if (volume <= 0) {
            return 'onDark-control_volume_mute';
        }
        return 'onDark-control_volume_down';
    };

    useEffect(() => {
        if (status) {
            setVolume(status?.volume * 100);
        }
    }, [status?.mediaInfo?.contentId]);

    useEffect(() => {
        setVolumeIcon(getVolumeIcon());
    }, [volume]);

    const onClosePressed = () => {
        sessionManager?.endCurrentSession();
        closeModal();
    };

    const onPlay = () => {
        if (isPlaying) client?.pause();
        else client?.play();
    };

    const onVolumeChange = (value) => {
        setVolume(value);
        client?.setStreamVolume(value / 100);
    };

    useEffect(() => {
        if (device) {
            setModalProps({
                modalTitle: device.friendlyName,
            });
        }
    }, [device]);

    return (
        <View style={styles.mainContainer}>
            <View style={styles.imageContainer}>
                {shouldShowImage ? (
                    <Image
                        style={[styles?.image, styles?.additionalImageStyles]}
                        source={{
                            uri: metaData?.images?.[0].url,
                        }}
                    />
                ) : (
                    <Text style={styles?.imageTextStyles}>{t('chromecast.noData.title')}</Text>
                )}
            </View>
            {status && (
                <CastMediaDetail
                    customData={status?.mediaInfo?.customData}
                    isPlaying={isPlaying}
                    onPlay={onPlay}
                    metaData={metaData}
                />
            )}
            <CastVolumeController
                onVolumeChange={onVolumeChange}
                volumeIcon={volumeIcon}
                volume={volume}
            />
            <SubactionButton
                title={t('chromecast:disconnect')}
                additionalContainerStyles={styles?.subactionContainer}
                additionalTextStyles={styles?.subactionText}
                onPress={onClosePressed}
                color={theme.color.textPrimary}
                testID={STOP_CASTING_TEST_IDS.DISCONNECT}
            />
        </View>
    );
};

export { getChromecastStopCastingStyles };
export default overridable(ChromecastStopCasting, 'ChromecastStopCasting');
