import React from 'react';

import * as prerenderFunctions from '../../pages/my-purchases';

import View from './View';
import useViewModel from './viewModel';

export default {
    // eslint-disable-next-line react/display-name
    Main: (props) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    page: {
        'my-purchases': prerenderFunctions,
    },
};
