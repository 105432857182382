import React, { useEffect } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import PinInput from '@24i/nxg-sdk-smartott/src/screens/SignupScreen/components/SignupInput';
import getSignupScreenStyles from '@24i/nxg-sdk-smartott/src/screens/SignupScreen/styles';
import getCreatePinScreenStyles from '@24i/nxg-sdk-smartott/src/screens/CreatePinScreen/styles';
import { checkCorrectPin } from '@24i/nxg-core-utils';
import { allExceptNumbersRegex } from '@24i/nxg-core-utils/src/constants';
import getChangeModalPinInputStyles from './styles';
import { ChangeModalItemProps } from '../../types';

const ChangeModalPinInput = (props: ChangeModalItemProps): JSX.Element => {
    const { pinLength = 4, textInputValue, setTextInputValue } = props;
    const { theme } = useTheme();

    const isEmpty = textInputValue?.length;

    useEffect(() => {
        setTextInputValue('');
    }, []);

    const onPinChange = (input: string) => {
        setTextInputValue(input.replace(allExceptNumbersRegex, ''));
    };

    const pinInputStyles = {
        ...getSignupScreenStyles(theme),
        ...getCreatePinScreenStyles(theme),
        ...getChangeModalPinInputStyles(theme),
    };

    return (
        <>
            <PinInput
                additionalContainerStyles={[pinInputStyles.passwordInputContainer]}
                activeTextInputColor={theme.color.lighter1}
                inactiveTextInputColor={theme.color.lighter3}
                additionalTextInputStyles={pinInputStyles.signupInputText}
                secureTextStyles={!isEmpty && pinInputStyles.secureText}
                placeholder=""
                placeholderTextColor={`${theme.color.textPrimary}4D`}
                selectionColor={`${theme.color.textPrimary}66`}
                isPasswordField
                addAdditionalAction
                additionalActionContainerStyles={pinInputStyles.actionContainer}
                additionalActionTextStyles={pinInputStyles.actionText}
                value={textInputValue}
                onChangeText={onPinChange}
                onKeyPress={checkCorrectPin}
                maxPasswordLength={pinLength as number}
                blurOnSubmit={false}
                keyboardType="numeric"
                autoFocus
            />
        </>
    );
};

export default ChangeModalPinInput;
