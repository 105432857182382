import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDevicesManagement } from '@24i/nxg-sdk-smartott-shared/src/context/DevicesManagement';
import { log } from '@24i/nxg-core-utils/src/logger';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { Device } from '@24i/nxg-sdk-photon';
import moment from 'moment';
import useRoute from '@24i/nxg-core-router/src/hooks/useRoute';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import {
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_ROOT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { Storage } from '@24i/nxg-sdk-quantum';
import { ASYNC_STORAGE_KEY_MOCK_DEVICES } from '@24i/nxg-core-utils/src/constants';
import { getCurrentDevice } from '@24i/nxg-core-utils';
import { isPlatformWeb } from 'renative';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { SEPARATORS } from '@24i/nxg-sdk-smartott/src/components/TitleView/components/TextWithSeparator';
import { MANAGE_DEVICES_MODES } from '../constants';
import { useFirebase } from '../../../context/Firebase';
import { useStartPageNavigation } from '../../../context/AppStartContext/hooks';
import { ManageDevicesScreenProps } from '../types';

const useViewModel = (props: ScreenProps): ManageDevicesScreenProps => {
    const { navigation } = props;
    const { recordError } = useFirebase();
    const devicesManagementDataClient = useDevicesManagement();
    const { t } = useTranslation();
    const { setUserData, setSelectedUserProfile } = useStore();
    const userDataClient = useUserData();
    const route = useRoute();
    const router = useRouter();
    const [devices, setDevices] = useState<Device[]>([]);
    const [deviceLimit, setDeviceLimit] = useState<number>();
    const [deviceToRemove, setDeviceToRemove] = useState<Device>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [currentDevice, setCurrentDevice] = useState<Device>();
    const { navigateToStartPage } = useStartPageNavigation();
    // @ts-ignore
    const mode = route.params?.mode;
    const removeDeviceLink = SOTT_DEFAULT_WEB_SCREENS.RemoveDevice.getLink();
    const onboarding =
        mode === MANAGE_DEVICES_MODES.ONBOARDING || router?.pathname === removeDeviceLink.href;
    const topBarTitle = onboarding ? t('error.E01.title') : t('manageDevices.title');

    const getDevices = async () => {
        try {
            const [fetchedCurrentDevice, fetchedDevices, fetchedDeviceLimit] = await Promise.all([
                getCurrentDevice(),
                devicesManagementDataClient.fetchDevices(),
                devicesManagementDataClient.getDeviceLimit(),
            ]);

            setCurrentDevice(fetchedCurrentDevice);
            setDevices(fetchedDevices);
            setDeviceLimit(fetchedDeviceLimit);

            setIsLoading(false);
        } catch (err) {
            alert(err);
            recordError(err);
            log(err);
        }
    };

    useEffect(() => {
        getDevices();
    }, []);

    const formatDate = (dateStr: string) => {
        const dateTime = moment(dateStr).format('DD/MM/YYYY HH:mm');
        const date = dateTime.toString().substring(0, 10);
        const time = dateTime.toString().substring(11, 16);

        return `${t('manageDevices.addedDate')} ${date} ${SEPARATORS.DOT} ${time}`;
    };

    const onRemoveDevicePress = (device: Device) => {
        setDeviceToRemove(device);
        setModalVisible(!isModalVisible);
    };

    const onModalClose = () => {
        setModalVisible(!isModalVisible);
    };

    const isDevicesLimitExceeded = async () => {
        const ok = await devicesManagementDataClient.checkDeviceLimit();

        return !ok;
    };

    const onModalRemoveDevicePress = async (device: Device) => {
        setIsLoading(true);
        try {
            await devicesManagementDataClient.deleteDevice(device);
            showToast(`${device.name} ${t('manageDevices.removed')}`);
        } catch (err) {
            alert(err);
            recordError(err);
            log(err);
        }

        const isTooManyDevices = await isDevicesLimitExceeded();
        if (!onboarding || isTooManyDevices) {
            getDevices();
        } else {
            navigateToStartPage();
        }
    };

    const onCloseScreen = async () => {
        await Storage.removeItem(ASYNC_STORAGE_KEY_MOCK_DEVICES);
        await userDataClient.logout();
        setUserData(null);
        setSelectedUserProfile(null);
        navigation.navigate(SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN, {
            screen: SOTT_DEFAULT_SCREENS.LANDING,
        });
    };

    return {
        currentDevice,
        deviceLimit,
        devices,
        deviceToRemove,
        formatDate,
        isLoading,
        isModalVisible,
        onboarding,
        onCloseScreen,
        onModalClose,
        onModalRemoveDevicePress,
        onRemoveDevicePress,
        topBarTitle,
        webHeaderTitle: topBarTitle,
        forceShowHeader: isPlatformWeb && !onboarding,
        forceDisable: isPlatformWeb && onboarding,
        ...props,
    };
};

export { useViewModel };
