import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { isPlatformAndroid } from 'renative';

const SettingsDeviceManagement = (theme) => ({
    container: {
        flex: 1,
        backgroundColor: theme.color.background1,
    },
    scrollContainer: {
        paddingHorizontal: 20,
    },
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: isPlatformAndroid ? 16 : 8,
        paddingTop: isPlatformAndroid ? 16 : 8,
        paddingBottom: 8,
        marginBottom: 32,
    },
    headerContainerLandscape: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: isPlatformAndroid ? 32 : 16,
        marginBottom: 32,
        marginHorizontal: 32,
        paddingTop: isPlatformAndroid ? 16 : 8,
        paddingBottom: 8,
    },
    headerText: {
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        marginLeft: 8,
        ...getFont(theme, 'h1'),
    },
    headerTextLandscape: {
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h1'),
    },
    headerIcon: {
        fontSize: 27,
        marginRight: 8,
        marginLeft: 0,
    },
    DevicesContainer: {
        flexDirection: 'row',
        marginBottom: 36,
    },
    DevicesContainerLandscape: {
        flexDirection: 'row',
        marginHorizontal: 32,
        marginBottom: 36,
    },
    deviceIcon: {
        alignSelf: 'center',
        marginRight: 20,
    },
    title: {
        lineHeight: 24,
        ...getFont(theme, 'h3'),
        color: '#fff',
    },
    text: {
        ...getFont(theme, 'h6'),
        color: 'rgba(240,240,240,0.6)',
    },
    controlContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        right: 0,
    },
    switch: {
        alignSelf: 'center',
    },
    closeIcon: {
        marginLeft: 20,
    },
    removeTitleText: {
        width: 300,
        textAlign: 'center',
        ...getFont(theme, 'h1'),
        color: '#ffffff',
    },
    removeSubtitleText: {
        width: 300,
        textAlign: 'center',
        ...getFont(theme, 'h4'),
        color: '#ffffff',
        opacity: 0.5,
        marginTop: 12,
        marginBottom: 42,
    },
    additionalActionButtonContainerStyles: {
        width: 150,
        height: 52,
        backgroundColor: '#ffffff',
        borderRadius: 4,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
    },
    additionalActionButtonTextStyles: {
        color: '#000000',
    },
    secondaryButtonContainerStyles: {
        backgroundColor: 'transparent',
        marginTop: 12,
    },
    secondaryButtonTextStyles: {
        color: '#ffffff',
    },
});

export default SettingsDeviceManagement;
