import { useState } from 'react';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { emailValidatorReg, nameValidatorReg } from '@24i/nxg-sdk-smartott/src/utils/regexConsts';
import { useUserQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/user/useUserQuery';
import { useProfilesQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/useProfilesQuery';
import {
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { UpdateUserRequest } from '@24i/nxg-sdk-photon/src';
import {
    ChangeableTextItemProps,
    ChangeableItemType,
} from '@24i/nxg-sdk-smartott/src/components/ChangeableText/types';
import {
    useUpdateUserEmailMutation,
    useUpdateUserMutation,
} from '@24i/nxg-sdk-smartott/src/hooks/mutations/user/useUserMutation';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { ACCOUNT_DETAIL_KEY, AccountDetailsModelProps, AccountDetailsProps } from '../types';
import { getSuccessToastMessage, getErrorMessage } from './utils';

const useViewModel = (props: AccountDetailsModelProps): AccountDetailsProps => {
    const { t } = useTranslation();
    const { mutate: userMutate } = useUpdateUserMutation();
    const { mutate: userEmailMutate } = useUpdateUserEmailMutation();
    const { selectedProfile } = useProfilesQuery();
    const { user } = useUserQuery();
    const { recordError } = useFirebase();
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const router = useRouter();
    const webHeaderTitle = t('account.accountDetails.title');

    const [errorForModal, setErrorForModal] = useState<string | undefined>(undefined);
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

    const displayToaster = (text: string): void => {
        showToast(text);
    };

    const handleUpdateUserResponse = (key: string): void => {
        setIsModalOpened(false);
        const message = getSuccessToastMessage(key as ACCOUNT_DETAIL_KEY, t);
        displayToaster(message);
    };

    const handleUpdateUserError = (error: any, key: string): void => {
        recordError(error);

        if (typeof error === 'string') {
            setErrorForModal(error);
        } else if (error?.message && typeof error.message === 'string') {
            setErrorForModal(`Error: ${error.message}`);
        } else {
            const message = getErrorMessage(key as ACCOUNT_DETAIL_KEY, t);
            setErrorForModal(message);
        }
    };

    const updateDetail = async (input: string, key: string): Promise<void> => {
        if (nameValidatorReg.test(input) === false) {
            const message = getErrorMessage(key as ACCOUNT_DETAIL_KEY, t);
            setErrorForModal(message);
            setIsModalOpened(true);
            return;
        }

        const body = {
            ...user,
            [key]: input,
        } as UpdateUserRequest;

        userMutate(body, {
            onSuccess: () => handleUpdateUserResponse(key),
            onError: (err) => handleUpdateUserError(err, key),
        });
    };

    const updateEmail = async (input: string): Promise<void> => {
        if (emailValidatorReg.test(input) === false) {
            setErrorForModal(t('error.E10.body') as string);
            setIsModalOpened(true);
            return;
        }

        userEmailMutate(input, {
            onSuccess: () => handleUpdateUserResponse(ACCOUNT_DETAIL_KEY.EMAIL),
            onError: (err) => handleUpdateUserError(err, ACCOUNT_DETAIL_KEY.EMAIL),
        });
    };

    const openModal = (): void => {
        setIsModalOpened(true);
    };

    const closeModal = (hasErrorMessage?: boolean): void => {
        if (hasErrorMessage) {
            setErrorForModal(undefined);
        }
        setIsModalOpened(false);
    };

    async function handlePasswordChange() {
        // TODO: - password changed
    }

    const onDeleteAccountPress = (): void => {
        if (isPlatformWeb) {
            const deleteAccountLink = SOTT_DEFAULT_WEB_SCREENS.DeleteAccount.getLink();
            router.push(
                {
                    pathname: deleteAccountLink.href,
                    query: { containingMenuTitle: webHeaderTitle },
                },
                deleteAccountLink.as
            );
        } else {
            navigation.navigate(SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT);
        }
    };

    const changeableDetails: ChangeableTextItemProps[] = [
        {
            title: t('account.accountDetails.firstName'),
            value: user?.firstName || '',
            confirmationHandler: updateDetail,
            itemKey: ACCOUNT_DETAIL_KEY.FIRST_NAME.toString(),
            buttonText: t('common.change') as string,
            type: ChangeableItemType.INPUT,
        },
        {
            title: t('account.accountDetails.lastName'),
            value: user?.lastName || '',
            confirmationHandler: updateDetail,
            itemKey: ACCOUNT_DETAIL_KEY.LAST_NAME.toString(),
            buttonText: t('common.change') as string,
            type: ChangeableItemType.INPUT,
        },
        {
            title: t('account.accountDetails.email'),
            value: user?.email || '',
            confirmationHandler: updateEmail,
            itemKey: ACCOUNT_DETAIL_KEY.EMAIL.toString(),
            buttonText: t('common.change') as string,
            type: ChangeableItemType.INPUT,
        },
        {
            /* Password change button hidden until version 3.2 + placeholder data */
            title: t('account.accountDetails.password'),
            value: 'password',
            confirmationHandler: handlePasswordChange,
            itemKey: ACCOUNT_DETAIL_KEY.PASSWORD.toString(),
            // buttonText: t('common.change')
            secureText: true,
            type: ChangeableItemType.INPUT,
        },
    ];

    return {
        changeableDetails,
        isModalOpened,
        errorForModal,
        openModal,
        closeModal,
        onDeleteAccountPress,
        webHeaderTitle,
        active: Boolean(selectedProfile?.isPinProtected),
        ...props,
    };
};

export { useViewModel };
