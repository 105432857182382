import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { AddSubscriptionModalStatusLayoutStyles } from './types';

const getStyles = (theme: Theme): AddSubscriptionModalStatusLayoutStyles => ({
    wrapper: {
        paddingVertical: 24,
        paddingHorizontal: 24,
        paddingBottom: 24,
    },
    titleWrapper: {
        marginBottom: 16,
    },
    title: {
        fontSize: 24,
        color: theme.color.textPrimary,
    },
    contentWrapper: {
        marginBottom: 32,
    },
    content: {
        color: theme.color.textPrimary,
        opacity: 0.6,
    },
    buttonsWrapper: {
        flexDirection: 'row-reverse',
    },
    actionButtonText: {
        ...getFont(theme, 'primaryButton'),
    },
    actionButtonContainer: {
        width: 'auto',
        paddingHorizontal: 16,
    },
    cancelButtonWrapper: {
        marginRight: 16,
    },
    cancelButtonText: {
        ...getFont(theme, 'secondaryButton'),
        color: theme.color.textButtonSecondary,
    },
    cancelButtonContainer: {
        width: 'auto',
        paddingHorizontal: 16,
        height: 54,
        backgroundColor: theme.color.buttonSecondary,
    },
});

export default getStyles;
