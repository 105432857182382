import { ScreenProps } from 'packages/sdk@gluons/src/clients/AppNavigationClient';
import { PurchaseSubscriptionScreenProps } from '../../screens/PurchaseSubscriptionScreen/types';
import { WithWebHeaderProps } from '../../navigation/components/withWebHeader/types';

type ScreenElements<T, U> = {
    Main: (props: WithWebHeaderProps & T) => JSX.Element;
    View: (props: WithWebHeaderProps & U) => JSX.Element;
};

export type ModularSubscriptionsElements = {
    PurchaseScreen: ScreenElements<
        PurchaseSubscriptionScreenProps & Partial<ScreenProps>,
        PurchaseSubscriptionScreenProps
    >;
    PaymentMethodScreen: ScreenElements<WithWebHeaderProps, WithWebHeaderProps>;
    PaymentHistoryScreen: ScreenElements<WithWebHeaderProps, WithWebHeaderProps>;
    ConfigProvider?: React.FC;
};

// If the number of supported providers increases, expand this list, and the related map in packages/sdk@smartott/src/context/SubscriptionsHandlerProvider/data.ts
export const supportedSubscriptionsProviders = ['cleeng'] as const;

export type SupportedSubscriptionsProvider = typeof supportedSubscriptionsProviders[number];

export const isSupportedProvider = (provider: any): provider is SupportedSubscriptionsProvider => {
    return supportedSubscriptionsProviders.includes(provider);
};
