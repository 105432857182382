import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { NavigationEvent } from '@24i/nxg-sdk-photon/src/analytics/navigation';
import { useCurrentWebPageConfig } from '@24i/nxg-sdk-smartott/src/hooks/useCurrentWebPageConfig';
import { useCommonEventParams } from '../../hooks/useCommonEventParams';

export const useCommonNavEventParamsWithoutNav = (): Omit<NavigationEvent, 'type'> => {
    const router = useRouter();
    const { currentPageConfig } = useCurrentWebPageConfig();
    const event = useCommonEventParams();
    return { ...event, pageId: currentPageConfig?.name || '', params: router?.query };
};

export const useCommonNavEventParams = useCommonNavEventParamsWithoutNav;
