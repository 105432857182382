import React, { memo, useMemo } from 'react';
import { Ratio, useTheme } from '@24i/nxg-sdk-higgs';
import { TouchableOpacity } from 'react-native';
import { ThemeIcon, View } from '@24i/nxg-sdk-quarks';
import { ThemeIconName } from '@24i/nxg-sdk-photon';
import { isPlatformWeb, isFactorTv } from 'renative';
import { PackshotActionButtonProps } from './types';
import getPackshotActionBtnStyles from './styles';

const PackshotActionButton = (props: PackshotActionButtonProps) => {
    const {
        isHighlighted,
        testID,
        actionIcon,
        onPress,
        styles: getStyles = getPackshotActionBtnStyles,
        overrideDisplayIcon = true,
    } = props;
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const iconSize = useMemo(() => {
        if (isPlatformWeb) {
            if (isHighlighted) return 48;
            return 30;
        }

        if (isFactorTv) {
            if (isHighlighted) return Ratio(80);
            return Ratio(56);
        }

        if (isHighlighted) return 44;
        return 24;
    }, [isHighlighted]);

    const iconNames: Record<typeof actionIcon, ThemeIconName> = {
        play: 'onTileMainButton-play',
        delete: 'onTileMainButton-delete',
        cancel: 'onTileMainButton-cancel',
        pause: 'onTileMainButton-pause',
    };

    if (!actionIcon) return null;

    if (!overrideDisplayIcon) {
        return (
            <TouchableOpacity
                testID={testID}
                onPress={(event) =>
                    isPlatformWeb ? onPress?.(event) : onPress?.({ data: {}, event })
                }
            />
        );
    }

    return (
        <View style={styles.itemActionButtonContainer}>
            <TouchableOpacity
                testID={testID}
                style={[
                    styles.itemActionButton,
                    isHighlighted && styles.itemActionButtonHighlighted,
                ]}
                onPress={(event) =>
                    isPlatformWeb ? onPress?.(event) : onPress?.({ data: {}, event })
                }
            >
                <ThemeIcon iconName={iconNames[actionIcon]} size={iconSize} />
            </TouchableOpacity>
        </View>
    );
};

export default memo(PackshotActionButton);
