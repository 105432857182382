import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Breakpoint } from '@24i/nxg-sdk-gluons/src/utils/styles/constants';
import { SubscriptionRequiredModalStyles } from './types';

export const getStyles = (theme): SubscriptionRequiredModalStyles => ({
    subheaderText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h3'),
    },
    modalMessageText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body2'),
        opacity: 0.6,
    },
    modalContainer: {
        height: 244,
        width: 528,
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        backgroundColor: theme.color.primary1,
        position: 'absolute',
        left: '50%',
        top: '30%',
        marginLeft: -264,
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: '80%',
            marginLeft: '-40%',
            height: 315,
        },
    },
    contentContainer: {
        margin: 24,
        display: 'flex',
        flex: 1,
        justifyContent: 'space-around',
    },
    buttonsContainer: {
        justifyContent: 'flex-end',
        flexDirection: 'row',
        width: '100%',
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: 120,
        },
    },
});
