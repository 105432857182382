export enum SPLASH_SCREEN_STATES {
    SPLASH = 0,
    LOADING = 1,
    FINISHED = 2,
    ERROR = 3,
}

export enum SPLASH_PROMISE_STATE {
    INITIAL = 0,
    RESOLVED = 1,
    REJECTED = 2,
}

export interface StaticSplashConfig {
    logo: number | null;
    background: number | null;
}

export interface SplashScreenProps {
    state: SPLASH_SCREEN_STATES;
    splashAnimationDuration: number;
    staticSplashConfig?: StaticSplashConfig;
}
export interface SplashScreenModelProps<T> extends Partial<SplashScreenProps> {
    minTime: number;
    maxTime: number;
    promise?: Promise<T>;
}
