import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Breakpoint } from '../../../../../utils/styles/constants';
import { DeleteAccountModalStyles } from '../types';

const getStyles = (theme: Theme): DeleteAccountModalStyles => ({
    main: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.color?.darkerBlur1?.color,
    },

    contentContainer: {
        padding: 32,
        width: '100%',
        [`@media (min-width: ${Breakpoint.MD}px)`]: {
            padding: 24,
            width: 'auto',
            maxWidth: 528,
            backgroundColor: theme.color.primary1,
            alignSelf: 'center',
            alignItems: 'flex-start',
            borderRadius: 8,
            ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        },
    },
    title: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        marginBottom: 16,
        ...getFont(theme, 'h1'),
        [`@media (min-width: ${Breakpoint.MD}px)`]: {
            ...getFont(theme, 'h3'),
            textAlign: 'left',
        },
    },
    description: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        textAlign: 'center',
        marginBottom: 16,
        ...getFont(theme, 'body1'),
        [`@media (min-width: ${Breakpoint.MD}px)`]: {
            ...getFont(theme, 'body2'),
            textAlign: 'left',
        },
    },
    buttonContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        minHeight: 72,
        [`@media (min-width: ${Breakpoint.MD}px)`]: {
            alignSelf: 'flex-end',
            flexDirection: 'row-reverse',
            alignItems: 'flex-end',
        },
    },
    loadingContainer: {
        [`@media (min-width: ${Breakpoint.MD}px)`]: {
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            alignSelf: 'center',
        },
    },
    confirmButton: {
        backgroundColor: theme.color.buttonPrimary,
        height: 52,
        marginVertical: 16,
        width: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 26,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
    },
    confirmButtonText: { color: theme.color.textButtonPrimary, ...getFont(theme, 'primaryButton') },
    cancelButton: {
        marginVertical: 16,
        paddingHorizontal: 16,
        [`@media (min-width: ${Breakpoint.MD}px)`]: {
            ...getBorderRadius(theme.elements, 'buttonSecondary-cornerRadius'),
            backgroundColor: theme.color.buttonSecondary,
            height: 52,
            margin: 16,
            width: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 26,
        },
    },
    cancelButtonText: {
        color: theme.color.textButtonTertiary,
        textAlign: 'center',
        ...getFont(theme, 'tertiaryButton'),
        [`@media (min-width: ${Breakpoint.MD}px)`]: { ...getFont(theme, 'secondaryButton') },
    },
});

export default getStyles;
