import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import getSearchNothingFoundStyles from '../styles';
import { SearchNothingFoundProps, SEARCH_NOTHING_FOUND_TEST_IDS } from '../types';

const SearchNothingFound = (props: SearchNothingFoundProps) => {
    const { styles: getStyles = getSearchNothingFoundStyles, query } = props;
    const { t } = useTranslation('sott');
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.nothingFoundContainer}>
            <Text
                testID={SEARCH_NOTHING_FOUND_TEST_IDS.SEARCH_NOTHING_FOUND_TEXT_PRIMARY}
                style={styles.primaryText}
            >
                {t('search.noData.title')}
            </Text>
            <Text
                testID={SEARCH_NOTHING_FOUND_TEST_IDS.SEARCH_NOTHING_FOUND_TEXT_SECONDARY}
                style={styles.secondaryText}
            >
                {t('search.noData.description', { term: query })}
            </Text>
        </View>
    );
};

export { getSearchNothingFoundStyles };
export default overridable(SearchNothingFound, 'SearchNothingFound');
