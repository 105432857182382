import { PinProtectionScopes, Profile } from '@24i/nxg-sdk-photon';
import React, { useState, useRef, useEffect } from 'react';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { Platform } from 'react-native';
import PinInputModal from '../../components/PinInputModal';

const { useStore } = Platform.isTV
    ? require('@24i/nxg-sdk-smartott-tv/src/context/ApplicationStore')
    : require('@24i/nxg-sdk-smartott/src/context/ApplicationStore');

export type IsPinCodeValidProp = {
    isPinCodeValid: boolean;
};

type PinControlProps = React.ComponentProps<typeof PinInputModal>;

export interface WithPinControlProps {
    openPinControl?: (
        tappedProfileWithPin: Profile | null,
        overrideProps?: Partial<PinControlProps>
    ) => Promise<number | undefined>;
    closePinControl?: () => void;
    handlePinSubmit?: (pin: number) => boolean;

    profileWithPin?: Profile | null;
    isPinControlVisible?: boolean;
    pinControlProps?: Partial<PinControlProps>;

    updateLastValidationTime?: () => void;
    arePinScopesValidated?: (scopes: PinProtectionScopes[]) => boolean;
}

export const usePinControl = (): WithPinControlProps => {
    const pinProtection = useFeature('pinProtection');
    const { selectedProfile } = useStore();
    const [isPinControlVisible, setIsPinControlVisible] = useState(false);
    const [profileWithPin, setProfileWithPin] = useState<Profile | null>(null);
    const [pinControlProps, setPinControlProps] = useState<Partial<PinControlProps>>();

    const resolveModalOpenedPromiseRef = useRef<{
        resolve: (value: number | PromiseLike<number | undefined>) => void;
        reject: () => void;
    }>();

    const [lastSuccesfullValidation, setLastSuccesfullValidation] = React.useState<
        Date | undefined
    >();

    const findShortestScopeMinutes = (scopes: PinProtectionScopes[]): number => {
        let shortestScopeMinutes: number | undefined;

        for (let i = 0; i < scopes.length; i++) {
            const scope = scopes[i];
            const scopeMinutes = pinProtection?.scope?.[scope];
            if (scopeMinutes) {
                if (shortestScopeMinutes === undefined || scopeMinutes < shortestScopeMinutes) {
                    shortestScopeMinutes = scopeMinutes;
                }
            }
        }
        return shortestScopeMinutes ?? 0;
    };

    const updateLastValidationTime = () => {
        const now = new Date();
        setLastSuccesfullValidation(now);
    };

    const arePinScopesValidated = (scopes: PinProtectionScopes[]): boolean => {
        if (!lastSuccesfullValidation) {
            return false;
        }

        if (scopes.length === 0) return false;
        const scopeValidFor = findShortestScopeMinutes(scopes);

        const now = new Date();
        const scopeValidUntil = new Date(
            lastSuccesfullValidation.getTime() + scopeValidFor * 60000
        );

        const isValid = scopeValidUntil > now;
        return isValid;
    };

    const openPinControl = async (
        tappedProfileWithPin: Profile | null,
        overrideProps?: Partial<PinControlProps>
    ) => {
        const isEnabled = pinProtection?.enabled;

        const promise = new Promise<number | undefined>((resolve, reject) => {
            if (isEnabled) {
                if (overrideProps) {
                    setPinControlProps(overrideProps);
                }

                setProfileWithPin(tappedProfileWithPin);
                setIsPinControlVisible(true);
            } else {
                resolve(undefined);
            }
            resolveModalOpenedPromiseRef.current = { resolve, reject };
        });
        return promise;
    };

    const handlePinSubmit = (pin: number) => {
        setProfileWithPin(null);
        if (!pinControlProps?.onModalClose) setIsPinControlVisible(false);
        resolveModalOpenedPromiseRef.current?.resolve(pin);

        updateLastValidationTime();
        return !!pinControlProps?.onModalClose;
    };

    const closePinControl = () => {
        setIsPinControlVisible(false);
        resolveModalOpenedPromiseRef.current?.reject();
    };

    useEffect(() => {
        setLastSuccesfullValidation(undefined);
    }, [selectedProfile]);

    return {
        openPinControl,
        closePinControl,
        handlePinSubmit,

        profileWithPin,
        isPinControlVisible,
        pinControlProps,

        arePinScopesValidated,
        updateLastValidationTime,
    };
};
