import { getFont, getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { GenreScreenStylesTypes } from './types';

const GenreScreenStyles = (theme: Theme): GenreScreenStylesTypes => ({
    holder: {
        backgroundColor: theme.color.background1,
        flex: 1,
        flexDirection: 'column',
    },
    sectionsContainer: { flex: 1, flexDirection: 'column', overflow: 'hidden' },
    pickerHolder: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.color.background1,
        ...getMargins(),
        marginTop: 96,
    },
    animatedPickerHolderBackground: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: theme.color.primaryBlur1Fallback,
        borderBottomWidth: 1,
        borderBottomColor: theme.color.contrast4,
    },
    pickerItem: {
        textAlign: 'center',
        color: theme.color.textPrimary,
        fontSize: 20,
        lineHeight: 50,
        height: 50,
        fontWeight: '700',
    },
    sectionTitle: {
        textAlign: 'center',
        ...getFont(theme, 'h2'),
        color: theme?.color?.textPrimary,
    },
    fetchingActivityIndicator: {
        paddingTop: 20,
        width: '89%',
    },
    activityIndicator: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridContainer: {
        flex: 1,
        ...getMargins(),
    },
});

export default GenreScreenStyles;
