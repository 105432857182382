import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { PurchaseModalStatusLayoutStyles } from './types';
import { Breakpoint } from '../../../../../../utils/styles/constants';

const getStyles = (theme: Theme, isModalContent?: boolean): PurchaseModalStatusLayoutStyles => ({
    wrapper: {
        paddingVertical: 24,
        paddingHorizontal: 24,
        paddingBottom: 24,
        ...(!isModalContent && {
            width: Breakpoint.SM,
            alignSelf: 'center',
            marginTop: 108,
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                width: '100%',
                marginHorizontal: 0,
            },
        }),
    },
    titleWrapper: {
        marginBottom: 40,
    },
    title: {
        ...getFont(theme, 'h2'),
        color: theme.color.textPrimary,
    },
    contentWrapper: {
        marginBottom: 32,
    },
    content: {
        ...getFont(theme, 'body2'),
        color: theme.color.textPrimary,
        opacity: 0.6,
        maxWidth: 480,
    },
    buttonsWrapper: {
        flexDirection: isModalContent ? 'row-reverse' : 'row',
        justifyContent: 'flex-start',
    },
    actionButtonText: {
        ...getFont(theme, 'primaryButton'),
    },
    actionButtonContainer: {
        width: 'auto',
        paddingHorizontal: 16,
    },
    cancelButtonWrapper: {
        marginRight: 16,
    },
    cancelButtonText: {
        ...getFont(theme, 'primaryButton'),
    },
    cancelButtonContainer: {
        width: 'auto',
        paddingHorizontal: 16,
        height: 54,
    },
});

export default getStyles;
