/* eslint-disable max-statements */
import { v4 as uuidv4 } from 'uuid';
import { Storage, DeviceInfo } from '@24i/nxg-sdk-quantum';
import { createDevice, Device } from '@24i/nxg-sdk-photon';
import {
    isPlatformAndroid,
    isPlatformAndroidtv,
    isPlatformIos,
    isPlatformTvos,
    isPlatformWeb,
} from 'renative';
import { WEB_UUID } from './constants';
import { getBrowserName } from './browser';

export const getPlatformName = () => {
    let platform = 'undefined';

    if (isPlatformWeb) platform = 'laptop';
    if (isPlatformAndroidtv) platform = 'android';
    if (isPlatformTvos) platform = 'iphone';
    if (isPlatformIos) platform = DeviceInfo.isTablet() ? 'ipad' : 'iphone';
    if (isPlatformAndroid) platform = DeviceInfo.isTablet() ? 'tabletAndroid' : 'android';

    return platform;
};

export const getDeviceId = async (key: string = WEB_UUID): Promise<string> => {
    if (!isPlatformWeb) return DeviceInfo.getUniqueId().toString();
    return (await Storage.getItem(key)) ?? Storage.setItem(key, uuidv4());
};

export const getCurrentDevice = async (): Promise<Device> => {
    const device = {
        deviceId: await getDeviceId(),
        name: isPlatformWeb ? getBrowserName().name : await DeviceInfo.getDeviceName(),
        registrationDate: new Date().toISOString(),
        platform: getPlatformName(),
    };

    return createDevice(device);
};
