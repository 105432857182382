import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs/src/StyleSheet';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { ToggleItemStyles } from './types';

const getStyles = (theme: Theme): ToggleItemStyles => ({
    mainContainer: {
        width: '100%',
        flexDirection: 'column',
    },
    settingsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    textContainer: {
        width: '75%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    toggleContainer: {
        width: '25%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    descriptionContainer: {
        width: '100%',
    },
    title: { ...getFont(theme, 'h4'), textAlign: 'left', color: theme.color.textPrimary },
    description: {
        ...getFont(theme, 'body2'),
        textAlign: 'left',
        color: theme.color.textPrimary,
        opacity: 0.6,
        marginTop: 6,
    },
    toggleText: { ...getFont(theme, 'h4'), marginRight: 12, color: theme.color.textPrimary },
    divider: {
        marginTop: 16,
        marginBottom: DeviceInfo.isTablet() ? 45 : 30,
        height: 1,
        minWidth: '100%',
        backgroundColor: theme.color.contrast4,
    },
});

export default getStyles;
