import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@24i/nxg-sdk-quarks';
import { DATE, TIME, noop } from './constants';

const getValidDate = (date) => (Number.isNaN(date.getTime()) ? new Date() : date);

const valueToInputValue = (value, mode) => {
    const safeValue = getValidDate(value);
    const [date, time] = safeValue.toISOString().split('T');
    if (mode === DATE) return date;
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
};

const stringDateToDate = (inputValue, value) => {
    const safeValue = getValidDate(value);
    const time = safeValue.toISOString().split('T')[1];
    return new Date(`${inputValue}T${time}`);
};

const stringTimeToDate = (inputValue, value) => {
    const safeValue = getValidDate(value);
    const [date] = safeValue.toISOString().split('T');
    return new Date(`${date}T${inputValue}:00.000Z`);
};

const innerValueToDate = (inputValue, mode, value) =>
    mode === DATE ? stringDateToDate(inputValue, value) : stringTimeToDate(inputValue, value);

const DateTimePicker = ({ mode, onChange, value, minimumDate, maximumDate, ...props }) => {
    const input = useRef();

    const innerOnChange = (event) => {
        const {
            nativeEvent: { text },
        } = event;
        const newValue = innerValueToDate(text, mode, value);
        if (maximumDate && newValue > maximumDate) onChange(event, maximumDate);
        else if (minimumDate && newValue < minimumDate) onChange(event, minimumDate);
        else onChange(event, newValue);
    };

    useEffect(() => {
        /* eslint-disable no-underscore-dangle */
        if (input.current && input.current._node) {
            input.current._node.type = mode;
        }
        /* eslint-enable no-underscore-dangle */
    }, [mode]);

    return (
        <TextInput
            ref={input}
            onChange={innerOnChange}
            value={valueToInputValue(value, mode)}
            {...props}
        />
    );
};
DateTimePicker.propTypes = {
    mode: PropTypes.oneOf([DATE, TIME]),
    onChange: PropTypes.func,
    value: PropTypes.instanceOf(Date).isRequired,
    minimumDate: PropTypes.instanceOf(Date),
    maximumDate: PropTypes.instanceOf(Date),
};
DateTimePicker.defaultProps = {
    mode: DATE,
    onChange: noop,
    minimumDate: null,
    maximumDate: null,
};
DateTimePicker.DATE = DATE;
DateTimePicker.TIME = TIME;

export default DateTimePicker;
