import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text, Interactable } from '@24i/nxg-sdk-quarks';
import CornerLogo from '@24i/nxg-sdk-gluons/src/components/ui/CornerLogo';
import { log } from '@24i/nxg-core-utils/src/logger';
import { DeleteAccountSuccessProps, DELETE_ACCOUNT_SUCCESS_TEST_ID } from '../types';
import defaultStyles from '../styles';
import useUserSession from '../../../hooks/useUserSession';

const DeleteAccountSuccessScreen = ({
    styles: getStyles = defaultStyles,
}: DeleteAccountSuccessProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation();
    const { signOutUser } = useUserSession();

    const handleOnPressContinue = async () => {
        try {
            await signOutUser();
        } catch (error) {
            log(error);
        }
    };

    return (
        <View style={styles.main}>
            <View style={styles.headerContainer}>
                <CornerLogo />
            </View>
            <View style={styles.actionContainer}>
                <Text style={styles.title}>{t('account.delete.deleteSuccess.title')}</Text>
                <Text style={styles.description}>
                    {t('account.delete.deleteSuccess.description')}
                </Text>
                <Interactable
                    onPress={handleOnPressContinue}
                    style={styles.button}
                    testID={DELETE_ACCOUNT_SUCCESS_TEST_ID.CONTINUE_BUTTON}
                >
                    <Text style={styles.buttonText} numberOfLines={1}>
                        {t('common.continue')}
                    </Text>
                </Interactable>
            </View>
        </View>
    );
};

export default DeleteAccountSuccessScreen;
