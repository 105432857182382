import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { SubMenuStackScreens } from '@24i/nxg-sdk-smartott/src/screens/SubMenuScreen/stack';
import { TV_SCREENS } from '@24i/nxg-sdk-smartott-tv/src/navigation/constants';
import { BACKSTAGE_MENU_ITEM_TYPES } from '@24i/nxg-integration-backstage/src/clients/AppStructureDataClient';
import {
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_ROOT_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { CorePayload, Payload, SceneViewResult } from '../types';
import { mapBase } from './mapBase';

const getSourcePage = (payload: CorePayload): string => {
    if (payload.params?.screen === SOTT_DEFAULT_SCREENS.LANDING)
        return SOTT_DEFAULT_SCREENS.LANDING;
    if (payload.pageId === TV_SCREENS.MODAL) return '';
    if (payload.pageId === TV_SCREENS.DYNAMIC_CONTENT) return TV_SCREENS.HOME;
    if (payload.pageId === SubMenuStackScreens.ScreensList) return '';
    if (payload.pageId === SOTT_DEFAULT_SCREENS.INDEX) return '';
    if (
        payload.pageId === BACKSTAGE_MENU_ITEM_TYPES.HOME &&
        Object.keys(payload.params).length === 0
    ) {
        return '';
    }
    if (payload.pageId === TV_SCREENS.SUB_MENU) return payload.params.screenName;
    return payload.pageId || payload.screen;
};

const getAreMinimumRequirementsFilled = (mappedResult: SceneViewResult): boolean => {
    if (
        mappedResult.device_id &&
        mappedResult.source_page &&
        mappedResult.source_page !== SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN &&
        mappedResult.session_id
    ) {
        return true;
    }

    return false;
};

export const mapSceneView = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): SceneViewResult | undefined => {
    const result = {
        ...mapBase(payload),
        action: triggerName,
        event_trigger: triggerName,
        source_page: getSourcePage(payload.payload),
        thing_id:
            payload.payload.params?.id ||
            payload.payload.params?.asset?.id ||
            payload.payload.asset?.id ||
            '',
    };
    if (getAreMinimumRequirementsFilled(result)) return result;
    return undefined;
};
