import { ASSET_TYPE, Broadcast, Channel, isChannel } from '@24i/nxg-sdk-photon';
import { getLiveAssetTimeInformation } from '../../utils';
import { BackstageEpgResponse, BackstageChannelsResponse } from './types';

const mapProgram = (program: Broadcast, channel?: Channel): Broadcast => {
    const programDetails = getLiveAssetTimeInformation(program);
    const isAdultChannel = channel?.isAdult ? { isAdult: true } : {};

    return {
        ...program,
        title: program.label,
        enableCatchUp: program.enableCatchUp ?? false,
        allowsRecording: false,
        channelLogo: channel?.images?.logo?.[0]?.url,
        number: channel?.number,
        type: ASSET_TYPE.BROADCAST,
        still: program?.images?.still?.[0]?.url,
        ...programDetails,
        ...isAdultChannel,
    };
};

export const mapChannel = (channel: Channel): Channel => ({
    ...channel,
    type: ASSET_TYPE.CHANNEL,
    channelLogo: channel.images?.logo?.[0]?.url,
});

export const mapEpgResponseToEpgData = (
    response: BackstageEpgResponse,
    channels: BackstageChannelsResponse
): Channel[] => {
    const result: Channel[] = channels.items
        .map((channel): Channel | null => {
            const programs = response[channel.id.toString()]?.map((program) =>
                mapProgram(program, channel)
            );
            return {
                ...mapChannel(channel),
                programs,
            };
        })
        .filter(isChannel);
    return result;
};
