import { QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { useAppSettingsData } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useUserQuery } from './useUserQuery';

export const useUpdateAccountPin = () => {
    const queryClient = useQueryClient();
    const { updateAccountPin } = useUserData();
    const { t } = useTranslation();
    const { user } = useUserQuery();
    const {
        client: { setShowCreatePin },
    } = useAppSettingsData();
    const { recordError } = useFirebase();

    const { mutate } = useMutation((pinCode: string) => updateAccountPin(pinCode), {
        onSuccess: async () => {
            if (user?.id) await setShowCreatePin(user.id);
            queryClient.invalidateQueries(QUERY_KEYS.user);
        },
        onError: (error) => {
            showToast(t('error.A00.body'), {
                type: 'error',
            });
            log(error);
            if (recordError) recordError(error);
        },
    });

    return mutate;
};
