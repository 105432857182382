import { isPlatformAndroid } from 'renative';
import { Ratio, getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { TextStyle, ViewStyle } from 'react-native';

export type ManageProfilesStyles = typeof styles;

const styles = (theme) => ({
    container: {
        flex: 1,
        backgroundColor: theme.color.background1,
    },
    scrollContainer: {
        justifyContent: 'space-between',
        flexGrow: 1,
    } as ViewStyle,
    screenContainer: {
        marginTop: isPlatformAndroid ? 30 : 0,
    },
    header: {
        flexDirection: 'row',
        marginTop: 27,
        justifyContent: 'center',
    },
    headerText: {
        color: theme.color.textPrimary,
        fontSize: Ratio(32),
        fontWeight: 'bold',
        lineHeight: 40,
        ...getFont(theme, 'h1'),
    },
    iconButtonContainer: {
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 10,
        top: 3,
    },
    contentContainer: {
        justifyContent: 'space-between',
        alignSelf: 'center',
        width: '90%',
        marginTop: 40,
    } as ViewStyle,
    textInput: {
        height: 48,
        ...getFont(theme, 'body1', true),
        color: theme.color.textPrimary,
    },
    profileImageContainer: {
        marginTop: 40,
        marginBottom: 28,
    },
    additionalProfileImageContainer: {
        height: Ratio(118),
        width: Ratio(118),
        borderRadius: Ratio(8),
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        marginTop: Ratio(24),
        borderColor: theme.color.textPrimary,
    },
    profileImageText: {
        color: theme.color.textPrimary,
        fontSize: Ratio(20),
        fontWeight: 'bold',
        lineHeight: 24,
        ...getFont(theme, 'h3'),
    } as TextStyle,
    ageCategoryContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 24,
    },
    ageCategoryText: {
        color: theme.color.textPrimary,
        fontSize: Ratio(20),
        fontWeight: 'bold',
        lineHeight: 24,
        ...getFont(theme, 'h3'),
    } as TextStyle,
    additonalAgeCategory: {
        width: 24,
        height: 24,
        borderColor: theme.color.buttonPrimary,
        borderWidth: 2,
        borderRadius: 3,
        justifyContent: 'center',
        flex: 1,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    ageCategoryDescriptionText: {
        color: `${theme.color.textPrimary}99`,
        fontSize: Ratio(16),
        lineHeight: 24,
        ...getFont(theme, 'body1'),
    },
    selectionAgeCategory: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 10,
    },
    additonalPreferenceOfAge: {
        color: theme.color.textPrimary,
        fontSize: Ratio(16),
        lineHeight: 48,
        ...getFont(theme, 'h4'),
    },
    additionalAgeValueContainer: {
        position: 'absolute',
        right: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        paddingBottom: 3,
        borderRadius: 4,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    additionalSaveButtonContainer: {
        width: 160,
        height: 52,
        marginBottom: 24,
    },
    deleteButton: {
        color: theme.color.textPrimary,
        fontSize: Ratio(14),
        fontWeight: '600',
        lineHeight: 24,
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    },
    modalText: {
        color: theme.color.textPrimary,
        fontSize: Ratio(32),
        fontWeight: 'bold',
        lineHeight: 40,
        textAlign: 'center',
        ...getFont(theme, 'h1'),
    } as TextStyle,
    modalDescription: {
        marginTop: 16,
        color: `${theme.color.textPrimary}99`,
        fontSize: Ratio(16),
        fontWeight: '500',
        lineHeight: 24,
        textAlign: 'center',
        width: '94%',
        alignSelf: 'center',
        ...getFont(theme, 'body1'),
    } as TextStyle,
    modalDeleteButton: {
        height: 52,
        width: 'auto',
        paddingHorizontal: 24,
        marginTop: 48,
    },
    activityIndicatorContainer: {},
});

export default styles;
