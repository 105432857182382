import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Theme } from '@24i/nxg-sdk-photon';
import getFallbackImageStyles from '../styles';

interface FallbackImageProps {
    fallbackImage: (theme: Theme) => JSX.Element;
    hasGradientAlready: boolean;
}

const FallbackImage = ({ fallbackImage, hasGradientAlready }: FallbackImageProps) => {
    const { theme } = useTheme();

    const styles = getFallbackImageStyles();
    const { colors } = theme.color.gradient2;

    const transparentGradient = ['rgba(0,0,0,0.7)', 'rgba(0,0,0,0)'];

    const gradientColors = hasGradientAlready ? transparentGradient : colors;

    return (
        <LinearGradient
            colors={gradientColors}
            style={styles.container}
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 0 }}
        >
            {fallbackImage(theme)}
        </LinearGradient>
    );
};

export default overridable(FallbackImage, 'FallbackImage');
