import { EpgDataClientStub as EpgDataClient } from '@24i/nxg-sdk-smartott-stubs/src/clients/EpgDataClient';
import { ContentDataClient } from '@24i/nxg-integration-backstage';
import { createStubRecordingClient } from '../RecordingsClient';
import { DevicesManagementClientStub as DevicesManagementClient } from '../DevicesManagementClient';
import { RemindersClientStub as RemindersClient } from '../RemindersClient';
import { DefaultClientStubs } from './types';

export const getDefaultClientStubs = (contentDataClient: ContentDataClient): DefaultClientStubs => {
    if (!contentDataClient)
        throw new Error(
            `Not possible to create default client stubs. Please specify a valid contentDataClient.`
        );
    const creds = {}; // Use for stub EPG data
    // @ts-ignore
    const epgDataClient = new EpgDataClient({ credentials: creds });
    const recordingsClient = createStubRecordingClient({ contentDataClient });
    const devicesManagementClient = new DevicesManagementClient();
    const remindersClient = new RemindersClient();
    return { epgDataClient, recordingsClient, devicesManagementClient, remindersClient };
};
