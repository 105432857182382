import { Theme } from '@24i/nxg-sdk-photon';
import getConfirmPurchasesStyles from '../../../components/PurchasesModal/ConfirmPurchase/styles/index.web';
import { ConfirmPurchaseStyles } from '../../../components/PurchasesModal/ConfirmPurchase/styles/types';

export const confirmPurchasesOverrideStyles = (theme: Theme): ConfirmPurchaseStyles => ({
    ...getConfirmPurchasesStyles(theme),
    buttonWrapper: {
        ...getConfirmPurchasesStyles(theme).buttonWrapper,
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
});

export const confirmPurchasesOnboardingOverrideStyles = (theme: Theme): ConfirmPurchaseStyles => ({
    ...getConfirmPurchasesStyles(theme),
    informationText: {
        ...getConfirmPurchasesStyles(theme).informationText,
        textAlign: 'center',
    },
    confirmButton: {
        ...getConfirmPurchasesStyles(theme).confirmButton,
        minWidth: '70%',
    },
});
