import React, { ReactElement } from 'react';
import {
    ImageWithFallBackProps,
    ImageWithFallBackPropsWeb,
} from '@24i/nxg-sdk-quarks/src/components/Image/types';
import { Image } from '@24i/nxg-sdk-quarks';
import ImageWeb from '@24i/nxg-sdk-quarks/src/components/Image/index.web';
import LinearGradient from 'react-native-linear-gradient';
import { getPackshotTestID, PACKSHOT_TEST_IDS } from '../test-utils';
import ProgressBar, {
    getProgressBarStyles,
    getLinearGradientStyles,
    ProgressBarProps,
} from '../../ProgressBar';
import ChannelOverlay from './ChannelOverlay';
import ChannelOverlayWeb from './ChannelOverlay/index.web';
import LiveBadge from './LiveBadge';
import PackshotTitle from './PackshotTitle';
import PackshotActionButton from './PackshotActionButton';
import { ChannelOverlayProps, ChannelOverlayPropsWeb } from './ChannelOverlay/types';
import { PackshotActionButtonProps } from './PackshotActionButton/types';
import { LiveBadgeProps } from './LiveBadge/types';
import { getGradientVectors, getRenderProps } from './utils';
import { PackshotTitleProps } from './PackshotTitle/types';
import { RenderPropsOptions } from '../types';

export const defaultRenderImage = (
    props: ImageWithFallBackProps | ImageWithFallBackPropsWeb,
    options: RenderPropsOptions
): ReactElement =>
    getRenderProps<ImageWithFallBackProps, ImageWithFallBackPropsWeb>(
        Image,
        ImageWeb,
        props,
        options,
        PACKSHOT_TEST_IDS.IMAGE
    );

export const defaultRenderChannelOverlay = (
    props: ChannelOverlayProps | ChannelOverlayPropsWeb,
    options: RenderPropsOptions
) =>
    getRenderProps<ChannelOverlayProps, ChannelOverlayPropsWeb>(
        ChannelOverlay,
        ChannelOverlayWeb,
        props,
        options,
        PACKSHOT_TEST_IDS.CHANNEL_OVERLAY
    );

export const defaultRenderPackshotActionButton = (
    props: PackshotActionButtonProps,
    options: RenderPropsOptions
) => (
    <PackshotActionButton
        testID={getPackshotTestID(PACKSHOT_TEST_IDS.ACTION_BUTTON, options)}
        {...props}
    />
);

export const defaultRenderLiveBadge = (props: LiveBadgeProps, options: RenderPropsOptions) => (
    <LiveBadge testID={getPackshotTestID(PACKSHOT_TEST_IDS.LIVE_BADGE, options)} {...props} />
);

export const defaultRenderProgressBar = (props: ProgressBarProps, options: RenderPropsOptions) => {
    const { theme } = props;
    const { colors } = options;
    const { vectorStart, vectorEnd } = getGradientVectors(theme.color.gradient2);
    return (
        <>
            {colors && theme && (
                <LinearGradient
                    colors={colors}
                    style={getLinearGradientStyles(theme).linearGradient}
                    start={vectorStart}
                    end={vectorEnd}
                />
            )}
            <ProgressBar
                testID={getPackshotTestID(PACKSHOT_TEST_IDS.PROGRESS_BAR, options)}
                {...props}
                styles={getProgressBarStyles}
            />
        </>
    );
};

export const defaultRenderPackshotTitle = (
    props: PackshotTitleProps,
    options: RenderPropsOptions
) => <PackshotTitle testID={getPackshotTestID(PACKSHOT_TEST_IDS.TITLE, options)} {...props} />;
