import { createTestIDGetter } from '@24i/nxg-core-utils';

export enum SLIDER_TEST_IDS {
    CONTAINER = 'container',
    PREV_BUTTON = 'prev_button',
    NEXT_BUTTON = 'next_button',
    SLIDE_PLAY_BUTTON = 'slide_play_button',
    SLIDE_MORE_INFO_BUTTON = 'slide_more_info_button',
    SLIDE_CONTAINER_PRESSABLE = 'slide_slide_container_pressable',
    SLIDE_CONTAINER = 'slide_slide',
    SLIDE_IMAGE = `slide_image`,
}

export const getSliderTestId = createTestIDGetter('slider');
