import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

type BackstageMenuItem = {
    type: string;
    slug: string;
    icon?: string;
    reference?: string | null;
    label: string;
    items?: BackstageMenuItem[];
};

export const BackstageMenuItemGuard = t.recursion<BackstageMenuItem>('MenuItem', (Self) =>
    createGuard(
        'MenuItem',
        {
            type: t.string,
            slug: t.string,
            label: t.string,
        },
        {
            icon: t.string,
            reference: nullable(t.string),
            items: t.array(Self),
        }
    )
);

export const BackstageMenuGuard = createGuard('MenuInfo', {
    id: t.string,
    type: t.string,
    label: t.string,
    items: t.array(BackstageMenuItemGuard),
});

export const BackstageMenuResponseGuard = t.array(BackstageMenuGuard, 'BackstageMenuResponse');
