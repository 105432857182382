import { SearchDropdownPropsStyles } from '../types';

const SearchDropdown = (): SearchDropdownPropsStyles => ({
    holder: {
        paddingHorizontal: 32,
        marginTop: 10,
        paddingLeft: 20,
    },
});

export default SearchDropdown;
