import React, { FC } from 'react';
import { PaymentResult } from '../../../components/PaymentResult';
import {
    NoOpProps,
    PaymentResultProps,
    areValidResultProps,
} from '../../../components/PaymentResult/types';

const PaymentResultScreen: FC<PaymentResultProps | NoOpProps> = (props) => {
    if (!areValidResultProps(props)) return null;

    return <PaymentResult {...props} />;
};

export default PaymentResultScreen;
