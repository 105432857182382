import { useState, useEffect, useRef } from 'react';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { log } from '@24i/nxg-core-utils/src/logger';
import { Alert } from 'react-native';
import { useWebGridPagination } from '@24i/nxg-sdk-smartott/src/hooks/pagination';
import { Asset } from '@24i/nxg-sdk-photon';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import { GenreScreenProps, GenreWebProps } from '../types';

const useViewModel = (props: GenreScreenProps): GenreWebProps => {
    const [genreAssets, setGenreAssets] = useState<Asset[]>([]);
    const [selectedGenre, setSelectedGenre] = useState<string | string[] | undefined>('');
    const contentDataClient = useContentData();
    const { recordError, logEvent, crashlyticsLog } = useFirebase();
    const router = useRouter();
    const {
        grid: { pageSize },
    } = getRuntimeConfig('app');
    const endOfPageReached = useRef<boolean>(false);
    const gridContainerRef = useRef<Element>();
    const [oldOffset, setOldOffset] = useState<number>(-1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { handlePackshotPress } = useAssetActions();

    const getGenreAssets = async (offset: number) => {
        if (oldOffset === offset) return false;
        try {
            if (router?.query.id && router?.query.type) {
                setIsLoading(true);
                const fetchedGenreAssets = await contentDataClient.fetchGenreAssets(
                    router.query.id,
                    router.query.type,
                    offset || 0,
                    pageSize
                );
                if (fetchedGenreAssets.length !== pageSize) endOfPageReached.current = true;
                if (JSON.stringify(fetchedGenreAssets) === JSON.stringify(genreAssets)) {
                    setIsLoading(false);
                    return false;
                }
                setOldOffset(offset);
                setGenreAssets((prevState: Asset[]) => [...prevState, ...fetchedGenreAssets]);
                setIsLoading(false);
                return true;
            }
        } catch (err: any) {
            if (recordError) recordError(err);
            log(err);
            if (err?.message) {
                Alert.alert('Something went wrong...', err.message, [{ text: 'Okay' }]);
            }
        }
        return false;
    };

    useWebGridPagination({
        isLoading,
        fetchData: getGenreAssets,
        gridContainerRef,
        assetsLength: genreAssets?.length,
        endOfPageReached,
    });

    useEffect(() => {
        setSelectedGenre(router?.query.genre);
        getGenreAssets(0);
        logEvent('genre_screen_entered');
        crashlyticsLog('GenreScreen entered');
        return crashlyticsLog('GenreScreen exited');
    }, []);

    return {
        selectedGenre,
        genreAssets,
        handlePackshotPress,
        isLoading,
        gridContainerRef,
        ...props,
    };
};

export { useViewModel };
