import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';

export const getStyles = (theme: Theme) => ({
    buttonWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    space: {
        marginBottom: 32,
    },
    title: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h2'),
    },
    message: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body2'),
        opacity: 0.6,
    },
});
