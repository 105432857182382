import { useQuery, UseQueryOptions } from 'react-query';
import { Asset, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';

const useAssetExtrasQuery = (asset?: Asset, options?: UseQueryOptions<Asset[]>) => {
    const isOptionEnabled = options?.enabled !== undefined ? options.enabled : true;
    const { fetchExtras } = useContentData();

    return useQuery<Asset[]>(
        [QUERY_KEYS.assets, QUERY_KEYS.assetExtras, asset?.id],
        () => asset && fetchExtras(asset),
        { staleTime: 5 * 60 * 1000, ...options, enabled: !!asset?.id && isOptionEnabled }
    );
};

export default useAssetExtrasQuery;
