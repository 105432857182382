import React, { ReactElement, useEffect } from 'react';
import { ScrollView as QuarksScrollView, View } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';

import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import useSafeAreaInsets from '@24i/nxg-sdk-gluons/src/hooks/useSafeAreaInsets';
import LinearGradientProps from 'react-native-linear-gradient';
import { WEB_SCREEN_MAX_WIDTH } from '@24i/nxg-sdk-gluons/src/utils/constants';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { ActivityIndicatorProps } from '@24i/nxg-sdk-quarks/src/components/ActivityIndicator';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper/View';
import MoreScreenAuthSection from '../components/MoreScreenAuthSection';
import MoreScreenMenu from '../components/MoreScreenMenu';
import ProfilePicker from '../components/ProfilePicker';
import { getBottomBarHeight } from '../../../utils';
import getMoreScreenStyles from '../styles';
import { MoreScreenProps } from '../types';
import { ProfilePickerProps } from '../components/ProfilePicker/types';
import { MoreScreenMenuProps } from '../components/MoreScreenMenu/types';
import { MoreScreenAuthSectionProps } from '../components/MoreScreenAuthSection/types';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../navigation/constants';

export const defaultRenderProfilePicker = (props: ProfilePickerProps): ReactElement => {
    return <ProfilePicker {...props} />;
};
export const defaultRenderMoreScreenMenu = (props: MoreScreenMenuProps): ReactElement => (
    <MoreScreenMenu {...props} />
);
export const defaultRenderMoreScreenAuthSection = (
    props: MoreScreenAuthSectionProps
): ReactElement => <MoreScreenAuthSection {...props} />;

const ScrollView = isPlatformWeb ? View : QuarksScrollView;

const MoreScreen = (props: MoreScreenProps): ReactElement => {
    const {
        styles: getStyles = getMoreScreenStyles,
        profiles = [],
        selectProfile = () => null,
        isLoading = true,
        showProfilePicker = false,
        menuItems,
        onSignInPress,
        onRegisterPress,
        linearGradientProps = {},
        showBottomAuthSection = true,
        renderProfilePicker = defaultRenderProfilePicker,
        renderMoreScreenMenu = defaultRenderMoreScreenMenu,
        renderMoreScreenAuthSection = defaultRenderMoreScreenAuthSection,
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { top } = useSafeAreaInsets();
    const { width } = useDimensions();
    const router = useRouter();

    // More screen doesn't exists for web on bigger screens,
    // adding this redirects user to Homepage when screen width increases.
    useEffect(() => {
        if (isPlatformWeb && width > WEB_SCREEN_MAX_WIDTH.XS) {
            const homeLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();
            router?.replace(homeLink.href, homeLink.as);
        }
    }, [width]);

    const loaderProps: ActivityIndicatorProps = {
        size: 'large',
        holderStyles: { marginTop: 350 },
    };

    return (
        <LoadingWrapper loaderProps={loaderProps} isLoading={isLoading}>
            <ScrollView
                style={styles.scrollViewStyle}
                contentContainerStyle={[
                    { paddingBottom: getBottomBarHeight(), paddingTop: top },
                    styles.contentContainerStyle,
                ]}
                testID="MoreScreen.ScrollView.1"
            >
                <View testID="MoreScreen.View.1">
                    {showProfilePicker &&
                        renderProfilePicker({
                            profiles,
                            selectProfile,
                            isLoading,
                        })}
                    {renderMoreScreenMenu({ menuItems })}
                </View>
                {showBottomAuthSection &&
                    renderMoreScreenAuthSection({
                        onSignInPress,
                        onRegisterPress,
                        linearGradientProps: linearGradientProps as LinearGradientProps,
                    })}
            </ScrollView>
        </LoadingWrapper>
    );
};

export { getMoreScreenStyles };
export default overridable(MoreScreen, 'MoreScreen');
