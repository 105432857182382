import { extendGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { Episode, EpisodeGuard } from './episode';
import { ASSET_TYPE } from '../enums';
import { Asset, AssetGuard } from './asset';

const PodcastSeriesGuard = extendGuard(
    'PodcastSeries',
    AssetGuard,
    { type: t.literal(ASSET_TYPE.PODCAST_SERIES) },
    {}
);

export const getIsPodcastSeries = (asset: Asset): asset is PodcastSeries =>
    asset && asset.type === ASSET_TYPE.PODCAST_SERIES;

export type PodcastSeries = t.TypeOf<typeof PodcastSeriesGuard>;

export type AssetPossiblyPodcastEpisode = Asset &
    Partial<Pick<Episode, 'seriesType' | 'podcastName'>>;

const PodcastEpisodeGuard = extendGuard(
    'PodcastEpisode',
    EpisodeGuard,
    { seriesType: t.literal('podcast'), podcastName: t.string },
    {}
);

export const getIsPodcastEpisode = (asset: AssetPossiblyPodcastEpisode): asset is PodcastEpisode =>
    asset.type === ASSET_TYPE.EPISODE &&
    asset.seriesType === 'podcast' &&
    asset.podcastName != null;

export type PodcastEpisode = t.TypeOf<typeof PodcastEpisodeGuard>;

export type PodcastEpisodePlaybackStatus = 'playing' | 'paused';

export type PlaybackDirection = 'prev' | 'next';
