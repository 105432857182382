export const isEmptyString = (s) => typeof s !== 'string' || s.length === 0;
export const isInCorrectBucket = (width, currentBucket, nextBucket) => {
    if (!nextBucket) {
        return true;
    }

    const maxWidth = currentBucket.value?.maxWidth;
    const minWidth = currentBucket.value?.minWidth;
    const nextMinWidth = nextBucket.value?.minWidth;

    let isCorrect;
    if (maxWidth && minWidth) {
        isCorrect = width <= maxWidth && width >= minWidth;
    } else if (maxWidth) {
        isCorrect = width <= maxWidth;
    } else if (minWidth) {
        isCorrect = width >= minWidth && (nextMinWidth ? width < nextMinWidth : true);
    } else {
        throw new Error('missing either "minWidth" or "maxWidth"');
    }

    return isCorrect;
};

export const transformToArray = (sizeBucketsObject) =>
    Object.keys(sizeBucketsObject).map((key) => ({
        key,
        value: sizeBucketsObject[key],
    }));

export const sortSizeBuckets = (sizesArray) => {
    // order sizes by smallest width
    sizesArray.sort((a, b) => {
        const sizeA = a.value?.maxWidth || a.value?.minWidth;
        const sizeB = b.value?.maxWidth || b.value?.minWidth;

        return sizeA - sizeB;
    });

    return sizesArray;
};
