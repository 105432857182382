import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import { BlockedItem, BLOCKING_REASON_TYPES } from '@24i/nxg-sdk-photon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useViewModel = <P extends { item?: any }>(
    props: P
): P & {
    isObscured: boolean | undefined;
    isGeoBlocked: boolean | undefined;
} => {
    const { hasAdultBlocker } = useAssetPinControl({
        asset: props.item,
        active: false,
    });

    const isGeoBlocked = props.item?.blocked?.find(
        (blocking: BlockedItem) => blocking.reason === BLOCKING_REASON_TYPES.GEO_TYPE
    ) as BlockedItem | undefined;

    return { ...props, isObscured: hasAdultBlocker, isGeoBlocked: !!isGeoBlocked };
};

export { useViewModel };
