import React, { useRef } from 'react';
import { Interactable, Text, View, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import useOutsideClick from '@24i/nxg-sdk-smartott/src/hooks/useOutsideClick';
import getDropdownButtonStyle from '../styles';
import { DropdownButtonProps } from '../../../types';

const DropdownButton = (props: DropdownButtonProps) => {
    const {
        title,
        onPress,
        buttonTestID,
        buttonKey,
        additionalButtonStyles,
        additionalTextStyles,
        iconName,
        iconSize,
        handleClickOutside,
        styles: getStyles = getDropdownButtonStyle,
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme);
    const buttonRef = useRef(null);

    useOutsideClick(buttonRef, handleClickOutside);

    return (
        <Interactable testID={buttonTestID} key={buttonKey} onPress={onPress} ref={buttonRef}>
            <View style={[styles.buttonContainer, additionalButtonStyles]}>
                <View style={styles.innerButtonContainer}>
                    {iconName && <ThemeIcon iconName={iconName} size={iconSize || 22} />}
                    <Text style={[styles.dropdownButtonText, additionalTextStyles]}>{title}</Text>
                </View>

                <ThemeIcon
                    iconName="onButtonTertiary-dropdown"
                    size={20}
                    iconStyles={styles.secondaryIcon}
                />
            </View>
        </Interactable>
    );
};

export { getDropdownButtonStyle };
export default DropdownButton;
