import { useEffect, useRef } from 'react';

function usePrevious(value: unknown) {
    const ref = useRef<unknown>(null);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export default usePrevious;
