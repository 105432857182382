import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { log } from '@24i/nxg-core-utils/src/logger';
import { Asset, PageSection } from '@24i/nxg-sdk-photon';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { useWebGridPagination } from '@24i/nxg-sdk-smartott/src/hooks/pagination';
import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import { SeeAllScreenProps, SeeAllScreenWebProps } from '../types';

const useViewModel = (props: SeeAllScreenProps): SeeAllScreenWebProps => {
    const router = useRouter();
    const pageId = router?.query.pageId;
    const sectionId = router?.query.sectionId;
    const contentDataClient = useContentData();
    const gridContainerRef = React.useRef<Element>();
    const { runtimeConfig } = useStore();
    const app: any = runtimeConfig?.app;
    const { pageSize } = app.grid;
    const { t } = useTranslation(['sott']);
    const { recordError } = useFirebase();
    const [assets, setAssets] = useState<Asset[]>([]);
    const [displayType, setDisplayType] = useState<'landscape' | 'portrait'>('landscape');
    const [assetsLabel, setAssetslabel] = useState<string | null | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [oldOffset, setOldOffset] = useState<number>(-1);
    const endOfPageReached = React.useRef<boolean>(false);
    const { handlePackshotPress } = useAssetActions();
    const { sessionId } = useSessionId();

    const reportAnalytics = (label: string): void => {
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
            screen: t('analytics.documentTitle.seeAll', { rowTitle: label }),
            sessionId,
        });
    };

    const fetchSectionWithoutPlaylistId = async (sections: PageSection[]) => {
        const sectionsWithPlaylists = await contentDataClient.fetchPlaylist(sections);
        const [fetchedAssets] = sectionsWithPlaylists?.filter?.(
            (item: Asset) => item.id === sectionId
        );
        endOfPageReached.current = true;
        setIsLoading(false);
        setAssets(fetchedAssets.items);
        setAssetslabel(fetchedAssets.label);
        reportAnalytics(fetchedAssets.label);
    };

    const fetchSectionWithPlaylistId = async (section: PageSection, fromItem: number) => {
        const { items: fetchedAssets } = await contentDataClient.fetchAssets(
            section?.playlistId,
            section?.sorting,
            section?.pinnedItems,
            fromItem || 0, // if fromItem is not supplied, must be the first render
            pageSize
        );
        if (fetchedAssets.length < pageSize) endOfPageReached.current = true;
        setOldOffset(fromItem);
        setAssets((prevState: Asset[]) => [...prevState, ...fetchedAssets]);
        setAssetslabel(section.label);
        reportAnalytics(section.label as string);
        setIsLoading(false);
    };

    const fetchPlaylistData = async (fromItem: number) => {
        if (oldOffset === fromItem) return false;
        try {
            setIsLoading(true);
            const sections = await contentDataClient.fetchPage(pageId);
            const [firstSelectedSection] = sections?.filter?.(
                (item: Asset) => item.id === sectionId
            );
            if (firstSelectedSection.display) setDisplayType(firstSelectedSection.display);
            if (firstSelectedSection?.playlistId)
                fetchSectionWithPlaylistId(firstSelectedSection, fromItem);
            else fetchSectionWithoutPlaylistId(sections);
            return true;
        } catch (err) {
            if (recordError) recordError(err);
            log(err);
            showToast(t('error.A00.title'));
            return false;
        }
    };
    useWebGridPagination({
        isLoading,
        fetchData: fetchPlaylistData,
        gridContainerRef,
        assetsLength: assets?.length,
        endOfPageReached,
    });

    useEffect(() => {
        fetchPlaylistData(0);
    }, []);

    return {
        assets,
        assetsLabel,
        isLoading,
        gridContainerRef,
        displayType,
        handlePackshotPress,
        ...props,
    };
};

export { useViewModel };
