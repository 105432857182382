import { isPlatformAndroid, isPlatformIos, isPlatformWeb } from '@24i/nxg-core-utils';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { ISupportedFormats } from '@24i/player-base';
import { detect } from 'detect-browser';
import { mapToPlayerDrmSystem, mapToPlayerMimetype } from './mapSource';

export type ScorerType = (type: string, drm: string) => number;

export const googleScorer: ScorerType = (type, drm) => {
    switch (drm) {
        case 'unencrypted':
            switch (type) {
                case ASSET_TYPE.DASH:
                    return 7;
                case ASSET_TYPE.HLS:
                    return 4;
                case ASSET_TYPE.MP4:
                case ASSET_TYPE.MP3:
                    return 1;
                default:
                    return 0;
            }
        case 'widevine':
            switch (type) {
                case ASSET_TYPE.DASH:
                    return 9;
                default:
                    return 0;
            }
        default:
            return 0;
    }
};

export const appleScorer: ScorerType = (type, drm) => {
    switch (drm) {
        case 'unencrypted':
            switch (type) {
                case ASSET_TYPE.HLS:
                    return 7;
                case ASSET_TYPE.MP4:
                case ASSET_TYPE.MP3:
                    return 1;
                default:
                    return 0;
            }
        case 'fairplay':
            switch (type) {
                case ASSET_TYPE.HLS:
                    return 9;
                default:
                    return 0;
            }
        default:
            return 0;
    }
};

export const genericScorer: ScorerType = (type, drm) => {
    if (drm !== 'unencrypted') return 0;
    switch (type) {
        case ASSET_TYPE.DASH:
            return 4;
        case ASSET_TYPE.HLS:
            return 4;
        case ASSET_TYPE.MP4:
        case ASSET_TYPE.MP3:
            return 1;
        default:
            return 0;
    }
};

const selectDefaultScorer = () => {
    if (isPlatformAndroid) return googleScorer;
    if (isPlatformIos) return appleScorer;
    if (!isPlatformWeb) return genericScorer;

    const detected = detect(global.navigator?.userAgent);
    if (!detected || detected.type !== 'browser') return genericScorer;
    switch (detected.name) {
        case 'ios':
        case 'ios-webview':
        case 'safari':
            return appleScorer;
        case 'chrome':
        case 'android':
            return googleScorer;
        default:
            return genericScorer;
    }
};

export const defaultScorer = selectDefaultScorer();

export const selectSupportedScorer =
    (formats: ISupportedFormats): ScorerType =>
    (type, drm) => {
        const mimeType = mapToPlayerMimetype(type);
        const drmType = mapToPlayerDrmSystem(drm);
        const manifest = formats.findManifest(mimeType);
        if (!manifest) return 0;
        const manifestIndex = formats.manifest.findIndex((m) => m.mimeTypes.includes(mimeType));
        if (drmType) {
            const supportedDrm = manifest.drm.find((d) => d.keySystems.includes(drmType));
            if (!supportedDrm) return 0;
        }
        /* workaround: iOS returns "manifest"s in wrong order, "native" should be last option */
        if (manifest.name === 'native') return 1;
        return Math.max(2, formats.manifest.length - manifestIndex + 1);
    };
