import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import {
    BlockedItem,
    BLOCKING_REASON_TYPES,
    getIsPodcastSeries,
    getIsPodcastEpisode,
} from '@24i/nxg-sdk-photon';
import { useTranslation } from 'react-i18next';
import { DeviceInfo, useDimensions } from '@24i/nxg-sdk-quantum';
import { ORIENTATIONS } from '@24i/nxg-core-utils/src/constants';
import { SliderItemComponentProps } from '../../../types';
import { getSliderSubtitle } from '../utils';

const useViewModel = (props: Omit<SliderItemComponentProps, 'isObscured'>) => {
    const { isLandscape } = useDimensions();
    const { hasAdultBlocker } = useAssetPinControl({
        asset: props.data,
        active: false,
    });
    const { data: asset } = props;
    const { t } = useTranslation();

    const isGeoBlocked = props.data.blocked?.find(
        (blocking: BlockedItem) => blocking.reason === BLOCKING_REASON_TYPES.GEO_TYPE
    );

    const orientation = isLandscape ? ORIENTATIONS.LANDSCAPE : ORIENTATIONS.PORTRAIT;
    const isPortraitPhone = !DeviceInfo.isTablet() && orientation === ORIENTATIONS.PORTRAIT;
    const isPodcastSeriesOrEpisode = getIsPodcastSeries(asset) || getIsPodcastEpisode(asset);

    const subtitle = getSliderSubtitle({
        asset,
        isObscured: !!hasAdultBlocker,
        isPodcastSeriesOrEpisode,
        obscuredLocalizedMessage: t('asset.blocked.metadata'),
    });

    return {
        ...props,
        isObscured: hasAdultBlocker,
        isPortraitPhone,
        isGeoBlocked: !!isGeoBlocked,
        subtitle,
    };
};

export { useViewModel };
