import { Theme } from '@24i/nxg-sdk-photon';
import { CreateCarouselStyles } from './types';

const getCreateCarouselStyles = (theme: Theme): CreateCarouselStyles => {
    return {
        carouselWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        continueButtonContainer: {
            alignSelf: 'center',
            position: 'absolute',
            bottom: 82,
            width: 335,
            height: 52,
            backgroundColor: theme.color.buttonPrimary,
        },
        continueButtonText: {
            color: theme.color.textButtonPrimary,
        },
    };
};

export default getCreateCarouselStyles;
