import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { RECORDING_STATUS_GUARD } from '../enums';

export const RecordingsFolderGuard = createGuard('RecordingsFolder', {
    recordingIds: t.array(t.string),
    still: nullable(t.string),
    title: t.string,
    id: t.string,
    status: RECORDING_STATUS_GUARD,
    totalDuration: nullable(t.number),
    profileId: nullable(t.string),
    channelLogo: nullable(t.string),
});

export type RecordingsFolder = t.TypeOf<typeof RecordingsFolderGuard>;

export const createRecordingsFolder = (data: unknown): RecordingsFolder | never => {
    return decodeModelWith(RecordingsFolderGuard, data, 'RecordingsFolder');
};

export const isRecordingFolder = (data: unknown): data is RecordingsFolder => {
    try {
        return !!decodeModelWith(RecordingsFolderGuard, data, 'RecordingsFolder', {
            disableErrorLog: true,
        });
    } catch {
        return false;
    }
};
