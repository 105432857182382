import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import Collection from 'react-virtualized/dist/commonjs/Collection';
import { filter, filterByDate, processData } from '../utils/DataProcessing';
import { WebEpgContainerProps } from './types';
import WebTimeline from './web/components/Timeline';
import { defaultEpgViewConfig } from '../utils/constants';
import EPGView from './web';

const EPG = (props: WebEpgContainerProps) => {
    const {
        currentTime = new Date(new Date().setSeconds(1, 1)),
        selectedDate = new Date(),
        data = [],
        loadingIndicator = () => <></>,
        onPressCell = () => {},
        onBlur = () => {},
        onKeyEvent = () => {},
        handleTimeChange = true,
        customCell = undefined,
        customFilterAdapter = null,
        shouldBeLocked = false,
        isFetchingDone = true,
        onVerticalEndReached = () => {},
        isLoading,
        isFetching,
        currentTimeLabelText = '',
        is12HourClock = false,
        onWebViewportScroll,
        filters = [],
        viewConfig = defaultEpgViewConfig,
        onScroll,
        scrollLeft,
        scrollTop,
        lockedContentTitle,
        landscapeTheme,
        secondLayer,
    } = props;

    const collectionRef = useRef<Collection | null>(null);
    const timelineRef = useRef<typeof WebTimeline | null>(null);

    const [currentTimeState, setCurrentTimeState] = useState<Date | undefined>(props.currentTime);

    useEffect(() => {
        if (handleTimeChange) {
            const interval = setInterval(() => {
                setCurrentTimeState(new Date());
            }, viewConfig?.currentTimeUpdateInterval * 1000 || 0 * 1000);
            return () => clearInterval(interval);
        }
    }, []);

    const getPixelsPerMin = () => {
        return props.viewConfig?.pixelsPerMin || 10;
    };

    const themeWithConfig = {
        ...props.landscapeTheme,
        PIXELS_PER_MIN: viewConfig?.pixelsPerMin,
        VISIBLE_CHUNK_SIZE_VIEWPORTS: viewConfig?.visibleChunkSizeViewports,
        currentTimeUpdateInterval: viewConfig?.currentTimeUpdateInterval,
    };

    const getData = () => {
        const dayData = filterByDate(data, selectedDate);

        const { filteredData } = filter(data, dayData, filters, filterByDate, customFilterAdapter);
        return processData(filteredData, {
            selectedDate,
            landscapeCellHeight: landscapeTheme.cell.container.height,
            pixelsPerMin: getPixelsPerMin(),
            shouldBeLocked: shouldBeLocked,
        });
    };

    // TODO: Maybe memoize this if there are performance issues
    // it might be too heavy
    const processedData = getData().state;

    return processedData.matrix?.length ? (
        <View style={{ flex: 1, zIndex: -1 }}>
            <EPGView
                theme={themeWithConfig}
                data={processedData.data}
                onScroll={onScroll}
                key={selectedDate.toString()}
                onWebViewportScroll={onWebViewportScroll}
                scrollLeft={scrollLeft}
                scrollTop={scrollTop}
                currentTime={currentTimeState}
                selectedDate={processedData.selectedDate}
                matrix={processedData.matrix}
                channels={data.filter((channel) => processedData.channels.includes(channel.id))}
                onPressCell={onPressCell}
                isLoading={isLoading}
                isFetching={isFetching}
                onBlur={onBlur}
                onKeyEvent={onKeyEvent}
                customCell={customCell}
                collectionRef={(el) => {
                    collectionRef.current = el;
                }}
                timelineRef={(el) => {
                    timelineRef.current = el;
                }}
                isFetchingDone={isFetchingDone}
                // loadingIndicator={loadingIndicator} replaced with LoadingWrapper
                onVerticalEndReached={onVerticalEndReached}
                currentTimeLabelText={currentTimeLabelText}
                is12HourClock={is12HourClock}
                lockedContentTitle={lockedContentTitle}
                lastDateChange={processedData.lastDateChange}
            />
            {secondLayer && secondLayer({ currentTime })}
        </View>
    ) : (
        loadingIndicator?.()
    );
};

export default EPG;
