import * as localStorage from './LocalStorage';
import * as cookieStorage from './CookieStorage';

const storage = typeof window !== 'undefined' && window.localStorage ? localStorage : cookieStorage;
const { getItem, setItem, removeItem, getAllKeys } = storage;

const multiGet = (keys) =>
    Promise.all(keys.map((key) => getItem(key).then((value) => [key, value])));

const multiSet = (keyValuePairs) =>
    Promise.all(keyValuePairs.map(([key, value]) => setItem(key, value)));

const multiRemove = (keys) => Promise.all(keys.map((key) => removeItem(key)));

const clear = () => getAllKeys().then(multiRemove);

export default {
    getItem,
    setItem,
    removeItem,
    getAllKeys,
    multiGet,
    multiSet,
    multiRemove,
    clear,
};
