/* eslint-disable @typescript-eslint/no-unused-vars */
import { ASSET_TYPE, Broadcast, Channel, EpgDataClient, Recording } from '@24i/nxg-sdk-photon';
import { DateTime } from 'luxon';
import { isLessThenXDays } from '@24i/nxg-core-utils';
import stubData from './stub_data.json';

const convertMs = (ms: number) => {
    let h;
    let m;
    let s;

    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s %= 60;
    h = Math.floor(m / 60);
    m %= 60;
    const d = Math.floor(h / 24);
    h %= 24;

    return { d, h, m, s };
};

const NUMBER_OF_PAST_DAYS_TO_PLAY_EPG = 1;

export const mapBroadcastToStubEpgData = (data, i) => ({
    modelType: 'tv',
    data: {
        id: data.id,
        channelId: '21',
        channelname: 'Series test channel',
        channelLogoUrl: 'https://backstage-cms.24i.com/images/24i-logo-red.png',
        images: [data.images.still[0].url],
        name: data.label,
        shortDescription: data.description,
        longDescription: data.description,
        start: 1614121200000 + 5400000 * i,
        end: 1614121200000 + 5400000 * (i + 1),
        seriesId: data.series,
        enableCatchUp: isLessThenXDays(
            NUMBER_OF_PAST_DAYS_TO_PLAY_EPG,
            1614121200000 + 5400000 * i
        ),
    },
});

export const STUB_EPG_SEPARATOR = '@@';
export const STUB_CHANNEL_EPG_SEPARATOR = '__00__';
const DAYS = 2;

/**
 * Allows recording when the givenDate is before then today + ${DAYS} days
 * ${DAYS} days as arbitrary value to be able to test recordings feature
 */
const allowsRecording = (startTime: Date, endTime: Date): boolean => {
    const today = new Date();
    return (
        startTime.getTime() < today.setDate(today.getDate() + DAYS) &&
        endTime.getTime() >= Date.now()
    );
};

/**
 * Calculated time addon in miliseconds so that stub data are visible for each day.
 */
const calculateAddonMs = (date: Date) => {
    const timeOrig = stubData[0].programs[0].start;
    const timeDiff = convertMs(date.getTime() - timeOrig);

    return timeDiff.d * 24 * 60 * 60 * 1000;
};

const findProgram = (channelId, programId) => {
    const channels = stubData.filter((item) => !channelId || item.id === channelId);
    for (let i = 0; i < channels.length; i++) {
        const program = channels[i].programs.find((item) => item.id === programId);
        if (program) {
            return program;
        }
    }
    return undefined;
};

const mapProgramToBroadcast = (
    program,
    addonMs: number,
    dateStr: string,
    recording?: Recording
) => {
    const startDate = new Date(program.start + addonMs);
    const endDate = new Date(program.end + addonMs);
    const start = program.start + addonMs;
    const end = program.end + addonMs;
    const duration = end - start;
    const durationInMinutes = duration / 60 / 1000;
    let isLive = false;
    let progress;

    if (start < +new Date() && end > +new Date()) {
        isLive = true;
        const timeLeft = end - +new Date();
        progress = (duration - timeLeft) / duration;
    }

    return {
        id: `${program.id}${STUB_EPG_SEPARATOR}${dateStr}`,
        type: ASSET_TYPE.BROADCAST,
        channelId: program.channelId,
        ...(program.channelLogo && { channelLogo: program.channelLogo }),
        start,
        end,
        startsAt: (program.start + addonMs) / 1000,
        endsAt: (program.end + addonMs) / 1000,
        title: program.title,
        still: program.still,
        isAdult: program.isAdult,
        description: program.description,
        // it can't deal with some of the stub data having the
        // series id, status but others not.
        // @ts-ignore
        status: recording?.status,
        // @ts-ignore
        series: program.series || program.seriesId,
        allowsRecording: allowsRecording(startDate, endDate),
        enableCatchUp: isLessThenXDays(NUMBER_OF_PAST_DAYS_TO_PLAY_EPG, program.start + addonMs),
        isLive,
        progress,
        duration: durationInMinutes,
        channel: {
            label: program.channelTitle,
            number: program.channelId,
        },
    };
};

export class EpgDataClientStub implements EpgDataClient {
    getBroadcastByRecording = async (recording: Recording): Promise<Broadcast | undefined> => {
        const [programId, dateStr] = recording.assetId.split(STUB_EPG_SEPARATOR);

        if (!dateStr) {
            return undefined;
        }

        const program = findProgram(recording.channelId, programId);

        if (!program) {
            return undefined;
        }

        const addonMs = calculateAddonMs(new Date(dateStr));

        return mapProgramToBroadcast(program, addonMs, dateStr, recording);
    };

    getBroadcastById = async ({ broadcastId, channelId }): Promise<Broadcast | undefined> => {
        const [programId, dateStr] = broadcastId.split(STUB_EPG_SEPARATOR);
        const adjustedChannelId = channelId.split(STUB_CHANNEL_EPG_SEPARATOR)[0];

        if (!dateStr) {
            return undefined;
        }

        const program = findProgram(adjustedChannelId, programId);

        if (!program) {
            return undefined;
        }

        const addonMs = calculateAddonMs(new Date(dateStr));

        return mapProgramToBroadcast(program, addonMs, dateStr);
    };

    getEpgData = async (
        start: string | null,
        end: string | null,
        channelIds: string[] | undefined = [],
        filterFunction: (broadcast: Broadcast) => boolean = () => true
    ) => {
        const date = new Date();
        const dateStr = date.toISOString().slice(0, 10);
        const addonMs = calculateAddonMs(new Date(dateStr));

        return (stubData as Channel[]).map((channel: Channel, index) => {
            const { channelLogo, title: channelTitle } = channel;
            return {
                ...channel,
                label: `Test ${index}`,
                programs: channel.programs
                    ?.map((program) =>
                        mapProgramToBroadcast(
                            Object.assign(program, { channelLogo, channelTitle }),
                            addonMs,
                            dateStr
                        )
                    )
                    .filter(filterFunction),
            };
        });
    };

    // TODO: currently this will work only for an interval from midnight to midnight next day
    // needs to be improved
    getEpgDataInRange: EpgDataClient['getEpgDataInRange'] = async (range) => {
        const startDate = DateTime.now().toISO();
        const endDate = DateTime.now().toISO();
        const result = await this.getEpgData(
            startDate,
            endDate,
            undefined,
            (b) => b.end >= range.start && b.start <= range.end
        );
        return result;
    };

    getChannels = async () => JSON.parse(JSON.stringify(stubData)) as Channel[];
}
