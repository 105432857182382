import { ViewStyle } from 'react-native';

export const DEFAULT_SIZE = 74;
export const SHADOW_HEIGHT = 2;
export const SHADOW_WIDTH = -2;
export const ANIMATION_DURATION = 200;

export const THUMB_STYLES: ViewStyle = {
    height: DEFAULT_SIZE,
    borderRadius: DEFAULT_SIZE / 2,
    shadowOpacity: 0.4,
    shadowRadius: 4,
};

export const CONTAINER_FOCUSED_WIDTH = 95;
export const CONTAINER_BORDER_WIDTH = 3;
export const CONTAINER_WIDTH = 140;
export const CONTAINER_HEIGHT = DEFAULT_SIZE + CONTAINER_BORDER_WIDTH * 2;
export const CONTAINER_BORDER_RADIUS = Math.ceil(CONTAINER_HEIGHT / 2);
export const CONTAINER_STYLES: ViewStyle = {
    height: CONTAINER_HEIGHT,
    width: CONTAINER_WIDTH,
    borderWidth: CONTAINER_BORDER_WIDTH,
    borderRadius: CONTAINER_BORDER_RADIUS,
};

const DEFAULT_TOGGLE_SIZE_WEB = { width: 42, height: 23 };

export const TOGGLE_SIZE_WEB = {
    S: DEFAULT_TOGGLE_SIZE_WEB,
    M: DEFAULT_TOGGLE_SIZE_WEB,
    L: DEFAULT_TOGGLE_SIZE_WEB,
    XL: { width: 80, height: 44 },
};
