import React, { ReactElement } from 'react';
import View from './View';
import useViewModel from './viewModel';

const ReminderModal = (): ReactElement => {
    const viewModelProps = useViewModel();
    return <View {...viewModelProps} />;
};

export default ReminderModal;
