var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;var _react=_interopRequireWildcard(require("react"));
var _nxgSdkQuarks=require("@24i/nxg-sdk-quarks");
var _nxgSdkHiggs=require("@24i/nxg-sdk-higgs");
var _reactI18next=require("react-i18next");
var _withWebHeader=require("@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader");
var _index=_interopRequireDefault(require("@24i/nxg-sdk-smartott/src/screens/SettingsScreen/components/SettingsOptionPage/View/index.web"));

var _index2=require("../styles/index.web");var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/components/Settings/SettingsService/view/index.web.tsx";function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=typeof e&&"function"!=typeof e)return{default:e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n.default=e,t&&t.set(e,n),n;}

var ServiceScreen=function ServiceScreen(props){
var
selectedService=






props.selectedService,serviceOptions=props.serviceOptions,isBackstageLoginEnabled=props.isBackstageLoginEnabled,onLoginToBackstage=props.onLoginToBackstage,signedInWithBackstage=props.signedInWithBackstage,onSignOutOfBackstage=props.onSignOutOfBackstage,onSelectedOption=props.onSelectedOption;

var _useTranslation=(0,_reactI18next.useTranslation)(),t=_useTranslation.t;
var _useTheme=(0,_nxgSdkHiggs.useTheme)(),theme=_useTheme.theme;
var styles=(0,_react.useMemo)(function(){return(0,_index2.getStyles)(theme);},[theme]);

var renderOptions=function renderOptions(){return(
serviceOptions==null?void 0:serviceOptions.map(function(option){return(
_react.default.createElement(_nxgSdkQuarks.Interactable,{
testID:option.testID,
key:option.value,
onPress:function onPress(){return onSelectedOption(option.title);},
style:[styles.itemContainer],__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:27,columnNumber:13}},

_react.default.createElement(_nxgSdkQuarks.Text,{
style:[
styles.itemText,
option.title===(selectedService==null?void 0:selectedService.title)&&styles.activeItem],__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:33,columnNumber:17}},


option.title
)
));}
));};

return(
_react.default.createElement(_nxgSdkQuarks.ScrollView,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:45,columnNumber:9}},
_react.default.createElement(_nxgSdkQuarks.Text,{style:styles.screenDescription,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:46,columnNumber:13}},t('settings.service.description')),
_react.default.createElement(_nxgSdkQuarks.View,{style:styles.selectorContainer,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:47,columnNumber:13}},
_react.default.createElement(_index.default,{
description:t('common.selected')+" "+t(
'settings.service.title'
).toLocaleLowerCase(),
renderOptions:renderOptions,
subtitle:(selectedService==null?void 0:selectedService.title)||'',
optionalButtonProps:
isBackstageLoginEnabled?
{
title:signedInWithBackstage?
'Sign out of Backstage':
'Sign in with Backstage',
onPress:signedInWithBackstage?
onSignOutOfBackstage:
onLoginToBackstage,
additionalContainerStyles:styles.backstageButtonStyles
}:
undefined,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:48,columnNumber:17}}

)
)
));

};var _default=

(0,_withWebHeader.withWebHeader)(ServiceScreen);exports.default=_default;