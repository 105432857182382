import React from 'react';
import View from './View';
import { useViewModel } from './viewModel';
import { CreatePinScreenProps } from './types';

export default {
    Main: (props: CreatePinScreenProps) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
};
