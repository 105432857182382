import { BackstageUserDataClient } from '../UserDataClient';
import { BackstageAnalyticsDataClient } from '../AnalyticsDataClient';
import { BackstageAppSettingsDataClient } from '../AppSettingsDataClient';
import { BackstageAppStructureDataClient } from '../AppStructureDataClient';
import { ContentDataClient } from '../ContentDataClient';
import { BackstageEntitlementsClient } from '../EntitlementsClient';
import { BackstagePlayerDataClient } from '../PlayerDataClient';
import { BackstagePurchaseDataClient } from '../PurchaseDataClient';
import { BackstageThemeDataClient } from '../ThemeDataClient';
import { BackstageEpgDataClient } from '../EpgDataClient';
import { BackstagePrimetimeDataClient } from '../PrimetimeDataClient';

export interface DefaultBackstageClients {
    analyticsDataClient: BackstageAnalyticsDataClient;
    appSettingsDataClient: BackstageAppSettingsDataClient;
    appStructureDataClient: BackstageAppStructureDataClient;
    contentDataClient: ContentDataClient;
    entitlementsClient: BackstageEntitlementsClient;
    playerDataClient: BackstagePlayerDataClient;
    purchaseDataClient: BackstagePurchaseDataClient;
    themeDataClient: BackstageThemeDataClient;
    userDataClient: BackstageUserDataClient;
    epgDataClient: BackstageEpgDataClient;
    primetimeDataClient: BackstagePrimetimeDataClient;
}

export const isDefaultBackstageClients = (clients: unknown): clients is DefaultBackstageClients => {
    if (clients && typeof clients === 'object') {
        return (
            'analyticsDataClient' in clients &&
            'appSettingsDataClient' in clients &&
            'appStructureDataClient' in clients &&
            'contentDataClient' in clients &&
            'entitlementsClient' in clients &&
            'playerDataClient' in clients &&
            'purchaseDataClient' in clients &&
            'themeDataClient' in clients &&
            'userDataClient' in clients &&
            'primetimeDataClient' in clients
        );
    }
    return false;
};
