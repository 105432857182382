import { BLOCK_TV_FOCUS } from '@24i/nxg-core-utils/src/constants';
import { PageSection, Theme } from '@24i/nxg-sdk-photon';
import { TextInputRefType } from '@24i/nxg-sdk-quarks/src/components/TextInput/types';
import { TextStyleWeb, ViewStyleWeb } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import React, { ReactNode } from 'react';
import { NativeMethods, TextInputProps, TextStyle, ViewStyle } from 'react-native';
import { OnItemPress } from '../../../types';

export interface SearchTextInputStyles {
    inputContainer?: ViewStyle;
    textInputFocused?: ViewStyle;
    inputStyle: TextStyle;
    searchIcon?: TextStyle;
    cancelIcon?: TextStyle;
    textInput?: ViewStyle | TextStyleWeb;
    tvosFocusedInputHide?: ViewStyle;
    tvosHideSecondLayer?: ViewStyle;
    tvosFocusedInputText?: TextStyle;
    searchContainer?: ViewStyle;
    searchContainerFocused?: ViewStyle;
    clearIcon?: ViewStyleWeb;
}

export interface SearchTextInputPropsCommon extends TextInputProps, React.RefAttributes<unknown> {
    styles?: (theme: Theme) => SearchTextInputStyles;
    onPress: () => void;
    onSearchBarFocus?: () => void;
    onSearchBarBlur?: () => void;
    isSearchBarFocused?: boolean;
    inputRef: React.RefObject<TextInputRefType>;
}

export type SearchTextInputPropsGeneric = SearchTextInputPropsCommon;

export type SearchTextInputPropsMobile = SearchTextInputPropsCommon;

export interface SearchTextInputPropsTv extends SearchTextInputPropsCommon {
    nextFocusDown?:
        | React.RefObject<React.Component & NativeMethods>
        | typeof BLOCK_TV_FOCUS
        | undefined;
    nextFocusUp?: React.RefObject<React.Component & NativeMethods> | typeof BLOCK_TV_FOCUS;
}

export interface SearchTextInputPropsWeb extends SearchTextInputPropsCommon {
    autoFocus?: boolean;
    canSearch?: boolean;
    fixedGrid?: boolean;
    gridItemWidth?: number;
    initialData?: PageSection[];
    isLoading?: boolean;
    isSearchBarFocused?: boolean;
    items?: PageSection[];
    maxSearchQuery?: number;
    minSearchQuery?: number;
    onCancelPress: () => void;
    onDropdownItemPress?: () => void;
    onDropdownItemRemovePress?: () => void;
    onItemPress?: OnItemPress;
    overrideInitialDataLayout?: null | (() => ReactNode);
    pastSearches?: string[];
    query?: string;
    suggestedQueries?: string[];
}

export interface SearchTextInputProps
    extends SearchTextInputPropsGeneric,
        SearchTextInputPropsMobile,
        SearchTextInputPropsTv,
        SearchTextInputPropsWeb {}

export enum SEARCH_INPUT_TEST_IDS {
    SEARCH_INPUT = 'search_input',
    SEARCH_INPUT_X_BUTTON = 'search_input_x_button',
}
