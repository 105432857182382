import { extendGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ASSET_TYPE, BLOCKING_REASON_TYPES } from '../enums';
import { AssetGuard } from './asset';
import { SeasonGuard } from './season';

export const SeriesGuard = extendGuard(
    'Series',
    AssetGuard,
    {
        seasons: t.array(SeasonGuard),
        year: t.number,
    },
    {}
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSeries = (asset: any): asset is Series => {
    return asset && asset.type === ASSET_TYPE.SERIES;
};

export const isAnnouncedSeries = (asset: any): asset is Series => {
    return (
        isSeries(asset) &&
        (asset.blocked || []).some((block) => block.reason === BLOCKING_REASON_TYPES.BLOCKED_WINDOW)
    );
};

export type Series = t.TypeOf<typeof SeriesGuard>;
export type isAnnouncedSeries = Series;
