import { ViewStyleWeb } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import { ThemeIcon } from '@24i/nxg-sdk-quarks';
import React from 'react';
import { ThemeIconName } from '@24i/nxg-sdk-photon/src/models/theme';

type ArrowsVariant = 'profiles' | 'carousel';
type ArrowsIconData = {
    icons: {
        right: ThemeIconName;
        left: ThemeIconName;
    };
    size: {
        base: number;
        hover: number;
    };
};

const arrowIconsMap: Record<ArrowsVariant, ArrowsIconData> = {
    profiles: {
        icons: {
            right: 'onPrimary-back_rtl',
            left: 'onPrimary-subpage_rtl',
        },
        size: {
            base: 22,
            hover: 36,
        },
    },
    carousel: {
        icons: {
            right: 'onDark-back_rtl',
            left: 'onDark-back',
        },
        size: {
            base: 32,
            hover: 46,
        },
    },
};
interface ArrowProps {
    orientation: 'left' | 'right';
    isEnabled: boolean;
    isHovered?: boolean;
    outterWrapperStyle?: ViewStyleWeb;
    onClick?: () => void;
    variant: ArrowsVariant;
}

export const Arrow = ({
    orientation,
    isEnabled,
    isHovered,
    outterWrapperStyle,
    onClick,
    variant,
}: ArrowProps) => {
    const iconsData = arrowIconsMap[variant];

    return (
        <div
            role="button"
            style={{ ...outterWrapperStyle }}
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex={0}
        >
            <ThemeIcon
                iconName={orientation === 'left' ? iconsData.icons.left : iconsData.icons.right}
                size={isHovered ? iconsData.size.hover : iconsData.size.base}
                iconStyles={{ opacity: isEnabled && isHovered ? 1 : 0.6 }}
            />
        </div>
    );
};
