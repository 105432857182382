import { RECORDING_STATUS } from '@24i/nxg-sdk-photon/src';

export const getToastMessage = (duration = 0, count = 1, t, status?: string) => {
    const minutes = Math.floor(duration / 60000);
    const hours = Math.floor(minutes / 60);
    const text =
        status === RECORDING_STATUS.SCHEDULED
            ? t('recordings.cancel.success', { count })
            : t('recordings.delete.withDetails.success', { count });

    const durationText = duration ? t('asset.duration', { hours, minutes: minutes % 60 }) : '';

    return text + durationText;
};
