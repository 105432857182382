let DeviceInfo; // eslint-disable-line import/no-mutable-exports
// if (typeof navigator !== 'undefined') {
//     // DeviceInfo = require('react-native-device-info');
// } else {
DeviceInfo = {
    getUniqueId: () => 'Web',
    getDeviceType: () => 'Web',
    isTablet: () => false,
    getManufacturer: () => 'Web',
};
// }
export default DeviceInfo;
