import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { UseViewModelProps } from '../types';
import useShared from './useShared';

const useViewModel = (props): UseViewModelProps => {
    const router = useRouter();
    const { playlist } = (router as any)?.query;
    const shared = useShared(props, playlist);

    return {
        ...shared,
    };
};

export default useViewModel;
