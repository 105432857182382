import React from 'react';
import { SplashScreenModelProps } from './types';
import View from './View';
import { useViewModel } from './viewModel';

export const Main = (props: SplashScreenModelProps<unknown>): JSX.Element => {
    const viewModelProps = useViewModel(props);
    return <View {...props} {...viewModelProps} />;
};

export default Main;
