import React from 'react';
import { TopBarMenuDropdown } from '../../TopBarMenu';
import { ProfileDropdownViewProps } from './types';

export const ProfileDropdown = (props: ProfileDropdownViewProps) => {
    const { dropdownItems, handleItemClick, menuItem } = props;

    return (
        <TopBarMenuDropdown
            {...menuItem}
            title={menuItem.menuTitle}
            icon={menuItem.icon ?? ''}
            items={dropdownItems}
            onListItemPress={handleItemClick}
        />
    );
};

export default ProfileDropdown;
