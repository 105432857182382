import { ScaledSize } from 'react-native';

export enum ORIENTATIONS {
    LANDSCAPE = 'LANDSCAPE',
    PORTRAIT = 'PORTRAIT',
}

export interface DimensionsInfo extends ScaledSize {
    isLandscape?: boolean;
    orientation?: ORIENTATIONS;
    viewPortWidth?: number;
    viewPortHeight?: number;
}
