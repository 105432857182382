import { useEffect } from 'react';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { v4 as uuid } from 'uuid';

let sessionId = '';
let userId: number | string | null | undefined = '';

export const useSessionId = () => {
    const userDataClient = useUserData();
    const { data } = useQuery(QUERY_KEYS.user, userDataClient.fetchUser);
    useEffect(() => {
        if (data?.id !== userId) {
            sessionId = uuid();
            userId = data?.id;
        }
    }, [data?.id]);

    return { sessionId };
};
