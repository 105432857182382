// @ts-nocheck
import { isPlatformWeb } from 'renative';
import { parseXmlToMvpdArray } from '../api/utils';
import type { MVPD, MvpdLists, ResourceData, BackstageTierOneMVPD } from './types';

export const createTierOneMvpdList = (
    mvpds: MVPD[],
    backstageTierOneMvpds: BackstageTierOneMVPD[]
): MvpdLists => {
    const tierOneMvpds = mvpds.filter((mvpd) =>
        backstageTierOneMvpds
            .map((backstageTierOneMvpd) => backstageTierOneMvpd.id)
            .includes(mvpd.id)
    );
    return { tierOneMvpds, allMvpds: mvpds };
};

export const adobePrimetimeJSDevDebugLogger = (message: number): void => {
    const messages: { [key: number]: string } = {
        0: 'You have not provided config values required at runtime: software statement, requestor id, redirect URL or Backstage tier one mvpd list',
        1: 'Native SDK configuration phase failed',
        2: 'MVPDs could not be retrieved from Adobe servers',
        3: 'Tier 1 MVPDs could not be retrieved from Backstage config',
        4: 'User authenticated successfully',
        5: 'User logged out successfully',
        //  The MvpdSignInScreen viewModel skips renderState evauating to ERROR in the case of this being called after sign out.
        6: 'The authentication flow failed due to reasons such as network unavailability or the user canceled the authentication flow explicitly - can be ignored during sign out workflow.',
    };
    // eslint-disable-next-line no-console
    console.log('ADOBE PRIMETIME JS DEV DEBUG LOGGER ------------>', messages[message]);
};

export const createResourceId = ({ title, rating, id }: ResourceData, requestorId?: string) =>
    `<rss version="2.0" xmlns:media="http://search.yahoo.com/mrss/"><channel><title>${
        requestorId || ''
    }</title><item><title>${title}</title><media:rating scheme="urn:${rating.system}">${
        rating.label
    }</media:rating><guid>${id || 'null'}</guid></item></channel></rss>`;

export const replaceTierOneImageUrls = (
    sdkTierOneMvpds: MVPD[],
    backstageTierOneMvpds: BackstageTierOneMVPD[]
): MVPD[] => {
    const listWithReplacedImageUrls = sdkTierOneMvpds.map((mvpd) => {
        const mvpdToReplace = backstageTierOneMvpds.find(
            (tierOneMvpd) => tierOneMvpd.id === mvpd.id
        );
        return {
            ...mvpd,
            logoUrl: mvpdToReplace?.logoUrl || '',
        };
    });
    return listWithReplacedImageUrls;
};

export const parseMvpdsLists = (
    mvpds: string,
    backstageTierOneMvpds: BackstageTierOneMVPD[],
    // if set to false, JSX needs to be updated to render an Image, not an SVG
    useBackstageTierOneImageUrls = true
): MvpdLists | undefined => {
    const resolvedMvpds = isPlatformWeb ? parseXmlToMvpdArray(mvpds) : JSON.parse(mvpds);
    const mvpdLists = createTierOneMvpdList(resolvedMvpds, backstageTierOneMvpds);
    if (mvpdLists.allMvpds.length > 0 && mvpdLists.tierOneMvpds.length > 0) {
        const tierOneMvpds = useBackstageTierOneImageUrls
            ? replaceTierOneImageUrls(mvpdLists.tierOneMvpds, backstageTierOneMvpds)
            : mvpdLists.tierOneMvpds;
        return { tierOneMvpds, allMvpds: mvpdLists.allMvpds };
    }
    if (__DEV__) adobePrimetimeJSDevDebugLogger(2);
    return undefined;
};
