import React, { useLayoutEffect } from 'react';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, Text, Image } from '@24i/nxg-sdk-quarks';
import { ScrollView } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { FlatGrid } from 'react-native-super-grid';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { NEW_PROFILE_HANDLER } from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/constants';
import ManageProfileScreenHeader from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/components/ManageProfileScreenHeader';
import ProfileItem from '@24i/nxg-sdk-smartott/src/components/ProfileItem';
import { createManageableProfile } from '@24i/nxg-sdk-photon/src';
import {
    ManageProfilesViewProps,
    MANAGE_PROFILE_TEST_IDS,
} from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/types';
import getStyles from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/styles';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';

const ManageProfilesScreen = (props: ManageProfilesViewProps) => {
    const {
        editing,
        onItemPress,
        onEditPress,
        profiles,
        onBack,
        isLoading,
        allowModeSwitch,
        navigation,
        contentContainerDimensions,
        itemDimensions,
        spacing,
        shouldDisplayBackArrow,
    } = props;
    const { t } = useTranslation('sott');
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const styles = getStyles(theme, dimensions);
    const showTitle = isPlatformWeb;

    useLayoutEffect(() => {
        navigation?.setOptions({
            header: () => (
                <ManageProfileScreenHeader
                    editing={editing}
                    onBack={onBack}
                    onEditPress={onEditPress}
                    allowModeSwitch={allowModeSwitch}
                    shouldDisplayBackArrow={shouldDisplayBackArrow}
                />
            ),
        });
    }, [navigation, editing]);

    const renderActionButton = () => {
        const iconName = editing ? 'onButtonTertiary-confirm' : 'onButtonTertiary-edit';
        const title = editing ? t('common.done') : t('profiles.manage.title');
        const handlePress = editing && !allowModeSwitch ? onBack : onEditPress;
        const testId = editing ? MANAGE_PROFILE_TEST_IDS.DONE : MANAGE_PROFILE_TEST_IDS.MANAGE;
        return (
            <SubactionButton
                key={title}
                iconName={iconName}
                iconSize={30}
                title={title}
                additionalContainerStyles={styles.doneButton}
                onPress={handlePress}
                testID={testId}
            />
        );
    };

    return (
        <SafeAreaView style={styles.screenContainer}>
            <LoadingWrapper
                isLoading={isLoading || profiles?.length < 1}
                loaderProps={{
                    size: 'large',
                    color: theme.color.textPrimary,
                    holderStyles: styles.loader,
                }}
                wrapperStyle={styles.screenContainer}
            >
                <>
                    {isPlatformWeb && (
                        <Image
                            source={
                                typeof theme.logo === 'number' ? theme.logo : { uri: theme.logo }
                            }
                            style={styles.logo}
                            resizeMode="contain"
                        />
                    )}
                    {showTitle && (
                        <Text style={styles.title}>
                            {editing
                                ? t('profiles.manage.title')
                                : t('profiles.whoIsWatching.title')}
                        </Text>
                    )}
                    <ScrollView contentContainerStyle={styles.contentContainer}>
                        <FlatGrid
                            itemDimension={itemDimensions}
                            data={profiles}
                            spacing={spacing}
                            scrollEnabled
                            staticDimension={contentContainerDimensions}
                            contentContainerStyle={styles.flatGridContainer}
                            renderItem={({ item, index }) => {
                                const isNewProfileHandler = item.id === NEW_PROFILE_HANDLER.id;
                                const profile = createManageableProfile(item);
                                const editingIcon = editing ? 'onTileMainButton-edit' : undefined;
                                const icon = isNewProfileHandler ? 'onPrimary-add' : editingIcon;
                                const iconSize = isNewProfileHandler ? 40 : 30;

                                // TODO: - Type this
                                const profileItemProps = {
                                    index,
                                    adjustedDimensions: itemDimensions,
                                    isActive: !editing,
                                    noBorders: true,
                                    editing,
                                    isNewProfileHandler,
                                    name: profile.name,
                                    avatar: profile.image,
                                    onPress: () => onItemPress(profile),
                                    icon,
                                    iconSize,
                                };
                                // TODO: - ProfileItem to TS
                                return <ProfileItem {...profileItemProps} />;
                            }}
                        />
                        {isPlatformWeb && renderActionButton()}
                    </ScrollView>
                </>
            </LoadingWrapper>
        </SafeAreaView>
    );
};

export default overridable(ManageProfilesScreen, 'ManageProfilesScreen');
