import React from 'react';
import { SliderItemComponentProps } from '../../types';
import SliderItemView from './View';
import { useViewModel } from './viewModel';

const SliderItem = (props: Omit<SliderItemComponentProps, 'isObscured'>) => {
    const viewProps = useViewModel(props);
    return <SliderItemView {...viewProps} />;
};

export default SliderItem;
