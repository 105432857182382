import { BlockModalTypes } from '@24i/nxg-sdk-smartott/src/components/BlockedModal/types';
import { BLOCKING_REASON_TYPES } from '@24i/nxg-sdk-photon';

export const calculateEventOffset = (
    mockAssetCurrentTime?: number,
    mockAssetDuration?: number | null,
    realAssetDuration?: number | null
): number | undefined => {
    if (mockAssetCurrentTime && realAssetDuration && mockAssetDuration) {
        const percentage = mockAssetCurrentTime / mockAssetDuration;
        const offset = Math.floor(realAssetDuration * percentage);
        return offset;
    }
    return undefined;
};

export const handlePlaybackError = (
    errorMessage,
    openBlockedModal,
    setBlocker,
    openErrorModal,
    onClose
) => {
    if (errorMessage) {
        const errorCode = String(errorMessage.code);
        if (errorCode === 'E08') {
            const type = BlockModalTypes.CONCURRENCY;
            openBlockedModal(type, undefined, { onModalClose: onClose }, onClose);
        } else if (['E21', 'E25'].includes(errorCode)) {
            setBlocker(errorMessage.title as BLOCKING_REASON_TYPES);
        } else {
            openErrorModal(errorMessage.title, errorMessage.body, false);
        }
    }
};
