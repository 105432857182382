import React from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButtonWithoutBg';
import { View, Text } from '@24i/nxg-sdk-quarks';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { getStyles } from './styles';

type BaseProps = {
    title: string;
    description: string;
    mainButtonText: string;
    onMainButtonPress: () => void;
};

export type CenteredMessageProps =
    | (BaseProps & { secondaryButtonText?: never; onSecondaryButtonPress?: never })
    | (BaseProps & {
          secondaryButtonText: string;
          onSecondaryButtonPress: () => void;
      });

export const CenteredMessage = ({
    onMainButtonPress,
    title,
    description,
    mainButtonText,
    secondaryButtonText,
    onSecondaryButtonPress,
}: CenteredMessageProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.mainContainer}>
            <Text style={styles.headerText}>{title}</Text>

            <View style={styles.descriptionTextContainer}>
                <Text numberOfLines={30} style={styles.descriptionText}>
                    {description}
                </Text>
            </View>
            <ActionButton
                title={mainButtonText}
                onPress={onMainButtonPress}
                additionalContainerStyles={styles.mainButton}
            />
            {secondaryButtonText != null && (
                <TertiaryButtonWithoutBg
                    title={secondaryButtonText}
                    onPress={onSecondaryButtonPress}
                    additionalContainerStyles={styles.secondaryButton}
                />
            )}
        </View>
    );
};
