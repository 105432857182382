import React, { FC } from 'react';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { CleengPurchase } from '../../../CleengPurchase';
import { PurchaseSubscriptionScreenProps } from '../../../../../screens/PurchaseSubscriptionScreen/types';

const CleengPurchaseScreenView: FC<PurchaseSubscriptionScreenProps> = ({ query }) => {
    if (!query || !query.offerId) return null;
    const { offerId, referralAssetId, referralAssetType } = query;

    return (
        <CleengPurchase
            offerId={offerId}
            referralAssetId={referralAssetId}
            referralAssetType={referralAssetType}
        />
    );
};

export default withWebHeader(CleengPurchaseScreenView);
