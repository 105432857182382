import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { IconButtonWebStyles } from '../../IconButton/types';

const ActionButton = (theme: Theme): IconButtonWebStyles => ({
    holder: {
        width: 'auto',
        height: 54,
        borderRadius: 27,
        ...getBorderRadius(theme.elements, 'buttonSecondary-cornerRadius'),
        backgroundColor: theme.color.buttonSecondary,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginRight: 10,
        minWidth: 52,
        paddingLeft: 18,
        paddingRight: 27,
        '@media (max-width: 1200px)': {
            height: 52,
            borderRadius: 26,
            paddingLeft: 16,
            paddingRight: 24,
            ...getBorderRadius(theme.elements, 'buttonSecondary-cornerRadius'),
        },
        transition: 'opacity 0.15s ease-out',
        pressed: { opacity: 0.7 },
        hovered: { opacity: 0.8 },
    },
    text: {
        color: theme.color.textButtonSecondary,
        marginLeft: 10,
        ...getFont(theme, 'secondaryButton'),
    },
});
export default ActionButton;
