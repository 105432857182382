import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, VideoCompleteResult } from '../types';
import { EVENTS } from '../constants';
import { mapVideoProgress } from './mapVideoProgress';

export const mapVideoComplete = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): VideoCompleteResult => ({
    ...mapVideoProgress(triggerName, payload),
    event: EVENTS.VIDEO_COMPLETE,
});
