import React from 'react';

// TODO: this can be used in the future for ad player

// import { Text } from 'react-native';
// import { AdCounter, Duration } from '@24i/nxg-sdk-neutron/src/ui';

// const PlayerAdCounter = ({ styles, t }) => (
//     <AdCounter>
//         {(adPosition, adsCount) => (
//             <Text style={[styles.container, styles.text]}>
//                 {`${t('player.advertisement.title')} ${adPosition} / ${adsCount}`}
//                 {' ('}
//                 <Duration style={styles.text} format="mm:ss" />
//                 {')'}
//             </Text>
//         )}
//     </AdCounter>
// );

const PlayerAdCounter = () => <></>;

export default PlayerAdCounter;
