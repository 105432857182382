import { QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useQuery } from 'react-query';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { AnalyticsEvent } from '@24i/nxg-sdk-photon/src/analytics/base';
import { useSessionId } from '../useSessionId';

export const useCommonEventParams = (): AnalyticsEvent => {
    const userDataClient = useUserData();
    const { sessionId } = useSessionId();
    const { data: user } = useQuery(QUERY_KEYS.user, userDataClient.fetchUser);
    const timestamp = Date.now();
    const { selectedProfile } = useStore();

    return { user, profile: selectedProfile, timestamp, sessionId };
};
