import { logWarn } from '@24i/nxg-core-utils/src/logger';
import baseSchema from './schemaDefinition';
import styleSheetProp from './styleSheetProp';
import nullTheme from './nullTheme';

export const schemaFromDefaultStyles = (defaultStyles) => {
    const schema = {};
    Object.keys(defaultStyles).forEach((key) => {
        schema[key] = styleSheetProp;
    });
    return schema;
};

const schema = baseSchema;
let lastCheckedTheme = nullTheme;

const compareKeysValidity = (compared, pattern, path) => {
    let valid = true;
    Object.keys(pattern).forEach((key) => {
        const newPath = [...path, key];
        if (typeof compared[key] === 'undefined' || compared[key] === null) {
            logWarn(`Theme schema expected ${newPath.join('.')} to exist`);
            valid = false;
        } else if (typeof pattern[key] === 'string') {
            // eslint-disable-next-line valid-typeof
            const isEqualType = pattern[key] === typeof compared[key];
            if (!isEqualType) {
                logWarn(
                    `Theme schema expected ${newPath.join('.')} to be ${
                        pattern[key]
                    } but found ${typeof compared[key]}`
                );
            }
            valid = valid && isEqualType;
        } else if (typeof pattern[key] === 'object' && pattern[key] !== null) {
            valid = valid && compareKeysValidity(compared[key], pattern[key], newPath);
        }
    });
};

const checkAgainstSchema = (theme) => {
    // eslint-disable-next-line
    if (__DEV__) {
        compareKeysValidity(theme, schema, []);
        lastCheckedTheme = theme;
    }
};

const addToSchema = (key, toAdd) => {
    schema.components[key] = toAdd;
    if (lastCheckedTheme === nullTheme) return;
    checkAgainstSchema(lastCheckedTheme);
};

export { addToSchema, checkAgainstSchema };
