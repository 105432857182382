/* eslint-disable react/display-name */
import React from 'react';

import View from './View';
import { useViewModel } from './viewModel';

export { default as View, getModalPickerStyles } from './View';
export { useViewModel } from './viewModel';
export { default as ContentModal } from './components/ContentModal';
export { default as PickerItem } from './components/PickerItem';

const Main = (props) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
