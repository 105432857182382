import { useState, useEffect } from 'react';
import { useRoute } from '@24i/nxg-core-router';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { RouteProp } from '@react-navigation/native';
import { StackParamList } from '@24i/nxg-sdk-smartott';
import { StackNavigationProp } from '@react-navigation/stack';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import { useDeleteFromFavoritesMutation } from '@24i/nxg-sdk-smartott/src/hooks/query/favorites/useFavoritesMutation';
import { useStore } from '../../../context/ApplicationStore';
import useMyFavoritesQuery from '../../../hooks/query/favorites/useMyFavoritesQuery';
import useMyFavoritesPlaylistQuery from '../../../hooks/query/favorites/useMyFavoritesPlaylistQuery';
import { MyListModelProps, MyListViewProps } from '../types';

const useViewModel = (props: MyListModelProps): MyListViewProps => {
    const { t } = useTranslation();
    const route = useRoute<RouteProp<StackParamList, SOTT_DEFAULT_SCREENS.MY_LIST>>();
    const navigation = useNavigation<StackNavigationProp<StackParamList>>();
    const { selectedProfile } = useStore();
    const assetActions = useAssetActions();
    const { mutate: deleteFromFavorites } = useDeleteFromFavoritesMutation();

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const { data: userFavorites, refetch: refetchUserFavorites } = useMyFavoritesQuery();

    const {
        data: favoritesPlaylist,
        isFetching: favoritesFetching,
        isLoading: favoritesLoading,
        refetch: refetchFavoritesPlaylist,
    } = useMyFavoritesPlaylistQuery();

    useEffect(() => {
        refetchUserFavorites();
        refetchFavoritesPlaylist();
    }, [selectedProfile]);

    const isLoading = favoritesFetching || favoritesLoading;

    const handleBackButtonPress = (): void => navigation.goBack();

    const handleEditButtonPress = (): void => {
        setIsEditing((prevState) => !prevState);
    };

    const handlePackshotPress = async ({ asset, action }): Promise<void> => {
        if (!isEditing) {
            assetActions.handlePackshotPress({ asset, action });
        } else {
            const assetToDelete = userFavorites?.find((favorite) => favorite.entityId === asset.id);
            if (assetToDelete) {
                await deleteFromFavorites(assetToDelete.id, {
                    onSuccess: () => showToast(t('favorites.manage.delete.success')),
                    onError: () => showToast(t('favorites.manage.delete.failure')),
                });
            }
        }
    };

    return {
        data: favoritesPlaylist,
        actionIcon: isEditing ? 'delete' : undefined,
        emptyListTitle: t('favorites.manage.noData.title'),
        emptyListDescription: t('favorites.manage.noData.description'),
        backButtonTitle: `${t('common.backTo')} ${t('myContent.title')}`,
        fullWidth: isPlatformWeb,
        navigation,
        route,
        isEditing,
        isLoading,
        handleBackButtonPress,
        handleEditButtonPress,
        handlePackshotPress,
        topBarTitle: t('favorites.manage.title'),
        ...props,
    };
};

export default useViewModel;
