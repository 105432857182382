import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';
import { breakpoints } from '../../../../constants';
import { ViewStyleWithExtraProps } from '../../../../types';

type MiniPlayerContainerStyles = {
    container: ViewStyleWithExtraProps;
    divider: ViewStyle;
    leftContent: ViewStyle;
    rightContent: ViewStyle;
    centerContent?: ViewStyle;
    progressContent?: ViewStyle;
};

export const getStyles = (theme: Theme): MiniPlayerContainerStyles => ({
    container: {
        width: '100%',
        height: 96,
        minWidth: 580,
        paddingVertical: 15,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        flexDirection: 'row',
        columnGap: 20,
        backgroundColor: theme.color.darkerBlur1.color,
        color: theme.color.textPrimary,
        backdropFilter: 'blur(30px)',
        fontWeight: 600,
        [`@media (max-width: ${breakpoints.lg})`]: {
            columnGap: 16,
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            columnGap: 12,
        },
    },

    divider: {
        width: '100%',
        height: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.color.lighter2,
    },

    leftContent: {
        flex: 1,
        paddingLeft: '4%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [`@media (max-width: ${breakpoints.xl})`]: {
            paddingLeft: 48,
        },
        [`@media (max-width: ${breakpoints.lg})`]: {
            paddingLeft: 32,
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingLeft: 20,
        },
    },

    centerContent: {
        width: '40%',
        minWidth: 232,
        justifyContent: 'center',
        alignItems: 'center',
    },

    rightContent: {
        flex: 1,
        paddingRight: '4%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [`@media (max-width: ${breakpoints.xl})`]: {
            paddingRight: 48,
        },
        [`@media (max-width: ${breakpoints.lg})`]: {
            paddingRight: 32,
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingRight: 20,
        },
    },
});
