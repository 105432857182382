/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { SOTT_DEFAULT_WEB_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { log } from '@24i/nxg-core-utils/src/logger';
import { PinProtectionScopes, Profile } from '@24i/nxg-sdk-photon';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { usePinProtectedScreen } from '@24i/nxg-sdk-pin-protection/src/hooks/usePinProtectedScreen';
import { usePinModalWithProfile } from '@24i/nxg-sdk-pin-protection/src/hooks/usePinModalWithProfile';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useSwitchProfile } from '@24i/nxg-sdk-smartott/src/hooks/useSwitchProfile';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { NewProfileHandler } from '../../MoreScreen/components/ProfilePicker/types';
import {
    ManageProfilesScreenModes,
    GRID_MOBILE_BREAKPOINT,
    GRID_TABLET_BREAKPOINT,
    NEW_PROFILE_HANDLER,
    NUMBER_OF_PROFILES_TO_SCREEN_OVERFLOW,
    FLATGRID_DIMENSIONS_MOBILE_3_COL,
    FLATGRID_DIMENSIONS_MOBILE_2_COL,
    FLATGRID_DIMENSIONS_TABLET,
    FLATGRID_DIMENSIONS_LANDSCAPE,
} from '../constants';
import { useFirebase } from '../../../context/Firebase';
import { useStore } from '../../../context/ApplicationStore';
import { ManageProfilesModelProps, ManageProfilesViewProps, FlatGridDimension } from '../types';
import { useStartPageNavigation } from '../../../context/AppStartContext/hooks';

const useManageProfilesScreen = (props: ManageProfilesModelProps): ManageProfilesViewProps => {
    const mode = props.mode || ManageProfilesScreenModes.VIEW_AND_EDIT;
    const router = useRouter();
    const [isLoading, setLoading] = useState(false);
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const { selectedProfile, isEditing, setEditing } = useStore();
    const { recordError } = useFirebase();
    const userDataClient = useUserData();
    const profileLimit = useFeature('profileLimit');
    const maxNumberOfProfiles = useFeature('maxNumberOfProfiles');
    const { width } = useDimensions();
    const { navigateToStartPage } = useStartPageNavigation();
    const maximumNumberOfProfiles = profileLimit?.maxCount || maxNumberOfProfiles || Infinity;
    const allowModeSwitch = mode === ManageProfilesScreenModes.VIEW_AND_EDIT;
    const { triggerPinControl } = usePinProtectedScreen({
        scopes: [PinProtectionScopes.profileManagement],
        active: false,
        goBackAfterActivation: false,
        goBackAfterDismiss: !allowModeSwitch,
    });
    const { triggerPinModalWithProfile } = usePinModalWithProfile({
        goBackAfterActivation: false,
        goBackAfterDismiss: false,
    });
    const { switchProfileAsync } = useSwitchProfile();

    useEffect(() => {
        const triggerPin = async () => {
            await triggerPinControl({ triggerScopes: [PinProtectionScopes.profileManagement] });
            setEditing(true);
        };
        if (!allowModeSwitch) triggerPin();
    }, [allowModeSwitch]);

    const handleOnEditingPress = async (): Promise<void> => {
        try {
            if (!isEditing) {
                await triggerPinControl({ triggerScopes: [PinProtectionScopes.profileManagement] });
            }

            setEditing(!isEditing);
        } catch {
            // pin control rejected
        }
    };

    const loadUserProfiles = async (): Promise<void> => {
        try {
            setLoading(true);
            const userProfiles = await userDataClient.fetchUserProfiles();
            setProfiles(userProfiles);
            setLoading(false);
        } catch (err) {
            recordError(err);
            log(err);
        }
    };

    useEffect(() => {
        loadUserProfiles();
    }, []);

    useEffect(() => {
        if (selectedProfile && allowModeSwitch) navigateToStartPage();
    }, [selectedProfile]);

    useEffect(() => {
        if (mode === ManageProfilesScreenModes.EDIT_ONLY) {
            setEditing(true);
        }
    }, [mode]);

    // Send the information about new selected profile to the backend
    const selectProfile = async (newSelectedProfile: Profile): Promise<void> => {
        await switchProfileAsync(newSelectedProfile);
        setLoading(false);
    };

    const getFlatGridDimensions = (): FlatGridDimension => {
        const areProfilesOveflowing = profiles.length > NUMBER_OF_PROFILES_TO_SCREEN_OVERFLOW;
        if (width < GRID_MOBILE_BREAKPOINT && areProfilesOveflowing) {
            return FLATGRID_DIMENSIONS_MOBILE_3_COL;
        }
        if (width < GRID_MOBILE_BREAKPOINT) {
            return FLATGRID_DIMENSIONS_MOBILE_2_COL;
        }
        if (width < GRID_TABLET_BREAKPOINT) {
            return FLATGRID_DIMENSIONS_TABLET;
        }
        return FLATGRID_DIMENSIONS_LANDSCAPE;
    };

    // TODO: This is duplicated in the ProfilePicker, it should be used from a single place
    const redirectToNewProfileScreen = (): void => {
        const manageProfilesLink = SOTT_DEFAULT_WEB_SCREENS.CreateProfile.getLink();
        router.push({ pathname: manageProfilesLink.href });
    };

    const redirectToEditProfileScreen = (profile: Profile): void => {
        const link = SOTT_DEFAULT_WEB_SCREENS.ProfileEdit.getLink({
            profileId: profile.id,
        });
        router.push({
            pathname: link.href,
            query: { profileId: profile.id },
        });
    };

    const onItemPress = async (profile: Profile): Promise<void> => {
        const isNewProfileHandler = profile.id === 'NEW_PROFILE_HANDLER';

        if (isNewProfileHandler) {
            redirectToNewProfileScreen();
            return;
        }
        if (isEditing) {
            redirectToEditProfileScreen(profile);
            return;
        }

        try {
            if (profile?.isPinProtected) {
                await triggerPinModalWithProfile(profile);
            }

            selectProfile(profile);
        } catch {
            // pin control rejected
        }
    };

    const handleGoBack = (): void => {
        router.back();
    };

    const shouldDisplayBackArrow = false;

    const getUserProfiles = (): (Profile | NewProfileHandler)[] => {
        if (profiles.length < maximumNumberOfProfiles) {
            return [...profiles, NEW_PROFILE_HANDLER];
        }
        return profiles;
    };

    return {
        profiles: getUserProfiles(),
        editing: isEditing,
        isLoading,
        allowModeSwitch,
        shouldDisplayBackArrow,
        onBack: handleGoBack,
        onItemPress,
        onEditPress: handleOnEditingPress,
        onSelectPress: selectProfile,
        ...props,
        ...getFlatGridDimensions(),
        contentContainerDimensions: getFlatGridDimensions().contentContainerDimensions * width,
    };
};

export default useManageProfilesScreen;
