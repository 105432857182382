import { useTheme } from '@24i/nxg-sdk-higgs';
import { Linking } from 'react-native';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';

export const useOpenLinkWithInAppBrowser = () => {
    const { theme } = useTheme();

    const backgroundColor = theme.color.background1;
    const primaryOneColor = theme.color.primary1;
    const textPrimaryColor = theme.color.textPrimary;

    const settings = {
        // iOS Properties
        dismissButtonStyle: 'close',
        preferredBarTintColor: backgroundColor,
        preferredControlTintColor: textPrimaryColor,
        readerMode: false,
        animated: true,
        modalPresentationStyle: 'fullScreen',
        modalTransitionStyle: 'coverVertical',
        modalEnabled: true,
        enableBarCollapsing: false,
        // Android Properties
        showTitle: true,
        toolbarColor: backgroundColor,
        secondaryToolbarColor: primaryOneColor,
        navigationBarColor: backgroundColor,
        navigationBarDividerColor: textPrimaryColor,
        enableUrlBarHiding: true,
        enableDefaultShare: true,
        forceCloseOnRedirection: false,
        // Specify full animation resource identifier(package:anim/name)
        // or only resource name(in case of animation bundled with app).
        animations: {
            startEnter: 'slide_in_right',
            startExit: 'slide_out_left',
            endEnter: 'slide_in_left',
            endExit: 'slide_out_right',
        },
        // If needed, headers for the external link can be passed here. Include them as props if necessary
        // headers: {
        //
        // },
    };

    const openLinkWithInAppBrowser = async (url: string) => {
        try {
            await InAppBrowser.open(url, settings);
        } catch (error) {
            await Linking.openURL(url);
        }
    };

    return { openLinkWithInAppBrowser };
};
