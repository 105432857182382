import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { PlayerCloseResult, Payload } from '../types';
import { EVENTS } from '../constants';
import { mapBase } from './mapBase';

export const mapPlayerClose = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): PlayerCloseResult | undefined => {
    const progressPct = parseFloat(payload.payload.percentOfPlayback.toFixed(2));
    const assetDuration = payload.payload.duration / 1000 || payload.payload.asset.duration || 0;
    const duration = Math.round((assetDuration * progressPct) / 100);
    return {
        ...mapBase(payload),
        action: EVENTS.PLAYER_CLOSE,
        event_trigger: triggerName,
        thing_id: payload.payload.asset?.id,
        duration,
        progress_pct: progressPct,
    };
};
