import { ASSET_TYPE } from '@24i/nxg-sdk-photon/src';
import { useEffect, useRef, useState } from 'react';
import { DetailsProgressBarProps, DetailsProgressBarViewProps } from '../types';
import { getInitialProgress } from './utils';

const BROADCAST_TIMELINE_REFRESH_TIME_MS = 60 * 1000;

export const useViewModel = (props: DetailsProgressBarProps): DetailsProgressBarViewProps => {
    const { episodeToWatch, asset } = props;

    const [progress, setProgress] = useState(0);
    const progressTimer = useRef<NodeJS.Timer>();

    const isLive =
        asset.type === ASSET_TYPE.BROADCAST ? progress < 1 && !!asset?.isLive : !!asset?.isLive;

    const clearProgressTimer = () => {
        if (progressTimer.current) clearInterval(progressTimer.current);
        progressTimer.current = undefined;
    };

    useEffect(() => {
        const result = getInitialProgress(asset, episodeToWatch);
        setProgress(result);
    }, [asset.continueWatchingOffset, episodeToWatch?.continueWatchingOffset]);

    const getUpdatedProgress = (prevProgress: number): number => {
        if (!asset?.start || !asset?.end) {
            // Missing important data to calculate asset progress so canceling timer
            clearProgressTimer();
            return prevProgress;
        }

        const currentTime = Date.now();
        const durationMs = asset?.duration ? asset.duration * 60 * 1000 : asset.end - asset.start;
        const updatedProgressMs = prevProgress * durationMs + BROADCAST_TIMELINE_REFRESH_TIME_MS;
        const updatedProgressPercentage = updatedProgressMs / durationMs;

        if (currentTime > asset.end || updatedProgressPercentage > 1) return 1;

        return updatedProgressPercentage;
    };

    useEffect(() => {
        if (asset.type === ASSET_TYPE.BROADCAST) {
            progressTimer.current = setInterval(() => {
                setProgress(getUpdatedProgress);
            }, BROADCAST_TIMELINE_REFRESH_TIME_MS);
        }

        return () => {
            clearProgressTimer();
        };
    }, []);

    useEffect(() => {
        if (progress === 1) clearProgressTimer();
    }, [progress]);

    return { isLive, progress, ...props };
};
