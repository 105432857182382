export enum ANALYTICS_TRIGGERS {
    SCENE_VIEW = 'scene_view',
    PLAYBACK_START = 'playback_start',
    PLAYBACK_STOP = 'playback_stop',
    PLAYBACK_PAUSE = 'playback_pause',
    PLAYBACK_UNPAUSE = 'playback_unpause',
    PLAYER_OPEN = 'player_open',
    PLAYER_CLOSE = 'player_close',
    LOGIN = 'login',
    LOGOUT = 'logout',
    SCROLL_25 = 'scroll_25',
    SCROLL_50 = 'scroll_50',
    SCROLL_75 = 'scroll_75',
    SCROLL_90 = 'scroll_90',
    SCROLL_100 = 'scroll',
    SELECT_CONTENT = 'select_content',
    RATE_CONTENT = 'rate_content',
    PLAYBACK_10 = 'playback_10',
    PLAYBACK_25 = 'playback_25',
    PLAYBACK_50 = 'playback_50',
    PLAYBACK_75 = 'playback_75',
    PLAYBACK_90 = 'playback_90',
    HEARTBEAT_5 = 'heartbeat_5',
    PURCHASE_START = 'purchase_start',
    PURCHASE_CANCEL = 'purchase_cancel',
    PURCHASE_COMPLETE = 'purchase_complete',
    FAVORITE_ADD = 'favorite_add',
    FAVORITE_REMOVE = 'favorite_remove',
    SEARCH = 'search',
    SIGN_UP = 'sign_up',
    APP_CLOSE = 'app_close',
    PLAYBACK_BUFFERING_START = 'buffering_start',
    PLAYBACK_BUFFERING_FINISHED = 'buffering_stop',
    SCREEN_BUFFERING_START = 'buffering_start',
    SCREEN_BUFFERING_FINISHED = 'buffering_stop',
    AD_LOADED = 'ad_loaded',
    AD_SKIPPED = 'ad_skipped',
}

export enum ANALYTICS_PREFIXES {
    SCROLL = 'SCROLL_',
    PLAYBACK = 'PLAYBACK_',
}

export enum ANALYTICS_EVENTS {
    INSTALLED_APP = 'Installed app',
    LOADED_APP = 'Loaded App',
    LOGGED_IN = 'Logged In',
    LOGGED_OUT = 'Logged Out',
    CANCELLED_PURCHASE = 'Cancelled Purchase',
    PURCHASE_COMPLETE = 'Purchase Complete',
    HOME = 'home',
    DETAILS = 'details',
}
