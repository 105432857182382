import React from 'react';
import { DefaultCellModelProps } from './types';
import View from './View';
import { useViewModel } from './viewModel';

const Main = (props: DefaultCellModelProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
