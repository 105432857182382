import { useState, useEffect } from 'react';

import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { IDENTITY_PROVIDERS } from '@24i/nxg-core-utils/src/constants';

import { SharedViewModelReturnType } from './types';
import { AUTH_METHOD } from '../View/types';

const useShared = (): SharedViewModelReturnType => {
    const identityProvider = useFeature('identityProvider');
    const [authMethod, setAuthMethod] = useState(AUTH_METHOD.FORM);

    useEffect(() => {
        if (identityProvider?.enabled) {
            switch (identityProvider?.provider) {
                case IDENTITY_PROVIDERS.FLOWID:
                case IDENTITY_PROVIDERS.FLOWID_V2:
                    setAuthMethod(AUTH_METHOD.WEBVIEW);
                    break;
                case IDENTITY_PROVIDERS.GENERIC:
                    setAuthMethod(AUTH_METHOD.OPENAUTH);
                    break;
                default:
                    setAuthMethod(AUTH_METHOD.FORM);
                    break;
            }
        }
    }, [identityProvider]);

    return {
        authMethod,
    };
};

export default useShared;
