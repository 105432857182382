const Loader = (theme) => ({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.color.background1,
    },
    webImageBackground: {
        height: '100vh',
    },
    webIndicatorContainer: {
        backgroundColor: theme.color.background1,
        height: '100%',
    },
    text: {
        color: theme.color.textPrimary,
        marginTop: 24,
        fontSize: 24,
    },
});

export default Loader;
