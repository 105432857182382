import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ProductGuard } from './product';
import { TimelineEventGuard } from './timelineEvent';
import { SubtitleGuard } from './subtitles';

export const EditionGuard = createGuard(
    'Edition',
    {
        id: t.string,
        editionType: t.string,
        label: t.string,
        streams: t.array(
            t.type({
                type: t.string,
                drm: t.string,
            })
        ),
        subtitles: nullable(t.array(SubtitleGuard)),
    },
    {
        isLiveStream: t.boolean,
        productIds: t.array(t.UnknownRecord),
        price: nullable(t.unknown),
        timeline: nullable(t.array(TimelineEventGuard)),
        createdAt: t.unknown,
        updatedAt: t.unknown,
        livestreamId: nullable(t.unknown),
        blockedReasons: t.array(t.string),
        blocked: t.array(
            t.type({
                reason: t.string,
                resolvement: t.type({ data: t.type({ products: t.array(ProductGuard) }) }),
            })
        ),
    }
);

export type Edition = t.TypeOf<typeof EditionGuard>;
