import { useRef } from 'react';

// type definition like from useCallback
export default function useEvent<F extends (...args: any[]) => any>(fn: F): F {
    const latestFn = useRef<F>(fn);
    latestFn.current = fn;
    const stableRef = useRef<F>(null as any);
    if (!stableRef.current) {
        // @ts-ignore
        stableRef.current = (...args) => latestFn.current.apply(undefined, args);
    }
    return stableRef.current;
}
