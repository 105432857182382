import React from 'react';

import { DeleteAccountSuccessProps } from './types';
import View from './View';
import getDeleteAccountSuccessStyles from './styles';

const Main = (props: DeleteAccountSuccessProps) => {
    return <View {...props} />;
};

export default { Main, View, getDeleteAccountSuccessStyles };
