import { Text } from '@24i/nxg-sdk-quarks';
import { getFont, useTheme } from '@24i/nxg-sdk-higgs';
import React, { ReactNode } from 'react';

interface SectionTextProps {
    children: ReactNode;
}

export const SectionText = ({ children }: SectionTextProps) => {
    const { theme } = useTheme();

    return (
        <Text
            style={{
                opacity: 0.6,
                color: theme.color.textPrimary,
                ...getFont(theme, 'body2'),
            }}
        >
            {children}
        </Text>
    );
};
