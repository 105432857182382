import React from 'react';
import FirebaseContext from '../context';

const FirebaseProvider = ({ children }) => {
    const collectData = () => {};
    const logEvent = () => {};
    const recordError = () => {};
    const crash = () => {};
    const crashlyticsLog = () => {};

    return (
        <FirebaseContext.Provider
            value={{
                collectData,
                logEvent,
                recordError,
                crash,
                crashlyticsLog,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;
