import { FlatList } from '@24i/nxg-sdk-quarks';
import React, { ReactElement } from 'react';
import { View } from 'react-native';
import { MvpdGridProps } from '../types';
import { getMvpdGridStyles } from '../styles';
import { AllMvpdsItemContent } from '../../AllMvpdsItemContent';
import { TierOneMvpdItemContent } from '../../TierOneMvpdItemContent';

export function MvpdGrid(props: MvpdGridProps): ReactElement {
    const { mvpds, seeAllProviders, theme, handleSelectProvider, providerId } = props;
    const styles = getMvpdGridStyles(theme);
    const renderMvpdItem = ({ item, index }): ReactElement => {
        return (
            <View
                style={seeAllProviders ? styles.allMvpdsGridItem : styles.tierOneGridItem}
                key={`${item.id}${String(index)}`}
                // @ts-expect-error
                onClick={() => handleSelectProvider(item.id)}
            >
                {seeAllProviders ? (
                    <AllMvpdsItemContent mvpd={item} theme={theme} providerId={providerId} />
                ) : (
                    <TierOneMvpdItemContent mvpd={item} theme={theme} providerId={providerId} />
                )}
            </View>
        );
    };

    return (
        <View style={styles.mvpdGrid}>
            <FlatList
                nativeID="mvpdGrid"
                data={mvpds}
                key={seeAllProviders ? 'allMvpds' : 'tierTwoMvpds'}
                renderItem={renderMvpdItem}
                keyExtractor={(item) => item.key}
                numColumns={seeAllProviders ? 0 : 2}
                columnWrapperStyle={seeAllProviders ? undefined : styles.columnWrapperStyle}
            />
        </View>
    );
}
