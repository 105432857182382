import { TransactionStart, MonitoringDataClient } from '@24i/nxg-sdk-photon';
import { useRef } from 'react';

type TransactionHandle = {
    commit: (payload: any) => void;
};

export const useMonitoringTransactions = ({ monitors }: { monitors: MonitoringDataClient[] }) => {
    const transactions = useRef<Record<string, TransactionStart>>({}).current;
    const runningTransactionId = useRef<string>('');

    const commitMonitoringTransaction = (id: string, payload: any) => {
        const originalTransaction = transactions[id];
        if (!transactions[id]) return;
        monitors.forEach((currentClient) => {
            try {
                currentClient.commitTransaction?.(id, originalTransaction, {
                    payload,
                    end: Date.now(),
                });
            } catch (e) {
                // ignore
            }
        });
        delete transactions[id];
    };

    const beginMonitoringTransaction = (
        id: string,
        payload: any,
        belongsToRunningTransaction: boolean
    ): TransactionHandle | undefined => {
        const getRunningTransaction = () => transactions[runningTransactionId.current];
        if (!belongsToRunningTransaction) {
            if (getRunningTransaction()) return undefined;
            runningTransactionId.current = id;
        } else if (!getRunningTransaction()) {
            return undefined;
        }

        transactions[id] = {
            payload,
            start: Date.now(),
            belongsTo: belongsToRunningTransaction ? getRunningTransaction() : undefined,
        };
        monitors.forEach((currentClient) => {
            try {
                currentClient.beginTransaction?.(id, transactions[id]);
            } catch (e) {
                // ignore
            }
        });
        return {
            commit: (p) => commitMonitoringTransaction(id, p),
        };
    };

    return {
        beginMonitoringTransaction,
        commitMonitoringTransaction,
    };
};
