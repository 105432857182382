import canAutoplay from 'can-autoplay';
import { detect } from 'detect-browser';

const userBrowser = detect();

const prepareAutoPlay = async (): Promise<void> => {
    if (userBrowser && userBrowser.name === 'safari') {
        // Avoid ugly bug on safari ios where the player opens with native fullscreen controls
        return Promise.resolve();
    }
    return canAutoplay.video({ muted: false, timeout: 1000, inline: true });
};

export default prepareAutoPlay;
