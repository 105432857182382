import React, { ReactElement } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { isFactorTv } from 'renative';
import IconButton from '../../IconButton';
import getActionButtonStyles from '../styles';

import { IconButtonProps } from '../../IconButton/types';

const ActionButton = (props: IconButtonProps): ReactElement => {
    const { styles } = props;
    return (
        <IconButton
            styles={styles || getActionButtonStyles}
            activeOpacity={isFactorTv ? 1 : 0.5}
            {...props}
        />
    );
};

export { getActionButtonStyles };
export default overridable(ActionButton, 'ActionButton');
