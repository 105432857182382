import React from 'react';
import { GridWithLazyLoadingProps } from './type';
import View from './View';
import { useViewModel } from './viewModel';

const Main = (props: GridWithLazyLoadingProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
