import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { TV_SCREENS } from '@24i/nxg-sdk-smartott-tv/src/navigation/constants';

export const GOOGLE_ANALYTICS_TRIGGERS = [
    // App
    ANALYTICS_TRIGGERS.APP_CLOSE,

    // Scenes
    ANALYTICS_TRIGGERS.SCENE_VIEW,

    // Playback
    ANALYTICS_TRIGGERS.PLAYBACK_10,
    ANALYTICS_TRIGGERS.PLAYBACK_25,
    ANALYTICS_TRIGGERS.PLAYBACK_50,
    ANALYTICS_TRIGGERS.PLAYBACK_75,
    ANALYTICS_TRIGGERS.PLAYBACK_90,
    ANALYTICS_TRIGGERS.PLAYBACK_PAUSE,
    ANALYTICS_TRIGGERS.PLAYBACK_START,
    ANALYTICS_TRIGGERS.PLAYBACK_STOP,

    // Player
    ANALYTICS_TRIGGERS.PLAYER_CLOSE,
    ANALYTICS_TRIGGERS.PLAYER_OPEN,

    // Scrolling in scene
    ANALYTICS_TRIGGERS.SCROLL_25,
    ANALYTICS_TRIGGERS.SCROLL_50,
    ANALYTICS_TRIGGERS.SCROLL_75,
    ANALYTICS_TRIGGERS.SCROLL_90,
];

export enum EVENTS {
    SCREEN_VIEW = 'screen_view',
    VIDEO_PROGRES = 'video_progress',
    VIDEO_START = 'video_start',
    VIDEO_COMPLETE = 'video_complete',
    VIDEO_STOP = 'video_stop',
    VIDEO_PAUSE = 'video_pause',
    SCROLL = 'scroll',
    OPEN_PLAYER_SESSION = 'open_player_session',
    CLOSE_PLAYER_SESSION = 'close_player_session',
    SELECT_CONTENT = 'select_content',
    CLOSE_PROGRESS = 'close_progress',
}

export const TRIGGER_NAME_TO_GA_EVENTS = {
    [ANALYTICS_TRIGGERS.SCENE_VIEW]: [EVENTS.SCREEN_VIEW],
    [ANALYTICS_TRIGGERS.PLAYBACK_10]: [EVENTS.VIDEO_PROGRES],
    [ANALYTICS_TRIGGERS.PLAYBACK_25]: [EVENTS.VIDEO_PROGRES],
    [ANALYTICS_TRIGGERS.PLAYBACK_50]: [EVENTS.VIDEO_PROGRES],
    [ANALYTICS_TRIGGERS.PLAYBACK_75]: [EVENTS.VIDEO_PROGRES],
    [ANALYTICS_TRIGGERS.PLAYBACK_90]: [EVENTS.VIDEO_COMPLETE],
    [ANALYTICS_TRIGGERS.PLAYBACK_PAUSE]: [EVENTS.VIDEO_PAUSE],
    [ANALYTICS_TRIGGERS.PLAYBACK_STOP]: [EVENTS.VIDEO_STOP],
    [ANALYTICS_TRIGGERS.PLAYER_CLOSE]: [EVENTS.CLOSE_PLAYER_SESSION, EVENTS.CLOSE_PROGRESS],
    [ANALYTICS_TRIGGERS.PLAYER_OPEN]: [EVENTS.OPEN_PLAYER_SESSION],
    [ANALYTICS_TRIGGERS.SCROLL_25]: [EVENTS.SCROLL],
    [ANALYTICS_TRIGGERS.SCROLL_50]: [EVENTS.SCROLL],
    [ANALYTICS_TRIGGERS.SCROLL_75]: [EVENTS.SCROLL],
    [ANALYTICS_TRIGGERS.SCROLL_90]: [EVENTS.SCROLL],
    [ANALYTICS_TRIGGERS.PLAYBACK_START]: [EVENTS.VIDEO_START],
    [ANALYTICS_TRIGGERS.APP_CLOSE]: [EVENTS.CLOSE_PROGRESS, EVENTS.CLOSE_PLAYER_SESSION],
};

export const LOCALIZATION_KEYS = {
    [SOTT_DEFAULT_SCREENS.LANGUAGE]: 'analytics.documentTitle.selectLanguage',
    [TV_SCREENS.LANGUAGE]: 'analytics.documentTitle.selectLanguage',
    [SOTT_DEFAULT_SCREENS.LANDING]: 'analytics.documentTitle.landingPage',
    [TV_SCREENS.LANDING]: 'analytics.documentTitle.landingPage',
    [SOTT_DEFAULT_SCREENS.SIGN_IN]: 'auth.signIn.title',
    [TV_SCREENS.SIGN_IN]: 'auth.signIn.title',
    [SOTT_DEFAULT_SCREENS.SIGN_UP]: 'register.title',
    [TV_SCREENS.SIGN_UP]: 'register.title',
    [SOTT_DEFAULT_SCREENS.FORGOT_PASS]: 'password.forgot.title',
    [TV_SCREENS.FORGOT_PASS]: 'password.forgot.title',
    [SOTT_DEFAULT_SCREENS.PASSWORD_RESET]: 'password.change.title',
    [SOTT_DEFAULT_SCREENS.REMOVE_DEVICE]: 'error.E01.title',
    [SOTT_DEFAULT_SCREENS.ONBOARDING_INTRO]: 'onboarding.infoFirstTime.title',
    [SOTT_DEFAULT_SCREENS.ADDITIONAL_INFO]: 'onboarding.infoUpdate.title',
    [SOTT_DEFAULT_SCREENS.TERMS_CONDITIONS]: 'analytics.documentTitle.termsAndConditions',
    [TV_SCREENS.TERMS_AND_CONDITIONS]: 'analytics.documentTitle.termsAndConditions',
    [SOTT_DEFAULT_SCREENS.CREATE_PIN]: 'pin.add.title',
    [SOTT_DEFAULT_SCREENS.UPSERT_PROFILE]: 'profiles.add.createButton',
    [SOTT_DEFAULT_SCREENS.SIGN_OUT]: 'auth.signOut.title',
    [TV_SCREENS.SIGN_OUT]: 'auth.signOut.title',
    [SOTT_DEFAULT_SCREENS.ACCOUNT]: 'analytics.documentTitle.account',
    [SOTT_DEFAULT_SCREENS.ACCOUNT_DETAILS]: 'analytics.documentTitle.accountDetails',
    [TV_SCREENS.ACCOUNT_DETAILS]: 'analytics.documentTitle.accountDetails',
    [SOTT_DEFAULT_SCREENS.ACCOUNT_PARENTAL_CONTROLS]:
        'analytics.documentTitle.accountParentalControls',
    [SOTT_DEFAULT_SCREENS.ACCOUNT_SUBSCRIPTION]: 'analytics.documentTitle.accountSubscription',
    [SOTT_DEFAULT_SCREENS.ADD_SUBSCRIPTION]: 'analytics.documentTitle.addSubscription',
    [SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT]: 'analytics.documentTitle.deleteAccount',
    [TV_SCREENS.DELETE_ACCOUNT]: 'analytics.documentTitle.deleteAccount',
    [SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT_SUCCESS]: 'analytics.documentTitle.deleteAccountSuccess',
    [TV_SCREENS.DELETE_ACCOUNT_SUCCESS]: 'analytics.documentTitle.deleteAccountSuccess',
    [SOTT_DEFAULT_SCREENS.ENTER_PIN]: 'analytics.documentTitle.enterPin',
    [SOTT_DEFAULT_SCREENS.FALLBACK]: 'analytics.documentTitle.fallback',
    [TV_SCREENS.FALLBACK]: 'analytics.documentTitle.fallback',
    [SOTT_DEFAULT_SCREENS.GENRE]: 'analytics.documentTitle.genre',
    [TV_SCREENS.GENRE]: 'analytics.documentTitle.genre',
    [SOTT_DEFAULT_SCREENS.MANAGE_DEVICES]: 'analytics.documentTitle.manageDevices',
    [SOTT_DEFAULT_SCREENS.MANAGE_PROFILES]: 'analytics.documentTitle.manageProfiles',
    [TV_SCREENS.MODAL]: 'analytics.documentTitle.modal',
    [SOTT_DEFAULT_SCREENS.MORE]: 'analytics.documentTitle.more',
    [SOTT_DEFAULT_SCREENS.MVPD_SIGNIN]: 'analytics.documentTitle.mvpdSignIn',
    [SOTT_DEFAULT_SCREENS.MY_CONTENT]: 'analytics.documentTitle.myContent',
    [SOTT_DEFAULT_SCREENS.MY_LIST]: 'analytics.documentTitle.myList',
    [TV_SCREENS.MY_LIST]: 'analytics.documentTitle.myList',
    [SOTT_DEFAULT_SCREENS.MY_PURCHASES]: 'analytics.documentTitle.myPurchases',
    [TV_SCREENS.MY_PURCHASES]: 'analytics.documentTitle.myPurchases',
    [SOTT_DEFAULT_SCREENS.MY_SUBSCRIPTIONS]: 'analytics.documentTitle.mySubscription',
    [TV_SCREENS.MY_SUBSCRIPTIONS]: 'analytics.documentTitle.mySubscription',
    [SOTT_DEFAULT_SCREENS.PAGE]: 'analytics.documentTitle.page',
    [TV_SCREENS.PAGE]: 'analytics.documentTitle.page',
    [SOTT_DEFAULT_SCREENS.PAYMENT_HISTORY]: 'analytics.documentTitle.paymentHistory',
    [SOTT_DEFAULT_SCREENS.PAYMENT_METHOD]: 'analytics.documentTitle.paymentMethod',
    [SOTT_DEFAULT_SCREENS.PAYMENT_RESULT]: 'analytics.documentTitle.paymentResult',
    [SOTT_DEFAULT_SCREENS.PLAYLIST]: 'analytics.documentTitle.playlist',
    [TV_SCREENS.PLAYLIST]: 'analytics.documentTitle.playlist',
    [SOTT_DEFAULT_SCREENS.PURCHASE_SUBSCRIPTION]: 'analytics.documentTitle.purchaseSubscription',
    [SOTT_DEFAULT_SCREENS.RECENTLY_WATCHED]: 'analytics.documentTitle.recentlyWatched',
    [TV_SCREENS.RECENTLY_WATCHED]: 'analytics.documentTitle.recentlyWatched',
    [SOTT_DEFAULT_SCREENS.RECORDINGS]: 'analytics.documentTitle.recordings',
    [SOTT_DEFAULT_SCREENS.SEARCH]: 'analytics.documentTitle.search',
    [TV_SCREENS.SEARCH]: 'analytics.documentTitle.search',
    [SOTT_DEFAULT_SCREENS.SECOND_SCREEN_ACTIVATION]:
        'analytics.documentTitle.secondScreenActivation',
    [SOTT_DEFAULT_SCREENS.SERVICE]: 'analytics.documentTitle.service',
    [TV_SCREENS.SERVICE]: 'analytics.documentTitle.service',
    [SOTT_DEFAULT_SCREENS.SETTINGS]: 'analytics.documentTitle.settings',
    [TV_SCREENS.SETTINGS]: 'analytics.documentTitle.settings',
    [SOTT_DEFAULT_SCREENS.SIGN_IN_ADOBE_PRIMETIME]: 'analytics.documentTitle.signInAdobe',
    [TV_SCREENS.SIGN_IN_ADOBE_PRIMETIME]: 'analytics.documentTitle.signInAdobe',
    [SOTT_DEFAULT_SCREENS.SIGN_UP_QR]: 'analytics.documentTitle.signUpQr',
    [TV_SCREENS.SIGN_UP_QR]: 'analytics.documentTitle.signUpQr',
    [SOTT_DEFAULT_SCREENS.SIGN_UP_SUCCESS]: 'analytics.documentTitle.signUpSuccess',
    [TV_SCREENS.SIGN_UP_SUCCESS]: 'analytics.documentTitle.signUpSuccess',
    [SOTT_DEFAULT_SCREENS.STREAMING]: 'analytics.documentTitle.streaming',
    [SOTT_DEFAULT_SCREENS.SUBSCRIPTIONS_LIST]: 'analytics.documentTitle.subscriptionsList',
    [SOTT_DEFAULT_SCREENS.TECHNICAL_INFO]: 'analytics.documentTitle.technicalInfo',
    [TV_SCREENS.TECHNICAL_INFO]: 'analytics.documentTitle.technicalInfo',
    [SOTT_DEFAULT_SCREENS.TEXT_PAGE]: 'analytics.documentTitle.textPage',
    [TV_SCREENS.TEXT_PAGE]: 'analytics.documentTitle.textPage',
    [SOTT_DEFAULT_SCREENS.THEME]: 'analytics.documentTitle.theme',
    [TV_SCREENS.THEME]: 'analytics.documentTitle.theme',
    [SOTT_DEFAULT_SCREENS.TV_GUIDE]: 'analytics.documentTitle.tvGuide',
    [SOTT_DEFAULT_SCREENS.WELCOME]: 'analytics.documentTitle.welcome',
    [SOTT_DEFAULT_SCREENS.WHO_IS_WATCHING]: 'analytics.documentTitle.whoIsWatching',
};
