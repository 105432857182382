import React, { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { View } from '@24i/nxg-sdk-quarks';

export interface WithErrorProps {
    children: ReactNode;
    errorMessage: ReactNode;
    isErrorVisible: boolean;
    displayErrorUnder: boolean;
    innerWrapperStyles?: StyleProp<ViewStyle>;
}

export const WithError = ({
    children,
    errorMessage,
    isErrorVisible,
    displayErrorUnder,
    innerWrapperStyles,
}: WithErrorProps) => {
    return (
        <>
            {!displayErrorUnder && isErrorVisible && errorMessage}
            <View style={innerWrapperStyles}>{children}</View>
            {displayErrorUnder && isErrorVisible && errorMessage}
        </>
    );
};
