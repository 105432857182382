import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ColorValue } from 'react-native';
import { DeleteModalStyle } from './types';
import { MODAL_BREAKPOINT } from './constants';

const getDeleteModalStyles = (theme: Theme): DeleteModalStyle => {
    return {
        modalHeading: {
            textAlign: 'center',
            color: <ColorValue>theme.color.textPrimary,
            ...getFont(theme, 'h3'),

            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                ...getFont(theme, 'h1'),
            },
        },
        modalDescription: {
            textAlign: 'left',
            marginTop: 16,
            opacity: 0.6,
            color: <ColorValue>theme.color.textPrimary,
            ...getFont(theme, 'body1'),

            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                textAlign: 'center',
            },
        },
        modalContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            margin: 'auto',
            width: 'auto',
            height: 'auto',
            padding: 24,
            maxWidth: 528,
            backgroundColor: theme.color.primary1,
            ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),

            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                width: '100%',
                height: '100%',
                padding: 32,
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
                maxWidth: 'none',
            },
        },
        buttonsWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: 32,

            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                flexDirection: 'column',
                justifyContent: 'center',
                width: 'auto',
            },
        },
        cancelButton: {
            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                marginTop: 48,
            },
        },
        cancelButtonText: {
            color: <ColorValue>theme.color.textPrimary,
        },
        deleteButton: {
            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                marginTop: 32,
            },
        },
    };
};

export default getDeleteModalStyles;
