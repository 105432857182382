import React, { useCallback } from 'react';
import { isPlatformWeb } from 'renative';
import ExpandableText, {
    getExpandableTextStyles,
} from '@24i/nxg-sdk-gluons/src/components/text/ExpandableText';
import Packshot from '@24i/nxg-sdk-gluons/src/components/ui/Packshot';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useImage } from '@24i/nxg-sdk-gluons/src/context/ImageService';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import { getIsPodcastEpisode } from '@24i/nxg-sdk-photon';

import { getEpisodeTestID, DETAILS_SCREEN_TEST_IDS } from '../../../test-utils';
import { EpisodePickerItemProps } from '../types';
import { EpisodeSubtitle } from '../components/EpisodeSubtitle';
import { usePodcastPlayer } from '../../../../../context/PodcastPlayerProvider';
import { getPodcastEpisodeActionIcon } from '../../../../../utils/podcasts';

const EpisodePickerItem = (props: EpisodePickerItemProps) => {
    const { item, index, styles, handleEpisodePress, hideTitle, isSignedIn } = props;
    const { title, description, episodeNumber, continueWatchingOffset, duration } = item;
    const isPodcastEpisode = getIsPodcastEpisode(item);
    const shouldHidePlayIcon = !isSignedIn && isPodcastEpisode;
    const { getIsEpisodeInPlayer, playbackStatus, handlePodcastPlayerFromOutside } =
        usePodcastPlayer();
    const isEpisodeInPlayer = getIsEpisodeInPlayer(item.id);

    const { getImageUrl } = useImage();
    const { t } = useTranslation(['sott']);
    const { hasAdultBlocker } = useAssetPinControl({
        asset: item,
        active: false,
        goBackAfterDismiss: !isPodcastEpisode,
    });

    let imageSource = '';
    const imageWidth = isPlatformWeb ? 248 : 160;
    const imageHeight = isPlatformWeb ? 139 : 90;

    const imagePath = isPlatformWeb
        ? item.poster || item.heroPortrait || item.backgroundPortrait || item.still || ''
        : item.still || item.heroLandscape || item.background || '';

    imageSource = getImageUrl(
        {
            imagePath,
            width: imageWidth,
            height: imageHeight,
        },
        item?.images?.poster || item?.images?.still || item?.images?.background
    );

    const itemProgress =
        continueWatchingOffset && duration ? continueWatchingOffset / duration : undefined;

    const episodeNumberString = isPodcastEpisode ? '' : `${episodeNumber}. `;
    const itemTitle = hasAdultBlocker
        ? `${episodeNumberString}${t('asset.blocked.title')}`
        : `${episodeNumberString}${title}`;

    const getActionIcon = useCallback(() => {
        switch (true) {
            case shouldHidePlayIcon:
                return undefined;

            case isPodcastEpisode: {
                return getPodcastEpisodeActionIcon({ isEpisodeInPlayer, playbackStatus });
            }

            default:
                return 'play';
        }
    }, [isPodcastEpisode, shouldHidePlayIcon, isEpisodeInPlayer, playbackStatus]);

    const onPressHandler = () => {
        if (isPodcastEpisode && isEpisodeInPlayer) {
            handlePodcastPlayerFromOutside(item.id);
        } else {
            handleEpisodePress?.(item);
        }
    };

    return (
        <View style={styles?.episodePickerItem}>
            <View style={styles?.episodeHolder}>
                <View style={styles?.episodePackshotHolder}>
                    <Packshot
                        testID={getEpisodeTestID(DETAILS_SCREEN_TEST_IDS.TILE, {
                            scopeIndex: index + 1,
                        })}
                        onPress={(event) => {
                            if (event && 'preventDefault' in event) event.preventDefault();
                            onPressHandler();
                        }}
                        item={item}
                        image={imageSource}
                        progress={itemProgress}
                        actionIcon={getActionIcon()}
                        packshotWidth={imageWidth}
                        packshotHeight={imageHeight}
                        title=""
                        hideTitle={hideTitle || hasAdultBlocker}
                    />
                </View>
                <View style={styles?.episodeTitleContainer}>
                    <Text
                        testID={getEpisodeTestID(DETAILS_SCREEN_TEST_IDS.EPISODE_TITLE, {
                            scopeIndex: index + 1,
                        })}
                        style={styles?.episodeTitleText}
                        numberOfLines={2}
                    >
                        {itemTitle}
                    </Text>

                    <EpisodeSubtitle item={item} styles={styles} />
                </View>
            </View>
            {!hasAdultBlocker && styles && (
                <View style={styles.textWrapper}>
                    <ExpandableText
                        testID={getEpisodeTestID(DETAILS_SCREEN_TEST_IDS.DESCRIPTION, {
                            scopeIndex: index + 1,
                        })}
                        styles={(stylesTheme) => ({
                            ...getExpandableTextStyles(stylesTheme),
                            description: styles.episodeDescriptionText,
                        })}
                        text={description || ''}
                        numberOfLines={3}
                        fontName="body2"
                    />
                </View>
            )}
        </View>
    );
};

export default overridable(EpisodePickerItem, 'EpisodePickerItem');
