// This rule makes no sense
/* eslint-disable no-restricted-properties */
import getDefaultActionButtonStyles from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton/styles';
import getDefaultSubactionButtonStyles from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton/styles';
import getDefaultTertiaryButtonStyles from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton/styles';
import { getFont, Ratio } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DimensionsInfo } from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions/types';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { isPlatformWeb, isFactorMobile } from 'renative';
import { LandingScreenStyles, LogoSizingOptions } from './types';

export type LandingScreenViewStylesGetter = typeof getLandingScreenViewStyles;

export const getLandingScreenViewStyles = (
    theme: Theme,
    {
        dimensions,
        logoSizingOptions,
    }: { dimensions: DimensionsInfo; logoSizingOptions: LogoSizingOptions }
): LandingScreenStyles => {
    const defaultButtonStyles = getDefaultActionButtonStyles(theme);
    const defaultSubactionButtonStyles = getDefaultSubactionButtonStyles(theme);
    const defaultTertiaryButtonStyles = getDefaultTertiaryButtonStyles(theme);

    const { width, height } = dimensions;

    const whiteSpacePixels =
        (logoSizingOptions.logoImageHeight - logoSizingOptions.logoContentHeight) / 2;
    const whitespaceToContentRatio = whiteSpacePixels / logoSizingOptions.logoImageHeight;

    // All magic numbers are taken from the Zeplin designs
    let desiredLogoHeight = 130;
    let signInButtonMargin = 32;
    let textSectionMargin = 57;
    let headlineTextMargin = 15;
    let registerButtonMargin = 24;
    let orTextMargin = 24;
    let verticalTopOffset = 0;
    const spacingBetweenLogoAndContent = { max: 190, min: 50 };

    const aspectRatio = width / height;

    let buttonWidth = Ratio(400);

    const isTabletSize = width < Breakpoint.XL || aspectRatio > 1.6;

    if (isTabletSize) {
        headlineTextMargin = Ratio(16);
        textSectionMargin = Ratio(48);
        signInButtonMargin = Ratio(24);
        registerButtonMargin = Ratio(16);
        orTextMargin = Ratio(16);
        buttonWidth = Ratio(320);
        desiredLogoHeight = Ratio(110);
        verticalTopOffset = Ratio(80);
        spacingBetweenLogoAndContent.max = Ratio(110);
    }

    if (width < Breakpoint.SM) {
        desiredLogoHeight = Ratio(95);
        verticalTopOffset = Ratio(40);
        spacingBetweenLogoAndContent.max = Ratio(150);
    }

    // Edge case for tables when aspectRatio is equal and higher then 1.6
    if (width < Breakpoint.L && aspectRatio >= 1.6) {
        verticalTopOffset = Ratio(1);
        desiredLogoHeight = Ratio(80);
        signInButtonMargin = Ratio(20);
        spacingBetweenLogoAndContent.max = Ratio(1);
    }

    const logoMaxHeight = desiredLogoHeight + desiredLogoHeight * whitespaceToContentRatio * 2;
    verticalTopOffset -= desiredLogoHeight * whitespaceToContentRatio;
    spacingBetweenLogoAndContent.max -= desiredLogoHeight * whitespaceToContentRatio;

    return {
        backgroundWrapper: {
            minHeight: isPlatformWeb ? '100vh' : '100%',
        },
        backgroundImageStyle: {
            opacity: 1,
        },
        scrollView: {
            height: '100%',
        },
        scrollViewContent: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        contentWrapper: {
            padding: Ratio(32),
            flexGrow: 1,
            marginTop: verticalTopOffset,
        },
        logoSection: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            marginTop: isFactorMobile ? 68 : 174,
        },
        logoImage: {
            width: logoSizingOptions.logoImageWidth,
            maxWidth: '100%',
            height: logoSizingOptions.logoImageHeight,
            maxHeight: logoMaxHeight,
            position: 'relative',
        },
        logoText: {
            ...getFont(theme, 'h1'),
            color: theme.color.textPrimary,
        },
        spacer: {
            flexGrow: 1,
            maxHeight: spacingBetweenLogoAndContent.max,
            minHeight: spacingBetweenLogoAndContent.min,
        },
        mainContentSection: {},
        textSection: {
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginBottom: textSectionMargin,
        },
        headlineText: {
            color: theme.color.textPrimary,
            textAlign: 'center',
            ...getFont(theme, 'h2'),
            marginBottom: headlineTextMargin,
        },
        subtitle: {
            color: theme.color.textPrimary,
            textAlign: 'center',
            marginHorizontal: 16,
            opacity: 0.6,
            ...getFont(theme, 'body2'),
        },
        buttonSection: {
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
        signInButton: {
            ...defaultButtonStyles,
            holder: {
                ...defaultButtonStyles.holder,
                marginRight: 0,
                width: '100%',
                maxWidth: buttonWidth,
                marginBottom: signInButtonMargin,
            },
        },
        registerButton: {
            ...defaultSubactionButtonStyles,
            holder: {
                ...defaultSubactionButtonStyles.holder,
                marginRight: 0,
                width: '100%',
                maxWidth: buttonWidth,
                marginBottom: registerButtonMargin,
            },
        },
        orText: {
            color: theme.color.textPrimary,
            marginBottom: orTextMargin,
            ...getFont(theme, 'body2'),
        },
        lookAroundButton: {
            ...defaultTertiaryButtonStyles,
            holder: {
                ...defaultTertiaryButtonStyles.holder,
                marginRight: 0,
                minWidth: Ratio(180),
            },
        },
    };
};
