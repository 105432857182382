import { PRODUCT_SCREENS } from '@24i/nxg-sdk-photon';
import {
    AppNavigationClientContext,
    NavScreenConfig,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { SOTT_DEFAULT_SCREENS, SOTT_HOME_FALLBACK_REFERENCE } from '../../constants';
import { SottNavBuilderScreens } from '../navConfigBuilder/types';
import { SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS } from '../screenConfigBuilders';
import {
    ScreenBuilderMap,
    ScreenConfBuilderContext,
    ScreenConfigBuilder,
    SottNavScreenConfNative,
    SottNavScreenConfs,
    SottNavScreenConfWeb,
    SottScreenBuilderNames,
    SottScreenConfigBuilders,
} from '../types';
import { isDisableResult } from '../config-builders-lib';

export type SottStructureGetter<
    TRvalue extends SottNavBuilderScreens,
    TInScreens extends NavScreenConfig
> = (
    dynamicScreens: TInScreens[],
    screenBuilders: SottScreenConfigBuilders,
    context: AppNavigationClientContext
) => TRvalue;

/**
 * Currently SOTT default navigation client has no designated/default "home" page.
 * It expects one of the screens received from the AppStructureProvider to be considered a home page
 * This function determines which of the screens provided will be designated as the home screen
 */
export const addHomeScreenFallback = (
    menuScreens: (SottNavScreenConfNative | SottNavScreenConfWeb)[],
    screenBuilders: SottScreenConfigBuilders,
    context: ScreenConfBuilderContext
): (SottNavScreenConfNative | SottNavScreenConfWeb)[] => {
    const homeScreenIndex = menuScreens.findIndex(
        (screen) => screen.correspondingProductScreen === PRODUCT_SCREENS.HOME
    );

    if (homeScreenIndex < 0) {
        const fallbackHome = screenBuilders.buildHomeScreen(
            {
                name: SOTT_DEFAULT_SCREENS.HOME,
                title: 'Home',
                reference: SOTT_HOME_FALLBACK_REFERENCE,
                embeddedScreens: [],
                icon: 'dashboard',
                screenTypeToUse: PRODUCT_SCREENS.HOME,
            },
            context
        );

        if (isDisableResult(fallbackHome)) {
            throw new Error(
                `The fallback home screen could not be built! The screen builder has requested to be disabled!`
            );
        }

        return [
            {
                ...fallbackHome,
                // The builders is returning a little bit different object that we expect here
                placementNative: fallbackHome.placementNative.static ?? [],
                placementWeb: fallbackHome.placementWeb.static ?? [],
            } as SottNavScreenConfNative,
            ...menuScreens,
        ];
    }

    return menuScreens;
};

export const areAllRequiredScreenBuildersPresent = (
    screenBuilderMap: Record<string, ScreenConfigBuilder>
): screenBuilderMap is ScreenBuilderMap<SottScreenBuilderNames> => {
    // This would be even more robust if we were also checking the values of the map!
    return Object.keys(SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS).every((key) =>
        Object.keys(screenBuilderMap).includes(key)
    );
};

export const buildScreensFromAllScreenBuilders = (
    screenBuilderMap: Record<string, ScreenConfigBuilder>,
    context: ScreenConfBuilderContext
): SottNavScreenConfs[] => {
    if (!areAllRequiredScreenBuildersPresent(screenBuilderMap)) {
        throw new Error(
            `Some of the required screen builders are missing from the screen builder map provided to the sott static screens creation function. Make sure you are not using and type assertions and correct TS types (no "any string" maps), TS typings are in place that should catch this issue!`
        );
    }

    // Do not remove this middle step, it is here to typeguard the screens
    const screens: (SottNavScreenConfs | null)[] = Object.values(screenBuilderMap).map(
        (screenBuilder) => {
            const builtScreen = screenBuilder(null, context);

            // The screen has requested not to be added to the screens array
            if (isDisableResult(builtScreen)) return null;

            return {
                ...builtScreen,
                // We are picking the static placement here as these screens are not parameterized
                // This is mainly done for you convenience
                placementNative: builtScreen.placementNative.static || [],
                placementWeb: builtScreen.placementWeb.static || [],
            };
        }
    );

    return screens.filter(Boolean) as SottNavScreenConfs[];
};

export const removeDuplicates = <T extends { name: string }>(
    firstScreens: T[],
    secondScreens: T[]
) => {
    const index = secondScreens.map((screen) => screen.name);
    return firstScreens.filter((screen) => !index.includes(screen.name));
};
