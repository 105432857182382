import { useState } from 'react';
import { useStartPageNavigation } from '@24i/nxg-sdk-smartott/src/context/AppStartContext/hooks';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useAppSettingsData } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';

type UseOnMaybeLaterPressProps = {
    onMaybeLaterPress: () => Promise<void>;
    isLoading: boolean;
};

export const useOnMaybeLaterPress = (): UseOnMaybeLaterPressProps => {
    const { navigateToStartPage } = useStartPageNavigation();
    const userDataClient = useUserData();
    const appSettings = useAppSettingsData();
    const [isLoading, setIsLoading] = useState(false);

    const onMaybeLaterPress = async (): Promise<void> => {
        try {
            setIsLoading(true);
            const user = await userDataClient.fetchUser();
            if (user?.id) await appSettings.client.setShowAddSubscription(user.id);
            navigateToStartPage();
        } catch (err) {
            log(err);
        }
    };

    return {
        onMaybeLaterPress,
        isLoading,
    };
};
