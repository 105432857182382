import React, { ComponentType } from 'react';
import { WithBlockedModalProps } from '../../types';
import useBlockedModal from '../../hooks/useBlockedModal';

const withBlockedModal =
    <P extends WithBlockedModalProps>(Component: ComponentType<P>) =>
    // eslint-disable-next-line react/display-name
    (props: Omit<P, keyof WithBlockedModalProps>) => {
        const { openBlockedModal } = useBlockedModal();

        return <Component {...(props as P)} openBlockedModal={openBlockedModal} />;
    };

export default withBlockedModal;
