import React from 'react';
import { PRODUCT_SCREENS } from '@24i/nxg-sdk-photon';
import {
    addHomeScreenFallback,
    SottStructureGetter,
    buildScreensFromAllScreenBuilders,
    removeDuplicates,
} from './shared';
import { SottNavBuilderScreensWeb } from '../navConfigBuilder/web/types';
import { PlacementWeb, SottNavScreenConfWeb } from '../types';
import { GUEST_DROPDOWN, PROFILE_DROPDOWN, SOTT_DEFAULT_SCREENS } from '../../constants';
import ProfileDropdown from '../../components/ProfileDropdown/index.web';
import GuestDropdown from '../../components/GuestDropdown/index.web';

const getUserDrodownScreens = (
    staticScreens: SottNavScreenConfWeb[],
    dynamicScreens: SottNavScreenConfWeb[],
    adobePrimetimeEnabled?: boolean
) => {
    const filterUserDropdown = (configs: SottNavScreenConfWeb[]) =>
        configs.filter((screen) => screen.placementWeb.includes(PlacementWeb.PROFILE_DROPDOWN));

    const dynamicProfileDropdownScreens = filterUserDropdown(dynamicScreens);
    const staticProfileDropdownScreens = removeDuplicates(
        filterUserDropdown(staticScreens),
        dynamicProfileDropdownScreens
    );

    if (adobePrimetimeEnabled) {
        // If adobe is enabled we want to add the signout modal/screen also for user not signed in with our app
        const signOutScreen = staticScreens.filter((s) => s.name.includes('sign-out'));
        const mvpdSigninScreen = staticScreens.filter((s) => s.name.includes('mvpd-signin'));

        return [
            ...staticProfileDropdownScreens,
            ...dynamicProfileDropdownScreens,
            ...removeDuplicates(signOutScreen, staticProfileDropdownScreens),
            ...removeDuplicates(mvpdSigninScreen, staticProfileDropdownScreens),
        ];
    }

    return [...staticProfileDropdownScreens, ...dynamicProfileDropdownScreens];
};

export const getSottScreensAndStructureWeb: SottStructureGetter<
    SottNavBuilderScreensWeb,
    SottNavScreenConfWeb
> = (menu, screenBuilders, context) => {
    const { user, selectedProfile, profiles, features } = context;
    const staticScreens = buildScreensFromAllScreenBuilders(screenBuilders, context);
    const adobePrimetimeEnabled = features?.adobePrimetime?.enabled;

    const moreScreen = staticScreens.find((screen) => screen.name === SOTT_DEFAULT_SCREENS.MORE);

    if (!moreScreen) {
        throw new Error(
            `Some required screens were not built by the screen builders! The sott screen builder requires the following screens to be truthy: ${JSON.stringify(
                { moreScreen: Boolean(moreScreen) }
            )}`
        );
    }

    const profileSwitchingEnabled = features?.accounts?.select_profiles;
    const onlyUnselectedProfiles =
        profiles?.filter((profile) => profile.id !== selectedProfile?.id) ?? [];
    const profilesToAddToDropdown = profileSwitchingEnabled ? onlyUnselectedProfiles : [];

    const menuWithHome = addHomeScreenFallback(
        menu,
        screenBuilders,
        context
    ) as SottNavScreenConfWeb[];

    const userDropdown: SottNavScreenConfWeb = {
        name: user ? PROFILE_DROPDOWN : GUEST_DROPDOWN,
        menuTitle: (user && selectedProfile?.name) || '',
        icon: (user && selectedProfile?.image) || 'account',
        // eslint-disable-next-line react/display-name
        renderWebLink: ({ menuItem }) => {
            if (user)
                return (
                    <ProfileDropdown
                        key={menuItem.menuTitle}
                        menuItem={menuItem}
                        profiles={profilesToAddToDropdown}
                        screens={getUserDrodownScreens(
                            staticScreens,
                            menuWithHome,
                            adobePrimetimeEnabled
                        )}
                    />
                );
            return (
                <GuestDropdown
                    key={menuItem.menuTitle}
                    menuItem={menuItem}
                    screens={getUserDrodownScreens(
                        staticScreens,
                        menuWithHome,
                        adobePrimetimeEnabled
                    )}
                />
            );
        },
        correspondingProductScreen: PRODUCT_SCREENS.EXTERNAL,
        placementWeb: [PlacementWeb.TOP_BAR_RIGHT],
        // No route to match, only dropdown
        matchesWebRoute: () => false,
    };

    // Combining menu with localScreens (local items) to define allNavigationItems
    // allNavigationItems is needed for the WEB to determine which navigation item represents which page in the app
    // It's needed, for example, when application needs to decide if navbar or footer should be shown in that particular page or not (by checking navigation item `options` property)
    const allNavigationItems: SottNavScreenConfWeb[] = [
        ...menuWithHome,
        ...staticScreens,
        { ...userDropdown },
    ];

    const menuRight = allNavigationItems.filter((screen) =>
        screen.placementWeb?.includes(PlacementWeb.TOP_BAR_RIGHT)
    );

    const menuLeft = allNavigationItems.filter((screen) =>
        screen.placementWeb?.includes(PlacementWeb.TOP_BAR_LEFT)
    );

    const bottomBarMenu = allNavigationItems.filter((screen) =>
        screen.placementWeb?.includes(PlacementWeb.BOTTOM_BAR)
    );

    const footer = allNavigationItems.filter((screen) =>
        screen.placementWeb?.includes(PlacementWeb.FOOTER)
    );

    return {
        allNavigationItems,
        bottomBarMenu,
        footer,
        topBarMenu: {
            menuLeft,
            menuRight,
        },
        moreScreen,
    };
};
