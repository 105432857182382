import { useQuery, UseQueryOptions } from 'react-query';
import { Asset, QUERY_KEYS, Rating, RATING_SYSTEM } from '@24i/nxg-sdk-photon';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useAppConfigQuery } from '../useAppConfigQuery';
import { useStore } from '../../../context/ApplicationStore';

const useAssetRatingQuery = (asset?: Asset, options?: UseQueryOptions<Rating | undefined>) => {
    const isOptionEnabled = options?.enabled !== undefined ? options.enabled : true;
    const { getAssetRatingForUser } = useUserData();
    const { appSettings } = useAppConfigQuery();
    const { userData } = useStore();
    const ratingSystem = appSettings?.features.ratings?.system;

    return useQuery<Rating | undefined>(
        [QUERY_KEYS.assets, QUERY_KEYS.assetRating, asset?.id],
        () => asset && getAssetRatingForUser(asset, ratingSystem || RATING_SYSTEM.FIVESTARS),
        { ...options, enabled: !!asset?.id && isOptionEnabled && !!userData }
    );
};

export default useAssetRatingQuery;
