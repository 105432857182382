import { Ratio } from '@24i/nxg-sdk-higgs';
import { Breakpoint } from '../../utils/styles/constants';

export const NEW_PROFILE_HANDLER = {
    id: 'NEW_PROFILE_HANDLER',
    icon: {
        font: 'antDesign',
        name: 'plus',
        size: Ratio(20),
        color: '#b4b4b4',
    },
};

export const EDIT_PROFILE_ICON_SIZE = Ratio(45);

export const GRID_MOBILE_BREAKPOINT = Breakpoint.MD;

export const GRID_TABLET_BREAKPOINT = Breakpoint.L;

export const GRID_EXTRA_LARGE_BREAKPOINT = Breakpoint.XXL;

export enum ManageProfilesScreenModes {
    VIEW_AND_EDIT,
    EDIT_ONLY,
}

export const NUMBER_OF_PROFILES_TO_SCREEN_OVERFLOW = 7;

export const FLATGRID_DIMENSIONS_ANDROIDTV = {
    contentContainerDimensions: 0.7,
    spacing: Ratio(30),
    itemDimensions: 190,
};

export const FLATGRID_DIMENSIONS_APPLETV = {
    contentContainerDimensions: 0.7,
    spacing: Ratio(90),
    itemDimensions: 190,
};

export const FLATGRID_DIMENSIONS_MOBILE_2_COL = {
    contentContainerDimensions: 0.63,
    spacing: 4,
    itemDimensions: 96,
};

export const FLATGRID_DIMENSIONS_MOBILE_3_COL = {
    contentContainerDimensions: 0.9,
    spacing: 4,
    itemDimensions: 96,
};

export const FLATGRID_DIMENSIONS_TABLET = {
    contentContainerDimensions: 0.75,
    spacing: 20,
    itemDimensions: 112,
};

export const FLATGRID_DIMENSIONS_LANDSCAPE = {
    contentContainerDimensions: 0.84,
    spacing: 20,
    itemDimensions: 112,
};
