import { useEffect, useRef } from 'react';
import { IAd, IAdTrackingEvent, PlayerBase } from '@24i/player-base';
import { PlaybackViewProps } from '@24i/nxg-sdk-smartott/src/screens/PlaybackScreen/types';
import { ViewModelReturnType } from '@24i/nxg-sdk-smartott-tv/src/screens/PlaybackScreen/types';
import { isPlatformWeb, isPlatformIos, isPlatformTvos } from '@24i/nxg-core-utils';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { PlaybackStarted } from '@24i/nxg-core-utils/src/globalSingletons';
import { isCompatibleSource } from '@24i/player-utils-react';
import { PlaybackState, updatePlaybackState } from './playbackState';
import { useBuildPlaybackEvent } from './buildPlaybackEvent';
import { useTriggerOnPlaybackHeartbeat } from './useTriggerOnPlaybackHeartbeat';
import { useTriggerOnPlaybackMilestone } from './useTriggerOnPlaybackMilestone';
import { useTriggerOnAdLoaded } from './useTriggerOnAdLoaded';
import { useTriggerOnAdSkipped } from './useTriggerOnAdSkipped';
import { useTriggerOnPlayerEvent } from './useTriggerOnPlayerEvent';

type PlaybackProps = PlaybackViewProps | ViewModelReturnType;

export const usePlayerAnalytics = (props: PlaybackProps) => {
    const playerInstance = useRef<PlayerBase>();
    const currentPlaybackState = useRef<PlaybackState | undefined>(undefined);

    const nextPlaybackState = useRef<PlaybackState>({});
    nextPlaybackState.current = {
        asset: props.asset,
        source: props.source,
        seriesData: props.seriesData,
        hasAccurateTimes: false,
    };

    const autoPause = useRef<boolean>(false);

    const buildPlaybackEvent = useBuildPlaybackEvent();

    const initOrUpdatePlaybackState = () => {
        if (
            !currentPlaybackState.current ||
            currentPlaybackState.current.asset?.id !== nextPlaybackState.current.asset?.id ||
            !isCompatibleSource(
                currentPlaybackState.current.source,
                nextPlaybackState.current.source
            )
        ) {
            currentPlaybackState.current = nextPlaybackState.current;
        }
        updatePlaybackState(currentPlaybackState.current, playerInstance.current);
    };

    const triggerOnPlaybackHeartbeat = useTriggerOnPlaybackHeartbeat(buildPlaybackEvent);
    const triggerOnPlaybackMilestone = useTriggerOnPlaybackMilestone(buildPlaybackEvent);
    const triggerOnAdLoaded = useTriggerOnAdLoaded(buildPlaybackEvent);
    const triggerOnAdSkipped = useTriggerOnAdSkipped(buildPlaybackEvent);
    const triggerOnPlayerEvent = useTriggerOnPlayerEvent(buildPlaybackEvent);

    useEffect(() => {
        if (isPlatformWeb) {
            const handleAppStateChange = () => {
                if (PlaybackStarted.get()) {
                    const event = buildPlaybackEvent('APP_CLOSE', currentPlaybackState.current);
                    if (event) onAnalytics(ANALYTICS_TRIGGERS.APP_CLOSE, event);
                    PlaybackStarted.set(false);
                }
            };
            window.addEventListener('beforeunload', handleAppStateChange);
            return () => {
                window.removeEventListener('beforeunload', handleAppStateChange);
            };
        }
        return () => {};
    }, []);

    const onTimeUpdate = () => {
        updatePlaybackState(currentPlaybackState.current, playerInstance.current);
        triggerOnPlaybackHeartbeat(currentPlaybackState.current);
        triggerOnPlaybackMilestone(currentPlaybackState.current);
    };

    const onPlay = () => {
        if (autoPause.current) {
            autoPause.current = false;
        } else {
            props.onPlay?.();
            initOrUpdatePlaybackState();
            triggerOnPlayerEvent(currentPlaybackState.current, 'PLAYBACK_UNPAUSE');
            triggerOnPlayerEvent(currentPlaybackState.current, 'PLAYBACK_START');
        }
    };

    const onAutoPause = () => {
        autoPause.current = true;
    };

    const onPause = () => {
        if (autoPause.current) return;
        props.onPause?.();
        updatePlaybackState(currentPlaybackState.current, playerInstance.current);
        triggerOnPlayerEvent(currentPlaybackState.current, 'PLAYBACK_PAUSE');
    };

    const onEnd = () => {
        props.onEnd?.();
        triggerOnPlayerEvent(currentPlaybackState.current, 'PLAYBACK_ENDED');
    };

    const onStop = () => {
        props.onStop?.();
        triggerOnPlayerEvent(currentPlaybackState.current, 'PLAYBACK_STOP');
    };

    const onWaiting = () => {
        updatePlaybackState(currentPlaybackState.current, playerInstance.current);
        triggerOnPlayerEvent(currentPlaybackState.current, 'PLAYBACK_BUFFERING_START');
    };

    const onCanPlay = () => {
        initOrUpdatePlaybackState();
        if ((isPlatformIos || isPlatformTvos) && playerInstance.current?.playing) {
            triggerOnPlayerEvent(currentPlaybackState.current, 'PLAYBACK_START');
        }
        triggerOnPlayerEvent(currentPlaybackState.current, 'PLAYBACK_BUFFERING_FINISHED');
    };

    const onAdStarted = (ad: IAd) => {
        updatePlaybackState(currentPlaybackState.current, playerInstance.current);
        const { title, adBreakIndex } = ad;
        triggerOnAdLoaded(currentPlaybackState.current, {
            adName: title,
            adPosition: adBreakIndex,
        });
    };

    const onAdTracking = (adTrackingEvent: IAdTrackingEvent) => {
        updatePlaybackState(currentPlaybackState.current, playerInstance.current);
        const { ad, trackingEvent } = adTrackingEvent;
        if (trackingEvent === 'skip') {
            triggerOnAdSkipped(currentPlaybackState.current, {
                adName: ad?.title,
                adPosition: ad?.adBreakIndex,
            });
        }
    };

    const addListeners = (): void => {
        // @ts-ignore
        playerInstance.current?.addEventListener('autopause', onAutoPause);
        playerInstance.current?.addEventListener('timeupdate', onTimeUpdate);
        playerInstance.current?.addEventListener('waiting', onWaiting);
        playerInstance.current?.addEventListener('canplay', onCanPlay);
        playerInstance.current?.addEventListener('adstarted', onAdStarted);
        playerInstance.current?.addEventListener('adtracking', onAdTracking);
    };

    const setVideoPlayerRef: PlaybackProps['setVideoPlayerRef'] = (ref: PlayerBase) => {
        if (!playerInstance.current) {
            playerInstance.current = ref;
            if (isPlatformWeb) addListeners();
        }
        return props.setVideoPlayerRef?.(ref);
    };

    useEffect(() => {
        if (!isPlatformWeb) addListeners();
        return () => {
            // @ts-ignore
            playerInstance.current?.removeEventListener('autopause', onAutoPause);
            playerInstance.current?.removeEventListener('timeupdate', onTimeUpdate);
            playerInstance.current?.removeEventListener('waiting', onWaiting);
            playerInstance.current?.removeEventListener('canplay', onCanPlay);
            playerInstance.current?.removeEventListener('adstarted', onAdStarted);
            playerInstance.current?.removeEventListener('adtracking', onAdTracking);
        };
    }, [playerInstance.current]);

    return { onPlay, onPause, onEnd, onStop, setVideoPlayerRef };
};
