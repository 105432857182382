import React from 'react';
import PodcastPlayer from '../../../components/PodcastPlayer';
import { usePodcastPlayer } from '..';

export const PodcastPlayerContainer = () => {
    const { episodeInPlayer, isLoading } = usePodcastPlayer();
    if (!episodeInPlayer && !isLoading) return null;

    return <PodcastPlayer />;
};
