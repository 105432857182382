import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { ActivityIndicator, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import DynaRowScreen from '../../DynaRowScreen';
import NoPurchases from '../components/NoPurchases';
import { MyPurchasesViewProps } from '../types';
import { getMyPurchasesScreenStyles } from '../styles';

const MyPurchasesScreen = ({
    sections,
    isLoading,
    getStyles = getMyPurchasesScreenStyles,
    handlePackshotPress,
    handleSeeAllItemPress,
}: MyPurchasesViewProps) => {
    const { theme } = useTheme();
    const styles = getStyles();

    if (isLoading) {
        return (
            <View style={styles.loaderWrapper}>
                <ActivityIndicator size={24} color={theme.color.textPrimary} />
            </View>
        );
    }

    if (!sections.length) {
        return <NoPurchases />;
    }

    return (
        <DynaRowScreen.View
            sections={sections}
            handlePackshotPress={handlePackshotPress}
            handleSeeAllItemPress={handleSeeAllItemPress}
        />
    );
};

export default withWebHeader(overridable(MyPurchasesScreen, 'MyPurchasesScreen'));
