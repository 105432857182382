import React from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text } from '@24i/nxg-sdk-quarks';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { WithWebHeaderProps } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader/types';
import SettingsWrapper from '@24i/nxg-sdk-smartott/src/screens/SettingsScreen/components/SettingsWrapper';
import getFallbackScreenStyles from '../styles';

export interface FallbackScreenProps extends WithWebHeaderProps {
    title: string;
    isLandscape: boolean;
    isDetailOpened: boolean;
    onBack: null;
    setOpenedDetail: () => void;
}

const FallbackScreen = ({
    title = '',
    isLandscape = false,
    isDetailOpened = false,
    onBack = null,
    setOpenedDetail = () => {},
}: FallbackScreenProps) => {
    const { theme } = useTheme();
    const styles = getFallbackScreenStyles(theme);

    return (
        <SettingsWrapper
            title={title}
            onBack={onBack}
            isDetailOpened={isDetailOpened}
            setOpenedDetail={setOpenedDetail}
        >
            <Text style={[styles.description, isLandscape && styles.descriptionLandscape]}>
                This screen has not been implemented yet. Please ensure that the slug or the type of
                the menu item is correct
            </Text>
        </SettingsWrapper>
    );
};

export { getFallbackScreenStyles };
export default withWebHeader(FallbackScreen);
