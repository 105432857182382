import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import getTertiaryButtonStyles from '../styles';
import { IconButtonProps as TertiaryButtonProps } from '../../IconButton/types';

const TertiaryButton = (props: TertiaryButtonProps) => (
    <IconButton styles={getTertiaryButtonStyles} {...props} />
);

export { getTertiaryButtonStyles };
export default overridable(TertiaryButton, 'TertiaryButton');
