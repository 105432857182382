import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';

import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton/View';
import { Subscription } from '@24i/nxg-sdk-photon';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { useArePurchasesEnabled } from '@24i/nxg-sdk-smartott-shared/src/hooks/useArePurchasesEnabled';

import NoSubscriptions from './NoSubscriptions';
import getStyles from './styles';
import SubscriptionItem from '../../PurchasesItem/SubscriptionItem';

interface Props {
    availableSubscriptions: Subscription[];
    currentPlans?: Subscription[];
    onAddSubscriptionButtonPress: () => void;
    onChangeSubscriptionButtonPress: (itemId: string) => void;
    isLoading?: boolean;
}

const CurrentSubscriptions = ({
    availableSubscriptions,
    currentPlans,
    onAddSubscriptionButtonPress,
    onChangeSubscriptionButtonPress,
    isLoading,
}: Props) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme, DeviceInfo.isTablet());

    const subscriptionItemWithDivider = ({ item, index }) => (
        <View style={styles.subscriptionItemWrapper}>
            <SubscriptionItem
                testID={`subscription_screen_item_${index + 1}`}
                item={item}
                onSecondaryButtonPress={() => onChangeSubscriptionButtonPress(item.id)}
                key={item.id}
                hasTransparentBackground
            />
            <View style={styles.divider} />
        </View>
    );

    const arePurchasesEnabled = useArePurchasesEnabled();

    const addSubscriptionButton = (
        <View style={styles.addSubscriptionButtonWrapper}>
            {arePurchasesEnabled && availableSubscriptions?.length > 0 && (
                <ActionButton
                    testID="subscription_screen_add_subscription_button"
                    onPress={onAddSubscriptionButtonPress}
                    additionalContainerStyles={styles.additionalContainerStyles}
                    additionalTextStyles={styles.additionalTextStyles}
                    title={t('svod.add.title')}
                />
            )}
        </View>
    );

    return (
        <LoadingWrapper
            isLoading={Boolean(isLoading)}
            loaderProps={{
                size: 'large',
                color: theme.color.textPrimary,
                holderStyles: styles.loader,
            }}
            wrapperStyle={styles.wrapper}
        >
            {currentPlans === undefined || currentPlans.length === 0 ? (
                <NoSubscriptions onAddSubscriptionPress={onAddSubscriptionButtonPress} />
            ) : (
                <FlatList
                    data={currentPlans}
                    renderItem={subscriptionItemWithDivider}
                    ListFooterComponent={addSubscriptionButton}
                    keyExtractor={({ id }) => id}
                />
            )}
        </LoadingWrapper>
    );
};

export default CurrentSubscriptions;
