// @ts-nocheck
import { isPlatformWeb } from 'renative';
import { iOS } from './api/utils';

const getSoftwareStatement = () => {
    if (isPlatformWeb) {
        // Web SoftwareStatement
        return '';
    }
    return iOS ? '' : '';
};

const getRedirectUrl = () => {
    if (isPlatformWeb) {
        // Web is a different redirect logic, delivery team needs to provide
        // a landing page or home page redirect url according to customer requirements
        return '';
    }

    return iOS ? '' : '';
};

export const adobePrimetimeLocalConfig = {
    softwareStatement: getSoftwareStatement(),
    redirectUrl: getRedirectUrl(),
};

export const { softwareStatement, redirectUrl } = adobePrimetimeLocalConfig;

export const ACCESS_ENABLER_CDN_URL =
    'https://entitlement.auth.adobe.com/entitlement/v4/AccessEnabler.js';

export const isLocalConfig = !!softwareStatement.length && !!redirectUrl.length;
