/*
@PRODUCT_FEATURE: C 1.0, C 1.2.1
*/
import React, { useEffect, useRef } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { useRouter } from 'next/router';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import {
    useUserData,
    setUserToken,
    setUserDataToStorage,
} from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { logError } from '@24i/nxg-core-utils/src/logger';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useStartPageNavigation } from '@24i/nxg-sdk-smartott/src/context/AppStartContext/hooks/index.web';
import { ApplicationStates, useAppStart } from '@24i/nxg-sdk-smartott/src/context/AppStartContext';
import { useQueryClient } from 'react-query';
import { WebRouter } from '@24i/nxg-core-router/src/NextRouter/index';
import { loaderStyle, parseUrl, webViewLoaderStyle } from '../utils';

const CustomOverridenSigninScreen = () => {
    const userDataClient = useUserData();
    const { appState, runStartSequence } = useAppStart();
    const codeFromUrl = useRef('');
    const { login } = useStartPageNavigation();
    const { setUserData, userData, setProfilesAndPossiblySelectOne } = useStore();
    const queryClient = useQueryClient();

    const router = useRouter() as WebRouter;

    // const { logEvent } = useFirebase();
    useEffect(() => {
        const routerQueryCode = router.query?.code;
        if (routerQueryCode && typeof routerQueryCode === 'string') {
            codeFromUrl.current = routerQueryCode;
        } else if (router.asPath && router.asPath.includes('?code=')) {
            // in case router query is not parsed on refresh
            codeFromUrl.current = parseUrl(router.asPath).code;
        }
    }, [router.query?.code, router.asPath]);

    useEffect(() => {
        const continueStartSequence = async () => {
            // queries needs to be invalidated because request could be still
            // unauthenticated until this moment
            await queryClient.invalidateQueries();
            runStartSequence(undefined, router);
        };

        if (userData && appState === ApplicationStates.initialNavigationComplete) {
            continueStartSequence();
        }
    }, [appState, userData]);

    useEffect(() => {
        const getUserData = async () => {
            try {
                const { token } = await userDataClient.getUserOauthToken(codeFromUrl.current);
                codeFromUrl.current = '';
                if (!token) return;

                await setUserToken(token);

                const user = await userDataClient.fetchUser();
                if (user) {
                    const profiles = await userDataClient.fetchUserProfiles();

                    setProfilesAndPossiblySelectOne(profiles);
                }

                setUserData(user);
                await setUserDataToStorage(user);

                login();

                // logEvent(eventCategorySession, { [EVENT_ACTION]: SESSION_AUTH });
            } catch (error) {
                logError(error);
            }
        };

        if (codeFromUrl.current && appState !== ApplicationStates.notInitialized && !userData)
            getUserData();
    }, [codeFromUrl.current, appState]);

    useEffect(() => {
        const fetchServiceURL = async () => {
            const { redirectUrl: url } = await userDataClient.getOAuthUrl();

            if (typeof window !== 'undefined') {
                window.location.href = url;
            }
        };
        if (!codeFromUrl.current && !userData && router.isReady) fetchServiceURL();
    }, [router.isReady, userData]);

    return (
        <View style={webViewLoaderStyle}>
            <Loader additionalStyles={loaderStyle} color="white" size="large" />
        </View>
    );
};

export default CustomOverridenSigninScreen;
