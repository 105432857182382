import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';

import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import getSettingsWrapperStyles from '../styles';

const SettingsWrapper = ({
    children,
    styles: getStyles,
    additionalScrollViewContentContainerStyles,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const renderContent = () => (
        <View style={[styles.scrollViewContainer, additionalScrollViewContentContainerStyles]}>
            {children}
        </View>
    );

    return renderContent();
};

SettingsWrapper.propTypes = {
    children: PropTypes.node,
    styles: PropTypes.func,
    additionalScrollViewContentContainerStyles: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
};

SettingsWrapper.defaultProps = {
    children: null,
    styles: getSettingsWrapperStyles,
    additionalScrollViewContentContainerStyles: {},
};

export { getSettingsWrapperStyles };
export default overridable(SettingsWrapper, 'SettingsWrapper');
