/* eslint-disable react/display-name */
import React from 'react';
import View from './View';
import useViewModel from './viewModel';
import getPlaylistScreenStyles from './styles';
import { PlaylistScreenProps } from './types';

export default {
    Main: (props: PlaylistScreenProps) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    // default styles getter
    getDefaultStyles: getPlaylistScreenStyles,
};
