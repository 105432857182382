import { ViewStyle } from 'react-native';
import { getMargins } from '@24i/nxg-sdk-higgs';
import { DynaRowSectionStyles } from '../types';

const getDynaRowSectionStyles = (): DynaRowSectionStyles => ({
    packshotContainer: {
        ...getMargins(),
    } as ViewStyle,
});

export default getDynaRowSectionStyles;
