import React from 'react';
import { ThemeIcon, Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import Interactable from '@24i/nxg-sdk-quarks/src/components/Interactable';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButtonWithoutBg';
import { useArePurchasesEnabled } from '@24i/nxg-sdk-smartott-shared/src/hooks/useArePurchasesEnabled';

import getStyles from './styles';
import { PurchasableItemViewProps } from '../types';

const PurchasableItem = ({
    testID,
    onPress,
    title,
    description,
    tagText,
    itemInfoLines,
    showBorder,
    hasTransparentBackground,
    textAfterDescription,
    secondaryButtonText = 'secondary button',
    secondaryButtonTestID,
    onSecondaryButtonPress,
    wasCancelled,
}: PurchasableItemViewProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme, { showBorder, hasTransparentBackground });
    const isClickable = !!onPress;

    const Wrapper = isClickable ? Interactable : View;

    const arePurchasesEnabled = useArePurchasesEnabled();

    return (
        <Wrapper testID={testID} style={styles.wrapper} onPress={onPress}>
            <View style={styles.contentWrapper}>
                {!!tagText && (
                    <View style={styles.space}>
                        <View style={styles.tagWrapper}>
                            <Text style={styles.tag}>{tagText}</Text>
                        </View>
                    </View>
                )}
                <View style={styles.header}>
                    <Text style={styles.name}>{title}</Text>
                    {!wasCancelled && arePurchasesEnabled && onSecondaryButtonPress && (
                        <View>
                            <TertiaryButtonWithoutBg
                                testID={secondaryButtonTestID}
                                styles={() => ({
                                    text: styles.changeButtonText,
                                })}
                                title={secondaryButtonText}
                                onPress={onSecondaryButtonPress}
                            />
                        </View>
                    )}
                </View>
                <View style={styles.space}>
                    {itemInfoLines.map((line) => (
                        <Text key={line} style={styles.price}>
                            {line}
                        </Text>
                    ))}
                </View>
                <Text style={styles.description}>{description}</Text>
                {!!textAfterDescription && (
                    <View style={styles.nextBillingDateWrapper}>
                        <Text style={styles.nextBillingDate}>{textAfterDescription}</Text>
                    </View>
                )}
            </View>
            {isClickable && (
                <View style={styles.iconWrapper}>
                    <ThemeIcon iconName="onPrimary-subpage" size={24} />
                </View>
            )}
        </Wrapper>
    );
};

export default PurchasableItem;
