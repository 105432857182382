import { useEffect } from 'react';
import { NavConfigWeb, NavScreenConfig } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import useShared from './useShared';
import { useRegister } from '../../../hooks/useRegister';
import { useStore } from '../../../context/ApplicationStore';
import { useMenuItemPress } from '../../../navigation/utils';
import { SOTT_DEFAULT_SCREENS } from '../../../navigation/constants';
import { MoreScreenScreenProps, WebViewModelReturnType } from '../types';
import { useNavigationConfigQuery } from '../../../hooks/query/useNavigationConfigQuery';

const useViewModel = (props: MoreScreenScreenProps): WebViewModelReturnType => {
    const shared = useShared(props);
    const { getUserProfiles, isProfileLoading } = shared;
    const { userData } = useStore();
    const { navigationConfig } = useNavigationConfigQuery();
    const onMenuItemPress = useMenuItemPress();
    const { accountRegistration } = useRegister();

    useEffect(() => {
        getUserProfiles();
    }, [userData]);

    // casting solution taken from packages/sdk@smartott/src/Application/viewModel/index.web.ts line 36
    const navigationConfigWeb = navigationConfig as NavConfigWeb;

    const handleOnMenuItemPress = (navigationItemName: SOTT_DEFAULT_SCREENS) => {
        if (navigationConfig) {
            const { allNavigationItems } = navigationConfigWeb;

            const navigationItem = allNavigationItems.find(
                (item) => item.name === navigationItemName
            );

            const item = navigationItem as NavScreenConfig;
            onMenuItemPress(item);
        }
    };

    const onSignInPress = () => handleOnMenuItemPress(SOTT_DEFAULT_SCREENS.SIGN_IN);

    const onRegisterPress = () => handleOnMenuItemPress(SOTT_DEFAULT_SCREENS.SIGN_UP);

    return {
        ...shared,
        isLoading: isProfileLoading,
        showBottomAuthSection: !userData,
        onSignInPress,
        onRegisterPress: accountRegistration ? onRegisterPress : undefined,
        // TODO: The XS constant here should be replaced with mapping as this will always use the "XS" settings regardless of the width of the screen
        menuItems: navigationConfigWeb ? navigationConfigWeb.XS.moreScreen : [],
        ...props,
    };
};

export { useViewModel };
