import React from 'react';
import View from './View/index.web';
import { useViewModel } from './viewModel';
import { TopBarMenuDropdownProps } from './types';

export { getTopBarMenuDropdownStyles } from './styles';

const Main = (props: TopBarMenuDropdownProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
