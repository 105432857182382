import { createGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { EpisodeGuard } from './episode';

export const SeasonGuard = createGuard(
    'Season',
    {
        name: t.string,
        episodes: t.array(EpisodeGuard),
    },
    {
        seasonNumber: t.number,
    }
);

export type Season = t.TypeOf<typeof SeasonGuard>;
