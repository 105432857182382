import React from 'react';
// DONT CHANGE IMPORT PATHS BELOW OR NEXT WILL STOP BUILDING ROUTES CORRECTLY
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { TouchableOpacity } from 'react-native';
import { AppVersion } from '@24i/nxg-core-utils/src/globalSingletons';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { WithWebHeaderProps } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader/types';
import { useAppConfigQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/useAppConfigQuery';
import JSONTree from 'react-native-json-tree';
import SettingsWrapper from '../../SettingsScreen/components/SettingsWrapper';
import getTechnicalInfoScreenStyles from '../styles';
import { TECHNICAL_INFO_TEST_IDS } from '../types';

export interface TechnicalInfoScreenProps extends WithWebHeaderProps {
    title: string;
    isLandscape: boolean;
    isDetailOpened: boolean;
    onBack: null;
    setOpenedDetail: () => void;
}

const Wrapper = SettingsWrapper;

const TechnicalInfoScreen = ({
    title = '',
    isLandscape = false,
    isDetailOpened = false,
    onBack = null,
    setOpenedDetail = () => {},
}: TechnicalInfoScreenProps) => {
    const { theme } = useTheme();
    const styles = getTechnicalInfoScreenStyles(theme);
    const { t } = useTranslation(['sott']);
    const { appSettings } = useAppConfigQuery();
    const sentryClient = Sentry.getCurrentHub().getClient();
    const SHOW_DEV_INFO = __DEV__;
    return (
        <Wrapper
            title={title}
            onBack={onBack}
            isDetailOpened={isDetailOpened}
            setOpenedDetail={setOpenedDetail}
        >
            <View style={{ width: '100%' }}>
                <View style={styles.itemWrapper}>
                    <Text style={styles.title}>App Version</Text>
                    <Text
                        style={[styles.description, isLandscape && styles.descriptionLandscape]}
                        numberOfLines={1}
                        testID={TECHNICAL_INFO_TEST_IDS.APP_VESRION_TEXT}
                    >
                        {t('settings.technicalInfo.appVersion')} {AppVersion.get()}
                    </Text>
                </View>
                {SHOW_DEV_INFO && (
                    <View style={styles.itemWrapper}>
                        <Text style={styles.title}>App Settings</Text>
                        <JSONTree data={appSettings} />
                    </View>
                )}
                {SHOW_DEV_INFO && (
                    <View style={styles.itemWrapper}>
                        <Text style={styles.title}>Sentry</Text>
                        {/* @ts-ignore */}
                        <JSONTree data={sentryClient} />
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <TouchableOpacity
                                onPress={() => {
                                    throw new Error('Sentry Test Error');
                                }}
                            >
                                <View
                                    style={{
                                        padding: 5,
                                        borderColor: 'white',
                                        borderWidth: 1,
                                        marginTop: 8,
                                        marginRight: 8,
                                    }}
                                >
                                    <Text style={{ color: 'white' }}>Crash JS</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                )}
            </View>
        </Wrapper>
    );
};

export { getTechnicalInfoScreenStyles };
export default withWebHeader(TechnicalInfoScreen);
