/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { Interactable, View, Text, Image, ScrollView, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Router } from '@24i/nxg-core-router/src/NextRouter';
import { isIconMapImage, IconMapItem, IconProps } from '@24i/nxg-sdk-photon';
import { getTopBarMenuDropdownStyles } from '../styles';
import useOutsideClick from '../../../../../../hooks/useOutsideClick';
import { TopBarMenuDropdownProps } from '../types';
import { ListItem } from '../components/ListItem/index.web';

const TopBarMenuDropdown = ({
    name,
    items,
    styles: getStyles = getTopBarMenuDropdownStyles,
    icon,
    title: headerTitle,
    isOnboarding,
    onListItemPress,
}: TopBarMenuDropdownProps) => {
    const [listOpen, setListOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const completeScrollBarHeight = useRef(0);
    const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0);

    const showScrollIndicator = completeScrollBarHeight.current > visibleScrollBarHeight;

    const scrollIndicatorSize = showScrollIndicator
        ? (visibleScrollBarHeight * visibleScrollBarHeight) / completeScrollBarHeight.current
        : visibleScrollBarHeight;

    const scrollIndicator = useRef(new Animated.Value(0)).current;

    const difference =
        visibleScrollBarHeight > scrollIndicatorSize
            ? visibleScrollBarHeight - scrollIndicatorSize
            : 1;

    const scrollIndicatorPosition = Animated.multiply(
        scrollIndicator,
        visibleScrollBarHeight / completeScrollBarHeight.current
    ).interpolate({
        inputRange: [0, difference],
        outputRange: [0, difference],
        extrapolate: 'clamp',
    });

    const { theme } = useTheme();
    const containerRef = useRef(null);

    const styles = getStyles(theme);

    const toggleList = () => {
        setListOpen((prevState) => !prevState);
        if (listOpen) completeScrollBarHeight.current = 0;
    };

    const handleClickOutside = () => {
        setListOpen(false);
        completeScrollBarHeight.current = 0;
    };

    useEffect(() => {
        const webRouter = Router;

        webRouter.events.on('routeChangeComplete', handleClickOutside);

        return () => webRouter.events.off('routeChangeComplete', handleClickOutside);
    }, []);

    const isNavigationIcon = typeof icon === 'string';

    // By default, navigation icons for this component come from iconsMap object
    // TODO: There is some really weird polymorphism here. It needs to be refactored.
    // @ts-ignore
    const menuItemIcon: IconMapItem | IconProps = isNavigationIcon
        ? theme.icons.menu.iconsMap[icon as string]
        : icon;

    const isValidImageUrl = (checkedIcon) => {
        if (!checkedIcon || !isNavigationIcon) return false;
        return !!checkedIcon.match(/.(jpg|jpeg|png|gif|svg)$/i);
    };

    useOutsideClick(containerRef, handleClickOutside);

    const calculateScrollBarHeight = (sectionHeight: number) => {
        completeScrollBarHeight.current += sectionHeight;
    };

    return (
        <View style={{ flexDirection: 'column' }} ref={containerRef}>
            <Interactable
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                key={`topmenu-dropdown-link-${name}`}
                style={styles.itemContainer}
                onPress={toggleList}
                testID={`${name}_button`}
            >
                <View style={styles.titleContainer}>
                    {Boolean(headerTitle) && (
                        <Text style={[styles.title, isHovered && styles.titleActive]}>
                            {headerTitle}
                        </Text>
                    )}
                    {menuItemIcon &&
                        (isIconMapImage(menuItemIcon) ? (
                            <Image
                                style={[
                                    styles.iconImage,
                                    ...(isHovered || isOnboarding ? [styles.iconActive] : []),
                                ]}
                                source={{
                                    uri: menuItemIcon.uri,
                                }}
                            />
                        ) : (
                            <ThemeIcon
                                iconStyles={[
                                    styles.icon,
                                    { opacity: isHovered || isOnboarding ? 1 : 0.6 },
                                ]}
                                size={styles.icon?.fontSize || 25}
                                iconName="onPrimary-select"
                            />
                        ))}
                    {isValidImageUrl(icon) && typeof icon === 'string' && (
                        <View style={styles.profileAvatarImageContainer}>
                            <Image
                                source={{
                                    uri: icon,
                                }}
                            />
                        </View>
                    )}
                </View>
            </Interactable>
            {listOpen && items !== null && items?.length > 0 && (
                <View
                    style={[
                        styles.dropdownContainer,
                        isValidImageUrl(icon) && {
                            top: 53,
                            right: -10,
                            paddingTop: 8,
                            paddingBottom: 8,
                        },
                    ]}
                >
                    <ScrollView
                        onLayout={(e) => {
                            setVisibleScrollBarHeight(e.nativeEvent.layout.height);
                        }}
                        scrollEventThrottle={16}
                        onScroll={Animated.event(
                            [{ nativeEvent: { contentOffset: { y: scrollIndicator } } }],
                            { useNativeDriver: false }
                        )}
                        showsVerticalScrollIndicator={false}
                    >
                        {items?.map((menuItem, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    index={index}
                                    onToggle={toggleList}
                                    onItemPress={onListItemPress}
                                    menuItem={menuItem}
                                    calculateScrollBarHeight={calculateScrollBarHeight}
                                    isValidImageUrl={isValidImageUrl}
                                />
                            );
                        })}
                    </ScrollView>

                    {showScrollIndicator && (
                        <View style={[styles.scrollContainer]}>
                            <Animated.View
                                style={[
                                    styles.scrollIndicator,
                                    {
                                        height: scrollIndicatorSize - 12,
                                        transform: [{ translateY: scrollIndicatorPosition }],
                                    },
                                ]}
                            />
                        </View>
                    )}
                </View>
            )}
        </View>
    );
};

export default overridable(TopBarMenuDropdown, 'TopBarMenuDropdown');
