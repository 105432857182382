import { useState, useRef } from 'react';
import { EPGModelProps, EPGProps } from '../types';

const useViewModel = (props: EPGModelProps): EPGProps => {
    const timelineRef = useRef();
    const collectionRef = useRef();

    const [isEndReached, setIsEndReached] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);

    const handleEndReached = (isEnd: boolean) => setIsEndReached(isEnd);
    const handleScrolling = (isScrolling: boolean) => setIsScrolling(isScrolling);

    return {
        ...props,
        isEndReached,
        isScrolling,
        timelineRef,
        collectionRef,
        handleEndReached,
        handleScrolling,
    };
};

export { useViewModel };
