import { log } from '@24i/nxg-core-utils/src/logger';
import { usePinProtection } from '@24i/nxg-sdk-pin-protection/src/context/PinProtection';
import React from 'react';
import { useStore } from '../../context/ApplicationStore';
import { useStartPageNavigation } from '../../context/AppStartContext/hooks';

const EnterPinScreen = () => {
    const { profiles, selectedProfile, setSelectedUserProfile } = useStore();
    const { openPinControl } = usePinProtection();
    const { navigateToStartPage } = useStartPageNavigation();

    const showPinControl = async () => {
        const profile = profiles?.[0];

        try {
            if (!profiles?.length) {
                await openPinControl?.(null, { dismissible: false });
                navigateToStartPage();
            } else if (profile?.isPinProtected) {
                await openPinControl?.(profile, { dismissible: false });
                setSelectedUserProfile(profile || null);
            }
        } catch {
            // pin control rejected
            log('Pin control rejected.');
        }
    };

    React.useEffect(() => {
        if (selectedProfile) {
            navigateToStartPage();
        }
    }, [selectedProfile]);

    React.useEffect(() => {
        showPinControl();
    }, []);

    return <></>;
};

export default EnterPinScreen;
