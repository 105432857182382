import { useInterval } from 'ahooks';
import React, { FC, useEffect, useState } from 'react';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import { CLEENG_OPERATION_BUFFER_TIME, THIRTY_SECONDS } from '@24i/nxg-core-utils/src/constants';
import { useThemingForCleeng } from '../../../hooks/cleeng/useThemingForCleeng';
import { useCleengTokenHandling } from '../../../hooks/cleeng/useCleengTokenHandling';
import { CleengStyles } from './styles';

/**
 * Use this wrapper whenever implementing a new Cleeng component. Preferred this approach instead of a classic HOC, so that the wrapper is agnostic about the props of the wrapped component.
 * It provides automatic token-refreshing by checking that there is "enough" time to carry out the operation, taking 3-minute as a reference.

 * Theoretically, the token should never expire, but as an extra precaution, the wrapper also listens for Cleeng component's event MSSDK:Auth-failed, when this will be implemented
 * It also passes the overall styling classes (font and color)
 */

const SDK_AUTH_FAILED_EVENT = 'MSSDK:Auth-failed';
const TOKEN_REFRESHING_INTERVAL = CLEENG_OPERATION_BUFFER_TIME - THIRTY_SECONDS;

export const CleengComponentWrapper: FC = ({ children }) => {
    const [loadingToken, setLoadingToken] = useState(true);

    const { handleCleengComponentToken, refreshToken } = useCleengTokenHandling();

    const { appFont, colors } = useThemingForCleeng();

    // Checks the token on component mount and refreshes it if there is not "enough" time
    useEffect(() => {
        handleCleengComponentToken({ purpose: 'initialisation' }).then((token) => {
            if (token != null) {
                setLoadingToken(false);
            }
        });
    }, [handleCleengComponentToken]);

    // Periodically checks the token to know if there is "enough" time before expiry, otherwise it refreshes it
    useInterval(() => {
        handleCleengComponentToken({ purpose: 'periodic-check' });
    }, TOKEN_REFRESHING_INTERVAL);

    // Checks for Cleeng failed-auth event, when Cleeng will implement it
    useEffect(() => {
        window.addEventListener(SDK_AUTH_FAILED_EVENT, refreshToken);

        return () => {
            window.removeEventListener(SDK_AUTH_FAILED_EVENT, refreshToken);
        };
    }, [refreshToken]);

    return (
        <LoadingWrapper isLoading={loadingToken}>
            <div className="cleeng-wrapper">
                {children}
                {/* The extra styles below are to target Adyen-specific components */}
                <style jsx>{`
                    .cleeng-wrapper {
                        font-family: ${appFont};
                        color: ${colors.textPrimary} !important;
                    }
                `}</style>
                <CleengStyles />
            </div>
        </LoadingWrapper>
    );
};
