import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';

const FallbackScreen = (theme: Theme) => ({
    description: {
        color: theme.color.textPrimary,
        marginBottom: 32,
        opacity: 0.6,
        ...getFont(theme, 'body1'),
    },
    descriptionLandscape: {
        marginHorizontal: 32,
        width: '57%',
    },
});

export default FallbackScreen;
