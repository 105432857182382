import React from 'react';
import PropTypes from 'prop-types';
import { Interactable, NavigationIcon } from '@24i/nxg-sdk-quarks';
import { queryObject } from '../utils/utils';

const TwitterButton = ({ size, url, message, title, onShare, testID }) => {
    const share = () => {
        const query = queryObject({
            url,
            text: `${title}\n${message}`,
            hashtags: '',
        });
        window.open(`https://twitter.com/share${query}`);
        onShare();
    };
    return (
        <Interactable onPress={share} testID={testID}>
            <NavigationIcon size={size} iconName="social_twitter" />
        </Interactable>
    );
};

TwitterButton.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    url: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string,
    onShare: PropTypes.func.isRequired,
    testID: PropTypes.string.isRequired,
};

TwitterButton.defaultProps = {
    size: 50,
    color: '#1DA1F2',
    url: '',
    message: '',
    title: '',
};

export default TwitterButton;
