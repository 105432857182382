import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { ThemeIcon, Interactable, TextInput, View } from '@24i/nxg-sdk-quarks';
import React from 'react';
import getSearchTextInputStyles from '../styles/index.web';
import { SearchTextInputPropsWeb, SEARCH_INPUT_TEST_IDS } from './types';

const SearchTextInput = ({
    styles: getStyles = getSearchTextInputStyles,
    onChangeText,
    value,
    onPress,
    isSearchBarFocused = false,
    onSearchBarFocus,
    onSearchBarBlur,
    inputRef,
    ...props
}: Omit<SearchTextInputPropsWeb, 'onCancelPress'>) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const onChange = (e) => {
        const textValue = e.target?.value || '';
        onChangeText?.(textValue);
    };

    const valueExists = Boolean(value && value.length > 0);

    return (
        <View
            testID="SearchTextInput.View.1"
            style={[styles.textInput, isSearchBarFocused && styles.textInputFocused]}
        >
            <ThemeIcon iconName="onPrimary-field_search" size={32} iconStyles={styles.searchIcon} />
            <TextInput
                type="search"
                style={[styles.inputStyle, { borderWidth: 0 }]}
                onFocus={onSearchBarFocus}
                onBlur={onSearchBarBlur}
                onChange={onChange}
                value={value}
                testID={SEARCH_INPUT_TEST_IDS.SEARCH_INPUT}
                selectionColor={theme.color.textPrimary}
                {...props}
                ref={inputRef}
            />
            {valueExists && (
                <Interactable
                    onPress={onPress}
                    testID={SEARCH_INPUT_TEST_IDS.SEARCH_INPUT_X_BUTTON}
                >
                    <ThemeIcon
                        iconName="onPrimary-field_clear"
                        size={20}
                        iconStyles={styles.clearIcon}
                    />
                </Interactable>
            )}
        </View>
    );
};

export { getSearchTextInputStyles };

export default overridable(SearchTextInput, 'SearchTextInput');
