import React, { useState, useEffect, useRef, ReactElement, useCallback } from 'react';
import { TextInput, Interactable, View } from '@24i/nxg-sdk-quarks';
import {
    TextInputKeyPressEventData,
    NativeSyntheticEvent,
    TextInputChangeEventData,
} from 'react-native';
import styles from '../styles';
import type { SequentialInputPropsWeb, InputRefType } from '../types';
import { KEYBOARD_KEYS, MAX_LENGTH_OF_INPUT } from '../constants';

export const TEXT_INPUT_TEST_ID_PREFIX = 'text-input-';

const SequentialInput = (props: SequentialInputPropsWeb): ReactElement => {
    const {
        numberOfInputs = 1,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange = () => {},
        secureTextEntry = false,
        lengthOfSingleInput = MAX_LENGTH_OF_INPUT,
        renderInput = () => null,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onFinish = () => {},
        inputFilter = () => true,
        focusUnderlineStyle = View.defaultProps,
        isIncorrectPin = false,
        returnFocus,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        resetReturnFocus = () => {},
    } = props;

    const [indexOfFocusedInput, setIndexOfFocusedInput] = useState<number | null>(null);
    const useInputRef: InputRefType = () => useRef(null);
    const inputRefs = Array.from({ length: numberOfInputs }, useInputRef);
    const [values, setValues] = useState(Array.from({ length: numberOfInputs }, () => ''));

    useEffect(() => {
        if (!indexOfFocusedInput) {
            inputRefs[0].current?.focus();
            setIndexOfFocusedInput(0);
        }
    }, []);

    // This is for keeping focus on certain PIN inputs user was focused in, when he clicks out of them so he can still type
    useEffect(() => {
        if (returnFocus) {
            if (indexOfFocusedInput !== null) {
                inputRefs[indexOfFocusedInput].current?.focus();
                resetReturnFocus();
            }
        }
    }, [returnFocus]);

    // This is for keeping focus when you click on some input
    useEffect(() => {
        if (indexOfFocusedInput !== null && indexOfFocusedInput < numberOfInputs) {
            inputRefs[indexOfFocusedInput].current?.focus();
        }
    }, [indexOfFocusedInput]);

    // This is for keeping focus after deleting some input value
    useEffect(() => {
        if (indexOfFocusedInput !== null) inputRefs[indexOfFocusedInput].current?.focus();
    }, [values, indexOfFocusedInput]);

    // This is for removing all PIN input numbers and setting focus on first input
    useEffect(() => {
        if (isIncorrectPin) {
            setValues(Array.from({ length: numberOfInputs }, () => ''));
            setIndexOfFocusedInput(0);
        }
    }, [isIncorrectPin]);

    // This method after entering one input sets next focused input, stores inserted value
    // and if it is last input, whole PIN is sent to PinControl component.
    const onChangeHandler = useCallback(
        (event: NativeSyntheticEvent<TextInputChangeEventData>, index: number): void => {
            const { text } = event.nativeEvent;
            if (!inputFilter(text)) return;
            onChange({ event, index });
            if (index < numberOfInputs - 1 && text.length === lengthOfSingleInput) {
                setIndexOfFocusedInput(index + 1);
            }

            const newTextValues = [...values.slice(0, index), text, ...values.slice(index + 1)];

            const numberOfFilledInputs = newTextValues.filter(
                ({ length }) => length === lengthOfSingleInput
            ).length;

            if (
                numberOfFilledInputs === numberOfInputs &&
                indexOfFocusedInput === numberOfFilledInputs - 1
            ) {
                onFinish({ value: newTextValues.join('') });
            }

            setValues(newTextValues);
        },
        [indexOfFocusedInput]
    );

    const removeValueFor = (index: number): void => {
        setValues([...values.slice(0, index), '', ...values.slice(index + 1)]);
    };

    const handleBack = (nativeEvent: TextInputKeyPressEventData, index: number): void => {
        if (nativeEvent.key === KEYBOARD_KEYS.BACKSPACE) {
            if (index < numberOfInputs) {
                removeValueFor(index);
            }
        }
    };

    return (
        <>
            {Array.from({ length: numberOfInputs }, (_, index) => (
                <View key={index}>
                    {renderInput({
                        focused: index === indexOfFocusedInput,
                        value: values[index],
                    })}
                    <TextInput
                        ref={inputRefs[index]}
                        clearTextOnFocus
                        style={styles.invisible}
                        maxLength={lengthOfSingleInput}
                        caretHidden={false}
                        value={values[index]}
                        secureTextEntry={secureTextEntry}
                        onChange={(event) => onChangeHandler(event, index)}
                        key={index}
                        onKeyPress={({ nativeEvent }) => handleBack(nativeEvent, index)}
                        testID={`${TEXT_INPUT_TEST_ID_PREFIX}${index}`}
                    />
                    {index === indexOfFocusedInput && <View style={focusUnderlineStyle} />}
                    <Interactable
                        onPress={() => {
                            setIndexOfFocusedInput(index);
                        }}
                        style={styles.invisible}
                    />
                </View>
            ))}
        </>
    );
};

export default SequentialInput;
