import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { RateItemStyles } from './types';

const getStyles = (theme: Theme): RateItemStyles => ({
    text: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        ...getFont(theme, 'h4'),
    },
    icon: {
        marginRight: 2,
    },
});

export default getStyles;
