import * as t from 'io-ts';
import { ImageGuard } from '@24i/nxg-sdk-photon/src/models/image';
import { nullable, createGuard } from '@24i/nxg-core-utils/src/guards';

const AssetGuard = createGuard('Asset', undefined, {
    type: t.string,
    src: t.array(ImageGuard),
});

const SlideshowTypeGuard = createGuard(
    'SlideshowType',
    { speed: t.string },
    {
        type: t.string,
    }
);

export type SlideshowType = t.TypeOf<typeof SlideshowTypeGuard>;

const assetsVariants = nullable(t.array(AssetGuard));
export const WelcomeScreenGuard = createGuard('WelcomeScreen', undefined, {
    id: t.string,
    name: t.string,
    slideshowType: SlideshowTypeGuard,
    target: nullable(
        createGuard('Target', undefined, {
            type: t.string,
            value: nullable(t.string),
        })
    ),
    show: t.string,
    assets: nullable(
        createGuard('Assets', undefined, {
            phonePortrait: assetsVariants,
            phoneLandscape: assetsVariants,
            tabletPortrait: assetsVariants,
            tabletLandscape: assetsVariants,
            tv: assetsVariants,
        })
    ),
    createdAt: t.string,
    updatedAt: t.string,
});

export type WelcomeScreen = t.TypeOf<typeof WelcomeScreenGuard>;
