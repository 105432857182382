import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { PrimetimeDataClient } from '@24i/nxg-sdk-photon';
import autoBind from 'auto-bind';
import { BackstageApiBase } from '../../base';
import { ConnectCodeGuard, MvpdAuthGuard, CheckAuthPrimetimeGuard } from './guards';

export class BackstagePrimetimeDataClient extends BackstageApiBase implements PrimetimeDataClient {
    constructor(params: BaseApiParams) {
        super(params);
        autoBind(this);
    }

    async getConnectCode() {
        return this.request({
            path: '/utils/primetime/code',
            method: 'GET',
            guard: ConnectCodeGuard,
        });
    }

    async verifyPrimetimeAuth() {
        return this.request({
            path: '/utils/primetime/authenticate',
            method: 'POST',
            guard: MvpdAuthGuard,
        });
    }

    async logoutPrimetime() {
        return this.request({
            path: '/utils/primetime/logout',
            method: 'POST',
        });
    }

    async checkAuthorization(resource) {
        return this.request({
            path: '/utils/primetime/authorize',
            method: 'POST',
            guard: CheckAuthPrimetimeGuard,
            body: {
                resource,
            },
        });
    }
}

export const createBackstagePrimetimeDataClient = (params: BaseApiParams) => {
    return new BackstagePrimetimeDataClient(params);
};
