import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionConfirmPurchaseProps } from '../../types';
import ConfirmPurchase from '../ConfirmPurchase';

const SubscriptionConfirmPurchase: React.FC<SubscriptionConfirmPurchaseProps> = (props) => {
    const {
        children,
        selectedItem: { billingFrequency },
    } = props;
    const { t } = useTranslation();
    const middleTextKey =
        billingFrequency.unit === 'year' && billingFrequency.count === 1
            ? 'svod.add.addConfirm.recurrenceAnnually'
            : 'svod.add.addConfirm.recurrencePayment';
    return (
        <ConfirmPurchase
            {...props}
            topText={t('svod.add.confirmSubscription.description')}
            middleText={t(middleTextKey)}
            bottomText={t('svod.add.addConfirm.cancelInfo')}
        >
            {children}
        </ConfirmPurchase>
    );
};

export default SubscriptionConfirmPurchase;
