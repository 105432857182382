import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import ChangeableText from '@24i/nxg-sdk-smartott/src/components/ChangeableText/View';

import { View } from '@24i/nxg-sdk-quarks';
import ChangeModal from '@24i/nxg-sdk-smartott/src/components/ChangeableText/components/ChangeModal';
import getStyles from '../styles';

import { ParentalControlPinProps } from '../types';
import ToggleItem from '../../../components/ToggleItem';

const shouldCheckPinLength = true;

const AccountParentalControlsScreen = ({
    items,
    shouldShowPinToggle,
    pinToggleSettings,
    itemToChange,
    isModalOpened,
    closeModal,
    handleModalOpen,
}: ParentalControlPinProps) => {
    const styles = getStyles();

    return (
        <View style={styles.container}>
            <ChangeModal
                item={itemToChange}
                showModal={isModalOpened}
                closeModal={closeModal}
                shouldCheckPinLength={shouldCheckPinLength}
            />
            {items.map((item) => (
                <ChangeableText
                    key={item.itemKey}
                    item={item}
                    onChangePress={(selectedItem) => handleModalOpen(selectedItem)}
                />
            ))}
            {shouldShowPinToggle && <ToggleItem {...pinToggleSettings} />}
        </View>
    );
};

export default overridable(withWebHeader(AccountParentalControlsScreen), 'AccountParentalControls');
