import { Asset } from '@24i/nxg-sdk-photon/src/models/asset';
import { formatGenres } from '../../../../screens/DetailsScreen/viewModel/utils';

export const getSliderSubtitle = ({
    asset,
    isObscured,
    isPodcastSeriesOrEpisode,
    obscuredLocalizedMessage,
}: {
    asset: Asset;
    isObscured: boolean;
    isPodcastSeriesOrEpisode: boolean;
    obscuredLocalizedMessage: string;
}) => {
    switch (true) {
        case isObscured:
            return obscuredLocalizedMessage;

        case isPodcastSeriesOrEpisode:
            return formatGenres(asset);

        default:
            return asset.subtitle || asset.description || '';
    }
};
