import { GetGridScreenStyles } from './types';

const getGridScreenStyles = (): GetGridScreenStyles => ({
    container: { flex: 1, width: '100%', height: '100%' },
    activityIndicatorHolder: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default getGridScreenStyles;
