import React from 'react';
import PurchasableItem from '../View';
import { SubscriptionItemProps } from './types';
import { useSubscriptionItem } from './viewModel';

const SubscriptionItem = (props: SubscriptionItemProps) => {
    const viewModelProps = useSubscriptionItem(props);
    return <PurchasableItem {...viewModelProps} />;
};

export default SubscriptionItem;
