import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { OfferType } from '@24i/nxg-sdk-photon';
import { extractQueryParamsFromAsPath } from '../../../utils';
import { NoOpProps, PaymentResultProps } from '../../../components/PaymentResult/types';

export const useViewModel = (): PaymentResultProps | NoOpProps => {
    const router = useRouter();

    // Extracting params from router's query did not work in this instance, hence the workaround
    const urlParams = extractQueryParamsFromAsPath(router.asPath);

    const offerType = (urlParams.get('offerType') as OfferType) ?? OfferType.SUBSCRIPTION;
    const onContinueUrl = urlParams.get('onContinueUrl');
    const onCancelUrl = urlParams.get('onCancelUrl');
    const onTryAgainUrl = urlParams.get('onTryAgainUrl');

    if (onCancelUrl && onTryAgainUrl) {
        return {
            offerType,
            onCancelUrl,
            onTryAgainUrl,
        };
    }

    if (onContinueUrl) {
        return {
            offerType,
            onContinueUrl,
        };
    }

    // Fallback
    return {
        offerType,
        onCancelUrl: null,
        onContinueUrl: null,
        onTryAgainUrl: null,
    };
};
