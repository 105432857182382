import { useDimensions } from '@24i/nxg-sdk-quantum';
import { WEB_SCREEN_SIZES, WEB_SCREEN_MAX_WIDTH } from '@24i/nxg-sdk-gluons/src/utils/constants';

export const getWebSize = (width: number) => {
    if (width <= WEB_SCREEN_MAX_WIDTH.XS) return WEB_SCREEN_SIZES.XS;
    if (width <= WEB_SCREEN_MAX_WIDTH.S) return WEB_SCREEN_SIZES.S;
    if (width <= WEB_SCREEN_MAX_WIDTH.L) return WEB_SCREEN_SIZES.L;
    return WEB_SCREEN_SIZES.XL;
};

export const useCurrentWebScreenSize = () => {
    const { width: screenWidth } = useDimensions();
    return { screenSize: getWebSize(screenWidth) };
};
