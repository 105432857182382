import React from 'react';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, Interactable } from '@24i/nxg-sdk-quarks';
import { AddSubscriptionsContentProps } from './types';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import getAddSubscriptionsWebContent from './styles';

const AddSubscriptionsContent = ({
    description,
    isLoading,
    primaryButton,
    secondaryButton,
    styles: getStyles = getAddSubscriptionsWebContent,
}: AddSubscriptionsContentProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <>
            <Text style={styles.description}>{description}</Text>
            <LoadingWrapper
                isLoading={isLoading}
                loaderProps={{ size: 'small', color: theme.color.textPrimary }}
                wrapperStyle={styles.container}
            >
                <ActionButton
                    title={primaryButton.title}
                    onPress={primaryButton.onPress}
                    additionalContainerStyles={styles.primaryButton}
                />
                {secondaryButton ? (
                    <Interactable style={styles.secondaryButton} onPress={secondaryButton.onPress}>
                        <Text style={styles.secondaryText}>{secondaryButton.title}</Text>
                    </Interactable>
                ) : (
                    <></>
                )}
            </LoadingWrapper>
        </>
    );
};

export default AddSubscriptionsContent;
