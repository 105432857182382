import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';

const webStyles = (theme) => ({
    holder: {
        flex: 1,
        backgroundColor: theme.color.primary3,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        '@media (min-width: 801px)': {
            paddingVertical: 30,
            alignItems: 'center',
        },
        '@media (max-width: 800px)': {
            flexDirection: 'row',
            paddingHorizontal: 32,
            paddingVertical: 16,
        },
    },
    holderWithoutIcon: {
        justifyContent: 'flex-end',
    },
    innerHolder: {
        justifyContent: 'center',
        '@media (min-width: 801px)': { marginTop: 12 },
        '@media (max-width: 800px)': { marginLeft: 12 },
    },
    text: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
    },
    iconContainer: {},
    icons: {},
});

export default webStyles;
