import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import SingleRow from '@24i/nxg-sdk-gluons/src/components/containers/SingleRow/index.web';
import { SingleRowWebProps } from '@24i/nxg-sdk-gluons/src/components/containers/SingleRow/View/types';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Asset, PageSection } from '@24i/nxg-sdk-photon/src';
import { ThemeIcon, View } from '@24i/nxg-sdk-quarks';
import { SOTT_DEFAULT_WEB_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDetailsTabStyles } from '../styles';
import { DetailsTabPropsWeb } from '../types';

const defaultRenderSingleRow = (props: SingleRowWebProps) => <SingleRow {...props} />;

const DetailsTab = ({
    asset,
    renderSingleRow = defaultRenderSingleRow,
    getStyles = getDetailsTabStyles,
}: DetailsTabPropsWeb) => {
    const styles = getStyles();
    const router = useRouter();
    const { t } = useTranslation(['sott']);

    const onGenrePress = (item: Asset) => {
        const genreLink = SOTT_DEFAULT_WEB_SCREENS.Genre.getLink({
            id: item.id,
            genre: item.title || '',
            type: asset.type,
        });
        return router.push(genreLink.href, genreLink.as);
    };

    const mappedGenres = asset.genres?.map((item): Asset => {
        return {
            id: item.id || '',
            title: item.label || '',
            label: item.label || '',
            type: asset.type,
        };
    });

    const formatCrewItems = (crewList) =>
        crewList?.map((actor) => ({
            ...actor,
            poster: actor.images?.portrait?.[0]?.url || '',
            subtitle: actor.roleLabel,
            title: actor.name,
        })) || [];

    const genresSection: PageSection = {
        label: t('asset.genres.title', { count: mappedGenres?.length || 0 }),
        items: mappedGenres,
        isGenre: true,
        display: 'landscape',
        id: `${asset.id}-genres`,
    };

    const crewSection: PageSection = {
        label: t('asset.castAndCrew.title'),
        items: formatCrewItems(asset.crew),
        display: 'portrait',
        id: `${asset.id}-cast-and-crew`,
    };

    return (
        <View style={styles.content}>
            {genresSection.items &&
                genresSection.items.length > 0 &&
                renderSingleRow({
                    title: genresSection.label,
                    section: genresSection,
                    isGenre: !!genresSection.isGenre,
                    onItemPress: onGenrePress,
                    showSeeAll: false,
                })}
            {crewSection.items &&
                crewSection.items.length > 0 &&
                renderSingleRow({
                    title: crewSection.label,
                    section: crewSection,
                    isOnPackshotPressDisabled: true,
                    fallbackImage: (_theme) => (
                        <ThemeIcon
                            iconName="onPrimary-actor"
                            size={96}
                            iconStyles={{ opacity: 0.1 }}
                        />
                    ),
                    showSeeAll: false,
                })}
        </View>
    );
};

export { getDetailsTabStyles };
export default overridable(DetailsTab, 'DetailsTab');
