import { useTranslation } from 'react-i18next';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { OnboardingHeaderItemsEnum, OnboardingWebHeaderProps } from '../OnboardingWebHeader/types';

const useOnboardingHeader = (props): OnboardingWebHeaderProps => {
    const { items } = props;
    const { t } = useTranslation(['sott']);
    const router = useRouter();

    const onBack = (): void => {
        router?.back();
    };

    const defaultItems = [
        {
            key: OnboardingHeaderItemsEnum.Signout,
            name: OnboardingHeaderItemsEnum.Signout,
            title: t('auth.signOut.title'),
            icon: 'signOut',
        },
    ];

    return {
        onBack,
        items: items ?? defaultItems,
        ...props,
    };
};

export default useOnboardingHeader;
