import React, { FC } from 'react';

import View from './View';
import { useViewModel } from './viewModel';
import Screen from '../navigation/components/ApplicationScreen';
import type { MainProps } from './types';

const Main: FC<MainProps> = (props) => {
    const viewModelProps: Partial<MainProps> = useViewModel(props);
    return <View {...viewModelProps} />;
};
Main.displayName = 'ApplicationMain';

const Application = {
    Main,
    View,
    useViewModel,
    Screen,
};

export default Application;
