/* eslint-disable react/display-name */
import React, { Ref, ReactElement } from 'react';
import MenuItemView, { TopBarMenuItem as TopBarMenuItemView } from './View';
import { useViewModel } from './viewModel';
import { MenuItemProps } from './types';

export { getMenuItemStyles, getTopBarMenuItemStyles } from './View';

export const TopBarMenuItem = (props): ReactElement => {
    const viewModelProps = useViewModel(props);
    return <TopBarMenuItemView {...viewModelProps} />;
};

const MenuItem = React.forwardRef(
    (props: MenuItemProps, ref: Ref<typeof MenuItemView>): ReactElement => {
        const viewModelProps = useViewModel(props);
        return <MenuItemView ref={ref} {...viewModelProps} />;
    }
);

export default MenuItem;
