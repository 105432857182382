import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';

const AccountSubscription = (theme) => ({
    buttonContainer: {
        marginTop: 12,
        padding: 16,
        height: 'auto',
        flex: 1,
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        marginBottom: 30,
    },
    currentPlanTextStyles: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body1'),
        marginBottom: 4,
    },
    titleTextStyles: {
        ...getFont(theme, 'h4'),
        color: theme.color.textPrimary,
    },
    subtitleTextStyles: {
        color: theme.color.contrast2,
        ...getFont(theme, 'body1'),
    },
    innerContainer: {
        alignItems: 'flex-end',
    },
    innerContainerLandscape: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    insideSubscriptionButton: {
        flexDirection: 'row',
        marginBottom: 8,
        alignItems: 'center',
    },
    currencyText: {
        ...getFont(theme, 'h4'),
    },
    perMonthText: {
        ...getFont(theme, 'body1'),
        fontWeight: '600',
    },
});

export default AccountSubscription;
