import { Theme } from '@24i/nxg-sdk-photon/src';
import { ViewStyle, TextStyle } from 'react-native';
import LinearGradientProps from 'react-native-linear-gradient';

export enum MORE_SCREEN_AUTH_SECTION_TEST_IDS {
    LOG_IN_BUTTON = 'log_in_button',
    SIGN_UP_BUTTON = 'sign_up_button',
}

export interface MoreScreenAuthSectionProps {
    styles?: (theme: Theme) => MoreScreenAuthSectionStyles;
    onSignInPress: () => void;
    onRegisterPress?: () => void;
    linearGradientProps?: LinearGradientProps;
    setSignoutModalVisible?: (visible: boolean) => void;
}

export interface MoreScreenAuthSectionStyles {
    authSectionContainer: ViewStyle;
    authPrimaryButtonContainer: ViewStyle;
    authSecondaryButtonContainer: ViewStyle;
    authDescriptionText: TextStyle;
    primaryAuthButtonText: TextStyle;
    secondaryAuthButtonText: TextStyle;
    authSectionGradient?: ViewStyle;
    menuItemsContainer?: ViewStyle;
    mvpdAuthSignoutButtonContainer?: ViewStyle;
}
