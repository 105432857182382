import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useStore } from '../../context/ApplicationStore';

export type UseShouldSignIn = () => boolean;

export const useShouldSignIn = (): UseShouldSignIn => {
    const { userData } = useStore();
    const guestModeFeature = useFeature('guestMode');
    const signInEnabled = useFeature('signIn');
    const isUserLogged = !!userData;

    const isSignInEnabled = signInEnabled?.enabled || signInEnabled === undefined;

    const shouldSignIn = (): boolean =>
        !guestModeFeature?.allowWatchContent && !isUserLogged && isSignInEnabled;

    return shouldSignIn;
};
