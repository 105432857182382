/* eslint-disable class-methods-use-this */
import { AlertButton, AlertStatic } from 'react-native';

/**
 * This solution is based on this answer in react native web thread
 * https://github.com/necolas/react-native-web/issues/1026#issuecomment-687572134
 *  It was needed because currently Alert is not supported by
 *  the react native web library
 */
class Alert implements Pick<AlertStatic, 'alert'> {
    public alert(title: string, message?: string, buttons?: AlertButton[]): void {
        if (buttons === undefined || buttons.length === 0) {
            window.alert([title, message].filter(Boolean).join('\n'));
            return;
        }

        const result = window.confirm([title, message].filter(Boolean).join('\n'));

        if (result === true) {
            const confirm = buttons.find(({ style }) => style !== 'cancel');
            confirm?.onPress?.();
            return;
        }

        const cancel = buttons.find(({ style }) => style === 'cancel');
        cancel?.onPress?.();
    }
}

export default new Alert();
