import { TextStyle, ViewStyle } from 'react-native';
import { Theme } from '@24i/nxg-sdk-photon';
import { StylesGetter } from '../../utils/styles/types';

export interface ToggleItemStyles {
    mainContainer: ViewStyle;
    settingsContainer: ViewStyle;
    textContainer: ViewStyle;
    toggleContainer: ViewStyle;
    descriptionContainer: ViewStyle;
    title: TextStyle;
    description: TextStyle;
    toggleText: TextStyle;
    divider: ViewStyle;
}

export interface ToggleItemProps {
    title: string;
    description?: string | null;
    defaultValue?: boolean;
    testID?: string;
    withDivider?: boolean;
    onValueChange: (value: boolean) => Promise<void> | void;
    styles?: StylesGetter<Theme, ToggleItemStyles>;
    thumbColor?: string;
}

export enum TOGGLE_ITEM_TEST_ID {
    TOGGLE_ITEM = 'testId_toggle_component',
    DESCRIPTION_TEXT = 'toggle_item_description_text',
    DIVIDER = 'toggle_item_divider',
}
