Object.defineProperty(exports,"__esModule",{value:true});exports.useExperienceUserData=void 0;var _UserData=require("@24i/nxg-sdk-smartott-shared/src/context/UserData");


var isFunction=function isFunction(obj){return function(key){
return typeof obj[key]==='function';
};};

var isExperienceUserDataClient=function isExperienceUserDataClient(obj){
return[
'loginWithBackstage',
'logoutWithBackstage',
'fetchBackstageUser',
'getBackstageOrganisationListForUser',
'getBackstageServicesForUser',
'getBackstageApplicationsForService'].
every(isFunction(obj));
};







var useExperienceUserData=function useExperienceUserData(){
var context=(0,_UserData.useUserData)();

if(!isExperienceUserDataClient(context)){
throw new Error(
'The context was not of the expected type. `ExperienceUserDataClient` expected.'
);
}

return context;
};exports.useExperienceUserData=useExperienceUserData;