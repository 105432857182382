import React, { useEffect } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { withSignoutModal, SignoutModalProps } from '../../../components/SignOut';

const SignoutScreen = ({ setSignoutModalVisible }: SignoutModalProps) => {
    useEffect(() => {
        setSignoutModalVisible(true);
    }, []);
    return <></>;
};

export default overridable(withSignoutModal(SignoutScreen), 'SignoutScreen');
