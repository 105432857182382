import { getFont, Ratio } from '@24i/nxg-sdk-higgs';
import { ColorValue } from 'react-native';
import { shouldDisplayDesktopVersion } from '../../../../../utils/styles/helpers';
import { TitleStyleGetter } from './types';

const getTitleStyles: TitleStyleGetter = ({ theme, dimensions }) => {
    let paddingTop = Ratio(20);
    let switchMarginLeft = 6;

    if (shouldDisplayDesktopVersion(dimensions)) {
        paddingTop = 40;
        switchMarginLeft = 12;
    }

    return {
        container: {
            paddingTop,
            paddingBottom: Ratio(6),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        titleLabel: {
            flex: 1,
        },
        label: {
            color: <ColorValue>theme.color.textPrimary,
            fontWeight: 'bold',
            ...getFont(theme, 'h4'),
            marginRight: Ratio(8),
        },
        switchContainer: {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        switchInteractable: {
            width: Ratio(80),
        },
        switchInteractableThumb: {
            height: Ratio(34),
            width: Ratio(36),
        },
        switchInteractableBorder: {
            height: Ratio(42),
            width: Ratio(80),
            borderWidth: 4,
        },
        switchInteractableFocused: {
            width: Ratio(36),
        },
        switch: {
            marginLeft: switchMarginLeft,
        },
    };
};

export default getTitleStyles;
