const getDetailsContentStyles = (theme) => ({
    content: {
        flexDirection: 'row',
        marginTop: '15%',
        flex: 1,
        '@media (max-width: 1200px)': {
            marginTop: '27%',
        },
        flexGrow: 1,
    },
    assetLabelContainer: {
        position: 'absolute',
        top: -11,
    },
    description: {
        marginTop: 16,
        opacity: 0.8,
    },
    subtitleView: {
        marginTop: 16,
        flexDirection: 'row',
        width: '100%',
    },
    leftCon: {
        maxWidth: 760,
        width: '100%',
        '@media (max-width: 1200px)': {
            maxWidth: 640,
        },
    },
    actionButtonContainer: {
        marginBottom: 32,
        marginRight: 12,
        flexDirection: 'row',
        alignItems: 'center',
    },
    emptyActionButtonContainer: {
        marginTop: 0,
        marginBottom: 10,
    },
    additionalMovieActionButtonContainerStyles: {
        alignSelf: 'flex-start',
        width: 'auto',
        paddingLeft: 20,
        paddingRight: 25,
    },
    additionalEpgActionButtonContainerStyles: {
        alignSelf: 'flex-start',
        width: 'auto',
        paddingLeft: 20,
        paddingRight: 25,
    },
    additionalSeriesActionButtonContainerStyles: {
        alignSelf: 'flex-start',
        width: 'auto',
        paddingLeft: 20,
        paddingRight: 25,
    },
    additionalLiveActionButtonContainerStyles: {
        alignSelf: 'flex-start',
        width: 'auto',
        paddingLeft: 20,
        paddingRight: 25,
    },
    additionalActionButtonContainerStyles: {
        alignSelf: 'flex-start',
        width: 'auto',
        paddingLeft: 20,
        paddingRight: 25,
    },
    addToListButtonContainer: {
        height: 54,
        width: 54,
        paddingLeft: 12,
        paddingRight: 12,
        '@media (max-width: 1200px)': {
            height: 52,
            width: 52,
            paddingLeft: 12,
            paddingRight: 12,
        },
        backgroundColor: theme.color.buttonSecondary,
    },
    remindButtonContainer: {
        height: 54,
        paddingLeft: 24,
        alignSelf: 'flex-start',
        width: 'auto',
    },
    additionalSecondaryButtonContainerStyles: {
        marginRight: 6,
    },
    replayButtonContainer: {
        marginLeft: 12,
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 12,
        paddingRight: 12,
        '@media (max-width: 1200px)': {
            paddingLeft: 8,
            paddingRight: 8,
        },
        backgroundColor: theme.color.buttonSecondary,
    },
    loaderWrapper: {
        paddingRight: 12,
    },
    tertiaryButtonContainer: {
        marginTop: 24,
        height: 42,
        justifyContent: 'space-evenly',
        paddingLeft: 18,
        paddingRight: 18,
        marginRight: 12,
        width: 'auto',
        alignSelf: 'flex-start',
    },
});
export default getDetailsContentStyles;
