import React from 'react';
import { usePinProtectedScreen } from '@24i/nxg-sdk-pin-protection/src/hooks/usePinProtectedScreen';
import View from './View';
import { useViewModel } from './viewModel';
import { MySubscriptionScreenProps } from './types';

export default {
    Main: (props: MySubscriptionScreenProps) => {
        const viewModelProps = useViewModel(props);
        usePinProtectedScreen(viewModelProps);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
};
