import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs/src/hooks/useTheme';
import BackButton from '@24i/nxg-sdk-gluons/src/components/buttons/BackButton';
import { View, Text } from '@24i/nxg-sdk-quarks';
import CommonLayout from '../components/CommonLayout';
import { ViewModelReturnType } from '../types';
import getStyles from '../styles/index.web';

const ForgotPasswordScreen = (props: ViewModelReturnType) => {
    const { onBackToSignIn } = props;

    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.wrapper}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.backArrowContainer}>
                        <BackButton
                            onPress={onBackToSignIn}
                            iconName="onPrimary-back"
                            iconSize={24}
                        />
                    </View>
                    <Text style={styles.headerText}>{t('password.forgot.title')}</Text>
                </View>
                {CommonLayout(props)}
            </View>
        </View>
    );
};

export default overridable(ForgotPasswordScreen, 'ForgotPasswordScreen');
