import {
    Asset,
    ASSET_TYPE,
    BlockedItem,
    EntitlementsClient,
    BLOCKING_REASON_TYPES,
    getIsPodcastEpisode,
} from '@24i/nxg-sdk-photon';
import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { ErrorCodes } from '@24i/nxg-sdk-smartott/src/utils/errorCodesMapper/types';
import { BackstageApiBase } from '../../base';
import { ContentDataClient } from '../ContentDataClient';

const getBlocker = (blocker: BlockedItem[] | null | undefined): BlockedItem | undefined => {
    if (!blocker) {
        return undefined;
    }

    const subscriptionBlockers = blocker?.filter(
        (obj) => obj.reason === BLOCKING_REASON_TYPES.SUBSCRIPTION_TYPE
    )?.[0];

    const geoBlockers = blocker?.filter(
        (obj) => obj.reason === BLOCKING_REASON_TYPES.GEO_TYPE
    )?.[0];

    if (subscriptionBlockers) {
        return subscriptionBlockers;
    }

    if (geoBlockers) {
        return geoBlockers;
    }

    return undefined;
};

export interface BackstageEntitlementsClientParams {
    contentDataClient: ContentDataClient;
}

export class BackstageEntitlementsClient extends BackstageApiBase implements EntitlementsClient {
    contentDataClient: ContentDataClient;

    constructor(params: BaseApiParams, contentClient: ContentDataClient) {
        super(params);
        this.contentDataClient = contentClient;
    }

    getCurrentBlockerAsset = async (asset: Asset): Promise<BlockedItem | undefined> => {
        // IMPORTANT: only geo blockers and legacy subscriptions
        let blocker = await this.contentDataClient.checkBlockers(asset);
        try {
            await this.contentDataClient.fetchEditions(asset);
        } catch (error) {
            if ((error as Error).message === ErrorCodes.INVALID_AUTHENTICATION_TOKEN) {
                blocker = [{ reason: BLOCKING_REASON_TYPES.SUBSCRIPTION_TYPE }];
            }
        }

        return getBlocker(blocker);
    };

    getCurrentBlocker = (blocker: BlockedItem[]): BlockedItem | undefined => {
        return getBlocker(blocker);
    };

    checkForBlocker = async (
        asset: Asset
    ): Promise<{ blocker: BlockedItem | undefined; geoBlocked: boolean }> => {
        const selectedAsset =
            asset?.type === ASSET_TYPE.BROADCAST && asset?.channelId
                ? { id: asset.channelId, type: ASSET_TYPE.CHANNEL }
                : asset;

        let blocker = await this.getCurrentBlockerAsset(selectedAsset);
        const isPodcastEpisode = getIsPodcastEpisode(asset);

        // This should probably be validated in Backstage, but
        // for now it will work https://jira.24i.com/browse/PRDSAPPSRN-1718
        if (!blocker && asset.type === ASSET_TYPE.EPISODE && asset.seriesId) {
            const typeToCheck = isPodcastEpisode ? ASSET_TYPE.PODCAST_SERIES : ASSET_TYPE.SERIES;
            blocker = await this.getCurrentBlockerAsset({
                id: asset.seriesId,
                type: typeToCheck,
            });
        }
        const geoBlocked = blocker?.reason === BLOCKING_REASON_TYPES.GEO_TYPE;

        return { blocker, geoBlocked };
    };
}

export const createBackstageEntitlementsClient = (
    params: BaseApiParams,
    contentClient: ContentDataClient
) => {
    return new BackstageEntitlementsClient(params, contentClient);
};
