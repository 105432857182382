import { createGuard, decodeModelWith } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const FavoriteGuard = createGuard('Favorite', {
    id: t.string,
    entityType: t.string,
    entityId: t.string,
    userId: t.string,
    profileId: t.string,
});

export type Favorite = t.TypeOf<typeof FavoriteGuard>;

export const isFavorite = (data: unknown): data is Favorite => {
    try {
        return !!decodeModelWith(FavoriteGuard, data, 'Favorite', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createFavorite = (data: unknown): Favorite | never => {
    return decodeModelWith(FavoriteGuard, data, 'Favorite');
};
