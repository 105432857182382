import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Broadcast, getIsPodcastEpisode } from '@24i/nxg-sdk-photon';
import _ from 'lodash';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useRemindersDataClient } from '@24i/nxg-sdk-smartott-shared/src/context/Reminders';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { usePodcastPlayer } from '../../../context/PodcastPlayerProvider';

import { useShared } from './useShared';
import { utilsOnRemindPress } from './utils';
import { DetailsScreenProps, DetailsViewProps } from '../types';

const useViewModel = (props: DetailsScreenProps): DetailsViewProps => {
    const { t } = useTranslation();
    const { selectedProfile } = useStore();
    const { crashlyticsLog } = useFirebase();
    const router = useRouter();
    const {
        closePlayer: closePodcastPlayer,
        getIsEpisodeInPlayer: getIsPodcastEpisodeInPlayer,
        playPodcastEpisode,
    } = usePodcastPlayer();

    const shared = useShared(props);
    const { reminderIsSet, setReminderIsSet, logReminderError, episodeToPlay, seasons } = shared;
    const { remindersDataClient } = useRemindersDataClient();

    useEffect(() => {
        crashlyticsLog('Details screen entered');
        return crashlyticsLog('Details screen exited');
    }, []);

    async function onRemindPress(broadcast: Broadcast): Promise<void> {
        if (selectedProfile) {
            const { id: profileId } = selectedProfile;
            await utilsOnRemindPress(
                broadcast,
                profileId,
                remindersDataClient,
                reminderIsSet,
                setReminderIsSet,
                logReminderError,
                t
            );
        }
    }

    const onCloseErrorModal = () => router?.back();

    const removeQueryParam = useCallback(
        (param: string) => {
            const { pathname, query } = router;
            const params = new URLSearchParams(query as Record<string, string>);
            params.delete(param);
            router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
        },
        [router]
    );

    useEffect(() => {
        if (episodeToPlay && seasons) {
            const episodeIsAlreadyPlaying = getIsPodcastEpisodeInPlayer(episodeToPlay);
            if (episodeIsAlreadyPlaying) return;

            const episodeData = seasons
                ?.flatMap((season) => season.episodes)
                ?.find((episode) => episode.id === episodeToPlay);

            if (episodeData && getIsPodcastEpisode(episodeData)) {
                closePodcastPlayer();
                playPodcastEpisode(episodeData);
                removeQueryParam('episodeToPlay');
            }
        }
    }, [
        episodeToPlay,
        getIsPodcastEpisodeInPlayer,
        seasons,
        playPodcastEpisode,
        removeQueryParam,
        closePodcastPlayer,
    ]);

    return {
        ...shared,
        ...props,
        onRemindPress,
        onCloseErrorModal,
    };
};

export { useViewModel };
