import React from 'react';
import { currentTimeLineProps } from './types';
import { View } from '@24i/nxg-sdk-quarks';

const CurrentTimeLine = (props: currentTimeLineProps) => {
    const { timelinePosition, height = 0, theme = {} } = props;

    return (
        <View>
            <View
                style={{
                    left: timelinePosition,
                    height,
                    ...theme.line,
                }}
                pointerEvents="none"
            />
            <View
                style={{
                    left: timelinePosition - 5,
                    ...theme.arrow,
                }}
                pointerEvents="none"
            />
        </View>
    );
};

export default CurrentTimeLine;
