import type {
    AdobePrimetimeAuthState,
    AuthorizationStatus,
    SetAuthState,
    SetMVPDS,
    BackstageTierOneMVPD,
} from '../shared/types';
import {
    ACCESS_ENABLER_AUTH_CODE,
    ACCESS_ENABLER_STATUS,
    AUTHORIZATION_STATUS,
    SERVICE_PROVIDERS,
} from '../shared/accessEnablerConstants';
import { adobePrimetimeJSDevDebugLogger, parseMvpdsLists } from '../shared/utils';
import { ACCESS_ENABLER_CDN_URL, redirectUrl } from '../adobePrimetimeLocalConfig';
import type { DisplayProviderDialog, SetMvpdSignInPath } from './types';

const { SUCCESS } = ACCESS_ENABLER_STATUS;
const { USER_AUTHENTICATED } = ACCESS_ENABLER_AUTH_CODE;

export const initialise = (
    softwareStatment: string,
    requestorId: string,
    serviceProvider: SERVICE_PROVIDERS,
    setAuthState: SetAuthState,
    _redirectUrl: string,
    setMvpds: SetMVPDS,
    backstageTierOneMvpds: BackstageTierOneMVPD[]
): void => {
    let script = document.querySelector(`script[src="${ACCESS_ENABLER_CDN_URL}"]`);

    const handleScript = (e: Event): void => {
        if (e.type === 'load') {
            if (window.AccessEnabler) {
                window.AE = new window.AccessEnabler.AccessEnabler(softwareStatment);
            }
        } else if (e.type !== 'load') {
            if (__DEV__) adobePrimetimeJSDevDebugLogger(1);
        }
    };

    if (!script) {
        script = document.createElement('script');
        // @ts-expect-error type, src and async are not typeable property for Element of any kind
        script.type = 'application/javascript';
        // @ts-expect-error
        script.src = ACCESS_ENABLER_CDN_URL;
        // @ts-expect-error
        script.async = true;
        document.body.appendChild(script);
        script.addEventListener('load', handleScript);
        script.addEventListener('error', handleScript);
    }

    const setRequestorComplete = (status: ACCESS_ENABLER_STATUS): void => {
        setAuthState(
            (prevState): AdobePrimetimeAuthState => ({
                ...prevState,
                initialising: false,
                initialised: status,
            })
        );
    };

    window.entitlementLoaded = () => {
        window.AE?.setRequestor(requestorId, [serviceProvider]);
    };

    window.setConfig = (list: XMLHttpRequestResponseType) => {
        if (list) setRequestorComplete(1);
        const parsedMvpdList = parseMvpdsLists(list, backstageTierOneMvpds);
        if (parsedMvpdList) setMvpds(parsedMvpdList);
    };

    window.setAuthenticationStatus = (
        status: ACCESS_ENABLER_STATUS,
        code: ACCESS_ENABLER_AUTH_CODE
    ): void => {
        setAuthState(
            (prevState): AdobePrimetimeAuthState => ({
                ...prevState,
                authenticating: false,
                authenticated: status,
                authCode: status === SUCCESS ? USER_AUTHENTICATED : code,
            })
        );
    };

    window.createIFrame = (width: string, height: string): void => {
        const mvpdWindow = window.open(
            '',
            'mvpdframe',
            `width=${width},height=${height},top=t,left=l`
        );
        if (!mvpdWindow) {
            console.log(
                `There is no onCreateIframe callback and window.open didn't return anyting.`
            );
        }
    };

    window.sendTrackingData = (trackingEventType: string, trackingData: any): void => {
        console.log('SEND TRACKING DATA', trackingEventType, trackingData);
        console.log('TRACKING EVENT TYPE', typeof trackingEventType, trackingEventType);
        console.log('TRACKING DATA', typeof trackingData, trackingData);
    };

    window.selectedProvider = () => {};

    window.displayProviderDialog = () => {};
};

export const checkForAuthentication = (setAuthState: SetAuthState): void => {
    setAuthState(
        (prevState): AdobePrimetimeAuthState => ({
            ...prevState,
            authenticating: true,
        })
    );

    window.AE?.checkAuthentication();
};

export function getAuthentication(
    _forceAuthn = false,
    _displayProviderDialogCallback: DisplayProviderDialog
): void {
    window.AE?.getAuthentication(redirectUrl);
}

export const setProvider = (id: string): void => {
    window.AE?.setSelectedProvider(id);
};

export const signOut = (
    _setMvpdSignInPath: SetMvpdSignInPath,
    _redirectUrl: string,
    _setAuthState: SetAuthState
): void => {
    window.AE?.logout();
};

export function getSelectedProvider() {
    window.AE?.getSelectedProvider();
}

export const checkAuthorizationForResource = (resourceID: string): AuthorizationStatus => {
    return new Promise((resolve, _reject) => {
        window.tokenRequestFailed = (_resource: string, code: string, description: string) =>
            resolve({
                status: AUTHORIZATION_STATUS.FAILURE,
                code: description ? `${code}: ${description}` : code,
            });
        window.setToken = (_resource: string, token: string) =>
            resolve({ status: AUTHORIZATION_STATUS.SUCCESS, code: token });
        window.AE?.checkAuthorization(resourceID);
    });
};
