/*
@PRODUCT_FEATURE: J 1.2.0
*/
/* eslint-disable react/display-name */
import React from 'react';

import * as prerenderFunctions from '../../pages/recently-watched';
import View from './View';
import useViewModel from './viewModel';

export default {
    Main: (props) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    page: {
        'recently-watched': prerenderFunctions,
    },
};
