import { QUERY_KEYS, Profile } from '@24i/nxg-sdk-photon/src';
import { useQuery } from 'react-query';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useStore } from '../../context/ApplicationStore';

export const useProfilesQuery = () => {
    const client = useUserData();
    const { selectedProfile, setProfilesAndPossiblySelectOne } = useStore();

    const { isLoading, data, refetch } = useQuery<Profile[]>(
        QUERY_KEYS.profiles,
        () => client.fetchUserProfiles(),
        {
            onSuccess: (profiles) => {
                setProfilesAndPossiblySelectOne(profiles);
            },
        }
    );

    return {
        isLoading,
        profiles: data,
        selectedProfile,
        refetch,
    };
};
