import { StyleSheet } from 'react-native';
import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { CustomItemTitleStyles } from '../types';

export const getCustomItemTitleStyles = (theme: Theme, _?: number): CustomItemTitleStyles =>
    StyleSheet.create({
        textWrapper: {
            marginTop: 6,
            justifyContent: 'flex-start',
            height: 70,
        },
        textContainer: {
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: theme.color.gradient2.color,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 20,
            paddingBottom: 20,
            ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        },
        titleContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        customItemSubtitle: {
            textAlign: 'center',
            paddingTop: 2,
            color: theme.color.textSecondary,
            ...getFont(theme, 'h5'),
            opacity: 0.6,
        },
        titleBelow: {
            fontWeight: 'bold',
            paddingTop: 2,
            color: theme.color.textPrimary,
            ...getFont(theme, 'h4'),
        },
        titleOver: {
            textAlign: 'center',
            fontWeight: 'bold',
            paddingTop: 2,
            color: theme.color.textSecondary,
            ...getFont(theme, 'h4'),
        },
        itemTextSubs: {
            textAlign: 'left',
            paddingTop: 2,
            color: `${theme.color.textPrimary}99`,
            ...getFont(theme, 'h5'),
        },
    });
