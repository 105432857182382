import React from 'react';
import View, { WelcomeScreenProps } from './View';
import { useViewModel, WelcomeScreenViewModelProps } from './viewModel';

const Main = (props: WelcomeScreenViewModelProps) => {
    const viewModelProps: WelcomeScreenProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default {
    Main,
};
