import { SupportedSubscriptionsProvider, ModularSubscriptionsElements } from './types';
import { CleengPurchaseScreen } from '../../components/Cleeng/ModularScreens/CleengPurchaseScreen';
import { CleengPaymentMethodScreen } from '../../components/Cleeng/ModularScreens/CleengPaymentMethodScreen';
import { CleengPaymentHistoryScreen } from '../../components/Cleeng/ModularScreens/CleengPaymentHistoryScreen';
import { CleengProvider } from '../CleengProvider';

export const subscriptionProviderElementsMap: Record<
    SupportedSubscriptionsProvider,
    ModularSubscriptionsElements
> = {
    cleeng: {
        PurchaseScreen: CleengPurchaseScreen,
        PaymentMethodScreen: CleengPaymentMethodScreen,
        PaymentHistoryScreen: CleengPaymentHistoryScreen,
        ConfigProvider: CleengProvider,
    },
};
