import { createGuard, decodeModelWith } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { RATING_SYSTEM_GUARD } from '../enums';

export const RatingResponseGuard = createGuard('RatingResponse', {
    id: t.string,
    userId: t.string,
    assetId: t.string,
    profileId: t.string,
    rating: t.number,
});

export const RatingGuard = createGuard('Rating', {
    rating: t.number,
    ratingSystem: RATING_SYSTEM_GUARD,
});

export type Rating = t.TypeOf<typeof RatingGuard>;

export type RatingResponse = t.TypeOf<typeof RatingResponseGuard>;

export const createRating = (data: unknown): Rating | never => {
    return decodeModelWith(RatingGuard, data, 'Rating');
};
