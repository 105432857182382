import React, { forwardRef } from 'react';
import { Text as RNText } from 'react-native-web';
import StyleSheet from 'react-native-media-query';
import { TextProps, TextRefType } from './types';

const Text = forwardRef<TextRefType, TextProps>(({ style, ...props }, ref) => {
    const { ids, styles } = StyleSheet.process(style);
    return <RNText ref={ref} style={styles} dataSet={{ media: ids }} {...props} />;
});

Text.displayName = 'Text_Quarks';

export default Text;
