import platform from './api/platform';
import { Platform } from './constants';

export const isPlatformAndroid = platform === Platform.android;
export const isPlatformAndroidtv = platform === Platform.androidtv;
export const isPlatformFiretv = platform === Platform.firetv;
export const isPlatformIos = platform === Platform.ios;
export const isPlatformTvos = platform === Platform.tvos;
export const isPlatformWeb = platform === Platform.web;
export const isPlatformNative = isPlatformAndroid || isPlatformIos;
