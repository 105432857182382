import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, ThemeIcon, View } from '@24i/nxg-sdk-quarks';

import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import getRemoveDeviceStyles from '../styles';

const RemoveDevice = (props) => {
    const {
        confirmText,
        cancelText,
        deviceId,
        removeTitle,
        removeSubtitle,
        isLandscape,
        onRemovePress,
        onCancelPress,
        selectedDevice,
        styles: getStyles,
    } = props;
    const { registered, os, name } = selectedDevice;
    const { theme } = useTheme();
    const styles = getStyles(theme);

    let icon = null;

    switch (os) {
        case 1:
            icon = 'onPrimary-device_smartphone';
            break;
        case 2:
            icon = 'onPrimary-device_laptop';
            break;
        case 3:
            icon = 'onPrimary-device_tv';
            break;
        case 4:
            icon = 'onPrimary-device_tablet';
            break;
        case 5:
            icon = 'onPrimary-device_laptop';
            break;
        default:
            icon = 'onPrimary-device_laptop';
    }

    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={styles.removeTitleText}>{removeTitle}</Text>
            {removeSubtitle && <Text style={styles.removeSubtitleText}>{removeSubtitle}</Text>}
            <View style={isLandscape ? styles.DevicesContainerLandscape : styles.DevicesContainer}>
                <ThemeIcon iconName={icon} size={24} iconStyles={styles.deviceIcon} />
                <View>
                    <Text style={styles.title}>{name}</Text>
                    <Text style={styles.text}>
                        {`Added ${registered.toLocaleDateString('en-US', {
                            minute: '2-digit',
                            hour: 'numeric',
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        })}`}
                    </Text>
                </View>
            </View>
            <ActionButton
                title={confirmText}
                onPress={() => onRemovePress(deviceId)}
                additionalContainerStyles={[
                    styles.additionalActionButtonContainerStyles,
                    styles.primaryActionButtonContainerStyles,
                ]}
                additionalTextStyles={[
                    styles.additionalActionButtonTextStyles,
                    styles.primaryActionButtonTextStyles,
                ]}
            />
            <ActionButton
                title={cancelText}
                onPress={onCancelPress}
                additionalContainerStyles={[
                    styles.additionalActionButtonContainerStyles,
                    styles.secondaryButtonContainerStyles,
                ]}
                additionalTextStyles={[
                    styles.additionalActionButtonTextStyles,
                    styles.secondaryButtonTextStyles,
                ]}
            />
        </View>
    );
};

RemoveDevice.defaultProps = {
    styles: getRemoveDeviceStyles,
};

RemoveDevice.propTypes = {
    styles: PropTypes.func,
};

export { getRemoveDeviceStyles };

const LocalizedDeviceManagement = overridable(RemoveDevice, 'RemoveDevice');
export default LocalizedDeviceManagement;
