import React from 'react';
import { Interactable, View, Text, NavigationIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getStyles from './styles';
import { SettingsButtonLargeProps } from './types';
import { normalizeTestId } from '../../../utils/testing';

const SettingsButtonLarge = ({ title, iconName, onPress, iconSize }: SettingsButtonLargeProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <Interactable
            activeOpacity={0.7}
            onPress={onPress}
            testID={normalizeTestId(title, 'button')}
        >
            <View style={styles.optionButton}>
                <View style={styles.buttonContainer}>
                    <NavigationIcon iconName={iconName} size={iconSize} />
                    <Text style={styles.subheaderText}>{title}</Text>
                </View>
            </View>
        </Interactable>
    );
};

export default SettingsButtonLarge;
