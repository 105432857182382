import React, { useState } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { TextInput, View, Text } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import { NUMBER_OF_PIN_INPUTS } from '@24i/nxg-core-utils/src/constants';
import SequentialInput from '../../SequentialInput';
import getPinInputStyles from '../styles';
import ProfileItem from '../../PinProfileItem';

import { PinInputModalContentViewProps } from '../types';

const PinInputModalContent = ({
    styles: getStyles = getPinInputStyles,
    additionalInputStyles = {},
    message = '',
    title = '',
    profileWithPin,
    shouldDisplayTitle = true,
    isIncorrectPin,
    isLoading,
    defaultMessage,
    onFinish,
}: PinInputModalContentViewProps) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme, undefined);
    const [returnFocus, setReturnFocus] = useState(false);

    const blurPinInput = () => {
        setReturnFocus(true);
    };

    const resetReturnFocus = () => {
        setReturnFocus(false);
    };

    return (
        <TouchableWithoutFeedback onPress={blurPinInput}>
            <View style={styles.modalContainer}>
                <View style={styles.modalFrame}>
                    {shouldDisplayTitle && (
                        <Text style={styles.heading}>{title || t('pin.enter.title')}</Text>
                    )}
                    {profileWithPin && (
                        <View style={styles.profileContainer}>
                            <ProfileItem profile={profileWithPin} />
                        </View>
                    )}
                    <Text style={styles.pleaseEnterText}>{message || defaultMessage}</Text>
                    <View style={styles.sequentialInputContainer}>
                        <SequentialInput
                            secureTextEntry
                            renderInput={({ value, focused }) => {
                                let inputStyle = {};
                                if (focused)
                                    inputStyle = {
                                        ...styles.focusedInput,
                                    };
                                return (
                                    <View
                                        style={[
                                            styles.inputWrapper,
                                            additionalInputStyles,
                                            inputStyle,
                                        ]}
                                    >
                                        <TextInput
                                            value={value}
                                            style={styles.input}
                                            secureTextEntry
                                        />
                                    </View>
                                );
                            }}
                            numberOfInputs={NUMBER_OF_PIN_INPUTS}
                            lengthOfSingleInput={1}
                            onFinish={onFinish}
                            inputFilter={(input) => /^\d+$/.test(input)}
                            focusUnderlineStyle={styles.focusUnderline}
                            isIncorrectPin={isIncorrectPin}
                            returnFocus={returnFocus}
                            resetReturnFocus={resetReturnFocus}
                        />
                    </View>
                    <View style={styles.incorrectTextWrapper}>
                        {!isLoading && isIncorrectPin && (
                            <Text style={styles.incorrectText}>{t('pin.enter.failure')}</Text>
                        )}
                        {isLoading && (
                            <Loader
                                size={30}
                                additionalStyles={styles.loader}
                                color={theme.color.textPrimary}
                                disableImageBackground
                            />
                        )}
                    </View>
                    <Text style={styles.forgotButtonText}>{t('pin.enter.forgotPin')}</Text>
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
};

export default PinInputModalContent;
