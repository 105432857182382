import { useState, useEffect } from 'react';
import { AdditionalItems, Asset, ASSET_TYPE } from '@24i/nxg-sdk-photon/src';
import { DeviceInfo, useDimensions } from '@24i/nxg-sdk-quantum';
import { ORIENTATIONS } from '@24i/nxg-core-utils/src/constants';
import { BannerCarouselTypes } from '../types';

const useViewModel = (props: BannerCarouselTypes): BannerCarouselTypes => {
    const { section, itemLimit } = props;
    const [items, setItems] = useState<Asset[] | undefined>(undefined);
    const { isLandscape } = useDimensions();
    let orientation = ORIENTATIONS.PORTRAIT;
    if (DeviceInfo.isTablet() && isLandscape) {
        orientation = ORIENTATIONS.LANDSCAPE;
    }
    const isPortraitPhone = !DeviceInfo.isTablet() && orientation === ORIENTATIONS.PORTRAIT;
    const { label } = section;
    const transformSection = (): void => {
        // We should never display more then 8 assets in the Hero
        const sectionItems = section.items?.slice(0, Math.min(itemLimit, 8)) || [];
        if (section.additionalItems && section.additionalItems.length > 0) {
            const { additionalItems } = section;
            additionalItems
                .slice()
                .reverse()
                .forEach(
                    (
                        {
                            id,
                            title,
                            description,
                            position,
                            textPlacement,
                            type,
                            target,
                            images,
                        }: AdditionalItems,
                        i
                    ) => {
                        const addItem: Asset = {
                            id: id || `${i}`,
                            title,
                            description,
                            background: images?.[0].url,
                            textPlacement,
                            type: type as ASSET_TYPE,
                            target,
                        };
                        if (position === 'start') sectionItems.unshift(addItem);
                        if (position === 'end') sectionItems.push(addItem);
                    }
                );
        }
        setItems(sectionItems);
    };

    useEffect(() => {
        transformSection();
    }, [section]);

    return {
        ...props,
        items,
        isPortraitPhone,
        label,
    };
};

export { useViewModel };
