/**
 * Query key factory
 */
export const queryKeys = {
    assets: ['assets'] as const,
    asset: (assetId: string) => [...queryKeys.assets, assetId] as const,
    assetPurchases: (assetId: string) => [...queryKeys.asset(assetId), 'purchases'] as const,
    myRentals: ['myRentals'] as const,
    myExpiredRentals: () => [...queryKeys.myRentals, 'expired'] as const,
    myPurchases: ['myPurchases'] as const,
    isAssetPurchased: (assetId: string) => [...queryKeys.myPurchases, assetId] as const,
};
