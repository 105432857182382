import React from 'react';

import View from './View';
import { useViewModel } from './viewModel';

export { default as AccountSubscriptionContent } from './components/AccountSubscriptionContent';
export { default as ChangeSubscription } from './components/ChangeSubscription';
export { default as SubactionButton } from './components/SubscriptionButton';
export { default as View, getAccountSubscriptionStyles } from './View';
export { useViewModel } from './viewModel';

const Main = (props) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default {
    Main,
    View,
    useViewModel,
};
