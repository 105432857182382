import React from 'react';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { WithSubscriptionsListScreenQuery } from './types';
import { useViewModel } from './viewModel';
import View from './View';

// Subscriptions-list needed for the purchse-flow initiated from an asset-detail page.
export default {
    Main: (props: WithSubscriptionsListScreenQuery & Partial<ScreenProps>) => {
        const viewModelProps = useViewModel(props);

        return <View {...viewModelProps} />;
    },

    View,
};
