import { extendGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ASSET_TYPE } from '../enums';
import { AssetGuard } from './asset';
import { BroadcastGuard } from './broadcast';

export const LiveEventGuard = extendGuard(
    'LiveEvent',
    AssetGuard,
    {
        channelId: t.string,
        startsAt: t.number,
        endsAt: t.number,
        programs: nullable(t.array(BroadcastGuard)),
        number: t.number,
        label: t.string,
    },
    {}
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLiveEvent = (asset: any): asset is LiveEvent =>
    asset && asset.type === ASSET_TYPE.LIVE_EVENT;

export type LiveEvent = t.TypeOf<typeof LiveEventGuard>;
