import { createGuard, decodeModelWith } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const DevicesManagementGuard = createGuard('DeviceManagemnent', {
    deviceId: t.string,
    name: t.string,
    registrationDate: t.string,
    platform: t.string,
});

export type Device = t.TypeOf<typeof DevicesManagementGuard>;

export const createDevice = (data: unknown): Device | never => {
    return decodeModelWith(DevicesManagementGuard, data, 'Device');
};
