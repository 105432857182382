var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.createExperienceAppVersionClient=exports.ExperienceAppVersionClient=void 0;var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));var _createClass2=_interopRequireDefault(require("@babel/runtime/helpers/createClass"));var _classCallCheck2=_interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));var _inherits2=_interopRequireDefault(require("@babel/runtime/helpers/inherits"));var _possibleConstructorReturn2=_interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));var _getPrototypeOf2=_interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _nxgIntegrationBackstage=require("@24i/nxg-integration-backstage");function _createSuper(t){var r=_isNativeReflectConstruct();return function(){var e,o=(0,_getPrototypeOf2.default)(t);if(r){var s=(0,_getPrototypeOf2.default)(this).constructor;e=Reflect.construct(o,arguments,s);}else e=o.apply(this,arguments);return(0,_possibleConstructorReturn2.default)(this,e);};}function _isNativeReflectConstruct(){try{var t=!Boolean.prototype.valueOf.call(Reflect.construct(Boolean,[],function(){}));}catch(t){}return(_isNativeReflectConstruct=function _isNativeReflectConstruct(){return!!t;})();}


var URL=
'https://raw.githubusercontent.com/24i/smart-apps-version-check/main/production/version.json';var

ExperienceAppVersionClient=function(_BackstageUserDataCli){(0,_inherits2.default)(ExperienceAppVersionClient,_BackstageUserDataCli);var _super=_createSuper(ExperienceAppVersionClient);function ExperienceAppVersionClient(){var _this;(0,_classCallCheck2.default)(this,ExperienceAppVersionClient);for(var _len=arguments.length,args=new Array(_len),_key=0;_key<_len;_key++){args[_key]=arguments[_key];}_this=_super.call.apply(_super,[this].concat(args));_this.
fetchVersionConfig=function _callee(){var data;return _regenerator.default.async(function _callee$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.next=2;return _regenerator.default.awrap(
fetch(URL));case 2:data=_context.sent;return _context.abrupt("return",
data.json());case 4:case"end":return _context.stop();}},null,null,null,Promise);};return _this;}return(0,_createClass2.default)(ExperienceAppVersionClient);}(_nxgIntegrationBackstage.BackstageUserDataClient);exports.ExperienceAppVersionClient=ExperienceAppVersionClient;



var createExperienceAppVersionClient=function createExperienceAppVersionClient(params){
return new ExperienceAppVersionClient(params);
};exports.createExperienceAppVersionClient=createExperienceAppVersionClient;