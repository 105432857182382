import { Theme } from '@24i/nxg-sdk-photon';
import { TOGGLE_SIZE_WEB } from '../constants';
import { ToggleStylesContextWeb, ToggleStylesWeb } from '../types';

const getToggleStyles = (
    theme: Theme,
    {
        size,
        thumbColor,
        isActive,
        trackColor = { true: theme.color.contrast1, false: theme.color.darker1 },
    }: ToggleStylesContextWeb
): ToggleStylesWeb => {
    const verticalPadding = 4;

    const { height, width } = TOGGLE_SIZE_WEB[size];

    const thumbSize = height - verticalPadding;

    const horizontalPadding = 2;
    return {
        wrapper: {
            width,
            height,
            backgroundColor: isActive ? trackColor.true : trackColor.false,
            borderRadius: 100,
        },
        thumb: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: isActive ? width - thumbSize - horizontalPadding : horizontalPadding,
            width: thumbSize,
            height: thumbSize,
            borderRadius: 100,
            margin: 'auto',
            backgroundColor: thumbColor ?? theme.color.darker1,
            transition: 'left 0.3s',
        },
    };
};

export default getToggleStyles;
