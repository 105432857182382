export class AbstractLayoutClient {
    constructor() {}

    getPackshotSize() {
        return Promise.reject(new Error('Not implemented'));
    }

    getOffsetAfter() {
        return Promise.reject(new Error('Not implemented'));
    }
}
