import { PinProtectionScopes, Profile } from '@24i/nxg-sdk-photon/src';
import { PinInputModalAndContentProps } from '../../components/PinInputModal/types';
import { UseProtectedScreenParams } from '../types';
import { usePinProtectedScreen } from '../usePinProtectedScreen';

export const usePinModalWithProfile = (params: UseProtectedScreenParams) => {
    const { triggerPinControl } = usePinProtectedScreen({
        active: false,
        goBackAfterActivation: false,
        goBackAfterDismiss: false,
        ...params,
    });

    const triggerPinModalWithProfile = async (profile: Profile | null) => {
        const props: PinInputModalAndContentProps | undefined = {
            ...params.modalProps,
            profileWithPin: profile,
        };
        return triggerPinControl({
            activeModalProps: props,
            triggerScopes: [PinProtectionScopes.profileManagement],
        });
    };

    return {
        triggerPinModalWithProfile,
    };
};
