import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { SignInScreenStylesWeb } from './types';

const getStyles = (theme: Theme): SignInScreenStylesWeb => ({
    signinContainer: {
        flex: 1,
        backgroundColor: theme.color.primary1,
        width: 383,
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        position: 'fixed',
        top: '15%',
        left: '50%',
        marginLeft: -192,
        alignItems: 'center',
        paddingBottom: 24,
    },
    headerSection: {
        flexDirection: 'row',
        marginTop: 24,
        marginBottom: 24,
    },
    headerTitle: {
        color: theme.color.textPrimary,
        fontWeight: 'bold',
        textAlign: 'center',
        flexGrow: 1,
        ...getFont(theme, 'h2'),
    },
    headerDescription: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        flexGrow: 1,
        flexWrap: 'wrap',
        opacity: 0.6,
        ...getFont(theme, 'body2'),
        width: 336,
    },
    headerBackButtonContainer: {
        position: 'absolute',
        zIndex: 9999,
    },
    signinSection: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    alertSection: {
        width: 335,
        minHeight: 18,
        marginTop: 5,
    },
    alertSectionTablet: {
        width: 335,
        height: 18,
        position: 'absolute',
        marginTop: 30,
        right: 40,
    },
    customAlertSectionTablet: {
        width: 335,
        height: 18,
        position: 'absolute',
        marginTop: 30,
        top: 47,
    },
    alertSectionContent: {
        alignItems: 'center',
    },
    alertIcon: {
        fontSize: 14,
        marginRight: 8,
        marginLeft: 14,
    },
    alertText: {
        ...getFont(theme, 'h5'),
        textAlign: 'center',
    },
    textInputView: {
        flexDirection: 'row',
        height: 48,
        width: 335,
        borderRadius: 28,
        ...getBorderRadius(theme.elements, 'textField-cornerRadius'),
        marginTop: 14,
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'transparent',
        flex: 1,
    },
    textInputEmail: {
        paddingLeft: 24,
        paddingRight: 10,
        color: theme.color.textPrimary,
        ...getFont(theme, 'body1'),
    },
    textInputPassword: {
        width: '80%',
        paddingLeft: 12,
        paddingRight: 10,
        color: theme.color.textPrimary,
        ...getFont(theme, 'body1'),
    },
    togglePassView: {
        height: 48,
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
        position: 'absolute',
        zIndex: 999,
        right: -12,
        padding: 0,
    },
    togglePassViewText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h6'),
        padding: 0,
    },
    activeSigninButton: {
        height: 52,
        borderRadius: 27,
        width: '100%',
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
        backgroundColor: theme.color.buttonPrimary,
        justifyContent: 'center',
        marginTop: 12,
        marginBottom: 7,
        marginRight: 0,
        shadowColor: 'transparent',
    },
    activeSigninButtonText: {
        textAlign: 'center',
        fontWeight: 'bold',
        ...getFont(theme, 'primaryButton'),
        color: theme.color.textButtonPrimary,
    },
    forgotPassButton: {
        backgroundColor: 'transparent',
        marginBottom: 48,
        justifyContent: 'center',
        shadowColor: 'transparent',
        paddingLeft: 14,
        paddingRight: 14,
        '@media (max-width: 1200px)': {
            paddingLeft: 12,
            paddingRight: 12,
        },
    },
    forgotPassButtonText: {
        marginLeft: 0,
    },
    signupSection: {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '0%',
    },
    signupTitle: {
        ...getFont(theme, 'body2'),
        color: `${theme.color.textPrimary}99`,
        textAlign: 'center',
        letterSpacing: 0,
        opacity: 0.6,
    },
    signupButton: {
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
        paddingLeft: 14,
        paddingRight: 14,
        '@media (max-width: 1200px)': {
            paddingLeft: 12,
            paddingRight: 12,
        },
    },
    signupButtonText: {
        marginLeft: 0,
        color: theme.color.textPrimary,
    },
    activityIndicatorContainer: {
        marginTop: 12,
        height: 52,
        justifyContent: 'center',
    },
    activityIndicatorWrapper: {
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: 48,
        top: 48,
        zIndex: 99,
    },
});

export default getStyles;
