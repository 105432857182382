import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Interactable, Text } from '@24i/nxg-sdk-quarks';

const tabButtonStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    height: 40,
    marginRight: -2,
    backgroundColor: '#999999',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#000000',
};
const tabButtonStyleActive = {
    backgroundColor: '#aaaaaa',
    borderBottomColor: '#aaaaaa',
};
const tabButtonStyleLast = {
    marginRight: 0,
};

const TabButton = ({ onPress, tabName, isActive, isLast }) => {
    const [focused, setFocused] = useState(false);

    return (
        <Interactable
            onPress={onPress}
            style={[
                tabButtonStyle,
                isActive ? tabButtonStyleActive : {},
                isLast ? tabButtonStyleLast : {},
            ]}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
        >
            <Text style={{ color: focused ? '#b81d2d' : '#000000' }}>{tabName}</Text>
        </Interactable>
    );
};
TabButton.propTypes = {
    onPress: PropTypes.func.isRequired,
    tabName: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
};
export default TabButton;
