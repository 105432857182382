import React from 'react';
import PropTypes from 'prop-types';
import { FlatList } from 'react-native';

const PickerMenuContent = ({ transformedChildren }) => (
    <FlatList renderItem={({ item }) => item} data={transformedChildren} />
);

PickerMenuContent.propTypes = {
    transformedChildren: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PickerMenuContent;
