import { useQuery, UseQueryOptions } from 'react-query';
import type { Asset, PLAYLIST_TYPE } from '@24i/nxg-sdk-photon';
import { QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';

const usePlaylistAssetsQuery = (playlistId: PLAYLIST_TYPE, options?: UseQueryOptions<Asset[]>) => {
    const contentDataClient = useContentData();

    return useQuery<Asset[]>(
        [QUERY_KEYS.playlists, playlistId],
        () => contentDataClient.fetchAssets(playlistId),
        options
    );
};

export default usePlaylistAssetsQuery;
