import React, { ReactElement } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import getTertiaryButtonWithoutBgStyles from './styles';
import { IconButtonProps } from '../IconButton/types';

const TertiaryButtonWithoutBg = (props: IconButtonProps): ReactElement => {
    const { styles } = props;
    return <IconButton styles={styles || getTertiaryButtonWithoutBgStyles} {...props} />;
};

export { getTertiaryButtonWithoutBgStyles };
export default overridable(TertiaryButtonWithoutBg, 'TertiaryButtonWithoutBg');
