import { ViewStyleWeb } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import { ViewStyle } from 'react-native';
import { ChannelOverlayStylesWeb, ChannelOverlayStyles } from './types';

export const getViewStyle = (
    hasBigChannelLogo: boolean,
    orientation: string,
    progressBarDisplayed: boolean,
    styles: ChannelOverlayStylesWeb | ChannelOverlayStyles
): ViewStyleWeb | ViewStyle | undefined => {
    if (!hasBigChannelLogo) {
        if (orientation === 'portrait' && progressBarDisplayed) {
            return styles.portraitLogoLive;
        }
        if (orientation === 'portrait' && !progressBarDisplayed) {
            return styles.portraitLogo;
        }
        if (orientation === 'landscape' && progressBarDisplayed) {
            return styles.landscapeLogoLive;
        }
        if (orientation === 'landscape' && !progressBarDisplayed) {
            return styles.landscapeLogo;
        }
    }

    return {};
};
