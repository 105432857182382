import React, { useEffect } from 'react';
import { Theme } from '@24i/nxg-sdk-photon';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useThemeData } from '@24i/nxg-sdk-smartott-shared/src/context/ThemeData';
import { log } from '@24i/nxg-core-utils/src/logger';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import SettingsDropdownPicker from '@24i/nxg-sdk-smartott/src/components/SettingsDropdownPicker';
import getThemeScreenStyles from '../styles';

// TODO: This component is actually old code taken from legacty ThemeScreen, that's why there are some hardcoded styles e.t.c.
// Settings screen will be reworked so refactoring this component will be included in that task
const ThemeScreen = () => {
    const { t } = useTranslation();
    const themeDataClient = useThemeData();
    const { theme, setTheme } = useTheme();

    const [themes, setThemes] = React.useState<Theme[]>([]);

    useEffect(() => {
        const getThemes = async () => {
            try {
                const fetchedThemes = await themeDataClient.fetchAllThemes();
                setThemes(fetchedThemes);
            } catch (err) {
                log(err);
            }
        };

        getThemes();
    }, []);

    const handleSelectTheme = async (themeIndex) => {
        await themeDataClient.selectTheme(themes[themeIndex].id);
        setTheme(themes[themeIndex]);
    };

    return (
        <SettingsDropdownPicker
            options={themes.map((themeItem) => themeItem.name)}
            selectedItem={theme.name}
            onSelectionPress={handleSelectTheme}
            title={t('settings.theme.title')}
        />
    );
};

export { getThemeScreenStyles };
export default withWebHeader(ThemeScreen);
