import View from './View';

export {
    PlayerTitleView,
    getPlayerTitleViewStyles,
    default as View,
    getTitleViewStyles,
} from './View';

export default View;
