var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));var _nxgSdkHiggs=require("@24i/nxg-sdk-higgs");function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}

var getSettingsThemeStyles=function getSettingsThemeStyles(theme){return{
listItem:{
flexDirection:'row',
alignItems:'center',
flex:1,
marginVertical:12,
width:'100%'
},
listItemText:_objectSpread({
color:""+theme.color.menuItemActive},
(0,_nxgSdkHiggs.getFont)(theme,'menuItem-inactive'))

};};var _default=

getSettingsThemeStyles;exports.default=_default;