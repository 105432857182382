import { QUERY_KEYS, User } from '@24i/nxg-sdk-photon/src';
import { useQuery } from 'react-query';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useStore } from '../../../context/ApplicationStore';

export const useUserQuery = () => {
    const client = useUserData();
    const { setUserData } = useStore();

    const { isLoading, data, refetch } = useQuery<User | null>(
        QUERY_KEYS.user,
        () => client.fetchUser(),
        {
            onSuccess: (user) => {
                setUserData(user);
            },
        }
    );

    return {
        isLoading,
        user: data,
        refetch,
    };
};
