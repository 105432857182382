import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import { BLOCKING_REASON_TYPES } from '@24i/nxg-sdk-photon';
import { BannerCarouselItemTypes } from '../../../types';

// TODO implement
const useViewModel = (props: BannerCarouselItemTypes) => {
    const { hasAdultBlocker } = useAssetPinControl({
        asset: props.item.data,
        active: false,
    });

    const isGeoblocked = props.item.data.blocked?.find(
        (blocking) => blocking.reason === BLOCKING_REASON_TYPES.GEO_TYPE
    );

    return { ...props, isObscured: hasAdultBlocker, isGeoBlocked: !!isGeoblocked };
};

export { useViewModel };
