import { useSwitchProfile } from '@24i/nxg-sdk-smartott/src/hooks/useSwitchProfile';
import { removeBy } from '@24i/nxg-core-utils';
import { NavScreenConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { usePinModalWithProfile } from '@24i/nxg-sdk-pin-protection/src/hooks/usePinModalWithProfile';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useMenuItemPress } from '../../../utils';
import { ProfileDropdownProps } from '../types';
import { ProfileDropdownViewProps } from '../View/types';
import { DropdownItem } from '../../TopBarMenu/components/TopBarMenuDropdown/types';
import { SOTT_DEFAULT_SCREENS } from '../../../constants';

export const useViewModel = (props: ProfileDropdownProps): ProfileDropdownViewProps => {
    const { menuItem, profiles, screens } = props;

    const onPress = useMenuItemPress();
    const { switchProfile } = useSwitchProfile();
    const profileLimit = useFeature('profileLimit');
    const maxNumberOfProfiles = useFeature('maxNumberOfProfiles');
    const { triggerPinModalWithProfile } = usePinModalWithProfile({
        goBackAfterActivation: false,
        goBackAfterDismiss: false,
    });

    const maximumNumberOfProfilesInDropdown =
        (profileLimit?.maxCount || maxNumberOfProfiles || Infinity) - 1; // Removing the current selected profile

    const isMaximumProfilesCountReached = profiles.length >= maximumNumberOfProfilesInDropdown;

    let outputScreens: NavScreenConfigWeb[];

    if (isMaximumProfilesCountReached) {
        outputScreens = removeBy(
            screens,
            (screen) => screen.name === SOTT_DEFAULT_SCREENS.UPSERT_PROFILE
        );
    } else {
        outputScreens = screens;
    }

    const handleItemClick = async (dropdownItem: DropdownItem): Promise<void> => {
        const profileItem = profiles.find(({ name }) => name === dropdownItem.name);
        if (profileItem) {
            try {
                if (profileItem?.isPinProtected) {
                    await triggerPinModalWithProfile(profileItem);
                }

                switchProfile(profileItem);
            } catch {
                // pin control rejected
            }

            return;
        }

        const correspondingMenuItem = screens.find(({ name }) => name === dropdownItem.name);

        if (!correspondingMenuItem) {
            throw new Error(
                `A menu item was clicked but the menu item was not found in the profile dropdown screens!? Clicked item: ${dropdownItem.name}`
            );
        }

        onPress(correspondingMenuItem);
    };

    const dropdownItems = [
        ...profiles.map(
            (profile): DropdownItem => ({
                name: profile.name ?? '',
                title: profile.name ?? '',
                image: profile.image ?? '',
            })
        ),
        ...outputScreens.map(
            (screen): DropdownItem => ({
                name: screen.name,
                title: screen.menuTitle,
                icon: screen.icon,
                displayDividerUnder: screen.name === SOTT_DEFAULT_SCREENS.MANAGE_PROFILES,
            })
        ),
    ];

    return { menuItem, dropdownItems, handleItemClick };
};
