import React from 'react';
import PropTypes from 'prop-types';
import { View, ActivityIndicator } from '@24i/nxg-sdk-quarks';

const Loader = ({
    styles,
    additionalStyles,
    size,
    color,
    disableImageBackground,
    backgroundImage,
}) => (
    <View
        activeOpacity={1}
        style={[
            { flex: 1, zIndex: 9999 },
            (!backgroundImage || disableImageBackground) && styles.container,
            additionalStyles,
        ]}
    >
        <ActivityIndicator size={size} color={color} />
    </View>
);

Loader.propTypes = {
    styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    additionalStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    additionalImageBackgroundStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    size: PropTypes.oneOf(['large', 'small']),
    color: PropTypes.string,
    backgroundImage: PropTypes.string,
    disableImageBackground: PropTypes.bool,
};

Loader.defaultProps = {
    styles: {
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    backgroundImage: null,
    additionalStyles: {},
    additionalImageBackgroundStyles: {},
    size: 'large',
    color: '#FFFFFF',
    disableImageBackground: false,
};

export default Loader;
