/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-statements */
import { isPlatformWeb } from 'renative';
import autoBind from 'auto-bind';

import { firstLetterUppercase, groupWith } from '@24i/nxg-core-utils';
import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { log } from '@24i/nxg-core-utils/src/logger';
import {
    Asset,
    AssetGuard,
    AssetImages,
    ASSET_TYPE,
    Broadcast,
    Channel,
    ChannelGuard,
    ContentDataClient,
    Edition,
    Episode,
    Image,
    LiveEvent,
    PageSection,
    Season,
    Stream,
    StreamGuard,
    FetchAssetsResponse,
} from '@24i/nxg-sdk-photon';
import { TextPageContent } from '@24i/nxg-sdk-photon/src/models/textPageContent';
import {
    IPlayerEngine,
    ISource,
    ISourceDrm,
    ISourceSsai,
    ISourceSsaiAwsMediaTailor,
    ISourceSsaiGoogleDai,
    ISourceTextTrack,
    ISourceThumbnailSpritesheet,
    MIME_TYPE,
} from '@24i/player-base';
import {
    EpgDataClientStub,
    STUB_EPG_SEPARATOR,
} from '@24i/nxg-sdk-smartott-stubs/src/clients/EpgDataClient';

import { BackstageApiBase } from '../../base';
import {
    EditionsResponseGuard,
    ItemsResponse,
    ItemsResponseGuard,
    PaginatedResponse,
    PaginatedResponseGuard,
    BackstageAssetGuard,
    BackstageBroadcastGuard,
    BackstagePlaylistGuard,
    BackstagePlaylist,
    BackstageAsset,
    BackstageEpisode,
    BackstageEpisodeGuard,
    BackstageBroadcast,
} from './guards';
import {
    mapEditionToAssetTrailer,
    mapToPlayerDrmSystem,
    mapToPlayerMimetype,
    mapToSourceTimelineEvent,
    mapSearchAssetsToSections,
    mapSubtitlesToSource,
} from './helpers/mapSource';
import { getLiveAssetTimeInformation } from '../../utils';
import { mapProgramsResponse } from './mappers';
import { determineAgeClassification } from './utils';
import {
    googleScorer,
    defaultScorer,
    selectSupportedScorer,
    ScorerType,
} from './helpers/streamScorer';

export const determineEndpointType = <T extends { type: ASSET_TYPE | null }>(asset: T) => {
    let endpointType: string;

    switch (asset.type) {
        case ASSET_TYPE.EPISODE:
            endpointType = 'episodes';
            break;
        case ASSET_TYPE.LIVE_EVENT:
        case ASSET_TYPE.EPG:
            endpointType = 'live-events';
            break;
        case ASSET_TYPE.MOVIE:
            endpointType = 'movies';
            break;

        case ASSET_TYPE.SERIES:
            endpointType = 'series';
            break;

        case ASSET_TYPE.CHANNEL:
            endpointType = 'channels';
            break;
        case ASSET_TYPE.BROADCAST:
            endpointType = 'broadcasts';
            break;
        case ASSET_TYPE.CLIP:
            endpointType = 'clips';
            break;
        case ASSET_TYPE.PODCAST_SERIES:
            endpointType = 'podcasts';
            break;
        default:
            throw new Error(`invalid or unsupported asset type ${asset.type}`);
    }

    return endpointType;
};

export class SharedContentDataClient
    extends BackstageApiBase
    implements Partial<ContentDataClient>
{
    stubEpgClient: EpgDataClientStub;

    constructor(params: BaseApiParams) {
        super(params);
        autoBind(this);
        this.stubEpgClient = new EpgDataClientStub();
    }

    async fetchEditions(asset: Asset) {
        const editionType = determineEndpointType({ type: asset.type });
        // Makes no sense to ask for editions on series
        if (['series', 'podcasts'].includes(editionType)) {
            return [];
        }

        const id = asset.type === ASSET_TYPE.EPG && asset.isLive ? asset?.channel?.id : asset.id;

        return this.request({
            path: `/media/${editionType}/${id}/editions`,
            method: 'GET',
            guard: EditionsResponseGuard,
        });
    }

    // TODO: Try to make prepareStream return a type from photon (generic). The do the ISource transformation on PlaybackScreen or its viewmodels
    async prepareStream(
        requestedAsset: Asset,
        assetId: string,
        engine?: IPlayerEngine,
        isCast = false
    ): Promise<ISource | void> {
        let asset = requestedAsset;
        try {
            let isStubChannel = false;
            if (asset.id?.includes(STUB_EPG_SEPARATOR)) {
                const broadcast = await this.stubEpgClient.getBroadcastById({
                    broadcastId: asset.id,
                    channelId: asset.channelId,
                });

                if (!broadcast) {
                    return undefined;
                }

                isStubChannel = true;
            }
            if (asset.type === ASSET_TYPE.CHANNEL) {
                const channels = await this.stubEpgClient.getChannels();
                if (channels.find((channel) => channel.id === asset.id)) {
                    isStubChannel = true;
                }
            }

            if (isStubChannel) {
                return {
                    url:
                        asset.isLive || asset.type === ASSET_TYPE.CHANNEL
                            ? `https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8#${asset.id}`
                            : 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
                    mimeType: MIME_TYPE.HLS,
                };
            }

            const isStartOver = asset.isStartOver || false;
            // Hack until BS resolves error on editions/ endpoint for live channels
            if (
                asset.type === ASSET_TYPE.BROADCAST &&
                asset.isLive &&
                // !startOver &&
                asset.channel
            ) {
                const channelId = asset.channelId || asset.channel.id;
                return await this.prepareStream(
                    {
                        ...asset.channel,
                        isStartOver,
                        type: ASSET_TYPE.CHANNEL,
                    },
                    channelId,
                    engine,
                    isCast
                );
            }
            // End of hack

            // If we request a series, fetchEditions will fail
            if (asset.type === ASSET_TYPE.SERIES && !asset.isTrailer) {
                const seasons = await this.fetchEpisodes(asset.id);
                const seasonsWithEpisodes = seasons
                    .filter((s) => s?.episodes?.length)
                    .sort((a, b) => (a?.seasonNumber ?? 0) - (b?.seasonNumber ?? 0));
                if (seasonsWithEpisodes?.length) {
                    const [firstEpisode] = seasonsWithEpisodes[0].episodes;
                    asset = firstEpisode;
                }
            }

            const editions = await this.fetchEditions(asset);
            if (!editions) throw new Error('USER_NOT_AUTHENTICATED');
            if (!editions.length) throw new Error('No editions available for this item');

            const selectedEditionType = asset.isTrailer ? 'trailer' : 'full';
            const selectedEdition = editions.find((ed) =>
                asset.editionId ? ed.id === asset.editionId : ed.editionType === selectedEditionType
            );

            const edition = selectedEdition || editions[0];
            const edId = asset.editionId || edition.id;

            // Check if edition is blocked
            if (edition.blocked) {
                const firstReason = edition.blocked[0];
                if (firstReason?.reason === 'PRODUCTS_MISMATCH_BLOCKED') {
                    throw new Error(firstReason?.reason);
                }
            }

            // TODO: Replace all the above types and DRMs with real supported types returned from player and prioritize them
            let scorer: ScorerType = defaultScorer;
            if (isCast) {
                scorer = googleScorer;
            } else if (engine) {
                const supportedFormats = await engine.getSupportedFormats();
                scorer = selectSupportedScorer(supportedFormats);
            }
            const stream = edition.streams
                .map((s) => ({ stream: s, score: scorer(s.type, s.drm) }))
                .sort((a, b) => b.score - a.score)
                .filter((v) => v.score > 0)[0]?.stream;
            if (!stream) throw new Error(`findStream didn't return any stream`);

            const assetType = determineEndpointType({ type: asset.type });
            const streamData = await this.request<any>({
                path: `/media/${assetType}/${assetId}/stream`,
                method: 'GET',
                guard: StreamGuard,
                query: {
                    editionId: edId,
                    ...(stream ? { type: stream.type, drm: stream.drm } : {}),
                    startOver: isStartOver,
                },
            });

            const mappedStream: Stream = {
                ...streamData,
            };

            let { url }: { url?: string } = mappedStream;
            let drm: ISourceDrm | undefined;
            const assetDRM = mapToPlayerDrmSystem(mappedStream?.drm);

            if (assetDRM && mappedStream?.data?.licenseUrl)
                drm = {
                    packageName: assetDRM,
                    licenseServer: mappedStream.data?.licenseUrl,
                    certificateUrl: mappedStream.data?.certificateUrl,
                };

            const textTracks: Array<ISourceTextTrack> = mapSubtitlesToSource(edition?.subtitles);

            const timeline = edition.timeline?.map(mapToSourceTimelineEvent);
            const ssai = SharedContentDataClient.mapToSsai(mappedStream);
            if (ssai && !isCast) url = undefined;
            const thumbnails = SharedContentDataClient.mapToSourceThumbnails(mappedStream);

            return {
                url,
                mimeType: mapToPlayerMimetype(mappedStream.type),
                ads: mappedStream.advertisement,
                drm,
                ssai,
                textTracks,
                timeline,
                thumbnails,
            };
        } catch (err) {
            log('ERROR PREPARING STREAM', err);
            throw err;
        }
    }

    private static mapToSourceThumbnails(
        mappedStream: any
    ): ISourceThumbnailSpritesheet | undefined {
        if (mappedStream?.thumbnails?.type === 'spritesheet') {
            const thumbnailsData = mappedStream.thumbnails.data;
            let { interval } = thumbnailsData;
            if (typeof interval === 'number') {
                // convert to milliseconds
                interval *= 1000;
            } else if (interval instanceof Array) {
                // convert to milliseconds
                interval = interval.map((time) => time * 1000);
            }

            return {
                image: thumbnailsData.image,
                frame: thumbnailsData.frame,
                orientation: thumbnailsData.orientation,
                gridCols: thumbnailsData.gridCols,
                interval,
            } as ISourceThumbnailSpritesheet;
        }
        return undefined;
    }

    private static mapToSsai(mappedStream): ISourceSsai | undefined {
        let ssai: ISourceSsai | undefined;
        ssai = ssai ?? SharedContentDataClient.mapToGoogleSsaiFromData(mappedStream.data);
        ssai = ssai ?? SharedContentDataClient.mapToMediaTailorSsaiFromData(mappedStream.data);
        ssai = ssai ?? SharedContentDataClient.mapToGoogleSsaiFromUrl(mappedStream.url);
        ssai = ssai ?? SharedContentDataClient.mapToMediaTailorSsaiFromUrl(mappedStream.url, true);
        return ssai;
    }

    private static mapToGoogleSsaiFromUrl(url: string): ISourceSsaiGoogleDai | undefined {
        if (!url.startsWith('https://dai.google.com/?')) return undefined;
        /* NOTE: If there is a standard way of parsing URL parameters that works in React Native
          (because URL & URLSearchParams are broken in RN), please, please, please do suggest
          those in PR review (or replace it here afterwards). */
        const parameters = url
            .split('?')[1]
            .split('&')
            .reduce((acc, queryPart) => {
                const [key, encodedValue] = queryPart.split('=');
                return {
                    ...acc,
                    [key]: decodeURIComponent(encodedValue),
                };
            }, {} as Record<string, string>);
        return {
            type: 'googledai',
            live: !!parameters.assetKey,
            assetKey: parameters.assetKey,
            contentSourceId: parameters.contentSourceId,
            videoId: parameters.videoId,
            apiKey: parameters.apiKey,
        };
    }

    private static mapToGoogleSsaiFromData(parameters: any): ISourceSsaiGoogleDai | undefined {
        const { cmsid, vid, event, authToken } = parameters ?? {};
        const isDaiLive = Boolean(event);
        const isDaiVod = Boolean(cmsid && vid);
        if (!isDaiLive && !isDaiVod) return undefined;
        return {
            type: 'googledai',
            live: isDaiLive,
            assetKey: event,
            contentSourceId: cmsid,
            videoId: vid,
            authToken,
        };
    }

    private static mapToMediaTailorSsaiFromUrl(
        url: string,
        preferServerSideTracking: boolean
    ): ISourceSsaiAwsMediaTailor | undefined {
        const match = url.match(
            /^(https?:\/\/.+\.mediatailor\.[^.]+\.amazonaws.com\/v1)\/(session|master|dash)\/(.*)$/
        );
        if (!match) return undefined;
        if (preferServerSideTracking && match[2] !== 'session') return undefined;
        return {
            type: 'awsmediatailor',
            sessionUrl: `${match[1]}/session/${match[3]}`,
        };
    }

    private static mapToMediaTailorSsaiFromData(
        parameters: any
    ): ISourceSsaiAwsMediaTailor | undefined {
        const { sessionUrl, adsParams } = parameters ?? {};
        if (!sessionUrl) return undefined;
        return {
            type: 'awsmediatailor',
            sessionUrl,
            adsParams,
        };
    }

    async fetchEPGChannelCurrentLiveProgramme(id: string): Promise<PaginatedResponse<Broadcast>> {
        const dateNow = Date.now();
        const dateFiveHoursFromNow = dateNow + 5 * 3600 * 1000;

        return this.request({
            path: `/media/channels/${id}/epg`,
            method: 'GET',
            guard: PaginatedResponseGuard(BackstageBroadcastGuard),
            query: {
                from: Math.floor(dateNow / 1000),
                to: Math.floor(dateFiveHoursFromNow / 1000),
            },
        })
            .then(mapProgramsResponse)
            .catch((err) => {
                log('ERROR FETCHING CHANNEL PROGRAM DATA', err);

                throw err;
            });
    }

    async fetchChannel(id: string): Promise<Channel> {
        return this.request({
            path: `/media/channels/${id}`,
            method: 'GET',
            guard: ChannelGuard,
        }).catch((err) => {
            log('ERROR FETCHING CHANNEL', err);

            throw err;
        });
    }

    async searchAssets(
        query: string,
        translation?: (label: string, { count }: { count: number }) => string
    ): Promise<PageSection[]> {
        return this.request({
            path: `/search`,
            method: 'GET',
            guard: BackstagePlaylistGuard,
            query: {
                q: query,
                offset: 0,
                size: 500,
            },
        })
            .then((assets) => this.transformResponse({ items: assets.items }))
            .then((assets) => {
                const searchedItems = assets.map((item) => ({ ...item, progress: 0 }));

                return mapSearchAssetsToSections(searchedItems, translation);
            });
    }

    async searchAutoSuggest(query: string): Promise<string[]> {
        return this.request({
            path: `/search/autosuggest`,
            method: 'GET',
            query: {
                q: query,
            },
        });
    }

    async fetchAssets(
        playlistId: PageSection['playlistId'],
        sorting: PageSection['sorting'],
        pinnedItems: PageSection['pinnedItems'],
        offset?: number,
        size?: number
    ): Promise<FetchAssetsResponse> {
        return this.request({
            path: `/playlists/${playlistId}`,
            method: 'GET',
            guard: BackstagePlaylistGuard,
            query: {
                ...(offset ? { offset } : { offset: 0 }),
                ...(size ? { size } : {}),
                ...(sorting?.field ? { sortField: sorting?.field } : {}),
                ...(sorting?.order ? { sortOrder: sorting?.order } : {}),
                ...(pinnedItems?.length ? { pinnedItems: pinnedItems.join(',') } : {}),
            },
        })
            .then(async (response) => {
                const items = await this.transformResponse(response);
                return {
                    total: response.total,
                    offset: response.offset,
                    items,
                };
            })
            .catch((err) => {
                log('ERROR FETCHING ASSETS', err);

                throw err;
            });
    }

    async fetchEpisodes(id: string, translation?: any, mediaType?: ASSET_TYPE): Promise<Season[]> {
        const mediaTypeToUse = mediaType ?? ASSET_TYPE.SERIES;

        return this.request({
            path: `/media/${mediaTypeToUse}/${id}/episodes`,
            method: 'GET',
            guard: ItemsResponseGuard(BackstageEpisodeGuard),
            query: {
                size: 300,
            },
        })
            .then((response: ItemsResponse<BackstageEpisode>) => {
                const episodes = groupWith(response.items, (episode) => episode.seasonNumber);

                return this.transformSeasons(episodes, translation);
            })
            .catch((err) => {
                log(
                    'ERROR FETCHING EPISODES',
                    err,
                    `/media/${mediaTypeToUse}/${id}/episodes?size=300`
                );

                throw err;
            });
    }

    async checkBlockers(asset: Asset): Promise<Asset['blocked']> {
        const isLiveBroadcast = asset.type === ASSET_TYPE.BROADCAST && asset.isLive;
        const assetType = isLiveBroadcast ? 'channels' : determineEndpointType(asset);
        const id = isLiveBroadcast ? asset.channelId : asset.id;

        return this.request({
            path: `/media/${assetType}/${id}`,
            method: 'GET',
            query: {
                size: 300,
            },
            guard: AssetGuard,
        })
            .then((r: Asset) => r.blocked)
            .catch((err) => {
                log('ERROR FETCHING ASSET BLOCKERS INFORMATION', err, `/media/${assetType}/${id}`);

                if ([ASSET_TYPE.EPG, ASSET_TYPE.BROADCAST, ASSET_TYPE.CHANNEL].includes(asset.type))
                    return undefined;
                throw err;
            });
    }

    async fetchAsset(asset: Asset): Promise<Asset> {
        if (asset.id.includes(STUB_EPG_SEPARATOR)) {
            return this.stubEpgClient.getBroadcastById({
                broadcastId: asset.id,
                channelId: asset.channelId,
            }) as unknown as Asset;
        }

        const assetType = determineEndpointType(asset);

        return this.request({
            path: `/media/${assetType}/${asset.id}`,
            method: 'GET',
            guard: BackstageAssetGuard,
            query: {
                ...(asset.type === ASSET_TYPE.SERIES ? { includeEpisodes: false } : {}),
            },
        })
            .then(this.transformAsset)
            .catch((err) => {
                log('ERROR FETCHING ASSET', err, `/media/${assetType}/${asset.id}`);

                throw err;
            });
    }

    async fetchRecommended(assetId: string, type: ASSET_TYPE, size?: number): Promise<Asset[]> {
        const assetType = determineEndpointType({ type });

        return this.request({
            path: `/media/${assetType}/${assetId}/recommendations`,
            method: 'GET',
            guard: BackstagePlaylistGuard,
            query: { ...(size && { size }) },
        })
            .then(this.transformResponse)
            .catch((err) => {
                log(
                    'ERROR FETCHING RECOMMENDED',
                    err,
                    `/media/${assetType}/${assetId}/recommendations`
                );

                throw err;
            });
    }

    async fetchPlaylist(
        response: PageSection[],
        fetchSize = Infinity,
        pageNumber = 0
    ): Promise<PageSection[]> {
        if (!response?.length) {
            return [];
        }

        const fetchingFrom = fetchSize * pageNumber;
        const fetchingTo = Math.min(response.length, fetchSize * (pageNumber + 1));

        const items = response.slice(fetchingFrom, fetchingTo).map(async (page: PageSection) => {
            let assets: Asset[] = [];
            try {
                if (page.playlistId) {
                    const playlistResponse = await this.fetchAssets(
                        page.playlistId,
                        page.sorting,
                        page.pinnedItems
                    );
                    assets = playlistResponse.items;
                } else if (page.computedPlaylist) {
                    if (page?._links?.href) {
                        const params = page._links.href.split('playlists');
                        assets = await this.fetchComputedPlaylist(`/playlists${params[1]}`);
                    }
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.warn('Error fetching assets for playlist', page);
            }

            return { ...page, items: assets };
        });

        return Promise.all(items);
    }

    async fetchFavoritesPlaylist({
        offset,
        size,
    }: {
        offset?: number;
        size?: number;
    }): Promise<Asset[]> {
        return this.request({
            path: `/playlists/user-favorites`,
            method: 'GET',
            guard: BackstagePlaylistGuard,
            query: {
                ...(offset ? { offset } : {}),
                ...(size ? { size } : {}),
            },
        }).then(this.transformResponse);
    }

    async fetchContinueWatchingPlaylist(
        offset?: number,
        size?: number,
        signal?: AbortSignal
    ): Promise<Asset[]> {
        return this.request({
            path: `/playlists/user-continue-watching`,
            method: 'GET',
            guard: BackstagePlaylistGuard,
            query: {
                ...(offset ? { offset } : {}),
                ...(size ? { size } : {}),
            },
            signal,
        }).then(this.transformResponse);
    }

    async fetchWatchHistoryPlaylist(offset?: number, size?: number): Promise<Asset[]> {
        return this.request({
            path: `/playlists/watch-history`,
            method: 'GET',
            guard: BackstagePlaylistGuard,
            query: {
                ...(offset ? { offset } : {}),
                ...(size ? { size } : {}),
            },
        }).then(this.transformResponse);
    }

    async fetchClips(clipIds: string[]): Promise<Asset[]> {
        return Promise.all(
            clipIds.map((clip) => this.fetchAsset({ id: clip, type: ASSET_TYPE.CLIP }))
        );
    }

    async fetchExtras(asset: Asset): Promise<Asset[]> {
        let extras: Asset[] = [];
        try {
            // Extras are composed of trailers of current asset and first editions from clips from current asset.
            const [fetchedClips = [], fetchedAssetEditions = []] = await Promise.all([
                this.fetchClips(asset.clipIds || []),
                this.fetchEditions({
                    id: asset.id,
                    type: asset.type,
                }),
            ]);

            const fetchedClipsEditions: Array<Edition[]> = await Promise.all(
                fetchedClips.map((clip) =>
                    this.fetchEditions({ id: clip.id, type: ASSET_TYPE.CLIP })
                )
            );

            const trailerFromAsset: Asset[] = fetchedAssetEditions
                .filter((edition: Edition) => edition.editionType === 'trailer')
                .map((edition) => mapEditionToAssetTrailer(edition, asset));

            const extrasFromClips: Asset[] = fetchedClipsEditions
                .map((editions, clipIndex) => {
                    // When getting editions from clips, only first one from array will be used.
                    const defaultEdition = editions.find(
                        (edition) => edition.editionType === 'full'
                    );
                    return {
                        ...fetchedClips[clipIndex],
                        editionId: defaultEdition?.id,
                        isTrailer: defaultEdition?.editionType === 'trailer',
                    };
                })
                .filter((extraClip) => extraClip !== undefined);

            extras = trailerFromAsset.concat(extrasFromClips.filter((extra) => !!extra.editionId));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Error fetching extras for asset', e);
        }
        return extras;
    }

    getImagesForAsset = (
        asset: BackstageAsset | Asset
    ): { [key in keyof AssetImages]: Image['url'] | undefined | null } => {
        const getImageUrl = (
            imageKey: keyof AssetImages,
            fallbackIndex?: number
        ): string | undefined | null => {
            const images = asset.images?.[imageKey];
            if (images && images.length > 0) {
                // TODO: Implement logic to choose the best image from the array
                // For now, return the last image if available, otherwise, return the image at the fallback index or the first image
                if (fallbackIndex !== undefined) {
                    return images[fallbackIndex]?.url || images[0]?.url;
                }
                const lastIndex = images.length - 1;
                return images[lastIndex]?.url || images[0]?.url;
            }
            return undefined;
        };

        const poster = getImageUrl('poster', 2);
        const still = getImageUrl('still', 2);
        const highlight = getImageUrl('highlight', 2);
        const background = getImageUrl('background');
        const backgroundPortrait = getImageUrl('backgroundPortrait');
        const heroLandscape = getImageUrl('heroLandscape', isPlatformWeb ? 2 : 0);
        const heroPortrait = getImageUrl('heroPortrait', 2);
        const packshotLandscape = getImageUrl('still', 0);

        return {
            poster,
            still,
            background,
            heroLandscape,
            heroPortrait,
            backgroundPortrait,
            highlight,
            packshotLandscape,
        };
    };

    protected transformOpts(asset: BackstageAsset): Asset {
        const s = this.prepareStream;
        const {
            poster,
            still,
            background,
            heroLandscape,
            heroPortrait,
            backgroundPortrait,
            highlight,
            packshotLandscape,
        } = this.getImagesForAsset(asset);
        let subtitle: string | undefined | null = asset.genres?.[0]?.label;
        let channelLogo: string | undefined | null;
        let number: number | undefined | null;
        let duration: number | undefined | null = asset?.duration;
        let rating = 0;
        const ageClassification = determineAgeClassification(asset);

        const ratingNumber = Number(asset.rating);

        if (ratingNumber) {
            /*
             * Backstage returns a value between 0-100,
             * we want a value between 0-5 (stars) (rounded to 1 decimal)
             */
            rating = Math.round(((ratingNumber * 5) / 100) * 10) / 10;
        }

        const crew = (asset.crew || []).map((c) => ({ ...c, name: c.label }));

        const releaseDate = asset.releaseDate?.slice(0, 4) || asset.year?.toString() || '';
        const fullReleaseDate = asset.releaseDate ?? null;

        if (asset.genres?.[1]?.label) {
            subtitle += `, ${asset.genres[1].label}`;
        }

        if (asset.type === ASSET_TYPE.CHANNEL) {
            number = asset.number;
            subtitle = undefined;
            if (asset.images?.logo) {
                channelLogo =
                    asset.images?.logo[4]?.url ||
                    asset.images?.logo[3]?.url ||
                    asset.images?.logo[2]?.url ||
                    asset.images?.logo[1]?.url ||
                    asset.images?.logo[0]?.url;
            }
        }

        if (asset.type === ASSET_TYPE.BROADCAST || asset.type === ASSET_TYPE.LIVE_EVENT) {
            if (asset.endsAt && asset.startsAt) {
                duration = asset.endsAt - asset.startsAt;
            }
        }

        return {
            channelLogo,
            number,
            description: asset.description,
            poster,
            still,
            background,
            heroLandscape,
            heroPortrait,
            backgroundPortrait,
            highlight,
            packshotLandscape,
            genres: asset.genres,
            id: asset.id,
            subtitle,
            title: asset.label,
            rating,
            releaseDate,
            clipIds: asset.clipIds || [],
            // @ts-ignore I really don't know what type the stream should be.
            stream: s,
            type: asset.type,
            crew,
            duration,
            startsAt: asset.startsAt,
            endsAt: asset.endsAt,
            continueWatchingOffset: asset.continueWatchingOffset ?? null,
            continueWatchingLastTime: asset.continueWatchingLastTime || 0,
            ageClassification,
            externalId: asset.external_id,
            blocked: asset.blocked,
            assetLabel: asset.assetLabel,
            isAdult: asset.isAdult,
            externalAuthDetails: asset.externalAuthDetails,
            fullReleaseDate,
        };
    }

    protected async transformAsset(asset: BackstageAsset): Promise<Asset> {
        const transformedOpts = this.transformOpts(asset);
        let model: Asset | Episode;
        switch (asset.type) {
            case ASSET_TYPE.EPISODE: {
                const episode = asset as Episode;
                const {
                    seasonNumber,
                    episodeNumber,
                    series,
                    nextEpisodeId,
                    podcastName,
                    seriesType,
                } = episode;

                let seriesTitle;

                if (series && seriesType === ASSET_TYPE.SERIES) {
                    try {
                        const serie = await this.fetchAsset({
                            id: series,
                            type: ASSET_TYPE.SERIES,
                        });

                        seriesTitle = serie.title;
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.warn('Error fetching series', series, err);
                    }
                }

                model = {
                    ...transformedOpts,
                    seasonNumber,
                    episodeNumber,
                    series,
                    nextEpisodeId,
                    podcastName,
                    seriesType,
                    seriesName: seriesTitle,
                };

                break;
            }

            case ASSET_TYPE.CHANNEL: {
                // Get information about currently live programme
                const liveProgram = await this.fetchEPGChannelCurrentLiveProgramme(asset.id).then(
                    (fetchedChannel) => {
                        const onGoingProgram = fetchedChannel?.items?.[0];
                        const { progress, timeLeft, isLive } =
                            getLiveAssetTimeInformation(onGoingProgram);
                        const onGoingProgramImages = onGoingProgram
                            ? this.getImagesForAsset(onGoingProgram)
                            : undefined;
                        return {
                            progress,
                            timeLeft,
                            isLive,
                            startsAt: onGoingProgram?.startsAt || onGoingProgram?.start,
                            endsAt: onGoingProgram?.endsAt || onGoingProgram?.end,
                            broadcastMetadata: {
                                ...onGoingProgram,
                                ...onGoingProgramImages,
                            },
                        };
                    }
                );

                model = {
                    ...transformedOpts,
                    ...liveProgram,
                };

                break;
            }

            case ASSET_TYPE.BROADCAST:
            case ASSET_TYPE.LIVE_EVENT: {
                let channel: Channel | undefined;
                let number: number | undefined | null;
                const liveEvent = asset as LiveEvent;

                if (liveEvent.channelId) {
                    try {
                        channel = await this.fetchChannel(liveEvent.channelId);
                        number = channel?.number;
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.warn('Error fetching chanel', liveEvent.channelId, err);
                    }
                }

                const { progress, isLive } = getLiveAssetTimeInformation(asset);
                const isAdultChannel = channel?.isAdult ? { isAdult: true } : {};

                const enableCatchUp =
                    asset.type === ASSET_TYPE.BROADCAST
                        ? (asset as BackstageBroadcast).channelFeatures?.catchup?.enabled
                        : false;

                model = {
                    ...transformedOpts,
                    progress,
                    /* @ts-ignore */
                    channelLogo: channel?.images?.logo?.[0]?.url,
                    isLive,
                    // JS timestamp needed for AndroidTV
                    startsAt: asset.startsAt,
                    start: asset.startsAt ? asset.startsAt * 1000 : undefined,
                    endsAt: asset.endsAt,
                    end: asset.endsAt ? asset.endsAt * 1000 : undefined,
                    channel,
                    channelId: channel?.id,
                    number,
                    enableCatchUp,
                    ...isAdultChannel,
                };

                break;
            }

            default:
                model = transformedOpts;
                break;
        }

        return model;
    }

    protected async transformResponse(response: Partial<BackstagePlaylist>): Promise<Asset[]> {
        const transformWithoutError = async (asset: BackstageAsset) => {
            try {
                return await this.transformAsset(asset);
            } catch (e) {
                console.warn('Error transforming asset', e);
                return null;
            }
        };
        const result = await (response.items instanceof Array
            ? Promise.all(response.items.map((asset) => transformWithoutError(asset)))
            : Promise.resolve([]));

        return result.filter((asset) => asset !== null) as Asset[];
    }

    protected transformSeasons(groups: BackstageEpisode[][], translation?: any): Season[] {
        return groups?.map((origEpisodes) => {
            let seasonName = '';
            let seasonNumber: number | undefined;

            const episodes = origEpisodes.map((episode: BackstageEpisode) => {
                const transformedEpisode: Episode = this.transformOpts(episode) as Episode;

                transformedEpisode.episodeNumber = episode.episodeNumber;
                transformedEpisode.seasonNumber = episode.seasonNumber;
                transformedEpisode.seriesId = episode.series;
                transformedEpisode.nextEpisodeId = episode.nextEpisodeId;
                transformedEpisode.seriesType = episode.seriesType;
                transformedEpisode.podcastName = episode.podcastName;

                seasonName = `${
                    translation
                        ? firstLetterUppercase(
                              translation('asset.series.season', {
                                  count: 1,
                              })
                          )
                        : 'Season'
                }${' '}${episode.seasonNumber}`;

                seasonNumber = transformedEpisode.seasonNumber;

                return transformedEpisode;
            });

            return {
                name: seasonName,
                seasonNumber,
                episodes: episodes.sort((a: Episode, b: Episode) =>
                    a.episodeNumber > b.episodeNumber ? 1 : -1
                ),
            };
        });
    }

    protected async fetchComputedPlaylist(link: string): Promise<Asset[]> {
        if (!link) {
            return [];
        }
        return this.request({ path: link, method: 'GET', guard: BackstagePlaylistGuard })
            .then(this.transformResponse)
            .catch((err) => {
                log('ERROR FETCHING COMPUTED PLAYLIST', err);

                return [];
            });
    }

    fetchTextPageContent(reference: string): Promise<TextPageContent> {
        try {
            return this.request({
                path: `/textPages/${reference}`,
                method: 'GET',
                query: {
                    includeItems: false,
                },
            });
        } catch (err) {
            log('ERROR FETCHING PAGE', err);

            throw err;
        }
    }

    async fetchGenreAssets(
        genreId: string,
        types?: string,
        offset?: number,
        size?: number
    ): Promise<Asset[]> {
        return this.request({
            path: `/genres/${genreId}/media`,
            method: 'GET',
            guard: BackstagePlaylistGuard,
            query: {
                ...(types ? { types } : {}),
                ...(offset ? { offset } : {}),
                ...(size ? { size } : {}),
            },
        }).then(this.transformResponse);
    }
}
