import { createTestIDGetter } from '@24i/nxg-core-utils/src/test-utils';

export enum DETAILS_SCREEN_TEST_IDS {
    // Containers
    RELATED_CONTAINER = 'details_screen_related_container',
    EXTRAS_CONTAINER = 'details_screen_extras_container',
    PROGRESS_BAR = 'details_screen_progress_bar',
    // Main Buttons
    PLAY_BUTTON = 'play_button',
    REPLAY_BUTTON = 'details_screen_replay_button',
    SHARE_BUTTON = 'share_button',
    PURCHASE_BUTTON = 'details_screen_purchase_button',
    ADD_TO_FAVORITES_BUTTON = 'add_to_favorites_button',
    START_OVER_BUTTON = 'details_screen_start_over_button',
    RECORD_BUTTON = 'details_screen_record_button',
    REMIND_BUTTON = 'details_screen_remind_button',
    RATE_BUTTON = 'rate_button',
    UNAVAILABLE_BUTTON = 'details_screen_unavailable_geoblock_button',
    TITLE = 'details_screen_title',
    EPISODE_TITLE = 'episode_title',
    LONG_DESCRIPTION_TEST = 'long_description_test',
    METADATA = 'metadata',
    EPISODES_TAB = 'episodes_tab',
    TRAILERS_EXTRAS_TAB = 'trailers_extras_tab',
    MORE_LIKE_THIS_TAB = 'more_like_this_tab',
    DETAILS_TAB = 'details_tab',
    SEASON_PICKER = 'season_picker',
    TILE = 'tile',
    DESCRIPTION = 'description',
    VOD_TITLE_TEXT = 'VOD_title_text',
    VOD_TAG_TEXT = 'VOD_tag_text',
}

export const getEpisodeTestID = createTestIDGetter('episode');
