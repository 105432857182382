/* eslint-disable max-statements */

import { Dimensions } from 'react-native';
import { isEmpty } from 'lodash';
import { isFactorTv, isPlatformWeb, isFactorMobile } from 'renative';
import { AbstractImageServiceClient } from '@24i/nxg-sdk-gluons';
import { isSvgUrl } from '@24i/nxg-sdk-quarks/src/components/Image/helpers';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { IsTablet } from '@24i/nxg-core-utils/src/globalSingletons';

/* fallback images */
import fallbackXL from '../../../assets/fallback_XL.png';
import fallbackL from '../../../assets/fallback_L.png';
import fallbackM from '../../../assets/fallback_M.png';
import fallbackS from '../../../assets/fallback_S.png';

/* facebook images */
import facebookXL from '../../../assets/facebook_XL.png';
import facebookL from '../../../assets/facebook_L.png';
import facebookM from '../../../assets/facebook_M.png';
import facebookS from '../../../assets/facebook_S.png';

/* twitter images */
import twitterXL from '../../../assets/twitter_XL.png';
import twitterL from '../../../assets/twitter_L.png';
import twitterM from '../../../assets/twitter_M.png';
import twitterS from '../../../assets/twitter_S.png';

const XL = 'XL';
const L = 'L';
const M = 'M';
const S = 'S';

const fallbackImage = {
    [XL]: fallbackXL,
    [L]: fallbackL,
    [M]: fallbackM,
    [S]: fallbackS,
};

const facebookImage = {
    [XL]: facebookXL,
    [L]: facebookL,
    [M]: facebookM,
    [S]: facebookS,
};

const twitterImage = {
    [XL]: twitterXL,
    [L]: twitterL,
    [M]: twitterM,
    [S]: twitterS,
};

const getFallbackImage = (size) =>
    isPlatformWeb ? `/images/fallback_${size}.png` : fallbackImage[size];

const getFacebookImage = (size) =>
    isPlatformWeb ? `/images/facebook_${size}.png` : facebookImage[size];

const getTwitterImage = (size) =>
    isPlatformWeb ? `/images/twitter_${size}.png` : twitterImage[size];

const fallback = {
    XL: getFallbackImage(XL),
    L: getFallbackImage(L),
    M: getFallbackImage(M),
    S: getFallbackImage(S),
};

const facebook = {
    XL: getFacebookImage(XL),
    L: getFacebookImage(L),
    M: getFacebookImage(M),
    S: getFacebookImage(S),
};

const twittter = {
    XL: getTwitterImage(XL),
    L: getTwitterImage(L),
    M: getTwitterImage(M),
    S: getTwitterImage(S),
};

const BASE_URI = 'https://imageresize.24i.com';
const DEFAULT_OUTPUT = 'jpg';

const { width: screenWidth } = Dimensions.get('window');

const getScreenSizePrefix = () => {
    if (screenWidth < 801) return 'S';
    if (screenWidth < 1201) return 'M';
    if (screenWidth < 1601) return 'L';
    return 'XL';
};

export class ImageServiceClient extends AbstractImageServiceClient {
    constructor(opts = {}) {
        super(opts);
        this.baseUri = opts?.baseUri || BASE_URI;
    }

    getImageUrl = (opts = {}, images = []) => {
        const { imagePath, height, width, output, quality } = opts;
        const heightParam = height ? `&h=${height}` : '';
        const widthParam = width ? `&w=${width}` : '';
        const features = getRuntimeConfig('features');

        const imagePathWithParams = `${imagePath}${heightParam}${widthParam}`;

        // Image resizer converts images to png/jpg. When using svg this is not needed since they are unlimited scalable;
        if (isSvgUrl(imagePath)) return imagePathWithParams;

        if (features?.images?.useImageResizer) {
            const url = `${this.baseUri}/?url=${imagePathWithParams}&output=${
                output || DEFAULT_OUTPUT
            }&q=${quality || features.images?.defaultQuality}`;
            return url;
        }

        if (images.length > 0) {
            if (images.length > 1) {
                const initRes = width * height;
                const posRes = images.map((item) => item.size.width * item.size.height);
                const closestRes = posRes.reduce((prev, curr) =>
                    Math.abs(curr - initRes) < Math.abs(prev - initRes) ? curr : prev
                );

                return images[posRes.indexOf(closestRes)].url;
            }

            return images[0].url;
        }

        return imagePath;
    };

    // https://aferian.atlassian.net/wiki/spaces/PRJ001NXG/pages/50297419/Image+Type+Definition#Acceptance-criteria
    selectDetailBackgroundImage = (asset) => {
        if (isEmpty(asset)) return '';
        const { background, backgroundPortrait, packshotLandscape, still } = asset;
        const isPhone = isFactorMobile && !IsTablet.get();
        // AC 8
        if (!isPhone && background) return background;
        // AC 9
        if (!isPhone && !background) return still || packshotLandscape;
        // AC 10
        if (isPhone && backgroundPortrait) return backgroundPortrait;
        // AC 11
        if (isPhone && background && !backgroundPortrait) return background;
        // AC 12
        if (isPhone && !background) return still || packshotLandscape;
        return background || still;
    };

    getImageSource = (type) => {
        const sizePrefix = getScreenSizePrefix();

        switch (type) {
            case 'facebook':
                return facebook[sizePrefix];
            case 'twitter':
                return twittter[sizePrefix];
            case 'fallback':
            default:
                return fallback[sizePrefix];
        }
    };

    getImageSourceWithFallback = (source) => {
        if (source?.uri && typeof source?.uri === 'string') {
            return source.uri;
        }

        if (source && (typeof source === 'string' || typeof source === 'number')) {
            return source;
        }

        return this.getImageSource('fallback');
    };

    getBetterQualityAsset = (url) =>
        isFactorTv
            ? url?.replace('526x296', '2104x1184')?.replace('426x240', '1280x720')
            : url?.replace('526x296', '1052x592')?.replace('426x240', '960x540');

    getSpecificQualityAsset = (url, targetWidth, targetHeight) => {
        const match = url.match(/(\d+)x(\d+)/);

        if (match) {
            const currentWidth = parseInt(match[1], 10);
            const currentHeight = parseInt(match[2], 10);

            if (currentWidth !== targetWidth || currentHeight !== targetHeight) {
                return url.replace(/\d+x\d+/, `${targetWidth}x${targetHeight}`);
            }
        }
        return url;
    };

    // eslint-disable-next-line no-unused-vars
    getObscuredImage = (asset) => undefined;
}
