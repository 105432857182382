import { useQuery, UseQueryOptions } from 'react-query';
import { Favorite, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useUserQuery } from '../user/useUserQuery';

const useMyFavoritesQuery = (options?: UseQueryOptions<Favorite[]>) => {
    const { fetchFavorites } = useUserData();
    const { user } = useUserQuery();

    return useQuery<Favorite[]>(QUERY_KEYS.myFavorites, fetchFavorites, {
        ...options,
        enabled: !!user,
    });
};

export default useMyFavoritesQuery;
