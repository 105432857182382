import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useImage } from '@24i/nxg-sdk-gluons/src/context/ImageService';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Interactable } from '@24i/nxg-sdk-quarks';
import { InteractableRefType } from '@24i/nxg-sdk-quarks/src/components/Interactable/types';
import React, { forwardRef, memo, ReactElement, useCallback, useMemo } from 'react';
import { ViewStyle } from 'react-native';
import PackshotContent from '../components/PackshotContent';
import getPackshotStyles from '../styles/index.web';
import { PackshotStylesWeb } from '../styles/types';
import { getPackshotTestID, PACKSHOT_TEST_IDS } from '../test-utils';
import { OnPressEvent, OrientationType, PackshotPropsWeb } from '../types';

const Packshot = forwardRef<InteractableRefType, PackshotPropsWeb>((props, ref): ReactElement => {
    const {
        displayType = '',
        image = null,
        isOnPackshotPressDisabled,
        isObscured = false,
        isGrid = false,
        isGenre = false,
        onPress,
        packshotWidth = 0,
        styles: getStyles = getPackshotStyles,
        index = 0,
        sliderIndex = 0,
        testID: propsTestId = '',
        item,
        channelLogo,
        isChannel,
        isHighlighted,
        isLive,
        packshotGradient,
        packshotGradientColors,
        renderPackshotTitle,
        renderImage,
        renderProgressBar,
        resizeMode,
        actionIcon,
        subtitle,
        textPlacement,
        time,
        title,
        packshotTitleStyles,
        fallbackImage,
        type,
        actionButtonStyles,
        liveBadgeStyles,
        progressBarStyles,
        channelOverlayStyles,
        progress,
        isSelected = false,
        hideTitle,
        showMetadata,
        isGeoBlocked = false,
        isSeeAll,
        showDuration = false,
        textColor,
    } = props;

    const { theme } = useTheme();

    const { getImageUrl, getObscuredImage } = useImage();

    const styles = getStyles(theme);

    const packshotLayoutProps = useMemo(() => {
        const poster = displayType === 'portrait';

        const orientation: OrientationType = poster ? 'portrait' : 'landscape';

        const contentStyles = [
            poster ? styles.posterImageContainer : styles.landscapeImageContainer,
        ];

        const defaultImg = image
            ? getImageUrl(
                  {
                      imagePath: image,
                      ...(poster ? { width: 260, height: 460 } : { width: 460, height: 260 }),
                  },
                  item.images
              )
            : undefined;

        const obscuredImg = getObscuredImage(item);

        const packshotContainerStyles = [
            styles.item,
            packshotWidth && { width: packshotWidth },
            isGrid && styles.gridContainer,
            isGenre && styles.genreContainer,
        ] as ViewStyle;

        const testId = getPackshotTestID(PACKSHOT_TEST_IDS.CONTAINER_BUTTON, {
            scopeIndex: sliderIndex,
            index,
        });

        return {
            poster,
            orientation,
            contentStyles,
            defaultImg,
            obscuredImg,
            packshotContainerStyles,
            testId,
        };
    }, []);

    const handlePress = useCallback(
        (e: OnPressEvent): void => {
            if (isOnPackshotPressDisabled) return;
            onPress?.(e);
        },
        [onPress]
    );

    return (
        <Interactable
            testID={propsTestId ?? packshotLayoutProps.testId}
            ref={ref}
            onPress={handlePress}
            style={packshotLayoutProps.packshotContainerStyles}
            disabled={isOnPackshotPressDisabled}
        >
            <PackshotContent
                isSelected={isSelected}
                assetLabel={item?.assetLabel || ''}
                imageStyles={styles.itemImage}
                contentStyles={packshotLayoutProps.contentStyles}
                styles={styles as PackshotStylesWeb}
                image={packshotLayoutProps.defaultImg}
                poster={packshotLayoutProps.poster}
                testId={propsTestId}
                obscuredImage={packshotLayoutProps.obscuredImg}
                isObscured={isObscured}
                orientation={packshotLayoutProps.orientation}
                theme={theme}
                channelLogo={channelLogo}
                isChannel={isChannel}
                isHighlighted={isHighlighted}
                isLive={isLive}
                packshotGradient={packshotGradient}
                packshotGradientColors={packshotGradientColors}
                renderPackshotTitle={renderPackshotTitle}
                renderImage={renderImage}
                renderProgressBar={renderProgressBar}
                resizeMode={resizeMode}
                actionIcon={actionIcon}
                subtitle={subtitle}
                textPlacement={textPlacement}
                time={time}
                title={title}
                packshotTitleStyles={packshotTitleStyles}
                fallbackImage={fallbackImage}
                type={type}
                actionButtonStyles={actionButtonStyles}
                liveBadgeStyles={liveBadgeStyles}
                progressBarStyles={progressBarStyles}
                channelOverlayStyles={channelOverlayStyles}
                progress={progress}
                isGrid={isGrid}
                isGenre={isGenre}
                onPress={onPress}
                index={index}
                sliderIndex={sliderIndex}
                item={item}
                hideTitle={hideTitle}
                showMetadata={showMetadata}
                isGeoBlocked={isGeoBlocked}
                isSeeAll={isSeeAll}
                showDuration={showDuration}
                textColor={textColor}
            />
        </Interactable>
    );
});

Packshot.displayName = 'Packshot_Gluon_Web';

export default overridable(memo(Packshot), 'Packshot');
