import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';

const AccountSubscription = (theme) => ({
    container: {
        flex: 1,
        backgroundColor: theme.color.background1,
    },
    backButtonText: {
        color: theme.color.textPrimary,
        marginTop: 4,
        marginLeft: 4,
        marginBottom: 4,
        ...getFont(theme, 'h5'),
    },
    explanationText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body2'),
    },
    modalButton: {
        width: '25%',
        '@media (max-width: 425px)': {
            width: '35%',
        },
        backgroundColor: theme.color.buttonPrimary,
        borderRadius: 36,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
        padding: 8,
        alignItems: 'center',
        justifyContent: 'center',
        height: 48,
    },
    modalButtonText: {
        ...getFont(theme, 'h5'),
    },
    // Change Subscription
    changeSubscriptionContainer: {
        flex: 1,
        backgroundColor: theme.color.primaryBlur1.color,
        height: '100%',
    },
    changeSubscriptionHeaderContainer: {
        alignItems: 'center',
    },
    changeSubscriptionHeader: {
        ...getFont(theme, 'h2'),
        color: theme.color.textPrimary,
        marginTop: 8,
        paddingTop: 8,
    },
    changeSubscriptionInfoContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginLeft: 20,
        marginRight: 20,
    },
    changeSubscriptionInfoContainerLandscape: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginLeft: 20,
        marginRight: 20,
        width: '31%',
        maxWidth: 335,
    },
    changeSubscriptionPrimaryTextContainer: {
        marginBottom: 8,
    },
    changeSubscriptionFirstPrimaryText: {
        color: `${theme.color.textPrimary}80`,
        ...getFont(theme, 'body1'),
        textAlign: 'center',
    },
    changeSubscriptionSecondPrimaryText: {
        color: `${theme.color.textPrimary}60`,
        ...getFont(theme, 'body1'),
        textAlign: 'center',
    },
    changeSubscriptionBoxContainer: {
        borderColor: theme.color.contrast4,
        borderWidth: 2,
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        padding: 23,
        marginTop: 8,
        marginBottom: 8,
        width: 576,
        '@media (max-width: 596px)': {
            width: '90%',
        },
    },
    changeSubscriptionBoxPrimaryText: {
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        textAlign: 'center',
        marginTop: 4,
        marginBottom: 4,
        ...getFont(theme, 'tertiaryButton'),
    },
    changeSubscriptionPlanInfoContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 4,
        marginBottom: 8,
    },
    changeSubscriptionNewPlanName: {
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        marginRight: 7.5,
        ...getFont(theme, 'h3'),
        textAlign: 'center',
    },
    changeSubscriptionNewPlanDescription: {
        ...getFont(theme, 'body1'),
        color: `${theme.color.textPrimary}99`,
        textAlign: 'center',
        marginTop: 4,
        marginBottom: 4,
        alignSelf: 'center',
    },
    changeSubscriptionDateText: {
        ...getFont(theme, 'body2'),
        color: `${theme.color.textPrimary}99`,
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 8,
    },
    changeSubscriptionConfirmButtonContainer: {
        marginTop: 40,
        marginBottom: 12,
    },
    changeSubscriptionCancelButtonContainer: {
        marginTop: 12,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: 'transparent',
    },
    changeSubscriptionCancelButtonText: {
        marginLeft: 0,
        color: theme.color.textPrimary,
    },
    pricePerMonthPriceText: {
        ...getFont(theme, 'h4'),
    },
    pricePerMonthSecondaryText: {
        ...getFont(theme, 'body1'),
        fontWeight: '600',
    },
    // Subcription Button
    buttonContainer: {
        marginTop: 12,
        padding: 16,
        height: 'auto',
        flex: 1,
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        marginBottom: 30,
    },
    currentPlanTextStyles: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body1'),
        marginBottom: 4,
    },
    titleTextStyles: {
        ...getFont(theme, 'h4'),
        color: theme.color.textPrimary,
    },
    subtitleTextStyles: {
        color: theme.color.contrast2,
        ...getFont(theme, 'body1'),
    },
    innerContainer: {
        alignItems: 'flex-end',
    },
    innerContainerLandscape: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    insideSubscriptionButton: {
        flexDirection: 'row',
        marginBottom: 8,
        alignItems: 'center',
    },
    currencyText: {
        ...getFont(theme, 'h4'),
    },
    perMonthText: {
        ...getFont(theme, 'body1'),
        fontWeight: '600',
    },
});

export default AccountSubscription;
