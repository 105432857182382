import { useQuery } from 'react-query';
import { PageSection, Profile, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useTranslation } from 'react-i18next';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useFirebase } from '../../../context/Firebase';

const useSectionsStructureQuery = (pageId: string, selectedProfile?: Profile | null) => {
    const { i18n } = useTranslation();
    const { language } = i18n;
    const { fetchPage } = useContentData();
    const { recordError } = useFirebase();

    return useQuery<PageSection[]>(
        [QUERY_KEYS.sectionsStructure, pageId, selectedProfile?.id, language],
        () => fetchPage(pageId),
        {
            enabled: !!pageId,
            onError: (error) => {
                log(error);
                recordError?.(error);
            },
        }
    );
};

export default useSectionsStructureQuery;
