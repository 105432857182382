import React, { Fragment, useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Interactable, Text, View } from '@24i/nxg-sdk-quarks';
import LinearGradient from 'react-native-linear-gradient';
import { useTranslation } from 'react-i18next';
import doRequestAnimationFrame from '../../../lib/requestAnimationFrame/index';

const styles = {
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
};

const NOT_MOUNTED = 'Expandable text no longer mounted';

const TextToggle = ({ isExpanded, onPress, textProps }) => {
    const [focused, setFocused] = useState(false);
    const { t } = useTranslation();

    return (
        <Interactable
            onPress={onPress}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            style={{
                borderStyle: 'solid',
                borderWidth: 2,
                borderColor: focused ? '#b91e2d' : '#000000',
            }}
        >
            <Text numberOfLines={1} {...textProps}>
                {isExpanded ? t('Show less') : t('Read more')}
            </Text>
        </Interactable>
    );
};
TextToggle.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    onPress: PropTypes.func.isRequired,
    textProps: PropTypes.shape({}).isRequired,
};
const toggler = (props) => <TextToggle {...props} />;

const gradientToggler = (props) =>
    !props?.isExpanded ? (
        <LinearGradient
            start={props?.gradientStart}
            end={props?.gradientEnd}
            locations={props?.gradientLocations}
            colors={props?.gradientColors}
            style={styles.gradient}
        />
    ) : (
        <View />
    );

// For reference - https://github.com/expo/react-native-read-more-text/blob/master/index.js
const waitForRepaint = () => new Promise((resolve) => doRequestAnimationFrame(resolve));

const getNumberOfLines = (numberOfLines, isMeasured, isExpanded, isExpandable) => {
    if (!isMeasured) return 0;
    if (!isExpandable) return numberOfLines;
    if (isExpanded) return 0;
    return numberOfLines;
};

const measureText = (text, height, type, isMounted) =>
    new Promise((resolve) => {
        if (!isMounted.current) throw new Error(NOT_MOUNTED);
        text.current.measure((x, y, w, h) => {
            height[type] = h;
            resolve();
        });
    });

const ExpandableText = forwardRef(
    (
        {
            focusStyle,
            withGradient,
            gradientColors,
            numberOfLines,
            renderToggler,
            startExpanded,
            ...props
        },
        forwardedRef
    ) => {
        const [isMeasured, setIsMeasured] = useState(false);
        const [isExpanded, setIsExpanded] = useState(() => startExpanded);
        const [isExpandable, setIsExpandable] = useState(false);
        const [isFocused, setIsFocused] = useState(false);
        const isMounted = useRef(false);
        const text = useRef(null);

        useEffect(() => {
            /*
             * Track mounted state without using react state
             * It is checked before each measuring which runs async and it can happen
             * that the component unmounts before measurements are completed
             * and in that case it will end the measurements
             * For reference - https://github.com/expo/react-native-read-more-text/blob/master/index.js
             */
            isMounted.current = true;
            return () => {
                isMounted.current = false;
            };
        }, []);

        const height = {};

        useEffect(() => {
            if (numberOfLines === 0) {
                setIsExpandable(false);
                return;
            }
            waitForRepaint() // paint the whole text for measurement
                .then(() => measureText(text, height, 'full', isMounted))
                .then(() => setIsMeasured(true))
                .then(waitForRepaint) // paint the limited text for measurement
                .then(() => measureText(text, height, 'limited', isMounted))
                .then(() => {
                    if (height.full > height.limited) {
                        setIsExpandable(true);
                    }
                })
                .catch((error) => {
                    if (error.message !== NOT_MOUNTED) throw error;
                });
        }, [numberOfLines]);

        useEffect(() => {
            if (!forwardedRef) return;
            if (typeof forwardedRef === 'function') forwardedRef(text.current);
            else forwardedRef.current = text.current;
        }, [forwardedRef, text]);

        const onPress = () => {
            setIsExpanded((_) => !_);
        };

        return withGradient ? (
            <>
                <Interactable
                    onPress={onPress}
                    activeOpacity={1}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    style={isFocused ? focusStyle : {}}
                >
                    <Text
                        ref={text}
                        numberOfLines={getNumberOfLines(
                            numberOfLines,
                            isMeasured,
                            isExpanded,
                            isExpandable
                        )}
                        {...props}
                    />
                    {gradientToggler({ isExpanded, onPress, textProps: props, gradientColors })}
                </Interactable>
            </>
        ) : (
            <>
                <Text
                    ref={text}
                    numberOfLines={getNumberOfLines(
                        numberOfLines,
                        isMeasured,
                        isExpanded,
                        isExpandable
                    )}
                    {...props}
                />
                {renderToggler({ isExpanded, onPress, textProps: props })}
            </>
        );
    }
);

ExpandableText.displayName = 'ExpandableText';

const coordinateShape = {
    x: PropTypes.number,
    y: PropTypes.number,
};

ExpandableText.propTypes = {
    gradientColors: PropTypes.arrayOf(PropTypes.string),
    gradientStart: PropTypes.objectOf(PropTypes.shape(coordinateShape)),
    gradientEnd: PropTypes.objectOf(PropTypes.shape(coordinateShape)),
    gradientLocations: PropTypes.arrayOf(PropTypes.number),
    withGradient: PropTypes.bool,
    numberOfLines: PropTypes.number,
    renderToggler: PropTypes.func,
    startExpanded: PropTypes.bool,
};
ExpandableText.defaultProps = {
    focusStyle: { borderColor: 'black', borderWidth: 1 },
    gradientColors: ['#ffffff40', '#fffffff5'],
    gradientStart: { x: 0.0, y: 0.0 },
    gradientEnd: { x: 0.0, y: 1.0 },
    gradientLocations: [0.0, 1.0],
    withGradient: false,
    numberOfLines: 0,
    renderToggler: toggler,
    startExpanded: false,
};
export default ExpandableText;
