import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle, ViewStyle } from 'react-native';

interface CenteredMessageStyles {
    mainContainer: ViewStyle;
    headerText: TextStyle;
    descriptionTextContainer: ViewStyle;
    descriptionText: TextStyle;
    mainButton: ViewStyle;
    secondaryButton: ViewStyle;
}

export const getStyles = (theme: Theme): CenteredMessageStyles => ({
    mainContainer: {
        alignItems: 'center',
    },
    headerText: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        marginBottom: 34,
        ...getFont(theme, 'h2'),
    },
    descriptionTextContainer: {
        minHeight: 18,
        flexDirection: 'row',
        alignItems: 'center',
    },
    descriptionText: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        ...getFont(theme, 'body2'),
        textAlign: 'center',
    },
    mainButton: {
        marginTop: 50,
        width: 336,
    },
    secondaryButton: {
        marginTop: 35,
    },
});
