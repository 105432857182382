import { UIManager, findNodeHandle } from 'react-native';
import { LastFocusedPageTags } from '@24i/nxg-core-utils/src/globalSingletons';
import { BLOCK_TV_FOCUS } from './constants';

// To get the view tag you need to call react native's findNodeHandle on a ref.current
export function setFocusTo(viewTag: React.MutableRefObject<React.ComponentType> | number) {
    // @ts-ignore - according to TS types, UIManager.updateVie does not exist but I am too afraid to delete this as its used everywhere
    UIManager.updateView(viewTag, 'RCTView', {
        hasTVPreferredFocus: true,
    });
}

// Only works for AndroidTV
type Directions = 'right' | 'left' | 'up' | 'down';
export function setNextFocus(
    viewTag: React.MutableRefObject<React.ComponentType>,
    toViewTag: React.MutableRefObject<React.ComponentType>,
    directions: Directions[] = []
) {
    const args = {
        ...(directions.includes('right') && { nextFocusRight: toViewTag }),
        ...(directions.includes('left') && { nextFocusLeft: toViewTag }),
        ...(directions.includes('up') && { nextFocusUp: toViewTag }),
        ...(directions.includes('down') && { nextFocusDown: toViewTag }),
    };

    // @ts-ignore
    UIManager.updateView(viewTag, 'RCTView', args);
}

interface ComponentWithMountTracking extends React.Component<unknown> {
    __isMounted?: boolean;
    isMounted?: () => boolean;
}

export function setNativeProps(viewTag: unknown, props: Record<string, unknown>) {
    // @ts-ignore - according to TS types, UIManager.updateVie does not exist but I am too afraid to delete this as its used everywhere
    UIManager.updateView(viewTag, 'RCTView', props);
}

const isComponentRef = (
    value: unknown
): value is React.MutableRefObject<ComponentWithMountTracking> =>
    typeof value === 'object' && !!value && 'current' in value;

export function isfocusGuideDestinationValid(ref?: unknown) {
    if (typeof ref === 'string') return ref === BLOCK_TV_FOCUS;
    if (!isComponentRef(ref)) return false;

    return ref?.current?.__isMounted || ref?.current?.isMounted?.(); // eslint-disable-line no-underscore-dangle
}

export function setLastFocusedPageTag(
    routeKey: string,
    componentRef?:
        | React.RefObject<React.Component>
        | number
        | React.MutableRefObject<React.Component | undefined | null>
) {
    if (typeof componentRef === 'number') {
        LastFocusedPageTags.set(routeKey, componentRef);
    } else if (componentRef?.current) {
        LastFocusedPageTags.set(routeKey, findNodeHandle(componentRef.current));
    }
}

export function getLastFocusedPageTag(routeKey: string): number | null {
    return LastFocusedPageTags.get(routeKey);
}
