import React, { FC } from 'react';
import { Text } from '@24i/nxg-sdk-quarks';
import { formatFullReleaseDate, formatDurationText } from '@24i/nxg-core-utils';
import { useTranslation } from 'react-i18next';
import { getIsPodcastEpisode } from '@24i/nxg-sdk-photon';
import { EpisodePickerItemProps } from '../types';

type Props = Pick<EpisodePickerItemProps, 'item' | 'styles'>;

export const EpisodeSubtitle: FC<Props> = ({ styles, item }) => {
    const { duration, fullReleaseDate } = item;
    const { i18n } = useTranslation(['sott']);

    if (!duration && !fullReleaseDate) return null;

    const isPodcastEpisode = getIsPodcastEpisode(item);
    const shouldShowReleaseDate = isPodcastEpisode && fullReleaseDate;

    const releaseDateToRender =
        shouldShowReleaseDate && typeof fullReleaseDate === 'string'
            ? formatFullReleaseDate(fullReleaseDate, i18n.language)
            : '';
    const durationToRender = duration ? formatDurationText(duration * 1000) : '';
    const separator = shouldShowReleaseDate && durationToRender ? ' · ' : '';

    return (
        <Text style={styles?.episodeDurationText} numberOfLines={2}>
            {`${releaseDateToRender}${separator}${durationToRender}`}
        </Text>
    );
};
