export const DEFAULT_BREAKPOINTS = {
    small: {
        maxWidth: 800,
    },
    medium: {
        minWidth: 801,
        maxWidth: 1200,
    },
    large: {
        minWidth: 1201,
        maxWidth: 1600,
    },
    xl: {
        minWidth: 1601,
    },
};
