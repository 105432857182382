import { withEmptyArray, createGuard } from '@24i/nxg-core-utils/src/guards';
import {
    AppSettingsApiGuard,
    AppSettingsApplicationGuard,
    AppSettingsFeaturesGuard,
    AppSettingsThemingGuard,
} from '@24i/nxg-sdk-photon';
import * as t from 'io-ts';
import { WelcomeScreenGuard } from '@24i/nxg-sdk-photon/src/models/welcomeScreen';
import { textPageContentGuard } from '@24i/nxg-sdk-photon/src/models/textPageContent';

export const AppSettingsResponseGuard = t.partial(
    {
        application: withEmptyArray(AppSettingsApplicationGuard),
        api: withEmptyArray(AppSettingsApiGuard),
        features: withEmptyArray(AppSettingsFeaturesGuard),
        theming: withEmptyArray(AppSettingsThemingGuard),
    },
    'AppSettingsResponse'
);

export const WelcomeScreenResponseGuard = WelcomeScreenGuard;

export const TextPagesContentResponseGuard = createGuard(
    'TextPagesContentResponseGuard',
    t.array(textPageContentGuard)
);

export type AppSettings = t.TypeOf<typeof AppSettingsResponseGuard>;
export type AppSettingsResponse = t.TypeOf<typeof AppSettingsResponseGuard>;
