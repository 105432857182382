import { useAdobePrimetime } from '@24i/adobe-primetime/src';
import { NavScreenConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useMenuItemPress } from '../../../utils';
import { GuestDropdownProps } from '../types';
import { GuestDropdownViewProps } from '../View/types';
import { DropdownItem } from '../../TopBarMenu/components/TopBarMenuDropdown/types';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../constants';

export const useViewModel = (props: GuestDropdownProps): GuestDropdownViewProps => {
    const { menuItem, screens } = props;
    const {
        authState: { authenticated },
    } = useAdobePrimetime();
    const adobePrimetimeEnabled = useFeature('adobePrimetime')?.enabled;

    const onPress = useMenuItemPress();

    const handleItemClick = async (dropdownItem: DropdownItem): Promise<void> => {
        const correspondingMenuItem = screens.find(({ name }) => name === dropdownItem.name);

        if (correspondingMenuItem) onPress(correspondingMenuItem);
    };

    if (adobePrimetimeEnabled) {
        const mvpdLinkRef = SOTT_DEFAULT_WEB_SCREENS.MvpdSignIn.getLink().href;
        const signInLinkRef = SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink().href;
        const signOutLinkRef = SOTT_DEFAULT_WEB_SCREENS.SignOut.getLink().href;

        const mvpdSigninScreen = screens?.find(
            (screen) => screen?.webMenuLink?.href === mvpdLinkRef
        );

        const dropdownItems = screens
            .reduce(
                (acc: NavScreenConfigWeb[], screen: NavScreenConfigWeb): NavScreenConfigWeb[] => {
                    if (!authenticated && screen?.webMenuLink?.href === signOutLinkRef) {
                        // If the user is not authenticated,
                        // exclude the sign out screen from the array
                        return acc;
                    }
                    if (authenticated && screen?.webMenuLink?.href === signInLinkRef) {
                        // If the user is authenticated,
                        // exclude the sign in screen from the array
                        return acc;
                    }
                    if (screen?.webMenuLink?.href === signInLinkRef) {
                        // If the screen is the sign in screen,
                        // replace it with the custom sign in screen
                        // @ts-expect-error
                        const { name, webMenuLink } = mvpdSigninScreen;
                        return [...acc, { ...screen, name, webMenuLink }];
                    }
                    if (screen?.webMenuLink?.href === mvpdLinkRef) return acc;
                    // Otherwise, include the screen in the array
                    return [...acc, screen];
                },
                []
            )
            .map(
                (screen): DropdownItem => ({
                    name: screen.name,
                    title: screen.menuTitle,
                    icon: screen.icon,
                })
            );
        return { menuItem, dropdownItems, handleItemClick };
    }

    const dropdownItems = screens.map(
        (screen): DropdownItem => ({
            name: screen.name,
            title: screen.menuTitle,
            icon: screen.icon,
        })
    );

    return { menuItem, dropdownItems, handleItemClick };
};
