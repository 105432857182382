export const calcMinutes = (time) => {
    const minutes = time.getHours() * 60 + time.getMinutes();
    return minutes;
};

export const isToday = (d1, d2) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

export const dateDiffInWords = (a, b) => {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    const dateDiff = Math.floor((utc2 - utc1) / (60000 * 60 * 24));
    if (dateDiff === 0) {
        return 'TODAY';
    }
    if (dateDiff === 1) {
        return 'TOMORROW';
    }
    if (dateDiff === -1) {
        return 'YESTERDAY';
    }
    if (dateDiff < -1) {
        return `${Math.abs(dateDiff)} DAYS AGO`;
    }
    if (dateDiff > 1) {
        return `IN ${dateDiff} DAYS`;
    }
    return null;
};

export const minuteDifference = (start, end) => {
    const length = Math.abs(end - start);
    const res = Math.floor(length / 60000);
    return res;
};

export const midnights = (date) => {
    const todayMidnight = +new Date(date).setHours(0, 0, 0, 0);
    const tomorrowMidnight = +new Date(date).setHours(24, 0, 0, 0);
    return { todayMidnight, tomorrowMidnight };
};

export const cutOffCells = (selectedDate, startStamp, endStamp) => {
    const { todayMidnight, tomorrowMidnight } = midnights(selectedDate);
    let start = startStamp >= todayMidnight ? startStamp : todayMidnight;
    start = +new Date(start).setSeconds(0, 0);
    const startFromMid = start;
    let end = endStamp <= tomorrowMidnight ? endStamp : tomorrowMidnight;
    end = +new Date(end).setSeconds(0, 0);
    const mins = minuteDifference(start, end);
    start = Math.floor((start - todayMidnight) / 60000);
    return { start, end, mins, startFromMid, endTillMid: end };
};

export const hourChecker = (
    start,
    end,
    tomorrowMidnight,
    time = new Date().getHours(),
    todayMidnight
) => {
    const startTime = start.getHours();
    const endTime = end.getHours();

    const isPlayingAtMidnight =
        (startTime <= time && +end >= tomorrowMidnight) ||
        (+start <= todayMidnight && endTime >= time);

    return (startTime <= time && endTime >= time) || isPlayingAtMidnight;
};

export const isOnThisDay = (start, end, todayMidnight, tomorrowMidnight) => {
    if (end <= todayMidnight || start >= tomorrowMidnight) {
        return false;
    }
    return true;
};

export const isPlayingNow = (time, start, end) => Number(time) >= start && Number(time) <= end;

export const formattedTime = (is12HourClock, currentTime) => {
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours < 10 ? `0${hours}` : hours;

    if (is12HourClock) {
        hours %= 12;
        hours = hours || 12; // the hour '0' should be '12'
    }

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;

    if (is12HourClock) {
        return strTime;
    }

    return `${hours.toString().length > 1 ? '' : '0'}${hours}:${minutes}`;
};

export const timeElapsed = (start, end) => {
    const currentMins = calcMinutes(new Date());
    const startMinutes = calcMinutes(start);
    const endMinutes = calcMinutes(end);
    const duration = (endMinutes - startMinutes) / 1000 / 60;
    const timeLeft = (endMinutes - currentMins) / 1000 / 60;
    const elapsed = Math.round((100 * (duration - timeLeft)) / duration);

    return elapsed;
};
