import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from '@24i/nxg-sdk-quarks';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { getStyles } from './styles';

interface Props {
    onContinue: () => void;
}

const SubscriptionCancelled = ({ onContinue }: Props) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View>
            <View style={styles.space}>
                <Text style={styles.title}>{t('svod.cancel.cancelSuccess.title')}</Text>
            </View>
            <View style={styles.space}>
                <Text style={styles.message}>{t('svod.cancel.cancelSuccess.description')}</Text>
            </View>
            <View style={styles.buttonWrapper}>
                <ActionButton
                    title={t('common.continue')}
                    onPress={onContinue}
                    additionalContainerStyles={{
                        width: 'auto',
                        height: 52,
                        paddingHorizontal: 16,
                    }}
                    additionalTextStyles={{
                        marginLeft: 0,
                    }}
                />
            </View>
        </View>
    );
};

export default SubscriptionCancelled;
