import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { ViewStyle, TextStyle } from 'react-native';

export interface ForgotPasswordProps extends Partial<ScreenProps> {
    isModal?: boolean;
}

export interface ViewModelReturnType extends ForgotPasswordProps {
    email: string;
    isValidEmail: boolean;
    onEmailChange: (text: string) => void;
    hasError: boolean;
    errorMessage?: string;
    isLoading: boolean;
    onSendLinkPress: (text: string) => Promise<void | null>;
    onBackToSignIn: () => void;
}

export enum FORGOT_PASSWORD_TEST_IDS {
    FORGOT_PASSWORD_EMAIL_INPUT = 'forgot_password_email_input',
    RESET_PASSWORD_BUTTON = 'reset_password_button',
    GO_BACK_BUTTON = 'forgot_password_screen_go_back_button',
}

export interface CommonLayoutStyles {
    input: TextStyle;
    inputOnError: TextStyle;
    loader: ViewStyle;
    sendButtonContainer: ViewStyle;
    instructionText: TextStyle;
    errorText: TextStyle;
}

export interface ForgotPasswordStyles {
    container: ViewStyle;
    backButtonContainer: ViewStyle;
    backButtonText: TextStyle;
}

export interface ForgotPasswordWebStyles extends Partial<ForgotPasswordStyles> {
    wrapper: ViewStyle;
    header: ViewStyle;
    backArrowContainer: ViewStyle;
    headerText: TextStyle;
}
