import { IsAssetPurchasedResponse, PurchasesProps } from '@24i/nxg-sdk-photon/src';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import { queryKeys } from '../queryKeys';

export const useInvalidatePurchases = () => {
    const queryClient = useQueryClient();

    const invalidatePurchasesQuery = () => {
        queryClient.invalidateQueries(queryKeys.myPurchases);
        queryClient.invalidateQueries(queryKeys.myRentals);
    };

    return { invalidatePurchasesQuery };
};

const useIsAssetPurchasedQuery = (
    asset: PurchasesProps,
    options?: UseQueryOptions<unknown, Error, IsAssetPurchasedResponse>
) => {
    const { fetchIsAssetPurchased } = usePurchaseData();
    const reactQuery = getRuntimeConfig('reactQuery');

    return useQuery<unknown, Error, { isPurchased: boolean }>(
        queryKeys.isAssetPurchased(asset?.id),
        () => fetchIsAssetPurchased(asset),
        {
            staleTime: reactQuery.staleTime.short,
            enabled: Boolean(asset),
            ...options,
        }
    );
};

export default useIsAssetPurchasedQuery;
