import React, { ReactElement } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import getSubactionButtonStyles from '../styles';
import { IconButtonProps } from '../../IconButton/types';

const SubactionButton = (props: IconButtonProps): ReactElement => {
    const { styles } = props;
    return (
        <IconButton styles={styles || getSubactionButtonStyles} activeOpacity={0.5} {...props} />
    );
};

export { getSubactionButtonStyles };
export default overridable(SubactionButton, 'SubactionButton');
