import React from 'react';
import { DeleteAccountScreenProps } from './types';

import View from './View';
import useViewModel from './viewModel';
import getDeleteAccountStyles from './styles';

const Main = (props: DeleteAccountScreenProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default { Main, View, useViewModel, getDeleteAccountStyles };
