import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '../../../utils/styles/constants';
import { CreatePinScreenWebViewStyles } from './types';

const styles = (theme: Theme): CreatePinScreenWebViewStyles => {
    const description = {
        color: theme.color.textPrimary,
        opacity: 0.6,
        ...getFont(theme, 'body2'),
        textAlign: 'center',
    };
    return {
        description1: {
            ...description,
            marginBottom: 48,
            width: Breakpoint.MD,
            alignSelf: 'center',
            [`@media (max-width: ${Breakpoint.MD}px)`]: {
                marginBottom: 32,
                width: '100%',
            },
        },
        description2: {
            ...description,
            marginTop: 12,
            marginBottom: 26,
        },
        logo: {
            position: 'absolute',
            width: 72,
            height: 42,
            top: 14,
            left: 48,
        },
        headerText: {
            color: theme.color.textPrimary,
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: 150,
            flexGrow: 1,
            ...getFont(theme, 'h2'),
        },
        actionContainer: {
            zIndex: 1,
            position: 'absolute',
            bottom: 12,
            right: -5,
            backgroundColor: 'transparent',
            shadowColor: 'transparent',
            transform: 'uppercase',
        },
        actionText: {
            color: theme.color.textPrimary,
            ...getFont(theme, 'h6'),
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        loading: {
            marginTop: 12,
            height: 52,
            justifyContent: 'center',
        },
        userIcon: {
            position: 'absolute',
            right: 48,
            top: 23,
        },
        secureText: {
            fontSize: 30,
        },
        onboardingHeaderWrapper: {
            marginBottom: 24,
            [`@media (max-width: ${Breakpoint.MD}px)`]: {
                marginBottom: 92,
            },
        },
    };
};

export default styles;
