/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { IDENTITY_PROVIDERS } from '@24i/nxg-core-utils/src/constants';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useAdobePrimetime } from '@24i/adobe-primetime/src';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../../navigation/constants';
import SignOut from '../../View/index';
import useUserSession from '../../../../hooks/useUserSession';
import { cleengLogout } from '../../../../utils/cleeng';

function withSignoutModal<P>(Component: React.ComponentType<P>) {
    return (hocProps: Omit<P, 'setSignoutModalVisible'>) => {
        const [isModalVisible, setIsModalVisible] = useState(false);
        const { closeModal, setModalProps } = useModal();
        const identityProvider = useFeature('identityProvider');
        const { recordError, crashlyticsLog } = useFirebase();
        const userDataClient = useUserData();
        const { signOutUser } = useUserSession();
        const router = useRouter();
        const {
            authState: { authenticated: mvpdAuthenticated },
        } = useAdobePrimetime();

        const landingScreenEnabled = useFeature('landingScreen')?.enabled;

        const closeModalComponent = () => {
            setIsModalVisible(false);

            if (router?.pathname.includes('signout')) router.back();
        };
        const logoutFlowId = async () => {
            crashlyticsLog('Sign out pressed');
            await (userDataClient as any).logoutFlowID();
            const homeLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();
            await router.push(homeLink.href, homeLink.as);
            router.reload(); // TODO we need to this to refresh AppState, more info [PRDSAPPSRN-12561];
        };

        const signOutOnPressHandler = async () => {
            try {
                if (mvpdAuthenticated) {
                    setIsModalVisible(false);

                    if (!landingScreenEnabled) {
                        const homeLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();
                        router.push(
                            {
                                pathname: homeLink.href,
                                query: { shouldSignOut: true },
                            },
                            homeLink.as
                        );
                        return;
                    }
                    const mvpdLink = SOTT_DEFAULT_WEB_SCREENS.MvpdSignIn.getLink();
                    router.push(
                        {
                            pathname: mvpdLink.href,
                            query: { didInitialise: true, shouldSignOut: true },
                        },
                        mvpdLink.as
                    );
                    return;
                }

                cleengLogout();

                if (identityProvider?.enabled) {
                    switch (identityProvider?.provider) {
                        case IDENTITY_PROVIDERS.FLOWID:
                        case IDENTITY_PROVIDERS.FLOWID_V2:
                            await logoutFlowId();
                            break;
                        case IDENTITY_PROVIDERS.GENERIC:
                        default:
                            await signOutUser();
                            break;
                    }
                } else {
                    await signOutUser();
                }
                closeModal();
                crashlyticsLog('Sign out pressed');
            } catch (error) {
                recordError(error);
                log(error);
                /* eslint-disable no-alert */
                alert(error || 'Something went wrong...');
            }
        };

        return (
            <>
                <Component {...(hocProps as P)} setSignoutModalVisible={setIsModalVisible} />
                <Modal visible={isModalVisible} onRequestClose={closeModalComponent} transparent>
                    <SignOut
                        onConfirm={signOutOnPressHandler}
                        onClose={closeModalComponent}
                        setModalProps={setModalProps}
                    />
                </Modal>
            </>
        );
    };
}

export default withSignoutModal;
