/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo, useRef } from 'react';
import { Asset, PinProtectionScopes } from '@24i/nxg-sdk-photon';
import { usePrevious } from '@24i/nxg-core-utils/src/hooks';
import { useTranslation } from 'react-i18next';
import { isPlatformIos, isPlatformAndroid, isPlatformWeb } from 'renative';
import { Platform } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { usePinProtectedScreen } from '../usePinProtectedScreen';
import { UseProtectedScreenParams } from '../types';
import { usePinProtection } from '../../context/PinProtection';
import { getIsBlockedForAllAges, getIsUnderAgeLimit } from './utils';

const { useStore } = Platform.isTV
    ? require('@24i/nxg-sdk-smartott-tv/src/context/ApplicationStore')
    : require('@24i/nxg-sdk-smartott/src/context/ApplicationStore');

export type UseProtectedAssetParams = UseProtectedScreenParams & {
    asset?: Asset;
    usePinScope?: {
        arePinScopesValidated: ((scopes: PinProtectionScopes[]) => boolean) | undefined;
    };
};
export type UseProtectedAssetProps = {
    hasAgeBlocker?: boolean;
    hasAdultBlocker?: boolean;
    triggerPinControl: () => Promise<boolean>;
};
const isNativePlatform = isPlatformIos || isPlatformAndroid;

export const useAssetPinControl = ({
    asset,
    active: isPinControlActive = true,
    usePinScope,
    ...rest
}: UseProtectedAssetParams): UseProtectedAssetProps => {
    const { t } = useTranslation(['sott']);
    const { selectedProfile } = useStore();
    const { arePinScopesValidated } =
        Platform.isTV && usePinScope ? usePinScope : usePinProtection();
    const isFocused = isNativePlatform ? useIsFocused() : true;

    const previousAssetId = usePrevious(asset?.id, (_, next) => !!next);
    const previousFocus = usePrevious(isFocused, () => true);
    const assetChanged = previousAssetId !== asset?.id;
    const cameIntoFocus = isFocused && previousFocus !== isFocused;

    const scopes = useRef<PinProtectionScopes[]>([]);

    let isUnderAgeLimit = true;
    let isBlockedForAllAges = true;
    let isAdultBlocked = true;

    isUnderAgeLimit = useMemo(
        () => getIsUnderAgeLimit({ selectedProfile, asset }),
        [getIsUnderAgeLimit, selectedProfile, asset]
    );

    isBlockedForAllAges = useMemo(
        () => getIsBlockedForAllAges({ selectedProfile, asset }),
        [getIsBlockedForAllAges, selectedProfile, asset]
    );

    isAdultBlocked = !!asset?.isAdult;

    const isAdultValidationRequired = useRef(false);
    const isAgeValidationRequried = useRef(false);

    const updateUnlockStatus = () => {
        if (cameIntoFocus || assetChanged || isFocused) {
            if (
                (isUnderAgeLimit || isBlockedForAllAges) &&
                !arePinScopesValidated?.([PinProtectionScopes.ageControl])
            ) {
                isAgeValidationRequried.current = true;
            } else {
                isAgeValidationRequried.current = false;
            }

            if (isAdultBlocked && !arePinScopesValidated?.([PinProtectionScopes.adultControl])) {
                isAdultValidationRequired.current = true;
            } else {
                isAdultValidationRequired.current = false;
            }
        }
    };
    const updateScopes = () => {
        if (isAdultValidationRequired.current && isAgeValidationRequried.current) {
            scopes.current = [PinProtectionScopes.ageControl, PinProtectionScopes.adultControl];
        } else if (isAgeValidationRequried.current) {
            scopes.current = [PinProtectionScopes.ageControl];
        } else if (isAdultValidationRequired.current) {
            scopes.current = [PinProtectionScopes.adultControl];
        }
    };

    const updateRutine = () => {
        updateUnlockStatus();
    };

    const { triggerPinControl } = usePinProtectedScreen({
        scopes: scopes.current,
        modalProps: {
            animationType: 'none',
            isAsset: true,
            isAdult: !!asset?.isAdult,
            message: asset?.isAdult
                ? t('pin.enter.contentLockedAdult.description')
                : t('pin.enter.ageRestriction.description'),
        },
        goBackAfterActivation: !isPlatformWeb && !!asset?.isAdult,
        goBackAfterDismiss: true,
        ...rest,
        active:
            (isAdultValidationRequired.current || isAgeValidationRequried.current) &&
            isPinControlActive,
    });

    const triggerAssetPinControl = async () => {
        await triggerPinControl({ triggerScopes: scopes.current });
        updateRutine();
        return !isAgeValidationRequried.current && !isAdultValidationRequired.current;
    };
    updateUnlockStatus();
    updateScopes();

    return useMemo(
        () => ({
            hasAgeBlocker: isAgeValidationRequried.current,
            hasAdultBlocker: isAdultValidationRequired.current,
            triggerPinControl: triggerAssetPinControl,
        }),
        [isAgeValidationRequried.current, isAdultValidationRequired.current, triggerAssetPinControl]
    );
};

export { getIsUnderAgeLimit };
