import { DevicesManagementClient } from '@24i/nxg-sdk-photon/src/clients/DevicesManagementClient';
import { Device } from '@24i/nxg-sdk-photon';
import { getCurrentDevice, toggleBy, removeBy, findByField } from '@24i/nxg-core-utils';
import { Storage } from '@24i/nxg-sdk-quantum';
import { ASYNC_STORAGE_KEY_MOCK_DEVICES } from '@24i/nxg-core-utils/src/constants';
import { fakeBackend } from '../../utils';

const DEVICE_LIMIT = 5;

const mockDevices: Device[] = [
    {
        deviceId: '4CBC333A-F97B-4D0D-9D24-5016603F6066',
        name: "Matej's iPhone",
        registrationDate: '2021-11-10T10:49:57.667Z',
        platform: 'iphone',
    },
    {
        deviceId: '4CBC034A-F97B-4D0D-9D24-3336603F6066',
        name: "Matej's iPad",
        registrationDate: '2021-11-11T10:49:57.667Z',
        platform: 'ipad',
    },
    {
        deviceId: '4CBC034A-F97B-4D0D-9D24-5013333F6066',
        name: "Florian's Android",
        registrationDate: '2021-11-12T10:49:57.667Z',
        platform: 'android',
    },
    {
        deviceId: '4CBC034A-F97B-4D0D-9D24-5016603F6333',
        name: 'Sony KD-43X7055',
        registrationDate: '2021-11-13T10:49:57.667Z',
        platform: 'laptop',
    },
    {
        deviceId: '4CBC034A-F97B-4333-9D24-5016603F6333',
        name: 'Galaxy Tab A7',
        registrationDate: '2021-11-14T10:49:57.667Z',
        platform: 'tabletAndroid',
    },
];

const getDevicesFromStorage = async () => {
    try {
        return JSON.parse(await Storage.getItem(ASYNC_STORAGE_KEY_MOCK_DEVICES));
    } catch {
        return undefined;
    }
};

const setDevicesToStorage = async (devices: Device[]) => {
    await Storage.setItem(ASYNC_STORAGE_KEY_MOCK_DEVICES, JSON.stringify(devices));
};

export class DevicesManagementClientStub implements DevicesManagementClient {
    devices: Device[] = [];

    constructor(shouldFillWithMockData = false) {
        if (shouldFillWithMockData) {
            this.devices = mockDevices;
        }
    }

    fetchDevices = async (): Promise<Device[]> => {
        const devicesFromStorage = await getDevicesFromStorage();

        if (Array.isArray(devicesFromStorage)) {
            return devicesFromStorage;
        }

        await setDevicesToStorage(mockDevices);

        await fakeBackend(1);

        return mockDevices;
    };

    addDevice = async (device: Device): Promise<void> => {
        await fakeBackend(1);

        const devices = await this.fetchDevices();

        const updatedDevices = toggleBy(devices, device, (d) => d.deviceId === device.deviceId);

        await setDevicesToStorage(updatedDevices);
    };

    deleteDevice = async (device: Device): Promise<void> => {
        await fakeBackend(1);

        const devices = await this.fetchDevices();

        const updatedDevices = removeBy(devices, (d) => d.deviceId === device.deviceId);

        await setDevicesToStorage(updatedDevices);
    };

    checkDeviceLimit = async (): Promise<boolean> => {
        const currentDevice = await getCurrentDevice();
        const devices = await this.fetchDevices();
        const deviceLimit = await this.getDeviceLimit();

        const found = findByField(devices, 'deviceId', currentDevice.deviceId);

        if (found) {
            return devices.length <= deviceLimit;
        }

        if (devices.length >= deviceLimit) {
            return false;
        }

        await this.addDevice(currentDevice);

        return true;
    };

    getDeviceLimit = async (): Promise<number> => {
        return DEVICE_LIMIT;
    };
}
