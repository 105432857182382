import React from 'react';
import DoubleRowWithHighlight from './DoubleRowWithHighlight';
import IndependentDoubleRow from './IndependentDoubleRow';
import PureDoubleRow from './PureDoubleRow';
import type { DoubleRowProps } from './types';

const DoubleRow = (props: DoubleRowProps) => {
    const { renderHighlight, independentScrolling } = props;
    if (independentScrolling && renderHighlight) {
        throw new Error(
            'DoubleRow cannot have highlight and independent scrolling at the same time'
        );
    } else if (independentScrolling) {
        return <IndependentDoubleRow {...props} />;
    } else if (renderHighlight) {
        return <DoubleRowWithHighlight renderHighlight={renderHighlight} {...props} />;
    }
    return <PureDoubleRow {...props} />;
};

export default DoubleRow;
