/* eslint-disable no-extra-boolean-cast */
import {
    ASSET_TYPE,
    AppSettings,
    AppSettingsDataClient,
    RATING_SYSTEM,
    getDummyClient,
} from '@24i/nxg-sdk-photon';
import deepmerge from 'deepmerge';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { PrimaryButtonType } from '../../types/Detail';

type ServiceConfigDataWrapper = { data: AppSettings | null; isLoading: boolean };

const defaultClient = getDummyClient<AppSettingsDataClient>();

type ContextType = {
    client: AppSettingsDataClient;
    serviceConfigData: ServiceConfigDataWrapper;
    setServiceConfigData: (newValue: ServiceConfigDataWrapper) => void;
};

export const AppSettingsDataContext = createContext<ContextType>({
    client: defaultClient,
    serviceConfigData: { data: null, isLoading: false },
    setServiceConfigData: () => {
        throw new Error(
            `You are trying to use the AppSettingsDataContext but there is no provider available!`
        );
    },
});
interface AppSettingsDataProviderProps {
    client: AppSettingsDataClient;
    children: ReactNode;
}

export const AppSettingsDataProvider = ({
    client = defaultClient,
    children,
}: AppSettingsDataProviderProps) => {
    const [serviceConfigData, setServiceConfigData] = useState<ContextType['serviceConfigData']>({
        data: null,
        isLoading: false,
    });

    const fetchServiceConfig = async () => {
        const result = await client.fetchServiceConfig();
        const runtimeFeatures = getRuntimeConfig('features');

        return deepmerge<AppSettings>({ features: runtimeFeatures }, result, {
            arrayMerge: (d, s) => s,
        });
    };

    return (
        <AppSettingsDataContext.Provider
            value={{
                client: { ...client, fetchServiceConfig },
                serviceConfigData,
                setServiceConfigData,
            }}
        >
            {children}
        </AppSettingsDataContext.Provider>
    );
};

export const useAppSettingsData = () => useContext(AppSettingsDataContext);

export const useSettings = <T extends keyof AppSettings>(key: T): AppSettings[T] | never => {
    const appSettings = useAppSettingsData();

    const settings = appSettings.serviceConfigData.data?.[key];

    if (!settings) {
        throw new Error(
            `Settings '${key}' is missing. Ensure the settings is provided by the Backstage or set in the renative configuration file.`
        );
    }

    return settings;
};

const getDefaults = <T extends keyof AppSettings['features']>(
    key: T
): AppSettings['features'][T] | undefined => {
    const FEATURE_DEFAULTS: Partial<AppSettings['features']> = {
        inAppPurchases: {
            enabled: true, // TODO: For me this should be false as default, but leaving true for backwards compatibility
            disabledPlatforms: [], // TODO: For me this should be `enabledPlatforms` instead, is more resillent to new platforms, but following spec: https://aferian.atlassian.net/wiki/spaces/PRJ001NXG/pages/50317333/Custom+JSON+Feature+Configuration
            detailButtonWhenNoPurchases: PrimaryButtonType.PURCHASE,
        },
        ratings: {
            enabled: true,
            system: RATING_SYSTEM.FIVESTARS,
            rateableAssets: [ASSET_TYPE.MOVIE, ASSET_TYPE.EPISODE, ASSET_TYPE.LIVE_EVENT],
        },
    };
    return FEATURE_DEFAULTS[key as string];
};

export const useFeature = <T extends keyof AppSettings['features']>(
    name: T,
    defaultValue = getDefaults(name)
): AppSettings['features'][T] | undefined => {
    const appSettings = useAppSettingsData();

    const feature = appSettings.serviceConfigData.data?.features[name] ?? defaultValue;

    return feature;
};
