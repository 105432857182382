import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ExpandableTextStyles } from '../types';

const getExpandableTextStyles = (theme: Theme): ExpandableTextStyles => ({
    itemHolder: {
        backgroundColor: 'transparent',
    },
    description: {
        ...getFont(theme, 'body1'),
        color: theme.color.textPrimary,
        width: '100%',
    },
    container: {
        marginVertical: 16,
    },
    textOpacity: {
        opacity: 0.8,
    },
    invisibleTextOpacity: {
        opacity: 0,
    },
    textGradient: {
        position: 'absolute',
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1,
        height: getFont(theme, 'body1').lineHeight,
        borderRadius: 9,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
});

export default getExpandableTextStyles;
