/*
@PRODUCT_FEATURE: J 1.0
*/
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import * as prerenderFunctions from '../../pages/my-content';
import { SubMenuScreen } from '../SubMenuScreen';
import { MyContentScreenProps } from './types';

// Cycle dependency ignored since we are importing other screens for default (ex, MyPurchases, MyList...) that the one we are importing (MyContent) in screenConfigBuilders
// eslint-disable-next-line import/no-cycle
import { useViewModel } from './viewModel';

const header = (headerProps) => <TopBarPrimary {...headerProps} />;

export default {
    Main: (props: MyContentScreenProps): ReactElement => {
        const { subMenuScreens, submenuTitle, ...rest } = useViewModel(props);

        return (
            <SubMenuScreen
                header={header}
                subMenuTitle={submenuTitle}
                subMenuScreens={subMenuScreens}
                {...rest}
            />
        );
    },
    page: {
        'my-content': {
            ...prerenderFunctions,
        },
    },
};
