import { Theme } from '@24i/nxg-sdk-photon';
import { ChangeSubscriptionConfirmStyles } from './types';

export const getStyles = (theme: Theme): ChangeSubscriptionConfirmStyles => ({
    space: {
        marginBottom: 16,
    },
    informationTextWrapper: {
        marginBottom: 24,
    },
    informationText: {
        color: theme.color.labelPrimary,
    } as const,
    buttonWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
});
