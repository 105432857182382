import React, { ReactElement } from 'react';
import { View, Text, Interactable } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';

import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { MORE_SCREEN_AUTH_SECTION_TEST_IDS, MoreScreenAuthSectionProps } from '../types';
import getMoreScreenAuthSectionStyles from '../styles';

const MoreScreenAuthSection = (props: MoreScreenAuthSectionProps): ReactElement => {
    const {
        styles: getStyles = getMoreScreenAuthSectionStyles,
        onSignInPress = () => null,
        onRegisterPress,
    } = props;

    const { t } = useTranslation('sott');
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const authGradientColors = [
        theme.color.darker1,
        `${theme.color.darker1}7F`,
        `${theme.color.darker1}00`,
    ];

    return (
        <View
            style={[
                { backgroundImage: `linear-gradient(${authGradientColors})` },
                styles.authSectionContainer,
            ]}
        >
            <Text style={styles.authDescriptionText} numberOfLines={2}>
                {t('auth.signIn.description')}
            </Text>
            <Interactable
                testID={MORE_SCREEN_AUTH_SECTION_TEST_IDS.LOG_IN_BUTTON}
                onPress={onSignInPress}
                style={styles.authPrimaryButtonContainer}
            >
                <Text style={styles.primaryAuthButtonText}>{t('auth.signIn.title')}</Text>
            </Interactable>
            {onRegisterPress && (
                <Interactable
                    testID={MORE_SCREEN_AUTH_SECTION_TEST_IDS.SIGN_UP_BUTTON}
                    onPress={onRegisterPress}
                    style={styles.authSecondaryButtonContainer}
                >
                    <Text style={styles.secondaryAuthButtonText}>{t('register.title')}</Text>
                </Interactable>
            )}
        </View>
    );
};

export { getMoreScreenAuthSectionStyles };
export default overridable(MoreScreenAuthSection, 'MoreScreenAuthSection');
