import { ReactNode } from 'react';
import { SectionListData } from 'react-native';
import { Subscription, Purchase, Rent, Product } from '@24i/nxg-sdk-photon/src';

export enum PurchaseFlowStep {
    Selection,
    Confirm,
    Success,
    Failure,
    Unavailable,
}

export enum PurchaseModalLayout {
    Basic = 'basic',
    Status = 'status',
}

interface BaseStepData {
    title: string;
    subTitle?: string | null;
    content: ReactNode;
    layout: PurchaseModalLayout;
    step: PurchaseFlowStep;
}

export interface BasicStepData extends BaseStepData {
    layout: PurchaseModalLayout.Basic;
}

export interface StatusStepData extends BaseStepData {
    layout: PurchaseModalLayout.Status;
    actionButton: {
        title: string;
        onPress: () => void;
    };
    cancelButton?: {
        title?: string;
        onPress: () => void;
    };
}

export type StepData = BasicStepData | StatusStepData;

export type Section<T> = {
    title: string;
    key: string;
    data: T[];
};

export type AvailableOptions = Product | Purchase | Rent | Subscription;

export type SectionsData = SectionListData<
    Purchase | Rent | Subscription,
    { key: string; title: string }
>[];
