var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.createExperienceEpgDataClient=exports.ExperienceEpgDataClient=void 0;var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));var _slicedToArray2=_interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));var _createClass2=_interopRequireDefault(require("@babel/runtime/helpers/createClass"));var _classCallCheck2=_interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));var _toConsumableArray2=_interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));







var _renative=require("renative");

var _EpgDataClient=require("@24i/nxg-integration-backstage/src/clients/EpgDataClient");




var _initApp=require("@24i/nxg-sdk-smartott/src/Application/initApp");
var _EpgDataClient2=require("@24i/nxg-sdk-smartott-stubs/src/clients/EpgDataClient");function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}




var DEFAULT_CHANNEL_MULTIPLIER=1;

var stubCatch=function stubCatch(e){

console.error('Error in stub epg request',e);
return[];
};

var backstageCatch=function backstageCatch(e){

console.error('Error in backstage epg request',e);
return[];
};

var getChannelId=function getChannelId(id){var _getRuntimeConfig;
if(!((_getRuntimeConfig=(0,_initApp.getRuntimeConfig)('features'))!=null&&_getRuntimeConfig.loadEpgFakeData))return id;
return id.split(_EpgDataClient2.STUB_CHANNEL_EPG_SEPARATOR)[0];
};

var addIndexToId=function addIndexToId(originalChannels,result){
var processedChannellIndex={};
return result.
map(function(ch){var _ch$programs;
var channel=originalChannels.find(
function(c){return c.id.includes(ch.id)&&!processedChannellIndex[c.id];}
);
if(!channel)return null;
processedChannellIndex[channel.id]=true;
var id=""+ch.id+_EpgDataClient2.STUB_CHANNEL_EPG_SEPARATOR+
channel.id.split(_EpgDataClient2.STUB_CHANNEL_EPG_SEPARATOR)[1];

return _objectSpread(_objectSpread({},
ch),{},{
programs:(ch==null?void 0:(_ch$programs=ch.programs)==null?void 0:_ch$programs.map(function(p){return _objectSpread(_objectSpread({},p),{},{channelId:id});}))||[],
id:id});

}).
filter(Boolean);
};

var multiplyChannels=function multiplyChannels(
channels)

{var times=arguments.length>1&&arguments[1]!==undefined?arguments[1]:DEFAULT_CHANNEL_MULTIPLIER;
var result=Array.from({length:times}).reduce(
function(acc,_,index){var _channels$map;return[].concat((0,_toConsumableArray2.default)(
acc),(0,_toConsumableArray2.default)((_channels$map=
channels==null?void 0:channels.map(function(ch){return _objectSpread(_objectSpread({},
ch),{},{
number:Number(ch.number)*(index+1),
id:""+ch.id+_EpgDataClient2.STUB_CHANNEL_EPG_SEPARATOR+index});}
))!=null?_channels$map:[]));},

[]
);
return result;
};var



ExperienceEpgDataClient=(0,_createClass2.default)(




function ExperienceEpgDataClient(params){var _this=this;(0,_classCallCheck2.default)(this,ExperienceEpgDataClient);this.











getEpgData=function _callee(
start,
end,
channelIds){var epgData,features,shouldLoadFakeEpgData;return _regenerator.default.async(function _callee$(_context){while(1)switch(_context.prev=_context.next){case 0:

epgData=[];
features=(0,_initApp.getRuntimeConfig)('features');
shouldLoadFakeEpgData=!!(features!=null&&features.loadEpgFakeData);if(!

shouldLoadFakeEpgData){_context.next=9;break;}_context.next=6;return _regenerator.default.awrap(
_this.stubEpgClient.getEpgData(start,end,channelIds));case 6:epgData=_context.sent;_context.next=12;break;case 9:_context.next=11;return _regenerator.default.awrap(

_this.backstageEpgClient.getEpgData(start,end,channelIds));case 11:epgData=_context.sent;case 12:return _context.abrupt("return",


epgData);case 13:case"end":return _context.stop();}},null,null,null,Promise);};this.


getEpgDataInRange=function _callee2(range,channels){var _getRuntimeConfig2;var newChannels,_await$Promise$all,_await$Promise$all2,backstage,stub,result;return _regenerator.default.async(function _callee2$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:
newChannels=channels.map(function(channel){return _objectSpread(_objectSpread({},
channel),{},{
id:getChannelId(channel.id)});}
);if((_getRuntimeConfig2=
(0,_initApp.getRuntimeConfig)('features'))!=null&&_getRuntimeConfig2.loadEpgFakeData){_context2.next=3;break;}return _context2.abrupt("return",
_this.backstageEpgClient.getEpgDataInRange(range,newChannels));case 3:_context2.next=5;return _regenerator.default.awrap(
Promise.all([
_this.backstageEpgClient.getEpgDataInRange(range,newChannels).catch(backstageCatch),
_this.stubEpgClient.getEpgDataInRange(range,newChannels).catch(stubCatch)]
));case 5:_await$Promise$all=_context2.sent;_await$Promise$all2=(0,_slicedToArray2.default)(_await$Promise$all,2);backstage=_await$Promise$all2[0];stub=_await$Promise$all2[1];
result=[].concat((0,_toConsumableArray2.default)(addIndexToId(channels,stub)),(0,_toConsumableArray2.default)(addIndexToId(channels,backstage)));return _context2.abrupt("return",
result);case 11:case"end":return _context2.stop();}},null,null,null,Promise);};this.


getChannels=function _callee3(){var _getRuntimeConfig3,_this$backstageEpgCli,_this$backstageEpgCli2,_this$stubEpgClient;var _await$Promise$all3,_await$Promise$all4,backstage,stub,channels,configMultiplier,channelMultiplier,_args3=arguments;return _regenerator.default.async(function _callee3$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:if((_getRuntimeConfig3=
(0,_initApp.getRuntimeConfig)('features'))!=null&&_getRuntimeConfig3.loadEpgFakeData){_context3.next=2;break;}return _context3.abrupt("return",
(_this$backstageEpgCli=_this.backstageEpgClient).getChannels.apply(_this$backstageEpgCli,_args3));case 2:_context3.next=4;return _regenerator.default.awrap(
Promise.all([
(_this$backstageEpgCli2=_this.backstageEpgClient).getChannels.apply(_this$backstageEpgCli2,_args3).catch(backstageCatch),
(_this$stubEpgClient=_this.stubEpgClient).getChannels.apply(_this$stubEpgClient,_args3).catch(stubCatch)]
));case 4:_await$Promise$all3=_context3.sent;_await$Promise$all4=(0,_slicedToArray2.default)(_await$Promise$all3,2);backstage=_await$Promise$all4[0];stub=_await$Promise$all4[1];
channels=[].concat((0,_toConsumableArray2.default)(stub),(0,_toConsumableArray2.default)(backstage));





configMultiplier=(0,_initApp.getRuntimeConfig)().experienceChannelMultiplier;
channelMultiplier=_renative.isPlatformWeb?configMultiplier:1;return _context3.abrupt("return",
multiplyChannels(channels,channelMultiplier));case 12:case"end":return _context3.stop();}},null,null,null,Promise);};this.


getBroadcastByRecording=function _callee4(recording){return _regenerator.default.async(function _callee4$(_context4){while(1)switch(_context4.prev=_context4.next){case 0:return _context4.abrupt("return",
_this.stubEpgClient.getBroadcastByRecording(recording));case 1:case"end":return _context4.stop();}},null,null,null,Promise);};this.backstageEpgClient=(0,_EpgDataClient.createBackstageEpgDataClient)(params);this.stubEpgClient=new _EpgDataClient2.EpgDataClientStub();});exports.ExperienceEpgDataClient=ExperienceEpgDataClient;


var createExperienceEpgDataClient=function createExperienceEpgDataClient(params){return(
new ExperienceEpgDataClient(params));};exports.createExperienceEpgDataClient=createExperienceEpgDataClient;