import React from 'react';
import { FlatList, View } from '@24i/nxg-sdk-quarks';
import { getOddData, getEvenData } from './utils';
import type { IndependentDoubleRowProps } from './types';

const IndependentDoubleRow = (props: IndependentDoubleRowProps) => {
    const { data = [] } = props;

    return (
        <View style={{ flexDirection: 'column', flex: 1 }}>
            <FlatList {...props} data={getOddData(data)} horizontal />
            <FlatList {...props} data={getEvenData(data)} horizontal />
        </View>
    );
};
export default IndependentDoubleRow;
