import React, { ReactElement } from 'react';
import { SequentialInputPropsMobile } from './types';
import View from './View';
import { useViewModel } from './viewModel';

function Main(props: SequentialInputPropsMobile): ReactElement {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
}

export default Main;
