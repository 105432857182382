/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { isPlatformWeb } from 'renative';
import { adobePrimetimeJSDevDebugLogger, createResourceId } from '../../utils';
import {
    ACCESS_ENABLER_AUTH_CODE,
    ACCESS_ENABLER_STATUS,
    deafultAuthState,
    SERVICE_PROVIDERS,
} from '../../accessEnablerConstants';
import {
    checkAuthorizationForResource,
    checkForAuthentication,
    getMvpds,
    initialise,
    onAuthenticationSuccess,
    setProvider,
    signOut as initiateSignOut,
} from '../../../api';
import type {
    AdobePrimetimeAuthState,
    AdobePrimetimeContextType,
    AdobePrimetimeFeatureGuard,
    AuthorizationStatus,
    MvpdLists,
} from '../../types';
import { isLocalConfig, redirectUrl, softwareStatement } from '../../../adobePrimetimeLocalConfig';
import { getAuthentication } from '../../../api/index.web';

const { BASE_SP_URL_PRODUCTION } = SERVICE_PROVIDERS;
const { ERROR, SUCCESS } = ACCESS_ENABLER_STATUS;
const {
    GENERIC_AUTHENTICATION_ERROR,
    USER_NOT_AUTHENTICATED,
    AUTHENTICATION_UNINITIALIZED,
    USER_AUTHENTICATED,
    LOGOUT_SUCCESS,
} = ACCESS_ENABLER_AUTH_CODE;

const contextDefaults: AdobePrimetimeContextType = {
    authState: deafultAuthState,
    mvpds: { tierOneMvpds: [], allMvpds: [] },
    selectProvider: () => {},
    mvpdSignInPath: null,
    initialiseAdobePrimetimeSDK: () => {},
    displayWebView: false,
    onSuccessfulAuthentication: () => {},
    signOut: () => {},
    setMvpdSignInPath: () => {},
    checkAuthorization: () => new Promise(() => {}),
};

export const AdobePrimetimeContext = createContext<AdobePrimetimeContextType>(contextDefaults);

export const AdobePrimetimeProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [authState, setAuthState] = useState<AdobePrimetimeAuthState>(deafultAuthState);
    const [mvpds, setMvpds] = useState<MvpdLists>({ tierOneMvpds: [], allMvpds: [] });
    const [mvpdSignInPath, setMvpdSignInPath] = useState<string | null>(null);
    const initRef = useRef(false);
    const didAttemptInitialisation = initRef.current;
    const backstageConfig = useRef<AdobePrimetimeFeatureGuard | undefined>(undefined);
    const serviceProvider = BASE_SP_URL_PRODUCTION;

    const initialiseAdobePrimetimeSDK = (
        adobePrimetimeBackstageConfig: AdobePrimetimeFeatureGuard | undefined
    ): void => {
        initRef.current = true;
        backstageConfig.current = adobePrimetimeBackstageConfig;
        const requestorId = backstageConfig?.current?.requestorId;
        const backstageTierOneMvpds = backstageConfig?.current?.tierOneMvpds;
        setAuthState(
            (prevState): AdobePrimetimeAuthState => ({
                ...prevState,
                initialising: true,
            })
        );
        if (isLocalConfig && requestorId?.length && backstageTierOneMvpds?.length) {
            initialise(
                softwareStatement,
                requestorId,
                serviceProvider,
                setAuthState,
                redirectUrl,
                setMvpds,
                backstageTierOneMvpds
            );
        } else if (__DEV__) adobePrimetimeJSDevDebugLogger(0);
    };

    useEffect(() => {
        const { initialised, authenticated, authCode, initialising, authenticating } = authState;
        if (didAttemptInitialisation && !initialising && initialised === ERROR) {
            if (__DEV__) adobePrimetimeJSDevDebugLogger(1);
        }

        const backstageTierOneMvpds = backstageConfig?.current?.tierOneMvpds;

        if (initialised === SUCCESS && authenticated === ERROR) {
            initRef.current = false;
            const handleAuthCode = (code: ACCESS_ENABLER_AUTH_CODE): void => {
                const authActions = {
                    [AUTHENTICATION_UNINITIALIZED]: (): void => {
                        if (!authenticating) checkForAuthentication(setAuthState);
                    },
                    [USER_NOT_AUTHENTICATED]: (): void => {
                        // web specific method doesnt require arguments
                        if (isPlatformWeb) {
                            getAuthentication(false, () => {});
                            return;
                        }
                        if (backstageConfig.current && !authenticating) {
                            if (backstageTierOneMvpds?.length) {
                                getMvpds(backstageTierOneMvpds, setMvpds);
                            } else if (__DEV__) adobePrimetimeJSDevDebugLogger(3);
                        }
                    },
                    [USER_AUTHENTICATED]: (): void => {
                        if (__DEV__) adobePrimetimeJSDevDebugLogger(4);
                    },
                    [LOGOUT_SUCCESS]: (): void => {
                        if (__DEV__) adobePrimetimeJSDevDebugLogger(5);
                    },
                    [GENERIC_AUTHENTICATION_ERROR]: (): void => {
                        // We must fetch mvpds if we are signing out and didn't fetch them on app start (authenticated).
                        // Should hit this state on iOS only since it seems to return a different authCode
                        // after sign out ("Generic Authentication Error") to Android ("User Not Authenticated").
                        if (!mvpds.tierOneMvpds.length && backstageTierOneMvpds?.length) {
                            getMvpds(backstageTierOneMvpds, setMvpds);
                        }
                        if (__DEV__) adobePrimetimeJSDevDebugLogger(6);
                    },
                };
                authActions[code]();
            };

            if (Object.values<string>(ACCESS_ENABLER_AUTH_CODE).includes(authCode)) {
                handleAuthCode(authCode);
            } else {
                handleAuthCode(ACCESS_ENABLER_AUTH_CODE.GENERIC_AUTHENTICATION_ERROR);
            }
        }
    }, [authState]);

    const selectProvider = (id: string): void => {
        setProvider(id, setMvpdSignInPath, redirectUrl, setAuthState);
    };

    // iOS only
    const onSuccessfulAuthentication = (): void => {
        setAuthState((prevState) => ({ ...prevState, authenticating: true }));
        onAuthenticationSuccess(redirectUrl, setAuthState);
        setMvpdSignInPath(null);
    };

    const signOut = (): void => {
        initiateSignOut(setMvpdSignInPath, redirectUrl, setAuthState);
    };

    // we can't import anything from other packages in the mono repo because
    // this package won't build
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function checkAuthorization(asset: any): AuthorizationStatus {
        const { title, rating, id } = asset;
        const resourceId = createResourceId(
            {
                title,
                rating,
                id,
            },
            backstageConfig?.current?.requestorId
        );
        const check = await checkAuthorizationForResource(resourceId);

        return check;
    }

    return (
        <AdobePrimetimeContext.Provider
            value={{
                authState,
                mvpds,
                selectProvider,
                mvpdSignInPath,
                initialiseAdobePrimetimeSDK,
                displayWebView: !!mvpdSignInPath,
                onSuccessfulAuthentication,
                signOut,
                setMvpdSignInPath,
                checkAuthorization,
            }}
        >
            {children}
        </AdobePrimetimeContext.Provider>
    );
};

export const useAdobePrimetime = () => useContext(AdobePrimetimeContext);
