import React, { forwardRef } from 'react';
import { View as RNView } from 'react-native-web';
import StyleSheet from 'react-native-media-query';
import { ViewProps, ViewRefType } from './types';

const View = forwardRef<ViewRefType, ViewProps>(({ style, ...props }, ref) => {
    const { ids, styles } = StyleSheet.process(style);

    return <RNView ref={ref} style={styles} dataSet={{ media: ids }} {...props} />;
});

View.displayName = 'View_Quarks';

export default View;
