import { useEffect } from 'react';
import { Dimensions } from '@24i/nxg-sdk-quantum';

function useDidMount(callback) {
    useEffect(() => {
        callback();
    }, []);
}

function useSetMenuButtonDimensionsOnMount(pickerRef, setButtonDimensions, layout, isDropdownOpen) {
    useEffect(() => {
        const { height, width } = Dimensions.get('window');
        pickerRef.current.measure((x, y, buttonWidth, buttonHeight, pageX, pageY) => {
            setButtonDimensions({
                x,
                y,
                width: buttonWidth,
                height: buttonHeight,
                pageX,
                pageY,
                pageWidth: width,
                pageHeight: height,
            });
        });
    }, [layout, isDropdownOpen]);
}

function useNotifyOnOpen(isMounted, isDropdownOpen, onOpen, setMenuDimensions, onClose) {
    useEffect(() => {
        if (!isMounted) {
            return;
        }
        if (isDropdownOpen) {
            onOpen();
        } else {
            // reset menu position
            setMenuDimensions(null);
            onClose();
        }
    }, [isDropdownOpen]);
}

export {
    useDidMount as didMount,
    useSetMenuButtonDimensionsOnMount as setMenuButtonDimensionsOnMount,
    useNotifyOnOpen as notifyOnOpen,
};
