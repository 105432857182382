import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { ProfileImage } from '@24i/nxg-sdk-photon/src/models/profileImage';
import { Interactable, View } from '@24i/nxg-sdk-quarks';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { isPlatformWeb } from 'renative';
import React, { useRef, useState } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Swiper, SwiperSlide } from 'swiper/react';
// Eslint has problems with this import even though it is valid!
// eslint-disable-next-line import/no-unresolved
import SwiperClass from 'swiper/types/swiper-class';
import ProfileItem from '../../../../components/ProfileItem';
import { getStyles } from './styles/index.web';
import { Arrow } from './components/Arrow/index.web';
import {
    shouldDisplayDesktopVersion,
    DESKTOP_VERSION_BREAKPOINT,
} from '../../../../utils/styles/helpers';

interface ProfileImageProps {
    profileImages?: ProfileImage[];
    profileImageId?: string;
    onProfileImageChange: (id: string) => void;
}

const AvatarPicker = ({
    profileImages,
    profileImageId,
    onProfileImageChange,
}: ProfileImageProps) => {
    const dimensions = useDimensions();
    const { theme } = useTheme();

    const [isAtTheBeginning, setIsAtTheBeginning] = useState(true);
    const [isAtTheEnd, setIsAtTheEnd] = useState(!isAtTheBeginning);
    const [hoveredArrow, setHoveredArrow] = useState<'left' | 'right' | null>(null);

    const swiperRef = useRef<SwiperClass | undefined>();

    const shouldDisplayExtended = isPlatformWeb && shouldDisplayDesktopVersion(dimensions);

    const styles = getStyles({ theme, dimensions, shouldDisplayExtended });

    const renderItem = (profile: ProfileImage, index: number) => {
        const profileAvatar = profile?.images?.avatar?.find((image) => image?.size?.width === 100);

        const isSelected = profileImageId === profile.id;
        return (
            <SwiperSlide key={profile.id} style={styles.swiperSlide}>
                <ProfileItem
                    profile={profile}
                    avatar={profileAvatar?.url}
                    adjustedDimensions={shouldDisplayDesktopVersion(dimensions) ? 106 : 96}
                    onPress={() => onProfileImageChange(profile.id)}
                    isFocused={isSelected}
                    noBorders={!isSelected}
                    testID={`profile_image_${index}`}
                    isActive
                />
            </SwiperSlide>
        );
    };

    return (
        <View style={styles.wrapper}>
            {!isAtTheBeginning && <View style={styles.opacityGradientLeft} />}
            {!isAtTheEnd && <View style={styles.opacityGradientRight} />}
            <Swiper
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                onSlideChange={(swiper) => {
                    setIsAtTheBeginning(Boolean(swiper.isBeginning));
                    setIsAtTheEnd(Boolean(swiper.isEnd));
                }}
                onReachEnd={() => {
                    if (!swiperRef.current) return;

                    setIsAtTheEnd(true);
                    setIsAtTheBeginning(false);
                }}
                onReachBeginning={() => {
                    if (!swiperRef.current) return;

                    setIsAtTheBeginning(true);
                    setIsAtTheEnd(false);
                }}
                width={shouldDisplayExtended ? undefined : dimensions.width}
                slidesPerView={shouldDisplayExtended ? 5 : 'auto'}
                style={styles.swiper}
                slidesOffsetAfter={shouldDisplayExtended ? 120 : 52}
                breakpoints={{
                    [DESKTOP_VERSION_BREAKPOINT]: {
                        spaceBetween: 20,
                    },
                }}
                mousewheel={{
                    forceToAxis: true,
                }}
            >
                {profileImages?.map(renderItem)}
            </Swiper>
            {!isAtTheBeginning && (
                <Interactable
                    onPress={() => swiperRef.current?.slidePrev()}
                    style={[styles.navigationLeftContainer, styles.arrowWrapper]}
                    onMouseEnter={() => setHoveredArrow('left')}
                    onMouseLeave={() => setHoveredArrow(null)}
                >
                    <Arrow
                        orientation="left"
                        isEnabled
                        isHovered={hoveredArrow === 'left'}
                        variant="profiles"
                    />
                </Interactable>
            )}
            {!isAtTheEnd && (
                <Interactable
                    onPress={() => swiperRef.current?.slideNext()}
                    style={[styles.navigationRightContainer, styles.arrowWrapper]}
                    onMouseEnter={() => setHoveredArrow('right')}
                    onMouseLeave={() => setHoveredArrow(null)}
                >
                    <Arrow
                        orientation="right"
                        isEnabled
                        isHovered={hoveredArrow === 'right'}
                        variant="profiles"
                    />
                </Interactable>
            )}
        </View>
    );
};

export default overridable(AvatarPicker, 'AvatarPicker');
