import { useTranslation } from 'react-i18next';
import React from 'react';
import { Platform } from 'react-native';
import { BlockedItem } from '@24i/nxg-sdk-photon';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { SOTT_DEFAULT_WEB_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import BlockedModal from '../..';
import { BlockModalTypes } from '../../types';
import { useAppNavigation } from '../../../../context/AppNavigation';

const useShared = () => {
    const { t } = useTranslation();
    const { setModalChildren, setModalProps, closeModal } = useModal();
    const router = useRouter();
    const { previousRoute } = useAppNavigation();

    const isWeb = Platform.OS === 'web';
    const getError = (type) => {
        switch (type) {
            case BlockModalTypes.GEOBLOCK:
                return 'E05';
            case BlockModalTypes.CONCURRENCY:
                return 'E08';
            case BlockModalTypes.MVPD_PROTECTION_LEVEL_FULL:
                return 'E09';
            case BlockModalTypes.ACCOUNT:
                return 'H05';
            default:
                return 'E02';
        }
    };

    const shouldCallAction = (type) => {
        const excludedTypes = [
            BlockModalTypes.GEOBLOCK,
            BlockModalTypes.CONCURRENCY,
            BlockModalTypes.MVPD_PROTECTION_LEVEL_FULL,
        ];

        // Exclude "Subscribe" btn which doesn't work from modal on mobile devices
        // See more in comments here: https://aferian.atlassian.net/browse/BWYHD01-21
        if (!isWeb) {
            excludedTypes.push(BlockModalTypes.ACCOUNT);
        }

        return !excludedTypes.includes(type);
    };

    const navigateBack = () => {
        if (previousRoute) {
            router.back();
        } else {
            const homePageLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();
            router.push(homePageLink.href, homePageLink.as); // For case when user comes from different page via link
        }
    };

    const onCloseModal = (type: BlockModalTypes) => {
        if (isWeb && type === BlockModalTypes.ACCOUNT) {
            // Not sure if the issue affects also other Modals, for now this is fix for BWYHD01-85
            navigateBack();
        } else {
            closeModal();
        }
    };
    const openBlockedModal = (
        type: BlockModalTypes,
        blocker?: BlockedItem | string,
        modalProps?: any,
        onAction?: (type: BlockModalTypes) => void,
        onClose: () => void = closeModal
    ) => {
        const defaultOnClose = () => onCloseModal(type);

        const title =
            type === BlockModalTypes.ACCOUNT
                ? (blocker as any)?.reason || blocker
                : t(`error.${getError(type)}.title`);
        setModalProps(modalProps);
        setModalChildren(
            <BlockedModal
                title={title}
                subtitle={blocker || (t(`error.${getError(type)}.body`) as string)}
                onClose={onClose || defaultOnClose}
                {...(shouldCallAction(type) ? { onAction: () => onAction?.(type) } : {})}
            />
        );
    };

    return { openBlockedModal };
};

export default useShared;
