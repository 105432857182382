import { Dispatch, SetStateAction } from 'react';
import { TextStyle, ViewStyle } from 'react-native';

export type ConfirmationHandler = (textInputValue: string, key: string) => void;

export interface ChangeableTextItemProps {
    title: string;
    value: string;
    confirmationHandler: ConfirmationHandler;
    itemKey: string;
    buttonText?: string;
    customInteractable?: JSX.Element;
    secureText?: boolean;
    type?: ChangeableItemType;
}

export enum ChangeableItemType {
    INPUT = 'input',
    PIN = 'pin',
}

export interface ChangeableTextProps {
    item: ChangeableTextItemProps;
    onChangePress?: (item: ChangeableTextItemProps) => void;
}
export interface ChangeModalItemProps {
    item?: ChangeableTextItemProps;
    isFocused: boolean;
    setIsFocused: Dispatch<SetStateAction<boolean>>;
    textInputValue: string;
    setTextInputValue: Dispatch<SetStateAction<string>>;
    pinLength?: string;
    placeholder?: string;
    maxNameLength?: number;
}

export interface ChangeableTextModalProps extends Partial<ChangeableTextProps> {
    showModal: boolean;
    closeModal: (clearMessage?: boolean) => void;
    errorMessage?: string;
    shouldCheckPinLength?: boolean;
    maxNameLength?: number;
}

export interface ChangeableTextPropsStyles {
    container: ViewStyle;
    titleText: TextStyle;
    contentText: TextStyle;
    buttonText: TextStyle;
    itemDivider: ViewStyle;
    description: ViewStyle;
}

export interface ModalStyles {
    wrapper?: ViewStyle;
    textInputContainer: ViewStyle;
    textInputContainerErrorState: ViewStyle;
    textInput: TextStyle;
    textInputErrorText: TextStyle;
    buttonsContainer: ViewStyle;
    acceptButton?: ViewStyle;
    description?: ViewStyle;
    inactiveButton?: ViewStyle;
}

export enum CHANGEABLE_TEXT_TEST_IDS {
    TEXT = 'text_value',
    BUTTON_CHANGE = 'button_change',
    TEXT_INPUT = 'change_modal_text_input',
    ERROR_MESSAGE = 'change_modal_error_message',
    BUTTON_CANCEL = 'change_modal_button_cancel',
    BUTTON_CONFIRM = 'change_modal_button_confirm',
}
