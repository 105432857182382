import React from 'react';
import { StyleSheet } from 'react-native';
import { Interactable, Text } from '@24i/nxg-sdk-quarks';
import { PlusMinusProps } from './types';

const styles = StyleSheet.create({
    text: {
        fontWeight: '900',
    },
});

const PlusMinus = ({
    focused,
    activeTintColor,
    tintColor,
    style,
    onFocus,
    onBlur,
    onPress,
    label,
}: PlusMinusProps): JSX.Element => (
    <Interactable
        style={[
            style,
            {
                backgroundColor: focused ? activeTintColor : tintColor,
            },
        ]}
        onFocus={onFocus}
        onBlur={onBlur}
        onPress={onPress}
    >
        <Text style={styles.text}>{label}</Text>
    </Interactable>
);
export default PlusMinus;
