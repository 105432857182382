import { useCallback } from 'react';

import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';

import { BuildPlaybackEventFunction } from './buildPlaybackEvent';
import { PlaybackState } from './playbackState';

export interface AdLoadedPlayerEventParams {
    adName: string;
    adPosition: number;
    adServerUrl?: string;
}

export const useTriggerOnAdLoaded = (buildPlaybackEvent: BuildPlaybackEventFunction) => {
    const triggerOnAdLoaded = useCallback(
        (playbackState: PlaybackState | undefined, params: AdLoadedPlayerEventParams) => {
            const event = buildPlaybackEvent('AD_LOADED', playbackState);
            if (!event) return;

            onAnalytics(ANALYTICS_TRIGGERS.AD_LOADED, {
                ...event,
                adName: params.adName,
                adPosition: params.adPosition,
            });
        },
        []
    );

    return triggerOnAdLoaded;
};
