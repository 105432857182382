import React from 'react';
import { ForgotPasswordProps } from './types';
import View from './View';
import { useViewModel } from './viewModel';

const Main = (props: ForgotPasswordProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default {
    Main,
    useViewModel,
};
