import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS, RecordingsFolder } from '@24i/nxg-sdk-photon/src';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { useTranslation } from 'react-i18next';
import { useRecordings } from '@24i/nxg-sdk-smartott-shared/src/context/Recordings';
import { getToastMessage } from './utils';
import { useMyRecordingsQuery } from './useMyRecordingsQuery';

export function useDeleteRecordingsInFolder(): UseMutateFunction<
    void,
    unknown,
    RecordingsFolder,
    unknown
> {
    const { deleteRecordingsInFolder } = useRecordings();
    const { updateRecordings } = useMyRecordingsQuery();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(deleteRecordingsInFolder, {
        onMutate: async (newData) => {
            // cancel any outgoing queries for recordings data, so old server data
            // doesn't overwrite our optimistic update
            await queryClient.cancelQueries(QUERY_KEYS.recordings);

            const previousRecordingData = queryClient.getQueryData<RecordingsFolder[]>(
                QUERY_KEYS.recordings
            );

            // optimistically update the cache with updated recordings
            updateRecordings(previousRecordingData?.filter(({ id }) => id !== newData.id));

            // return context object with snapshotted value
            return { previousRecordingData };
        },
        onError: (error, newData, context: any) => {
            const errorMessage = error instanceof Error ? error.message : t('error.A00.body');
            // roll back cache to saved value
            if (context.previousRecordingData) {
                updateRecordings(context.previousRecordingData);
            }

            showToast(errorMessage, {
                type: 'error',
            });
        },
        onSuccess: (error, newData: any) => {
            queryClient.invalidateQueries(QUERY_KEYS.storageInfo);

            const duration = newData?.folderAssets?.reduce(
                (total, currentItem) => total + currentItem.end - currentItem.start,
                0
            );

            showToast(getToastMessage(duration, 1, t, newData.status));
        },
        onSettled: () => {
            // invalidate recordings query to make sure were in sync with server data
            queryClient.invalidateQueries(QUERY_KEYS.recordings);
        },
    });

    return mutate;
}
