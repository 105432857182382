import { useDimensions } from '@24i/nxg-sdk-quantum';
import { CreateCarouselItemStyles } from './types';

const GetCreateCarouselItemStyles = (): CreateCarouselItemStyles => {
    const { width, height } = useDimensions();
    return {
        itemWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        welcomeImage: {
            opacity: 0.6,
            height,
            width,
        },
        placeholderImage: {
            height: '30%',
            minWidth: '100%',
            opacity: 0.3,
        },
    };
};

export default GetCreateCarouselItemStyles;
