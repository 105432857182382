import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, VideoStopResult } from '../types';
import { mapVideoPause } from './mapVideoPause';

export const mapVideoStop = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): VideoStopResult => ({
    ...mapVideoPause(triggerName, payload),
});
