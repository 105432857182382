import { Theme } from '@24i/nxg-sdk-photon';

const styles = (_: Theme) => ({
    volumeContainer: {
        marginTop: 25,
        flexDirection: 'row',
        alignItems: 'center',
    },
    sliderStyles: {
        width: 255,
        marginLeft: 16,
    },
});

export default styles;
