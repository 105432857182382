import { useRouter, WebRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useAppStart } from '@24i/nxg-sdk-smartott/src/context/AppStartContext';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useQueryClient } from 'react-query';
import {
    onAnalytics,
    setUserInfo,
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';

export * from './shared';

export const useStartPageNavigation = () => {
    const router = useRouter() as WebRouter;
    const queryClient = useQueryClient();
    const { runStartSequence, onLogout } = useAppStart();
    const userDataClient = useUserData();

    const navigateToStartPage = () => runStartSequence(undefined, router);

    const login = () => {
        queryClient.clear();
        navigateToStartPage();
    };

    const logout = async () => {
        await userDataClient.logout();
        await queryClient.invalidateQueries();
        setUserInfo(null);
        onAnalytics(ANALYTICS_TRIGGERS.LOGOUT, { event: ANALYTICS_EVENTS.LOGGED_OUT });
        navigateToStartPage();
        onLogout();
    };

    return { navigateToStartPage, login, logout };
};
