import { Profile } from '@24i/nxg-sdk-photon';
import { useMutation, useQueryClient } from 'react-query';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { setUserInfo } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useStore } from '../../context/ApplicationStore';

export const useSwitchProfile = () => {
    const { setSelectedUserProfile } = useStore();
    const userDataClient = useUserData();
    const queryClient = useQueryClient();

    const { mutate, mutateAsync } = useMutation(
        async (profile: Profile) => {
            return userDataClient.selectUserProfile({ profileId: profile.id });
        },
        {
            onSuccess: async (_response, profile) => {
                setUserInfo({ userProfileId: profile.id });
                await queryClient.invalidateQueries();

                setSelectedUserProfile(profile);
            },
        }
    );

    return { switchProfile: mutate, switchProfileAsync: mutateAsync };
};
