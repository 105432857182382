// Replace /:id with /[id] as per nextjs' url convention
const replaceUrl = (url) => {
    let nextUrl = url;
    /*
     * Match all /:param instances in the url string and capture the param name
     * in a capturing group
     */
    const regex = /\/:(\w+)/g;
    let match = regex.exec(nextUrl);

    if (!match) {
        return url;
    }

    // eslint-disable-next-line no-cond-assign
    do {
        nextUrl = nextUrl.replace(match[0], `/[${match[1]}]`);
        // eslint-disable-next-line no-cond-assign
    } while ((match = regex.exec(nextUrl)) !== null);

    return nextUrl;
};
export default replaceUrl;
