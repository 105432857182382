import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { AdEvent } from 'packages/sdk@photon/src/analytics/player';
import { Payload, AdLoadedResult } from '../types';
import { mapBase } from './mapBase';

export const mapAdLoaded = (triggerName: ANALYTICS_TRIGGERS, payload: Payload): AdLoadedResult => {
    const adPayload = payload?.payload as unknown as AdEvent;
    return {
        ...mapBase(payload),
        action: triggerName,
        event_trigger: triggerName,
        thing_id: payload?.payload?.asset?.id || '',
        player_advertisment_name: adPayload?.adName,
        player_advertisment_position: adPayload?.adPosition,
    };
};
