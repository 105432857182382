import { useEffect } from 'react';
import { Storage } from '@24i/nxg-sdk-quantum';
import { isPlatformWeb } from 'renative';
import {
    ASYNC_STORAGE_KEY_USER_LANGUAGE,
    ASYNC_STORAGE_KEY_I18_NEXT_LANG,
} from '@24i/nxg-core-utils/src/constants';

// Needed to sync the value of i18nextLng with the value of user-language in the Storage. i18nextLng is used by Cleeng to select the translations to apply
export const useStorageLanguageSync = () => {
    useEffect(() => {
        if (isPlatformWeb) {
            Storage.getItem(ASYNC_STORAGE_KEY_USER_LANGUAGE).then((value) =>
                Storage.setItem(ASYNC_STORAGE_KEY_I18_NEXT_LANG, value ?? 'en')
            );
        }
    }, []);
};
