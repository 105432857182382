import React from 'react';
import {
    NavConfigWeb,
    NavScreenConfigWeb,
    WebLinkRenderProps,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { WEB_SCREEN_SIZES } from '@24i/nxg-sdk-gluons/src/utils/constants';

import { TFunction } from 'i18next';
import { SottMenuConfig } from '../types';
import { SottNavBuilderScreensWeb } from './types';
import { TopBarMenuDropdown } from '../../../components/TopBarMenu';
import { useMenuItemPress } from '../../../utils';
import { NAVIGATION_TEST_IDS } from '../../../types';

const maxItemsBySize = {
    [WEB_SCREEN_SIZES.S]: 3,
    [WEB_SCREEN_SIZES.M]: 4,
    [WEB_SCREEN_SIZES.L]: 6,
    [WEB_SCREEN_SIZES.XL]: 6,
};

type Context = {
    t: TFunction;
    menuConfig: SottMenuConfig;
    moreScreen: NavScreenConfigWeb | null;
};

const splitBottomBarItems = (
    items: NavScreenConfigWeb[],
    { menuConfig, moreScreen }: Context
): { screensThatFit: NavScreenConfigWeb[]; overflowingScreens: NavScreenConfigWeb[] } => {
    const {
        mobile: { maxVisibleNavbarItems, isMoreScreenEnabled },
    } = menuConfig;

    const willNotOverflow = maxVisibleNavbarItems <= 1 || items.length <= maxVisibleNavbarItems;

    if (!moreScreen || willNotOverflow) {
        return { screensThatFit: items, overflowingScreens: [] };
    }

    // If more screen is enabled then max visible nav bar items is being reduced by 1 because there will be additional menu item called `More`
    const maxVisibleItems =
        isMoreScreenEnabled !== false ? maxVisibleNavbarItems - 1 : maxVisibleNavbarItems;

    const itemsShownInTheBottomBar = items.slice(0, maxVisibleItems);
    const itemsShownInMoreScreen = items.slice(maxVisibleItems, items.length);

    const screensThatFit = [
        ...itemsShownInTheBottomBar,
        ...(isMoreScreenEnabled ? [moreScreen] : []),
    ];

    return { screensThatFit, overflowingScreens: itemsShownInMoreScreen };
};

// TODO: Check the implementation and possibly refactor
const splitTopBarItems = (
    items: NavScreenConfigWeb[],
    { t, menuConfig }: Context,
    size: typeof WEB_SCREEN_SIZES[keyof typeof WEB_SCREEN_SIZES]
): NavScreenConfigWeb[] => {
    const {
        desktop: { maxVisibleNavbarItems },
    } = menuConfig;
    let splitItems: NavScreenConfigWeb[] = items;
    const maxVisibleItems =
        maxVisibleNavbarItems < maxItemsBySize[size] ? maxVisibleNavbarItems : maxItemsBySize[size];

    if (maxVisibleItems <= 1 || items.length <= maxVisibleItems) {
        return splitItems;
    }

    const itemsShownInTopBarLeft = items.slice(0, maxVisibleItems - 1);
    const itemsShownInMoreDropdown = items.slice(maxVisibleItems - 1, items.length);

    const MoreLinkComponent = ({ menuItem }: WebLinkRenderProps) => {
        const onPress = useMenuItemPress();
        return (
            <TopBarMenuDropdown
                {...menuItem}
                title={menuItem.menuTitle}
                icon={menuItem.icon || ''}
                items={itemsShownInMoreDropdown.map((item) => ({
                    ...item,
                    title: item.menuTitle,
                }))}
                onListItemPress={(item) => {
                    const correspondingNavConf = itemsShownInMoreDropdown.find(
                        (navConfig) => navConfig.name === item.name
                    );

                    if (!correspondingNavConf) {
                        throw new Error(
                            `The corresponding nav config for the dropdown item was not found. Could not execute on click function!`
                        );
                    }

                    onPress(correspondingNavConf);
                }}
                testID={NAVIGATION_TEST_IDS.MORE_BUTTON}
            />
        );
    };

    splitItems = [
        ...itemsShownInTopBarLeft,
        {
            name: 'more',
            menuTitle: t('common.more'),
            icon: 'angle-down',
            // eslint-disable-next-line react/display-name
            renderWebLink: (props) => (
                <MoreLinkComponent key={props.menuItem.menuTitle} {...props} />
            ),
            matchesWebRoute: () => false,
        },
    ];

    return splitItems;
};

export const sottDefaultConfigBuilderWeb = (
    navigationMenu: SottNavBuilderScreensWeb,
    appContext: Pick<Context, 't' | 'menuConfig'>
): NavConfigWeb => {
    const { menuLeft, menuRight } = navigationMenu.topBarMenu;
    const { bottomBarMenu, allNavigationItems, footer } = navigationMenu;

    const context = {
        ...appContext,
        menuConfig: appContext.menuConfig,
        moreScreen: navigationMenu.moreScreen,
    };

    const bottomMenuItems = splitBottomBarItems(bottomBarMenu, context);

    return {
        allNavigationItems,
        [WEB_SCREEN_SIZES.XS]: {
            bottomMenu: bottomMenuItems.screensThatFit,
            moreScreen: bottomMenuItems.overflowingScreens,
            topMenu: {
                left: [],
                right: [],
            },
            footer: footer || [],
        },
        [WEB_SCREEN_SIZES.S]: {
            topMenu: {
                left: splitTopBarItems(menuLeft, context, WEB_SCREEN_SIZES.S),
                right: menuRight,
            },
            bottomMenu: [],
            moreScreen: [],
            footer: footer || [],
        },
        [WEB_SCREEN_SIZES.M]: {
            topMenu: {
                left: splitTopBarItems(menuLeft, context, WEB_SCREEN_SIZES.M),
                right: menuRight,
            },
            bottomMenu: [],
            moreScreen: [],
            footer: footer || [],
        },
        [WEB_SCREEN_SIZES.L]: {
            topMenu: {
                left: splitTopBarItems(menuLeft, context, WEB_SCREEN_SIZES.L),
                right: menuRight,
            },
            bottomMenu: [],
            moreScreen: [],
            footer: footer || [],
        },
        [WEB_SCREEN_SIZES.XL]: {
            topMenu: {
                left: splitTopBarItems(menuLeft, context, WEB_SCREEN_SIZES.XL),
                right: menuRight,
            },
            bottomMenu: [],
            moreScreen: [],
            footer: footer || [],
        },
    };
};
