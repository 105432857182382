import { useState } from 'react';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import {
    useTermsAndConditions,
    useAcceptTermsAndConditions,
} from '@24i/nxg-sdk-smartott-shared/src/hooks/query/useTermsAndConditions';
import { useStartPageNavigation } from '../../../context/AppStartContext/hooks';
import { TermsAndConditionsModelProps } from '../types';

export const useViewModel = (props: ScreenProps): TermsAndConditionsModelProps => {
    const userDataClient = useUserData();
    const { data: user } = useQuery(QUERY_KEYS.user, userDataClient.fetchUser);
    const { mutate: acceptTermsAndConditions } = useAcceptTermsAndConditions();
    const [hasError, setHasError] = useState<boolean>(false);
    const handleError = () => {
        setHasError(true);
    };
    const { data, isLoading } = useTermsAndConditions({
        onError: () => handleError(),
    });
    const { navigateToStartPage } = useStartPageNavigation();
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);

    const enableAcceptButton = (): void => {
        setButtonDisabled(false);
    };

    const onAcceptTermsAndConditions = async (): Promise<void> => {
        if (user?.id && data)
            acceptTermsAndConditions(
                { userId: user.id, termsAndConditions: data },
                {
                    onSuccess: () => navigateToStartPage(),
                }
            );
    };

    return {
        ...props,
        isLoading,
        content: data?.children[0],
        isButtonDisabled,
        hasError,
        enableAcceptButton,
        onAcceptTermsAndConditions,
    };
};
