import React, { FC } from 'react';
import { View, Interactable, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { Animated } from 'react-native';
import { getStyles } from './styles';

type Props = {
    onClose: () => void;
    height: number;
    show?: boolean;
    variant?: 'centered';
};

export const ExpandedPlayerContainer: FC<Props> = ({
    show,
    onClose,
    children,
    height,
    variant,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme, height ?? 0, DeviceInfo.isTablet());

    return (
        <Animated.View
            style={[
                styles.container,
                variant === 'centered' && styles.centeredContainer,
                {
                    transform: [
                        {
                            translateY: show ? 0 : height,
                        },
                    ],
                },
            ]}
            pointerEvents={show ? 'auto' : 'none'}
        >
            <View style={styles.closeButton}>
                <Interactable onPress={onClose}>
                    <ThemeIcon iconName="onPrimary-collapseDown" size={24} />
                </Interactable>
            </View>

            <View style={styles.inner}>{children}</View>
        </Animated.View>
    );
};
