import React, { ReactElement } from 'react';
import { useViewModel } from './viewModel';
import { SecondScreenActivation } from './View';

export default {
    Main: (props): ReactElement => {
        const viewModelProps = useViewModel(props);
        return <SecondScreenActivation {...viewModelProps} />;
    },
    View: SecondScreenActivation,
    useViewModel,
};
