import { useEffect, useRef, useState } from 'react';
// Used as ponyfill
import { URL } from 'react-native-url-polyfill';

import { useUserData, setUserToken } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { getDeepLink } from '@24i/nxg-core-utils/src/deeplink';

import { Alert, AppState, Linking } from 'react-native';
import InAppBrowser from 'react-native-inappbrowser-reborn';

import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { logError } from '@24i/nxg-core-utils/src/logger';

import { useStore } from '../../../../../context/ApplicationStore';
import { useStartPageNavigation } from '../../../../../context/AppStartContext/hooks';
import { AUTH_ACTION } from '../../../View/types';

enum BROWSER_METHOD {
    INAPP_BROWSER_OPEN,
    INAPP_BROWSER_OPENAUTH,
    RN_LINKING,
    UNKNOWN,
}

const inAppBrowserOptions = {
    // iOS Properties
    ephemeralWebSession: true,
    // Android Properties
    showTitle: false,
    enableUrlBarHiding: true,
    enableDefaultShare: false,
};

const OPERATION_CANCELLED = 'Cancelled operation';

const useViewModel = ({
    action,
    onSuccess,
    onAfterError,
}: {
    action?: AUTH_ACTION | undefined;
    onSuccess?: (() => void) | undefined;
    onAfterError?: ((error: any) => void) | undefined;
}) => {
    const identityProvider = useFeature('identityProvider');
    const [isLoading, setIsLoading] = useState(!!action);
    const [redirectUrl, setRedirectUrl] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [deepLink, setDeeplink] = useState<string>('');
    const userDataClient = useUserData();
    const appState = useRef(AppState.currentState);
    const [browserMethod, setBrowserMethod] = useState<BROWSER_METHOD>(BROWSER_METHOD.UNKNOWN);
    const { userData, setUserData, setProfilesAndPossiblySelectOne } = useStore();
    const { login } = useStartPageNavigation();
    const navigation = useNavigation();

    const fetchUserCodeAndLoginUrl = async () => {
        const { code: userCode, url } = await userDataClient.getUserConnectCode();
        const loginURL = new URL(url);
        return {
            userCode,
            loginURL,
        };
    };

    useEffect(() => {
        const setup = async () => {
            const { userCode, loginURL } = await fetchUserCodeAndLoginUrl();
            // Some flows require deeplink to properly work, so make sure it is configured (f.e. TAS)
            const configDeepLink = getDeepLink(`${action}?code=${userCode}`);
            const oauthURL =
                action === AUTH_ACTION.LOGIN
                    ? loginURL
                    : new URL(identityProvider?.registrationURL as string);

            oauthURL.searchParams.append('code', userCode);
            if (configDeepLink) {
                // TAS mode
                oauthURL.searchParams.append('redirectUrl', configDeepLink);
                // More standard
                oauthURL.searchParams.append('redirect_uri', configDeepLink);
            }

            setRedirectUrl(oauthURL.href);
            setCode(userCode);
            setDeeplink(configDeepLink);
            setIsLoading(false);
        };

        if (action) {
            setIsLoading(true);
            setup();
        } else {
            setIsLoading(false);
            setCode('');
            setBrowserMethod(BROWSER_METHOD.UNKNOWN);
        }
    }, [action]);

    const handleError = (error) => {
        logError(error);
        setIsLoading(false);
        InAppBrowser.close();
        InAppBrowser.closeAuth();
        const afterError = onAfterError || navigation.goBack;
        if (error?.message === OPERATION_CANCELLED) {
            afterError(error);
        } else {
            // TODO: localize
            Alert.alert('Error', 'Operation cancelled or error login in with provider', [
                {
                    text: 'OK',
                    onPress: () => {
                        afterError(error);
                    },
                },
            ]);
        }
    };

    const detectBrowserMethod = async () => {
        if (await InAppBrowser.isAvailable()) {
            if (deepLink) {
                setBrowserMethod(BROWSER_METHOD.INAPP_BROWSER_OPENAUTH);
            } else {
                setBrowserMethod(BROWSER_METHOD.INAPP_BROWSER_OPEN);
            }
        } else {
            setBrowserMethod(BROWSER_METHOD.RN_LINKING);
        }
    };

    useEffect(() => {
        if (!isLoading && !userData && code && redirectUrl) {
            detectBrowserMethod();
        }
    }, [isLoading, code, redirectUrl, deepLink, userData]);

    const tryToGetUserFromCodeAndToken = async () => {
        setIsLoading(true);
        const { token: userToken } = await userDataClient.getUserConnectCodeVerification(code);
        if (userToken) {
            await setUserToken(userToken);
            const user = await userDataClient.fetchUser();
            if (user) {
                const profiles = await userDataClient.fetchUserProfiles();
                setProfilesAndPossiblySelectOne(profiles);
            }
            setUserData(user);
        }
    };

    useEffect(() => {
        switch (browserMethod) {
            case BROWSER_METHOD.INAPP_BROWSER_OPEN:
            case BROWSER_METHOD.INAPP_BROWSER_OPENAUTH: {
                InAppBrowser.close();
                InAppBrowser.closeAuth();
                // Some flows require deeplink to properly work, so make sure it is configured (f.e. TAS)
                const browserPromise = deepLink
                    ? InAppBrowser.openAuth(redirectUrl, deepLink, inAppBrowserOptions)
                    : InAppBrowser.open(redirectUrl, inAppBrowserOptions);
                browserPromise
                    .then((result) => {
                        InAppBrowser.close();
                        InAppBrowser.closeAuth();
                        switch (result?.type) {
                            case 'success': {
                                const loginPromise = tryToGetUserFromCodeAndToken();
                                loginPromise
                                    .then(() => login())
                                    .then(() => {
                                        setIsLoading(false);
                                        onSuccess?.();
                                    })
                                    .catch(handleError);
                                break;
                            }
                            case 'cancel':
                            case 'dismiss':
                            default:
                                handleError(new Error(OPERATION_CANCELLED));
                        }
                    })
                    .catch(handleError);
                break;
            }
            case BROWSER_METHOD.RN_LINKING: {
                Linking.openURL(redirectUrl);
                const appStateListener = (nextAppState) => {
                    if (
                        appState.current.match(/inactive|background/) &&
                        nextAppState === 'active'
                    ) {
                        // App is active again (coming from browser probably)
                        const loginPromise = tryToGetUserFromCodeAndToken();
                        loginPromise
                            .then(() => login())
                            .then(() => {
                                setIsLoading(false);
                                onSuccess?.();
                            })
                            .catch(handleError);
                    }
                    appState.current = nextAppState;
                };

                AppState.addEventListener('change', appStateListener);

                return () => {
                    AppState.removeEventListener('change', appStateListener);
                };
            }

            case BROWSER_METHOD.UNKNOWN:
            default:
                break;
        }
        return () => {};
    }, [browserMethod]);

    return {
        isLoading,
    };
};

export { useViewModel };
