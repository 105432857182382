import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Interactable, View } from '@24i/nxg-sdk-quarks';
import { useRouter } from '@24i/nxg-core-router';

const RouteLink = ({ to, action, onPress, prefetch, children, ...rest }) => {
    const router = useRouter();

    if (action === 'pop') {
        return (
            <Interactable {...rest} onPress={() => router.pop()}>
                {children}
            </Interactable>
        );
    }

    if (action === 'popToTop') {
        return (
            <Interactable {...rest} onPress={() => router.navigate('/')}>
                {children}
            </Interactable>
        );
    }

    const href = typeof to === 'string' ? to : to.href;

    return (
        <Link href={href} as={to.as} prefetch={prefetch}>
            <View {...rest} style={[rest.style, { cursor: 'pointer' }]}>
                {children}
            </View>
        </Link>
    );
};

RouteLink.defaultProps = {
    prefetch: false,
    children: null,
    onPress: null,
    action: null,
    to: null,
};

RouteLink.propTypes = {
    to: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            as: PropTypes.string,
        }),
    ]),
    prefetch: PropTypes.bool,
    children: PropTypes.node,
    onPress: PropTypes.func,
    action: PropTypes.oneOf(['push', 'replace', 'pop', 'popToTop']),
};

export default RouteLink;
