import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import {
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
} from '../../../../../navigation/constants';
import { DeleteAccountModalProps } from '../types';
import { useDeleteUser } from '../../../../../hooks/query/user';

const useViewModel = ({
    onCloseModal,
    openErrorModal,
    ...props
}: DeleteAccountModalProps): DeleteAccountModalProps => {
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const router = useRouter();
    const { t } = useTranslation();
    const { mutate: deleteUser, isLoading } = useDeleteUser();

    const navigateToSuccesScreen = (): void => {
        if (isPlatformWeb) {
            const deleteAccountSuccessLink =
                SOTT_DEFAULT_WEB_SCREENS.DeleteAccountSuccess.getLink();
            router.replace(deleteAccountSuccessLink.href, deleteAccountSuccessLink.as);
            return;
        }

        navigation.reset({
            index: 0,
            routes: [
                {
                    name: SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT_SUCCESS,
                },
            ],
        });
    };

    const onConfirmDeleteAccount = () => {
        deleteUser(undefined, {
            onSuccess: () => {
                onCloseModal();
                navigateToSuccesScreen();
            },
            onError: () => {
                onCloseModal();
                openErrorModal(t('error.A00.title'), t('error.A00.body'));
            },
        });
    };

    return {
        onConfirmDeleteAccount,
        onCloseModal,
        openErrorModal,
        isLoading,
        ...props,
    };
};

export default useViewModel;
