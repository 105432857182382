import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, VideoPauseResult } from '../types';
import { EVENTS } from '../constants';
import { mapVideoStart } from './mapVideoStart';

export const mapVideoPause = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): VideoPauseResult => ({
    ...mapVideoStart(triggerName, payload),
    event: EVENTS.VIDEO_PROGRES,
    video_percent: Math.round(payload.payload.percentOfPlayback || 0),
    video_current_time: Math.round((payload.payload.currentPlaybackTimeMs || 0) / 1000),
});
