import { useEffect } from 'react';

export const useListScrollListener = (scrollInPercents) => {
    const calculatePercentage = (scrollValue) =>
        Math.round(Math.max(0, Math.min(scrollValue * 100, 100)));

    const onScroll = () => {
        const {
            documentElement: { scrollTop, clientHeight, scrollHeight },
        } = document;
        const scrollValue = (scrollTop + clientHeight) / scrollHeight;
        const percentage = calculatePercentage(scrollValue);
        scrollInPercents(percentage);
    };
    useEffect(() => {
        window.addEventListener('scroll', onScroll, false);
        return () => {
            window.removeEventListener('scroll', onScroll, false);
        };
    }, []);

    return { onScroll };
};
