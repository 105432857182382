/*
@PRODUCT_FEATURE: D 1.0
*/
/* eslint-disable react/display-name */
import React from 'react';

import View, { getSignupScreenStyles } from './View';
import { useViewModel } from './viewModel';
import * as prerenderFunctions from '../../pages/register';

export default {
    Main: (props) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    page: {
        register: prerenderFunctions,
    },
    getDefaultStyles: getSignupScreenStyles,
};
