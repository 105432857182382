import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ChangeModalPinInputStyles } from './types';

const getStyles = (theme: Theme): ChangeModalPinInputStyles => ({
    secureText: {
        ...getFont(theme, 'body2'),
    },
});

export default getStyles;
