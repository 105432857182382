import React, { useEffect } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import { getTermsAndConditionsPageStyles } from '../styles';
import { TermsAndConditionsViewProps, TERMS_AND_CONDITIONS_TEST_IDS } from '../types';
import HtmlComponent from '../../../components/HtmlComponent';
import withErrorModal from '../../../components/GenericModal/hoc/ErrorModal';
import OnboardingHeader from '../../../navigation/components/OnboardingHeader';

const TermsAndConditionsScreen = ({
    content = null,
    isLoading = false,
    styles: getStyles = getTermsAndConditionsPageStyles,
    hasError,
    onAcceptTermsAndConditions,
    openErrorModal,
}: TermsAndConditionsViewProps) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    useEffect((): void => {
        if (hasError) openErrorModal(t('error.B00.title'), t('error.B00.body'), true);
    }, [hasError]);

    const html = content?.content?.text || '<p></p>';
    const title = content?.content?.title || '';

    return (
        <>
            <OnboardingHeader title={title} wrapperStyle={styles.onboardingHeaderWrapper} />
            <LoadingWrapper
                isLoading={isLoading || content === null}
                loaderProps={{
                    size: 'large',
                    color: theme.color.textPrimary,
                    holderStyles: styles.loaderContainer,
                }}
                wrapperStyle={styles.wrapper}
            >
                <View style={styles.contentContainer}>
                    <HtmlComponent html={html} />
                    <View style={styles.buttonContainer}>
                        <ActionButton
                            testID={TERMS_AND_CONDITIONS_TEST_IDS.CONFIRM_BUTTON}
                            title={t('common.acceptAndContinue.title')}
                            onPress={onAcceptTermsAndConditions}
                            additionalContainerStyles={styles.button}
                        />
                    </View>
                </View>
            </LoadingWrapper>
        </>
    );
};

export default overridable(withErrorModal(TermsAndConditionsScreen), 'TermsAndConditionsScreen');
