import { Profile, RuntimeConfigType, User, Service } from '@24i/nxg-sdk-photon';
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    ReactNode,
    useContext,
    useState,
} from 'react';

const contextDefaults: ContextType = {
    backstageUserData: null,
    isAppLoading: true,
    setIsAppLoading: () => undefined,
    runtimeConfig: null,
    selectedProfile: null,
    setSelectedUserProfile: () => undefined,
    profiles: [],
    setProfiles: () => undefined,
    setProfilesAndPossiblySelectOne: () => undefined,
    deviceRemoved: false,
    setDeviceRemoved: () => undefined,
    isNavBarTransparent: false,
    setNavbarTransparent: () => undefined,
    setUserData: () => null,
    userData: null,
    isEditing: false,
    setEditing: () => undefined,
    playerDeepLinkingPath: undefined,
    setPlayerDeepLinkingPath: () => undefined,
};

export type ContextType = {
    setBackstageUserData?: Dispatch<SetStateAction<User | null>>;
    backstageUserData: User | null;
    userData: User | null;
    setUserData: Dispatch<SetStateAction<User | null>>;
    isAppLoading: boolean;
    setIsAppLoading: Dispatch<boolean>;
    selectedProfile: Profile | null;
    setSelectedUserProfile: Dispatch<Profile | null>;
    runtimeConfig: RuntimeConfigType | null;
    profiles: Profile[] | null;
    setProfiles: Dispatch<Profile[]>;
    setProfilesAndPossiblySelectOne: (profiles: Profile[] | null) => void;
    deviceRemoved: boolean;
    setDeviceRemoved: Dispatch<boolean>;
    isNavBarTransparent: boolean;
    setNavbarTransparent: Dispatch<boolean>;
    serviceData?: Service;
    setServiceData?: (v?: Service) => Promise<void>;
    isEditing: boolean;
    setEditing: Dispatch<boolean>;
    playerDeepLinkingPath?: string;
    setPlayerDeepLinkingPath: Dispatch<string | undefined>;
};

export const StoreContext = createContext<ContextType>(contextDefaults);

interface ProviderProps {
    children?: ReactNode;
    value: Partial<ContextType>;
    runtimeConfig: RuntimeConfigType;
}

export const ApplicationStoreProvider = ({
    children,
    runtimeConfig: config,
    value,
}: ProviderProps) => {
    // TODO: most of this shouldn't be here, it should be in individual providers
    const [backstageUserData, setBackstageUserData] = useState<User | null>(null);
    const [userData, setUserData] = useState<User | null>(null);
    const [appIsLoading, setIsAppLoading] = useState(true);
    const [selectedProfile, setSelectedUserProfile] = useState<Profile | null>(null);
    const [playerDeepLinkingPath, setPlayerDeepLinkingPath] = useState<string | undefined>(
        undefined
    );

    const [isEditing, setEditing] = useState<boolean>(false);

    const [profiles, setProfiles] = useState<Profile[] | null>(null);
    const [deviceRemoved, setDeviceRemoved] = useState(false);
    const [isNavBarTransparent, setNavbarTransparent] = useState<boolean>(false);
    const [runtimeConfig] = useState(config);

    // If there is only one profile and it is unprotected choose it as a selected profile.
    const setProfilesAndPossiblySelectOne = (userProfiles: Profile[] | null) => {
        if (!userProfiles) return;

        if (userProfiles?.length > 1) {
            setProfiles(userProfiles);

            return;
        }

        if (userProfiles?.length === 1) {
            const profile = userProfiles[0];
            const isProtected = profile?.isPinProtected;
            setProfiles(userProfiles);
            if (!isProtected) setSelectedUserProfile(profile);
        }
    };

    return (
        <StoreContext.Provider
            value={{
                ...value,
                setBackstageUserData,
                backstageUserData,
                userData,
                deviceRemoved,
                setDeviceRemoved,
                setUserData,
                selectedProfile,
                setSelectedUserProfile,
                runtimeConfig,
                isAppLoading: appIsLoading,
                setIsAppLoading,
                profiles,
                setProfiles,
                setProfilesAndPossiblySelectOne,
                isNavBarTransparent,
                setNavbarTransparent,
                isEditing,
                setEditing,
                playerDeepLinkingPath,
                setPlayerDeepLinkingPath,
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => useContext(StoreContext);

// The inner component will never be used alone
// eslint-disable-next-line react/display-name
export const withStore = (Component) => (props) =>
    (
        <StoreContext.Consumer>
            {(context) => <Component {...props} {...context} />}
        </StoreContext.Consumer>
    );
