/* eslint-disable class-methods-use-this */
import { AnalyticsClient, AnalyticsConfig, AnyPayload } from '@24i/nxg-sdk-photon';
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { TealiumInputPayload, TriggerMappers, TriggerMappersFactory } from './types';
import { createTealiumSdk } from './sdk';

export class TealiumClient implements AnalyticsClient {
    sdk = createTealiumSdk();

    name = 'Tealium';

    mappers: TriggerMappers;

    triggers: string[];

    dataMapper: Record<string, any> = {};

    constructor(mappers?: TriggerMappers | TriggerMappersFactory) {
        if (typeof mappers === 'object') this.mappers = mappers;
        else if (typeof mappers === 'function') this.mappers = mappers();
        else this.mappers = {};
        this.triggers = Object.keys(this.mappers) as ANALYTICS_TRIGGERS[];
    }

    init(config: AnalyticsConfig): void {
        this.dataMapper = config.options.data_mapper as Record<string, any>;
        this.sdk.init(config);
    }

    async onEvent(triggerName: ANALYTICS_TRIGGERS, payload: AnyPayload): Promise<void> {
        const mapFunction = this.mappers[triggerName];
        const result = mapFunction
            ? mapFunction(triggerName, payload as TealiumInputPayload, this.dataMapper)
            : undefined;
        if (!result || result.ignore) return;
        await this.sdk.track(result);
    }

    sendEvent(_: AnyPayload): Promise<void> {
        return Promise.resolve();
    }
}

export const createTealiumClient = () => new TealiumClient();
