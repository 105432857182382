import { useTranslation } from 'react-i18next';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { formatDurationText } from '@24i/nxg-core-utils';
import { PackshotContentProps } from '../types';

export interface SharedModuleReturnType {
    title?: string | null;
    subtitle?: string | null;
}

export const useShared = (props: PackshotContentProps): SharedModuleReturnType => {
    const { item, title, subtitle, showDuration } = props;

    const { t } = useTranslation();

    const getPackshotTitle = () => {
        if (item.type === ASSET_TYPE.EPISODE) return item.seriesName || title;

        return title;
    };

    const getPackshotSubtitle = () => {
        const { seasonNumber, episodeNumber } = item;

        if (showDuration) {
            const durationInMs = props.item.duration * 1000;
            return formatDurationText(durationInMs);
        }

        if (item.type === ASSET_TYPE.EPISODE) {
            const seasonNumberText = seasonNumber
                ? `${t('asset.series.seasonInitial')}${seasonNumber} `
                : '';
            const episodeNumberText = episodeNumber
                ? `${t('asset.series.episode.initial')}${episodeNumber} `
                : '';
            const episodeTitleText =
                (seasonNumber || episodeNumber) && title ? `- ${title}` : title;

            return `${seasonNumberText}${episodeNumberText}${episodeTitleText}`;
        }
        return subtitle;
    };

    return {
        title: getPackshotTitle(),
        subtitle: getPackshotSubtitle(),
    };
};
