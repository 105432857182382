/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import View, {
    defaultRenderMoreScreenAuthSection,
    defaultRenderMoreScreenMenu,
    defaultRenderProfilePicker,
    getMoreScreenStyles,
} from './View';
import { useViewModel } from './viewModel';
import * as prerenderFunctions from '../../pages/more';
import MoreScreenAuthSection from './components/MoreScreenAuthSection';
import MoreScreenMenu from './components/MoreScreenMenu';
import ProfilePicker from './components/ProfilePicker';
import { MoreScreenScreenProps } from './types';

export default {
    Main: (props: MoreScreenScreenProps): ReactElement => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    page: {
        more: prerenderFunctions,
    },
    // components
    MoreScreenAuthSection,
    MoreScreenMenu,
    ProfilePicker,
    // default render functions
    defaultRenderMoreScreenAuthSection,
    defaultRenderMoreScreenMenu,
    defaultRenderProfilePicker,
    // default styles getter
    getDefaultStyles: getMoreScreenStyles,
};
