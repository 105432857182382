import React from 'react';
import { BannerCarouselItemTypes } from '../../types';
import BannerCarouselItemView from './View';
import { useViewModel } from './viewModel';

const BannerCarousel = (props: Omit<BannerCarouselItemTypes, 'isObscured'>) => {
    const viewProps = useViewModel(props);
    return <BannerCarouselItemView {...viewProps} />;
};

export default BannerCarousel;
