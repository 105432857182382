import { Asset, QUERY_KEYS, Rating } from '@24i/nxg-sdk-photon/src';
import { useMutation, useQueryClient } from 'react-query';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';

const useAssetUpdateRatingQuery = (asset: Asset) => {
    const queryClient = useQueryClient();
    const { rateAssetForUser, deleteAssetRatingForUser } = useUserData();

    const { recordError } = useFirebase();

    const { mutate: updateUserRating, isLoading: isUpdateLoading } = useMutation(
        (userRating: Rating) => rateAssetForUser(asset, userRating),
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([QUERY_KEYS.assets, QUERY_KEYS.assetRating]);
            },
            onError: (error) => {
                log(error);
                recordError?.(error);
            },
        }
    );

    const { mutate: deleteUserRating, isLoading: isDeleteLoading } = useMutation(
        () => deleteAssetRatingForUser(asset),
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([QUERY_KEYS.assets, QUERY_KEYS.assetRating]);
            },
            onError: (error) => {
                log(error);
                recordError?.(error);
            },
        }
    );

    return { isLoading: isDeleteLoading || isUpdateLoading, deleteUserRating, updateUserRating };
};

export default useAssetUpdateRatingQuery;
