import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { View } from '@24i/nxg-sdk-quarks';
import TextInputWeb from '@24i/nxg-sdk-quarks/src/components/TextInput/index.web';
import { TextInputRefType } from '@24i/nxg-sdk-quarks/src/components/TextInput/types';
import React, { forwardRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { SignUpInputViewProps } from './types';

const SignupInput = forwardRef<TextInputRefType, SignUpInputViewProps>(
    (
        {
            additionalContainerStyles,
            activeTextInputColor: activeTextInputStyle,
            inactiveTextInputColor: inactiveTextInputStyle,
            placeholderTextColor,
            placeholder,
            additionalTextInputStyles,
            secureTextStyles,
            isPasswordField,
            addAdditionalAction,
            additionalActionContainerStyles,
            additionalActionTextStyles,
            selectionColor,
            maxPasswordLength,
            onChangeText,
            t,
            ...textInputProps
        },
        ref
    ) => {
        const [isActive, setIsActive] = useState(false);
        const [isVisible, setIsVisible] = useState(isPasswordField);

        return (
            <View>
                <TextInputWeb
                    ref={ref}
                    type={isVisible ? 'password' : 'text'}
                    style={[
                        { borderWidth: 0 },
                        additionalContainerStyles,
                        {
                            backgroundColor: isActive
                                ? activeTextInputStyle
                                : inactiveTextInputStyle,
                        },
                        additionalTextInputStyles,
                        isVisible && secureTextStyles,
                    ]}
                    placeholder={placeholder}
                    placeholderTextColor={placeholderTextColor}
                    secureTextEntry={isVisible}
                    selectionColor={selectionColor}
                    // Adding any not to break anything
                    onChange={(e) => onChangeText((e.target as any).value)}
                    onFocus={() => setIsActive(true)}
                    onBlur={() => setIsActive(false)}
                    maxLength={maxPasswordLength}
                    {...textInputProps}
                />
                {addAdditionalAction ? (
                    <ActionButton
                        testID={`${textInputProps.testID}_show_button`}
                        title={!isVisible ? t('common.hide') : t('common.show')}
                        onPress={() => setIsVisible(!isVisible)}
                        additionalContainerStyles={additionalActionContainerStyles}
                        additionalTextStyles={additionalActionTextStyles}
                    />
                ) : null}
            </View>
        );
    }
);

SignupInput.displayName = 'SignupInput';

const Overridable = overridable(SignupInput, 'SignupInput');

const LocalizedSignupInput = withTranslation(['sott'])(Overridable);
export default LocalizedSignupInput;
