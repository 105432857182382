import React, { ReactElement } from 'react';
import { ProfilePickerProps } from './types';
import View from './View';
import { useViewModel } from './viewModel';

export { default as View, defaultRenderProfileItem } from './View';

const Main = (props: ProfilePickerProps): ReactElement => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
