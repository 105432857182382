import React, { createContext, useContext } from 'react';
import { Storage } from '@24i/nxg-sdk-quantum';
import {
    ASYNC_STORAGE_KEY_USER_TOKEN,
    ASYNC_STORAGE_KEY_USER,
} from '@24i/nxg-core-utils/src/constants';

import { UserDataClient } from '@24i/nxg-sdk-photon';
import { getDefaultUserDataClient } from './defaultClient';

const dummyDataClient = getDefaultUserDataClient();

export const UserDataContext = createContext<UserDataClient>(dummyDataClient);

export const UserDataProvider = ({ client = dummyDataClient, children }) => (
    <UserDataContext.Provider value={client}>{children}</UserDataContext.Provider>
);

export const useUserData = () => useContext(UserDataContext);

export const setUserToken = async (token: string) =>
    Storage.setItem(ASYNC_STORAGE_KEY_USER_TOKEN, token);

export const setUserDataToStorage = async (data) =>
    Storage.setItem(ASYNC_STORAGE_KEY_USER, JSON.stringify(data));
