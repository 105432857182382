import useAssetDetailsQuery from './useAssetDetailsQuery';
import useAssetExtrasQuery from './useAssetExtrasQuery';
import useAssetRelatedQuery from './useAssetRelatedQuery';
import useAssetSeasonsQuery from './useAssetSeasonsQuery';
import useAssetRatingQuery from './useAssetRatingQuery';
import useAssetUpdateRatingQuery from './useAssetUpdateRatingQuery';

export {
    useAssetDetailsQuery,
    useAssetRelatedQuery,
    useAssetSeasonsQuery,
    useAssetExtrasQuery,
    useAssetRatingQuery,
    useAssetUpdateRatingQuery,
};
