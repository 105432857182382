import { getFont } from '@24i/nxg-sdk-higgs';

const getSettingsLanguageStyles = (theme) => ({
    listItem: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        marginVertical: 12,
        width: '100%',
    },
    listItemText: {
        color: `${theme.color.menuItemActive}`,
        ...getFont(theme, 'menuItem-inactive'),
    },
});

export default getSettingsLanguageStyles;
