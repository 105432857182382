import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockedItem } from '@24i/nxg-sdk-photon';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text } from '@24i/nxg-sdk-quarks';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { isPlatformWeb } from 'renative';
import { DIALOG_MODAL_TEST_IDS } from './types';
import getBlockedModalStyles from './styles';

interface Props {
    title: ReactNode;
    subtitle?: BlockedItem | string;
    onAction?: () => void;
    onClose?: () => void;
    children?: JSX.Element;
}

const BlockedModal = ({ title, subtitle, onAction, onClose, children }: Props) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getBlockedModalStyles(theme);

    const finalTitle: string | null | undefined =
        subtitle && typeof subtitle !== 'string' && subtitle.resolvement
            ? subtitle.resolvement.action
            : t('auth.signIn.title');

    return (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <View style={styles.container} testID={DIALOG_MODAL_TEST_IDS.MODAL_WINDOW}>
                <Text style={styles.title} testID={DIALOG_MODAL_TEST_IDS.TITLE}>
                    {title}
                </Text>
                <Text style={styles.subtitle} testID={DIALOG_MODAL_TEST_IDS.SUBTITLE}>
                    {subtitle && typeof subtitle !== 'string' && subtitle?.message
                        ? subtitle.message
                        : subtitle}
                </Text>
                <View style={styles.buttonsContainer}>
                    {children || (
                        <>
                            {onAction && (
                                <SubactionButton
                                    title={finalTitle}
                                    onPress={onAction}
                                    disableATVonPressLimiter
                                    additionalTextStyles={styles.actionButtonText}
                                    additionalContainerStyles={styles.actionButtonContainer}
                                    testID={DIALOG_MODAL_TEST_IDS.BUTTON_CONFIRM}
                                />
                            )}
                            <SubactionButton
                                title={onAction ? t('common.cancel') : t('common.close')}
                                onPress={onClose}
                                disableATVonPressLimiter
                                additionalContainerStyles={[
                                    styles.closeButtonContainer,
                                    {
                                        alignSelf: isPlatformWeb ? 'flex-end' : null,
                                    },
                                    !isPlatformWeb && { marginTop: onAction ? 24 : 48 },
                                ]}
                                additionalTextStyles={!isPlatformWeb && { marginLeft: 0 }}
                                testID={DIALOG_MODAL_TEST_IDS.BUTTON_CANCEL}
                            />
                        </>
                    )}
                </View>
            </View>
        </View>
    );
};

export default BlockedModal;
