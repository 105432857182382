import React, { forwardRef } from 'react';
import { TextInput as RNTextInput } from 'react-native-web';
import StyleSheet from 'react-native-media-query';
import { TextInputRefType, TextInputPropsWeb } from './types';

const TextInputWeb = forwardRef<TextInputRefType, TextInputPropsWeb>(({ style, ...props }, ref) => {
    const { ids, styles } = StyleSheet.process(style);
    return <RNTextInput ref={ref} style={styles} dataSet={{ media: ids }} {...props} />;
});

TextInputWeb.displayName = 'TextInput_Web_Quarks';

export default TextInputWeb;
