var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.getClients=exports.default=void 0;var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _initApp=_interopRequireWildcard(require("@24i/nxg-sdk-smartott/src/Application/initApp"));
var _DefaultBackstageClients=require("@24i/nxg-integration-backstage/src/clients/DefaultBackstageClients");
var _nxgSdkSmartottDefaults=require("@24i/nxg-sdk-smartott-defaults");
var _nxgSdkSmartottStubs=require("@24i/nxg-sdk-smartott-stubs");
var _AppStartClient=require("@24i/nxg-sdk-smartott-defaults/src/clients/AppStartClient");
var _globalSingletons=require("@24i/nxg-core-utils/src/globalSingletons");
var _renativeRuntime=_interopRequireDefault(require("../../../platformAssets/renative.runtime.json"));
var _ExperienceUserDataClient=require("./clients/ExperienceUserDataClient");
var _customScreens=require("./customScreens");
var _locale=require("../locale");
var _ExperienceAppVersionDataClient=require("./clients/ExperienceAppVersionDataClient");
var _ExperienceEpgDataClient=require("./clients/ExperienceEpgDataClient");

var _logo=_interopRequireDefault(require("../../../platformAssets/runtime/images/logo.png"));function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=typeof e&&"function"!=typeof e)return{default:e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n.default=e,t&&t.set(e,n),n;}function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}

_globalSingletons.AppVersion.set(_renativeRuntime.default.appVersion+"-"+_renativeRuntime.default.timestamp);

var runtimeConfig=(0,_initApp.getMergedRuntimeConfig)(_renativeRuntime.default,_nxgSdkSmartottDefaults.defaultRuntimeConfig);

var staticSplashConfig={
background:null,
logo:_logo.default
};

(0,_initApp.default)({i18nOptions:_locale.i18nOptions});

var getClients=function getClients(serviceParams){

var defaultBackstageClients=(0,_DefaultBackstageClients.getDefaultBackstageClients)(serviceParams);


var userDataClient=(0,_ExperienceUserDataClient.createExperienceUserDataClient)(serviceParams);
var appVersionClient=(0,_ExperienceAppVersionDataClient.createExperienceAppVersionClient)(serviceParams);


var defaultClientStubs=(0,_nxgSdkSmartottStubs.getDefaultClientStubs)(defaultBackstageClients.contentDataClient);


var defaultSDKClients=(0,_nxgSdkSmartottDefaults.getDefaultSDKClients)(_customScreens.CUSTOM_SCREEN_BUILDERS,runtimeConfig);

var epgDataClient=(0,_ExperienceEpgDataClient.createExperienceEpgDataClient)(serviceParams);

var appStartClient=new _AppStartClient.DefaultAppStartClient(_objectSpread(_objectSpread(_objectSpread(_objectSpread({},
defaultSDKClients),
defaultClientStubs),
defaultBackstageClients),{},{
epgDataClient:epgDataClient,
userDataClient:userDataClient,
appVersionClient:appVersionClient})
);

return _objectSpread(_objectSpread(_objectSpread(_objectSpread({},
defaultSDKClients),
defaultClientStubs),
defaultBackstageClients),{},{
epgDataClient:epgDataClient,
userDataClient:userDataClient,
appVersionClient:appVersionClient,
appStartClient:appStartClient});

};exports.getClients=getClients;var _default=


{
runtimeConfig:runtimeConfig,
staticSplashConfig:staticSplashConfig
};exports.default=_default;