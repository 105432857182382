import type { RuntimeParams } from '@24i/nxg-core-utils/src/api';
import type { RuntimeConfig } from '@24i/nxg-sdk-photon';
import { Api } from 'renative';

export const getParamsFromRuntimeConfig = (runtimeConfig: RuntimeConfig): RuntimeParams => {
    const { apiService, baseUri } = runtimeConfig;
    let serviceId;
    let applicationId;

    if (apiService) {
        serviceId = apiService.id;
        applicationId = apiService.appId[Api.platform] || apiService.appId.default;
    }

    return {
        baseUri,
        serviceId,
        applicationId,
    };
};
