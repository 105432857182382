export default {
    app: {
        rows: {
            rowItemLimit: 15,
            heroItemLimit: 8,
            enableDynamicSliderImageType: true,
        },
        defaultServiceThemeIndex: 0,
        grid: {
            pageSize: 24,
        },
    },
    menu: {
        mobile: {
            isMoreScreenEnabled: true,
            moreScreenName: 'more',
            maxVisibleNavbarItems: 5,
        },
        desktop: {
            maxVisibleNavbarItems: 6,
        },
    },
    screens: {
        details: {
            enableShareButton: true,
        },
        player: {
            enableBuffering: false,
        },
    },
    defaultLazyLoading: {
        mobile: 15,
        desktop: 0,
    },
};
