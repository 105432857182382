import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { withModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import SettingsButton from '@24i/nxg-sdk-smartott/src/components/Buttons/SettingsButton';
import SettingsWrapper from '@24i/nxg-sdk-smartott/src/screens/SettingsScreen/components/SettingsWrapper';
import ChangeSubscription from '../../ChangeSubscription';
import SubscriptionButton from '../../SubscriptionButton';
import getAccountSubscriptionContentStyles from '../styles';

const AccountSubscriptionContent = ({
    styles: getStyles,
    plans,
    currentPlan,
    handleOnRequestSubscription,
    isModalVisible,
    setModalState,
    closeModal,
    setModalChildren,
    ...props
}) => {
    const [selectedPlan, setSelectedPlan] = useState(currentPlan);
    const { t } = useTranslation(['sott'], { wait: true });
    const { isLandscape } = useDimensions();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const handleOnOptionPress = (index) => setSelectedPlan(index);

    return (
        <SettingsWrapper {...props}>
            {plans.map((plan, index) => (
                <SubscriptionButton
                    key={plan.id}
                    onPress={() => handleOnOptionPress(index)}
                    title={plan.name}
                    priceTag={{ price: plan.price, currency: plan.currency }}
                    subtitle={plan.description}
                    iconName={isPlatformWeb ? 'onPrimary-subpage' : 'onPrimary-confirm'}
                    subtitleNumberOfLines={isLandscape ? 2 : 3}
                    isCurrentPlan={currentPlan === index}
                    isSelected={selectedPlan === index}
                />
            ))}
            <SettingsButton
                onPress={() =>
                    isPlatformWeb
                        ? setModalChildren(
                              <ChangeSubscription
                                  currentPlan={currentPlan >= 0 ? plans[currentPlan] : currentPlan}
                                  selectedPlan={plans[selectedPlan]}
                                  closeModal={() => closeModal()}
                                  handleOnRequestSubscription={handleOnRequestSubscription}
                              />
                          )
                        : setModalState(true)
                }
                title={t('common.continue')}
                iconName="onPrimary-subpage"
                iconSize={16}
                additionalContainerStyles={[
                    isLandscape
                        ? styles.continueButtonContainerLandscape
                        : styles.continueButtonContainer,
                    { opacity: selectedPlan === currentPlan ? 0.6 : 1 },
                ]}
                additionalTextStyles={styles.continueButtonText}
                additionalIconStyles={styles.continueButtonIcon}
                disabled={selectedPlan === currentPlan}
            />
            {!isPlatformWeb && (
                <Modal visible={isModalVisible} onRequestClose={() => setModalState(false)}>
                    <ChangeSubscription
                        currentPlan={currentPlan >= 0 ? plans[currentPlan] : currentPlan}
                        selectedPlan={plans[selectedPlan]}
                        closeModal={() => setModalState(false)}
                        handleOnRequestSubscription={handleOnRequestSubscription}
                        isLandscape={isLandscape}
                    />
                </Modal>
            )}
        </SettingsWrapper>
    );
};

AccountSubscriptionContent.propTypes = {
    styles: PropTypes.func,
    onBack: PropTypes.func,
    plans: PropTypes.shape([]),
    currentPlan: PropTypes.number,
    setCurrentPlan: PropTypes.func,
    handleOnRequestSubscription: PropTypes.func,
    isModalVisible: PropTypes.bool,
    setModalState: PropTypes.func,
    closeModal: PropTypes.func,
    setModalChildren: PropTypes.func,
};

AccountSubscriptionContent.defaultProps = {
    styles: getAccountSubscriptionContentStyles,
    onBack: null,
    plans: [],
    currentPlan: 0,
    isModalVisible: false,
    setModalState: () => {},
    setCurrentPlan: () => {},
    handleOnRequestSubscription: () => {},
    closeModal: () => {},
    setModalChildren: () => {},
};

export { getAccountSubscriptionContentStyles };
export default withModal(overridable(AccountSubscriptionContent, 'AccountSubscriptionContent'));
