import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QUERY_KEYS, Subscription } from '@24i/nxg-sdk-photon/src';
import { log } from '@24i/nxg-core-utils/src/logger';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import { useUserQuery } from '../user/useUserQuery';
import { useFirebase } from '../../../context/Firebase';

const useEntitledSubscriptionsQuery = (
    options?: UseQueryOptions<{ subscriptions: Subscription[] }>
): UseQueryResult<{ subscriptions: Subscription[] }> => {
    const { fetchEntitledSubscriptions } = usePurchaseData();
    const { user } = useUserQuery();
    const { recordError } = useFirebase();

    return useQuery<{ subscriptions: Subscription[] }>(
        QUERY_KEYS.mySubscriptions,
        () => fetchEntitledSubscriptions(),
        {
            ...options,
            enabled: !!user,
            onError: (error) => {
                log(error);
                recordError?.(error);
                options?.onError?.(error);
            },
            staleTime: 0,
        }
    );
};

export default useEntitledSubscriptionsQuery;
