import React, { createContext, useContext } from 'react';

import { getDummyClient, PurchaseDataClient } from '@24i/nxg-sdk-photon';

const defaultClient = getDummyClient<PurchaseDataClient>();

export const PurchaseDataContext = createContext<PurchaseDataClient>(defaultClient);

export const PurchaseDataProvider = ({ client = defaultClient, children }) => (
    <PurchaseDataContext.Provider value={client}>{children}</PurchaseDataContext.Provider>
);

export const usePurchaseData = () => useContext(PurchaseDataContext);
