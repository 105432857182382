import { getFont } from '@24i/nxg-sdk-higgs';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { TextStyle } from 'react-native';

const isTablet = DeviceInfo.isTablet();

const styles = (theme) => ({
    controllerContainer: {
        width: '100%',
        zIndex: 999,
        marginBottom: 60,
    },
    timelineContainer: {
        zIndex: 999,
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 30,
        width: isTablet ? '70%' : '100%',
        alignSelf: 'center',
    },
    timeTextStyles: {
        width: 94,
        color: theme.color.textPrimary,
        textAlign: 'center',
        ...getFont(theme, 'h5'),
    } as TextStyle,
    secondaryControlsContainer: {
        zIndex: 999,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 48,
        marginTop: 18,
        width: isTablet ? '70%' : '100%',
        alignSelf: 'center',
    },
    visibleVolumeContainer: {
        backgroundColor: theme.color.primary3,
        flex: 1,
        paddingLeft: 16,
        paddingRight: 16,
        height: '100%',
        borderRadius: 24,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 16,
        marginRight: 16,
        justifyContent: 'space-between',
    },
    additionalVisibleVolumeContainerStyles: {
        width: '80%',
    },
    volumeIcon: { marginLeft: 32 },
    subtitleIcon: { marginRight: 32 },
    relatedContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    relatedText: {
        ...getFont(theme, 'h5'),
        color: theme.color.textPrimary,
    },
    pauseContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
    },
    seekButton: {
        marginHorizontal: 32,
    },
});

export default styles;
