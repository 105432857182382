import { ImageServiceClient } from '../ImageServiceClient';
import { createSottNavigationClient } from '../AppNavigationClient';
import { LayoutClient } from '../LayoutClient';
import { createSentryMonitoringDataClient } from '../SentryMonitoringDataClient';
import { createDataPlatformClient } from '../DataPlatformClient';
import { createGoogleAnalyticsClient } from '../GoogleAnalyticsClient';
import { createTwentyFourIQClient } from '../TwentyFourIQClient';
import { createTealiumClient } from '../TealiumClient';

export const getDefaultTvSDKClients = (createCustomScreens, runtimeConfig) => {
    if (!runtimeConfig)
        throw new Error(
            `Not possible to create default SDK clients. Please specify a valid runtime config.`
        );
    // @ts-ignore
    const customScreens = createCustomScreens();
    const appNavigationClient = createSottNavigationClient(customScreens);
    const imageServiceClient = new ImageServiceClient();
    const layoutClient = new LayoutClient();
    const sentryMonitoringDataClient = createSentryMonitoringDataClient(runtimeConfig);
    const dataPlatformClient = createDataPlatformClient(runtimeConfig);
    const googleAnalyticsClient = createGoogleAnalyticsClient();
    const twentyFourIQClient = createTwentyFourIQClient();
    const tealiumClient = createTealiumClient();

    return {
        appNavigationClient,
        imageServiceClient,
        layoutClient,
        sentryMonitoringDataClient,
        dataPlatformClient,
        googleAnalyticsClient,
        twentyFourIQClient,
        tealiumClient,
    };
};
