import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { DeleteAccountStyles } from '../types';
import { Breakpoint } from '../../../utils/styles/constants';

const getStyles = (theme: Theme): DeleteAccountStyles => ({
    mainContainer: {
        width: Breakpoint.SM,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            flex: 1,
            paddingHorizontal: 20,
            width: '100%',
        },
    },
    descriptionText: {
        width: '100%',
        textAlign: 'left',
        color: theme.color.textPrimary,
        marginBottom: 40,
        opacity: 0.6,
        ...getFont(theme, 'body2'),
    },
    deleteButton: {
        backgroundColor: theme.color.buttonPrimary,
        height: 52,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
    },
    buttonText: {
        color: theme.color.textButtonPrimary,
        ...getFont(theme, 'primaryButton'),
    },
});

export default getStyles;
