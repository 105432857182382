import React from 'react';
import { TextInput } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import getStyles from '../ChangeModal/styles';
import { CHANGEABLE_TEXT_TEST_IDS, ChangeModalItemProps } from '../../types';

const ChangeModalTextInput = (props: ChangeModalItemProps): JSX.Element => {
    const { setIsFocused, textInputValue, setTextInputValue, placeholder, item, maxNameLength } =
        props;
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const updateTextInputValue = (value: string): void => {
        setTextInputValue(value);
    };
    return (
        <TextInput
            testID={CHANGEABLE_TEXT_TEST_IDS.TEXT_INPUT}
            style={styles.textInput}
            onChangeText={updateTextInputValue}
            placeholder={placeholder}
            value={textInputValue}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholderTextColor={theme.color.textPrimary}
            autoCapitalize="none"
            maxLength={item?.itemKey.includes('Name') ? maxNameLength : undefined}
        />
    );
};

export default ChangeModalTextInput;
