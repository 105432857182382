import { getBorderRadius, getFont, getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '../../../utils/styles/constants';
import { SignUpScreenViewStylesWeb } from './types';

const styles = (theme: Theme): SignUpScreenViewStylesWeb => ({
    screenContainer: {
        flex: 1,
        backgroundColor: theme.color.background1,
    },
    screenHeader: {
        position: 'absolute',
        zIndex: 9999,
        top: 0,
        left: 0,
        marginLeft: 48,
    },
    headerContainer: {
        marginTop: 80,
    },
    headerText: {
        color: theme.color.textPrimary,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 64,
        flexGrow: 1,
        ...getFont(theme, 'h2'),
    },
    screenContent: {
        alignSelf: 'center',
        width: 336,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            width: '100%',
            paddingHorizontal: 20,
        },
    },
    descriptionContainer: {
        width: '100%',
    },
    descriptionText: {
        textAlign: 'center',
        ...getFont(theme, 'h4'),
        color: theme.color.textPrimary,
    },
    inputErrorContainer: {
        flexDirection: 'row',
        marginBottom: 10,
        alignItems: 'center',
    },
    inputErrorIcon: {
        fontSize: 14,
        marginRight: 8,
        color: theme.color.error,
    },
    inputErrorText: {
        color: theme.color.error,
        ...getFont(theme, 'body2'),
        width: '100%',
        textAlign: 'center',
    },
    signupInputContainer: {
        paddingRight: 24,
        borderRadius: 24,
        ...getBorderRadius(theme.elements, 'textField-cornerRadius'),
        paddingLeft: 24,
        marginBottom: 16,
        height: 48,
        justifyContent: 'center',
    },
    signupEmailContainer: {
        paddingRight: 24,
        borderRadius: 24,
        ...getBorderRadius(theme.elements, 'textField-cornerRadius'),
        paddingLeft: 24,
        marginBottom: 16,
        height: 48,
        justifyContent: 'center',
    },
    signupInputText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body1'),
    },
    passwordErrorContainer: {
        flexDirection: 'row',
        marginBottom: 10,
        alignItems: 'center',
    },
    passwordInputContainer: {
        borderRadius: 24,
        ...getBorderRadius(theme.elements, 'textField-cornerRadius'),
        paddingLeft: 24,
        paddingRight: 80,
        marginBottom: 16,
        height: 48,
        justifyContent: 'center',
    },
    actionContainer: {
        zIndex: 1,
        position: 'absolute',
        right: -5,
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
        padding: 0,
    },
    actionText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h6'),
        padding: 0,
    },
    signupButtonContainer: {
        width: '100%',
        height: 54,
        borderRadius: 27,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
        justifyContent: 'center',
        shadowColor: 'transparent',
    },
    signButtonText: {
        textAlign: 'center',
        fontWeight: 'bold',
        ...getFont(theme, 'primaryButton'),
    },
    logoHolder: {
        alignSelf: 'center',
        marginBottom: 65,
    },
    backButtonContainer: {
        ...getMargins(),
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        zIndex: 999,
        left: 0,
        top: '3%',
    },
    backButtonText: {
        ...getFont(theme, 'body2'),
        color: `${theme.color.textPrimary}99`,
    },
    backButtonIcon: {
        marginRight: 8,
    },
    signInWrapper: {},
    signInButton: {
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
    },
    signInButtonText: {
        color: theme.color.textPrimary,
    },
    loaderContainer: {
        marginTop: 12,
        height: 52,
        justifyContent: 'center',
    },
    alreadyHaveAccountContainer: {
        width: '100%',
        marginTop: 48,
    },
    passwordInfoContainer: {
        width: '100%',
        marginTop: 8,
        marginBottom: 48,
    },
    subtitle: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        opacity: 0.6,
        ...getFont(theme, 'body2'),
        fontSize: 15,
    },
    signupEmailWrapper: {},
});

export default styles;
