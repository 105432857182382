import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, ClosePlayerSessionResult } from '../types';
import { EVENTS } from '../constants';
import { mapOpenPlayerSession } from './mapOpenPlayerSession';

export const mapClosePlayerSession = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): ClosePlayerSessionResult => ({
    ...mapOpenPlayerSession(triggerName, payload),
    event: EVENTS.CLOSE_PLAYER_SESSION,
});
