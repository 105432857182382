import { Text, View } from '@24i/nxg-sdk-quarks';
import React, { ReactElement } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useTranslation } from 'react-i18next';
import { getMvpdSignInContentStyles } from '../styles';
import { MvpdGrid } from '../../MvpdGrid';
import { SearchInput } from '../../SearchInput';
import { MvpdSignInContentViewModelReturnType } from '../types';

export const MvpdSignInContent = (props: MvpdSignInContentViewModelReturnType): ReactElement => {
    const {
        mvpds: { tierOneMvpds, allMvpds },
        handleSeeAllProviders,
        seeAllProviders,
        handleSelectProvider,
        handleSearchStringChange,
        searchString,
        forwardGuest,
        isModal,
        guestModeEnabled,
        landingScreenEnabled,
    } = props;
    const { theme } = useTheme();
    const styles = getMvpdSignInContentStyles(theme);
    const { closeModal } = useModal();

    const { t } = useTranslation();

    return (
        <View style={styles.container}>
            <View style={styles.headerSection}>
                <Text numberOfLines={1} style={styles.headerTitle}>
                    Sign In
                </Text>
            </View>
            <View style={styles.pageDescriptionContainer}>
                <Text style={styles.pageDescriptionText}>
                    {t('adobePrimetime.signInWithTVProvider')}
                </Text>
            </View>
            {seeAllProviders && (
                <SearchInput
                    theme={theme}
                    handleSearchStringChange={handleSearchStringChange}
                    searchString={searchString}
                />
            )}
            <MvpdGrid
                mvpds={seeAllProviders ? allMvpds : tierOneMvpds}
                theme={theme}
                seeAllProviders={seeAllProviders}
                handleSelectProvider={handleSelectProvider}
            />
            <View style={styles.allProvidersButtonContainer}>
                <TertiaryButton
                    title={
                        seeAllProviders
                            ? t('adobePrimetime.seeMainProviders')
                            : t('adobePrimetime.seeAllProviders')
                    }
                    styles={() => styles.allProvidersButton}
                    onPress={handleSeeAllProviders}
                />
                {guestModeEnabled && !landingScreenEnabled && (
                    <TertiaryButton
                        title="Have a look around"
                        styles={() => styles.haveAlookButton}
                        onPress={isModal ? closeModal : forwardGuest}
                    />
                )}
            </View>
        </View>
    );
};
