import { ASYNC_STORAGE_KEY_USER_LANGUAGE } from '@24i/nxg-core-utils/src/constants';
import { Storage } from '@24i/nxg-sdk-quantum';
import { InitOptions, LanguageDetectorAsyncModule } from 'i18next';
import { NativeModules, Platform } from 'react-native';
import { isPlatformWeb } from 'renative';

const getValidOrUndefLanguage = (
    language: string | undefined,
    i18nConfig: InitOptions
): string | undefined => {
    if (language && i18nConfig.resources?.[language]) {
        return language as string;
    }

    return undefined;
};

const getLanguageFromStorage = async (i18nConfig: InitOptions): Promise<string | undefined> => {
    const language = await Storage.getItem(ASYNC_STORAGE_KEY_USER_LANGUAGE);

    return getValidOrUndefLanguage(language, i18nConfig);
};

const getLanguageFromUrl = (i18nConfig: InitOptions): string | undefined => {
    if (!isPlatformWeb) {
        return undefined;
    }

    // Only on client side
    if (typeof window === 'undefined') {
        return undefined;
    }

    const language = window.location.pathname.split('/')[1];

    return getValidOrUndefLanguage(language, i18nConfig);
};

const getDeviceLanguage = (i18nConfig: InitOptions): string | undefined => {
    let language;

    if (isPlatformWeb) {
        // Only on client side
        if (typeof window === 'undefined') {
            return undefined;
        }

        language = window.navigator.language;
    } else if (Platform.OS === 'ios') {
        language =
            NativeModules.SettingsManager.settings.AppleLocale ||
            NativeModules.SettingsManager.settings.AppleLanguages[0]; // iOS 13
    } else {
        language = NativeModules.I18nManager.localeIdentifier;
    }

    if (!language) {
        return undefined;
    }

    const [prefix] = language.split('_');

    return getValidOrUndefLanguage(prefix, i18nConfig);
};

export const languageDetector = (i18nConfig: InitOptions): LanguageDetectorAsyncModule => ({
    type: 'languageDetector',
    async: true,
    init: () => {},
    cacheUserLanguage: () => {},
    detect: async (setLanguage) => {
        const language =
            getLanguageFromUrl(i18nConfig) ??
            (await getLanguageFromStorage(i18nConfig)) ??
            getDeviceLanguage(i18nConfig);

        if (!language) {
            const fallbackLng = Array.isArray(i18nConfig.fallbackLng)
                ? i18nConfig.fallbackLng[0]
                : i18nConfig.fallbackLng;

            if (!fallbackLng) {
                throw new Error('Please set at least one fallback language.');
            }

            setLanguage(fallbackLng as string);

            return Promise.resolve(fallbackLng);
        }

        setLanguage(language);
        return Promise.resolve(language);
    },
});
