import React, { ReactElement } from 'react';
import View from './View';
import { useViewModel } from './viewModel';
import { IconButtonProps } from './types';

export { default as View } from './View';
export { useViewModel } from './viewModel';

const Main = (props: IconButtonProps): ReactElement => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export const getDefaultStyles = () => ({});

export default Main;
