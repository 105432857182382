import React, { ComponentType, ReactElement } from 'react';
import { isPlatformWeb } from 'renative';
import { GradientColor } from '@24i/nxg-sdk-photon';
import { getPackshotTestID, PACKSHOT_TEST_IDS } from '../test-utils';
import { RenderPropsOptions } from '../types';

/**
 * Since packshot content is a multi platform file, importing and using platform specific
 * components creates TypeScript errors where platform specific components have unique
 * props interfaces. This function returns the componet for the platform used at runtime
 * and casts the correct interface for that platform
 *
 * @param {ComponentType<P>} Component component not used on web
 * @param {ComponentType<W>} WebComponent component used on web
 * @param {P | W} props component props of either web or non-web type
 * @param {RenderPropsOptions} options
 * @param {string} testId
 * @returns {ReactElement} platform specific component with correct typing
 */
export const getRenderProps = <P, W>(
    Component: ComponentType<P>,
    WebComponent: ComponentType<W>,
    props: P | W,
    options: RenderPropsOptions,
    testId: PACKSHOT_TEST_IDS
): ReactElement => {
    const testID = getPackshotTestID(testId, options);
    return isPlatformWeb ? (
        <WebComponent testID={testID} {...(props as W)} />
    ) : (
        <Component testID={testID} {...(props as P)} />
    );
};

export const getGradientVectors = (gradient: GradientColor) => {
    const angle = Number(gradient?.color?.split('deg')[0].split('(')[1]);
    let vectorStart = { x: 0.5, y: 0.5 };
    let vectorEnd = { x: 0.5, y: 1 };
    switch (angle) {
        case 0:
            if (isPlatformWeb) {
                vectorStart = { x: 0.5, y: 0.5 };
                vectorEnd = { x: 0.5, y: -1 };
            } else {
                vectorStart = { x: 0.5, y: 1 };
                vectorEnd = { x: 0.5, y: 0 };
            }
            break;
        case 90:
            if (isPlatformWeb) {
                vectorStart = { x: 0.5, y: 0.5 };
                vectorEnd = { x: 1, y: 0.5 };
            } else {
                vectorStart = { x: 0, y: 0.5 };
                vectorEnd = { x: 1, y: 0.5 };
            }
            break;
        case 270:
            if (isPlatformWeb) {
                vectorStart = { x: 0.5, y: 0.5 };
                vectorEnd = { x: 0, y: 0.5 };
            } else {
                vectorStart = { x: 0.5, y: 0 };
                vectorEnd = { x: 0.5, y: 1 };
            }
            break;
        case 180:
        default:
            if (isPlatformWeb) {
                vectorStart = { x: 0.5, y: 0.5 };
                vectorEnd = { x: 0.5, y: 1 };
            } else {
                vectorStart = { x: 0.5, y: 0.5 };
                vectorEnd = { x: 0.5, y: 1 };
            }
    }
    return { vectorStart, vectorEnd };
};
