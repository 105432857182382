import React, { createContext, FC, useCallback, useContext, useState, useEffect } from 'react';
import { Asset, PodcastEpisode, PodcastEpisodePlaybackStatus } from '@24i/nxg-sdk-photon';
import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import { PlayerBase } from '@24i/player-base';
import { useStore } from '../ApplicationStore';
import {
    withPurchaseModal,
    WithPurchasesModalProps,
} from '../../components/PurchasesModal/utils/withPurchasesModal';
import { MaybeEpisode } from '../../components/PodcastPlayer/types';
import { usePodcastPinBlockedModal } from '../../components/PodcastPinBlockedModal/hooks/usePodcastPinBlockedModal';
import useAssetBlockersValidation from '../../hooks/useAssetBlockersValidation';

type PodcastContext = {
    playPodcastEpisode: (episode: PodcastEpisode) => void;
    episodeInPlayer: MaybeEpisode;
    closePlayer: () => void;
    getIsEpisodeInPlayer: (episodeId: string) => boolean;
    onPlaybackPaused: () => void;
    onPlaybackResumed: () => void;
    playbackStatus: PodcastEpisodePlaybackStatus;
    handlePodcastPlayerFromOutside: (episodeId: string) => void;
    addToPodcastEpisodePinQueue: (episode: PodcastEpisode) => void;
    isMobilePlayerExpanded: boolean;
    openMobileExpandedPlayer: () => void;
    closeMobileExpandedPlayer: () => void;
    isLoading: boolean;
    startLoading: () => void;
    stopLoading: () => void;
    setPlayerInstanceHandle: (playerInstance: PlayerBase) => void;
    playerInstanceHandle: PlayerBase | null;
    startPreparingEpisode: () => void;
    stopPreparingEpisode: () => void;
} & Pick<WithPurchasesModalProps, 'openPurchaseModal'>;

const defaultValues: PodcastContext = {
    playPodcastEpisode: (_episode: PodcastEpisode) => {},
    episodeInPlayer: null,
    closePlayer: () => {},
    getIsEpisodeInPlayer: (_episodeId: string) => false,
    onPlaybackPaused: () => {},
    onPlaybackResumed: () => {},
    playbackStatus: 'playing',
    handlePodcastPlayerFromOutside: (_episodeId: string) => {},
    openPurchaseModal: (_asset: Asset) => {},
    addToPodcastEpisodePinQueue: (_episode: PodcastEpisode) => {},
    isMobilePlayerExpanded: false,
    openMobileExpandedPlayer: () => {},
    closeMobileExpandedPlayer: () => {},
    isLoading: false,
    startLoading: () => {},
    stopLoading: () => {},
    setPlayerInstanceHandle: (_playerInstance: PlayerBase) => {},
    playerInstanceHandle: null,
    startPreparingEpisode: () => {},
    stopPreparingEpisode: () => {},
};

const PodcastPlayerContext = createContext<PodcastContext>(defaultValues);

export const PodcastPlayerProviderBase: FC<WithPurchasesModalProps> = ({
    children,
    openPurchaseModal,
}) => {
    const [podcastEpisodeInPinQueue, setPodcastEpisodeInPinQueue] = useState<MaybeEpisode>(null);
    const [episodeInPlayer, setEpisodeInPlayer] = useState<MaybeEpisode>(null);
    const [playbackStatus, setPlaybackStatus] = useState<PodcastEpisodePlaybackStatus>('playing');
    const [isMobilePlayerExpanded, setIsMobilePlayerExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPreparingEpisode, setIsPreparingEpisode] = useState(false);
    const [playerInstanceHandle, setPlayerInstanceHandle] = useState<PlayerBase | null>(null);

    const { userData } = useStore();

    const { handleBlockersCheck } = useAssetBlockersValidation({});

    const { hasAdultBlocker, hasAgeBlocker } = useAssetPinControl({
        asset: episodeInPlayer || undefined,
        active: false,
        goBackAfterActivation: false,
        goBackAfterDismiss: false,
    });

    const {
        hasAdultBlocker: episodeInQueueHasAdultBlock,
        hasAgeBlocker: episodeInQueueHasAgeBlock,
        triggerPinControl,
    } = useAssetPinControl({
        asset: podcastEpisodeInPinQueue || undefined,
        active: false,
        goBackAfterActivation: false,
        goBackAfterDismiss: false,
    });

    const { openPodcastPinBlockedModal } = usePodcastPinBlockedModal();

    const addToPodcastEpisodePinQueue = useCallback((episode: PodcastEpisode) => {
        setPodcastEpisodeInPinQueue(episode);
    }, []);

    const playPodcastEpisode = useCallback(async (episode: PodcastEpisode) => {
        setPodcastEpisodeInPinQueue(null);
        setEpisodeInPlayer(episode);
    }, []);

    const openMobileExpandedPlayer = useCallback(() => {
        setIsMobilePlayerExpanded(true);
    }, []);

    const closeMobileExpandedPlayer = useCallback(() => {
        setIsMobilePlayerExpanded(false);
    }, []);

    const closePlayer = useCallback(() => {
        closeMobileExpandedPlayer();
        setIsPreparingEpisode(false);
        setIsLoading(false);
        setEpisodeInPlayer(null);
    }, [closeMobileExpandedPlayer]);

    const getIsEpisodeInPlayer = useCallback(
        (episodeId: string) => episodeInPlayer?.id === episodeId,
        [episodeInPlayer]
    );

    const onPlaybackPaused = () => {
        setPlaybackStatus('paused');
    };

    const onPlaybackResumed = () => {
        setPlaybackStatus('playing');
    };

    const startLoading = useCallback(() => {
        setIsLoading(true);
    }, []);

    const stopLoading = useCallback(() => {
        setIsLoading(false);
    }, []);

    const startPreparingEpisode = useCallback(() => {
        setIsPreparingEpisode(true);
    }, []);

    const stopPreparingEpisode = useCallback(() => {
        setIsPreparingEpisode(false);
    }, []);

    const handlePodcastPlayerFromOutside = useCallback(
        (episodeId: string) => {
            // Redundant check, just to stay safe
            const isEpisodeInPlayer = episodeId === episodeInPlayer?.id;
            if (!isEpisodeInPlayer) return;
            if (playerInstanceHandle?.paused) {
                playerInstanceHandle?.play();
                onPlaybackResumed();
            } else {
                playerInstanceHandle?.pause();
                onPlaybackPaused();
            }
        },
        [episodeInPlayer, playerInstanceHandle]
    );

    // Unmount when logged out!
    useEffect(() => {
        if (!userData) {
            closePlayer();
        }
    }, [userData]);

    useEffect(() => {
        if (episodeInPlayer) stopLoading();
    }, [episodeInPlayer, stopLoading]);

    useEffect(() => {
        // Checks for blockers and pin
        const checkBlockers = async (episode: PodcastEpisode) => {
            startLoading();
            const { hasBlocker } = await handleBlockersCheck({
                asset: episode,
                handlePurchase: openPurchaseModal,
            });

            if (hasBlocker) {
                closePlayer();
            }

            if (hasAdultBlocker || hasAgeBlocker) {
                addToPodcastEpisodePinQueue(episode);
                return;
            }

            stopLoading();
        };

        if (episodeInPlayer) {
            checkBlockers(episodeInPlayer);
        }
    }, [episodeInPlayer, hasAdultBlocker, hasAgeBlocker, addToPodcastEpisodePinQueue]);

    useEffect(() => {
        // Handles episodes in queue (waiting for pin)
        if (podcastEpisodeInPinQueue) {
            if (episodeInQueueHasAdultBlock || episodeInQueueHasAgeBlock) {
                closePlayer();
                openPodcastPinBlockedModal(
                    triggerPinControl,
                    episodeInQueueHasAdultBlock ? 'adult' : 'age-limit'
                );
            } else {
                stopLoading();
                playPodcastEpisode(podcastEpisodeInPinQueue);
            }
        }
    }, [
        podcastEpisodeInPinQueue,
        episodeInQueueHasAdultBlock,
        episodeInQueueHasAgeBlock,
        closePlayer,
        playPodcastEpisode,
    ]);
    return (
        <PodcastPlayerContext.Provider
            value={{
                playPodcastEpisode,
                episodeInPlayer,
                closePlayer,
                getIsEpisodeInPlayer,
                playbackStatus,
                onPlaybackPaused,
                onPlaybackResumed,
                handlePodcastPlayerFromOutside,
                openPurchaseModal,
                addToPodcastEpisodePinQueue,
                isMobilePlayerExpanded,
                openMobileExpandedPlayer,
                closeMobileExpandedPlayer,
                isLoading: isLoading || isPreparingEpisode,
                startLoading,
                stopLoading,
                setPlayerInstanceHandle,
                playerInstanceHandle,
                startPreparingEpisode,
                stopPreparingEpisode,
            }}
        >
            {children}
        </PodcastPlayerContext.Provider>
    );
};

PodcastPlayerProviderBase.displayName = 'PodcastPlayerProvider';

export const PodcastPlayerProvider = withPurchaseModal(PodcastPlayerProviderBase);

export const usePodcastPlayer = () => useContext(PodcastPlayerContext);
