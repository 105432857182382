import { AppSettings, QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import { useQuery } from 'react-query';
import { useAppSettingsData } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';

const INITIAL_STALE_TIME = 10 * 10000;

export const useAppConfigQuery = () => {
    const { client, setServiceConfigData } = useAppSettingsData();

    const { isLoading, data, refetch } = useQuery<AppSettings>(
        QUERY_KEYS.config,
        () => client.fetchServiceConfig(),
        {
            staleTime: INITIAL_STALE_TIME,
            onSuccess: (appSetting) => {
                setServiceConfigData({ data: appSetting, isLoading });
            },
        }
    );

    return {
        isLoading,
        appSettings: data,
        refetch,
    };
};
