/* eslint-disable camelcase */
import { createTypeWithNoValidation } from '@24i/nxg-core-utils/src/guards';

export const ReactNodeGuard = createTypeWithNoValidation<React.ReactNode, unknown>('ReactNodeType');

export interface PaginationLink {
    active: boolean;
    label: string | null;
    url: string | null;
}
export interface PaginationResult<T> {
    current_page: number;
    data: [T];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    links: [PaginationLink];
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
}

export interface BackstageApp {
    applicationId: string;
    configuration: string;
    configurationId: string;
    created_at: string;
    name: string;
    serviceId: string;
    templateId: string | null;
    updated_at: string;
}

export interface BackstageService {
    createdAt: number;
    databaseURI: string;
    id: string;
    infrastructureTier: string;
    initialised: boolean;
    isCombined: boolean;
    isConfigurationEmptyFilteringEnabled: boolean;
    logo: string | null;
    name: string;
    organisationId: string;
    snapshotMode: string | null;
    sourceVariants: [string];
    sources: [string];
    updatedAt: number;
    upgradeStatus: string;
    versionLabel: string;
}

export interface Organization {
    created_at: string;
    deleted_at: string | null;
    force_2fa: boolean;
    is_active: boolean;
    logo: string | null;
    nam: string;
    organisationId: string;
    pivot: { userId: string; organisationId: string };
    updated_at: string;
}
