import React from 'react';
import View from './View';
import { useViewModel } from './viewModel';

const Main = (props) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default {
    Main,
};
