import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { PurchaseModalBasicLayoutStyles } from './types';

const getStyles = (theme: Theme): PurchaseModalBasicLayoutStyles => ({
    wrapper: {
        backgroundColor: theme.color.primary1,
        borderRadius: 8,
        paddingBottom: 32,
    },
    titleWrapper: {
        paddingVertical: 24,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        ...getFont(theme, 'h2'),
        color: theme.color.textPrimary,
        textAlign: 'center',
    },
    content: {
        maxHeight: 480,
    },
    subtitle: {
        marginTop: 16,
        paddingHorizontal: 24,
        ...getFont(theme, 'body2'),
        textAlign: 'center',
        color: theme.color.textPrimary,
        opacity: 0.6,
    },
});

export default getStyles;
