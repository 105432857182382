import { createGuard, extendGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import {
    AGE_CLASSIFICATION_TYPE_GUARD,
    AssetGuard,
    AssetImagesGuard,
    ASSET_TYPE_GUARD,
    BLOCKING_REASON_GUARD,
    EditionGuard,
    ExternalAuthDetailsGuard,
    GenreGuard,
    ImageGuard,
    PersonGuard,
    PlaylistGuard,
    ROW_TYPES_GUARD,
    StreamGuard,
} from '@24i/nxg-sdk-photon';
import * as t from 'io-ts';

const AdditionalItemsGuard = createGuard('BackstageAdditionalItems', undefined, {
    textPlacement: nullable(t.string),
    position: nullable(t.string),
    title: nullable(t.string),
    description: t.string,
    images: t.array(
        t.type({
            size: t.type({ width: t.number, height: t.number }),
            url: t.string,
        })
    ),

    target: nullable(t.type({ type: ASSET_TYPE_GUARD, link: nullable(t.string) })),
    type: t.string,
    id: t.string,
});

export const BackstagePageSectionGuard = createGuard(
    'BackstagePageSection',
    {},
    {
        items: t.array(AssetGuard),
        type: nullable(ROW_TYPES_GUARD),
        id: nullable(t.string),
        display: nullable(t.string),
        label: nullable(t.string),
        playlistId: nullable(t.string),
        computedPlaylist: nullable(t.unknown),
        actionForAllItems: nullable(t.union([t.literal('player'), t.literal('detail')])),
        sorting: nullable(
            t.type({
                field: nullable(t.string),
                order: nullable(t.string),
            })
        ),
        _links: nullable(
            t.type({
                href: nullable(t.string),
            })
        ),
        pinnedItems: nullable(t.array(t.string)),
        actions: nullable(t.unknown),
        additionalItems: t.array(AdditionalItemsGuard),
        itemLimit: nullable(t.number),
        isGenre: nullable(t.boolean),
        rowId: nullable(t.string),
    }
);

export type BackstagePageSection = t.TypeOf<typeof BackstagePageSectionGuard>;

export const PagesResponseGuard = t.array(BackstagePageSectionGuard, 'PagesResponse');

export type PagesResponse = t.TypeOf<typeof PagesResponseGuard>;

export const PaginatedResponseGuard = <T extends t.Mixed>(guard: T) =>
    createGuard('PaginatedResponse', {
        externalLink: nullable(t.string),
        total: nullable(t.number),
        size: nullable(t.number),
        offset: nullable(t.number),
        items: t.array(guard),
    });

export interface PaginatedResponse<T> {
    externalLink?: string | null;
    total?: number | null;
    size?: number | null;
    offset?: number | null;
    items: T[];
}

export const ItemsResponseGuard = <T extends t.Mixed>(guard: T) =>
    createGuard('ItemsResponse', {
        items: t.array(guard),
    });

export interface ItemsResponse<T> {
    items: T[];
}

export const EditionsResponseGuard = t.array(EditionGuard, 'EditionResponse');
export const PlaylistResponseGuard = PlaylistGuard;
export const SearchAssetResponseGuard = t.array(AssetGuard, 'SearchAssetResponse');

export const BlockItemGuard = createGuard('BlockItem', undefined, {
    reason: nullable(BLOCKING_REASON_GUARD),
    message: nullable(t.string),
    resolvement: t.partial({
        action: nullable(t.string),
        data: t.partial({
            subscriptionIds: t.UnknownArray,
        }),
    }),
});

export const AgeIndicatorGuard = createGuard('AgeIndicator', undefined, {
    id: t.string,
    system: AGE_CLASSIFICATION_TYPE_GUARD,
    label: t.string,
    description: nullable(t.string),
    images: t.array(ImageGuard),
});

export const AgeClassificationGuard = createGuard('AgeClassification', undefined, {
    type: AGE_CLASSIFICATION_TYPE_GUARD,
    rating: nullable(t.string),
    description: nullable(t.string),
    indicators: t.array(AgeIndicatorGuard),
});

export const BackstageAssetGuard = createGuard(
    'Asset',
    {
        id: t.string,
        type: ASSET_TYPE_GUARD,
    },
    {
        duration: nullable(t.number),
        year: nullable(t.number),
        minimumPrice: nullable(t.unknown),
        clipIds: nullable(t.array(t.string)),
        external_id: nullable(t.string),
        externalAuthDetails: nullable(ExternalAuthDetailsGuard),
        label: nullable(t.string),
        description: nullable(t.string),
        shortDescription: nullable(t.string),
        short_description: nullable(t.string),
        images: nullable(AssetImagesGuard),
        genres: nullable(t.array(GenreGuard)),
        rating: nullable(t.union([t.string, t.number])),
        crew: nullable(t.array(PersonGuard)),
        releaseDate: nullable(t.string),
        isHidden: nullable(t.boolean),
        blocked: nullable(t.array(BlockItemGuard)),
        seasonNumber: nullable(t.number),
        episodeNumber: nullable(t.number),
        seriesName: nullable(t.string),
        series: nullable(t.string),
        startsAt: nullable(t.number),
        start: nullable(t.number),
        endsAt: nullable(t.number),
        end: nullable(t.number),
        number: nullable(t.number),
        geoblock: createGuard('Geoblock', undefined, {
            deny: t.array(t.string),
            allow: t.array(t.string),
        }),
        advertisementStrategyId: nullable(t.string),
        checksum: nullable(t.string),
        sourceId: nullable(t.string),
        translations: nullable(t.unknown),
        ageClassification: AgeClassificationGuard,
        subscriptionTags: t.array(
            createGuard('SubscriptionTags', undefined, {
                id: nullable(t.string),
            })
        ),
        windows: t.array(
            createGuard('Windows', undefined, {
                blocked: nullable(t.boolean),
                blockedMessage: nullable(t.string),
                countries: t.array(t.string),
                applications: t.array(t.string),
                startsAt: nullable(t.number),
                endsAt: nullable(t.number),
                title: nullable(t.string),
            })
        ),
        tags: nullable(t.array(t.string)),
        updatedByUserAt: nullable(t.number),
        createdAt: nullable(t.number),
        updatedAt: nullable(t.number),
        channelId: nullable(t.string),
        channel: nullable(
            // can full asset
            t.type({
                id: t.string,
                number: nullable(t.number),
            })
        ),
        channelLogo: nullable(t.string),
        // TODO: These are duplicate values from image property.
        // We shuld either use these or 'images' not both.
        poster: nullable(t.string),
        still: nullable(t.string),
        background: nullable(t.string),
        subtitle: nullable(t.string),
        title: nullable(t.string),
        // There is a bug in BS. This sometimes returns a number and sometimes a string. They will take a look into this. It should be always a number.
        // https://amino24i.slack.com/archives/C66TGFGGL/p1632145918086700
        continueWatchingOffset: nullable(t.number),
        continueWatchingLastTime: nullable(t.number),
        stream: StreamGuard,
        progress: nullable(t.number),
        isLive: nullable(t.boolean),
        enableCatchUp: nullable(t.boolean),
        editionId: nullable(t.string),
        status: t.string,
        assetLabel: nullable(t.string),
        allowsRestart: nullable(t.boolean),
        allowsTimeshift: nullable(t.boolean),
        allowsRecording: nullable(t.boolean),
        isTrailer: nullable(t.boolean),
        seriesId: nullable(t.string),
        textPlacement: nullable(t.string),
        target: nullable(
            t.type({
                type: ASSET_TYPE_GUARD,
                link: nullable(t.string),
            })
        ),
        position: nullable(t.string),
        isAdult: nullable(t.boolean),
    }
);

const feature = createGuard('feature', {
    enabled: nullable(t.boolean),
    options: nullable(t.type({ trickplay: nullable(t.boolean), window: nullable(t.number) })),
});

const features = createGuard('features', {
    catchup: nullable(feature),
    live: nullable(feature),
    npvr: nullable(feature),
});

export const BackstageBroadcastGuard = extendGuard(
    'Broadcast',
    BackstageAssetGuard,
    {
        channelId: t.string,
        startsAt: t.number,
        endsAt: t.number,
    },
    {
        channelFeatures: nullable(features),
    }
);

export const BackstagePlaylistGuard = createGuard('Playlist', {
    externalLink: nullable(t.string),
    total: nullable(t.number),
    size: nullable(t.number),
    offset: nullable(t.number),
    items: t.array(BackstageAssetGuard),
});

export const BackstageEpisodeGuard = extendGuard(
    'Episode',
    BackstageAssetGuard,
    {
        episodeNumber: t.number,
        seasonNumber: t.number,
        series: t.string,
        seriesType: t.keyof({
            podcast: null,
            series: null,
        }),
    },
    {
        seriesId: t.string,
        nextEpisodeId: nullable(t.string),
        podcastName: nullable(t.string),
        seriesName: t.string,
    }
);

export type BackstagePlaylist = t.TypeOf<typeof BackstagePlaylistGuard>;

export type BackstageAsset = t.TypeOf<typeof BackstageAssetGuard>;
export type BlockedItem = t.TypeOf<typeof BlockItemGuard>;
export type BackstageEpisode = t.TypeOf<typeof BackstageEpisodeGuard>;
export type BackstageBroadcast = t.TypeOf<typeof BackstageBroadcastGuard>;
export type Classification = t.TypeOf<typeof AgeClassificationGuard>;
export type Indicator = t.TypeOf<typeof AgeIndicatorGuard>;
