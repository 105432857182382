import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { NoSubscriptionsStyles } from './types';

export const getStyles = (theme: Theme): NoSubscriptionsStyles => ({
    wrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.color.background1,
    },
    cartIconWrapper: {
        borderRadius: 80,
        backgroundColor: theme.color.darker1,
        padding: 16,
    },
    infoWrapper: {
        marginTop: 24,
        alignItems: 'center',
    },
    noSubscriptionsTitle: {
        ...getFont(theme, 'h4'),
        color: theme.color.textPrimary,
        opacity: 0.8,
    },
    subscriptionsShowUpHereLabelWrapper: {
        marginTop: 8,
    },
    subscriptionsShowUpHereLabel: {
        ...getFont(theme, 'body2'),
        color: theme.color.textPrimary,
        opacity: 0.4,
    },
    addSubscriptionButtonWrapper: {
        marginTop: 48,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonWrapper: {
        marginTop: 40,
        width: 'auto',
        paddingVertical: 14,
        minHeight: 52,
        paddingRight: 26,
        paddingLeft: 26,
    },
    buttonText: {
        ...getFont(theme, 'secondaryButton'),
        color: theme.color.textButtonPrimary,
        marginLeft: 0,
        marginRight: 0,
    },
});
