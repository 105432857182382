export const globalStyles = `html,
body,
div#__next {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
}

#__next {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 1 auto;
}

a {
    color: inherit;
    text-decoration: none;
}

input {
    outline: none;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 350ms ease-in-out;
}

.ReactModal__Overlay__Search {
    opacity: 0;
    transition: opacity 350ms ease-in-out;
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Body--open__hidden {
    overflow-y: hidden;
}

.ReactModal__Body--open {
    overflow-y: scroll;
}

.ReactModal__Body--open::-webkit-scrollbar {
    width: auto;
}

.ReactModal__Body--open::-webkit-scrollbar-track {
    background-color: rgba(31, 31, 31, 0.9));
}

.ReactModal__Body--open::-webkit-scrollbar-thumb {
    background-color: rgba(31, 31, 31, 0.9);
}

`;
