import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';

const getStyles = (theme: Theme) => ({
    container: { flexDirection: 'row', alignItems: 'center' },
    profileImage: {
        width: 48,
        height: 48,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    imageContainer: {
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        overflow: 'hidden',
    },
    profileText: {
        padding: 12,
        ...getFont(theme, 'h4'),
        color: theme.color.textPrimary,
    },
});

export default getStyles;
