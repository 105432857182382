import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { SettingsScreenScreenProps, ViewModelReturnType } from '../types';
import { useCurrentWebPageConfig } from '../../../hooks/useCurrentWebPageConfig';
import { useMenuConfigQuery } from '../../../hooks/query/useMenuConfigQuery';

const useViewModel = ({ route, ...props }: SettingsScreenScreenProps): ViewModelReturnType => {
    const { t } = useTranslation();
    const { currentPageConfig } = useCurrentWebPageConfig();
    const { menuConfig } = useMenuConfigQuery();

    const getFilteredOptions = (initialOptions, filterOptions) =>
        // map object tree to array of objects and filter options shown based on platform
        filterOptions(
            Object.entries(initialOptions).map((option) => {
                const [id, optionProperties] = option;
                return { id, ...(optionProperties as Record<string, unknown>) };
            })
        );

    const getSubmenuTitle = (): string => {
        /**  WORKAROUND: For this submenu screen, menu title in mobile shouldn't come through params because it includes the language option.
         *   When it is in params, won't get updated when navigating back on language change because perserves the initial params state. Getting the screen menuTitle from
         *   menuConfig will always get the latest and translated menuTitle. */
        const mobileMenuTitle = menuConfig?.find((item) => item.name === route?.name)?.menuTitle;

        const webMenuTitle = currentPageConfig?.initialParams?.title as string | undefined;

        const subMenuTitle: string | undefined = isPlatformWeb ? webMenuTitle : mobileMenuTitle;

        return subMenuTitle || t('settings.title');
    };
    return {
        ...props,
        subMenuTitle: getSubmenuTitle(),
        getFilteredOptions,
    };
};

export { useViewModel };
