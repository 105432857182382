import React, { ReactElement } from 'react';
import { TextInput, Keyboard } from 'react-native';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';
import { getSearchInputStyles } from './styles';
import { SearchInputProps } from './types';

export const SearchInput = (props: SearchInputProps): ReactElement => {
    const { theme, handleSearchStringChange, searchString } = props;
    const styles = getSearchInputStyles(theme);
    const { t } = useTranslation();

    return (
        <TextInput
            value={searchString || ''}
            style={styles.container}
            placeholder={t('adobePrimetime.searchTVProvider') as string}
            // FIXME: check opacity for placeholder
            placeholderTextColor={
                isPlatformWeb ? theme.color.textPrimary : theme.color.textSecondary
            }
            onChangeText={handleSearchStringChange}
            onSubmitEditing={Keyboard.dismiss}
            autoCapitalize="none"
        />
    );
};
