import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs/src/StyleSheet';
import { DynaRowStyles } from '../types';

const getDynaRowScreenStyles = (theme: Theme): DynaRowStyles => ({
    app: {
        backgroundColor: theme.color.background1,
        flex: 1,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
    },
    browseTitleContainer: {
        marginTop: 102,
        height: 63,
        paddingTop: 7,
        paddingBottom: 14,
        paddingLeft: 48,
    },
    heroSliderTitleContainer: {
        marginTop: 102,
        height: 63,
        paddingTop: 7,
        paddingBottom: 14,
        paddingLeft: 48,
        position: 'absolute',
        zIndex: 9999,
    },
    browseTitle: {
        // @ts-expect-error
        color: theme.color.textPrimary,
        ...getFont(theme, 'h2'),
    },
    holder: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    wrapper: {
        flex: 1,
    },
    activityIndicator: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default getDynaRowScreenStyles;
