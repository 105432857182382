import { Platform } from 'react-native';
import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { ClassificationsStyles } from '../types';

const getStyles = (theme: Theme): ClassificationsStyles =>
    Platform.select({
        ios: {
            image: {
                height: Platform.isTV ? 33 : 18,
                width: Platform.isTV ? 33 : 18,
            },
            imageView: {
                width: Platform.isTV ? 40 : 22,
                height: Platform.isTV ? 40 : 18,
            },
            labelView: {
                marginHorizontal: Platform.isTV ? 2 : 1,
            },
            row: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
            },
            separator: {
                color: `${theme.color.textPrimary}99`,
                ...getFont(theme, 'h4'),
            },
        },
        android: {
            image: {
                height: 18,
                width: 18,
            },
            imageView: {
                width: 22,
                height: 18,
            },
            labelView: {
                marginHorizontal: 1,
            },
            row: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
            },
            separator: {
                color: `${theme.color.textPrimary}99`,
                ...getFont(theme, 'h4'),
            },
        },
        default: {
            imageView: {
                width: 24,
                height: 20,
            },
            image: {
                height: 18,
                width: 18,
            },
            labelView: {
                marginHorizontal: 1,
            },
            row: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
            },
            separator: {
                color: `${theme.color.textPrimary}99`,
                ...getFont(theme, 'h4'),
            },
        },
    });

export default getStyles;
