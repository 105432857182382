import { timeText } from '@24i/nxg-core-utils';

export default class Cell {
    // this model needs to be properly typed later
    data: any;
    height: number | null;
    width: number | null;
    x: number | null;
    y: number | null;
    selector: string | null;
    modelType: string | null;
    locked: boolean;

    constructor({
        data,
        height = null,
        width = null,
        x = null,
        y = null,
        selector = null,
        modelType = null,
        isLocked = false,
    }: {
        data: any;
        height: number | null;
        width: number | null;
        x: number | null;
        y: number | null;
        selector: string | null;
        modelType: string | null;
        isLocked: boolean;
    }) {
        this.data = data;
        this.height = height;
        this.width = width;
        this.x = x;
        this.y = y;
        this.selector = selector;
        this.modelType = modelType;
        this.locked = isLocked;
    }

    getSelector() {
        return this.selector;
    }

    getWidth() {
        return this.width;
    }

    getHeight() {
        return this.height;
    }

    getX() {
        return this.x;
    }

    getY() {
        return this.y;
    }

    isLocked() {
        return this.locked;
    }

    getLandscapeTheme() {
        return this.data.theme;
    }

    getPortraitTheme() {
        return this.data.portraitTheme;
    }

    /**
     * @return {[Number]}
     */
    getChannelId() {
        return this.data.channelId;
    }

    /**
     * @return {[String]}
     */
    getChannelName() {
        return this.data.channelname;
    }

    /**
     * @return {[Number]}
     */
    getId() {
        return this.data.id;
    }

    /**
     * @return {[String]}
     */
    getDescription() {
        return this.data.longDescription;
    }

    /**
     * @return {[String]}
     */
    getTitle() {
        return this.data.name || this.data.title;
    }

    /**
     * @return {[Number]}
     */
    getCreationYear() {
        return this.data.creationYear;
    }

    /**
     * @return {[String]}
     */
    getSubtitle() {
        return this.data.name;
    }

    /**
     * @return {[String]}
     */
    getEpisodeName() {
        return this.data.episodeTitle;
    }

    /**
     * @return {[String]}
     */
    getSeriesName() {
        return this.data.seriesTitle;
    }

    /**
     * @return {[Number]}
     */
    getSeasonNumber() {
        return this.data.seasonNumber;
    }

    /**
     * @return {[Number]}
     */
    getEpisodeNumber() {
        return this.data.episodeNumber;
    }

    /**
     * @return {[Number]}
     */
    getStart() {
        return this.data.start;
    }

    /**
     * @return {[Number]}
     */
    getStartTime() {
        return this.data.start;
    }

    /**
     * @return {[Number]}
     */
    getEnd() {
        return this.data.end;
    }

    /**
     * @return {[Number]}
     */
    getEndTime() {
        return this.data.end;
    }

    /**
     * @return {[String]}
     */
    getTimeText(is12HourClock = false) {
        const text = timeText(new Date(this.data.start), new Date(this.data.end), is12HourClock);
        return text;
    }

    /**
     * @return {[Number]}
     */
    getDuration() {
        return Math.floor(Math.abs(this.data.end - this.data.start) / 60000);
    }

    /**
     * @return {[Array]}
     */
    getImages() {
        return this.data.images || [];
    }

    /**
     * @return {[Array]}
     */
    getIcons() {
        return this.data.icons || [];
    }

    /**
     * @return {[Array]}
     */
    getIconsAsString() {
        return this.data.iconsAsString || [];
    }

    /**
     * @return {[String]}
     */
    getImageUrl() {
        return this.data.images && this.data.images.length ? this.data.images[0] : '';
    }

    /**
     * @return {[Array]}
     */
    getActors() {
        return this.data.actors || [];
    }

    getGenre() {
        return this.data.category;
    }

    /**
     * @return {[Object]}
     */
    getRawData() {
        return this.data;
    }

    /**
     * @return {[String]}
     */
    getChannelLogoUrl() {
        return this.data.channelLogoUrl || '';
    }

    /**
     * @return {[String]}
     */
    getParentalRating() {
        return this.data.parentalRating || '';
    }

    /**
     * @return {[String]}
     */
    getAdultRating() {
        return this.data.adult || '';
    }
}
