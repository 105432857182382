import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { TextStyle, ViewStyle } from 'react-native';

const styles = (theme) => ({
    mainContainer: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    modal: { margin: 0 },
    image: { flex: 1 },
    headerContainer: {
        justifyContent: 'center',
        flexDirection: 'column',
        marginHorizontal: '5%',
        marginBottom: 16,
    },
    title: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        marginBottom: 3,
        ...getFont(theme, 'h3'),
    } as TextStyle,
    subtitle: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        ...getFont(theme, 'h5'),
    } as TextStyle,
    roundedModalContainer: {
        height: '85%',
        borderTopLeftRadius:
            getBorderRadius(theme.elements, 'generalTile-cornerRadius').borderRadius ?? 36,
        borderTopRightRadius:
            getBorderRadius(theme.elements, 'generalTile-cornerRadius').borderRadius ?? 36,
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    borderNotchContainer: {
        height: 4,
        width: 28,
        backgroundColor: theme.color.contrastBlur2?.color,
        zIndex: 999,
        alignSelf: 'center',
        marginTop: 8,
        marginBottom: 30,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    linearGradientStyles: {
        height: '30%',
        left: 0,
        opacity: 0.9,
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    } as ViewStyle,
    metadataContainer: {
        justifyContent: 'space-between',
        zIndex: 999,
        height: '100%',
    },
    castedDeviceContainer: {
        zIndex: 999,
        backgroundColor: theme.color.primary3,
        alignSelf: 'center',
        marginTop: 16,
        borderRadius: 30,
        alignItems: 'center',
        flexDirection: 'row',
        paddingRight: 14,
        paddingVertical: 11,
    },
    castIconStyles: {
        marginLeft: 14,
        marginRight: 8,
    },
    castedToTextStyles: {
        fontWeight: '500',
        ...getFont(theme, 'body1'),
        color: theme.color.textPrimary,
    } as TextStyle,
});

export default styles;
