import React, { useState, forwardRef } from 'react';
import { TextInput, View, Interactable } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { InputProps, InputRefType } from './types';
import getInputStyles from './styles';

const Input = forwardRef<InputProps, InputRefType>((props, ref) => {
    const {
        additionalInputStyle,
        styles: getStyles = getInputStyles,
        additionalContainerStyle,
        renderVisibilitySwitch,
        secureTextEntry = false,
        ...restProps
    } = props;
    const [secure, setSecure] = useState<boolean>(secureTextEntry);
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={[styles.container, additionalContainerStyle]} ref={ref}>
            <TextInput
                {...restProps}
                style={[styles.input, additionalInputStyle]}
                secureTextEntry={secure}
            />
            {typeof renderVisibilitySwitch === 'function' && (
                <Interactable
                    onPress={() => setSecure(!secure)}
                    style={styles.visibilitySwitchContainer}
                >
                    {renderVisibilitySwitch(secure)}
                </Interactable>
            )}
        </View>
    );
});
Input.displayName = 'Input';
export default Input;
