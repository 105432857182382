import React from 'react';
import { useTranslation } from 'react-i18next';
import View from './View';
import useViewModel from './viewModel';
import ManageButton from './components/ManageButton';

export default {
    Main: Object.assign(
        (props) => {
            const viewModelProps = useViewModel(props);
            const { t } = useTranslation();
            const { isEditing, data } = viewModelProps;

            const renderActionButton = () => {
                if (data?.length) {
                    return (
                        <ManageButton
                            {...viewModelProps}
                            title={isEditing ? t('common.done') : t('favorites.manage.edit.title')}
                            onEdit={viewModelProps.handleEditButtonPress}
                        />
                    );
                }
                return null;
            };
            return (
                <View
                    webHeaderTitle={viewModelProps.topBarTitle}
                    {...viewModelProps}
                    renderActionButton={renderActionButton}
                    fullWidth
                />
            );
        },
        { displayName: 'MyList' }
    ),
    View,
    useViewModel,
};
