import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle, ViewStyle, ModalProps as ReactNativeModalProps } from 'react-native';

export type AnimationTypes = 'none' | 'slide' | 'fade';

interface WithTransition extends ViewStyle {
    transition?: AnimationTypes;
}

export interface ModalStyles {
    modal: ViewStyle;
    closeBtn: ViewStyle & { color: string };
    roundCloseBtn?: ViewStyle;
    titleContainer: ViewStyle;
    border: ViewStyle;
    blurView?: ViewStyle;
    blurBackground?: ViewStyle;
    imageBackground?: ViewStyle;
    backdrop?: ViewStyle;
    wrapper?: ViewStyle;
    container?: ViewStyle;
    title: TextStyle;
    subtitle?: TextStyle;
    actionButtonContainer?: ViewStyle;
    tertiaryButtonContainer?: ViewStyle;
    actionButtonText?: TextStyle;
    contentContainer?: ViewStyle;
    holder?: ViewStyle;
    fixedBackground?: ViewStyle;
    modalBackground?: ViewStyle;
    closeIcon?: TextStyle;
    backButton?: ViewStyle;
    overlay?: WithTransition;
    content?: ViewStyle;
}

export interface ModalProps extends ReactNativeModalProps {
    styles?: (theme: Theme, isLandscape?: boolean) => ModalStyles;
    modalTitle?: string | null;
    onRequestClose?: () => void;
    visible?: boolean;
    roundCloseButton?: boolean;
    blurType?:
        | 'xlight'
        | 'light'
        | 'dark'
        | 'chromeMaterial'
        | 'material'
        | 'thickMaterial'
        | 'thinMaterial'
        | 'ultraThinMaterial'
        | 'chromeMaterialDark'
        | 'materialDark'
        | 'thickMaterialDark'
        | 'thinMaterialDark'
        | 'ultraThinMaterialDark'
        | 'chromeMaterialLight'
        | 'materialLight'
        | 'thickMaterialLight'
        | 'thinMaterialLight'
        | 'ultraThinMaterialLight'
        | 'regular'
        | 'prominent'
        | 'extraDark';
    blurAmount?: number;
    disableBlurAndroid?: boolean;
    animationType?: AnimationTypes;
    transparent?: boolean;
    children: React.ReactNode;
    theme?: Record<string, unknown>;
    modalSafeAreaViewStyle?: ViewStyle;
    lightBackground?: boolean;
    isBackButtonVisible?: boolean;
    onBackPress?: () => void;
    noMargins?: boolean;
    name?: string;
    overrideDisplayIcon?: boolean;
}

export enum MODAL_TEST_IDS {
    CLOSE_BUTTON = 'modal_close_button',
    MODAL = 'modal-test-id',
}
