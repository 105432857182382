/* eslint-disable react/display-name */
import React, { forwardRef, Ref, ReactNode } from 'react';
import { ImageBackground as RNImageBackground } from 'react-native-web';
import StyleSheet from 'react-native-media-query';
import { Props, Styles } from './types';

const ImageBackground = forwardRef<Ref<ReactNode>, Props<Styles>>(({ style, ...props }, ref) => {
    const { ids, styles } = StyleSheet.process(style);
    return <RNImageBackground ref={ref} style={styles} dataSet={{ media: ids }} {...props} />;
});

export default ImageBackground;
