import React, { createContext, useContext } from 'react';

const LayoutContext = createContext();

export const LayoutProvider = ({ client, children }) => (
    <LayoutContext.Provider
        value={{
            ...client,
        }}
    >
        {children}
    </LayoutContext.Provider>
);

export const useLayout = () => useContext(LayoutContext);

export const withLayout = (Component) => (props) =>
    (
        <LayoutContext.Consumer>
            {(context) => <Component {...props} {...context} />}
        </LayoutContext.Consumer>
    );

export default LayoutProvider;
