import React, { createContext, useContext } from 'react';

import { getDummyClient, AnalyticsDataClient } from '@24i/nxg-sdk-photon';

const defaultClient = getDummyClient<AnalyticsDataClient>();

export const AnalyticsDataContext = createContext<AnalyticsDataClient>(defaultClient);

export const AnalyticsDataProvider = ({ client = defaultClient, children }) => (
    <AnalyticsDataContext.Provider value={client}>{children}</AnalyticsDataContext.Provider>
);

export const useAnalyticsData = () => useContext(AnalyticsDataContext);
