import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { ViewStyle } from 'react-native';
import { isPlatformWeb } from 'renative';
import { ChangeableTextPropsStyles } from '../types';

const getStyles = (theme: Theme): ChangeableTextPropsStyles => ({
    titleText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h6'),
        opacity: 0.6,
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 6,
        alignItems: 'center',
    },
    contentText: {
        color: theme.color.textPrimary,
        marginTop: 5,
        maxWidth: '85%',
        ...getFont(theme, 'h4'),
        flex: 1,
    },
    buttonText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h6'),
    },
    itemDivider: {
        marginTop: 16,
        marginBottom: DeviceInfo.isTablet() ? 45 : 30,
        height: 1,
        width: '100%',
        backgroundColor: theme.color.contrast4,
    },
    description: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        marginBottom: 50,
        ...getFont(theme, 'body2'),
        textAlign: !isPlatformWeb ? 'center' : null,
        maxWidth: DeviceInfo.isTablet() ? 335 : null,
        marginLeft: DeviceInfo.isTablet() ? 'auto' : null,
        marginRight: DeviceInfo.isTablet() ? 'auto' : null,
    } as ViewStyle,
});

export default getStyles;
