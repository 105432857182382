import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { RecordModalStyles } from './types';

const getRecordModalStyles = (theme: Theme): RecordModalStyles => ({
    title: {
        color: theme.color.textPrimary,
        marginBottom: 24,
        marginHorizontal: 24,
        textAlign: 'center',
        ...getFont(theme, 'h2'),
    },
    description: {
        color: theme.color.contrast3,
        textAlign: 'center',
        marginHorizontal: 32,
        marginBottom: 32,
        ...getFont(theme, 'body2'),
    },
    thisButtonContainer: {
        width: 185,
        height: 54,
        marginBottom: 24,
        alignSelf: 'center',
    },
    futureButtonContainer: {
        width: 'auto',
        height: 54,
        alignSelf: 'center',
    },
    deleteButtonContainer: {
        width: 244,
        height: 54,
        marginBottom: 24,
        alignSelf: 'center',
    },
    cancelButtonContainer: {
        height: 40,
        alignSelf: 'center',
        paddingRight: 32,
    },
});

export default getRecordModalStyles;
