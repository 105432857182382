/*
@PRODUCT_FEATURE: C 1.7.0
*/
import React from 'react';
import View from './View';

const Main = () => {
    return <View />;
};

export default {
    Main,
};
