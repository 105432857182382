/* eslint-disable no-alert */
import { useEffect, useState } from 'react';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { isPlatformWeb } from 'renative';

export const useViewModel = (props) => {
    const { route } = props;
    const contentDataClient = useContentData();
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState([]);
    const router = useRouter();

    const getPageData = async () => {
        try {
            const pageId = isPlatformWeb ? router.query.id : route?.params?.reference;
            const data = await contentDataClient.fetchTextPageContent(pageId);

            if (data) {
                const pageContent = data.children.filter((children) => children.content !== null);
                if (pageContent.length > 0) {
                    setContent(pageContent[0]);
                }
                setIsLoading(false);
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };

    useEffect(() => {
        getPageData();
    }, []);

    return {
        ...props,
        isLoading,
        content,
    };
};
