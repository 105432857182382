import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs/src';
import { ForgotPasswordWebStyles } from '../types';

const getStyles = (theme: Theme): ForgotPasswordWebStyles => ({
    wrapper: {
        flex: 1,
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    container: {
        minHeight: 342,
        width: 384,
        backgroundColor: theme.color.primary1,
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
        alignItems: 'center',
        width: '100%',
    },
    backArrowContainer: { position: 'absolute', left: 20 },
    headerText: {
        width: '100%',
        textAlign: 'center',
        textAlignVertical: 'center',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h2'),
    },
});

export default getStyles;
