import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import type { UseMVPDRouteParams } from './types';

export const useMVPDRouteParams = (): UseMVPDRouteParams => {
    const router = useRouter();
    const { query } = router;
    return {
        didInitialise: query.didInitialise === 'true',
        shouldSignOut: query.shouldSignOut === 'true',
        redirectToLanding: query.didInitialise !== undefined && query.didInitialise === 'false',
    };
};
