import { TFunction } from 'i18next';
import { ACCOUNT_DETAIL_KEY } from '../types';

export const getSuccessToastMessage = (key: ACCOUNT_DETAIL_KEY, t: TFunction): string => {
    switch (key) {
        case ACCOUNT_DETAIL_KEY.FIRST_NAME:
            return `${t('account.accountDetails.firstName')} ${t(
                'account.accountDetails.changeItem.success'
            )}`;
        case ACCOUNT_DETAIL_KEY.LAST_NAME:
            return `${t('account.accountDetails.lastName')} ${t(
                'account.accountDetails.changeItem.success'
            )}`;
        case ACCOUNT_DETAIL_KEY.EMAIL:
            return `${t('account.accountDetails.email')} ${t(
                'account.accountDetails.changeItem.success'
            )}`;
        default:
            return `Item ${t('account.accountDetails.changeItem.success')}`;
    }
};

export const getErrorMessage = (key: ACCOUNT_DETAIL_KEY, t: TFunction): string => {
    switch (key) {
        case ACCOUNT_DETAIL_KEY.FIRST_NAME:
            return `${t('error.A04.body') + t('account.accountDetails.firstName').toLowerCase()}`;
        case ACCOUNT_DETAIL_KEY.LAST_NAME:
            return `${t('error.A04.body') + t('account.accountDetails.lastName').toLowerCase()}`;
        case ACCOUNT_DETAIL_KEY.EMAIL:
            return `${t('error.E18.body')}`;
        default:
            return `${t('error.A00.body')}`;
    }
};
