import { UsePushNotifications } from 'packages/sdk@smartott/src/hooks/usePushNotifications/types';
import { ReactNode } from 'react';

export const useRemotePushNotifications = () => {
    throw new Error('This is not usable in your current context');
};

type Props = {
    children: ReactNode;
    localPushNotifications?: UsePushNotifications;
};

export const RemotePushNotificationsProvider = ({ children }: Props) => children;
