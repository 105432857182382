import { useQuery, UseQueryOptions } from 'react-query';
import { AvailableAssetPurchasesResponse, Product, PurchasesProps } from '@24i/nxg-sdk-photon/src';
import { Purchase } from '@24i/nxg-sdk-photon/src/models/purchase';
import { Rent } from '@24i/nxg-sdk-photon/src/models/rent';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import { queryKeys } from '../queryKeys';

const useAvailableAssetPurchasesQuery = (
    asset: PurchasesProps,
    options?: UseQueryOptions<unknown, Error, AvailableAssetPurchasesResponse>
) => {
    const { fetchAvailableAssetPurchases } = usePurchaseData();
    const reactQuery = getRuntimeConfig('reactQuery');

    return useQuery<unknown, Error, { availablePurchases: Array<Purchase | Rent | Product> }>(
        queryKeys.assetPurchases(asset?.id),
        () => fetchAvailableAssetPurchases(asset),
        {
            staleTime: reactQuery.staleTime.short,
            enabled: Boolean(asset),
            ...options,
        }
    );
};

export default useAvailableAssetPurchasesQuery;
