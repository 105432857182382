import { useQuery } from 'react-query';
import { Channel, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useEpgData } from '@24i/nxg-sdk-smartott-shared/src/context/EpgData';

const useChannelsQuery = () => {
    const { getChannels } = useEpgData();

    return useQuery<Channel[] | undefined>(QUERY_KEYS.channels, getChannels);
};

export default useChannelsQuery;
