import { I18nManager } from 'react-native';

export type UseIsRTL = () => boolean;

export const useIsRTL = (): UseIsRTL => {
    const isRTL = (): boolean => {
        return I18nManager.isRTL;
    };

    return isRTL;
};
