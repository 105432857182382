import {
    createGuard,
    decodeModelWith,
    extendGuard,
    nullable,
} from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const ProfileGuard = createGuard('Profile', {
    id: t.string,
    userId: nullable(t.string),
    name: nullable(t.string),
    age: nullable(t.number),
    isPinProtected: nullable(t.boolean),
    defaultProfile: nullable(t.boolean),
    image: nullable(t.string),
    selected: nullable(t.boolean),
});

export type Profile = t.TypeOf<typeof ProfileGuard>;

export const ManageableProfileGuard = extendGuard(
    'ManageableProfile',
    ProfileGuard,
    {
        icon: nullable(t.type({ font: t.string, name: t.string, color: t.string, size: t.number })),
    },
    {}
);

export type ManageableProfile = t.TypeOf<typeof ManageableProfileGuard>;

export const isProfile = (data: unknown): data is Profile => {
    try {
        return !!decodeModelWith(ProfileGuard, data, 'Profile', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createProfile = (data: unknown): Profile | never => {
    return decodeModelWith(ProfileGuard, data, 'Profile');
};

export const createManageableProfile = (data: unknown): ManageableProfile | never => {
    return decodeModelWith(ManageableProfileGuard, data, 'ManageableProfile');
};
