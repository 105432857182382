import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { getMargins } from '@24i/nxg-sdk-higgs';
import { BannerCarouselItemStyles } from './types';

const getBannerCarouselItemStyles = (theme: Theme): BannerCarouselItemStyles => ({
    bannerItemContainer: {
        position: 'relative',
        height: 550,
        [`@media (min-width: ${Breakpoint.SM}px)`]: {
            height: 746,
        },
    },
    slideInnerContainer: {
        paddingHorizontal: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    image: {
        flex: 1,
        backgroundColor: 'transparent',
        width: '100%',
        height: 550,
        [`@media (min-width: ${Breakpoint.SM}px)`]: {
            height: 746,
        },
    },
    textContainer: {
        position: 'absolute',
        height: 746,
        justifyContent: 'flex-end',
        paddingBottom: 280,
        zIndex: 999,
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            paddingHorizontal: 20,
        },
        ...getMargins('padding', '5.5%', true),
    },
    primaryButtonContainer: {
        marginTop: 22,
    },
    primaryButtonContainerWithTitle: {
        height: 54,
        alignSelf: 'flex-start',
        width: 'auto',
    },
    topGradient: {
        height: '30%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    bottomGradient: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    itemContainer: {
        flex: 1,
        width: '100%',
        [`@media (min-width: ${Breakpoint.SM}px)`]: {
            height: 746,
        },
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            height: 550,
        },
        backgroundColor: theme.color.background1,
        left: 0,
    },
    infoButtonContainer: {
        height: 54,
        paddingLeft: 24,
        alignSelf: 'flex-start',
        width: 'auto',
    },
    activityIndicator: { alignSelf: 'flex-start', marginTop: 22 },
    playButtonContainer: { flexDirection: 'row' },
    obscuredImageContainer: {
        height: '60%',
        width: '100%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default getBannerCarouselItemStyles;
