import React from 'react';
import { Interactable } from '@24i/nxg-sdk-quarks';
import Item from './Item';
import Button from './Button';

export const getPosition = ({ buttonDimensions, menuDimensions }) => {
    if (!(buttonDimensions && menuDimensions)) return {};
    const position = {
        top: buttonDimensions.pageY + buttonDimensions.height,
        left: buttonDimensions.pageX,
        horizontal: 'left',
        vertical: 'top',
    };
    const menuBottom = buttonDimensions.pageY + buttonDimensions.height + menuDimensions.height;
    if (menuBottom > buttonDimensions.pageHeight) {
        position.transform = [{ translateY: -(buttonDimensions.height + menuDimensions.height) }];
        position.vertical = 'bottom';
    }
    const menuRight = buttonDimensions.pageX + menuDimensions.width;
    if (menuRight > buttonDimensions.pageWidth) {
        position.transform = [{ translateX: -(menuDimensions.width - buttonDimensions.width) }];
        position.horizontal = 'right';
    }
    return position;
};

export const getIconPosition = ({ horizontal, vertical }, iconSize) => {
    const style = {};
    const iconMargin = 1;

    switch (horizontal) {
        case 'left':
            style.left = '10%';
            break;
        case 'right':
            style.left = '80%';
            break;
        default:
            break;
    }
    switch (vertical) {
        case 'top':
            style.top = -(iconSize - iconMargin);
            break;
        case 'bottom':
            style.top = '100%';
            style.transform = [{ rotateX: '180deg' }];
            break;
        default:
            break;
    }
    return style;
};

/*
 * This function returns children based on data or children properties.
 * The shape of children is based on presence of children and renderItem props.
 */
export const getTransformedChildren = (
    { data, renderItem, onValueChange },
    childrenArray,
    setDropdownOpen
) => {
    const onPress = (i, value) => {
        onValueChange(value, i);
        setDropdownOpen(false);
    };
    if (data) {
        return data.map((itemProps, index) =>
            renderItem ? (
                renderItem(itemProps)
            ) : (
                <Item
                    {...itemProps}
                    onPress={() => {
                        onPress(index, itemProps.value);
                    }}
                />
            )
        );
    }

    return childrenArray
        .filter((child) => child.type === Item)
        .map((child, index) => {
            const generatedKey = `${child.value || index}${child.label || index}`;
            if (child.props.children) {
                const childChild = React.Children.toArray(child.props.children)[0];
                if (childChild.props.onPress) {
                    return React.cloneElement(childChild, {
                        ...childChild.props,
                        onPress: () => {
                            childChild.props.onPress();
                            onPress(index, child.props.value);
                        },
                        key: generatedKey,
                    });
                }
                return (
                    <Interactable
                        onPress={() => {
                            onPress(index, child.props.value);
                        }}
                        key={generatedKey}
                    >
                        {childChild}
                    </Interactable>
                );
            }
            return (
                <Item
                    {...child.props}
                    onPress={() => setDropdownOpen(false)}
                    onValueChange={onValueChange}
                    index={index}
                    key={generatedKey}
                />
            );
        });
};

export const getLabelValueMap = (data, childrenArray) => {
    const reducer = (acc, curr) => {
        acc[curr.value] = curr.label;
        return acc;
    };
    const flattenMap = ({ props: { label, value } }) => ({ label, value });
    const flattenData = data || childrenArray.map(flattenMap);
    return flattenData.reduce(reducer, {});
};

export const getDefaultLabel = ({ data, children }) =>
    data ? data[0].label : children?.[0]?.props.label || '';

export const createButtonComponent = (childrenArray, selectedLabel, setDropdownOpen) => {
    const ButtonComponent = childrenArray.find((child) => child.type === Button);
    if (ButtonComponent) {
        return React.cloneElement(ButtonComponent, { setDropdownOpen });
    }
    return <Button selectedLabel={selectedLabel} setDropdownOpen={setDropdownOpen} />;
};
