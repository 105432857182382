import React from 'react';
import { Text, ThemeIcon, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getStyles from './styles';

export interface RateItemProps {
    rating: number;
}

const RateItem = ({ rating }: RateItemProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    // FIXME: fix icon alignment on web

    return (
        <>
            <View style={styles.icon}>
                <ThemeIcon iconName="onPrimary-rating" size={20} iconStyles={{ opacity: 0.6 }} />
            </View>
            <Text style={styles.text}>{rating.toString()}</Text>
        </>
    );
};

export default RateItem;
