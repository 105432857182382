import React, { ComponentProps, RefObject, useLayoutEffect } from 'react';
import { NativeSyntheticEvent, TextInputSubmitEditingEventData } from 'react-native';
import { withTranslation, useTranslation } from 'react-i18next';

import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButtonWithoutBg';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Text, View } from '@24i/nxg-sdk-quarks';
import TextInputWeb from '@24i/nxg-sdk-quarks/src/components/TextInput/index.web';
import { TextInputRefType } from '@24i/nxg-sdk-quarks/src/components/TextInput/types';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';

import { EnhancedComponent } from '../../../utils/class-components';
import { StylesGetter } from '../../../utils/styles/types';
import getStyles from '../styles';
import { SignInScreenStyles } from '../styles/types';
import withPlatformWrapper from '../viewModel/withPlatformWrapper';
import { SignInScreenViewProps, SIGN_IN_TEST_IDS, AUTH_METHOD, AUTH_ACTION } from './types';
import LoadingWrapper from '../../../components/LoadingWrapper/View/index';
import SigninWebViewContent from '../components/SigninWebView';
import BrowserAuth from '../components/BrowserAuth';

export { getStyles as getSignInScreenStyles };

interface State {
    email: string;
    password: string;
    loginError: null | boolean;
    passVisibility: boolean;
    alertMessage?: string | null;
}
class SigninScreen extends EnhancedComponent<
    SignInScreenViewProps,
    typeof SigninScreen.defaultProps,
    State
> {
    static defaultProps = {
        styles: {},
        isLoading: false,
        displayErrorUnder: true,
        showForgotPassword: true,
        customEmailInputPlaceholderText: null,
        signInToBackstage: false,
        authMethod: AUTH_METHOD.FORM,
    };

    passwordInput: React.MutableRefObject<TextInputRefType | null> = React.createRef();

    emailInput: RefObject<TextInputRefType> = React.createRef();

    constructor(props: SignInScreenViewProps) {
        super(props);

        this.state = {
            email: '',
            password: '',
            loginError: null,
            passVisibility: false,
            alertMessage: '',
        };
    }

    componentDidMount() {
        this.setCustomAlertMsg();
    }

    shouldDisableLoginButton = (email: string, password: string) => !!(!email || !password);

    setCustomAlertMsg = () => {
        const { t } = this.getPropsWithDefaults();

        this.shouldDisableLoginButton('', '');
        this.setState({ alertMessage: t('error.E00.body') });
    };

    handleEmail = (text: string) => {
        this.validationReset();
        this.setState({ email: text });
    };

    handlePassword = (text: string) => {
        this.validationReset();
        this.setState({ password: text });
    };

    handleSignin = async (email: string, password: string) => {
        const { onSigninPress } = this.props;
        const response = onSigninPress ? await onSigninPress(email, password) : null;

        if (!response?.login) {
            this.setState({ loginError: true });
            this.alertBorderColor(this.passwordInput);
            this.alertBorderColor(this.emailInput);
        }
    };

    alertBorderColor = (element: React.MutableRefObject<TextInputRefType | null>) => {
        const { alertColor, theme } = this.props;
        element.current?.setNativeProps({
            style: {
                borderColor: alertColor || theme.color.error,
            },
        });
    };

    transparentBorderColor = (element: React.MutableRefObject<TextInputRefType | null>) => {
        element.current?.setNativeProps({
            style: {
                borderColor: 'transparent',
            },
        });
    };

    handlePassVisibility = () => {
        this.setState((prevState) => ({
            passVisibility: !prevState.passVisibility,
        }));
    };

    textInputActiveStyle = (
        element: React.MutableRefObject<TextInputRefType | null>,
        theme: Theme
    ) => {
        const { activeTextInputStyle } = this.props;
        element.current?.setNativeProps({
            style: activeTextInputStyle || {
                backgroundColor: theme.color.lighter1,
            },
        });
    };

    textInputInactiveStyle = (
        element: React.MutableRefObject<TextInputRefType | null>,
        theme: Theme
    ) => {
        const { inactiveTextInputStyle } = this.props;
        element.current?.setNativeProps({
            style: inactiveTextInputStyle || {
                backgroundColor: theme.color.lighter3,
            },
        });
    };

    validationReset = () => {
        const { loginError } = this.state;
        const { resetAlertMessages } = this.props;
        if (loginError) {
            this.setState(
                {
                    loginError: false,
                },
                () => {
                    if (resetAlertMessages) resetAlertMessages();
                    this.transparentBorderColor(this.emailInput);
                    this.transparentBorderColor(this.passwordInput);
                    this.setCustomAlertMsg();
                }
            );
        }
    };

    handleSubmitEmail = (_e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => {
        this.passwordInput.current?.focus();
    };

    handlePasswordSubmit = () => {
        const { email, password } = this.state;
        this.handleSignin(email, password);
    };

    render() {
        const {
            t,
            theme,
            styles,
            isLoading,
            alertColor,
            alertMessages,
            displayErrorUnder,
            onForgotPasswordPress,
            inactiveTextInputStyle,
            customEmailInputPlaceholderText,
            textInputPlaceholderTextColor,
            signInToBackstage,
        } = this.getPropsWithDefaults();

        const { email, password, loginError, passVisibility, alertMessage } = this.state;
        const alertColorSelector = alertColor || theme.color.error;

        const disabledOpacity = theme.backgroundImage ? 0.7 : 0.4;
        const opacity = this.shouldDisableLoginButton(email, password) ? disabledOpacity : 1;
        const defaultEmailInputPlaceholderText = t('account.accountDetails.emailAddress');

        return (
            <>
                <View style={styles.alertSection}>
                    {loginError && !alertMessages && !displayErrorUnder ? (
                        <View style={styles.alertSectionContent}>
                            <Text style={[styles.alertText, { color: alertColorSelector }]}>
                                {alertMessage}
                            </Text>
                        </View>
                    ) : null}
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <TextInputWeb
                        testID={SIGN_IN_TEST_IDS.EMAIL_INPUT}
                        type="text"
                        style={[
                            styles.textInputView,
                            inactiveTextInputStyle || {
                                backgroundColor: theme.color.lighter3,
                            },
                            styles.textInputEmail,
                        ]}
                        placeholder={
                            customEmailInputPlaceholderText || defaultEmailInputPlaceholderText
                        }
                        placeholderTextColor={
                            textInputPlaceholderTextColor || `${theme.color.textPrimary}4D`
                        }
                        onChange={(e) => {
                            // Adding any not to break anything
                            this.handleEmail((e.target as any).value);
                        }}
                        ref={this.emailInput}
                        onFocus={() => this.textInputActiveStyle(this.emailInput, theme)}
                        onBlur={() => this.textInputInactiveStyle(this.emailInput, theme)}
                        onSubmitEditing={this.handleSubmitEmail}
                    />
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <>
                        <View
                            style={[
                                styles.textInputView,
                                inactiveTextInputStyle || {
                                    backgroundColor: theme.color.lighter3,
                                },
                                styles.textInputPassword,
                            ]}
                            ref={this.passwordInput}
                            onFocus={() => this.textInputActiveStyle(this.passwordInput, theme)}
                            onBlur={() => this.textInputInactiveStyle(this.passwordInput, theme)}
                        >
                            <TextInputWeb
                                testID={SIGN_IN_TEST_IDS.PASSWORD_INPUT}
                                secureTextEntry={!passVisibility}
                                style={styles.textInputPassword}
                                placeholder={t('account.accountDetails.password') as string}
                                placeholderTextColor={
                                    textInputPlaceholderTextColor || `${theme.color.textPrimary}4D`
                                }
                                onChange={(e) => {
                                    // Adding as to any not to break the existing functionality
                                    this.handlePassword((e.target as any).value);
                                }}
                                onSubmitEditing={this.handlePasswordSubmit}
                            />
                            <ActionButton
                                testID={SIGN_IN_TEST_IDS.SHOW_HIDE_BUTTON}
                                title={passVisibility ? t('common.hide') : t('common.show')}
                                onPress={() => this.handlePassVisibility()}
                                additionalContainerStyles={styles.togglePassView}
                                additionalTextStyles={styles.togglePassViewText}
                            />
                        </View>
                    </>
                </View>
                <View style={styles.alertSection}>
                    {!alertMessages && loginError && displayErrorUnder ? (
                        <View style={styles.alertSectionContent}>
                            <Text
                                style={[styles.alertText, { color: alertColorSelector }]}
                                testID={SIGN_IN_TEST_IDS.ERROR_MESSAGE_PASSWORD}
                            >
                                {alertMessage}
                            </Text>
                        </View>
                    ) : (
                        alertMessages && (
                            <View style={{ flexDirection: 'column' }}>
                                {alertMessages.map((message) => (
                                    <View style={styles.alertSectionContent} key={message}>
                                        <Text
                                            style={[
                                                styles.alertText,
                                                {
                                                    color: alertColorSelector,
                                                },
                                            ]}
                                        >
                                            {message}
                                        </Text>
                                    </View>
                                ))}
                            </View>
                        )
                    )}
                </View>
                <LoadingWrapper
                    isLoading={isLoading}
                    loaderProps={{ holderStyles: styles.activityIndicatorContainer }}
                    wrapperStyle={styles.activityIndicatorWrapper}
                >
                    <ActionButton
                        testID={SIGN_IN_TEST_IDS.CONFIRM_BUTTON}
                        title={t('auth.signIn.title')}
                        onPress={() => {
                            this.handleSignin(email, password);
                        }}
                        additionalContainerStyles={[
                            alertMessages && {
                                marginTop:
                                    alertMessages.length > 0 ? 16 * alertMessages.length : 12,
                            },
                            {
                                opacity,
                            },
                            styles.activeSigninButton,
                            styles.signInButton,
                        ]}
                        disabled={this.shouldDisableLoginButton(email, password)}
                        additionalTextStyles={styles.activeSigninButtonText}
                    />
                </LoadingWrapper>
                {onForgotPasswordPress && !signInToBackstage && (
                    <TertiaryButtonWithoutBg
                        testID={SIGN_IN_TEST_IDS.FORGOT_PASSWORD_BUTTON}
                        title={t('password.enter.forgotPassword')}
                        onPress={onForgotPasswordPress}
                        additionalContainerStyles={styles.forgotPassButton}
                        additionalTextStyles={styles.forgotPassButtonText}
                        titleNumberOfLines={2}
                    />
                )}
            </>
        );
    }
}

// Wrapper must get theme and styles, so this is a workaround for now
const WrappedSigninScreen = withPlatformWrapper(SigninScreen);

// This has to be done to preserve all React default props typing magic
// Or we can use JSX.LibraryManagedProps directly
// TODO: DEFAULT_PROPS Introduce more robust solution
type InferredProps = ComponentProps<typeof WrappedSigninScreen>;

// To Do. Remove after refactoring class based component into functional one
// TODO: This also creates a mess in the props typing as one of the views has "styles" and the other "getStyles"
const SignInWithHooks = (
    props: Omit<InferredProps, 'styles' | 'theme' | 'accountRegistrationEnabled'> & {
        styles?: StylesGetter<Theme, SignInScreenStyles>;
    }
) => {
    const { isLoading, authMethod, styles = getStyles, navigation, signInToBackstage } = props;
    const { theme } = useTheme();
    const computedStyles = styles?.(theme) || {};
    const accountRegistrationEnabled = useFeature('accountRegistration');
    const { t } = useTranslation();

    useLayoutEffect(() => {
        navigation?.setOptions({
            title: signInToBackstage
                ? `Backstage ${t('auth.signIn.title')}`
                : t('auth.signIn.title'),
        });
    }, [navigation]);

    const renderContentByAuthMethod = () => {
        switch (authMethod) {
            case AUTH_METHOD.WEBVIEW:
                return <SigninWebViewContent />;
            case AUTH_METHOD.OPENAUTH:
                return <BrowserAuth action={AUTH_ACTION.LOGIN} />;
            case AUTH_METHOD.FORM:
            default:
                return (
                    <WrappedSigninScreen
                        theme={theme}
                        {...props}
                        accountRegistrationEnabled={accountRegistrationEnabled?.enabled}
                        styles={computedStyles}
                        isDefaultLayout={!signInToBackstage}
                    />
                );
        }
    };

    return (
        <LoadingWrapper
            isLoading={Boolean(isLoading)}
            loaderProps={{ holderStyles: computedStyles.activityIndicatorContainer }}
            wrapperStyle={computedStyles.activityIndicatorWrapper}
        >
            {renderContentByAuthMethod() || null}
        </LoadingWrapper>
    );
};

const Overridable = overridable(SignInWithHooks, 'SignInScreen');
export default withTranslation('sott')(Overridable);
