import { isPlatformWeb } from 'renative';

const getStyles = (theme: any, additionalStyle: any, isFocused: boolean) => ({
    container: {
        ...additionalStyle,
        ...theme.container,
        minWidth: additionalStyle.width,
        border: `${theme.container.borderWidth}px solid ${theme.container.borderColor}`,
        whiteSpace: 'nowrap',
        padding: 0,
        paddingHorizontal: 0,
        outline: 'none',
    },
    epgItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        maxWidth: '100%',
        cursor: 'pointer',
        outline: 'none',
        transition: 'padding .3s ease-in-out,max-width .3s ease-in-out,z-index .3s ease-in-out',
        paddingLeft: isFocused
            ? theme.container.paddingHorizontal
            : additionalStyle.width > 55
            ? theme.container.paddingHorizontal
            : 0,
        paddingRight: isFocused
            ? theme.container.paddingHorizontal
            : additionalStyle.width > 55
            ? theme.container.paddingHorizontal
            : 0,
    },
    description: {
        ...theme.description,
        overflow: isFocused ? 'visible' : 'hidden',
        textOverflow: 'ellipsis',
        ...(isPlatformWeb &&
            isFocused && {
                width: 'auto',
            }),
        textAlign: 'justify',
    },
});

export default getStyles;
