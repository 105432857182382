var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.useViewModel=void 0;var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));var _react=_interopRequireDefault(require("react"));

var _reactI18next=require("react-i18next");

var _Modal=require("@24i/nxg-sdk-gluons/src/context/Modal");
var _GenericModal=_interopRequireDefault(require("@24i/nxg-sdk-smartott/src/components/GenericModal"));
var _NextRouter=require("@24i/nxg-core-router/src/NextRouter");
var _constants=require("@24i/nxg-sdk-smartott/src/navigation/constants");
var _shared=require("./shared");var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/components/Settings/SettingsService/viewModel/index.web.tsx";


var useViewModel=function useViewModel(){
var _useShared=






(0,_shared.useShared)(),handleOnSignoutOfBackstage=_useShared.handleOnSignoutOfBackstage,serviceOptions=_useShared.serviceOptions,selectedService=_useShared.selectedService,isBackstageLoginEnabled=_useShared.isBackstageLoginEnabled,signedInWithBackstage=_useShared.signedInWithBackstage,onSelectedOption=_useShared.onSelectedOption;
var _useTranslation=(0,_reactI18next.useTranslation)(),t=_useTranslation.t;
var router=(0,_NextRouter.useRouter)();
var _useModal=(0,_Modal.useModal)(),setModalChildren=_useModal.setModalChildren,closeModal=_useModal.closeModal;

var onLoginToBackstage=function onLoginToBackstage(){
var backstageLogin=_constants.SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink();
router.push(
{pathname:backstageLogin.href,query:{backstage:true}},
backstageLogin.as
);
};

var onSignOutOfBackstage=function onSignOutOfBackstage(){return(
setModalChildren(
_react.default.createElement(_GenericModal.default,{
requiresModalWrapper:false,
defaultHorizontalButtonLayout:true,
title:{
text:t('auth.signOut.title')+" of Backstage"
},
description:{
text:t('auth.signOut.signOutConfirm.title')
},
primaryButton:{
title:t('auth.signOut.title'),
onPress:function onPress(){return _regenerator.default.async(function onPress$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.next=2;return _regenerator.default.awrap(
handleOnSignoutOfBackstage());case 2:
closeModal();case 3:case"end":return _context.stop();}},null,null,null,Promise);}

},
secondaryButton:{
title:t('common.cancel'),
onPress:closeModal
},__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:35,columnNumber:13}}
)
));};

return{
onSignOutOfBackstage:onSignOutOfBackstage,
serviceOptions:serviceOptions,
signedInWithBackstage:signedInWithBackstage,
onLoginToBackstage:onLoginToBackstage,
selectedService:selectedService,
isBackstageLoginEnabled:isBackstageLoginEnabled,
onSelectedOption:onSelectedOption
};
};exports.useViewModel=useViewModel;