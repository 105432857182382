import React, { createContext, useContext } from 'react';

import { getDummyClient } from '@24i/nxg-sdk-photon';
import { AppVersionDataClient } from '@24i/nxg-sdk-photon/src/clients/AppVersionDataClient';

const defaultClient = getDummyClient<AppVersionDataClient>();

export const AppVersionDataContext = createContext<AppVersionDataClient>(defaultClient);

export const AppVersionDataProvider = ({ client = defaultClient, children }) => (
    <AppVersionDataContext.Provider value={client}>{children}</AppVersionDataContext.Provider>
);

export const useAppVersionData = () => useContext(AppVersionDataContext);
