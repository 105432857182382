import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';

const getSettingsDropdownPickerStyles = (theme: Theme) => ({
    selectContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomColor: theme.color.contrast4,
        borderBottomWidth: 0.5,
        marginBottom: 300,
    },
    description: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
    },
    buttonText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h5'),
        opacity: 0.8,
    },
    buttonContainer: {
        backgroundColor: 'transparent',
    },
});

export default getSettingsDropdownPickerStyles;
