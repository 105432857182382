import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    invisible: {
        ...StyleSheet.absoluteFillObject,
        opacity: 0,
    },
    focusTracker: {
        width: 800,
        height: '100%',
        marginTop: 220,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default styles;
