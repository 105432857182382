import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '../../../../utils/styles/constants';
import { AddSubscriptionsContentStyles } from './types';

const styles = (theme: Theme): AddSubscriptionsContentStyles => ({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 80,
    },
    description: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body2'),
        opacity: 0.6,
        textAlign: 'center',
    },
    primaryButton: {
        marginTop: 48,
        width: 335,
        height: 52,
        marginRight: 0,
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: 'auto',
        },
    },
    secondaryButton: {
        marginTop: 42,
    },
    secondaryText: {
        color: theme.color.textPrimary,
    },
});

export default styles;
