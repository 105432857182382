import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { AssetGuard } from './asset';

export const PlaylistGuard = createGuard('Playlist', {
    externalLink: nullable(t.string),
    total: nullable(t.number),
    size: nullable(t.number),
    offset: nullable(t.number),
    items: t.array(AssetGuard),
});

export type Playlist = t.TypeOf<typeof PlaylistGuard>;
