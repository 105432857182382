import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { UseProtectedScreenParams } from '@24i/nxg-sdk-pin-protection/src/hooks/types';
import { WithWebHeaderProps } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader/types';
import { ChangeableTextItemProps } from '../../components/ChangeableText/types';

export type AccountDetailsModelProps = ScreenProps;

export enum ACCOUNT_DETAIL_KEY {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email',
    PASSWORD = 'password',
    PIN_TOOGLE = 'pin-toogle',
    PIN_CHANGE = 'pin-change',
    DELETE_ACCOUNT = 'delete-account',
}

export interface AccountDetailsProps extends WithWebHeaderProps, UseProtectedScreenParams {
    changeableDetails: ChangeableTextItemProps[];
    isModalOpened: boolean;
    errorForModal: string | undefined;
    onDeleteAccountPress: () => void;
    openModal: () => void;
    closeModal: (hasErrorMessage?: boolean) => void;
}
