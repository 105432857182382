/* eslint-disable max-statements */
import React, { useEffect } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from 'react-native';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import CornerLogo from '@24i/nxg-sdk-gluons/src/components/ui/CornerLogo/View/index.web';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { NavScreenConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useAdobePrimetime } from '@24i/adobe-primetime/src';
import getTopBarMenuStyles from '../styles';
import { TopBarMenuItem } from '../../../../components/MenuItem';
import { useMenuItemPress } from '../../../utils';
import { TopBarMenuPropsWeb, TOP_BAR_MENU_TEST_IDS } from './types';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../constants';

const TopBarMenu = ({
    styles: getStyles = getTopBarMenuStyles,
    menuLeft,
    menuRight,
    nativeID,
}: TopBarMenuPropsWeb) => {
    const { asPath } = useRouter();
    const { theme } = useTheme();
    const topMenuRef = React.useRef(null);
    const onPress = useMenuItemPress();
    const adobePrimetimeEnabled = useFeature('adobePrimetime')?.enabled;
    const {
        authState: { authenticated },
    } = useAdobePrimetime();
    const signInEnabled = useFeature('signIn');
    const isSignInEnabled = signInEnabled?.enabled || signInEnabled === undefined;

    const styles = getStyles(theme);

    useEffect(() => {
        const onScroll = () => {
            if (topMenuRef?.current) {
                if (window.pageYOffset > 50) {
                    // @ts-ignore I don't know what generic type should be used in the useRef
                    topMenuRef.current.style.backgroundColor = theme.color.background1;
                } else {
                    // @ts-ignore I don't know what generic type should be used in the useRef
                    topMenuRef.current.style.backgroundColor = 'transparent';
                }
            }
        };
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const getMenuItemComponent = (menuItem: NavScreenConfigWeb, index: number) => {
        const { icon, menuTitle, name, renderWebLink: renderWebLinkComponent } = menuItem;

        if (
            (adobePrimetimeEnabled && authenticated && name?.includes('signin')) ||
            (!isSignInEnabled && name?.includes('signin'))
        )
            return null;

        if (renderWebLinkComponent) {
            return (
                <React.Fragment key={`topbar-menuoption_${index}`}>
                    {renderWebLinkComponent({ menuItem })}
                </React.Fragment>
            );
        }

        return (
            <View key={`topmenu-link-${name}`} style={styles.topBarMenuItemWrapper}>
                <TopBarMenuItem
                    key={`topmenu-${menuTitle}`}
                    title={menuTitle}
                    icon={icon}
                    index={index}
                    active={asPath.split(/[/?]/).includes(name)}
                    spatial
                    onPress={() => onPress(menuItem)}
                />
            </View>
        );
    };

    const homeLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();

    return (
        <View ref={topMenuRef} nativeID={nativeID} style={styles.menuContainerTransparent}>
            <CornerLogo linkProps={homeLink} testID={TOP_BAR_MENU_TEST_IDS.LOGO} />
            <View style={styles.sectionContainer}>
                <View style={styles.section}>{menuLeft.map(getMenuItemComponent)}</View>
                {menuRight && (
                    <View style={styles.rightSection}>{menuRight.map(getMenuItemComponent)}</View>
                )}
            </View>
        </View>
    );
};

export default overridable(TopBarMenu, 'TopBarMenu');
export { TopBarMenu, getTopBarMenuStyles };
