import { UserDataClient } from '@24i/nxg-sdk-photon';

/**
 * The app expects the default fetchUser resolves to null.
 */
export const getDefaultUserDataClient = () =>
    new Proxy(
        {},
        {
            get: function get(_, prop) {
                if (prop === 'fetchUser') {
                    return () => Promise.resolve(null);
                }

                throw new Error(
                    `Acessing '${String(
                        prop
                    )}'. This is dummy UserDataClient implementation. Provide real data client if you want to use this function.`
                );
            },
        }
    ) as UserDataClient;
