const useNavigation = () => ({
    addListener: () => {},
    canGoBack: () => false,
    getParent: () => {},
    getState: () => {},
    dispatch: () => {},
    goBack: () => {},
    isFocused: () => false,
    jumpTo: () => {},
    navigate: () => {},
    pop: () => {},
    popToTop: () => {},
    push: () => {},
    removeListener: () => {},
    replace: () => {},
    reset: () => {},
    setOptions: () => {},
    setParams: () => {},
});

export default useNavigation;
