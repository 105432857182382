import {
    NativeSyntheticEvent,
    TextInputChangeEventData,
    TextInputKeyPressEventData,
    Platform,
} from 'react-native';
import { useState, useEffect, useRef } from 'react';
import { InputRefType, SequentialInputPropsMobile } from '../types';
import { KEYBOARD_KEYS, MAX_LENGTH_OF_INPUT } from '../constants';

function useViewModel(props: SequentialInputPropsMobile): SequentialInputPropsMobile {
    const {
        numberOfInputs = 1,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange = () => {},
        lengthOfSingleInput = MAX_LENGTH_OF_INPUT,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onFinish = () => {},
        inputFilter = () => true,
        isIncorrectPin = false,
    } = props;

    const useInputRef: InputRefType = () => useRef(null);
    const inputRefs = Array.from({ length: numberOfInputs }, useInputRef);
    const [indexOfFocusedInput, setIndexOfFocusedInput] = useState<number | null>(null);
    const [values, setValues] = useState(Array.from({ length: numberOfInputs }, () => ''));

    useEffect(() => {
        if (indexOfFocusedInput !== null && indexOfFocusedInput < numberOfInputs) {
            inputRefs[indexOfFocusedInput].current?.focus();
        }
    }, [indexOfFocusedInput]);
    useEffect(() => {
        if (isIncorrectPin) {
            setValues(Array.from({ length: numberOfInputs }, () => ''));
            setIndexOfFocusedInput(0);
        }
    }, [isIncorrectPin]);

    const onChangeHandler = (
        event: NativeSyntheticEvent<TextInputChangeEventData>,
        index: number
    ): void => {
        const { text } = event.nativeEvent;
        if (!inputFilter(text)) return;
        onChange({ event, index });
        if (!Platform.isTV && index < numberOfInputs - 1 && text.length === lengthOfSingleInput) {
            setIndexOfFocusedInput(index + 1);
        }

        const newTextValues = [...values.slice(0, index), text, ...values.slice(index + 1)];

        const numberOfFilledInputs = newTextValues.filter(
            ({ length }) => length === lengthOfSingleInput
        ).length;

        if (
            numberOfFilledInputs === numberOfInputs &&
            (Platform.isTV || indexOfFocusedInput === numberOfFilledInputs - 1)
        ) {
            onFinish({ value: newTextValues.join('') });
        }

        setValues(newTextValues);
    };

    const shouldMoveFocus = (index: number): boolean => {
        if (index <= 0) return false;
        if (values[index].length > 0) return false;
        if (values[index].length > 0 && index === numberOfInputs - 1) return false;
        return true;
    };

    const removeValueFor = (index: number): void => {
        setValues([...values.slice(0, index), '', ...values.slice(index + 1)]);
    };

    const handleBack = (nativeEvent: TextInputKeyPressEventData, index: number): void => {
        if (nativeEvent.key === KEYBOARD_KEYS.BACKSPACE) {
            if (index < numberOfInputs) {
                removeValueFor(index);

                if (shouldMoveFocus(index)) {
                    setIndexOfFocusedInput(index - 1);
                }
            }
        }
    };

    useEffect(() => {
        // We need this setTimeout, because android has problems with invoking keyboard
        setTimeout(() => {
            if (!indexOfFocusedInput && inputRefs[0].current) {
                inputRefs[0].current.focus();
                setIndexOfFocusedInput(0);
            }
        }, 200);
    }, []);

    return {
        ...props,
        inputRefs,
        values,
        indexOfFocusedInput,
        setIndexOfFocusedInput,
        handleBack,
        onChangeHandler,
    };
}

export { useViewModel };
