import { Theme } from '@24i/nxg-sdk-photon';
import { isPlatformWeb } from 'renative';
import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { TextStyle, ViewStyle } from 'react-native';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { PurchasableItemViewProps } from '../../types';

export interface SubscriptionItemStyles {
    wrapper: ViewStyle;
    tagWrapper: ViewStyle;
    tag: TextStyle;
    contentWrapper: ViewStyle;
    space: ViewStyle;
    name: TextStyle;
    header: ViewStyle;
    price: TextStyle;
    description: TextStyle;
    iconWrapper: ViewStyle;
    nextBillingDate: TextStyle;
    nextBillingDateWrapper: ViewStyle;
    changeButtonText: TextStyle;
}

const getBgColor = (
    { showBorder, hasTransparentBackground }: Partial<PurchasableItemViewProps>,
    theme: Theme
) => {
    if (hasTransparentBackground) return 'transparent';
    if (showBorder) return theme.color.lighter4;
    // TODO: temporary add correct color later
    return theme.color.lighter4;
};

const NAME_SPACING = isPlatformWeb ? 48 : 24;

const getStyles = (
    theme: Theme,
    { hasTransparentBackground, showBorder }: Partial<PurchasableItemViewProps>
): SubscriptionItemStyles => {
    const isTablet = DeviceInfo.isTablet();

    return {
        wrapper: {
            backgroundColor: getBgColor({ hasTransparentBackground, showBorder }, theme),
            minHeight: 112,
            width: isTablet ? 440 : '100%',
            alignSelf: 'center',
            paddingVertical: 16,
            paddingHorizontal: !hasTransparentBackground || showBorder || isTablet ? 16 : 0,
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 8,
            borderWidth: showBorder ? 1 : 0,
            borderColor: theme.color.contrast4,
            ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        },
        tagWrapper: {
            alignSelf: 'flex-start',
        },
        tag: {
            paddingHorizontal: 4,
            paddingVertical: 1,
            color: theme.color.textPrimary,
            backgroundColor: theme.color.labelSecondary,
            borderRadius: 4,
            ...getFont(theme, 'h6'),
        },
        contentWrapper: {
            flex: 1,
        },
        space: {
            marginBottom: 8,
        },
        header: {
            marginBottom: 8,
            flexDirection: 'row',
        },
        name: {
            ...getFont(theme, 'h4'),
            flex: 2,
            color: theme.color.textPrimary,
            marginRight: NAME_SPACING,
            overflowWrap: 'anywhere',
        } as TextStyle,
        price: {
            ...getFont(theme, 'h5'),
            color: theme.color.textPrimary,
            opacity: 0.8,
        },
        description: {
            ...getFont(theme, 'body2'),
            color: theme.color.textPrimary,
            opacity: 0.6,
        },
        iconWrapper: {
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 8,
        },
        nextBillingDateWrapper: {
            marginTop: 16,
        },
        nextBillingDate: {
            ...getFont(theme, 'h5'),
            color: theme.color.textPrimary,
            opacity: 0.8,
        },
        changeButtonText: {
            color: theme.color.textPrimary,
            marginTop: 4,
            fontSize: 16,
            ...getFont(theme, 'h6'),
        } as const,
    };
};

export default getStyles;
