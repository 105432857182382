let appVersionValue = '';

export const AppVersion = {
    set: (value: string) => {
        appVersionValue = value;
    },
    get: () => appVersionValue,
};

let lastFocusedPageTagsObject: Record<string, number | null> = {};

export const LastFocusedPageTags = {
    set: (key: string, value: number | null) => {
        lastFocusedPageTagsObject[key] = value;
    },
    get: (key: string) => lastFocusedPageTagsObject[key],
    clear: () => {
        lastFocusedPageTagsObject = {};
    },
};

let layoutScrollViewObject: Record<string, any> = {};

export const LayoutScrollView = {
    set: (key: string, object) => {
        layoutScrollViewObject[key] = object;
    },
    get: () => layoutScrollViewObject,
    clear: () => {
        layoutScrollViewObject = {};
    },
};

export interface MenuItemRefsProps {
    current: React.Component;
    tag: number;
    route: string;
}

const tvDrawerObject: {
    menuItemRefs: MenuItemRefsProps[];
} = { menuItemRefs: [] };

export const TvDrawer = {
    set: (index: number, current: React.Component, tag: number, route: string) => {
        tvDrawerObject.menuItemRefs[index] = { current, tag, route };
    },
    get: () => tvDrawerObject,
    clear: () => {
        tvDrawerObject.menuItemRefs = [];
    },
};

let focusedSectionIdValue: string | number = 0;

export const FocusedSectionId = {
    set: (value: string | number) => {
        focusedSectionIdValue = value;
    },
    get: () => focusedSectionIdValue,
};

let epgAndroidNativeInstanceComponent: React.Component | null = null;

export const EpgAndroidNativeInstance = {
    set: (component: React.Component | null) => {
        epgAndroidNativeInstanceComponent = component;
    },
    get: () => epgAndroidNativeInstanceComponent,
};

let epgYAxisValue: number;

export const EPGyAxis = {
    set: (value: number) => {
        epgYAxisValue = value;
    },
    get: () => epgYAxisValue,
};

let epgXAxisValue: number;

export const EPGxAxis = {
    set: (value: number) => {
        epgXAxisValue = value;
    },
    get: () => epgXAxisValue,
};

let firstFocusableItemValue = '';

export const FirstFocusableItem = {
    set: (value: string) => {
        firstFocusableItemValue = value;
    },
    get: () => firstFocusableItemValue,
};

let isTabletValue = false;

export const IsTablet = {
    set: (value: boolean) => {
        isTabletValue = value;
    },
    get: () => isTabletValue,
};

let runtimeConfigObject: Record<string, any>;

export const RuntimeConfig = {
    set: (object: Record<string, any>) => {
        runtimeConfigObject = object;
    },
    get: () => runtimeConfigObject,
};

let focusedPackshotRefObject;

export const FocusedPackshotRef = {
    set: (object) => {
        focusedPackshotRefObject = object;
    },
    get: () => focusedPackshotRefObject,
};

let dynamicBottomBarHeightValue: number;

export const DynamicBottomBarHeight = {
    set: (value: number) => {
        dynamicBottomBarHeightValue = value;
    },
    get: () => dynamicBottomBarHeightValue,
};

let playbackStartedValue: boolean;

export const PlaybackStarted = {
    set: (value: boolean) => {
        playbackStartedValue = value;
    },
    get: () => playbackStartedValue,
};
