import React, { ReactElement } from 'react';
import GenericModal from '../../GenericModal';
import { ViewProps } from '../types';

const ReminderModal = (props: ViewProps): ReactElement => {
    const {
        modalVisible,
        closeModal,
        goToChannel,
        modalText: { title, description, primaryButtonTitle, secondaryButtonTitle },
    } = props;
    return (
        <GenericModal
            isVisible={modalVisible}
            requiresModalWrapper
            closeModal={closeModal}
            title={{ text: title }}
            description={{ text: description }}
            primaryButton={{
                title: primaryButtonTitle,
                onPress: goToChannel,
            }}
            secondaryButton={{
                title: secondaryButtonTitle,
                onPress: closeModal,
            }}
            defaultHorizontalButtonLayout
        />
    );
};

export default ReminderModal;
