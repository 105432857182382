import { useTheme } from '@24i/nxg-sdk-higgs';

const getStyles = () => {
    const { theme } = useTheme();

    return {
        wrapper: {
            flex: 1,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            top: 30,
        },
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
        },
        loadingWrapper: {
            width: '100%',
            height: '100%',
        },
        collection: {
            top: -1,
            backgroundColor: theme.color.background1,
        },
    };
};

export default getStyles;
