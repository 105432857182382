import { Animated, Easing } from 'react-native';
import { LandscapeEpgScroll } from '../../types';

type AnimatedScrollingParams = {
    theme: any;
    dimensions: { width: number; height: number };
    currentScroll: {
        scrollLeft: number | null;
        scrollTop: number;
        scrollHeight: number;
        scrollWidth: number;
    };
    setScrollState: (state: Partial<LandscapeEpgScroll>) => void;
};

type AnimateScrollingReturnType = {
    reachedStartOfDay: boolean;
    reachedEndOfDay: boolean;
    scrollOneWidthLeft: () => void;
    scrollOneWidthRight: () => void;
    scrollOneHeightDown: () => void;
    scrollOneHeightUp: () => void;
};

export const useAnimatedScrolling = ({
    theme,
    dimensions,
    currentScroll,
    setScrollState,
}: AnimatedScrollingParams): AnimateScrollingReturnType => {
    const setScrollLeft = (scrollLeft: number) => setScrollState({ scrollLeft });
    const setScrollTop = (scrollTop: number) => setScrollState({ scrollTop });

    const animate = (
        prevValue: number,
        finalValue: number,
        setValue: (value: number) => void,
        duration: number
    ) => {
        const animation = new Animated.Value(prevValue);
        animation.addListener(({ value }) => {
            setValue(value);
        });
        Animated.timing(animation, {
            toValue: finalValue,
            duration,
            useNativeDriver: true,
            easing: Easing.linear,
        }).start();
    };

    const endOfDayPosition =
        theme.PIXELS_PER_MIN * 24 * 60 - (dimensions.width - theme.sidebar.container.width);
    const startOfDayPosition = 0;

    const scrollOneWidthLeft = () => {
        if (currentScroll.scrollLeft === null) return;
        const newScrollLeft = Math.max(
            startOfDayPosition,
            (currentScroll.scrollLeft || 0) - dimensions.width
        );
        animate(currentScroll.scrollLeft, newScrollLeft, setScrollLeft, 300);
    };

    const scrollOneHeightDown = () => {
        if (currentScroll.scrollTop === null) return;
        const newScrollTop = Math.min(
            currentScroll.scrollHeight - dimensions.height + theme.timeline.container.height,
            currentScroll.scrollTop + dimensions.height
        );
        animate(currentScroll.scrollTop, newScrollTop, setScrollTop, 300);
    };

    const scrollOneHeightUp = () => {
        if (currentScroll.scrollTop === null) return;
        const newScrollTop = Math.max(0, currentScroll.scrollTop - dimensions.height);
        animate(currentScroll.scrollTop, newScrollTop, setScrollTop, 300);
    };

    const scrollOneWidthRight = () => {
        if (currentScroll.scrollLeft === null) return;
        const newScrollLeft = Math.min(
            endOfDayPosition,
            currentScroll.scrollLeft + dimensions.width
        );
        animate(currentScroll.scrollLeft, newScrollLeft, setScrollLeft, 300);
    };

    const nullScroll = currentScroll.scrollLeft === null;
    const reachedStartOfDay =
        (nullScroll ? 0 : Math.round(currentScroll.scrollLeft!)) > Math.round(startOfDayPosition);
    const reachedEndOfDay =
        (nullScroll ? Infinity : Math.round(currentScroll.scrollLeft!)) <
        Math.round(endOfDayPosition);

    return {
        reachedStartOfDay,
        reachedEndOfDay,
        scrollOneWidthLeft,
        scrollOneWidthRight,
        scrollOneHeightDown,
        scrollOneHeightUp,
    };
};
