import React, { useContext } from 'react';
import { SottMenuConfig } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/navConfigBuilder/types';
import {
    AppNavigationClient,
    NavConfig,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { getDummyClient } from '@24i/nxg-sdk-photon';
import { usePreviousRoute } from '../../hooks/usePreviousRoute';

const defaultClient = getDummyClient<AppNavigationClient<unknown>>();

export const AppNavigationContext = React.createContext<{
    client: AppNavigationClient<SottMenuConfig>;
    navigationConfig?: NavConfig | undefined;
    previousRoute?: string | null;
}>({ navigationConfig: undefined, client: defaultClient });

export interface AppNavigationProviderProps {
    client: AppNavigationClient<SottMenuConfig>;
    children?: React.ReactElement;
}

export const AppNavigationProvider = ({ client, children }: AppNavigationProviderProps) => {
    const previousRoute = usePreviousRoute();

    return (
        <AppNavigationContext.Provider value={{ client, previousRoute }}>
            {children}
        </AppNavigationContext.Provider>
    );
};

export const useAppNavigation = () => useContext(AppNavigationContext);
