/* eslint-disable @typescript-eslint/no-unused-expressions */
import { AnalyticsClient, AnalyticsConfig } from '@24i/nxg-sdk-photon';
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { AnyPayload, EventBody } from '@24i/nxg-sdk-photon/src/clients/AnalyticsClient';
import axios from 'axios';
import { isEventScheduled } from '@24i/nxg-sdk-smartott-shared/src/analytics/utils';
import {
    TWENTY_FOUR_IQ_TRIGGERS,
    TRIGGER_NAME_TO_24IQ_EVENTS,
    EVENTS,
    REDUNDANT_EVENTS,
} from './constants';
import { mapSceneView } from './mappers/mapSceneView';
import { mapBuffering } from './mappers/mapBuffering';
import { mapScroll } from './mappers/mapScroll';
import { mapVideoProgress } from './mappers/mapVideoProgress';
import { mapVideoStop } from './mappers/mapVideoStop';
import { mapVideoComplete } from './mappers/mapVideoComplete';
import { mapPlayerOpen } from './mappers/mapPlayerOpen';
import { mapPlayerClose } from './mappers/mapPlayerClose';
import { mapVideoStart } from './mappers/mapVideoStart';
import { mapVideoPause } from './mappers/mapVideoPause';
import { mapAdLoaded } from './mappers/mapAdLoaded';
import { mapAdSkipped } from './mappers/mapAdSkipped';
import { EventMappingFunction } from './types';

const eventsMapping: Record<EVENTS, EventMappingFunction> = {
    [EVENTS.SCENE_VIEW]: mapSceneView,
    [EVENTS.BUFFERING]: mapBuffering,
    [EVENTS.SCROLL]: mapScroll,
    [EVENTS.PLAYER_OPEN]: mapPlayerOpen,
    [EVENTS.PLAYER_CLOSE]: mapPlayerClose,
    [EVENTS.VIDEO_START]: mapVideoStart,
    [EVENTS.VIDEO_STOP]: mapVideoStop,
    [EVENTS.VIDEO_PROGRES]: mapVideoProgress,
    [EVENTS.VIDEO_COMPLETE]: mapVideoComplete,
    [EVENTS.VIDEO_PAUSE]: mapVideoPause,
    [EVENTS.AD_LOADED]: mapAdLoaded,
    [EVENTS.AD_SKIPPED]: mapAdSkipped,
};

export class TwentyFourIQClient implements AnalyticsClient {
    name = '24iQ';

    triggers: string[];

    baseURL = '';

    constructor() {
        this.triggers = TWENTY_FOUR_IQ_TRIGGERS;
    }

    async init(config: AnalyticsConfig): Promise<void> {
        this.baseURL = config.baseURL;
    }

    async sendEvent(eventBody: EventBody) {
        try {
            await axios.post(this.baseURL, eventBody, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                },
            });
        } catch (error) {
            console.error('24iQ Analytics err:', error);
        }
    }

    async onEvent(triggerName: ANALYTICS_TRIGGERS, payload: AnyPayload): Promise<void> {
        const events = TRIGGER_NAME_TO_24IQ_EVENTS[triggerName];
        const eventNameFromPayload = payload.payload.event;
        if (!isEventScheduled(REDUNDANT_EVENTS, eventNameFromPayload)) return;
        events &&
            (await Promise.all(
                events.map(async (event: string) => {
                    const mappingFunction = eventsMapping[event];
                    const eventBody = mappingFunction && mappingFunction(triggerName, payload);
                    eventBody && (await this.sendEvent(eventBody));
                })
            ));
    }
}

export const createTwentyFourIQClient = () => new TwentyFourIQClient();
