import React, { ReactElement, Ref } from 'react';
import View from './View';
import { useViewModel } from './viewModel';
import { EPGModelProps } from './types';

const Main = React.forwardRef((props: EPGModelProps, ref: Ref<typeof View>): ReactElement => {
    const viewModelProps = useViewModel(props);
    return <View ref={ref} {...viewModelProps} />;
});

export default Main;
