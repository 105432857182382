import { Theme } from '@24i/nxg-sdk-photon';
import { IconButtonStyles } from '../../IconButton/types';
import { getTertiaryButtonStyles } from '../../TertiaryButton';

const withoutBgStyles = (theme: Theme): IconButtonStyles => {
    const tertiaryButtonStyles = getTertiaryButtonStyles(theme);
    return {
        ...tertiaryButtonStyles,
        holder: {
            ...tertiaryButtonStyles.holder,
            backgroundColor: undefined,
        },
        holderFocused: {
            ...tertiaryButtonStyles.holderFocused,
            backgroundColor: undefined,
        },
    };
};

export default withoutBgStyles;
