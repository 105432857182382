import React, { ReactElement, useState, SyntheticEvent } from 'react';
import { Text, View } from 'react-native';
import { SvgUri } from 'react-native-svg';
import { isPlatformWeb } from 'renative';
import { getTierOneItemContentStyles } from './styles';
import { TierOneMvpdItemContentProps } from './types';

export const TierOneMvpdItemContent = (props: TierOneMvpdItemContentProps): ReactElement => {
    const [useFallback, setUseFallback] = useState(false);
    const { mvpd, theme, providerId } = props;
    const style = getTierOneItemContentStyles(theme);

    const handleSvgError = (_e: Error): void => {
        setUseFallback(true);
    };

    const handleImgError = (_e: SyntheticEvent<HTMLImageElement>): void => {
        setUseFallback(true);
    };
    const isSelected = providerId === mvpd.id;

    const svg = isPlatformWeb ? (
        <View style={[style.webSvgContainer, isSelected && style.selectedItem]}>
            <img
                src={mvpd.logoUrl}
                alt={mvpd.title}
                style={style.webSvg}
                onError={handleImgError}
            />
        </View>
    ) : (
        <SvgUri
            uri={mvpd.logoUrl}
            // @ts-expect-error: onError is missing in type declarations for this package version
            onError={handleSvgError}
            height="100%"
            width="100%"
        />
    );

    return useFallback ? (
        <View style={style.textContainer}>
            <Text style={[style.textStyle, isSelected && style.selectedItem]}>{mvpd.title}</Text>
        </View>
    ) : (
        svg
    );
};
