import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const GenreGuard = createGuard('Genre', undefined, {
    id: nullable(t.string),
    label: nullable(t.string),
});

export type Genre = {
    title: string;
    poster: string;
    packshotHeight?: number;
    packshotWidth?: number;
} & t.TypeOf<typeof GenreGuard>;
