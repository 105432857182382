import { ViewStyle } from 'react-native';
import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DropdownMenuStylesTypes } from '../../../types';

const DropdownMenuStyles = (theme: Theme): DropdownMenuStylesTypes => {
    const activeItem = {
        ...getFont(theme, 'menuItem-active'),
        color: theme.color.menuItemActive,
    };

    return {
        dropdownContainer: {
            position: 'absolute',
            top: 42,
            left: 0,
            backgroundColor: theme.color.darker1,
            borderRadius: 4,
            ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
            minWidth: 224,
            maxHeight: 385,
            marginTop: 8,
            paddingTop: 9,
            paddingBottom: 9,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.2,
            shadowRadius: 6,
        },
        pickerItem: {
            flexDirection: 'row',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 16,
            backgroundColor: theme.color.darker1,
            ':hover': { backgroundColor: theme.color.primary4 },
        } as ViewStyle,
        itemIcon: {
            marginLeft: 2,
            marginRight: 13,
        },
        itemText: {
            ...getFont(theme, 'menuItem-inactive'),
            color: theme.color.textPrimary,
            // @ts-expect-error
            ':hover': activeItem,
        },
        activeItem,
        scrollTrack: {
            position: 'absolute',
            paddingTop: 9,
            paddingBottom: 9,
            top: 0,
            bottom: 0,
            right: 2,
            height: '100%',
            width: 5,
        },
        scrollIndicator: {
            width: 3,
            borderRadius: 1.5,
            backgroundColor: theme.color.contrast3,
            opacity: 0.5,
        },
    };
};

export default DropdownMenuStyles;
