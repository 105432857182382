export const dummyGluonTheme = {
    color: {
        textButtonSecondary: '',
        background1: 'ccdd',
    },
    icons: {
        packshot: {
            play: {
                iconFont: 'font',
                iconName: 'name',
            },
        },
    },
    elements: {},
    fonts: {},
};

export const testableStyledComponentConfig = {
    shouldForwardProp: (prop, defaultValidatorFn) =>
        ['testID'].includes(prop) || ['testid'].includes(prop) || defaultValidatorFn(prop),
};
