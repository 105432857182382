import { extendGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ASSET_TYPE } from '../enums';
import { AssetGuard } from './asset';

export const ClipGuard = extendGuard(
    'Clip',
    AssetGuard,
    {
        type: t.literal(ASSET_TYPE.CLIP),
    },
    {}
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isClip = (asset: any): asset is Clip => {
    return asset && asset.type === ASSET_TYPE.CLIP;
};

export type Clip = t.TypeOf<typeof ClipGuard>;
