import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '../../../utils/styles/constants';
import { AddSubscriptionScreenViewStyles } from './types';

const styles = (theme: Theme): AddSubscriptionScreenViewStyles => ({
    container: {
        alignItems: 'center',
        top: 24,
        marginHorizontal: 'auto',
    },
    heading: {
        textAlign: 'center',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h2'),
        marginTop: 32,
        marginBottom: 24,
    },
    flowContainer: {
        maxWidth: Breakpoint.MD,
        [`@media (min-width: ${Breakpoint.SM}px)`]: {
            width: '100%',
            paddingHorizontal: 20,
        },
    },
});

export default styles;
