import { Text, View } from '@24i/nxg-sdk-quarks';
import React, { ReactElement } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import { useTranslation } from 'react-i18next';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { TextInput } from 'react-native';
import CornerLogo from '@24i/nxg-sdk-gluons/src/components/ui/CornerLogo';
import { getSecondScreenActivationStyles } from '../styles';
import { MvpdGrid } from '../components/MvpdGrid';
import { SearchInput } from '../components/SearchInput';
import { SecondScreenActivationViewModelReturnType } from '../types';

export const SecondScreenActivation = (
    props: SecondScreenActivationViewModelReturnType
): ReactElement => {
    const {
        mvpds,
        handleSeeAllProviders,
        seeAllProviders,
        handleSelectProvider,
        handleSearchStringChange,
        searchString,
        providerId,
        regCode,
        handleSignInPress,
        handleRegCodeChange,
        isButtonDisabled,
    } = props;
    const { theme } = useTheme();
    const styles = getSecondScreenActivationStyles(theme);

    const tierOneMvpds = mvpds?.tierOneMvpds || [];
    const allMvpds = mvpds?.allMvpds || [];
    const { t } = useTranslation();

    return (
        <>
            <View style={styles.cornerLogo}>
                <CornerLogo isClickable={false} />
            </View>
            <View style={styles.outerContainer}>
                <View style={styles.container}>
                    <View style={styles.headerSection}>
                        <Text numberOfLines={1} style={styles.headerTitle}>
                            {t('auth.devicePairingWeb.title1')}
                        </Text>
                    </View>
                    <View style={styles.blockSection}>
                        <Text numberOfLines={1} style={styles.blockTitle}>
                            {`1. ${t('auth.devicePairingWeb.blockTitle')}`}
                        </Text>
                        <Text numberOfLines={1} style={styles.blockDescriptionText}>
                            {t('auth.devicePairingWeb.description1')}
                        </Text>
                        <TextInput
                            value={regCode || ''}
                            style={styles.textInputContainer}
                            placeholder={t('auth.devicePairingWeb.blockTitle') as string}
                            placeholderTextColor={theme.color.contrast4}
                            onChangeText={handleRegCodeChange}
                            autoCapitalize="none"
                        />
                    </View>
                    <View style={styles.blockSection}>
                        <Text numberOfLines={1} style={styles.blockTitle}>
                            {`1. ${t('auth.devicePairingWeb.blockTitle1')}`}
                        </Text>
                    </View>
                    {seeAllProviders && (
                        <SearchInput
                            theme={theme}
                            handleSearchStringChange={handleSearchStringChange}
                            searchString={searchString}
                        />
                    )}
                    <MvpdGrid
                        mvpds={seeAllProviders ? allMvpds : tierOneMvpds}
                        theme={theme}
                        seeAllProviders={seeAllProviders}
                        handleSelectProvider={handleSelectProvider}
                        providerId={providerId}
                    />
                    <View style={styles.allProvidersButtonContainer}>
                        <TertiaryButton
                            title={
                                seeAllProviders
                                    ? t('adobePrimetime.seeMainProviders')
                                    : t('adobePrimetime.seeAllProviders')
                            }
                            styles={() => styles.allProvidersButton}
                            onPress={handleSeeAllProviders}
                        />
                    </View>
                    <View style={styles.buttonSection}>
                        <ActionButton
                            disabled={isButtonDisabled}
                            title={t('auth.signIn.title')}
                            onPress={handleSignInPress}
                            styles={() => styles.signInButton}
                        />
                    </View>
                </View>
            </View>
        </>
    );
};
