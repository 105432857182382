import { Theme } from '@24i/nxg-sdk-photon';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { TextStyle, ViewStyle } from 'react-native';
import { StylesGetter } from '../../utils/styles/types';

export interface DeleteAccountSuccessStyles {
    main: ViewStyle;
    title: TextStyle;
    description: TextStyle;
    button: ViewStyle;
    buttonText: TextStyle;
    headerContainer?: ViewStyle;
    actionContainer?: ViewStyle;
}

export interface DeleteAccountSuccessProps extends ScreenProps {
    styles?: StylesGetter<Theme, DeleteAccountSuccessStyles>;
}

export enum DELETE_ACCOUNT_SUCCESS_TEST_ID {
    CONTINUE_BUTTON = 'testId_delete_account_success_button',
}
