import React from 'react';
import { useTranslation } from 'react-i18next';
import View from './View';
import { useViewModel } from './viewModel';
import ManageButton from './components/ManageButton';

export default {
    Main: Object.assign(
        () => {
            const viewModelProps = useViewModel();
            const { t } = useTranslation();
            const { isEditing, recordings } = viewModelProps;

            const renderActionButton = () => {
                if (!recordings?.length) return null;
                return (
                    <ManageButton
                        {...viewModelProps}
                        title={
                            isEditing
                                ? t('recordings.manage.done')
                                : t('recordings.manage.description')
                        }
                        onEdit={viewModelProps.handleEditButtonPress}
                    />
                );
            };
            return <View {...viewModelProps} renderActionButton={renderActionButton} fullWidth />;
        },
        { displayName: 'RecordingScreen' }
    ),
    View,
    useViewModel,
};
