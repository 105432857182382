import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { InputStyle } from '../types';

const getInputStyles = (theme: Theme): InputStyle => ({
    container: {
        flexDirection: 'row',
        height: 55,
        paddingTop: 4,
        borderRadius: 5,
    },
    input: {
        ...getFont(theme, 'body1'),
        flex: 8,
        color: theme.color.lighter3,
        height: 55,
        lineHeight: 55,
        paddingHorizontal: 12,
    },
    visibilitySwitchContainer: {
        flex: 2,
        height: '100%',
    },
});

export default getInputStyles;
