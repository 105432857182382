import { Profile, RuntimeConfigType, User } from '@24i/nxg-sdk-photon';
import React, { createContext, Dispatch, ReactNode, useContext, useState } from 'react';

const contextDefaults: ContextType = {
    setUserData: () => undefined,
    userData: null,
    isAppLoading: true,
    setIsAppLoading: () => undefined,
    runtimeConfig: null,
    selectedProfile: null,
    setSelectedUserProfile: () => undefined,
    profiles: [],
    setProfiles: () => undefined,
    setProfilesAndPossiblySelectOne: () => undefined,
    isNavBarTransparent: false,
    setNavbarTransparent: () => undefined,
};

export type ContextType = {
    userData?: User | null;
    setUserData: Dispatch<User | null | undefined>;
    isAppLoading: boolean;
    setIsAppLoading: Dispatch<boolean>;
    selectedProfile: Profile | null;
    setSelectedUserProfile: Dispatch<Profile | null>;
    runtimeConfig: RuntimeConfigType | null;
    profiles: Profile[] | null;
    setProfiles: Dispatch<Profile[]>;
    setProfilesAndPossiblySelectOne: (profiles: Profile[] | null) => void;
    isNavBarTransparent: boolean;
    setNavbarTransparent: Dispatch<boolean>;
};

export const StoreContext = createContext<ContextType>(contextDefaults);

interface ProviderProps {
    children?: ReactNode;
    value: Partial<ContextType>;
    runtimeConfig: RuntimeConfigType;
}

export const ApplicationStoreProvider = ({
    children,
    runtimeConfig: config,
    value,
}: ProviderProps) => {
    // TODO: most of this shouldn't be here, it should be in individual providers
    const [userData, setUserData] = useState<User | null | undefined>();
    const [appIsLoading, setIsAppLoading] = useState(true);
    const [selectedProfile, setSelectedUserProfile] = useState<Profile | null>(null);
    const [profiles, setProfiles] = useState<Profile[] | null>(null);
    const [isNavBarTransparent, setNavbarTransparent] = useState<boolean>(false);
    const [runtimeConfig] = useState(config);

    // If there is only one profile and it is unprotected choose it as a selected profile.
    const setProfilesAndPossiblySelectOne = (userProfiles: Profile[] | null) => {
        if (!userProfiles) return;

        if (userProfiles?.length > 1) {
            setProfiles(userProfiles);

            return;
        }

        if (userProfiles?.length === 1) {
            const profile = userProfiles[0];
            const isProtected = profile?.isPinProtected;
            setProfiles(userProfiles);
            if (!isProtected) setSelectedUserProfile(profile);
        }
    };

    return (
        <StoreContext.Provider
            value={{
                ...value,
                userData,
                setUserData,
                selectedProfile,
                setSelectedUserProfile,
                runtimeConfig,
                isAppLoading: appIsLoading,
                setIsAppLoading,
                profiles,
                setProfiles,
                setProfilesAndPossiblySelectOne,
                isNavBarTransparent,
                setNavbarTransparent,
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => useContext(StoreContext);

// The inner component will never be used alone
// eslint-disable-next-line react/display-name
export const withStore = (Component) => (props) =>
    (
        <StoreContext.Consumer>
            {(context) => <Component {...props} {...context} />}
        </StoreContext.Consumer>
    );
