import React, { useState } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import WorkInProgress from '@24i/nxg-sdk-smartott/src/components/WorkInProgress';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import getAddSubscriptionScreenStyles from '../styles';
import { AddSubscriptionViewProps, OnboardingSubscriptionSteps } from './types';
import OnboardingHeader from '../../../navigation/components/OnboardingHeader';
import MySubscriptionsContent from '../../MySubscriptionsScreen/components/MySubscriptionsContent/index.web';
import useWebPurchaseCheckout from '../../../hooks/useWebPurchaseCheckout';
import { AddSubscriptionsContentProps } from '../components/AddSubscriptionsContent/types';
import AddSubscriptionsContent from '../components/AddSubscriptionsContent';

const AddSubscriptionScreen = ({
    isLoading = false,
    isOnboarding = true,
    availableSubscriptions,
    onMaybeLaterPress,
    styles: getStyles = getAddSubscriptionScreenStyles,
}: AddSubscriptionViewProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation(['sott']);
    const [onboardingStep, setOnboardingStep] = useState<OnboardingSubscriptionSteps>(
        OnboardingSubscriptionSteps.Initial
    );
    const { sessionId } = useSessionId();

    const getTitle = () => {
        switch (onboardingStep) {
            case OnboardingSubscriptionSteps.Confirm:
                return t('svod.add.title');
            case OnboardingSubscriptionSteps.Success:
                return t('svod.add.addConfirm.title');
            case OnboardingSubscriptionSteps.Failure:
                return t('error.H00.title');
            default:
                return t('onboarding.svod.add.title');
        }
    };

    const { resetQueryParams } = useWebPurchaseCheckout({
        onSuccessPurchaseOverride: () => {
            setOnboardingStep(OnboardingSubscriptionSteps.Success);
        },
        onFailurePurchaseOverride: () => {
            setOnboardingStep(OnboardingSubscriptionSteps.Failure);
        },
    });

    const getContentProps = (): AddSubscriptionsContentProps => {
        if (onboardingStep === OnboardingSubscriptionSteps.Success) {
            onAnalytics(ANALYTICS_TRIGGERS.PURCHASE_COMPLETE, {
                event: ANALYTICS_EVENTS.PURCHASE_COMPLETE,
            });
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
                screen: t('analytics.documentTitle.purchase.step3'),
                sessionId,
            });
            return {
                isLoading,
                description: t('svod.add.addConfirm.description'),
                primaryButton: {
                    title: t('common.continue'),
                    onPress: () => {
                        onMaybeLaterPress();
                        resetQueryParams();
                    },
                },
            };
        }
        if (onboardingStep === OnboardingSubscriptionSteps.Failure) {
            onAnalytics(ANALYTICS_TRIGGERS.PURCHASE_CANCEL, {
                event: ANALYTICS_EVENTS.CANCELLED_PURCHASE,
            });
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
                screen: t('analytics.documentTitle.purchase.paymentFailure'),
                sessionId,
            });
            return {
                isLoading,
                description: t('error.H00.body'),
                primaryButton: {
                    title: t('vod.add.tryAgainButton'),
                    onPress: () => {
                        setOnboardingStep(OnboardingSubscriptionSteps.Selection);
                        resetQueryParams();
                    },
                },
                secondaryButton: {
                    title: t('common.cancel'),
                    onPress: () => {
                        onMaybeLaterPress();
                        resetQueryParams();
                    },
                },
            };
        }

        return {
            isLoading,
            description: t('onboarding.svod.add.description'),
            primaryButton: {
                title: t('common.continue'),
                onPress: () => setOnboardingStep(OnboardingSubscriptionSteps.Selection),
            },
            secondaryButton: {
                title: t('onboarding.skipButton'),
                onPress: onMaybeLaterPress,
            },
        };
    };

    const shouldShowAddSubscriptionsContent =
        onboardingStep === OnboardingSubscriptionSteps.Initial ||
        onboardingStep === OnboardingSubscriptionSteps.Success ||
        onboardingStep === OnboardingSubscriptionSteps.Failure;

    return (
        <View style={styles.mainView}>
            <WorkInProgress />
            <OnboardingHeader title={getTitle()} />
            <View style={styles.container}>
                <View style={styles.flowContainer}>
                    {shouldShowAddSubscriptionsContent ? (
                        <AddSubscriptionsContent {...getContentProps()} />
                    ) : (
                        <MySubscriptionsContent.Main
                            availableSubscriptions={availableSubscriptions}
                            isOnboarding={isOnboarding}
                            setOnboardingStep={setOnboardingStep}
                        />
                    )}
                </View>
            </View>
        </View>
    );
};

export default AddSubscriptionScreen;
