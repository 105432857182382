import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import getSettingsButtonStyles from '../styles';

const SettingsButton = (props) => (
    <IconButton styles={getSettingsButtonStyles} variant="navigation" {...props} />
);

export default overridable(SettingsButton, 'SettingsButton');
