import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { ConfirmPurchaseStyles } from './types';

const getStyles = (theme: Theme, isModalContent?: boolean): ConfirmPurchaseStyles => ({
    informationTextWrapper: {
        marginVertical: 16,
    },
    informationText: {
        ...getFont(theme, 'body2'),
        textAlign: isModalContent ? 'center' : 'left',
        color: theme.color.textPrimary,
        opacity: isModalContent ? 1 : 0.6,
    },
    buttonWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: isModalContent ? 32 : 28,
    },
    confirmButton: {
        width: 'auto',
        height: 52,
        paddingHorizontal: 16,
    },
});

export default getStyles;
