import { useTimeout } from 'ahooks';

export const useOptionalTimedOutFunction = <T extends (...args: any[]) => any>(
    shouldRun: boolean,
    actionToPerform: T,
    timeout?: number
) => {
    const functionToRun = () => {
        if (!shouldRun) return;

        actionToPerform();
    };

    useTimeout(functionToRun, timeout || 2000);
};
