import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import { ViewModelReturnType } from '../View/types';

function useViewModel<P>(props: P): ViewModelReturnType<P> {
    const { getActionIcon } = useAssetActions();
    return {
        ...props,
        getActionIcon,
    };
}

export { useViewModel };
