import React, { createContext, useContext } from 'react';

import { getDummyClient, AppStructureDataClient } from '@24i/nxg-sdk-photon';

const defaultClient = getDummyClient<AppStructureDataClient>();

export const AppStructureDataContext = createContext<AppStructureDataClient>(defaultClient);

export const AppStructureDataProvider = ({ client = defaultClient, children }) => (
    <AppStructureDataContext.Provider value={client}>{children}</AppStructureDataContext.Provider>
);

export const useAppStructureData = () => useContext(AppStructureDataContext);
