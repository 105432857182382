import { useWindowDimensions } from 'react-native';
import { DimensionsInfo, ORIENTATIONS } from './types';

const useDimensions = (): DimensionsInfo => {
    const window = useWindowDimensions();

    const currentOrientation =
        window.width > window.height ? ORIENTATIONS.LANDSCAPE : ORIENTATIONS.PORTRAIT;

    return {
        isLandscape: window.width > window.height,
        orientation: currentOrientation,
        viewPortWidth: window.width,
        viewPortHeight: window.height,
        ...window,
    };
};

export default useDimensions;
