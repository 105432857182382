import { useQuery, UseQueryOptions, useQueryClient } from 'react-query';
import { RecordingsFolder, Recording, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useRecordings } from '@24i/nxg-sdk-smartott-shared/src/context/Recordings';

export const useMyRecordingsQuery = (
    options?: UseQueryOptions<(Recording | RecordingsFolder)[]>
) => {
    const { fetchAllRecordings } = useRecordings();
    const queryClient = useQueryClient();
    const { data, isLoading } = useQuery<(Recording | RecordingsFolder)[]>(
        QUERY_KEYS.recordings,
        fetchAllRecordings,
        options
    );

    const updateRecordings = (newData) => {
        queryClient.setQueryData(QUERY_KEYS.recordings, newData);
    };

    return { data, updateRecordings, isLoading };
};
