const SubMenuList = () => ({
    scrollContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '@media (max-width: 596px)': {
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
    },
    itemContainer: {
        marginTop: 12,
    },
});

export default SubMenuList;
