import React, { ComponentType } from 'react';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import {
    NavScreenConfig,
    NavScreenConfigWeb,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { isPlatformWeb } from 'renative';
import { useTheme } from '@24i/nxg-sdk-higgs';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { View } from '@24i/nxg-sdk-quarks';
import { Theme } from '@24i/nxg-sdk-photon';
import { IconButtonStyles } from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton/types';
import { TopBarMenuItem } from '../../../../../components/MenuItem';
import getOpenModalLinkStyles from './styles';

type CloseModal = {
    closeModal?: () => void;
};
interface OpenModalLinkProps {
    styles?: (theme: Theme) => IconButtonStyles;
    menuItem: NavScreenConfig;
    ModalComponent: ComponentType<unknown & CloseModal>;
    onModalClose?: () => void;
    modalOverlayClassName?: string;
    nameModal?: string;
}

export const OpenModalLink = ({
    menuItem,
    ModalComponent,
    styles: getStyles = getOpenModalLinkStyles,
    onModalClose,
    modalOverlayClassName,
    nameModal,
}: OpenModalLinkProps) => {
    const { setModalChildren, setModalProps } = useModal();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    if (!isPlatformWeb) {
        throw new Error(`ModalOpenLink is not supported on the native platforms!`);
    }

    const { name, menuTitle, icon, webLinkType = 'link' } = menuItem as NavScreenConfigWeb;

    const handleOnClick = () => {
        // TODO: check if there is a better solution than global modal because it causes the whole app to re-render
        setModalProps({
            name: nameModal || '',
            overlayClassName: modalOverlayClassName || '',
            onModalClose,
        });

        setModalChildren(<ModalComponent />);
    };

    return (
        <View key={`topmenu-link-${name}`}>
            {webLinkType === 'button' ? (
                <ActionButton onPress={handleOnClick} title={menuTitle} styles={() => styles} />
            ) : (
                <TopBarMenuItem
                    key={`topmenu-${menuTitle}`}
                    title={menuTitle}
                    icon={icon}
                    active={false}
                    spatial
                    onPress={handleOnClick}
                    enlargeIcon={name === 'searching'}
                />
            )}
        </View>
    );
};
