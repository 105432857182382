import React from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import RecordingButton from '../components/RecordingButton';
import AppGrid from '../../../components/AppGrid';
import RemainingSpace from '../components/RemainingSpace';
import { RecordingViewProps } from '../types';
import { withWebHeader } from '../../../navigation/components/withWebHeader';
import getRecordingStyles from '../styles';

const RecordingsScreen = (props: RecordingViewProps) => {
    const {
        recordings,
        handlePackshotPress,
        handleNavButtonPress,
        navButtonTitle,
        isEditing,
        storageInfo,
        showPlannedRecordingsButton,
        showRecordingsCapacityIndicator,
        styles: getStyles = getRecordingStyles,
    } = props;

    const styles = getStyles();

    return (
        <View style={styles.holder}>
            {showRecordingsCapacityIndicator && (
                <RemainingSpace
                    plannedTime={storageInfo?.plannedTime}
                    totalTimeAvailable={storageInfo?.totalTimeAvailable || 0}
                    usedTime={storageInfo?.usedTime || 0}
                />
            )}

            {showPlannedRecordingsButton && (
                <RecordingButton
                    title={navButtonTitle}
                    onPress={handleNavButtonPress}
                    disabled={isEditing}
                />
            )}
            {/* TODO - FIX ME
            @ts-ignore */}
            <AppGrid data={recordings} handleOnPackshotPress={handlePackshotPress} {...props} />
        </View>
    );
};

export default withWebHeader(RecordingsScreen);
