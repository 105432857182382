/*
@PRODUCT_FEATURE: H 2.1.0
*/
/* eslint-disable react/display-name */
import React from 'react';
import { NextError404ScreenProps } from './types';
import { use404FallbackRedirect } from './utils';

import View from './View/index.web';
import { useViewModel } from './viewModel';

export default {
    Main: (props: NextError404ScreenProps) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    MainWithNext404Fallback: (props: NextError404ScreenProps) => {
        const viewModelProps = use404FallbackRedirect(useViewModel(props));
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
};
