import View from './components/View';
import BlurView from './components/BlurView';
import { ThemeIcon } from './components/Icons/ThemeIcon';
// This rule actually makes sense, but I am disabling it not to break anything RN
// The default export should be renamed accordingly or the named export should be removed
// eslint-disable-next-line import/no-named-as-default
import ActivityIndicator from './components/ActivityIndicator';
import ScrollView from './components/ScrollView';
import Image, { preloadImage } from './components/Image';
import Text from './components/Text';
import SafeAreaView from './components/SafeAreaView';
import Modal from './components/Modal';
import TextInput from './components/TextInput';
import Interactable from './components/Interactable';
import FlatList from './components/FlatList';
import SectionList from './components/SectionList';
import ImageBackground from './components/ImageBackground';
import Alert from './components/Alert';
import { NavigationIcon } from './components/Icons/NavigationIcon';

export {
    View,
    BlurView,
    ThemeIcon,
    ActivityIndicator,
    ScrollView,
    Text,
    TextInput,
    Interactable,
    Image,
    preloadImage,
    SafeAreaView,
    Modal,
    FlatList,
    SectionList,
    ImageBackground,
    Alert,
    NavigationIcon,
};
