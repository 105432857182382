import React from 'react';
import { FlatList, View } from '@24i/nxg-sdk-quarks';
import { highlightAndPairUpData } from './utils';
import type { DoubleRowWithHighlightProps } from './types';

const DoubleRowWithHighlight = (props: DoubleRowWithHighlightProps) => {
    const { renderHighlight, renderItem, data } = props;

    return (
        <FlatList
            {...props}
            data={highlightAndPairUpData(data)}
            renderItem={({ item: [itemOne, itemTwo], index, separators }) => {
                if (index === 0) {
                    return <View>{renderHighlight({ item: itemOne, index, separators })}</View>;
                }
                return (
                    <View style={{ flexDirection: 'column' }}>
                        <View>
                            {renderItem({ item: itemOne, index: index * 2 - 1, separators })}
                        </View>
                        {itemTwo && renderItem({ item: itemTwo, index: index * 2, separators })}
                    </View>
                );
            }}
            horizontal
        />
    );
};

export default DoubleRowWithHighlight;
