import { Theme } from '@24i/nxg-sdk-photon';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ReactNode } from 'react';
import { WithTranslation } from 'react-i18next';
import { TextInputProps, TextStyle } from 'react-native';
import { StylesGetter } from '../../../utils/styles/types';
import { SignInScreenStyles } from '../styles/types';
import { WithPlatformWrapperAddedProps } from '../viewModel/withPlatformWrapper/types';

export enum AUTH_METHOD {
    FORM = 'form',
    WEBVIEW = 'webview',
    OPENAUTH = 'openauth',
}

export enum AUTH_ACTION {
    LOGIN = 'login',
    REGISTER = 'register',
}

export interface SignInScreenViewProps
    extends WithTranslation,
        SignInScreenViewPropsCommon,
        SignInScreenViewPropsTv,
        SignInScreenViewPropsGeneric {}

// TODO: Refactor into the new standard of: Props, PropsGeneric, PropsTv, ... (Should be coming in the next PRs)
// TODO: Change the "any" in the StackScreenProps
export interface SignInScreenViewPropsCommon extends WithPlatformWrapperAddedProps {
    navigation: NavigationProp<any, any>;
    route: RouteProp<any, any>;
    isLoading?: boolean;
    alertColor?: string;
    alertMessages?: string[];
    emailAlertMessage?: string;
    autoFocusEmailInput?: boolean;
    passwordAlertMessage?: string;
    autoFocusPasswordInput?: boolean;
    onSigninPress?: (email: string, password: string) => SignInReturnType;
    validateEmail?: ((email: string) => boolean) | null;
    validatePassword?: (password: string) => boolean;
    resetAlertMessages?: () => void;
    onForgotPasswordPress?: () => void;
    signInToBackstage?: boolean;
}

export interface SignInScreenViewPropsGeneric extends SignInScreenViewPropsCommon {
    theme: Theme;
    styles?: SignInScreenStyles;
    displayErrorUnder?: boolean;
    activeTextInputStyle?: TextStyle;
    inactiveTextInputStyle?: TextStyle;
    textInputSelectionColor?: string;
    textInputPlaceholderTextColor?: string;
    customEmailInputPlaceholderText?: string | null;
    onBackPress?: () => void;
    renderEmailInput?: (defaultProps: TextInputProps) => ReactNode;
    shouldDisableLoginButton?: (email: string, password: string) => boolean;
}

export interface SignInScreenViewPropsTv extends SignInScreenViewPropsCommon {
    emailValue: string | null;
    requestError?: Error | null;
    onEmailChange?: (text: string) => void;
    inputsTouched: boolean;
    passwordValue: string;
    emailValidationError: string | null;
    passwordValidationError: string | null;
    getStyles?: StylesGetter<Theme, SignInScreenStyles>;
    onEmailSubmit: () => void;
    // Notice that this has a TV postfix as this prop is used in TV View only as it has a different signature, conflicting with the generic version
    onSignInPressTv: () => void;
    onPasswordChange: (newPassword: string) => void;
    onPasswordSubmit: () => void;
    authMethod: AUTH_METHOD;
}

export type SignInReturnType = Promise<
    { login: null | boolean; message?: string; passwordInput?: ReactNode } | undefined
>;

export enum SIGN_IN_TEST_IDS {
    EMAIL_INPUT = 'login_email_input',
    PASSWORD_INPUT = 'login_password_input',
    CONFIRM_BUTTON = 'login_confirm_button',
    FORGOT_PASSWORD_BUTTON = 'forgot_password_button',
    REGISTER_BUTTON = 'register_now_button',
    ERROR_MESSAGE_EMAIL = 'error_message_email',
    ERROR_MESSAGE_PASSWORD = 'error_message_password',
    SHOW_HIDE_BUTTON = 'show_hide_button',
    CLOSE_BUTTON = 'close_button',
}
