import React, { forwardRef } from 'react';
import StyleSheet from 'react-native-media-query';
import { Pressable } from 'react-native-web';
import { InteractablePropsWeb, InteractableRefType } from './types';

const InteractableWeb = forwardRef<InteractableRefType, InteractablePropsWeb>(
    ({ style, ...props }, ref) => {
        const { ids, styles } = StyleSheet.process(style);
        return (
            <Pressable
                ref={ref}
                dataSet={{ media: ids, testID: props.testID }}
                {...props}
                style={({ pressed, hovered }) => [
                    styles,
                    hovered && styles.hovered,
                    pressed && styles.pressed,
                ]}
            >
                {props.children}
            </Pressable>
        );
    }
);

InteractableWeb.displayName = 'Interactable_Web_Quarks';

export default InteractableWeb;
