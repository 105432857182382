import React from 'react';
import PropTypes from 'prop-types';
import { Text, SafeAreaView, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { getCurrencySymbol } from '@24i/nxg-core-utils';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import getChangeSubscriptionStyles from '../styles';

const ChangeSubscription = ({
    styles: getStyles,
    currentPlan,
    selectedPlan,
    closeModal: closeChangeSubscriptionModal,
    isLandscape,
    handleOnRequestSubscription,
}) => {
    const { t } = useTranslation(['sott'], { wait: true });
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const date = new Date();
    const todaysDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    return (
        <SafeAreaView style={styles.changeSubscriptionContainer}>
            <View style={styles.changeSubscriptionHeaderContainer}>
                <Text style={styles.changeSubscriptionHeader}>
                    {t('svod.change.changeConfirm.title')}
                </Text>
            </View>
            <View
                style={
                    isLandscape
                        ? styles.changeSubscriptionInfoContainerLandscape
                        : styles.changeSubscriptionInfoContainer
                }
            >
                {currentPlan?.id && (
                    <View style={styles.changeSubscriptionPrimaryTextContainer}>
                        <Text style={styles.changeSubscriptionFirstPrimaryText}>{`${t(
                            'svod.currentSubscriptionBadge'
                        )} - ${currentPlan.name} - ${getCurrencySymbol(currentPlan.currency)}${
                            currentPlan.price
                        } / ${currentPlan.period}`}</Text>
                        <Text style={styles.changeSubscriptionSecondPrimaryText}>
                            {currentPlan.description}
                        </Text>
                    </View>
                )}
                <View style={styles.changeSubscriptionBoxContainer}>
                    <Text style={styles.changeSubscriptionBoxPrimaryText}>
                        {t('svod.newSubscriptionBadge')}
                    </Text>
                    <View style={styles.changeSubscriptionPlanInfoContainer}>
                        <Text style={styles.changeSubscriptionNewPlanName}>
                            {selectedPlan.name}
                            <Text style={styles.pricePerMonthPriceText}>
                                {`  ${getCurrencySymbol(selectedPlan.currency)}${
                                    selectedPlan.price
                                }`}
                                <Text style={styles.pricePerMonthSecondaryText}>
                                    {` / ${selectedPlan.period}`}
                                </Text>
                            </Text>
                        </Text>
                    </View>
                    {selectedPlan.description && (
                        <Text style={styles.changeSubscriptionNewPlanDescription}>
                            {selectedPlan.description}
                        </Text>
                    )}
                </View>
                <View>
                    <Text style={styles.changeSubscriptionDateText}>
                        {`${t('svod.add.addConfirm.startingFrom')} ${todaysDate}`}
                    </Text>
                </View>
                <View style={styles.changeSubscriptionConfirmButtonContainer}>
                    <SubactionButton
                        onPress={() => {
                            handleOnRequestSubscription(selectedPlan.id);
                        }}
                        title={t('subscription.confirm')}
                        additionalContainerStyles={{
                            width: 'auto',
                            height: 52,
                            paddingHorizontal: 24,
                        }}
                        additionalTextStyles={{ marginLeft: 0 }}
                    />
                </View>
                <TertiaryButton
                    onPress={closeChangeSubscriptionModal}
                    title="Cancel"
                    additionalContainerStyles={styles.changeSubscriptionCancelButtonContainer}
                    additionalTextStyles={styles.changeSubscriptionCancelButtonText}
                />
            </View>
        </SafeAreaView>
    );
};

ChangeSubscription.propTypes = {
    styles: PropTypes.func,
    isLandscape: PropTypes.bool,
    currentPlan: PropTypes.number,
    selectedPlan: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        price: PropTypes.string,
        description: PropTypes.string,
        currency: PropTypes.number,
    }),
    handleOnRequestSubscription: PropTypes.func,
    closeModal: PropTypes.func,
};

ChangeSubscription.defaultProps = {
    styles: getChangeSubscriptionStyles,
    isLandscape: false,
    currentPlan: -1,
    selectedPlan: {},
    handleOnRequestSubscription: () => {},
    closeModal: () => {},
};

export { getChangeSubscriptionStyles };
export default overridable(ChangeSubscription, 'ChangeSubscription');
