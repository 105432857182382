import {
    Asset,
    createRating,
    Rating,
    RatingResponse,
    RATING_SYSTEM,
} from '@24i/nxg-sdk-photon/src';

const toAppRatingSystem = (value: number, ratingSystem: RATING_SYSTEM): number => {
    switch (ratingSystem) {
        case RATING_SYSTEM.FIVESTARS:
        default:
            return Math.floor((value * 5) / 100);
    }
};

const toBSRating = (rating: Rating): number => {
    switch (rating.ratingSystem) {
        case RATING_SYSTEM.FIVESTARS:
        default:
            return Math.floor((rating.rating * 100) / 5);
    }
};

const mapRatingResponseToRating = (
    response: RatingResponse[] | undefined,
    asset: Asset,
    ratingSystem: RATING_SYSTEM
): Rating | undefined => {
    const assetRating = response?.find((item) => asset.id === item.assetId);
    if (assetRating && assetRating.rating !== 0) {
        const ratingObj = createRating({
            rating: toAppRatingSystem(assetRating.rating, ratingSystem),
            ratingSystem,
        });

        return ratingObj;
    }

    return undefined;
};

export { mapRatingResponseToRating, toBSRating };

let PIN_CODE: string;

export const getPin = (): string | undefined => {
    return PIN_CODE;
};

export const setPin = (pin: string) => {
    PIN_CODE = pin;
};

export const getPinIfExists = () => {
    const pin = getPin();

    return pin ? { pinCode: pin } : {};
};
