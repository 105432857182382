import { useMutation, useQueryClient } from 'react-query';
import { AddToFavoriesRequest, DeleteFromFavoritesRequest, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';

export const useDeleteFromFavoritesMutation = () => {
    const client = useUserData();
    const queryClient = useQueryClient();

    return useMutation(
        QUERY_KEYS.myFavorites,
        (id: DeleteFromFavoritesRequest['id']) => client.deleteFromFavorites(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEYS.myFavorites);
                queryClient.invalidateQueries(QUERY_KEYS.myFavoritesPlaylist);
                queryClient.invalidateQueries(QUERY_KEYS.playlists);
            },
        }
    );
};

export const useAddToFavoritesMutation = () => {
    const client = useUserData();
    const queryClient = useQueryClient();

    return useMutation(
        QUERY_KEYS.myFavorites,
        (params: {
            entityType: AddToFavoriesRequest['entityType'];
            entityId: AddToFavoriesRequest['entityId'];
        }) => client.addToFavorites(params.entityType, params.entityId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEYS.myFavorites);
                queryClient.invalidateQueries(QUERY_KEYS.myFavoritesPlaylist);
                queryClient.invalidateQueries(QUERY_KEYS.playlists);
            },
        }
    );
};
