import { OfferType } from '@24i/nxg-sdk-photon';
import { useTranslation } from 'react-i18next';
import { WebRouter, useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { PaymentOutcome, PaymentResultProps } from '../../components/PaymentResult/types';
import { goToMySubscriptionsPage } from '../../utils/navigation';

type RedirectionButton = {
    title: string;
    onPress: () => void;
};

type PaymentScreenData = {
    title: string;
    description: string;
    actionButton: RedirectionButton;
    cancelButton?: RedirectionButton;
};

type PaymentResultsElements = Record<PaymentOutcome, PaymentScreenData>;

export const usePaymentResultsElements = ({
    onContinueUrl,
    onCancelUrl,
    onTryAgainUrl,
    offerType,
}: PaymentResultProps) => {
    const { t } = useTranslation();
    const router = useRouter();

    const paymentOutcome: PaymentOutcome = onContinueUrl ? 'success' : 'failure';

    const getFailureComponent = ({ purpose }: { purpose: OfferType }) => {
        const isPaymentMethod = purpose === OfferType.PAYMENT_METHOD;
        const title = isPaymentMethod
            ? t('account.paymentMethod.add.failure.title')
            : t('error.H00.title');
        const description = isPaymentMethod
            ? t('account.paymentMethod.add.failure.description')
            : t('error.H00.body');

        const failureComponent: PaymentScreenData = {
            title,
            description,
            actionButton: {
                title: t('vod.add.tryAgainButton'),
                onPress: () => {
                    if (onTryAgainUrl != null) {
                        router.push(onTryAgainUrl);
                    }
                },
            },
            cancelButton: {
                title: t('common.cancel'),
                onPress: () => {
                    if (onCancelUrl != null) {
                        router.push(onCancelUrl);
                    }
                },
            },
        };

        return failureComponent;
    };

    const getSuccessActionButton = (buttonTitle?: string): RedirectionButton => {
        const isComingFromAsset = onContinueUrl && onContinueUrl.includes('detail');
        const title =
            buttonTitle ?? isComingFromAsset
                ? t('common.continue')
                : t('svod.add.addConfirm.goToMySubscriptions');

        const successActionButton = {
            title,
            onPress: () => {
                if (isComingFromAsset && onContinueUrl) {
                    router.push(onContinueUrl);
                } else {
                    goToMySubscriptionsPage(router as WebRouter, 'success');
                }
            },
        };

        return successActionButton;
    };

    const paymentResultsElementsMap: Record<OfferType, PaymentResultsElements> = {
        purchase: {
            success: {
                title: t('tvod.add.purchaseSuccess.title'),
                description: t('tvod.add.purchaseSuccess.description'),
                actionButton: getSuccessActionButton(),
            },
            failure: getFailureComponent({ purpose: OfferType.PURCHASE }),
        },
        subscription: {
            success: {
                title: t('svod.add.addConfirm.title'),
                description: t('svod.add.addConfirm.description'),
                actionButton: getSuccessActionButton(),
            },
            failure: getFailureComponent({ purpose: OfferType.SUBSCRIPTION }),
        },
        paymentMethod: {
            success: {
                title: t('account.paymentMethod.add.success.title'),
                description: t('account.paymentMethod.add.success.description'),
                actionButton: getSuccessActionButton(t('common.continue') as string),
            },
            failure: getFailureComponent({ purpose: OfferType.PAYMENT_METHOD }),
        },
    };

    return paymentResultsElementsMap[offerType][paymentOutcome];
};
