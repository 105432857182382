/* eslint-disable @typescript-eslint/no-unused-vars */
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getTitleStyles from './styles';
import { TitleStyleGetter } from './styles/types';

interface CreateProfileSectionTitleProps {
    label?: string;
    styles?: TitleStyleGetter;
}

const Title = (props: CreateProfileSectionTitleProps) => {
    const { styles: getStyles = getTitleStyles, label = 'Pick an option' } = props;

    const { theme } = useTheme();
    const dimensions = useDimensions();
    const style = getStyles({ theme, dimensions });
    const { t } = useTranslation(['sott']);

    return (
        <View style={style.container}>
            <Text style={[style.titleLabel, style.label]}>{t(label)}</Text>
        </View>
    );
};

export default overridable(Title, 'Title');
