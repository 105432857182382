import { getRuntimeConfig } from '../Application/initApp';

export async function getCommonServerSideProps(context) {
    // TODO: Check the web implementation of the clients
    const { themeDataClient, appStructureDataClient, appSettingsDataClient } = context.clients;
    const service = getRuntimeConfig('apiService');

    const serviceConfig = await appSettingsDataClient.fetchServiceConfig();

    const menu = await appStructureDataClient.fetchMenu(
        serviceConfig.features?.menus?.main,
        serviceConfig
    );
    const theme = await themeDataClient.fetchTheme();

    if (!service || !menu || !theme) {
        return {
            notFound: true,
        };
    }

    return {
        props: {
            service: JSON.parse(JSON.stringify(service || null)),
            menu: JSON.parse(JSON.stringify(menu || null)),
            theme: JSON.parse(JSON.stringify(theme || null)),
        }, // will be passed to the page component as props
    };
}
