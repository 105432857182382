import { useEffect, useState } from 'react';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useAppSettingsData } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { useAppConfigQuery } from '../query/useAppConfigQuery';
import { useUserQuery } from '../query/user';
import { useFirebase } from '../../context/Firebase';

interface UsePaymentPinProtectionProps {
    /** Indicates if pin protection is on or off. */
    isActive: boolean;
    /** Indicates if toggle to set on/off should be available to the user.
     *  If not, defaultValue from configuration will be applied when verifying purchases. */
    isToggleAvailable: boolean;
    /** Function to set new pin protection requirement for current user. */
    onSetPaymentsPinProtection: (value: boolean) => Promise<void>;
}

const usePaymentPinProtection = (): UsePaymentPinProtectionProps => {
    const {
        client: { isPinProtectionForPaymentsSet, setPinProtectionForPayments },
    } = useAppSettingsData();
    const { appSettings } = useAppConfigQuery();
    const { user } = useUserQuery();
    const { recordError } = useFirebase();

    const [isActive, setIsActive] = useState(
        !!appSettings?.features.paymentsPinProtection?.defaultValue
    );

    const isToggleAvailable = !!appSettings?.features.paymentsPinProtection?.showToggle;

    const getPinProtectionValueFromStorage = async () => {
        const isPinProtectionSet = await isPinProtectionForPaymentsSet(user?.id);
        if (isPinProtectionSet !== undefined) setIsActive(isPinProtectionSet);
    };

    const onSetPaymentsPinProtection = async (newValue: boolean) => {
        try {
            if (!user?.id) throw Error('User id not found');
            await setPinProtectionForPayments(user.id, newValue);
        } catch (error) {
            recordError(error);
            log(error);
        }
    };

    useEffect(() => {
        // If is available, we get the value from storage, otherwise we use default value from config.
        if (isToggleAvailable) getPinProtectionValueFromStorage();
    }, []);

    return { isActive, isToggleAvailable, onSetPaymentsPinProtection };
};

export default usePaymentPinProtection;
