import { getFont } from '@24i/nxg-sdk-higgs';

const AccountAddSubscription = (theme) => ({
    explanationText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body2'),
    },
});

export default AccountAddSubscription;
