import React from 'react';
import { EpgDataProvider } from '@24i/nxg-sdk-smartott-shared/src/context/EpgData';

import { RecordingsProvider } from '@24i/nxg-sdk-smartott-shared/src/context/Recordings';

import { DevicesManagementProvider } from '@24i/nxg-sdk-smartott-shared/src/context/DevicesManagement';
import { RemindersProvider } from '@24i/nxg-sdk-smartott-shared/src/context/Reminders';

export const DefaultClientStubsProvider = (props) => {
    const { clients, children } = props;
    const { epgDataClient, recordingsClient, devicesManagementClient, remindersClient } = clients;
    return (
        <EpgDataProvider client={epgDataClient}>
            <RecordingsProvider client={recordingsClient}>
                <DevicesManagementProvider client={devicesManagementClient}>
                    <RemindersProvider client={remindersClient}>{children}</RemindersProvider>
                </DevicesManagementProvider>
            </RecordingsProvider>
        </EpgDataProvider>
    );
};
