import React from 'react';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Subscription } from '@24i/nxg-sdk-photon';
import StepFlowModal from '../../../../components/StepFlowModal';

type ConfirmCancelSubscriptionModalProps = {
    isCancelConfirmModalVisible: boolean;
    handleOnSubscriptionCancelConfirm: () => void;
    closeCancelSubscriptionConfirmModal: () => void;
    // Will be used in the future
    // eslint-disable-next-line react/no-unused-prop-types
    subscriptionToCancel?: Subscription;
};

const ConfirmCancelSubscriptionModal = ({
    isCancelConfirmModalVisible,
    handleOnSubscriptionCancelConfirm,
    closeCancelSubscriptionConfirmModal,
}: ConfirmCancelSubscriptionModalProps) => {
    const { t } = useTranslation();

    return (
        <StepFlowModal
            isVisible={isCancelConfirmModalVisible}
            currentStepData={{
                layout: 'status',
                title: t('common.confirm'),
                content: t('svod.cancel.cancelDoubleConfirm.description'),
                actionButton: {
                    title: t('svod.cancel.cancelDoubleConfirmButton'),
                    onPress: () => handleOnSubscriptionCancelConfirm(),
                },
                cancelButton: {
                    title: t('common.backGo'),
                    onPress: () => closeCancelSubscriptionConfirmModal(),
                },
            }}
            handleOnBackPress={closeCancelSubscriptionConfirmModal}
            onModalClose={closeCancelSubscriptionConfirmModal}
        />
    );
};

export default overridable(ConfirmCancelSubscriptionModal, 'ConfirmCancelSubscriptionModal');
