import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { SubscriptionSelectionStyles } from './types';

const getStyles = (theme: Theme, isOnboarding?: boolean): SubscriptionSelectionStyles => ({
    wrapper: {
        paddingBottom: 32,
    },
    messageWrapper: {
        paddingBottom: 16,
        paddingRight: 16,
    },
    message: {
        ...getFont(theme, 'body2'),
        color: theme.color.textPrimary,
        opacity: 0.6,
        textAlign: isOnboarding ? 'center' : 'left',
    },
    contentContainerStyle: {
        paddingHorizontal: 0,
    },
    itemWrapper: {
        paddingBottom: 8,
    },
});

export default getStyles;
