import { Broadcast, PageSection } from '@24i/nxg-sdk-photon';
import { BackstageBroadcast, BackstagePageSection, PaginatedResponse } from '../guards';
import { determineAgeClassification } from '../utils';

export const mapPageSection = (pageSection: BackstagePageSection): PageSection => ({
    ...pageSection,
    display: pageSection.display === 'poster' ? 'portrait' : 'landscape',
});

export const mapBroadcast = (broadcast: BackstageBroadcast): Broadcast => ({
    ...broadcast,
    channel: {
        id: broadcast?.channel?.id || broadcast.channelId,
        number: Number(broadcast.channel?.number),
        label: '',
        features: null,
    },
    start: broadcast.startsAt * 1000,
    end: broadcast.endsAt * 1000,
    ageClassification: determineAgeClassification(broadcast),
});

export const mapProgramsResponse = (
    programsResponse: PaginatedResponse<BackstageBroadcast>
): PaginatedResponse<Broadcast> => ({
    ...programsResponse,
    items: programsResponse.items.map(mapBroadcast),
});
