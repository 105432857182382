import React from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { StepFlowModalProps } from './types';
import getStyles from './styles';
import { isStepStatusStep } from './utils';
import StatusModalLayout from '../modalLayouts/StatusModalLayout';
import ModalLayoutWithBackButton from '../modalLayouts/ModalLayoutWithBackButton';

const StepFlowModal = ({
    isVisible,
    onModalClose,
    currentStepData,
    handleOnBackPress,
}: StepFlowModalProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <Modal visible={isVisible} onRequestClose={onModalClose}>
            <View style={styles.modalContentContainer}>
                <View style={styles.modalContent}>
                    {isStepStatusStep(currentStepData) ? (
                        <StatusModalLayout
                            title={currentStepData.title}
                            actionButton={currentStepData.actionButton}
                            cancelButton={currentStepData.cancelButton}
                        >
                            {currentStepData.content}
                        </StatusModalLayout>
                    ) : (
                        <ModalLayoutWithBackButton
                            isBackButtonVisible={currentStepData.isBackButtonVisible}
                            onBackPress={handleOnBackPress}
                        >
                            {currentStepData.content}
                        </ModalLayoutWithBackButton>
                    )}
                </View>
            </View>
        </Modal>
    );
};

export default StepFlowModal;
