import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle, ViewStyle } from 'react-native';

const getTopBarMenuDropdownStyles = (theme: Theme) => ({
    itemContainer: {
        borderColor: 'transparent',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        color: `${theme.color.menuItemActive}80`,
        '@media (max-width: 800px)': {
            marginTop: 4,
            marginBottom: 4,
            flexDirection: 'column',
            marginRight: 0,
        },
        ':hover': {
            color: `${theme.color.menuItemActive}`,
        },
    } as ViewStyle,
    titleContainer: {
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        marginLeft: 16,
    } as ViewStyle,
    title: {
        padding: 0,
        fontSize: 16,
        ...getFont(theme, 'menuItem-inactive'),
        color: theme.color.menuItemInactive,
    } as ViewStyle,
    titleActive: {
        color: theme.color.menuItemActive,
        ...getFont(theme, 'menuItem-active'),
    } as ViewStyle,
    iconImage: {
        width: 21,
        height: 21,
        marginRight: 8,
        marginLeft: 4,
        '@media (max-width: 800px)': {
            width: 24,
            height: 24,
            marginRight: 0,
            marginBottom: 4,
        },
    } as ViewStyle,
    profileAvatarImageContainer: {
        width: 36,
        height: 36,
        marginLeft: 10,
        borderRadius: 4,
        overflow: 'hidden',
    } as ViewStyle,
    icon: {
        fontSize: 20,
        marginRight: 8,
        marginLeft: 4,
        paddingLeft: 1,
        color: 'rgba(255, 255, 255, 0.6)',
        '@media (max-width: 800px)': {
            fontSize: 24,
            marginRight: 0,
            marginLeft: 0,
            marginBottom: 4,
        },
    } as TextStyle,
    iconActive: {
        color: theme.color.menuItemActive,
        opacity: 1,
    },
    dropdownContainer: {
        flexDirection: 'row',
        backgroundColor: theme.color.darker1,
        borderRadius: 4,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        position: 'absolute',
        minWidth: 225,
        maxHeight: '80vh',
        right: 0,
        top: 45,
    } as ViewStyle,
    scrollContainer: {
        position: 'absolute',
        marginTop: 6,
        top: 0,
        right: 2,
        bottom: 0,
        height: '100%',
    } as ViewStyle,
    scrollIndicator: {
        width: 3,
        borderRadius: 1.5,
        backgroundColor: theme.color.contrast3,
        opacity: 0.5,
    } as ViewStyle,
});

export { getTopBarMenuDropdownStyles };
