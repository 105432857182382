/* eslint-disable react/display-name */
import React, { createContext, useContext } from 'react';

import { ContentDataClient, getDummyClient } from '@24i/nxg-sdk-photon';

const defaultClient = getDummyClient<ContentDataClient>();

export const ContentDataContext = createContext<any>(defaultClient);

export const ContentDataProvider = ({ client = defaultClient, children }) => (
    <ContentDataContext.Provider value={client}>{children}</ContentDataContext.Provider>
);

export const useContentData = () => useContext(ContentDataContext);
