import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Profile } from '@24i/nxg-sdk-photon';
import { TextInput, View } from '@24i/nxg-sdk-quarks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_PROFILE_NAME_LENGTH } from '../../constants';
import getNameInputStyles from './styles';
import { NameInputStyleGetter } from './styles/types';

interface NameInputProps {
    userProfile: Profile | null;
    onProfileNameChange: (name: string) => void;
    styles?: NameInputStyleGetter;
}

const NameInput = ({
    styles: getStyles = getNameInputStyles,
    userProfile,
    onProfileNameChange,
}: NameInputProps) => {
    const { theme } = useTheme();
    const styles = getStyles({ theme, hasText: Boolean(userProfile?.name) });
    const { t } = useTranslation(['sott']);
    const handleTextChange = (text: string) => {
        onProfileNameChange(text);
    };

    return (
        <View style={styles?.container}>
            <TextInput
                maxLength={MAX_PROFILE_NAME_LENGTH}
                style={[styles.textInput]}
                onChangeText={handleTextChange}
                value={userProfile?.name || ''}
                placeholder={t('profiles.name.label') as string}
                keyboardType="default"
                placeholderTextColor={styles.placeholderColor}
                testID="profile_name_input"
            />
        </View>
    );
};

export default overridable(NameInput, 'NameInput');
