import React from 'react';
import PropTypes from 'prop-types';

import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import Content from '../components/SettingsScreenContent';
import { withWebHeader } from '../../../navigation/components/withWebHeader';

const SettingsScreen = ({
    additionalOptions,
    customHeader,
    headerTitle,
    masterButtonIcons,
    customComponentBeforeOptions,
    customComponentAfterOptions,
    versionString,
    onBack,
    getFilteredOptions,
    filterOptions,
}) => {
    const router = useRouter();

    const initialOptions = additionalOptions();

    const options = getFilteredOptions(initialOptions, filterOptions);

    const onBackDefault = () => {
        router.back();
    };

    return (
        <Content
            router={router}
            options={options}
            headerTitle={headerTitle}
            customHeader={customHeader}
            onBack={onBack || onBackDefault}
            masterButtonIcons={masterButtonIcons}
            customComponentBeforeOptions={customComponentBeforeOptions}
            customComponentAfterOptions={customComponentAfterOptions}
            versionString={versionString}
        />
    );
};

SettingsScreen.defaultProps = {
    additionalOptions: () => ({}),
    filterOptions: (defaultOptions) => defaultOptions,
    masterButtonIcons: true,
    customHeader: null,
    customComponentBeforeOptions: null,
    customComponentAfterOptions: null,
    onBack: null,
    headerTitle: null,
    versionString: null,
};

SettingsScreen.propTypes = {
    additionalOptions: PropTypes.func,
    filterOptions: PropTypes.func,
    customHeader: PropTypes.node,
    masterButtonIcons: PropTypes.bool,
    customComponentBeforeOptions: PropTypes.node,
    customComponentAfterOptions: PropTypes.node,
    onBack: PropTypes.func,
    headerTitle: PropTypes.string,
    versionString: PropTypes.string,
};

export default withWebHeader(overridable(SettingsScreen, 'SettingsScreen'));
