/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import View from './View';
import { useViewModel } from './viewModel';
import { GenreScreenProps, GenreViewModelProps } from './types';
import GenreScreenStyles from './styles/index.web';

export default {
    Main: (props: GenreScreenProps): ReactElement => {
        const viewModelProps: GenreViewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    useViewModel,
    View,
    getDefaultStyls: GenreScreenStyles,
};
