/**
 * Converts seconds to hh:minutes:seconds format
 * @param {number} timeInput
 * @returns {string}
 */
export const getFormattedTime = (timeInput) => {
    const convert = (num, size) => `00${num}`.slice(size * -1);

    const time = parseFloat(timeInput).toFixed(3);
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time - minutes * 60);

    return `${convert(hours, 2)}:${convert(minutes, 2)}:${convert(seconds, 2)}`;
};
/**
 * Renders the time progress
 * @param {number} duration
 * @param {number} progress
 * @returns {string}
 */
export const getTime = (time) => {
    const { duration, progress } = time;
    const total = getFormattedTime(duration);
    const current = getFormattedTime(progress);
    return `${total} / ${current}`;
};
/**
 * Renders the total duration formatted
 * @param {object} time
 * @returns {string}
 */
export const getTotalTime = (time) => {
    const { duration } = time;
    const total = getFormattedTime(duration);
    return `${total}`;
};
/**
 * Renders the current progress formatted
 * @param {object} time
 * @returns {string}
 */
export const getCurrentTime = (time) => {
    const { progress } = time;
    const current = getFormattedTime(progress);
    return `${current}`;
};
