import React, { createContext, useContext } from 'react';

import { getDummyClient, EpgDataClient } from '@24i/nxg-sdk-photon';

const dummyClient = getDummyClient<EpgDataClient>();

export const EpgDataContext = createContext<EpgDataClient>(dummyClient);

export const EpgDataProvider = ({ client = dummyClient, children }) => (
    <EpgDataContext.Provider value={client}>{children}</EpgDataContext.Provider>
);

export const useEpgData = () => useContext(EpgDataContext);
