import { useEffect } from 'react';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { NextError404ScreenProps } from './types';
import { useCurrentWebPageConfig } from '../../hooks/useCurrentWebPageConfig/index.web';
import { useAppStart } from '../../context/AppStartContext/index.web';

// There needs to be a slight delay because routing is not synchronous
// with JS runtime leading to weird side effects.
// This might not be 100% reliable but seems to be good enough for
// the fallblack solution.
const REDIRECT_DELAY = 1000;

// Next JS doesn't route the page properly as it should if it is a dynamic
// route. It happens only when you specify custom page directory.
// There is no easy fix for this as we are using an outdated version of Next.
// This is trying to check the web pages definitions for a matching
// route and if it finds one it redirects to it.
// This should be used only used if you have these issues.
export const use404FallbackRedirect = (props: NextError404ScreenProps): NextError404ScreenProps => {
    const { currentPageConfig } = useCurrentWebPageConfig();
    const router = useRouter();
    const { dangerouslyOverrideInitialWebRoute } = useAppStart();
    const href = currentPageConfig?.dangerouslyGetWebLink?.({}).href;
    const isFalse404 = Boolean(currentPageConfig && href && href !== '/404');

    useEffect(() => {
        let timeout: any;
        if (href) {
            dangerouslyOverrideInitialWebRoute({ href, as: router.asPath });
            timeout = setTimeout(
                () =>
                    router?.replace(
                        {
                            pathname: href || '',
                            query: router.query,
                        },
                        router.asPath
                    ),
                REDIRECT_DELAY
            );
        }
        return () => clearTimeout(timeout);
    }, []);

    return {
        ...props,
        isFalse404,
    };
};
