import React from 'react';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { MySubscriptionsContentProps } from '../types';
import ConfirmCancelSubscriptionModal from '../../ConfirmCancelSubscriptionModal';
import withErrorModal from '../../../../../components/GenericModal/hoc/ErrorModal';

const MySubscriptionsWebContent = ({
    subscriptionToCancel,
    isCancelConfirmModalVisible,
    closeCancelSubscriptionConfirmModal,
    handleOnSubscriptionCancelConfirm,
    getCurrentStep,
}: MySubscriptionsContentProps) => {
    return (
        <>
            {getCurrentStep()}
            <ConfirmCancelSubscriptionModal
                subscriptionToCancel={subscriptionToCancel}
                isCancelConfirmModalVisible={isCancelConfirmModalVisible}
                handleOnSubscriptionCancelConfirm={handleOnSubscriptionCancelConfirm}
                closeCancelSubscriptionConfirmModal={closeCancelSubscriptionConfirmModal}
            />
        </>
    );
};

export default withErrorModal(withWebHeader(MySubscriptionsWebContent));
