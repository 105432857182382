import React, { ReactNode } from 'react';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { getFont, useTheme } from '@24i/nxg-sdk-higgs';
import { TextStyle, ViewStyle } from 'react-native';
import { Theme } from '@24i/nxg-sdk-photon/src';

interface LabelStyles {
    text: TextStyle;
    container: ViewStyle;
}

type LabelStylesGetter = (theme: Theme, active: boolean) => LabelStyles;

interface LabelProps {
    active: boolean;
    title: ReactNode;
    getStyles?: LabelStylesGetter;
}

const getDefaultStyle = (theme: Theme, active: boolean): LabelStyles => ({
    text: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h6'),
        textTransform: 'uppercase',
    },
    container: {
        paddingLeft: 6,
        paddingRight: 6,
        backgroundColor: theme.color.labelPrimary,
        borderRadius: 4,
        opacity: active ? 1 : 0.5,
        marginRight: 3,
    },
});

export const Label = ({ title, getStyles = getDefaultStyle, active = false }: LabelProps) => {
    const { theme } = useTheme();

    const styles = getStyles(theme, active);

    return (
        <View style={styles.container}>
            <Text
                style={styles.text}
                testID={`${String(title).toLowerCase().replace(/\s+/g, '_')}_button`}
            >
                {title}
            </Text>
        </View>
    );
};
