import { getFont } from '@24i/nxg-sdk-higgs';
import { getTextPagesStylesGetter } from '../types';
import { Breakpoint } from '../../../utils/styles/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getTextPagesStyles: getTextPagesStylesGetter = ({ theme, dimensions }) => {
    return {
        wrapper: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: 120,
            width: '100%',
        },
        textContainer: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: '100%',
            width: Breakpoint.SM,
            [`@media (max-width: ${Breakpoint.MD}px)`]: {
                width: '100%',
                paddingHorizontal: 20,
            },
        },
        scrollViewContainerStyle: {
            paddingHorizontal: 20,
        },
        title: {
            ...getFont(theme, 'h2'),
            color: theme.color.textPrimary,
            paddingBottom: 64,
        },
        loader: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
    };
};
