import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import MenuItem from '../../../../../components/MenuItem';
import { useMenuItemPress } from '../../../../../navigation/utils';
import { MenuItemToRender, MoreScreenMenuProps } from '../types';

export const defaultRenderMenuItem = (props: MenuItemToRender) => <MenuItem {...props} />;

const MoreScreenMenu = ({
    menuItems = [],
    renderMenuItem = defaultRenderMenuItem,
}: MoreScreenMenuProps) => {
    const onPress = useMenuItemPress();

    return (
        <>
            {menuItems.map((item, index) =>
                renderMenuItem(
                    {
                        testID: `${item.name}_button`,
                        key: `menuItem-${item.name}-${index}`,
                        title: item.menuTitle as string,
                        icon: item.icon,
                        onPress: () => onPress(item),
                    },
                    { item, index }
                )
            )}
        </>
    );
};

export default overridable(MoreScreenMenu, 'MoreScreenMenu');
