import { BlockedItem, BLOCKING_REASON_TYPES } from '@24i/nxg-sdk-photon';

export enum DIALOG_MODAL_TEST_IDS {
    MODAL_WINDOW = 'blocked_modal_window',
    TITLE = 'blocked_modal_title',
    SUBTITLE = 'blocked_modal_subtitle',
    BUTTON_CANCEL = 'blocked_modal_button_cancel',
    BUTTON_CONFIRM = 'blocked_modal_button_confirm',
}

export enum BlockModalTypes {
    ACCESS = 'access',
    GEOBLOCK = 'geoBlock',
    ACCOUNT = 'account',
    CONCURRENCY = 'conccurrent_users',
    MVPD_PROTECTION_LEVEL_ACCESS = 'mvpd_protection_level_access',
    MVPD_PROTECTION_LEVEL_FULL = 'mvpd_protection_level_full',
}

export type Blocker = {
    type: BLOCKING_REASON_TYPES;
    blocker?: BlockedItem | null;
};

export interface WithBlockedModalProps {
    openBlockedModal: (type: BlockModalTypes, subtitle?: string, modalProps?: any) => void;
}
