import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { PlayerOpenResult, Payload } from '../types';
import { mapBase } from './mapBase';

export const mapPlayerOpen = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): PlayerOpenResult => {
    return {
        ...mapBase(payload),
        action: triggerName,
        event_trigger: triggerName,
        source_page: SOTT_DEFAULT_SCREENS.PLAYBACK,
        thing_id: payload.payload.asset?.id || '',
        source_rail: payload.payload.asset.sectionLabel,
    };
};
