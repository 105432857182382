import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import AppGrid from '../../../components/AppGrid';
import { MyListViewProps } from '../types';

const MyListScreen = ({ handlePackshotPress, ...props }: MyListViewProps) => {
    // @ts-ignore
    return <AppGrid handleOnPackshotPress={handlePackshotPress} {...props} />;
};

export default withWebHeader(overridable(MyListScreen, 'MyListScreen'));
