import React, { ComponentType, useState } from 'react';
import { Asset } from '@24i/nxg-sdk-photon/src';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import PurchasesModal from '..';

export interface WithPurchasesModalProps {
    isPurchaseModalVisible: boolean;
    openPurchaseModal: (asset: Asset) => void;
    closePurchaseModal: () => void;
}

const withPurchaseModal =
    <P extends WithPurchasesModalProps>(Component: ComponentType<P>) =>
    // eslint-disable-next-line react/display-name
    (props: Omit<P, keyof WithPurchasesModalProps>) => {
        const [isModalVisible, setIsVisible] = useState(false);
        const [asset, setAsset] = useState<Asset | null>(null);

        const openPurchaseModal = (currentAsset: Asset) => {
            setAsset(currentAsset);
            setIsVisible(true);
        };

        const closePurchaseModal = () => {
            setIsVisible(false);
            onAnalytics(ANALYTICS_TRIGGERS.PURCHASE_CANCEL, {
                event: ANALYTICS_EVENTS.CANCELLED_PURCHASE,
            });
        };

        return (
            <>
                <Component
                    {...(props as P)}
                    openPurchaseModal={openPurchaseModal}
                    closePurchaseModal={closePurchaseModal}
                    isPurchaseModalVisible={isModalVisible}
                />
                <PurchasesModal
                    isVisible={isModalVisible}
                    asset={asset}
                    closeModal={closePurchaseModal}
                    onModalClose={closePurchaseModal}
                />
            </>
        );
    };

export { withPurchaseModal };
