import { TextStyle, ViewStyle } from 'react-native';
import { isPlatformWeb } from 'renative';
import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { RecordingButtonProps } from '../../../types';

interface RecordingButtonStyle {
    wrapper: ViewStyle;
    iconWrapper: ViewStyle;
    title: TextStyle;
}

const RecordingButton = (
    theme: Theme,
    { disabled }: Partial<RecordingButtonProps>
): RecordingButtonStyle => ({
    wrapper: {
        opacity: disabled ? 0.4 : 1,
        backgroundColor: theme.color.lighter4,
        height: 56,
        marginHorizontal: isPlatformWeb ? 0 : 20,
        marginBottom: isPlatformWeb ? 0 : 16.5,
        marginTop: isPlatformWeb ? 24.5 : 16.5,
        display: 'flex',
        alignItems: 'center',
        paddingHorizontal: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 8,
        borderColor: theme.color.labelPrimary,
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 8,
    },
    title: { ...getFont(theme, 'h4'), color: theme.color.textPrimary },
});

export default RecordingButton;
