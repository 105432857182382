import { useEffect } from 'react';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import { ROW_TYPES, Playlist, Asset, PLAYLIST_TYPE, PageSection } from '@24i/nxg-sdk-photon/src';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import useMyPurchasesQuery from '../../../hooks/query/purchases/useMyPurchasesQuery';
import useMyRentalsQuery from '../../../hooks/query/purchases/useMyRentalsQuery';
import useMyExpiredRentalsQuery from '../../../hooks/query/purchases/useMyExpiredRentalsQuery';
import { MyPurchasesModelProps, MyPurchasesViewProps } from '../types';
import { SOTT_DEFAULT_SCREENS, SOTT_DEFAULT_WEB_SCREENS } from '../../../navigation/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useViewModel = (props: MyPurchasesModelProps): MyPurchasesViewProps => {
    const { t } = useTranslation();
    const { data: myPurchases, isLoading: isMyPurchasesLoading } = useMyPurchasesQuery();
    const { data: myRentals, isLoading: isMyRentalsLoading } = useMyRentalsQuery();
    const { data: myExpiredRentals, isLoading: isMyExpiredRentalsLoading } =
        useMyExpiredRentalsQuery();
    const { crashlyticsLog } = useFirebase();
    const router = useRouter();
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

    const assetActions = useAssetActions();

    useEffect(() => {
        crashlyticsLog('MyPurchasesScreen entered');
        return crashlyticsLog('MyPurchasesScreen exited');
    }, []);

    const addCoverToItems = (playlistData: Playlist['items']): Asset[] =>
        playlistData.map((item) => {
            return { ...item, ...{ poster: item?.images?.still?.[0]?.url } };
        });

    const myPurchasesSections: PageSection[] = myPurchases?.items.length
        ? [
              {
                  label: t('tvod.manage.bought'),
                  type: ROW_TYPES.SINGLE_ROW,
                  id: PLAYLIST_TYPE.PURCHASED,
                  items: addCoverToItems(myPurchases.items),
                  display: 'landscape',
                  actionForAllItems: 'detail',
              },
          ]
        : [];

    const myRentalsSections: PageSection[] = myRentals?.items.length
        ? [
              {
                  label: t('tvod.manage.rented'),
                  type: ROW_TYPES.SINGLE_ROW,
                  id: PLAYLIST_TYPE.RENTALS,
                  items: addCoverToItems(myRentals.items),
                  display: 'landscape',
                  actionForAllItems: 'detail',
              },
          ]
        : [];

    const myExpiredRentalsSections: PageSection[] = myExpiredRentals?.items.length
        ? [
              {
                  label: t('tvod.manage.expired'),
                  type: ROW_TYPES.SINGLE_ROW,
                  id: PLAYLIST_TYPE.EXPIRED_RENTALS,
                  items: addCoverToItems(myExpiredRentals.items),
                  display: 'landscape',
                  actionForAllItems: 'detail',
              },
          ]
        : [];

    const sections = [...myPurchasesSections, ...myRentalsSections, ...myExpiredRentalsSections];

    const isLoading =
        !sections && isMyPurchasesLoading && isMyRentalsLoading && isMyExpiredRentalsLoading;

    const handleSeeAllItemPress = (section: PageSection) => {
        if (isPlatformWeb) {
            const link = SOTT_DEFAULT_WEB_SCREENS.Playlist.getLink({
                playlist: section.id || '',
            });
            router.push(link.href, link.as);
        } else {
            navigation.navigate(SOTT_DEFAULT_SCREENS.PLAYLIST, {
                playlistId: section.id,
            });
        }
    };

    const handlePackshotPress = ({ asset, section }) => {
        assetActions.handlePackshotPress({ asset, action: section.actionForAllItems });
    };

    return {
        ...props,
        sections,
        isLoading,
        backButtonTitle: `${t('common.backTo')} ${t('myContent.title')}`,
        webHeaderTitle: t('tvod.manage.title'),
        fullWidth: isPlatformWeb,
        hasMargin: false,
        handlePackshotPress,
        handleSeeAllItemPress,
    };
};

export default useViewModel;
