import { getFont, getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { EpisodePickerStyles, EpisodePicketStylesGetter } from '../types';

const EpisodePicker: EpisodePicketStylesGetter = (theme: Theme): EpisodePickerStyles => ({
    episodePickerContainer: {
        marginTop: 32,
        ...getMargins(),
    },
    seasonPickerContainer: {
        flexDirection: 'column',
        marginBottom: 32,
        zIndex: 999,
    },
    episodePickerItem: {
        marginBottom: 24,
    },
    episodeHolder: {
        flex: 1,
        flexDirection: 'row',
    },
    episodeTitleContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 16,
    },
    episodeTitleText: {
        ...getFont(theme, 'h4'),
        color: theme.color.textPrimary,
    },
    episodeDurationText: {
        ...getFont(theme, 'h5'),
        color: theme.color.textPrimary,
        opacity: 0.6,
    },
    textWrapper: {
        width: 760,
        opacity: 0.6,
        '@media (max-width: 992px)': {
            width: '100%',
        },
    },
    episodeDescriptionText: {
        ...getFont(theme, 'body2'),
        color: theme.color.textPrimary,
    },
});
export default EpisodePicker;
