import { useEffect, useState } from 'react';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { isPlatformWeb } from 'renative';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useTranslation } from 'react-i18next';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { emailValidatorReg } from '../../../utils/regexConsts.js';
import { ForgotPasswordProps, ViewModelReturnType } from '../types';

const useViewModel = (props: ForgotPasswordProps): ViewModelReturnType => {
    const { navigation, isModal } = props;

    const userDataClient = useUserData();
    const { recordError, crashlyticsLog } = useFirebase();
    const { t } = useTranslation();
    const router = useRouter();
    const { setPreviousModal } = useModal();

    const [email, setEmail] = useState<string>('');
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSendLinkPress = async (text: string): Promise<void | null> => {
        setIsLoading(true);
        return userDataClient
            .recoverPassword(text)
            .then(() => {
                setIsLoading(false);
                showToast(`${t('password.forgot.requestPasswordReset.success')}`);
            })
            .catch((err) => {
                recordError(err);
                setIsLoading(false);
                setHasError(true);
                setErrorMessage(`${t('error.E11.body')}`);
            });
    };

    const onBackToSignIn = () => {
        if (isPlatformWeb) {
            if (isModal) {
                setPreviousModal();
                return;
            }
            router.back();
            return;
        }
        navigation?.navigate(SOTT_DEFAULT_SCREENS.SIGN_IN);
    };

    const onEmailChange = (text: string) => {
        if (hasError) setHasError(false);
        setIsValidEmail(emailValidatorReg.test(text));
        setEmail(text);
    };

    useEffect(() => {
        if (isPlatformWeb) return () => null;

        const didFocus = navigation?.addListener('focus', () => {
            crashlyticsLog('ForgotPasswordScreen entered');
        });
        const willBlur = navigation?.addListener('blur', () => {
            crashlyticsLog('ForgotPasswordScreen exited');
        });

        return () => {
            if (didFocus) didFocus();
            if (willBlur) willBlur();
        };
    }, []);

    return {
        ...props,
        email,
        isValidEmail,
        onEmailChange,
        hasError,
        errorMessage,
        isLoading,
        onSendLinkPress,
        onBackToSignIn,
    };
};

export { useViewModel };
