import { getFont } from '@24i/nxg-sdk-higgs/src';
import { Theme } from '@24i/nxg-sdk-photon/src';
import getDefaultTertiaryButtonStyles from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton/styles';
import { MvpdSignInContentStyles } from '../types';

export const getMvpdSignInContentStyles = (theme: Theme): MvpdSignInContentStyles => {
    const allProvidersButton = getDefaultTertiaryButtonStyles(theme);
    return {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 999,
            width: 624,
            height: 795,
            backgroundColor: theme.color.primary1,
            paddingLeft: 24,
            paddingRight: 8,
        },
        headerSection: {
            flexDirection: 'row',
            marginTop: 24,
            zIndex: 999,
        },
        headerTitle: {
            color: theme.color.textPrimary,
            fontWeight: 'bold',
            textAlign: 'center',
            flexGrow: 1,
            ...getFont(theme, 'h2'),
        },
        pageDescriptionText: {
            ...getFont(theme, 'body2'),
            color: theme.color.textPrimary,
            opacity: 0.6,
            textAlign: 'center',
            padding: 4,
        },
        pageDescriptionContainer: {
            minHeight: 50,
            width: '100%',
            marginTop: 16,
        },
        allProvidersButtonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 50,
            width: '100%',
            marginBottom: 24,
            marginTop: 16,
        },
        allProvidersButton: {
            ...allProvidersButton,
            holder: {
                ...allProvidersButton.holder,
                backgroundColor: 'transparent',
                width: 160,
                height: 42,
                alignSelf: 'center',
            },
        },
        haveAlookButton: {
            ...allProvidersButton,
            holder: {
                ...allProvidersButton.holder,
                backgroundColor: 'transparent',
                width: '60%',
                alignSelf: 'center',
                marginBottom: 20,
            },
        },
    };
};
