import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidatePinQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/user/useValidatePin';
import { PinInputModalContentProps, PinInputModalContentViewProps } from '../types';

export const useViewModel = ({
    profileWithPin,
    onSubmit: propsOnSubmit,
    onModalClose,
    ...props
}: PinInputModalContentProps): PinInputModalContentViewProps => {
    const { t } = useTranslation(['sott']);
    const { validatePinCode, isLoading } = useValidatePinQuery();

    const [isIncorrectPin, setIncorrectPin] = useState(false);

    const onFinish = (text: { value: string }) => {
        const pinCode = text.value;
        setIncorrectPin(false);
        validatePinCode(pinCode, {
            onSuccess: (data) => {
                const { isPinCodeValid } = data;
                if (isPinCodeValid) {
                    const shouldCloseModal = !propsOnSubmit?.(parseInt(pinCode, 10));
                    if (shouldCloseModal) onModalClose?.();
                } else {
                    setIncorrectPin(true);
                }
            },
        });
    };

    const defaultMessage = profileWithPin
        ? t('pin.enter.swithProfile.description')
        : t('pin.enter.description');

    return {
        defaultMessage,
        isLoading,
        isIncorrectPin,
        onFinish,
        ...props,
    };
};
