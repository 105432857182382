import React, { useEffect, useState } from 'react';
import { Interactable } from '@24i/nxg-sdk-quarks';
import { getLowerValue, getHigherValue } from '../../utils';
import { ThumbProps } from './types';

const SAFE_TIMEOUT = 100;
const MINUS = 'minus';
const PLUS = 'plus';
const THUMB = 'thumb';

const Thumb = ({
    value,
    min,
    max,
    step,
    tintColor,
    activeTintColor,
    style,
    height,
    onChange,
    disabled,
    renderPlusButton,
    renderMinusButton,
    onFinish,
    onStart,
}: ThumbProps): JSX.Element => {
    const [finishToBeCalled, setFinishToBeCalled] = useState(false);
    const [isEntered, setIsEntered] = useState(false);
    const [focused, setFocused] = useState({
        minusFocused: false,
        thumbFocused: false,
        plusFocused: false,
    });
    const left = (100 * (value - min)) / (max - min);
    const focusStep = step || (max - min) / 10;
    const lowerValue = getLowerValue({ value, focusStep, min });
    const higherValue = getHigherValue({ value, focusStep, max });

    const start = () => {
        onStart(value);
        setFinishToBeCalled(true);
    };

    const finish = () => {
        onFinish(value);
        setFinishToBeCalled(false);
    };

    const callFinishIfBlurred = () => {
        if (
            finishToBeCalled &&
            !focused.minusFocused &&
            !focused.thumbFocused &&
            !focused.plusFocused
        ) {
            finish();
            setIsEntered(false);
        }
    };

    useEffect(() => {
        if (finishToBeCalled) {
            setTimeout(callFinishIfBlurred, SAFE_TIMEOUT);
        }
    }, [finishToBeCalled]);

    const onPress = (event) => {
        if (!isEntered) start();
        else finish();
        setIsEntered(!isEntered);
        if (typeof event.preventDefault === 'function') event.preventDefault();
        if (typeof event.stopPropagation === 'function') event.stopPropagation();
    };

    const focus = (interactable) => {
        setFocused({ ...focused, [`${interactable}Focused`]: true });
    };

    const blur = (interactable) => {
        setFocused({ ...focused, [`${interactable}Focused`]: false });
    };

    return (
        <>
            {isEntered &&
                renderMinusButton({
                    focused: focused.minusFocused,
                    activeTintColor,
                    tintColor,
                    style: { ...style, marginLeft: -height },
                    onFocus: () => focus(MINUS),
                    onBlur: () => blur(MINUS),
                    onPress: () => onChange(lowerValue),
                })}
            <Interactable
                style={[
                    style,
                    {
                        backgroundColor: focused.thumbFocused ? activeTintColor : tintColor,
                        left: `${left}%`,
                    },
                ]}
                onFocus={() => focus(THUMB)}
                onBlur={() => blur(THUMB)}
                onPress={onPress}
                disabled={disabled}
            />
            {isEntered &&
                renderPlusButton({
                    focused: focused.plusFocused,
                    activeTintColor,
                    tintColor,
                    style: { ...style, right: 0, marginRight: -height },
                    onFocus: () => focus(PLUS),
                    onBlur: () => blur(PLUS),
                    onPress: () => onChange(higherValue),
                })}
        </>
    );
};

export default Thumb;
