import { TextStyle, ViewStyle } from 'react-native';
import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';

interface ManageButtonStyle {
    wrapper: ViewStyle;
    container: ViewStyle;
    title: TextStyle;
}

const ManageButton = (theme: Theme): ManageButtonStyle => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: 20,
        marginTop: 20,
        height: 42,
        ...getBorderRadius(theme.elements, 'buttonTertiary-cornerRadius'),
        backgroundColor: theme.color.buttonTertiary,
        paddingLeft: 20,
        paddingRight: 20,
    },
    wrapper: {
        height: '100%',
        justifyContent: 'flex-end',
    },
    title: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body1'),
        fontSize: 16,
        marginLeft: 5,
        lineHeight: 21,
    },
});

export default ManageButton;
