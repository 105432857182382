import React from 'react';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { useTranslation } from 'react-i18next';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useRouter, WebRouter } from '@24i/nxg-core-router/src/NextRouter';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { SubscriptionRequiredModalProps, SubscriptionRequiredModalStyles } from '../types';
import { getStyles } from '../styles';
import { goToSubscriptionsListPage } from '../../../utils/navigation';

export const SubscriptionRequiredModal = ({
    isVisible,
    closeModal,
}: SubscriptionRequiredModalProps) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const router = useRouter();
    const styles = getStyles(theme) as SubscriptionRequiredModalStyles;
    const referralAssetId = typeof router.query?.id === 'string' ? router.query.id : undefined;
    const referralAssetType =
        typeof router.query?.type === 'string' ? (router.query.type as ASSET_TYPE) : undefined;

    const goToSubscriptions = () => {
        goToSubscriptionsListPage({
            router: router as WebRouter,
            referralAssetId,
            referralAssetType,
        });
    };

    return (
        <Modal visible={isVisible} onRequestClose={closeModal} transparent roundCloseButton>
            <View style={styles.modalContainer}>
                <View style={styles.contentContainer}>
                    <Text style={[styles.subheaderText, { marginBottom: 24 }]}>
                        {t('svod.add.addSubscriptionModal.title')}
                    </Text>
                    <Text style={[styles.modalMessageText, { marginBottom: 24 }]}>
                        {t('svod.add.addSubscriptionModal.description')}
                    </Text>
                    <View style={styles.buttonsContainer}>
                        <SubactionButton title={t('common.close')} onPress={closeModal} />
                        <ActionButton
                            title={t('svod.add.addSubscriptionModal.buttonText')}
                            onPress={goToSubscriptions}
                        />
                    </View>
                </View>
            </View>
        </Modal>
    );
};
