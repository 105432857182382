/* eslint-disable react/no-unused-prop-types */
import React, { ReactElement } from 'react';
import { View, FlatList } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';

import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import { Profile } from '@24i/nxg-sdk-photon/src';
import { usePinModalWithProfile } from '@24i/nxg-sdk-pin-protection/src/hooks/usePinModalWithProfile';
import { useStore } from '../../../../../context/ApplicationStore';
import ProfileItem from '../../../../../components/ProfileItem';
import getStyles from '../styles';
import { UnifiedProfilePickerProps } from '../types';

export const defaultRenderProfileItem = (props): ReactElement => <ProfileItem {...props} />;

const ProfilePicker = (props: UnifiedProfilePickerProps) => {
    const {
        profilesWithNewProfileHandler = [],
        adjustedDimensions = 100,
        isLoading = false,
        onProfileItemPress,
        onManageProfilesPress,
        renderProfileItem = defaultRenderProfileItem,
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation(['sott']);
    const { selectedProfile } = useStore();
    const { triggerPinModalWithProfile } = usePinModalWithProfile({
        goBackAfterDismiss: false,
        goBackAfterActivation: false,
    });

    if (isLoading) return <Loader color={theme.color.textPrimary} />;

    return (
        <>
            <View style={styles.holder}>
                <View style={styles.container}>
                    <FlatList
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        data={profilesWithNewProfileHandler}
                        style={styles.flatListContainer}
                        contentContainerStyle={[
                            styles.flatListContentContainer,
                            { width: isPlatformWeb ? '90vw' : 'auto' },
                        ]}
                        renderItem={({ item, index }) => {
                            const isActive = item.id === selectedProfile?.id;

                            const isNewProfileHandler = item.id === 'NEW_PROFILE_HANDLER';

                            const onPress = async () => {
                                const itemCast = item as Profile;

                                // casting to distingush between types in union
                                try {
                                    if (itemCast.isPinProtected) {
                                        await triggerPinModalWithProfile(itemCast);
                                    }

                                    onProfileItemPress(itemCast, index);
                                } catch {
                                    // pin control rejected
                                }
                            };

                            return (
                                <View style={isPlatformWeb && styles.profilePickerContainer}>
                                    {renderProfileItem(
                                        {
                                            index,
                                            adjustedDimensions,
                                            name: (item as Profile).name,
                                            avatar: (item as Profile).image || undefined,
                                            isActive,
                                            icon: isNewProfileHandler ? 'onPrimary-add' : undefined,
                                            iconSize: 40,
                                            onPress,
                                            isNewProfileHandler,
                                        },
                                        {
                                            item,
                                            index,
                                        }
                                    )}
                                </View>
                            );
                        }}
                    />
                </View>
                <TertiaryButton
                    testID="manage_profiles_button"
                    onPress={onManageProfilesPress}
                    iconName="onButtonTertiary-edit"
                    title={t('profiles.manage.title')}
                    iconSize={20}
                    additionalContainerStyles={styles.manageBtn}
                    additionalTextStyles={{ color: theme.color.textPrimary }}
                />
            </View>
            <View testID="MoreScreen.View.2" style={styles.divider} />
        </>
    );
};

export default overridable(ProfilePicker, 'ProfilePicker');
