import { isPlatformWeb } from 'renative';

const getNumberOfLinesForPortrait = (isHighlighted: boolean, hasSubtitle: boolean): number => {
    if (isHighlighted && hasSubtitle) return isPlatformWeb ? 5 : 4;
    if (isHighlighted && !hasSubtitle) return isPlatformWeb ? 11 : 9;
    if (hasSubtitle) return isPlatformWeb ? 4 : 3;
    return isPlatformWeb ? 8 : 5;
};

const getNumberOfLinesForLandscape = (isHighlighted: boolean, hasSubtitle: boolean): number => {
    if (isHighlighted && hasSubtitle) return 3;
    if (isHighlighted && !hasSubtitle) return isPlatformWeb ? 6 : 7;
    if (hasSubtitle) return 2;
    return isPlatformWeb ? 5 : 3;
};

export const getNumberOfLines = (
    orientation: string,
    displayOver: boolean,
    isHighlighted: boolean,
    subtitleLength: number
): number => {
    const isPortrait = orientation === 'portrait';
    const hasSubtitle = subtitleLength > 0;

    if (!displayOver) return 1;

    return isPortrait
        ? getNumberOfLinesForPortrait(isHighlighted, hasSubtitle)
        : getNumberOfLinesForLandscape(isHighlighted, hasSubtitle);
};
