import KeyNames from './keyNames';

const keyMap = {
    RIGHT: 39,
    LEFT: 37,
    UP: 38,
    DOWN: 40,
    BACK: 8,
    ENTER: 13,
    PLAY: 49,
    PAUSE: 19,
    PLAYPAUSE: 1000,
    STOP: 50,
    FF: 417,
    RW: 412,
    RED: 82,
    GREEN: 71,
    YELLOW: 89,
    BLUE: 66,
    ZERO: 96,
    ONE: 97,
    TWO: 98,
    THREE: 99,
    FOUR: 100,
    FIVE: 101,
    SIX: 102,
    SEVEN: 103,
    EIGHT: 104,
    NINE: 105,
    PUP: 33,
    PDOWN: 34,
    PRECH: 46,
    TXTMIX: 110,
};

export default keyMap;

export function getKeyName(keyCode) {
    switch (keyCode) {
        case keyMap.UP:
            return KeyNames.UP;
        case keyMap.DOWN:
            return KeyNames.DOWN;
        case keyMap.RIGHT:
            return KeyNames.RIGHT;
        case keyMap.LEFT:
            return KeyNames.LEFT;
        case keyMap.PLAY:
            return KeyNames.PLAY;
        case keyMap.STOP:
            return KeyNames.STOP;
        case keyMap.PAUSE:
            return KeyNames.PAUSE;
        case keyMap.BACK:
            return KeyNames.BACK;
        default:
            return '';
    }
}
