import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';

import { NavScreenConfig } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { ScreenConfigBuilder } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/types';
import { useAppConfigQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/useAppConfigQuery';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useArePurchasesEnabled } from '@24i/nxg-sdk-smartott-shared/src/hooks/useArePurchasesEnabled';

import { useStore } from '../../../context/ApplicationStore';
import { useCurrentWebPageConfig } from '../../../hooks/useCurrentWebPageConfig';
import { AccountScreenModelProps, AccountScreenViewProps } from '../types';
// eslint-disable-next-line import/no-cycle
import {
    buildAccountDetailsScreen,
    buildSignOutScreen,
    buildManageDevicesScreen,
    buildAccountParentalControlsScreen,
    buildMySubscriptionsScreen,
} from '../../../navigation/navigationConfig/screenConfigBuilders';

const useViewModel = (props: AccountScreenModelProps): AccountScreenViewProps => {
    const { subMenuScreens, route } = props;
    const { crashlyticsLog } = useFirebase();
    const { appSettings } = useAppConfigQuery();
    const { t } = useTranslation();
    const { userData } = useStore();
    const { currentPageConfig } = useCurrentWebPageConfig();
    const router = useRouter();
    const arePurchasesEnabled = useArePurchasesEnabled();

    const params = isPlatformWeb ? router.query : {};

    const defaultSubscreensBuilders = (): ScreenConfigBuilder[] => {
        const defaultScreens: ScreenConfigBuilder[] = [];

        // without push() usage, mobile would crash due to builder receiving false instead of screenBuilder
        defaultScreens.push(buildAccountDetailsScreen);
        defaultScreens.push(buildAccountParentalControlsScreen);
        if (arePurchasesEnabled) defaultScreens.push(buildMySubscriptionsScreen);
        if (appSettings?.features?.deviceManagement?.enabled)
            defaultScreens.push(buildManageDevicesScreen);
        defaultScreens.push(buildSignOutScreen);

        return defaultScreens;
    };

    const defaultSubscreens = defaultSubscreensBuilders().map(
        (builder) =>
            builder(null, {
                t,
                user: userData,
                features: appSettings?.features,
                params,
            }) as NavScreenConfig
    );

    const submenuTitle: string =
        route?.params?.title || currentPageConfig?.initialParams?.title || t('account.title');

    useEffect(() => {
        crashlyticsLog('AccountScreen entered');
        return crashlyticsLog('AccountScreen exited');
    }, []);

    return {
        subMenuScreens: subMenuScreens || defaultSubscreens,
        submenuTitle,
        ...props,
    };
};

export { useViewModel };
