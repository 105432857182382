import { isPlatformWeb } from 'renative';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
// To Do. Refactor to use global constant instead
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import {
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
    SOTT_DEFAULT_ROOT_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { Profile } from '@24i/nxg-sdk-photon/src';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import {
    ManageProfilesScreenModes,
    NEW_PROFILE_HANDLER,
} from '../../../../ManageProfilesScreen/constants';
import { UPSERT_PROFILE_MODES } from '../../../../UpsertProfileScreen/constants';
import { ProfilePickerProps, ViewModelReturnType } from '../types';

const defaultItemDimensions = 84;

const useViewModel = (props: ProfilePickerProps): ViewModelReturnType => {
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const router = useRouter();
    const profileLimit = useFeature('profileLimit');
    const maxNumberOfProfiles = useFeature('maxNumberOfProfiles');
    const { profiles, selectProfile } = props;

    const maximumNumberOfProfiles = profileLimit?.maxCount || maxNumberOfProfiles || Infinity;

    const userProfiles = [
        ...(profiles || []),
        (!profiles || profiles?.length < maximumNumberOfProfiles) && NEW_PROFILE_HANDLER,
    ].filter(Boolean);

    const adjustDimensions = (itemLength: number): number =>
        !DeviceInfo.isTablet() && itemLength > 3
            ? defaultItemDimensions * 0.8
            : defaultItemDimensions;

    const adjustedDimensions = adjustDimensions(userProfiles.length);

    const redirectToNewProfileScreen = (): void => {
        if (isPlatformWeb) {
            const profileCreateLink = SOTT_DEFAULT_WEB_SCREENS.CreateProfile.getLink();
            router.push(profileCreateLink.href, profileCreateLink.as);
            return;
        }

        navigation.navigate(SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN, {
            screen: SOTT_DEFAULT_SCREENS.UPSERT_PROFILE,
            params: {
                mode: UPSERT_PROFILE_MODES.CREATE,
            },
        });
    };

    const onProfileItemPress = (profile: Profile, index: number): void => {
        const isAddNewProfileItem = profile.id === NEW_PROFILE_HANDLER.id;

        if (isAddNewProfileItem) {
            redirectToNewProfileScreen();

            return;
        }

        selectProfile(profile, index);
    };

    const onManageProfilesPress = (): void => {
        if (isPlatformWeb) {
            const manageProfilesLink = SOTT_DEFAULT_WEB_SCREENS.ManageProfiles.getLink();
            router.push(manageProfilesLink.href, manageProfilesLink.as);
            return;
        }

        navigation.navigate(SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN, {
            screen: SOTT_DEFAULT_SCREENS.MANAGE_PROFILES,
            params: {
                mode: ManageProfilesScreenModes.EDIT_ONLY,
            },
        });
    };

    return {
        onProfileItemPress,
        onManageProfilesPress,
        adjustedDimensions,
        ...props,
        profilesWithNewProfileHandler:
            userProfiles as ViewModelReturnType['profilesWithNewProfileHandler'],
    };
};

export { useViewModel };
