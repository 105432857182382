import React from 'react';
import View from '@24i/nxg-sdk-quarks/src/components/View';
import Text from '@24i/nxg-sdk-quarks/src/components/Text';
import DropdownPicker from '@24i/nxg-sdk-gluons/src/components/inputs/DropdownPicker/';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getSettingsDropdownPickerStyles from '../styles';
import { SettingsDropdownPickerProps } from '../types';

const SettingsDropdownPicker = (props: SettingsDropdownPickerProps) => {
    const {
        options,
        selectedItem,
        onSelectionPress,
        title,
        styles: getStyles = getSettingsDropdownPickerStyles,
        buttonTestID,
        getItemsTestID,
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.selectContainer}>
            <View>
                <Text style={styles.description}>{title}</Text>
            </View>

            <DropdownPicker
                options={options}
                selectedItem={selectedItem}
                onSelectionPress={onSelectionPress}
                additionalTextStyles={styles.buttonText}
                additionalButtonStyles={styles.buttonContainer}
                buttonTestID={buttonTestID}
                getItemsTestID={getItemsTestID}
            />
        </View>
    );
};

export { getSettingsDropdownPickerStyles };
export default SettingsDropdownPicker;
