import { createGuard, decodeModelWith } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const DecodedCleengTokenGuard = createGuard('CleengDecodeToken', {
    customerId: t.number,
    publisherId: t.number,
    exp: t.number,
});

export type DecodedCleengToken = t.TypeOf<typeof DecodedCleengTokenGuard>;

export type CleengTokenData = {
    cleengToken: string;
    expirationTimestamp: DecodedCleengToken['exp'];
    email: string;
};

export const createCleengDecodedToken = (data: unknown): DecodedCleengToken | never => {
    return decodeModelWith(DecodedCleengTokenGuard, data, 'DecodedCleengToken');
};
