import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import useSafeAreaInsets from '@24i/nxg-sdk-gluons/src/hooks/useSafeAreaInsets';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import getWorkInProgressStyles from './styles';

interface WorkInProgressProps {
    children?: JSX.Element | JSX.Element[];
}

const WorkInProgress = ({ children }: WorkInProgressProps) => {
    const { t } = useTranslation();
    const insets = useSafeAreaInsets();
    const showWorkInProgressLabels = useFeature('showWorkInProgressLabels');
    const { theme } = useTheme();
    const styles = getWorkInProgressStyles(theme, insets);

    return (
        <>
            {showWorkInProgressLabels?.enabled && (
                <View style={styles.wrapperContainer}>
                    <View style={styles.contentContainer}>
                        <Text style={styles.title}>{t('common.workInProgress')}</Text>
                    </View>
                </View>
            )}
            {children}
        </>
    );
};

export default WorkInProgress;
