var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;Object.defineProperty(exports,"getSettingsThemeStyles",{enumerable:true,get:function get(){return _styles.default;}});var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));var _slicedToArray2=_interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));var _react=_interopRequireWildcard(require("react"));
var _propTypes=_interopRequireDefault(require("prop-types"));
var _nxgSdkQuarks=require("@24i/nxg-sdk-quarks");
var _reactI18next=require("react-i18next");
var _nxgSdkHiggs=require("@24i/nxg-sdk-higgs");
var _ThemeData=require("@24i/nxg-sdk-smartott-shared/src/context/ThemeData");
var _nxgCoreUtils=require("@24i/nxg-core-utils");
var _SettingsScreen=require("@24i/nxg-sdk-smartott/src/screens/SettingsScreen");
var _logger=require("@24i/nxg-core-utils/src/logger");
var _withWebHeader=require("@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader");

var _styles=_interopRequireDefault(require("./styles"));var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/components/Settings/SettingsTheme/index.web.js";function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=typeof e&&"function"!=typeof e)return{default:e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n.default=e,t&&t.set(e,n),n;}function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}



var SettingsTheme=function SettingsTheme(_ref){var title=_ref.title,getStyles=_ref.styles;
var _useTranslation=(0,_reactI18next.useTranslation)(),t=_useTranslation.t;
var themeDataClient=(0,_ThemeData.useThemeData)();
var _useTheme=(0,_nxgSdkHiggs.useTheme)(),theme=_useTheme.theme,setTheme=_useTheme.setTheme;

var styles=getStyles(theme);

var _React$useState=_react.default.useState([]),_React$useState2=(0,_slicedToArray2.default)(_React$useState,2),themes=_React$useState2[0],setThemes=_React$useState2[1];

(0,_react.useEffect)(function(){
var getThemes=function getThemes(){var fetchedThemes;return _regenerator.default.async(function getThemes$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.prev=0;_context.next=3;return _regenerator.default.awrap(

themeDataClient.fetchAllThemes());case 3:fetchedThemes=_context.sent;
setThemes(fetchedThemes);_context.next=10;break;case 7:_context.prev=7;_context.t0=_context["catch"](0);

(0,_logger.log)(_context.t0);case 10:case"end":return _context.stop();}},null,null,[[0,7]],Promise);};



getThemes();
},[]);

var handleSelectTheme=function handleSelectTheme(themeItem){return _regenerator.default.async(function handleSelectTheme$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:_context2.next=2;return _regenerator.default.awrap(
themeDataClient.selectTheme(themeItem.id));case 2:
setTheme(_objectSpread({
components:theme.components},
themeItem)
);case 3:case"end":return _context2.stop();}},null,null,null,Promise);};


var renderOptions=function renderOptions(){return(
themes.map(function(themeItem,index){return(

_react.default.createElement(_nxgSdkQuarks.Interactable,{
style:[
{
opacity:theme.id===themeItem.id?1:0.4,
':hover':{opacity:1}
},
styles.listItem],

onPress:function onPress(){return handleSelectTheme(themeItem);},
testID:"theme_"+index,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:49,columnNumber:13}},

_react.default.createElement(_nxgSdkQuarks.Text,{style:styles.listItemText,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:60,columnNumber:17}},themeItem.name)
));}
));};

return(
_react.default.createElement(_SettingsScreen.SettingsOptionPage,{
title:title,
subtitle:(0,_nxgCoreUtils.firstLetterUppercase)(theme.name),
description:t('settings.theme.title'),
renderOptions:renderOptions,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:65,columnNumber:9}}
));

};

SettingsTheme.propTypes={
styles:_propTypes.default.func
};

SettingsTheme.defaultProps={
styles:_styles.default
};var _default=


(0,_withWebHeader.withWebHeader)(SettingsTheme);exports.default=_default;