/* eslint-disable no-underscore-dangle */
import nativePush from './push';
import nativeNavigate from './navigate';
import nativePop from './pop';
import nativePopToTop from './popToTop';

class Router {
    constructor(routes, notFoundPath = null, prefixes = []) {
        /*
         * @TODO validate routes array
         * Internally use flattened routes
         */
        this._routes = this.flatten(routes);
        this.notFoundPath = notFoundPath;
        this.prefixes = prefixes;
    }

    // Externally return hierarchichal routes
    get routes() {
        return this.unflatten(this._routes);
    }

    flatten(routes, prefix = '', parent = null) {
        const flat = [];

        routes.forEach((route) => {
            const url = `${prefix}${route.url}`;
            const r = {
                ...route,
                url,
                shortUrl: route.url,
            };

            if (parent) {
                r.parent = parent;
            }

            flat.push(r);

            if (route?.children?.length) {
                flat.push(...this.flatten(route?.children, url, route));
            }
        });

        return flat;
    }

    unflatten(flatRoutes) {
        const routes = [];

        flatRoutes.forEach((route) => {
            if (!route.parent) {
                const r = {
                    ...route,
                    url: route.shortUrl,
                };

                routes.push(r);
            }
        });

        return routes;
    }

    linking() {
        const expand = (routes) => ({
            ...routes.reduce((obj, route) => {
                const r = {
                    [route.name]: {
                        path: route.url,
                    },
                };
                if (route.children && route.children.length) {
                    r[route.name].screens = expand(route.children);
                }
                return {
                    ...obj,
                    ...r,
                };
            }, {}),
        });

        return {
            prefixes: this.prefixes,
            config: {
                screens: {
                    ...expand(this.routes),
                    NotFound: {
                        path: this.notFoundPath || '/',
                    },
                },
            },
        };
    }

    findByKey(val, key = 'url') {
        return this._routes.find((route) => route[key] === val);
    }

    findByUrl(url) {
        return this.findByKey(url);
    }

    findByShortUrl(url) {
        return this.findByKey(url, 'shortUrl');
    }

    getCurrentRoute() {
        // @TODO Delegate to native router
    }

    findNavigationRoute(url) {
        let found = this.findByUrl(url);

        if (!found) {
            found = this.findByShortUrl(url);
        }

        if (!found) throw new Error(`Route with url ${url} not found`);

        return found;
    }

    navigate(url, params = null, query = null) {
        const route = this.findNavigationRoute(url);
        nativeNavigate(route, params, query, this.navigation, this.linking());
    }

    push(url, params = null, query = null) {
        const route = this.findNavigationRoute(url);
        nativePush(route, params, query, this.navigation);
    }

    pop() {
        nativePop(this.navigation);
    }

    popToTop() {
        nativePopToTop(this.navigation);
    }

    back = this.pop;
}

export default Router;
