var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.i18nOptions=void 0;
var _en=_interopRequireDefault(require("@24i/prd-localization-files-experienceapp/dist/en.json"));
var _es=_interopRequireDefault(require("@24i/prd-localization-files-experienceapp/dist/es.json"));
var _fr=_interopRequireDefault(require("@24i/prd-localization-files-experienceapp/dist/fr.json"));
var _de=_interopRequireDefault(require("@24i/prd-localization-files-experienceapp/dist/de.json"));
var _he=_interopRequireDefault(require("@24i/prd-localization-files-experienceapp/dist/he.json"));

var i18nOptions={
resources:{
en:{sott:_en.default},
es:{sott:_es.default},
fr:{sott:_fr.default},
de:{sott:_de.default},
he:{sott:_he.default}
},
react:{
useSuspense:false
},
fallbackLng:'en'
};exports.i18nOptions=i18nOptions;