/* eslint-disable react/display-name */
import * as React from 'react';
import { usePinControl, WithPinControlProps } from '../../hooks/usePinControl';
import PinControl from '../../components/PinInputModal';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const PinProtectionContext = React.createContext<WithPinControlProps>({
    openPinControl: async () => undefined,
    closePinControl: noop,
    handlePinSubmit: () => false,

    profileWithPin: null,
    isPinControlVisible: false,
    pinControlProps: undefined,

    arePinScopesValidated: () => false,
    updateLastValidationTime: noop,
});
PinProtectionContext.displayName = 'PinProtection';

export const usePinProtection = () => {
    const context = React.useContext(PinProtectionContext);
    return context;
};

const PinProtectionProvider: React.FC<React.PropsWithChildren<WithPinControlProps>> = ({
    children,
}) => {
    const {
        openPinControl,
        closePinControl,
        handlePinSubmit,

        profileWithPin,
        isPinControlVisible,
        pinControlProps,

        arePinScopesValidated,
        updateLastValidationTime,
    } = usePinControl();

    React.useEffect(() => {
        window.onpopstate = () => {
            if (isPinControlVisible) closePinControl?.();
        };
    }, [isPinControlVisible]);

    return (
        <PinProtectionContext.Provider
            value={{
                openPinControl,
                closePinControl,
                handlePinSubmit,

                profileWithPin,
                isPinControlVisible,
                pinControlProps,

                arePinScopesValidated,
                updateLastValidationTime,
            }}
        >
            <PinControl
                isVisible={isPinControlVisible}
                onSubmit={handlePinSubmit}
                onModalClose={closePinControl}
                profileWithPin={profileWithPin}
                {...pinControlProps}
            />
            {children}
        </PinProtectionContext.Provider>
    );
};

export default PinProtectionProvider;
