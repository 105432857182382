import { SubMenuStackScreens } from '@24i/nxg-sdk-smartott/src/screens/SubMenuScreen/stack';
import { TV_SCREENS } from '@24i/nxg-sdk-smartott-tv/src/navigation/constants';
import { BACKSTAGE_MENU_ITEM_TYPES } from '@24i/nxg-integration-backstage/src/clients/AppStructureDataClient';
import {
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_ROOT_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { isPlatformWeb, isPlatformTvos } from 'renative';
import { isPlatformNative } from '@24i/nxg-core-utils';
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import i18n from 'i18next';
import { CorePayload, Payload, SceneViewResult } from '../types';
import { EVENTS, LOCALIZATION_KEYS } from '../constants';
import { mapTagsParams } from './mapTagsParams';
import { capitalize } from '../utils';

const PAGE_VIEW = 'page_view';

const getSourcePage = (payload: CorePayload): string => {
    if (payload.params?.screen === SOTT_DEFAULT_SCREENS.LANDING)
        return SOTT_DEFAULT_SCREENS.LANDING;
    if (payload.pageId === TV_SCREENS.MODAL) return '';
    if (payload.pageId === TV_SCREENS.DYNAMIC_CONTENT) return TV_SCREENS.HOME;
    if (payload.pageId === SubMenuStackScreens.ScreensList) return '';
    if (payload.pageId === SOTT_DEFAULT_SCREENS.INDEX) return '';
    if (
        payload.pageId === BACKSTAGE_MENU_ITEM_TYPES.HOME &&
        Object.keys(payload.params).length === 0
    ) {
        return '';
    }
    if (payload.pageId === TV_SCREENS.SUB_MENU) return payload.params.screenName;
    if (isPlatformWeb && 'screen' in payload) return payload.screen;
    return payload.params?.title || payload.pageId || payload.screen;
};

const getAreMinimumRequirementsFilled = (
    screenName: string,
    deviceId: string,
    title: string
): boolean => {
    if (
        (screenName === SOTT_DEFAULT_SCREENS.PLAYBACK ||
            screenName === SOTT_DEFAULT_SCREENS.DETAILS) &&
        !title
    )
        return false;

    if (
        deviceId &&
        screenName &&
        screenName !== SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN
    )
        return true;

    return false;
};

const getScreenNameWithTitle = (
    screenName: string,
    title: string,
    payload: CorePayload
): string => {
    if (
        payload.pageId === SOTT_DEFAULT_SCREENS.DETAILS ||
        screenName === SOTT_DEFAULT_SCREENS.DETAILS ||
        payload.pageId === TV_SCREENS.DETAILS
    )
        return i18n.t('analytics.documentTitle.detail', { assetTitle: title });

    if (payload.pageId === SOTT_DEFAULT_SCREENS.SEE_ALL || payload.pageId === TV_SCREENS.SEE_ALL)
        return i18n.t('analytics.documentTitle.seeAll', { rowTitle: title });

    if (
        payload.pageId === SOTT_DEFAULT_SCREENS.PLAYBACK ||
        payload.pageId === TV_SCREENS.PLAYBACK ||
        screenName === SOTT_DEFAULT_SCREENS.PLAYBACK
    )
        return i18n.t('analytics.documentTitle.player', { assetTitle: title });

    return screenName;
};

export const mapScreenView = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): SceneViewResult | undefined => {
    let screenName = getSourcePage(payload.payload);
    const title =
        payload.payload.params?.title ||
        payload.payload.params?.asset?.title ||
        payload.payload.asset?.title ||
        payload.payload.title;
    if (!getAreMinimumRequirementsFilled(screenName, payload.deviceId, title)) return undefined;
    if (screenName.toUpperCase() !== BACKSTAGE_MENU_ITEM_TYPES.HOME.toUpperCase() && title)
        screenName = getScreenNameWithTitle(screenName, title, payload.payload);
    else {
        const localizedScreenName = i18n.t(LOCALIZATION_KEYS[screenName]);
        screenName = capitalize(localizedScreenName || screenName);
    }

    return {
        event_trigger: triggerName,
        language: payload.language,
        session_id: payload.payload.sessionId,
        is_authenticated: payload.userId ? 1 : 0,
        event: isPlatformWeb ? PAGE_VIEW : EVENTS.SCREEN_VIEW,
        ...(isPlatformTvos && { ...mapTagsParams(payload) }),
        ...(isPlatformWeb && { page_title: screenName }),
        ...(isPlatformTvos && { firebase_screen: screenName }),
        ...(isPlatformNative && { screen_name: screenName }),
    };
};
