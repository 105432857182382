const TopBarMenu = (theme) => ({
    menuScroll: {
        flex: 0,
        backgroundColor: theme.color.primaryBlur1Fallback,
    },
    sectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flex: 1,
    },
    section: {
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 800px)': {
            flex: 1,
            maxHeight: 51,
        },
    },
    rightSection: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 800px)': {
            display: 'none',
        },
    },
    menuContainer: {
        width: '100%',
        position: 'sticky',
        top: 0,
        flexDirection: 'row',
        display: 'flex',
        paddingVertical: 0,
        paddingHorizontal: '4%',
        height: 70,
        zIndex: 99,
        backgroundColor: theme.color.primaryBlur1Fallback,
        '@media (max-width: 800px)': {
            backgroundColor: theme.color.primaryBlur1.color,
            // backdropFilter: 'saturate(180%) blur(20)',
            position: 'fixed',
            bottom: 0,
            top: 'initial',
            height: 51,
        },
        '@media (min-width: 800px) and (max-width: 1200px)': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 32,
            paddingRight: 32,
        },
        '@media (min-width: 1201px) and (max-width: 1600px)': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 48,
            paddingRight: 48,
        },
    },
    menuItem: {
        overflow: 'hidden',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    logoHolder: {
        '@media (max-width: 800px)': {
            display: 'none',
        },
    },
    topBarMenuItemWrapper: {
        marginRight: 40,
    },
});

export default TopBarMenu;
