import { Reminders, RemindersClient } from '@24i/nxg-sdk-photon/src/clients/RemindersClient';
import { createReminder, Reminder } from '@24i/nxg-sdk-photon/src/models/reminder';
import { Storage } from '@24i/nxg-sdk-quantum';
import { Broadcast } from '@24i/nxg-sdk-photon';
import { v4 as uuidv4 } from 'uuid';
import { fakeBackend } from '../../utils';

export const STORAGE_KEY = 'reminders';

async function storeReminders(reminders: Reminders): Promise<void> {
    await Storage.setItem(STORAGE_KEY, JSON.stringify(reminders));
}

export async function fetchReminders(): Promise<string> {
    const reminders = await Storage.getItem(STORAGE_KEY);
    return reminders;
}

export class RemindersClientStub implements RemindersClient {
    stubReminders: Reminders | undefined;

    fakeBackend = fakeBackend;

    constructor(stubReminders: Reminders | undefined = undefined) {
        this.stubReminders = stubReminders;
    }

    // Reminder factory function
    /**
     * @param {Broadcast} broadcast - Broadcast that the reminder is for.
     * @param {string} profileId - Profile that the reminder is for.
     * @returns {Reminder} Reminder, given that shape is valid
     */
    createReminderForBroadcast = (broadcast: Broadcast, profileId: string): Reminder => {
        const { channelId, start, end, title, id: assetId, type: assetType } = broadcast;
        const id = uuidv4();
        const reminder = {
            id,
            assetId,
            profileId,
            channelId,
            start,
            end,
            title,
            assetType,
        };
        return createReminder(reminder);
    };

    // Store a reminder
    /**
     * @param {Broadcast} broadcast - Broadcast that the reminder is for.
     * @param {string} profileId - Profile that the reminder is for.
     * @returns {Promise<void>}
     */
    setReminderForBroadcast = async (broadcast: Broadcast, profileId: string): Promise<void> => {
        const existingReminders = this.stubReminders || (await this.getReminders()) || [];
        const reminder = this.createReminderForBroadcast(broadcast, profileId);
        const reminders = [...existingReminders, reminder];
        await storeReminders(reminders);
    };

    // Get all reminders if there are any, if not, get null
    /**
     * @returns {Promise<Reminders | null>}
     */
    getReminders = async (): Promise<Reminders | null> => {
        const reminders = await fetchReminders();
        if (reminders) {
            const parsedReminders: Reminders = JSON.parse(reminders);
            return parsedReminders;
        }
        return null;
    };

    // Serach provided reminders for specific asset and profile
    /**
     * @param {Reminders} reminders - list of reminders
     * @param {string} assetId - reminder property
     * @param {string} profileId - reminder property
     * @returns {Reminder | undefined}
     */
    findReminderInReminders = (
        reminders: Reminders,
        assetId: string,
        profileId: string
    ): Reminder | undefined =>
        reminders.find(
            (reminder) => reminder.assetId === assetId && reminder.profileId === profileId
        );

    // If the given asset and profile have a reminder set, return it. If not, return null.
    /**
     * @param {string} assetId - get reminder for this assetId
     * @param {string} profileId - get reminder for this profileId
     * @returns {Promise<Reminder | null>}
     */
    getReminderForBroadcast = async (
        assetId: string,
        profileId: string
    ): Promise<Reminder | null> => {
        await this.fakeBackend(1, 2000);
        const reminders = await this.getReminders();
        if (reminders) {
            const reminder = this.findReminderInReminders(reminders, assetId, profileId);
            if (reminder) return reminder;
        }
        return null;
    };

    // Delete a reminder for a specific asset and profile, then store remaining reminders
    /**
     * @param {string} assetId - get reminder for this assetId
     * @param {string} profileId - get reminder for this profileId
     * @returns {Promise<Reminder | null>}
     */
    deleteReminderForBroadcast = async (assetId: string, profileId: string): Promise<void> => {
        await this.fakeBackend(1, 2000);
        const reminders = await fetchReminders();
        if (reminders) {
            const parsedReminders: Reminders = JSON.parse(reminders);
            const updatedReminders = parsedReminders.filter(
                (reminder) => !(reminder.assetId === assetId && reminder.profileId === profileId)
            );
            await storeReminders(updatedReminders);
        }
    };
}
