import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { LiveBadgeProps } from './types';
import { getLiveBadgeStyles } from './styles';

const LiveBadge = ({
    styles: getStyles = getLiveBadgeStyles,
    testID = '',
    hasProgressBar,
}: LiveBadgeProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation(['sott']);
    const styles = getStyles(theme);

    return (
        <View
            testID={testID}
            style={[styles.liveBadgeContainer, !hasProgressBar && styles.liveBadgePosition]}
        >
            <Text style={styles.liveBadgeText} numberOfLines={1}>
                {t('asset.liveBadge')}
            </Text>
        </View>
    );
};

export default LiveBadge;
