import { TextStyle } from 'react-native';
import { WithTitleColorVariant, WithSubtitleFontSize } from '../../types';

export enum SEPARATORS {
    DOT = '•',
    PIPE = '|',
    STAR = '*',
}

export interface TextWithSeparatorProps extends WithTitleColorVariant, WithSubtitleFontSize {
    children: React.ReactNode[];
    separator: SEPARATORS;
}

export interface TextWithSepratorStyles {
    separator: TextStyle;
    subtitle: TextStyle;
}
