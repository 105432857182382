import { Channel, EpgDataClient, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useQueryClient, useQuery } from 'react-query';
import { EpgCollection } from '@24i/nxg-sdk-epg/src/cache/collections/EpgCollection';
import useChannelsQuery from '@24i/nxg-sdk-smartott/src/hooks/query/epg/useChannelsQuery';
import { useEpgData } from '@24i/nxg-sdk-smartott-shared/src/context/EpgData';

type UseEpgCacheParams = {
    channels?: Channel[];
    startTime: number;
    endTime: number;
    // if true will index the data before fetching and any request to fetch them
    // again will return the cache, if false will not. Defaults to true.
    optimisticIndex?: boolean;
};

const filterIndexedChannels = (
    channels: Channel[],
    collection: EpgCollection,
    range: [startTime: number, endTime: number]
) => {
    return channels.filter((channelToFilter) => {
        const result = !collection.isLoadedInRange(channelToFilter.id, {
            start: range[0],
            end: range[1],
        });
        return result;
    });
};

const addDataIntoCollection = (
    channels: Channel[],
    collection: EpgCollection,
    indexRange: [startTime: number, endTime: number]
) => {
    channels.forEach((channel) => {
        collection?.addTreePrograms(channel.id, channel.programs ?? [], indexRange);
    });
    return collection;
};

const indexDataInTheCollection = (
    collection: EpgCollection,
    channels: Channel[],
    range: [startTime: number, endTime: number]
) => {
    channels.forEach((ch) =>
        collection.updateLoadedProgramsRangeIndex(ch.id, { start: range[0], end: range[1] })
    );
};

const fetchDataNotIndexedInTheCollection = async (
    collection: EpgCollection = new EpgCollection(),
    client: EpgDataClient,
    channels: Channel[],
    range: [startTime: number, endTime: number],
    indexBeforeFetch: boolean
) => {
    const filteredChannels = filterIndexedChannels(channels, collection, range);
    if (indexBeforeFetch) indexDataInTheCollection(collection, channels, range);
    if (filteredChannels.length > 0) {
        const data = await client.getEpgDataInRange(
            {
                start: range[0],
                end: range[1],
            },
            filteredChannels
        );
        return data;
    }
    return [];
};

export const useEpgCache = (params: UseEpgCacheParams) => {
    const { channels, startTime, endTime, optimisticIndex = true } = params;
    const { data: allChannels = [] } = useChannelsQuery();
    const client = useEpgData();
    const queryClient = useQueryClient();
    const finalChannels = channels ?? allChannels ?? [];
    const idString = finalChannels.map((ch) => ch.id).join(',');

    const queryFunction = async () => {
        const collection = queryClient.getQueriesData<EpgCollection>({
            queryKey: QUERY_KEYS.epg,
        })?.[0]?.[1];
        const data = await fetchDataNotIndexedInTheCollection(
            collection,
            client,
            finalChannels,
            [startTime, endTime],
            optimisticIndex
        );
        return addDataIntoCollection(data, collection, [startTime, endTime]);
    };

    const query = useQuery([QUERY_KEYS.epg, startTime, endTime, idString], queryFunction, {
        staleTime: 0,
        initialData: new EpgCollection(),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        select: (collection: EpgCollection): Channel[] => {
            const result =
                finalChannels.map((channel) => {
                    return {
                        ...channel,
                        programs: collection.getPrograms(channel.id, [startTime, endTime]),
                    };
                }) ?? [];
            return result;
        },
        enabled: !!allChannels?.length,
    });
    return { ...query, allChannels };
};
