import { useState } from 'react';
import { NUMBER_OF_PIN_INPUTS, allExceptNumbersRegex } from '@24i/nxg-core-utils/src/constants';
import { useUpdateAccountPin } from '@24i/nxg-sdk-smartott/src/hooks/query/user';
import { validatePin } from '@24i/nxg-core-utils';
import { CreatePinViewProps } from '../View/types';
import { useStartPageNavigation } from '../../../context/AppStartContext/hooks';
import { CreatePinScreenProps } from '../types';

const useViewModel = (props: CreatePinScreenProps): CreatePinViewProps => {
    const { navigateToStartPage } = useStartPageNavigation();
    const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
    const [isPinValidated, setIsPinValidated] = useState<boolean | undefined>(false);
    const [pin, setPin] = useState<string | undefined>(undefined);
    const pinLength = props.pinLength || NUMBER_OF_PIN_INPUTS;
    const updateAccountPin = useUpdateAccountPin();

    const pinValidator = props.pinValidator || validatePin;

    const onPinChange = (input: string) => {
        const cleanedInputValue = input.replace(allExceptNumbersRegex, '');
        setPin(cleanedInputValue);
        if (pinValidator(cleanedInputValue)) setIsPinValidated(false);
        else setIsPinValidated(true);
    };

    const onConfirmPress = async (inputPin: string): Promise<void> => {
        setIsLoading(true);
        updateAccountPin(inputPin, {
            onSuccess: () => {
                navigateToStartPage();
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };

    return {
        isLoading,
        pin,
        isPinValidated,
        pinLength,
        onPinChange,
        onConfirmPress,
    };
};

export { useViewModel };
