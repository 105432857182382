// WEB IMPLEMENTATION DESPITE NOT HAVING .web.tsx

import React, { useState, useRef } from 'react';
import { Interactable, Text, NavigationIcon } from '@24i/nxg-sdk-quarks/src';
import getStyles from '@24i/nxg-sdk-smartott/src/components/ShareDropdown/styles';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import { ViewStyle } from 'react-native';
import useOutsideClick from '@24i/nxg-sdk-smartott/src/hooks/useOutsideClick';
import { ShareDropdownOption, ShareDropdownProps } from '../types';

const ShareDropdown = (props: ShareDropdownProps): JSX.Element => {
    const containerRef = useRef(null);
    const { options, closeDropdown } = props;
    const { theme } = useTheme();
    const styles = getStyles(theme);
    useOutsideClick(containerRef, closeDropdown);

    const defaultShareOptions = (): ShareDropdownOption[] => [
        { title: 'Facebook', iconName: 'social_facebook' },
        { title: 'Twitter', iconName: 'social_twitter' },
        { title: 'Email link', iconName: 'menu-icons-email' },
        // FIXME: this icon is not in the navigation icons
        { title: 'Copy link', iconName: 'link' },
    ];

    const toRender = options || defaultShareOptions();
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

    const getItemContainerStyles = (index: number): ViewStyle => {
        if (index === hoveredIndex) {
            return { ...styles.itemContainer, ...{ backgroundColor: theme.color.primary4 } };
        }

        return styles.itemContainer;
    };

    return (
        <Interactable style={styles.container} ref={containerRef}>
            {toRender.map((option: ShareDropdownOption, index) => (
                <Interactable
                    key={`share_dropdown_option${option.title})`}
                    style={getItemContainerStyles(index)}
                    onPress={() => (option.onPress ? option.onPress : closeDropdown())}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(-1)}
                >
                    {option.iconName != null && (
                        <NavigationIcon
                            iconName={option.iconName}
                            size={20}
                            iconStyles={{ marginRight: 16 }}
                        />
                    )}
                    <Text style={styles.text}>{option.title}</Text>
                </Interactable>
            ))}
        </Interactable>
    );
};

export default ShareDropdown;
