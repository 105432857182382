import {
    PaymentResultScreenParams,
    SOTT_DEFAULT_WEB_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { WebRouter } from '@24i/nxg-core-router/src/NextRouter';
import { ReferralAssetData } from './cleeng/types';

export const getMySubscriptionsScreenLink = () => {
    return SOTT_DEFAULT_WEB_SCREENS.MySubscriptions.getLink();
};

export const goToMySubscriptionsPage = (router: WebRouter, reason: 'back' | 'success') => {
    const subscriptionsPageLink = getMySubscriptionsScreenLink();
    router.push({
        pathname: subscriptionsPageLink.href,
        query: {
            containingMenuTitle: 'Account',
            purchaseFlow: reason,
        },
    });
};

export const getAssetDetailsScreenLink = ({
    referralAssetType,
    referralAssetId,
}: ReferralAssetData) => {
    return SOTT_DEFAULT_WEB_SCREENS.Details.getLink({
        type: referralAssetType,
        id: referralAssetId,
    });
};

export const goToAssetDetailsPage = ({
    router,
    referralAssetType,
    referralAssetId,
}: ReferralAssetData & { router: WebRouter }) => {
    const assetDetailPageLink = getAssetDetailsScreenLink({ referralAssetType, referralAssetId });

    router.push(
        {
            pathname: assetDetailPageLink.href,
            query: { id: referralAssetId, type: referralAssetType },
        },
        assetDetailPageLink.as
    );
};

export const getPaymentResultScreenLink = (queryParams: PaymentResultScreenParams) => {
    return SOTT_DEFAULT_WEB_SCREENS.PaymentResult.getLink(queryParams);
};

export const getPaymentMethodScreenLink = () => {
    return SOTT_DEFAULT_WEB_SCREENS.PaymentMethod.getLink();
};

export const getSubscriptionsListScreenLink = (queryParams: Partial<ReferralAssetData>) => {
    return SOTT_DEFAULT_WEB_SCREENS.SubscriptionsList.getLink(queryParams);
};

export const goToSubscriptionsListPage = ({
    router,
    referralAssetType,
    referralAssetId,
}: Partial<ReferralAssetData> & { router: WebRouter }) => {
    const subscriptionsListScreenLink = getSubscriptionsListScreenLink({
        referralAssetType,
        referralAssetId,
    });

    router.push({
        pathname: subscriptionsListScreenLink.href,
        query: { referralAssetId, referralAssetType },
    });
};

export const goToPurchasePage = ({
    referralAssetId,
    referralAssetType,
    offerId,
    router,
}: Partial<ReferralAssetData> & { offerId: string; router: WebRouter }) => {
    const referralAssetQuery =
        referralAssetId != null && referralAssetType != null
            ? {
                  referralAssetId,
                  referralAssetType,
              }
            : {};

    const queryForCleengPurchasePage = {
        offerId,
        ...referralAssetQuery,
    };

    const link = SOTT_DEFAULT_WEB_SCREENS.PurchaseSubscription.getLink(queryForCleengPurchasePage);
    router.push({
        pathname: link.href,
        query: { ...queryForCleengPurchasePage },
    });
};
