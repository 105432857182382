import { isPlatformWeb } from 'renative';
import { DimensionsInfo } from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions/types';
import { Breakpoint } from './constants';

export const TABLET_VERSION_BREAKPOINT = Breakpoint.SM;

export const DESKTOP_VERSION_BREAKPOINT = Breakpoint.MD;

export const shouldDisplayDesktopVersion = (dimensions: DimensionsInfo) =>
    isPlatformWeb && dimensions.width > DESKTOP_VERSION_BREAKPOINT;
