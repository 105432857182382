/* eslint-disable no-console */
export const logWarn = (...args: unknown[]) => {
    if (__DEV__) {
        console.warn.apply(null, args);
    }
};

export const logError = (...args: unknown[]) => {
    if (__DEV__) {
        console.error.apply(null, args);
    }
};

export const log = (...args: unknown[]) => {
    if (__DEV__) {
        console.log.apply(null, args);
    }
};
