import React, { FC } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text, Interactable, ThemeIcon } from '@24i/nxg-sdk-quarks';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import getSettingsOptionPageStyles from '../styles/index.web';
import { normalizeTestId } from '../../../../../utils/testing';
import { SettingsOptionPageProps } from '../types';

const SettingsOptionPage: FC<SettingsOptionPageProps> = ({
    subtitle,
    description,
    renderOptions,
    optionalButtonProps,
    styles: getStyles = getSettingsOptionPageStyles,
}) => {
    const [isListOpen, setIsListOpen] = React.useState(false);
    const { theme } = useTheme();

    const styles = getStyles(theme);

    return (
        <View>
            <View style={styles.container}>
                <View style={styles.itemContainer}>
                    <Text numberOfLines={2} style={styles.descriptionText}>
                        {description}
                    </Text>
                    <Interactable
                        style={styles.dropdownToggler}
                        onPress={() => setIsListOpen(!isListOpen)}
                        testID={normalizeTestId(description, 'picker')}
                    >
                        <Text style={styles.subheaderText}>{subtitle}</Text>
                        <View style={styles.icon}>
                            <ThemeIcon size={24} iconName="onPrimary-select" />
                        </View>
                    </Interactable>
                </View>
            </View>
            {optionalButtonProps && <TertiaryButton {...optionalButtonProps} />}
            {isListOpen && <View style={styles.dropdownContainer}>{renderOptions()}</View>}
        </View>
    );
};

export default overridable(SettingsOptionPage, 'SettingsOptionPage');
