import React from 'react';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import GenericModal from '../index';
import { GenericModalProps } from '../types';

const useGenericModal = () => {
    const { setModalChildren, closeModal } = useModal();

    const openGenericModal = (props: GenericModalProps): JSX.Element =>
        setModalChildren(<GenericModal {...props} />);

    return { openGenericModal, closeGenericModal: closeModal };
};

export default useGenericModal;
