import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ColorValue } from 'react-native';
import { RemoveModalStyles } from './types';
import { MODAL_BREAKPOINT } from '../../../constants';

const getRemoveModalStyles = (theme: Theme): RemoveModalStyles => {
    return {
        modalContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            minHeight: 421,
            paddingHorizontal: 56,
            paddingVertical: 34,
            width: 384,
            backgroundColor: theme.color.primary1,
            ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                width: '100%',
                height: '100%',
                padding: 32,
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
                maxWidth: 'none',
            },
        },
        modalHeading: {
            textAlign: 'center',
            color: <ColorValue>theme.color.textPrimary,
            ...getFont(theme, 'h2'),
            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                ...getFont(theme, 'h2'),
            },
            marginBottom: 50,
        },
        name: {
            color: theme.color.textPrimary,
            ...getFont(theme, 'h4'),
            marginTop: 16,
        },
        modalDescription: {
            textAlign: 'center',
            marginTop: 6,
            opacity: 0.6,
            color: <ColorValue>theme.color.textPrimary,
            ...getFont(theme, 'h6'),
            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                textAlign: 'center',
            },
        },
        removeButton: {
            marginTop: 33,
            left: 6,
        },
        cancelButton: {
            marginTop: 27,
            [`@media (max-width: ${MODAL_BREAKPOINT}px)`]: {
                marginTop: 48,
            },
        },
        cancelButtonText: {
            color: <ColorValue>theme.color.textPrimary,
        },
    };
};

export default getRemoveModalStyles;
