import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { SOTT_DEFAULT_WEB_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';

const useViewModel = (props) => {
    const router = useRouter();

    const onAddSubscriptionPress = () => {
        const addSubscriptionLink = SOTT_DEFAULT_WEB_SCREENS.Account.getLink({
            subScreen: 'addsubscription',
        });
        router.push(addSubscriptionLink.href, addSubscriptionLink.as);
    };

    return {
        onAddSubscriptionPress,
        ...props,
    };
};

export { useViewModel };
