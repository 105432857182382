/* eslint-disable @typescript-eslint/naming-convention */
import { AnyPayload, EventBody } from '@24i/nxg-sdk-photon/src/clients/AnalyticsClient';
import { isPlatformTvos } from 'renative';
import { UrlParamsResult } from './types';

const mapUrlParams = (
    eventBody: EventBody,
    isFirstVisit: boolean,
    sessionCount: number
): UrlParamsResult => ({
    cid: eventBody.device_id,
    sid: eventBody.session_id,
    ul: eventBody.language,
    uap: eventBody.device_platform,
    uapv: eventBody.os_version,
    uamb: isPlatformTvos ? 0 : 1,
    en: eventBody.event,
    seg: 1,
    sr: eventBody.screen_resolution,
    sct: sessionCount,
    'up.device_manufacturer': eventBody.device_manufacturer,
    'up.device_platform': eventBody.device_platform,
    'up.app_version': eventBody.app_version,
    'up.device_platform_version': eventBody.device_platform_version,
    'up.is_authenticated': eventBody.is_authenticated,
    ...(isFirstVisit && { _fv: 1 }),
    ...(isFirstVisit && { _ee: 1 }),
});

export const getUrl = (
    baseUrl: string,
    version: number,
    measurementId: string,
    eventBody: EventBody,
    isFirstVisit: boolean,
    sessionCount: number
): string => {
    const params = mapUrlParams(eventBody, isFirstVisit, sessionCount);
    const urlParts = [baseUrl, `?v=${version}&tid=${measurementId}`];
    Object.keys(params).forEach((paramName) => {
        urlParts.push(`&${paramName}=${encodeURIComponent(params[paramName])}`);
    });
    return urlParts.join('');
};

const getParamsRidOfUrlparams = (eventBody: EventBody): AnyPayload => {
    const {
        event_trigger,
        language,
        session_id,
        firebase_screen,
        video_percent,
        video_current_time,
        video_asset_id,
        video_title,
        video_external_id,
        content_type,
        video_series_title,
        video_episode_number,
        video_season_number,
        channel_number,
        channel_title,
        percent_scrolled,
        video_duration,
        video_url,
    } = eventBody;
    return {
        event_trigger,
        language,
        session_id,
        ...(firebase_screen ? { firebase_screen } : {}),
        ...(video_percent ? { video_percent } : {}),
        ...(video_current_time ? { video_current_time } : {}),
        ...(video_asset_id ? { video_asset_id } : {}),
        ...(video_title ? { video_title } : {}),
        ...(video_external_id ? { video_external_id } : {}),
        ...(content_type ? { content_type } : {}),
        ...(video_series_title ? { video_series_title } : {}),
        ...(video_episode_number ? { video_episode_number } : {}),
        ...(video_season_number ? { video_season_number } : {}),
        ...(channel_number ? { channel_number } : {}),
        ...(channel_title ? { channel_title } : {}),
        ...(percent_scrolled ? { percent_scrolled } : {}),
        ...(video_duration ? { video_duration } : {}),
        ...(video_url ? { video_url } : {}),
    };
};

export const getTagsBody = (eventBody: EventBody): string => {
    const eventParams = [`en=${eventBody.event}`];
    const paramsRidOfUrlParams = getParamsRidOfUrlparams(eventBody);
    Object.keys(paramsRidOfUrlParams).forEach((paramName) => {
        eventParams.push(`ep.${paramName}=${encodeURIComponent(paramsRidOfUrlParams[paramName])}`);
    });
    return eventParams.join('&');
};

export const capitalize = (string: string): string =>
    string.charAt(0).toUpperCase() + string.slice(1);
