import { Platform } from 'react-native';
import keyMap, { getKeyName } from './keyMap';
import engine from './engine';
import factor from './factor';

export default {
    ...Platform,
    platform: Platform.OS,
    getKeyName,
    keyMap,
    engine,
    factor,
};
