import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import { WebRouter, useRouter } from '@24i/nxg-core-router/src/NextRouter';
import {
    goToAssetDetailsPage,
    goToMySubscriptionsPage,
    goToPurchasePage,
} from '../../../utils/navigation';
import SubscriptionSelection from '../../../components/subscriptions/SubscriptionSelection';
import { SubscriptionsListScreenProps } from '../types';

const SubscriptionsListScreenView: FC<SubscriptionsListScreenProps> = ({
    query,
    availableSubscriptions = [],
    isLoading,
}) => {
    const router = useRouter();
    const { t } = useTranslation();

    const onBackClick = () => {
        if (query?.referralAssetId && query.referralAssetType) {
            goToAssetDetailsPage({
                router: router as WebRouter,
                referralAssetType: query.referralAssetType,
                referralAssetId: query.referralAssetId,
            });
        } else {
            goToMySubscriptionsPage(router as WebRouter, 'back');
        }
    };

    const handleOnSubscriptionSelect = (itemId: string) => {
        goToPurchasePage({
            router: router as WebRouter,
            offerId: itemId,
            referralAssetId: query?.referralAssetId,
            referralAssetType: query?.referralAssetType,
        });
    };

    return (
        <LoadingWrapper isLoading={isLoading}>
            <SubscriptionSelection
                description={t('svod.add.description')}
                subscriptions={availableSubscriptions}
                onSubscriptionSelect={handleOnSubscriptionSelect}
                onBackPress={onBackClick}
            />
        </LoadingWrapper>
    );
};

export default withWebHeader(SubscriptionsListScreenView);
