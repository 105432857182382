import React from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Device } from '@24i/nxg-sdk-photon';
import { View, Text, Interactable } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import Image from '@24i/nxg-sdk-quarks/src/components/Image/index';
import { getStyles } from './styles';

interface DeviceItemProps {
    device: Device;
    formatDate: (date: string) => string;
    onRemoveDevicePress: (deviceToRemove: Device) => void;
    isCurrent: boolean;
}

const DeviceItem = ({ device, formatDate, onRemoveDevicePress, isCurrent }: DeviceItemProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation(['sott']);
    const { name, platform, registrationDate } = device;

    const determineIcon = () => {
        switch (platform) {
            case 'iphone' || 'android':
                return theme.icons['onPrimary-device_smartphone'];
            case 'tabletAndroid' || 'ipad':
                return theme.icons['onPrimary-device_tablet'];
            default:
                return theme.icons['onPrimary-device_laptop'];
        }
    };

    const icon = determineIcon();

    return (
        <View
            key={`${device.name}${device.registrationDate}`}
            style={styles.deviceContainer}
            testID={`device-${device.name}`}
        >
            <Image source={icon.iconImage} style={{ height: icon.height, width: icon.width }} />
            <View />
            <View style={styles.textsContainer}>
                <Text style={styles.name}>{name}</Text>
                <Text style={styles.added}>{formatDate(registrationDate)}</Text>
            </View>

            {isCurrent ? (
                <Text style={{ ...styles.remove, color: `${theme.color.textPrimary}80` }}>
                    {t('manageDevices.thisDevice')}
                </Text>
            ) : (
                <Interactable
                    onPress={() => {
                        onRemoveDevicePress(device);
                    }}
                >
                    <Text style={styles.remove} testID={`remove-button-${device.name}`}>
                        {t('manageDevices.delete.deleteButton')}
                    </Text>
                </Interactable>
            )}
        </View>
    );
};

export default DeviceItem;
