import { Theme } from '@24i/nxg-sdk-photon';
import { DynaRowStyles } from '../../../../DynaRowScreen/types';

const getWebDynaRowScreenStylesOverride = (_theme: Theme): DynaRowStyles => ({
    app: {
        backgroundColor: 'transparent',
        flex: 1,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
    },
    holder: {
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    activityIndicator: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
});

export { getWebDynaRowScreenStylesOverride };
