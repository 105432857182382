import React from 'react';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Theme, Profile } from '@24i/nxg-sdk-photon';
import { View, Text, Interactable } from '@24i/nxg-sdk-quarks';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';
import getDeleteModalStyles from './styles';
import { DeleteModalStyle } from './styles/types';
import { UPSERT_PROFILE_TEST_IDS } from '../../types';

interface DeleteModalProps {
    userProfile: Profile | null;
    isVisible?: boolean;
    styles?: (theme: Theme, isLandscape) => DeleteModalStyle;
    onModalClose: () => void;
    onDeleteProfilePress: (profileId: string) => void;
}

const DeleteModal = ({
    userProfile,
    isVisible = false,
    styles: getStyles = getDeleteModalStyles,
    onModalClose,
    onDeleteProfilePress,
}: DeleteModalProps) => {
    const { theme } = useTheme();
    const { isLandscape } = useDimensions();
    const styles = getStyles(theme, isLandscape);
    const { t } = useTranslation(['sott']);
    const deleteProfileTitle = userProfile?.name
        ? t('profiles.delete.deleteConfirm.title', { profileName: userProfile.name })
        : t('manageProfiles.deleteQuestionFallback');

    const buttons = isPlatformWeb ? (
        <View style={styles.buttonsWrapper}>
            <SubactionButton
                title={t('manageProfiles.cancel')}
                onPress={onModalClose}
                additionalContainerStyles={styles.cancelButton}
                testID={UPSERT_PROFILE_TEST_IDS.DELETE_PROFILE_DIALOG_CANCEL_BUTTON}
            />
            <ActionButton
                title={t('profiles.delete.deleteButton')}
                onPress={() => {
                    if (userProfile?.id) {
                        onDeleteProfilePress(userProfile?.id);
                        onModalClose();
                    }
                }}
                additionalContainerStyles={styles.deleteButton}
                // This was here before, I don't know why it is done this way
                disabled={(userProfile?.name && userProfile?.name?.length < 1) || false}
                testID={UPSERT_PROFILE_TEST_IDS.DELETE_PROFILE_DIALOG_DELETE_BUTTON}
            />
        </View>
    ) : (
        <>
            <ActionButton
                title={t('profiles.delete.deleteButton')}
                onPress={() => {
                    if (userProfile?.id) {
                        onDeleteProfilePress(userProfile?.id);
                        onModalClose();
                    }
                }}
                additionalContainerStyles={styles.deleteButton}
                disabled={(userProfile?.name && userProfile?.name?.length < 1) || false}
            />
            <Interactable style={styles.cancelButton} onPress={onModalClose}>
                <Text style={styles.cancelButtonText}>{t('common.cancel')}</Text>
            </Interactable>
        </>
    );

    return (
        <Modal visible={isVisible} onRequestClose={onModalClose}>
            <View style={styles.modalContainer}>
                {userProfile?.name && <Text style={styles.modalHeading}>{deleteProfileTitle}</Text>}
                <Text style={styles.modalDescription}>
                    {t('profiles.delete.deleteConfirm.description')}
                </Text>
                {buttons}
            </View>
        </Modal>
    );
};

export default DeleteModal;
