import { StyleSheet } from 'react-native';
import { Platform } from '@24i/nxg-sdk-quantum';

const TRACK_HEIGHT = 3;
const THUMB_HEIGHT = 30;
const HALF_THUMB_HEIGHT = THUMB_HEIGHT / 2;

const { OS } = Platform;

export const styles = StyleSheet.create({
    slider: {
        position: 'relative',
        width: '100%',
        height: THUMB_HEIGHT,
    },
    track: {
        marginTop: 15,
        height: TRACK_HEIGHT,
        display: 'flex',
    },
    thumb: {
        position: 'absolute',
        width: THUMB_HEIGHT,
        height: THUMB_HEIGHT,
        marginTop: OS === 'smarttv' ? -HALF_THUMB_HEIGHT : 0,
        marginLeft: -HALF_THUMB_HEIGHT,
        borderRadius: HALF_THUMB_HEIGHT,
        borderWidth: 1,
        borderColor: '#999999',
        backgroundColor: '#ffffff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    thumbDisabled: {
        opacity: 0.5,
    },
});
