import { getFont, getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DetailsStyles } from '../types';

const getDetailsStyles = (theme: Theme): DetailsStyles => ({
    holder: {
        flex: 1,
        backgroundColor: theme.color.background1,
        height: '100%',
    },
    imageBackground: {
        top: 0,
        height: 700,
        width: '100%',
        alignSelf: 'flex-start',
        position: 'absolute',
    },
    topGradient: {
        top: 0,
        left: 0,
        height: 700,
        position: 'absolute',
        right: 0,
        zIndex: -1,
    },
    bottomGradient: {
        height: 700,
        width: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
    },
    wrapperGradient: {
        height: '100%',
        left: 0,
        right: 0,
        position: 'absolute',
    },
    contentContainer: {
        bottom: 40,
        ...getMargins(),
    },
    contentPickerHorizontalLine: {
        top: -2,
        height: 2,
        backgroundColor: theme.color.lighter4,
        borderRadius: 1,
        ...getMargins(),
    },
    contentPickerContainer: {
        ...getMargins(),
        flexDirection: 'row',
        overflow: 'scroll',
    },
    contentPickerItem: {
        marginRight: 32,
        paddingBottom: 12,
        borderBottomWidth: 2,
    },
    activeTabLine: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 2,
        width: '100%',
        backgroundColor: theme.color.buttonPrimary,
        borderRadius: 1,
    },
    contentPickerItemText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h3'),
    },
    bottomPart: {
        overflow: 'hidden',
        ...getMargins(),
    },
    bottomPartContainer: {
        backgroundColor: theme.color.darker3,
        paddingTop: 40,
    },
    actionButtonContainer: {
        alignSelf: 'center',
        backgroundColor: theme.color.buttonPrimary,
    },
    actionButtonText: {
        color: theme.color.textButtonPrimary,
    },
    inactiveTabText: {
        opacity: 0.6,
        ':hover': { opacity: 1 },
        transition: 'opacity 0.2s ease-in',
    },
    loader: {
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default getDetailsStyles;
