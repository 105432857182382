export const BASE_BACKSTAGE_URI = 'https://backstage-cms.24i.com/api';

export { createBackstageUserDataClient, BackstageUserDataClient } from './clients/UserDataClient';
export {
    createBackstageAnalyticsDataClient,
    BackstageAnalyticsDataClient,
} from './clients/AnalyticsDataClient';
export {
    createBackstageAppSettingDataClient,
    BackstageAppSettingsDataClient,
} from './clients/AppSettingsDataClient';
export {
    createBackstageEntitlementsClient,
    BackstageEntitlementsClient,
} from './clients/EntitlementsClient';
export {
    createBackstageThemeDataClient,
    BackstageThemeDataClient,
} from './clients/ThemeDataClient';
export {
    createBackstageAppStructureDataClient,
    BackstageAppStructureDataClient,
} from './clients/AppStructureDataClient';
export { ContentDataClient } from './clients/ContentDataClient';
export {
    createBackstagePlayerDataClient,
    BackstagePlayerDataClient,
} from './clients/PlayerDataClient';
export {
    createBackstagePurchaseDataClient,
    BackstagePurchaseDataClient,
} from './clients/PurchaseDataClient';
export {
    createBackstagePrimetimeDataClient,
    BackstagePrimetimeDataClient,
} from './clients/PrimetimeDataClient';
export { getParamsFromRuntimeConfig } from './runtimeParameters';

export * from './clients/DefaultBackstageClients';
export * from './clients/DefaultBackstageClients/types';
