import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { RecordModalStyles } from './types';

const getRecordModalWebStyles = (theme: Theme): RecordModalStyles => ({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: theme.color.darker3,
    },
    childContainer: {
        width: 528,
        backgroundColor: theme.color.primary1,
        borderRadius: 8,
        padding: 24,
    },
    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    title: {
        color: theme.color.textPrimary,
        marginBottom: 26,
        ...getFont(theme, 'h3'),
    },
    description: {
        color: theme.color.contrast3,
        marginBottom: 32,
        ...getFont(theme, 'body2'),
    },
    deleteButtonContainer: {
        height: 54,
        backgroundColor: theme.color.buttonPrimary,
        marginRight: 0,
    },
    cancelButtonContainer: {
        height: 54,
        marginRight: 16,
        backgroundColor: theme.color.buttonSecondary,
    },
    cancelButtonText: {
        textTransform: 'uppercase',
        color: theme.color.textPrimary,
    },
});

export default getRecordModalWebStyles;
