export const webViewLoaderStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
};
export const loaderStyle = { justifyContent: 'center' };

export const parseUrl = (url: string): { [key: string]: string } => {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params: { [key: string]: string } = {};
    let match;

    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(url))) {
        // eslint-disable-next-line prefer-destructuring
        params[match[1]] = match[2];
    }

    return params;
};
