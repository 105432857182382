/*
@PRODUCT_FEATURE: H 1.7.1
*/
/* eslint-disable react/display-name */
import React from 'react';

import View, { getSearchScreenStyles } from './View';
import { useViewModel } from './viewModel';
import SearchScreenContent from './components/SearchScreenContent';
import SearchDropdown from './components/SearchDropdown';
import SearchNothingFound from './components/SearchNothingFound';
import SearchTextInput from './components/SearchTextInput';
import * as prerenderFunctions from '../../pages/search';
import { SearchScreenProps } from './types';

export default {
    Main: (props: SearchScreenProps) => {
        const viewModelProps = useViewModel(props);

        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    page: {
        search: prerenderFunctions,
    },
    // components
    SearchScreenContent,
    SearchDropdown,
    SearchNothingFound,
    SearchTextInput,
    // default styles getter
    getDefaultStyles: getSearchScreenStyles,
};
