import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';

const SettingsOptionPage = (theme) => ({
    container: {
        flex: 1,
    },
    subheaderText: {
        color: `${theme.color.menuItemInactive}99`,
        ...getFont(theme, 'menuItem-inactive'),
    },
    descriptionText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
        width: '70%',
    },
    itemContainer: {
        borderColor: 'transparent',
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        color: `${theme.color.menuItemInactive}99`,
        cursor: 'pointer',
        '@media (max-width: 800px)': {
            flexDirection: 'row',
            marginRight: 0,
        },
        ':hover': { color: `${theme.color.menuItemActive}` },
    },
    icon: {
        marginLeft: 8,
    },
    dropdownContainer: {
        alignItems: 'flex-start',
        backgroundColor: theme.color.darker1,
        borderRadius: 4,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        position: 'absolute',
        minWidth: 126,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 20,
        paddingRight: 20,
        right: 0,
    },
    button: {
        width: '40%',
        padding: 5,
        backgroundColor: theme.color.buttonPrimary,
        borderRadius: 27,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
        marginRight: '4%',
        alignItems: 'center',
    },
});

export default SettingsOptionPage;
