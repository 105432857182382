import React from 'react';
import Toast from 'react-native-toast-message';
import { View } from '@24i/nxg-sdk-quarks';
import ToastComponent from '../components/Toast';

const ToastProvider = () => (
    <View
        style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            zIndex: 100,
        }}
        pointerEvents="none"
    >
        <Toast
            config={{
                // eslint-disable-next-line react/display-name
                success: ({ text1, ...props }) => <ToastComponent {...props} text={text1} />,
                error: ({ text1, ...props }) => <ToastComponent {...props} text={text1} />,
            }}
            ref={(ref) => Toast.setRef(ref)}
        />
    </View>
);

export default ToastProvider;
