import React from 'react';
import View from './View';
import getDeleteAccountModalStyles from './styles';
import useViewModel from './viewModel';
import { DeleteAccountModalProps } from './types';

const Main = (props: DeleteAccountModalProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default { Main, View, useViewModel, getDeleteAccountModalStyles };
