export enum BreakpointDirection {
    Min = 'min',
    Max = 'max',
}

export enum Breakpoint {
    SM = 576,
    MD = 743,
    L = 992,
    XL = 1200,
    XXL = 1600,
}
