import React, { createContext, useContext } from 'react';

import { getDummyClient } from '@24i/nxg-sdk-photon';
import { RecordingsClient } from '@24i/nxg-sdk-photon/src/clients/RecordingsClient';

const dummyClient = getDummyClient<RecordingsClient>();

export const RecordingsContext = createContext<RecordingsClient>(dummyClient);

export const RecordingsProvider = ({ client = dummyClient, children }) => (
    <RecordingsContext.Provider value={client}>{children}</RecordingsContext.Provider>
);

export const useRecordings = () => useContext(RecordingsContext);
