/*
@PRODUCT_FEATURE: TU 1.2.0
*/

import React from 'react';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import View from './View';
import { useViewModel } from './viewModel';

const Main = (props: ScreenProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default {
    Main,
};
