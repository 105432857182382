import { isPlatformWeb } from 'renative';
import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont, Ratio } from '@24i/nxg-sdk-higgs';
import { PackshotTitleStyles } from '../types';

export const getPackshotTitleStyles = (
    theme: Theme,
    textColor = theme.color.textPrimary
): PackshotTitleStyles => ({
    textWrapper: {
        marginTop: 6,
        justifyContent: 'flex-start',
        height: 70,
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemText: {
        fontWeight: 'bold',
        paddingTop: 2,
        color: textColor,
        ...(isPlatformWeb
            ? {
                  lineBreak: 'auto',
              }
            : {}),
        ...getFont(theme, 'h4'),
    },
    displayOver: {
        color: textColor,
        ...getFont(theme, 'h3'),
    },
    genreText: {
        color: textColor,
        ...getFont(theme, 'h3'),
    },
    itemTextSubs: {
        textAlign: 'left',
        paddingTop: 2,
        color: textColor,
        opacity: 0.6,
        ...getFont(theme, 'h5'),
    },
    itemTextSecondarySubs: {
        paddingTop: 2,
        color: textColor,
        textTransform: 'uppercase',
        opacity: 0.6,
        ...getFont(theme, 'h6'),
    },
    titleContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        // @ts-ignore
        background: theme.color.gradient2.color,
        color: textColor,
    },
    itemTextFocused: {},
    seeAllIcon: {
        height: Ratio(40),
        width: Ratio(40),
        marginLeft: Ratio(9),
    },
});
