export const getItem = (key) =>
    new Promise((resolve) => {
        if (typeof window === 'undefined') return resolve();
        const { localStorage } = window;
        return resolve(localStorage.getItem(key));
    });

export const setItem = (key, value) =>
    new Promise((resolve) => {
        if (typeof window === 'undefined') return resolve();
        const { localStorage } = window;
        localStorage.setItem(key, value);
        return resolve();
    });

export const removeItem = (key) =>
    new Promise((resolve) => {
        if (typeof window === 'undefined') return resolve();
        const { localStorage } = window;
        localStorage.removeItem(key);
        return resolve();
    });

export const getAllKeys = () =>
    new Promise((resolve) => {
        if (typeof window === 'undefined') return resolve();
        const { localStorage } = window;
        return resolve(Object.keys(localStorage));
    });
