import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle, ViewStyle } from 'react-native';

export interface SearchNothingFoundStyles {
    nothingFoundContainer: ViewStyle;
    primaryText: TextStyle;
    secondaryText: TextStyle;
}

export interface SearchNothingFoundProps {
    styles?: (theme: Theme) => SearchNothingFoundStyles;
    query: string;
}

export enum SEARCH_NOTHING_FOUND_TEST_IDS {
    SEARCH_NOTHING_FOUND_TEXT_PRIMARY = 'search_nothing_found_text_primary',
    SEARCH_NOTHING_FOUND_TEXT_SECONDARY = 'search_nothing_found_text_secondary',
}
