import { useEffect, useState } from 'react';
import { isFactorTv } from 'renative';
import {
    SplashScreenModelProps,
    SplashScreenProps,
    SPLASH_PROMISE_STATE,
    SPLASH_SCREEN_STATES,
} from '../types';

const useViewModel = (props: SplashScreenModelProps<unknown>): SplashScreenProps => {
    const { minTime = 0, maxTime = isFactorTv ? 4000 : 3000, promise = Promise.resolve() } = props;
    if (maxTime < minTime) throw new Error('maxTime cannot be less than minTime');

    const [minTimePassed, setMinTimePassed] = useState<boolean>(() => !(minTime > 0));
    const [maxTimePassed, setMaxTimePassed] = useState<boolean>(() => !(maxTime > 0));
    const [promiseState, setPromiseState] = useState<SPLASH_PROMISE_STATE>(
        SPLASH_PROMISE_STATE.INITIAL
    );

    const getState = (
        isMinTimePassed: boolean,
        isMaxTimePassed: boolean,
        currentState: SPLASH_PROMISE_STATE
    ): SPLASH_SCREEN_STATES => {
        if (currentState === SPLASH_PROMISE_STATE.RESOLVED && isMinTimePassed) {
            return SPLASH_SCREEN_STATES.FINISHED;
        }
        if (currentState === SPLASH_PROMISE_STATE.REJECTED) {
            return SPLASH_SCREEN_STATES.ERROR;
        }
        if (isMaxTimePassed) {
            return SPLASH_SCREEN_STATES.LOADING;
        }

        return SPLASH_SCREEN_STATES.SPLASH;
    };

    useEffect(() => {
        promise
            .then((args) => {
                setPromiseState(SPLASH_PROMISE_STATE.RESOLVED);
                return args;
            })
            .catch((error) => {
                setPromiseState(SPLASH_PROMISE_STATE.REJECTED);
                throw new Error(error);
            });
    }, [promise]);

    useEffect(() => {
        if (minTime > 0) setTimeout(() => setMinTimePassed(true), minTime);
        if (maxTime > 0) setTimeout(() => setMaxTimePassed(true), maxTime);
    }, []);

    const currentState = getState(minTimePassed, maxTimePassed, promiseState);

    return {
        state: currentState,
        splashAnimationDuration: minTime / 3,
        ...props,
    };
};

export { useViewModel };
