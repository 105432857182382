import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from '../../contexts/RouteContext';
import Router from '../../Router';

const RouteProvider = ({ children, router }) => <Provider value={router}>{children}</Provider>;

RouteProvider.propTypes = {
    children: PropTypes.node.isRequired,
    router: PropTypes.instanceOf(Router).isRequired,
};

export default RouteProvider;
