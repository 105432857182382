/* eslint-disable react/display-name */
import React, { ComponentType } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack';
import { Theme } from '@24i/nxg-sdk-higgs';
import { Font, FontVariant } from '@24i/nxg-sdk-photon';

export const dummyFontVariantDef: Font = {
    character: 1,
    family: '',
    size: 1,
    weight: '400',
};
export const dummyFontVariant: FontVariant = {
    tertiaryButton: dummyFontVariantDef,
    secondaryButton: dummyFontVariantDef,
    primaryButton: dummyFontVariantDef,
    body1: dummyFontVariantDef,
    body2: dummyFontVariantDef,
    h1: dummyFontVariantDef,
    h2: dummyFontVariantDef,
    h3: dummyFontVariantDef,
    h4: dummyFontVariantDef,
};

const dummyIcon = { iconFont: '', iconName: '' };

export const dummyTheme = {
    color: {},
    elements: {},
    fonts: {
        S: dummyFontVariant,
        M: dummyFontVariant,
        L: dummyFontVariant,
        XL: dummyFontVariant,
    },
    icons: {
        auth: { incorrect: dummyIcon },
        settings: { back: dummyIcon, check: dummyIcon },
        menu: { back: dummyIcon },
        search: { dropdownItem: dummyIcon, cancel: dummyIcon, clear: dummyIcon, input: dummyIcon },
    },
    logo: '',
    name: '',
    regular: '',
    backgroundImage: '',
};

export const Providers: React.FC = ({ children }) => {
    return (
        <NavigationContainer>
            <Theme theme={dummyTheme}>{children}</Theme>
        </NavigationContainer>
    );
};

const Stack = createStackNavigator();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AsScreen = (ScreenComponent: ComponentType<StackScreenProps<any, any>>) => () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="test_main" component={ScreenComponent} />
        </Stack.Navigator>
    );
};

export const normalizeTestId = (title: string, suffix?: string) =>
    [title.trim().toLowerCase().replace(/\s/g, '_'), suffix].filter(Boolean).join('_');
