import { createEnum, createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { WebScreenLink } from '@24i/nxg-sdk-smartott/src/navigation/types';
import { PRODUCT_SCREENS } from '../enums';

export const PRODUCT_SCREENS_GUARD = createEnum<PRODUCT_SCREENS>(
    PRODUCT_SCREENS,
    'SupportedProductScreens'
);

export type MenuItem = {
    name: string;
    title: string;
    icon?: string;
    image?: string;
    displayDividerUnder?: boolean;
    menuTitle?: string;
    webMenuLink?: WebScreenLink<Record<never, unknown>>;
    // TODO: Rename
    /** This is used to store a "reference" to external data that have to be fetched in order for this page to work */
    reference?: string | null;
    screenTypeToUse?: t.TypeOf<typeof PRODUCT_SCREENS_GUARD>;
    embeddedMenuItems?: MenuItem[];
    preferredWebMenuPlacement?: 'default' | 'profile_dropdown' | 'footer';
};

export const MenuItemGuard = t.recursion<MenuItem>('MenuItem', (Self) =>
    createGuard(
        'MenuItem',
        {
            name: t.string,
            title: t.string,
        },
        // TODO: These should probably be required? But currently this is how it is worked with
        {
            icon: t.string,
            // TODO: Maybe rename this to something like ID. It is used as a identifier
            reference: nullable(t.string),
            screenTypeToUse: PRODUCT_SCREENS_GUARD,
            embeddedMenuItems: t.array(Self),
        }
    )
);

export const createMenuItem = (data: Record<string, unknown> | MenuItem): MenuItem | never => {
    return decodeModelWith(MenuItemGuard, data, 'MenuItem');
};
