import React from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text } from '@24i/nxg-sdk-quarks';
import PackshotGrid from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import { PlaylistScreenProps } from '../types';
import getPlaylistScreenStyles from '../styles';

const PlaylistScreen = ({
    assets,
    title,
    styles: getStyles = getPlaylistScreenStyles,
    isLoading,
    handlePackshotPress,
}: PlaylistScreenProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.holder}>
            <View style={styles.pickerHolder}>
                <Text style={styles.sectionTitle}>{title}</Text>
            </View>
            {/* @ts-ignore to workaround that title maybe undefined */}
            <PackshotGrid items={assets} onItemPress={handlePackshotPress} />
            {isLoading && <Loader />}
        </View>
    );
};

export default PlaylistScreen;
