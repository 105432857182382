import { ASSET_TYPE, Product, Rent, Purchase, Subscription, Theme } from '@24i/nxg-sdk-photon/src';
import { ConfirmPurchaseStyles } from './styles/types';

export enum PurchaseErrorType {
    UNAVAILABLE = 'unavailable',
    UNKNOWN = 'unknown',
}
export class PurchaseError extends Error {
    type: PurchaseErrorType;

    constructor(message, type: PurchaseErrorType = PurchaseErrorType.UNKNOWN) {
        super(message);
        this.name = 'PurchaseError';
        this.type = type;
    }
}

export interface ConfirmPurchasePinProps {
    isPinControlVisible?: boolean;
    isPinVerificationActive?: boolean;
    children?: React.ReactNode;
    setPinControlVisible?: (visible: boolean) => void;
    onSubmitPin?: () => void;
}

export interface ConfirmPurchaseProps {
    selectedItem: Purchase | Rent | Product | Subscription;
    assetId?: string;
    assetType?: ASSET_TYPE;
    topText?: string | null;
    middleText?: string | null;
    bottomText?: string | null;
    isLoading?: boolean;
    isDisabled?: boolean;
    isModalContent?: boolean;
    isOnboarding?: boolean;
    closeModal?: () => void;
    onPurchaseSuccess?: () => void;
    onPurchaseError?: (error?: PurchaseError | unknown) => void;
    onPurchaseConfirm?: () => void;
    onPinValidationVisible?: (visible: boolean) => void;
    styles?: (theme: Theme, isModalContent?: boolean) => ConfirmPurchaseStyles;
}

export interface ConfirmPurchaseViewProps extends ConfirmPurchaseProps, ConfirmPurchasePinProps {
    messageBeforePrice?: string;
    topText?: string | null;
    middleText?: string | null;
    bottomText?: string | null;
}

export interface ProductConfirmPurchaseProps extends ConfirmPurchaseProps {
    selectedItem: Rent | Product | Purchase;
}

export interface SubscriptionConfirmPurchaseProps extends ConfirmPurchaseProps {
    selectedItem: Subscription;
}
