import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';

export const getStyles = (theme: Theme) => ({
    wrapper: {
        paddingVertical: 24,
        paddingHorizontal: 24,
        paddingBottom: 24,
        maxWidth: 528,
    },
    titleWrapper: {
        marginBottom: 16,
    },
    title: {
        fontSize: 24,
        color: theme.color.textPrimary,
    },
    contentWrapper: {
        marginTop: 32,
        marginBottom: 32,
    },
    modalContentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    modalContent: {
        backgroundColor: theme.color.primary1,
        borderRadius: 8,
        overflow: 'scroll',
    },
    content: {
        color: theme.color.textPrimary,
        opacity: 0.6,
    },
    buttonsWrapper: {
        flexDirection: 'row-reverse',
    },
    actionButtonText: {
        ...getFont(theme, 'primaryButton'),
    },
    actionButtonContainer: {
        width: 'auto',
        paddingHorizontal: 16,
    },
    cancelButtonContainer: {
        width: 'auto',
        paddingHorizontal: 16,
        height: 54,
    },
});
