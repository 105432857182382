export { getCommonServerSideProps as getServerSideProps } from '../../../utils/commonPrerender';

export async function getStaticPaths() {
    const settingsOptions = ['language', 'technicalInfo'];

    const paths = settingsOptions.map((type) => ({ params: { type } }));

    return {
        paths,
        fallback: false,
    };
}
