import { useContext } from 'react';
import Context from '../contexts/RouteContext';

const useRouter = () => {
    const router = useContext(Context);

    return router;
};

export default useRouter;
