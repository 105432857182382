import { useMutation } from 'react-query';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useFirebase } from '../../../context/Firebase';

export const useDeleteUser = () => {
    const { deleteUser } = useUserData();
    const { recordError } = useFirebase();

    return useMutation(() => deleteUser(), {
        onError: (error) => {
            log(error);
            recordError?.(error);
        },
    });
};
