import * as t from 'io-ts';
import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';

export const ConnectCodeGuard = createGuard('ConnectCode', {
    code: t.string,
    expires: t.number,
});

export const MvpdAuthGuard = createGuard('MvpdAuth', {
    mvpd: t.string,
    userId: t.string,
    authenticated: t.number,
});

export const CheckAuthPrimetimeGuard = createGuard('CheckAuthPrimetime', {
    message: nullable(t.string),
    token: nullable(t.string),
    expires: nullable(t.number),
});
