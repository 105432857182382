/* eslint-disable @typescript-eslint/no-explicit-any */
import { extendGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ASSET_TYPE, BLOCKING_REASON_TYPES } from '../enums';
import { AssetGuard } from './asset';

export const MovieGuard = extendGuard(
    'Movie',
    AssetGuard,
    {
        duration: t.number,
        year: t.number,
    },
    {}
);

export const isMovie = (asset: any): asset is Movie => {
    return asset && asset.type === ASSET_TYPE.MOVIE;
};

export const isAnnouncedMovie = (asset: any): asset is Movie => {
    return (
        isMovie(asset) &&
        (asset.blocked || []).some((block) => block.reason === BLOCKING_REASON_TYPES.BLOCKED_WINDOW)
    );
};

export type Movie = t.TypeOf<typeof MovieGuard>;
export type isAnnouncedMovie = Movie;
