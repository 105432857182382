import React, { FC } from 'react';
import { isPlatformWeb } from 'renative';
import { SvgXml } from 'react-native-svg';
import { useProjectIcons } from '@24i/nxg-sdk-smartott-shared';
import { GenericIconProps } from '../types';
import { getIconStyles } from '../utils';
import { FallbackIcon } from '../FallbackIcon';

export const ProjectLocalIcon: FC<GenericIconProps> = ({ iconName, size, iconStyles }) => {
    const { projectIcons } = useProjectIcons();
    const { imageStyles, svgStyles } = getIconStyles(iconStyles, size);

    if (iconName in projectIcons) {
        const icon = projectIcons[iconName];

        if (isPlatformWeb) {
            return (
                <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
                    style={imageStyles}
                    alt={iconName}
                />
            );
        }
        return <SvgXml key={iconName} xml={icon} width={size} height={size} style={svgStyles} />;
    }

    return <FallbackIcon size={size} iconStyles={iconStyles} />;
};
