import React, { createContext, useContext } from 'react';
import { getDummyClient } from '@24i/nxg-sdk-photon';
import { DevicesManagementClient } from '@24i/nxg-sdk-photon/src/clients/DevicesManagementClient';

const dummyClient = getDummyClient<DevicesManagementClient>();

export const DevicesManagementContext = createContext<DevicesManagementClient>(dummyClient);

export const DevicesManagementProvider = ({ client = dummyClient, children }) => (
    <DevicesManagementContext.Provider value={client}>{children}</DevicesManagementContext.Provider>
);

export const useDevicesManagement = () => useContext(DevicesManagementContext);
