import React, { ReactElement } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import { IconButtonProps } from '../IconButton/types';
import { getTertiaryButtonStyles } from './View';

const TertiaryButton = (props: IconButtonProps): ReactElement => (
    <IconButton styles={getTertiaryButtonStyles} {...props} />
);

export { getTertiaryButtonStyles };
export default overridable(TertiaryButton, 'TertiaryButton');
