import React, { createContext, useContext } from 'react';
import {
    CastState,
    MediaPlayerState,
    Device,
    CastSession,
    CastChannel,
    MediaStatus,
    RemoteMediaClient,
} from '@24i/react-native-google-cast/src';
import CastButton from '@24i/react-native-google-cast/src/components/CastButton';
import { ContextType } from './types';

const contextDefaults: ContextType = {
    isCastConnected: false,
    selectedSubtitle: 0,
    selectedAudio: 0,
    setSubtitle: () => {},
    setAudio: () => {},
    startCastingStream: () => {
        console.warn('WARNING, you are calling useChromecast on unsupported platform');
        return Promise.resolve();
    },
};

const useCastChannel = (): CastChannel | null => null;
const useCastDevice = (): Device | null => null;
const useCastState = () => CastState.NO_DEVICES_AVAILABLE;
const useCastSession = (): CastSession | null => null;
const useDevices = (): Device[] | null => null;
const useMediaStatus = (): MediaStatus | null => null;
const useRemoteMediaClient = (): RemoteMediaClient | null => null;
const useStreamPosition = (): number | null => null;
class CastContext {
    static getSessionManager() {
        return { startSession: (deviceId: string) => deviceId, endCurrentSession: () => {} };
    }
}

// NOTE: Chromecast is supported only on Mobiles/tablets
export const ChromecastContext = createContext<ContextType>(contextDefaults);

export const CastProvider = ({ children }) => {
    return (
        <ChromecastContext.Provider value={contextDefaults}>{children}</ChromecastContext.Provider>
    );
};

export const useCast = () => useContext(ChromecastContext);

export {
    useCastChannel,
    useCastDevice,
    useCastState,
    useCastSession,
    useDevices,
    useMediaStatus,
    useRemoteMediaClient,
    useStreamPosition,
    CastButton,
    CastContext,
    CastState,
    MediaPlayerState,
    RemoteMediaClient,
};
