/* eslint-disable react/display-name */
/*
@PRODUCT_FEATURE: TA 2.1.0
*/
import { usePinProtectedScreen } from '@24i/nxg-sdk-pin-protection/src/hooks/usePinProtectedScreen';
import React from 'react';
import { ViewProps } from 'react-native';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { PinProtectionScopes } from '@24i/nxg-sdk-photon/src/models/appSettings';
import View from './View';
import { useViewModel } from './viewModel';
import { UpsertScreenViewModelProps } from './types';

const Main = (props: ViewProps & UpsertScreenViewModelProps & Partial<ScreenProps>) => {
    const viewModelProps = useViewModel(props);
    usePinProtectedScreen({ ...props, scopes: [PinProtectionScopes.profileManagement] });
    return <View {...props} {...viewModelProps} />;
};

export default {
    Main,
    View,
    useViewModel,
};
