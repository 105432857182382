import React from 'react';
import { Animated } from 'react-native';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { Theme } from '@24i/nxg-sdk-photon';
import { Image } from '@24i/nxg-sdk-photon/src/models/image';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getCreatePaginatorStyles from './styles';
import { CreatePaginatorStyles } from './styles/types';

interface CarouselItemProps {
    data?: Array<Image>;
    scrollX?: Animated.Value;
    styles?: (theme: Theme) => CreatePaginatorStyles;
}

const Paginator = ({
    styles: getStyles = getCreatePaginatorStyles,
    data,
    scrollX,
}: CarouselItemProps) => {
    const { theme } = useTheme();
    const { width } = useDimensions();
    const styles = getStyles(theme);
    const dotColor = theme.color.lighterBlur1?.color;
    const dotColorActive = theme.color?.textPrimary;

    return (
        <>
            {data && (
                <View style={styles.paginatorWrapper}>
                    {data?.map((_, i) => {
                        const inputRange = [(i - 1) * width, i * width, i * 1 * width];
                        const dotColorTransition = scrollX?.interpolate({
                            inputRange,
                            outputRange: [dotColor, dotColorActive, dotColor],
                            extrapolate: 'clamp',
                        });
                        return (
                            <Animated.View
                                style={[
                                    styles.positionIdicator,
                                    { backgroundColor: dotColorTransition },
                                ]}
                                // eslint-disable-next-line react/no-array-index-key
                                key={i.toString()}
                            />
                        );
                    })}
                </View>
            )}
        </>
    );
};

export default overridable(Paginator, 'Paginator');
