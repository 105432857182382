import { Platform } from 'react-native';
import type { MVPD } from '../shared/types';

export const iOS = Platform.OS === 'ios';

export function parseXmlToMvpdArray(xmlResponse: string | XMLHttpRequestResponseType): MVPD[] {
    const parser = new window.DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');

    const items = Array.from(xmlDoc.getElementsByTagName('mvpd'));
    const values = items.map((item) => {
        const children = Array.from(item.children);
        return {
            id: children[0]?.textContent ? children[0]?.textContent : '',
            title: children[1]?.textContent ? children[1]?.textContent : '',
            logoUrl: children[2]?.textContent ? children[2]?.textContent : '',
        };
    });

    return values;
}
