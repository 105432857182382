import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { DetailsProgressBarStyles } from '../types';

const getDetailsProgressBarStyles = (theme: Theme, isLive?: boolean): DetailsProgressBarStyles => ({
    progressBarTrack: {
        width: 329,
        height: 5,
        bottom: 0,
        backgroundColor: theme.color.progressBarBackground,
        marginTop: 12,
        position: 'relative',
        borderRadius: 2,
        ...getBorderRadius(theme.elements, 'progressBar-cornerRadius'),
        alignSelf: 'flex-start',
    },
    progressContainer: {
        backgroundColor: isLive ? theme.color.progressBarLive : theme.color.progressBarAccent,
        borderRadius: 2,
        ...getBorderRadius(theme.elements, 'progressBar-cornerRadius'),
    },
    progressText: {
        ...getFont(theme, 'h4'),
        color: `${theme.color.textPrimary}`,
        marginTop: 16,
        opacity: 0.6,
    },
});

export default getDetailsProgressBarStyles;
