import React from 'react';
import PurchasableItem from '../View';
import { ProductItemProps } from './types';
import { useProductItem } from './viewModel';

const ProductItem = (props: ProductItemProps) => {
    const viewModelProps = useProductItem(props);
    return <PurchasableItem {...viewModelProps} />;
};

export default ProductItem;
