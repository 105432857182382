/*
@PRODUCT_FEATURE: G 1.2
*/
import { Platform } from '@24i/nxg-sdk-quantum';

const { isTV } = Platform;

/* eslint-disable global-require */
const getComponent = () => {
    if (!isTV) return require('./index.mobile').default;
    return require('./index.tv').default;
};
/* eslint-enable global-require */

export default getComponent();
