import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';

export interface SearchDropdownPropsStyles {
    holder: ViewStyle;
}

export interface SearchDropdownProps {
    items?: string[];
    onDropdownItemPress?: (item: string) => void;
    onRemovePress?: (item: string) => void;
    styles?: (theme?: Theme) => SearchDropdownPropsStyles;
}

export enum SEARCH_RECENT_TEST_IDS {
    SEARCH_RECENT_CONTAINER = 'search_recent_item_container',
    SEARCH_RECENT_TEXT = 'search_recent_text',
    SEARCH_RECENT_X_BUTTON = 'search_recent_x_button',
}
