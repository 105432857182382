import React, { ReactElement } from 'react';
import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import { withSignoutModal } from '@24i/nxg-sdk-smartott/src/components/SignOut';
import { SubMenuScreen } from '@24i/nxg-sdk-smartott/src/screens/SubMenuScreen';
import * as accountPrerenderFunctions from '@24i/nxg-sdk-smartott/src/pages/account';
import { AccountScreenModelProps } from './types';

// eslint-disable-next-line import/no-cycle
import { useViewModel } from './viewModel';

const header = (headerProps) => <TopBarPrimary {...headerProps} />;

const Main = (props: AccountScreenModelProps): ReactElement => {
    const { subMenuScreens, submenuTitle, setSignoutModalVisible, ...rest } = useViewModel(props);

    return (
        <SubMenuScreen
            header={header}
            subMenuTitle={submenuTitle}
            subMenuScreens={subMenuScreens}
            onSignOutPress={() => setSignoutModalVisible(true)}
            {...rest}
        />
    );
};

export default {
    Main: withSignoutModal(Main),
    page: {
        account: {
            ...accountPrerenderFunctions,
        },
    },
};
