import React, { createContext, useContext, useRef } from 'react';
import { AnalyticsClient, AnalyticsConfig } from '@24i/nxg-sdk-photon';
import { analytics } from '@24i/appstage-shared-analytics';

type ContextType = {
    clients: AnalyticsClient[];
    initAnalytics: (analyticsConfigs: AnalyticsConfig[]) => void;
};

const noop = () => {};

const contextDefaults: ContextType = {
    clients: [],
    initAnalytics: noop,
};

export const AnalyticsContext = createContext<ContextType>(contextDefaults);

export const AnalyticsProvider = ({ clients, children }) => {
    const isInitialized = useRef(false);

    const activateMatchingClient = (analyticsConfig: AnalyticsConfig) => {
        clients
            ?.filter((client) => client?.name === analyticsConfig.name)
            .forEach((client) => {
                // @ts-expect-error analytics.clients is private property
                if (!(client.name in analytics.clients)) {
                    analytics.addClient(client, client.triggers);
                    client.init(analyticsConfig);
                }
            });
    };

    const initAnalytics = (analyticsConfigs: AnalyticsConfig[]) => {
        if (isInitialized.current) {
            return;
        }
        isInitialized.current = true;
        analyticsConfigs
            ?.filter((analyticsConfig) => analyticsConfig.enabled)
            .map((analyticsConfig) => activateMatchingClient(analyticsConfig));
    };

    return (
        <AnalyticsContext.Provider
            value={{
                clients,
                initAnalytics,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => useContext(AnalyticsContext);
