import React, { ReactElement } from 'react';
import { View, Text, NavigationIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';

import getEmptyListPlaceholderStyles from '../styles';
import { EmptyListPlaceholderProps } from '../types';

const EmptyListPlaceholder = (props: EmptyListPlaceholderProps): ReactElement => {
    const {
        styles: getStyles = getEmptyListPlaceholderStyles,
        iconName,
        title,
        description = undefined,
    } = props;

    const { theme } = useTheme();
    const iconSize = isPlatformWeb ? 80 : 72;
    const styles = getStyles(theme);

    return (
        <View style={styles.container}>
            <View style={styles.iconContainer}>
                <NavigationIcon iconName={iconName} size={iconSize} iconStyles={styles.icon} />
            </View>
            <Text style={styles.primaryText}>{title}</Text>
            {description && <Text style={styles.secondaryText}>{description}</Text>}
        </View>
    );
};

export default EmptyListPlaceholder;
