import { getFont, getMargins, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { MoreScreenAuthSectionStyles } from '../types';

const AuthSection = (theme: Theme): MoreScreenAuthSectionStyles => ({
    authSectionContainer: {
        width: '100vw',
        alignItems: 'center',
        paddingBottom: 70,
    },
    menuItemsContainer: {
        ...getMargins(),
        backgroundColor: theme.color.background1,
        height: '100%',
        minHeight: '63vh',
        justifyContent: 'flex-start',
        // This has never worked becuase of typo so commenting out:
        // alignitems: 'flex-start',
        marginBottom: 48,
        marginTop: 25,
    },
    authPrimaryButtonContainer: {
        backgroundColor: theme.color.buttonPrimary,
        height: 52,
        width: 335,
        borderRadius: 26,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16,
    },
    authSecondaryButtonContainer: {
        height: 52,
        width: 335,
        borderRadius: 26,
        ...getBorderRadius(theme.elements, 'buttonSecondary-cornerRadius'),
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 24,
        backgroundColor: theme.color.buttonSecondary,
    },
    authDescriptionText: {
        marginTop: 24,
        marginBottom: 24,
        paddingRight: 53,
        paddingLeft: 53,
        textAlign: 'center',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h5'),
        height: '100%',
    },
    primaryAuthButtonText: {
        color: theme.color.textButtonPrimary,
        ...getFont(theme, 'primaryButton'),
    },
    secondaryAuthButtonText: {
        color: theme.color.textButtonSecondary,
        ...getFont(theme, 'secondaryButton'),
    },
});

export default AuthSection;
