/*
@PRODUCT_FEATURE: H 1.1
*/
/* eslint-disable react/display-name */
import React from 'react';

import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import View, { getDynaRowScreenStyles } from './View';
import {
    defaultRenderSingleRow,
    defaultRenderPackshotGrid,
    defaultRenderSlider,
    defaultRenderBannerCarousel,
} from './components/DynaRowSection';
import { useViewModel } from './viewModel';
import TopBarDynaRow from './components/TopBarDynaRow';
import { DynaRowScreenProps } from './types';

export default {
    Main: (props: DynaRowScreenProps & ScreenProps) => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    // components
    TopBarDynaRow,
    // default render functions
    defaultRenderSingleRow,
    defaultRenderPackshotGrid,
    defaultRenderSlider,
    defaultRenderBannerCarousel,
    // default styles getter
    getDefaultStyles: getDynaRowScreenStyles,
};
