import React, { useEffect } from 'react';
import { useScrollDirection, UP } from '@24i/nxg-sdk-smartott/src/hooks/useScrollDirection';
import { useDebounceFn, useEventListener } from '@24i/nxg-core-utils/src';

type UseWebGridPaginationParams = {
    isLoading: boolean;
    fetchData: (offset: number) => Promise<boolean>;
    gridContainerRef: React.MutableRefObject<Element | undefined>;
    endOfPageReached: React.MutableRefObject<boolean | undefined>;
    assetsLength: number;
};

export const useWebGridPagination = ({
    isLoading,
    fetchData,
    gridContainerRef,
    assetsLength,
    endOfPageReached,
}: UseWebGridPaginationParams) => {
    const scrollDirection = useScrollDirection();
    const gridBottomReached = React.useRef<boolean>();

    const detectNoScrollPossible = async () => {
        if (fetchData) {
            const { innerHeight } = window;
            const gridHeight = gridContainerRef.current?.getBoundingClientRect().height;
            const gridPossiblyNotFillingPage = (gridHeight || 0) < innerHeight;
            if (!isLoading && gridPossiblyNotFillingPage) {
                const fetched = await fetchData(assetsLength);
                if (fetched) gridBottomReached.current = false;
            }
        }
    };

    const handleScrollToBottom = async () => {
        if (!isLoading && gridContainerRef.current && !endOfPageReached.current) {
            const containerBottom = gridContainerRef.current.getBoundingClientRect().bottom;
            const { innerHeight } = window;
            if (
                containerBottom <= innerHeight &&
                !gridBottomReached.current &&
                scrollDirection !== UP
            ) {
                gridBottomReached.current = true;
                const fetched = await fetchData(assetsLength);
                if (fetched) gridBottomReached.current = false;
            }
        }
    };

    const { run: onScrollDebounced } = useDebounceFn(handleScrollToBottom, {
        wait: 200,
        leading: true,
    });

    useEventListener('scroll', onScrollDebounced);

    useEffect(() => {
        if (assetsLength > 0 && !endOfPageReached.current) {
            detectNoScrollPossible();
        }
    }, [assetsLength, isLoading]);
};
