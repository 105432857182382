import React from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { Button } from '@24i/nxg-sdk-gluons';
import { Platform } from '@24i/nxg-sdk-quantum';
import { ButtonProps } from './types';

const renderButton = ({ text, onPress, key }: ButtonProps) => (
    <View style={{ flex: 1, margin: 5 }} key={key}>
        <Button title={text} onPress={onPress} color={Platform.isTV ? '#20486c' : '#2980B9'} />
    </View>
);

export default renderButton;
