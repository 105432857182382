import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { SIGNOUT_TEST_IDS } from '../../../screens/SignoutScreen/View/types';
import getSignOutStyles from '../styles';
import { SignOutComponentStylesWeb, SignOutProps } from '../types';

const SignOut = ({ styles: getStyles = getSignOutStyles, onConfirm, onClose }: SignOutProps) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme) as SignOutComponentStylesWeb;

    return (
        <View style={styles.modalContainer} testID={SIGNOUT_TEST_IDS.CONTAINER}>
            <View style={styles.contentContainer}>
                <Text
                    style={[styles.subheaderText, { marginBottom: 24 }]}
                    testID={SIGNOUT_TEST_IDS.TITLE}
                >
                    {t('auth.signOut.title')}
                </Text>
                <Text
                    style={[styles.modalMessageText, { marginBottom: 24 }]}
                    testID={SIGNOUT_TEST_IDS.MESSAGE}
                >
                    {t('auth.signOut.signOutConfirm.title')}
                </Text>
                <View style={styles.buttonsContainer}>
                    <SubactionButton
                        title={t('common.cancel')}
                        onPress={onClose}
                        testID={SIGNOUT_TEST_IDS.CANCEL_BUTTON}
                    />

                    <ActionButton
                        title={t('auth.signOut.title')}
                        onPress={onConfirm}
                        testID={SIGNOUT_TEST_IDS.CONFIRM_BUTTON}
                    />
                </View>
            </View>
        </View>
    );
};

export { getSignOutStyles };
export default overridable(SignOut, 'SignOut');
