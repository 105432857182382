import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { CreateSingleImageWelcomeScreenStyles } from './types';

const getCreateSingleImageWelomeScreenStyles = (
    theme: Theme
): CreateSingleImageWelcomeScreenStyles => {
    return {
        backgroundWrapper: {
            minHeight: '100%',
            zIndex: -1,
        },
        mainText: {
            color: theme.color.textPrimary,
            ...getFont(theme, 'body2'),
        },
        continueButtonContainer: {
            alignSelf: 'center',
            position: 'absolute',
            bottom: 82,
            width: 335,
            height: 52,
            backgroundColor: theme.color.buttonPrimary,
        },
        continueButtonText: {
            color: theme.color.textButtonPrimary,
        },
        backgroundImage: {
            opacity: 0.6,
        },
    };
};

export default getCreateSingleImageWelomeScreenStyles;
