import { getFont, getMargins, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { isPlatformAndroid } from 'renative';

const PageSettings = (theme) => ({
    container: {
        flex: 1,
        backgroundColor: theme.color.background1,
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        ...getMargins(),
    },
    scrollContainer: {
        flex: 1,
        width: 576,
        '@media (max-width: 596px)': {
            width: '100%',
        },
    },
    scrollContainerLandscape: {
        flex: 1,
    },
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: isPlatformAndroid ? 16 : 8,
        paddingTop: isPlatformAndroid ? 16 : 8,
        paddingBottom: 8,
        marginBottom: 64,
    },
    headerContainerLandscape: {
        flexDirection: 'column',
        marginTop: isPlatformAndroid ? 32 : 16,
        marginBottom: 64,
        paddingTop: isPlatformAndroid ? 16 : 8,
        paddingBottom: 8,
    },
    headerText: {
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h2'),
    },
    backButtonText: {
        color: theme.color.textPrimary,
        marginTop: 4,
        marginLeft: 4,
        marginBottom: 4,
        ...getFont(theme, 'h5'),
    },
    subheaderText: {
        color: theme.color.textPrimary,
        marginTop: 15,
        marginLeft: 0,
        marginBottom: 8,
        ...getFont(theme, 'h4'),
        '@media (max-width: 596px)': {
            marginLeft: 8,
            marginTop: 8,
        },
    },
    headerTextLandscape: {
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h1'),
    },
    headerIcon: {
        fontSize: 27,
        marginRight: 8,
        marginLeft: 0,
    },
    optionIcon: {
        '@media (max-width: 596px)': {
            marginRight: 12,
        },
    },
    settingsButtonContainerLandscape: {
        marginLeft: 16,
        marginRight: 16,
        paddingLeft: 16,
        paddingRight: 16,
    },
    verticalBar: {
        position: 'absolute',
        backgroundColor: theme.color.contrast4,
        opacity: 0.1,
        width: 2,
        right: 0,
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '@media (max-width: 596px)': {
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
    },
    optionButton: {
        width: 280,
        height: 135,
        marginBottom: 16,
        backgroundColor: theme.color.contrast4,
        ':hover': {
            backgroundColor: theme.color.contrast3,
        },
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        '@media (max-width: 596px)': {
            width: '100%',
            height: 64,
            alignItems: 'flex-start',
            paddingLeft: 32,
            marginBottom: 12,
        },
    },
    buttonContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-width: 596px)': {
            flexDirection: 'row',
        },
    },
    modalContainer: {
        height: 244,
        width: 528,
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        backgroundColor: theme.color.background1,
        position: 'absolute',
        left: '50%',
        top: '30%',
        marginLeft: -264,
        '@media (max-width: 600px)': {
            height: 244,
            width: '80%',
            marginLeft: '-40%',
        },
    },
    modalTextInputView: {
        height: 48,
        borderRadius: 36,
        ...getBorderRadius(theme.elements, 'textField-cornerRadius'),
        paddingLeft: 24,
        paddingRight: 48,
        backgroundColor: theme.color.contrast4,
    },
    modalTextInput: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h5'),
    },
    modalButton: {
        width: '25%',
        '@media (max-width: 425px)': {
            width: '35%',
        },
        backgroundColor: theme.color.buttonPrimary,
        borderRadius: 36,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
        padding: 8,
        alignItems: 'center',
        justifyContent: 'center',
        height: 48,
    },
    modalButtonText: {
        ...getFont(theme, 'h5'),
    },
    explanationText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'body2'),
    },
});

export default PageSettings;
