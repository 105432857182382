import { Dimensions, Platform, PixelRatio } from 'react-native';

const { height } = Dimensions.get('window');

const Ratio = (pixels) => {
    const resolution = height * PixelRatio.get();

    return Math.round(
        pixels / (resolution < 2160 && Platform.isTV && Platform.OS === 'android' ? 2 : 1)
    );
};

export default Ratio;
