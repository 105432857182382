import View from './View';

export type { FooterProps } from './View';
export {
    default as View,
    getFooterStyles,
    defaultRenderIconLink,
    defaultRenderTextLink,
} from './View';
export { default as TextLink } from './components/TextLink';
export { default as IconLink } from './components/IconLink';

export default View;
