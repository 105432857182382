import _ from 'lodash';

import { BaseApiParams } from '@24i/nxg-core-utils/src/api';
import { Theme, ThemeDataClient } from '@24i/nxg-sdk-photon';
import { Storage } from '@24i/nxg-sdk-quantum';

import { BackstageApiBase } from '../../base';
import { replaceEmptyArraysWithEmptyObjects } from '../../utils';
import { CustomIconsResponseGuard } from './customIconsResponseGuard';
import { mapThemeResponse } from './mappers';
import { ThemeResponseItemGuard, ThemeResponseGuard } from './themeResponseGuard';
import { CustomIcons, ThemeResponseItem } from './types';
import backstageDefaultTheme, { DefaultTheme } from './backstageDefaultTheme';

const SERVICE_THEME_ID = 'SERVICE_THEME_ID';

export class BackstageThemeDataClient extends BackstageApiBase implements ThemeDataClient {
    defaultTheme: DefaultTheme;

    constructor(params: BaseApiParams) {
        super(params);
        this.defaultTheme = _.merge(backstageDefaultTheme, params.defaultTheme);
    }

    fetchTheme = async (): Promise<Theme> => {
        const themeId = await Storage.getItem(SERVICE_THEME_ID);
        let themeResponse = this.defaultTheme as unknown as ThemeResponseItem;
        if (themeId) {
            try {
                themeResponse = await this.request({
                    path: `/themes/${themeId}`,
                    method: 'GET',
                    guard: ThemeResponseItemGuard,
                });
            } catch (error) {
                // Do nothing, use defaultTheme
                console.error(`fetching of theme ${themeId} failed, using defaultTheme`, error);
            }
        }

        let customIcons: CustomIcons = { icons: {} };
        try {
            customIcons = await this.request({
                path: '/icons/custom',
                method: 'GET',
                guard: CustomIconsResponseGuard,
            }).then(replaceEmptyArraysWithEmptyObjects<CustomIcons>(['icons']));
        } catch (error) {
            // customIcons failed, use default (empty)
            console.error('fetching of customIcons failed', error);
        }

        return mapThemeResponse(themeResponse, this.defaultTheme, customIcons);
    };

    fetchAllThemes = async () => {
        const themeResponse = await this.request({
            path: '/themes',
            method: 'GET',
            guard: ThemeResponseGuard,
        });

        const customIcons = await this.request({
            path: '/icons/custom',
            method: 'GET',
            guard: CustomIconsResponseGuard,
        }).then(replaceEmptyArraysWithEmptyObjects<CustomIcons>(['icons']));

        const allThemes = themeResponse.items.map((theme) =>
            mapThemeResponse(theme, this.defaultTheme, customIcons)
        );

        return allThemes;
    };

    selectTheme = (selectedThemeId: string) => Storage.setItem(SERVICE_THEME_ID, selectedThemeId);

    getSelectedTheme = () => Storage.getItem(SERVICE_THEME_ID);

    resetSelectedTheme = () => Storage.removeItem(SERVICE_THEME_ID);
}

export const createBackstageThemeDataClient = (params: BaseApiParams) =>
    new BackstageThemeDataClient(params);
