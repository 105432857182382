import { BaseApi, RequestOpts } from '@24i/nxg-core-utils/src/api';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import * as Sentry from '@sentry/node';
import { ApiGuardTypeErrorName, ApiTypeGuardError } from '@24i/nxg-core-utils/src/guards';

export class BackstageApiBase extends BaseApi {
    protected async request<EncodeTo>(context: RequestOpts<EncodeTo, unknown>): Promise<EncodeTo> {
        const refetchToken = async () => {
            // Refresh JWT token, needed for keeping user logged in, when token is expired.
            const { url: urlRefresh, init: initRefresh } = await super.createFetchParams({
                method: 'POST',
                path: '/user/refresh-token',
            });
            const refreshResponse = await fetch(urlRefresh, initRefresh);

            const tokenData = await refreshResponse.json();

            return { token: tokenData?.token, status: refreshResponse.status };
        };
        try {
            return await super.request<EncodeTo>(context, refetchToken);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.name === ApiGuardTypeErrorName) {
                const typedError: ApiTypeGuardError<EncodeTo> = error;
                const serviceGuardEnabled = getRuntimeConfig('backstageServiceGuards')?.enabled;
                if (serviceGuardEnabled) {
                    if (__DEV__) {
                        // eslint-disable-next-line no-console
                        console.error('API Model errors: ', typedError.message);
                    } else {
                        // TODO: hook this properly to analytics when analytics provider is implemented
                        Sentry.captureException(error);
                    }
                }
                return typedError.rejectedPayload;
            }
            throw error;
        }
    }
}
