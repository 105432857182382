import { Theme } from '@24i/nxg-sdk-photon';
import { CancelSubscriptionStyles } from './types';

const getStyles = (theme: Theme): CancelSubscriptionStyles => ({
    informationTextWrapper: {
        marginVertical: 16,
    },
    informationText: {
        color: theme.color.textPrimary,
        opacity: 0.6,
    },
    subscriptionItemWrapper: {
        borderRadius: 8,
        borderColor: theme.color.labelPrimary,
        borderWidth: 1,
        marginBottom: 16,
    },
    buttonWrapper: {
        marginTop: 32,
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
});

export default getStyles;
