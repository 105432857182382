import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { SearchNothingFoundStyles } from '../types';

const SearchNothingFound = (theme: Theme): SearchNothingFoundStyles => ({
    nothingFoundContainer: {
        alignItems: 'center',
        alignSelf: 'center',
        width: '90%',
        marginTop: '10%',
        maxWidth: 370,
    },
    secondaryText: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        marginTop: 5,
        opacity: 0.6,
        ...getFont(theme, 'h5'),
    },
    primaryText: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        marginBottom: 5,
        ...getFont(theme, 'h3'),
        marginLeft: 5,
        marginRight: 5,
    },
});

export default SearchNothingFound;
