import { getFont } from '@24i/nxg-sdk-higgs';

const getPickerStyles = (theme) => ({
    viewContainer: {
        flex: 1,
    },
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    item: {
        textAlign: 'center',
        color: theme?.color?.textPrimary,
        ...getFont(theme, 'h2'),
    },
    selectedItem: {
        textAlign: 'center',
        color: theme?.color?.textPrimary,
        ...getFont(theme, 'h2'),
    },
});

export default getPickerStyles;
