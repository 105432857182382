import React from 'react';

export class EnhancedComponent<
    TProps,
    // This is here only to force the developer to write valid defaultProps
    TDefaultProps extends Partial<TProps>,
    TState = Record<string, unknown>
> extends React.Component<TProps, TState> {
    // This is only a retyping function that takes into account the default prop typings
    getPropsWithDefaults(): TProps & TDefaultProps {
        // Careful, we are forcing the type here, be sure to provide the correct TDefaultProps typing
        return this.props as TProps & TDefaultProps;
    }
}
