import { RecordingScreenStyles } from '../types';

const getRecordingStyles = (): RecordingScreenStyles => ({
    holder: {
        flex: 1,
        marginBottom: 220,
    },
});

export default getRecordingStyles;
