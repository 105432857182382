import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductConfirmPurchaseProps } from '../../types';
import ConfirmPurchase from '../ConfirmPurchase';

const ProductConfirmPurchase: React.FC<ProductConfirmPurchaseProps> = (props) => {
    const { children } = props;
    const { t } = useTranslation();

    return (
        <ConfirmPurchase
            {...props}
            topText={t('vod.add.addConfirm.description')}
            middleText={t('tvod.add.addConfirm.singlePayment')}
            bottomText=""
        >
            {children}
        </ConfirmPurchase>
    );
};

export default ProductConfirmPurchase;
