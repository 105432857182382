import React, { useEffect } from 'react';
import { FlatList } from 'react-native';
import { Subscription } from '@24i/nxg-sdk-photon/src';
import { Text, View, SafeAreaView } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useTranslation } from 'react-i18next';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import getStyles from './styles';
import SubscriptionItem from '../../PurchasesItem/SubscriptionItem';
import SubscriptionsUnavailable from '../SubscriptionsUnavailable';

interface Props {
    subscriptions: Subscription[];
    onSubscriptionSelect: (itemId: string) => void;
    onBackPress: () => void;
    description: string;
    children?: React.ReactNode;
    isOnboarding?: boolean;
}

const SubscriptionSelection = ({
    subscriptions,
    onSubscriptionSelect,
    description,
    children,
    isOnboarding,
    onBackPress,
}: Props) => {
    const { theme } = useTheme();
    const styles = getStyles(theme, isOnboarding);
    const { t } = useTranslation();
    const { sessionId } = useSessionId();

    useEffect(() => {
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
            screen: t('analytics.documentTitle.purchase.step1'),
            sessionId,
        });
    }, []);

    if (!subscriptions?.length) {
        return <SubscriptionsUnavailable onBackPress={onBackPress} />;
    }

    return (
        <SafeAreaView style={styles.wrapper}>
            <View style={styles.messageWrapper}>
                <Text style={styles.message}>{description}</Text>
            </View>
            <FlatList
                data={subscriptions}
                contentContainerStyle={styles.contentContainerStyle}
                renderItem={({ item }) => (
                    <View style={styles.itemWrapper}>
                        <SubscriptionItem item={item} onPress={onSubscriptionSelect} />
                    </View>
                )}
                keyExtractor={(item) => item.id}
            />
            {children}
        </SafeAreaView>
    );
};

export default SubscriptionSelection;
