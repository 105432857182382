import React from 'react';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Theme, Device } from '@24i/nxg-sdk-photon';
import { View, Text, Interactable, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { useTranslation } from 'react-i18next';
import getRemoveModalStyles from './styles';
import { RemoveModalStyles } from './styles/types';

interface RemoveModalProps {
    deviceToRemove?: Device;
    formatDate: (date: string) => string;
    isVisible?: boolean;
    styles?: (theme: Theme, isLandscape: boolean) => RemoveModalStyles;
    onModalClose: () => void;
    onModalRemoveDevicePress: (deviceToRemove: Device) => void;
}

const RemoveModal = ({
    deviceToRemove,
    isVisible = false,
    formatDate,
    styles: getStyles = getRemoveModalStyles,
    onModalClose,
    onModalRemoveDevicePress,
}: RemoveModalProps) => {
    const { theme } = useTheme();
    const { isLandscape } = useDimensions();
    const styles = getStyles(theme, isLandscape);
    const { t } = useTranslation(['sott']);

    const buttons = (
        <>
            <ActionButton
                title={t('manageDevices.delete.deleteButton')}
                onPress={() => {
                    if (deviceToRemove) {
                        onModalRemoveDevicePress(deviceToRemove);
                        onModalClose();
                    }
                }}
                additionalContainerStyles={styles.removeButton}
                testID="remove_device_modal_remove_button"
            />
            <Interactable style={styles.cancelButton} onPress={onModalClose}>
                <Text style={styles.cancelButtonText}>{t('common.cancel')}</Text>
            </Interactable>
        </>
    );

    const deviceIcons = {
        iphone: 'onPrimary-device_smartphone',
        android: 'onPrimary-device_smartphone',
        ipad: 'onPrimary-device_tablet',
        tabletAndroid: 'onPrimary-device_tablet',
        laptop: 'onPrimary-device_laptop',
    };

    const iconNameToUse =
        deviceToRemove && deviceToRemove.platform in deviceIcons
            ? deviceIcons[deviceToRemove.platform]
            : deviceIcons.laptop;

    return (
        <Modal visible={isVisible} onRequestClose={onModalClose} transparent>
            {deviceToRemove && (
                <View style={styles.modalContainer}>
                    <Text style={styles.modalHeading}>
                        {t('manageDevices.delete.deleteConfirm.title')}
                    </Text>
                    <ThemeIcon iconName={iconNameToUse} size={24} />
                    <Text style={styles.name}>{deviceToRemove.name}</Text>
                    <Text style={styles.modalDescription}>
                        {formatDate(deviceToRemove.registrationDate)}
                    </Text>
                    {buttons}
                </View>
            )}
        </Modal>
    );
};

export default RemoveModal;
