import React, { FC, ReactNode } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { getStyles } from './styles';

type Props = {
    leftContent: ReactNode;
    centerContent: ReactNode;
    rightContent: ReactNode;
};

export const MiniPlayerContainer: FC<Props> = ({ leftContent, centerContent, rightContent }) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.container}>
            <View style={styles.divider} />

            <View style={styles.leftContent}>{leftContent}</View>

            <View style={styles.centerContent}>{centerContent}</View>

            <View style={styles.rightContent}>{rightContent}</View>
        </View>
    );
};
