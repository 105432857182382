import React from 'react';
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from 'react-query';
import { log } from '@24i/nxg-core-utils/src/logger';
import { ReactQueryDevtools } from 'react-query/devtools';

const DEFAULT_STALE_TIME = 300000; // 5 minutes

const defaultClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error) => {
            log(error);
        },
    }),
    mutationCache: new MutationCache({
        onError: (error) => {
            log(error);
        },
    }),
    defaultOptions: {
        queries: {
            staleTime: DEFAULT_STALE_TIME,
            refetchOnWindowFocus: false,
        },
    },
});

const QueryContext = React.createContext(defaultClient);

export const QueryProvider = ({ client = defaultClient, children }) => {
    return (
        <QueryContext.Provider value={client}>
            <QueryClientProvider client={client}>
                {children}
                <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
        </QueryContext.Provider>
    );
};
