/*
@PRODUCT_FEATURE: G 1.7
*/
import React, { useState } from 'react';
import { ScrollView } from '@24i/nxg-sdk-quarks';
import { TopMenuProps } from './types';

export const SCROLL_VIEW_TEST_ID = 'scroll-view-test-id';
export const SCROLL_INDICATOR_TEST_ID = 'scroll-indicator-test-id';

const SCROLL_EVENT_THROTTLE = 50;

const TopMenu = ({
    renderMenu,
    renderScreen,
    renderScrollIndicator,
    menuHeight,
    isSticky = false,
}: TopMenuProps) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const onScroll = ({ nativeEvent }) => {
        const verticalScrollOffset = nativeEvent.contentOffset.y;
        if (menuHeight && verticalScrollOffset > menuHeight && !isScrolled) {
            setIsScrolled(true);
        } else if (menuHeight && verticalScrollOffset < menuHeight && isScrolled) {
            setIsScrolled(false);
        }
    };
    if (isSticky && renderMenu && renderScreen) {
        return (
            <>
                {renderMenu()}
                <ScrollView>{renderScreen()}</ScrollView>
            </>
        );
    }
    return (
        <>
            {isScrolled && renderScrollIndicator && renderScrollIndicator()}
            <ScrollView
                testID={SCROLL_VIEW_TEST_ID}
                onScroll={onScroll}
                scrollEventThrottle={SCROLL_EVENT_THROTTLE}
            >
                {renderMenu && renderMenu()}
                {renderScreen && renderScreen()}
            </ScrollView>
        </>
    );
};

export default TopMenu;
