import { getFont, Ratio } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { ColorValue, ViewStyle } from 'react-native';
import { DimensionsInfo } from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions/types';
import { Theme } from '@24i/nxg-sdk-photon';
import { DESKTOP_MAX_WIDTH, MOBILE_MAX_WIDTH } from '../constants';
import { UpsertProfileScreenStyle } from './types';
import getSectionStyles from '../components/Section/styles';
import { shouldDisplayDesktopVersion } from '../../../utils/styles/helpers';

export type UpsertProfileScreenStyleGetter = typeof getUpsertProfileScreenStyles;

const getUpsertProfileScreenStyles = (
    theme: Theme,
    dimensions: DimensionsInfo,
    isButtonDisabled?: boolean,
    isOnboarding?: boolean
): UpsertProfileScreenStyle => {
    let wrapper: ViewStyle = {
        marginTop: isOnboarding ? 0 : Ratio(49),
        alignItems: 'center',
        width: dimensions.width,
        alignContent: 'center',
        flex: 1,
    };

    let actionButtonContainer: ViewStyle = {
        marginTop: 20,
        marginBottom: 20,
    };

    let deleteButton = {
        marginBottom: 48,
        ...getFont(theme, 'tertiaryButton'),
    };

    let screenContent: ViewStyle = {
        // Some space for the scroll view to go past the last element on devices
        paddingBottom: isPlatformWeb ? 0 : 70,
        alignSelf: 'center',
        width: dimensions.width,
        paddingHorizontal: 24,
    };

    let onboardingHeaderWrapper: ViewStyle = {
        paddingBottom: isPlatformWeb ? 28 : 0,
        width: '100%',
    };

    if (shouldDisplayDesktopVersion(dimensions)) {
        wrapper = {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ...wrapper,
            marginTop: isOnboarding ? 0 : Ratio(80),
        };
        actionButtonContainer = {
            ...actionButtonContainer,
            marginTop: 44,
        };
        deleteButton = {
            ...deleteButton,
            marginBottom: 64,
        };

        screenContent = {
            ...screenContent,
            maxWidth: DESKTOP_MAX_WIDTH,
            marginTop: 70,
        };
        onboardingHeaderWrapper = {
            paddingBottom: 60,
            width: '100%',
        };
    }

    return {
        tvButtonContainer: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
            marginTop: Ratio(74),
            height: Ratio(220),
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        wrapper,
        actionButtonStyle: {
            width: '100%',
            opacity: isButtonDisabled ? 0.4 : 1,
        },
        tertiaryButtonStyle: {
            width: Ratio(320),
            height: Ratio(60),
        },
        actionButtonContainer,
        scrollView: {
            display: 'flex',
        },
        screenContent,
        deleteButton,
        deleteButtonText: {
            textAlign: 'center',
            fontWeight: 'bold',
        },
        heading: {
            textAlign: 'center',
            color: <ColorValue>theme.color.textPrimary,
            ...getFont(theme, 'h1'),
            marginBottom: Ratio(80),
        },
        modalDescription: {
            textAlign: 'center',
            marginTop: 16,
            opacity: 0.6,
            color: <ColorValue>theme.color.textPrimary,
            ...getFont(theme, 'body1'),
        },
        modalContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: Ratio(24),
            marginRight: Ratio(24),
            marginTop: '50%',
        },
        cancelButton: {
            marginTop: 48,
        },
        cancelButtonText: {
            color: <ColorValue>theme.color.textPrimary,
        },
        deleteProfileButtonModal: {
            marginTop: 32,
            width: Ratio(200),
        },
        profileSection: (params) => {
            const originalStyles = getSectionStyles(params);
            return { ...originalStyles, divider: { ...originalStyles.divider, marginTop: 8 } };
        },
        textInput: {
            height: 48,
            ...getFont(theme, 'body1', true),
            color: theme.color.textPrimary,
        },
        sectionsContainer: {
            alignSelf: 'center',
            margin: 'auto',
            maxWidth: MOBILE_MAX_WIDTH,
            overflow: 'visible',
        },
        tvContainer: {
            flexDirection: 'column',
            padding: Ratio(32),
        },
        tvContainerFocused: {
            borderColor: <ColorValue>theme.color.textPrimary,
            backgroundColor: <ColorValue>theme.color.background1,
            borderRadius: 8,
            borderWidth: 2,
            flexDirection: 'column',
            padding: Ratio(32),
        },
        separator: {
            width: Ratio(656),
            alignSelf: 'center',
            height: 2,
            backgroundColor: theme.color.lighter4,
            borderRadius: 1,
        },
        screenContainer: {
            backgroundColor: theme.color.background1,
            flex: 1,
            minHeight: '100%',
        },
        headerContainerLandscape: {
            flexDirection: 'column',
            marginTop: isOnboarding ? 98 : 16,
            paddingTop: isOnboarding ? 0 : 8,
            paddingBottom: 8,
        },
        backButtonText: {
            color: theme.color.textPrimary,
            opacity: 0.6,
            marginLeft: 4,
            ...getFont(theme, 'h5'),
        },
        headerText: {
            marginTop: isOnboarding ? 0 : 8,
            fontWeight: 'bold',
            color: theme.color.textPrimary,
            alignSelf: isOnboarding ? 'center' : 'flex-start',
            ...getFont(theme, 'h2'),
        },
        onboardingHeaderWrapper,
        loader: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
        pinToggleSection: (params) => {
            const originalStyles = getSectionStyles(params);
            return {
                ...originalStyles,
                container: { ...originalStyles.container, marginTop: isPlatformWeb ? 40 : 32 },
            };
        },
    };
};

export default getUpsertProfileScreenStyles;
