/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';

import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { NavScreenConfig } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import View from './View';
import { useViewModel } from './viewModel';
import * as settingsPrerenderFunctions from '../../pages/settings';
import * as settingsTypesPrerenderFunctions from '../../pages/settings/[type]';
import getSettingsScreenStyles from './styles';
import { SubMenuScreen } from '../SubMenuScreen';
import SettingsLanguage from './components/SettingsLanguage';
import SettingsTechnicalInfo from './components/SettingsTechnicalInfo';
import { SettingsScreenScreenProps, subScreenBuilder } from './types';

export { default as SettingsTechnicalInfo } from './components/SettingsTechnicalInfo';
export { default as SettingsDeviceManagement } from './components/SettingsDeviceManagement';
export { default as SettingsLanguage } from './components/SettingsLanguage';

export { default as SettingsOptionPage } from './components/SettingsOptionPage';
export { default as SettingsScreenContent } from './components/SettingsScreenContent';
export { default as SettingsTheme } from './components/SettingsTheme';
export { default as SettingsWrapper } from './components/SettingsWrapper';

const header = (headerProps) => <TopBarPrimary {...headerProps} />;

export const languageSetingsOptions = ({ t, theme }): NavScreenConfig => ({
    menuTitle: t('settings.language.title'),
    name: 'langauge',
    title: 'language',
    icon: theme.icons.settings.language.iconName,
    //  TODO: REMOVE THIS AFTER SETTINGS LANGUAGE COMPONENT IS PROPERLY TYPED.
    nativeRenderOptions: { renderComponent: (props) => <SettingsLanguage {...props} /> },
    webMenuLink: { href: '/settings/language', as: '/settings/language' },
});

export const technicalInfoSettingsOptions = ({ t, theme }) => ({
    menuTitle: t('settings.technicalInfo.title'),
    name: 'technicalInfo',
    title: 'technicalInfo',
    icon: theme.icons.settings.info.iconName,
    nativeRenderOptions: { renderComponent: (props) => <SettingsTechnicalInfo {...props} /> },
    webMenuLink: {
        href: '/settings/technicalInfo',
        as: '/settings/technicalInfo',
    },
});

export const defaultSettingsSubscreens: subScreenBuilder[] = [
    languageSetingsOptions,
    technicalInfoSettingsOptions,
];

export default {
    Main: (props: SettingsScreenScreenProps): ReactElement => {
        const { subMenuScreens = defaultSettingsSubscreens, ...rest } = props;
        const { t } = useTranslation();
        const { theme } = useTheme();
        const { subMenuTitle } = useViewModel(props);

        return (
            <SubMenuScreen
                header={header}
                subMenuTitle={subMenuTitle}
                subMenuScreens={subMenuScreens.map((builder) => builder({ t, theme }))}
                {...rest}
            />
        );
    },
    View,
    useViewModel,
    page: {
        settings: {
            ...settingsPrerenderFunctions,
            '[type]': settingsTypesPrerenderFunctions,
        },
    },
    // default styles getter
    getDefaultStyles: getSettingsScreenStyles,
};
