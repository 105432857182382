/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Animated } from 'react-native';
import { isFactorMobile } from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { View, FlatList } from '@24i/nxg-sdk-quarks';
import { useDimensions, DeviceInfo } from '@24i/nxg-sdk-quantum';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { useDebounceFn } from '@24i/nxg-core-utils/src';
import getCreateCarouselStyles from './styles';
import getCreateCarouselItemStyles from './components/CarouselItem/styles';
import getCreatePaginatorStyles from './components/Paginator/styles';
import CarouselItem from './components/CarouselItem';
import Paginator from './components/Paginator';
import { CarouselProps } from './types';
import {
    DEFAULT_ONE_THOUSAND,
    DEFAULT_ONE_SLIDE,
    DEFAULT_HALF_OF_THE_SCREEN,
    ONE_HUNDRED,
    HALF_SECOND,
} from './constants';

const WelcomeScreenCarousel = ({
    styles: getStyles = getCreateCarouselStyles,
    onPress,
    welcomeScreens,
    tabletLandscapeScreen,
    slideshowType = {
        speed: '5',
        type: 'manual',
    },
}: CarouselProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation(['sott']);
    const { width } = useDimensions();
    const [actualScroll, setActualScroll] = useState(0);
    const [isSlideShowFinished, setSlideShowFinished] = useState(false);
    const scrollX = React.useRef(new Animated.Value(0)).current;
    const carouselRef = React.useRef<typeof FlatList>(null);
    const screensCount = welcomeScreens?.length || 0;
    const end = (screensCount - DEFAULT_ONE_SLIDE) * width - width / DEFAULT_HALF_OF_THE_SCREEN;
    const hasReachedEnd = actualScroll >= end;
    const swipe = +slideshowType?.speed * DEFAULT_ONE_THOUSAND;
    const { isLandscape } = useDimensions();
    const data = DeviceInfo.isTablet() && isLandscape ? tabletLandscapeScreen : welcomeScreens;
    const currentScreen = React.useRef(0);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (
            !isSlideShowFinished &&
            slideshowType.type === 'automatic' &&
            currentScreen.current < screensCount - 1
        ) {
            const index = currentScreen.current + 1;
            const interval = setInterval(() => {
                carouselRef.current?.scrollToIndex({ index });
            }, swipe);
            return () => clearInterval(interval);
        }
    }, [slideshowType.type, currentScreen.current]);

    useEffect(() => {
        // Use of setTimeout to prevent scrolling to offset before the new Flatlist offset has been updated correctly.
        // Scrolls to screen that should be shown when device width changes (Rotates).
        setTimeout(() => {
            carouselRef.current.scrollToOffset({
                offset: currentScreen.current * width,
            });
        }, ONE_HUNDRED);
    }, [width]);

    const setCurrentScreen = (positionX) => {
        currentScreen.current = Math.round(positionX / width);
    };

    const { run: debouncedSetCurrenScreen } = useDebounceFn(setCurrentScreen, {
        wait: HALF_SECOND,
    });

    const handleScroll = (e) => {
        const positionX = e.nativeEvent.contentOffset.x;
        debouncedSetCurrenScreen(positionX);
        setActualScroll(positionX);
        if (hasReachedEnd) {
            setSlideShowFinished(true);
        }

        // TODO: FOCUS-ITEM-TV
        // if (positionX === 0 && isPlatformAndroidtv) {
        // }
    };

    return (
        <View style={styles.carouselWrapper}>
            <FlatList
                accesible={false}
                horizontal
                showsHorizontalScrollIndicator={false}
                pagingEnabled
                bounces={false}
                data={data}
                renderItem={({ item }) => (
                    <CarouselItem styles={getCreateCarouselItemStyles} item={item} />
                )}
                keyExtractor={(item) => item.id}
                onScroll={Animated.event(
                    [
                        {
                            nativeEvent: {
                                contentOffset: { x: scrollX },
                            },
                        },
                    ],
                    {
                        useNativeDriver: false,
                        listener: (e) => handleScroll(e),
                    }
                )}
                scrollEventThrottle={16}
                ref={carouselRef}
            />
            {isFactorMobile && hasReachedEnd && (
                <SubactionButton
                    onPress={onPress}
                    title={t('common.continue')}
                    additionalContainerStyles={styles.continueButtonContainer}
                    additionalTextStyles={styles.continueButtonText}
                />
            )}

            <Paginator styles={getCreatePaginatorStyles} data={welcomeScreens} scrollX={scrollX} />
        </View>
    );
};

export default overridable(WelcomeScreenCarousel, 'WelcomeScreenCarousel');
