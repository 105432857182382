import { TextStyle, ViewStyle } from 'react-native';
import { Theme } from '@24i/nxg-sdk-photon';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { WithWebHeaderProps } from '../../navigation/components/withWebHeader/types';
import { StylesGetter } from '../../utils/styles/types';

export interface DeleteAccountStyles {
    mainContainer: ViewStyle;
    descriptionText: TextStyle;
    deleteButton: ViewStyle;
    buttonText: TextStyle;
}

export interface DeleteAccountScreenProps extends ScreenProps {
    styles?: StylesGetter<Theme, DeleteAccountStyles>;
}

export interface DeleteAccountViewProps extends DeleteAccountScreenProps, WithWebHeaderProps {
    isModalVisible: boolean;
    onPressDeleteAccount: () => void;
    closeModal: () => void;
}

export enum DELETE_ACCOUNT_TEST_ID {
    DELETE_BUTTON = 'testId_delete_button',
}
