import { Ratio, getFont } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { Theme } from '@24i/nxg-sdk-photon';
import { DimensionsInfo } from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions/types';
import { GRID_MOBILE_BREAKPOINT } from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/constants';
import { ManageProfileHeaderStyles } from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen//types';

const getStyles = (theme: Theme, dimensions: DimensionsInfo): ManageProfileHeaderStyles => {
    const shouldRenderInVh = isPlatformWeb && dimensions.width >= GRID_MOBILE_BREAKPOINT;

    return {
        headerContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: shouldRenderInVh ? '14vh' : 56,
        },
        headerText: {
            color: theme.color.textPrimary,
            fontSize: Ratio(24),
            fontWeight: 'bold',
            lineHeight: 40,
            ...getFont(theme, isPlatformWeb ? 'h1' : 'h2'),
            paddingTop: 5,
        },
        goBackButtonContainer: {
            position: 'absolute',
            height: '100%',
            left: !DeviceInfo.isTablet() ? Ratio(10) : 30,
            backgroundColor: 'transparent',
        },
        editButtonContainer: {
            justifyContent: 'center',
            width: Ratio(40),
            position: 'absolute',
            right: !DeviceInfo.isTablet() ? Ratio(10) : 30,
            height: '100%',
            backgroundColor: 'transparent',
        },
    };
};

export default getStyles;
