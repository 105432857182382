import React from 'react';
import { useViewModel } from './viewModel';
import View from './View';
import { DetailsProgressBarProps } from './types';

const Main = (props: DetailsProgressBarProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
