import { ViewStyle } from 'react-native';
import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DimensionsInfo } from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions/types';
import { Breakpoint } from '../../../utils/styles/constants';
import { ManageDevicesScreenStyles } from './types';

const getCreateManageDevicesScreenStyles = (
    theme: Theme,
    dimensions: DimensionsInfo,
    _isLandscape: boolean,
    onboarding: boolean
): ManageDevicesScreenStyles => {
    return {
        screenContainer: {
            flex: 1,
            backgroundColor: theme.color.background1,
            paddingTop: 24,
            paddingHorizontal: onboarding ? '35%' : 0,
            alignSelf: 'center',
            alignItems: 'center',
            maxWidth: 576,
            [`@media (max-width: ${Breakpoint.MD}px)`]: {
                paddingHorizontal: 20,
                paddingTop: onboarding ? 28 : 24,
            },
        },
        indicator: {
            flex: 1,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
        description: {
            color: theme.color.textPrimary,
            marginBottom: 32,
            opacity: 0.6,
            ...getFont(theme, 'body1'),
            textAlign: onboarding ? 'center' : 'left',
        },
        title: {
            ...getFont(theme, 'h2'),
            color: theme.color.textPrimary,
            textAlign: 'center',
            marginBottom: 40,
            marginTop: 100,
        } as ViewStyle,
        onboardingWrapper: {
            width: '100%',
        },
    };
};

export default getCreateManageDevicesScreenStyles;
