import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { ViewStyleWeb } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import { ViewStyle } from 'react-native';
import { TopBarPrimaryStylesTypes } from '../types';

const getTopBarPrimaryStyles = (
    theme: Theme,
    hasActionButtons?: boolean
): TopBarPrimaryStylesTypes => ({
    container: {
        flex: 1,
        '@media (min-width: 801px)': { alignItems: 'center' },
    },
    textContainer: {
        '@media (min-width: 1600px)': { marginBottom: 64 },
        '@media (min-width: 801px)': { marginTop: '6%', marginBottom: '4%', width: 576 },
        '@media (max-width: 800px)': {
            marginTop: 41,
            marginHorizontal: 20,
            marginBottom: 32,
        },
    } as ViewStyleWeb,
    text: {
        ...getFont(theme, hasActionButtons ? 'h2' : 'h1'),
        color: theme.color.textPrimary,
    },
    closeButtonContainer: {
        position: 'absolute',
        backgroundColor: 'transparent',
        right: 20,
        '@media (max-width: 800px)': { top: 41 },
        '@media (min-width: 801px)': { top: '6%' },
    } as ViewStyle,
    backButtonContainer: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 20,
        '@media (max-width: 800px)': { top: 41 },
        '@media (min-width: 801px)': { top: '6%' },
    } as ViewStyleWeb,
});

export default getTopBarPrimaryStyles;
