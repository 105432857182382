import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { RatingModalStyles } from './types';

const getRatingModalStyles = (theme: Theme): RatingModalStyles => ({
    modalContainer: {
        flex: 1,
        alignSelf: 'center',
        alignItems: 'center',
    },
    content: {
        width: 384,
        padding: 24,
        backgroundColor: theme.color.primary1,
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    title: {
        color: theme.color.textPrimary,
        marginBottom: 16,
        textAlign: 'center',
        alignSelf: 'center',
        marginLeft: 32,
        marginRight: 32,
        ...getFont(theme, 'h2'),
    },
    description: {
        color: theme.color.contrast1,
        textAlign: 'center',
        marginHorizontal: 32,
        marginBottom: 32,
        alignSelf: 'center',
        fontSize: 16,
        width: 320,
        ...getFont(theme, 'body2'),
    },
});

export default getRatingModalStyles;
