import { Asset, Profile } from '@24i/nxg-sdk-photon';

type Params = {
    selectedProfile: Profile | undefined | null;
    asset: Asset | undefined;
};

export const getIsUnderAgeLimit = ({ selectedProfile, asset }: Params) => {
    const profileAge = selectedProfile?.age ?? Infinity;
    const assetAge =
        asset?.ageClassification?.rating != null ? Number(asset.ageClassification.rating) : 0;
    return profileAge < assetAge;
};

export const getIsBlockedForAllAges = ({ selectedProfile, asset }: Params) =>
    selectedProfile?.age === 0 ? !!asset && !Number(asset?.ageClassification?.rating === 0) : false;

export const getIsAgeValidationRequried = (params: Params) =>
    getIsUnderAgeLimit(params) || getIsBlockedForAllAges(params);
