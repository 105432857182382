import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '../../../../../utils/styles/constants';
import { SearchTextInputStyles } from '../View/types';

const SearchTextInput = (theme: Theme): SearchTextInputStyles => ({
    textInput: {
        backgroundColor: theme.color.lighter4,
        borderRadius: 32,
        ...getBorderRadius(theme.elements, 'searchField-cornerRadius'),
        width: '100%',
        maxWidth: 720,
        overflow: 'hidden',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        transition: '0.35s linear',
        height: 64,
        marginTop: 24,
        marginBottom: 48,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginBottom: 24,
        },
    },
    textInputFocused: {
        shadowColor: 'rgba(0,0,0, 0.06)',
        shadowOffset: { width: 0, height: 4 },
        shadowRadius: 10,
        backgroundColor: theme.color.lighter1,
    },
    inputStyle: {
        ...getFont(theme, 'h3'),
        borderRadius: 0,
        ...getBorderRadius(theme.elements, 'searchField-cornerRadius'),
        color: theme.color.textPrimary,
        paddingLeft: 5,
        height: 64,
        width: '100%',
        backgroundColor: 'transparent',
        textAlign: 'left',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            height: 46,
        },
    },
    searchIcon: {
        marginTop: 16,
        marginBottom: 16,
        marginRight: 18,
        marginLeft: 24,
        overflow: 'visible',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginTop: 11,
            marginBottom: 11,
            marginRight: 14,
            marginLeft: 16,
        },
    },
    clearIcon: {
        marginTop: 16,
        marginBottom: 16,
        marginRight: 24,
        marginLeft: 18,
        overflow: 'visible',
        opacity: 0.6,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginTop: 11,
            marginBottom: 11,
            marginRight: 16,
            marginLeft: 14,
        },
    },
});

export default SearchTextInput;
