import React from 'react';
import { Image as BgImage } from '@24i/nxg-sdk-quarks';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Theme } from '@24i/nxg-sdk-photon';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Image } from '@24i/nxg-sdk-photon/src/models/image';
import { useTranslation } from 'react-i18next';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { CreateSingleImageWelcomeScreenStyles } from './styles/types';
import getCreateSingleImageWelomeScreenStyles from './styles';

interface SingleImageWelomeScreenProps {
    onPress?: () => void;
    welcomeScreens?: Array<Image>;
    styles?: (theme: Theme) => CreateSingleImageWelcomeScreenStyles;
}

const SingleImageWelomeScreen = ({
    onPress,
    // THIS "welcomeScreens" PROP WILL BE USED WHEN ORIGINAL DESIGN ARE COMPLETED
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    welcomeScreens,
    styles: getStyles = getCreateSingleImageWelomeScreenStyles,
}: SingleImageWelomeScreenProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation(['sott']);

    return (
        <BgImage
            source={typeof theme.logo === 'number' ? theme.logo : { uri: theme.logo }}
            style={styles.backgroundWrapper}
            // imageStyle is not a prop
            // imageStyle={styles.backgroundImage}
        >
            <ActionButton
                onPress={onPress}
                title={t('common.continue')}
                additionalContainerStyles={styles.continueButtonContainer}
                additionalTextStyles={styles.continueButtonText}
            />
        </BgImage>
    );
};

export default overridable(SingleImageWelomeScreen, 'SingleImageWelomeScreen');
