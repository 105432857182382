import React, { useEffect, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { formatDurationText } from '@24i/nxg-core-utils';
import { useTranslation } from 'react-i18next';
import getRemainingTimeStyles from './Styles';

interface Props {
    plannedTime?: number;
    totalTimeAvailable: number;
    usedTime: number;
}

const PROGRESS_BAR_ANIM_DURATION = 300;

const RemainingSpace = ({ plannedTime = 0, totalTimeAvailable, usedTime }: Props) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getRemainingTimeStyles(theme);

    const usedPercentage = (usedTime / totalTimeAvailable) * 100;
    const plannedPercentage = (plannedTime / totalTimeAvailable) * 100;
    const prevUsedPercentage = useRef(0);
    const prevPlannedPercentage = useRef(0);

    const [usedPercentageAnim, setUsedPercentageAnim] = useState(0);
    const [plannedPercentageAnim, setPlannedPercentageAnim] = useState(0);

    const animate = (prevPercentage, percentage, setValue, duration) => {
        const animation = new Animated.Value(prevPercentage.current);
        animation.addListener(({ value }) => {
            setValue(value);
        });
        Animated.timing(animation, {
            toValue: percentage,
            duration,
            useNativeDriver: true,
            easing: Easing.linear,
        }).start();
        animation.removeListener; // eslint-disable-line
        prevPercentage.current = percentage; // eslint-disable-line
    };

    useEffect(() => {
        if (plannedTime >= 0) {
            animate(
                prevPlannedPercentage,
                plannedPercentage,
                setPlannedPercentageAnim,
                PROGRESS_BAR_ANIM_DURATION
            );
        }

        if (usedTime >= 0) {
            setTimeout(() => {
                animate(
                    prevUsedPercentage,
                    usedPercentage,
                    setUsedPercentageAnim,
                    PROGRESS_BAR_ANIM_DURATION / 1.333
                );
            }, PROGRESS_BAR_ANIM_DURATION / 2);
        }
    }, [plannedTime, usedTime]);

    return (
        <View style={styles.container}>
            <View style={styles.spaceBar}>
                <View style={[{ width: `${usedPercentageAnim}%` }, styles.usedTimeBar]} />
                <View style={[{ width: `${plannedPercentageAnim}%` }, styles.plannedTimeBar]} />
            </View>
            <View style={styles.textContainer}>
                <View style={styles.centerRow}>
                    <View style={styles.usedSpaceIndicator} />
                    <Text style={styles.textStyle}>{t('common.storage.used')}</Text>
                    <View style={styles.smallSquare} />
                    <Text style={styles.textStyle}>{formatDurationText(usedTime)}</Text>
                </View>
                <View style={styles.centerRow}>
                    <View style={styles.plannedSpaceIndicator} />
                    <Text style={styles.textStyle}>{t('common.storage.planned')}</Text>
                    <View style={styles.smallSquare} />
                    <Text style={styles.textStyle}>{formatDurationText(plannedTime)}</Text>
                </View>
                <View style={styles.centerRow}>
                    <View style={styles.freeSpaceIndicator} />
                    <Text style={styles.textStyle}>{t('common.storage.free')}</Text>
                    <View style={styles.smallSquare} />
                    <Text style={styles.textStyle}>
                        {formatDurationText(
                            Math.max(totalTimeAvailable - plannedTime - usedTime, 0)
                        )}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default RemainingSpace;
