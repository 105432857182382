import React, { createContext, useContext, useRef, useState } from 'react';

import { MonitoringDataClient, MonitorConfig } from '@24i/nxg-sdk-photon';
import { AnalyticsEventType } from '@24i/nxg-sdk-photon/src/analytics';
import { useMonitoringTransactions } from './useMonitoringTransactions';

type ContextType = {
    clients: MonitoringDataClient[];
    initMonitors: (monitoringConfigs: MonitorConfig[]) => void;
    triggerMonitoringEvent: (event: AnalyticsEventType) => void;
    logMonitoringException: (exception: any, context?: any) => void;
} & ReturnType<typeof useMonitoringTransactions>;

const noop = () => {};

const contextDefaults: ContextType = {
    clients: [],
    initMonitors: noop,
    triggerMonitoringEvent: noop,
    logMonitoringException: noop,
    beginMonitoringTransaction: () => ({ commit: noop, cancel: noop }),
    commitMonitoringTransaction: noop,
};

export const MonitoringDataContext = createContext<ContextType>(contextDefaults);

export const MonitoringDataProvider = ({ clients, children }) => {
    const [activeMonitors, setActiveMonitors] = useState<MonitoringDataClient[]>([]);
    const transactionFunctions = useMonitoringTransactions({ monitors: activeMonitors });
    const isInitialized = useRef(false);

    const activateMatchingClient = (monitorConfig: MonitorConfig) => {
        clients
            ?.filter((client) => client.name === monitorConfig.name)
            .forEach((client) => {
                client.init(monitorConfig);
                setActiveMonitors((activeClients) => [...activeClients, client]);
            });
    };

    const initMonitors = (monitoringConfigs: MonitorConfig[]) => {
        if (isInitialized.current) {
            return;
        }

        isInitialized.current = true;

        monitoringConfigs
            ?.filter((monitorConfig) => monitorConfig.enabled)
            .map((monitorConfig) => activateMatchingClient(monitorConfig));
    };

    const triggerMonitoringEvent = (event: AnalyticsEventType) => {
        activeMonitors.forEach((currentClient) => currentClient.logEvent(event));
    };

    const logMonitoringException = (exception: unknown, context?: any) => {
        activeMonitors.forEach((currentClient) => currentClient.logException(exception, context));
    };

    return (
        <MonitoringDataContext.Provider
            value={{
                clients,
                initMonitors,
                triggerMonitoringEvent,
                logMonitoringException,
                ...transactionFunctions,
            }}
        >
            {children}
        </MonitoringDataContext.Provider>
    );
};

export const useMonitoring = () => useContext(MonitoringDataContext);
