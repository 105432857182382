import { createTestIDGetter } from '@24i/nxg-core-utils';

export enum SINGLE_ROW_TEST_IDS {
    CONTAINER = 'container',
    TITLE = 'title',
    SHOW_ALL_BUTTON = 'show_all_button',
    PREV_SLIDE_BUTTON = 'prev_slide_button',
    NEXT_SLIDE_BUTTON = 'next_slide_button',
    SINGLE_ROW = 'single_row',
}

export const getSingleRowTestID = createTestIDGetter(SINGLE_ROW_TEST_IDS.SINGLE_ROW);
