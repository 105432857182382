import { Theme } from '@24i/nxg-sdk-photon/src';
import { hexToRgb } from '@24i/nxg-core-utils';
import { getFont, getBorderRadius, Ratio } from '@24i/nxg-sdk-higgs/src';
import { Platform } from 'react-native';
import { ToastStyles, ToastTypes } from './types';

const getToastStyles = (theme: Theme, type?: ToastTypes): ToastStyles => {
    const bg = hexToRgb(theme.color.primaryBlur1.color) || { r: 0, g: 0, b: 0 };
    const isPrimaryBlured = theme.color.primaryBlur1.blur !== 0;
    const blur = theme.color.primaryBlur1.blur || 0;
    const backdropFilter = { backdropFilter: `blur(${blur}px)` };
    return {
        container: {
            ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
            ...backdropFilter,
            width: Ratio(335),
            height: Platform.isTV ? Ratio(60) : Ratio(47),
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isPrimaryBlured
                ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.8)`
                : theme.color.primaryBlur1Fallback,
            opacity: 0.8,
            padding: 16,
            shadowColor: 'rgba(0,0,0, 0.2)',
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowRadius: 4,
        },
        bottomStripe: {
            width: 319,
            height: 2,
            backgroundColor: type === 'error' ? theme.color.error : theme.color.buttonPrimary,
            position: 'absolute',
            bottom: 1,
        },
        text: { ...getFont(theme, 'h5'), color: theme.color.textPrimary },
    };
};

export default getToastStyles;
