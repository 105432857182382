/*
@PRODUCT_FEATURE: N 1.1.0
*/
import en from '@24i/prd-localization-files-base/dist/en.json';
import config from './config';

export const sottTranslations = {
    en,
    defaultConfig: config,
};

export const i18nSottDefault = {
    ...sottTranslations.defaultConfig,
    resources: {
        en: { sott: sottTranslations.en },
    },
};
