var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;var _extends2=_interopRequireDefault(require("@babel/runtime/helpers/extends"));var _toConsumableArray2=_interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));var _react=_interopRequireDefault(require("react"));

var _SettingsScreen=_interopRequireWildcard(require("@24i/nxg-sdk-smartott/src/screens/SettingsScreen"));





var _SettingsTheme=_interopRequireDefault(require("../../components/Settings/SettingsTheme"));
var _SettingsService=_interopRequireDefault(require("../../components/Settings/SettingsService"));var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/screens/SettingsScreen/index.js";function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=typeof e&&"function"!=typeof e)return{default:e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n.default=e,t&&t.set(e,n),n;}

var themeSettingsOptions=function themeSettingsOptions(_ref){var _theme$icons,_theme$icons$settings,_theme$icons$settings2;var t=_ref.t,theme=_ref.theme;return{
menuTitle:t('settings.theme.title'),
name:'theme',
title:'theme',
icon:theme==null?void 0:(_theme$icons=theme.icons)==null?void 0:(_theme$icons$settings=_theme$icons.settings)==null?void 0:(_theme$icons$settings2=_theme$icons$settings.appTheme)==null?void 0:_theme$icons$settings2.iconName,
nativeRenderOptions:{Component:_SettingsTheme.default},
webMenuLink:{href:'/settings/theme',as:'/settings/theme'}
};};

var serviceSettingsOptions=function serviceSettingsOptions(_ref2){var _theme$icons2,_theme$icons2$setting,_theme$icons2$setting2;var t=_ref2.t,theme=_ref2.theme;return{
menuTitle:t('settings.service.title'),
name:'service',
title:'service',
icon:theme==null?void 0:(_theme$icons2=theme.icons)==null?void 0:(_theme$icons2$setting=_theme$icons2.settings)==null?void 0:(_theme$icons2$setting2=_theme$icons2$setting.service)==null?void 0:_theme$icons2$setting2.iconName,
nativeRenderOptions:{Component:_SettingsService.default},
webMenuLink:{href:'/settings/service',as:'/settings/service'}
};};

var OverridenSettingsScreen=function OverridenSettingsScreen(props){
var subMenuScreens=[].concat((0,_toConsumableArray2.default)(
_SettingsScreen.defaultSettingsSubscreens),[
themeSettingsOptions,
serviceSettingsOptions]).
filter(Boolean);

return _react.default.createElement(_SettingsScreen.default.Main,(0,_extends2.default)({subMenuScreens:subMenuScreens},props,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:37,columnNumber:12}}));
};var _default=

OverridenSettingsScreen;exports.default=_default;