import React, { useEffect } from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getSignupScreenStyles from '../styles';
import { SignupViewProps } from '../types';
import LoadingWrapper from '../../../components/LoadingWrapper/View/index';
import { AUTH_METHOD, AUTH_ACTION } from '../../SigninScreen/View/types';
import BrowserAuth from '../../SigninScreen/components/BrowserAuth';
import SignupForm from '../components/SignupForm';

const SignupScreen = (props: SignupViewProps) => {
    const {
        styles: getStyles = getSignupScreenStyles,
        isLoading = false,
        displayErrorUnder = true,
        authMethod,
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme, displayErrorUnder);

    const renderContentByAuthMethod = () => {
        if (isLoading) return null;
        switch (authMethod) {
            case AUTH_METHOD.OPENAUTH:
                return <BrowserAuth action={AUTH_ACTION.REGISTER} />;
            case AUTH_METHOD.WEBVIEW:
            case AUTH_METHOD.FORM:
            default:
                return <SignupForm {...props} />;
        }
    };

    useEffect(() => {
        console.log('SignupScreen.mount');
        return () => {
            console.log('SignupScreen.unmount');
        };
    }, []);

    return (
        <LoadingWrapper
            isLoading={Boolean(isLoading)}
            loaderProps={{ holderStyles: styles.loaderContainer }}
        >
            {renderContentByAuthMethod() || null}
        </LoadingWrapper>
    );
};

export { getSignupScreenStyles };
export default overridable(SignupScreen, 'SignupScreen');
