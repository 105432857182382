import React, { ReactElement } from 'react';
import { PodcastPlayerView } from './View';
import { useShared } from './viewModel/shared';

const Main = (): ReactElement | null => {
    const { engine } = useShared();

    return engine ? <PodcastPlayerView /> : null;
};

export default Main;
