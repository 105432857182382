import { Theme } from '@24i/nxg-sdk-photon';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { DeleteAccountSuccessStyles } from '../types';
import { Breakpoint } from '../../../utils/styles/constants';

const getStyles = (theme: Theme): DeleteAccountSuccessStyles => ({
    main: {
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: 48,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginHorizontal: 20,
        },
    },
    headerContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            display: 'none',
        },
    },
    actionContainer: {
        width: Breakpoint.SM,
        alignSelf: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 60,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            width: '100%',
        },
    },
    title: { color: theme.color.textPrimary, marginBottom: 24, ...getFont(theme, 'h2') },
    description: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        marginBottom: 24,
        textAlign: 'center',
        ...getFont(theme, 'body2'),
    },
    button: {
        backgroundColor: theme.color.buttonPrimary,
        height: 52,
        width: 157,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 24,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            width: '100%',
        },
    },
    buttonText: {
        color: theme.color.textButtonPrimary,
        ...getFont(theme, 'primaryButton'),
    },
});

export default getStyles;
