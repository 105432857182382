import { Theme } from '@24i/nxg-sdk-photon';
import { ChangeSubscriptionStyles } from './types';

const getStyles = (theme: Theme): ChangeSubscriptionStyles => ({
    wrapper: {},
    itemWrapper: {
        paddingBottom: 16,
    },
    cancelSubscriptionButtonWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    changeTextWrapper: {
        marginVertical: 32,
    },
    changeText: {
        color: theme.color.labelPrimary,
    },
});

export default getStyles;
