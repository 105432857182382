import { Platform } from 'react-native';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { MvpdGridStyles } from '../types';

export const getMvpdGridStyles = (theme: Theme): MvpdGridStyles => {
    const borderRadius =
        typeof theme.elements['headerTile-cornerRadius'] === 'number'
            ? theme.elements['headerTile-cornerRadius']
            : 4;

    return {
        mvpdGrid: {
            flex: 1,
            width: '100%',
        },
        tierOneGridItem: {
            width: 280,
            height: 112,
            marginBottom: 16,
            backgroundColor: theme.color.lighter4,
            borderRadius,
            ...Platform.select({
                web: {
                    cursor: 'pointer',
                },
            }),
        },
        allMvpdsGridItem: {
            width: '100%',
            marginBottom: 8,
            backgroundColor: theme.color.lighter4,
            borderRadius,
            ...Platform.select({
                web: {
                    cursor: 'pointer',
                },
            }),
        },
        columnWrapperStyle: {
            justifyContent: 'space-between',
            marginRight: 12,
        },
    };
};
