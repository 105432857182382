import { useTranslation } from 'react-i18next';
import { usePaymentHistoryScreen } from '../../context/SubscriptionsHandlerProvider';

export default {
    Main: () => {
        const { Main } = usePaymentHistoryScreen();
        const { t } = useTranslation();

        return Main({
            webHeaderTitle: t('account.paymentHistory.title'),
        });
    },

    View: () => {
        const { View } = usePaymentHistoryScreen();
        return View;
    },
};
