export const getDummyClient = <I>() =>
    new Proxy(
        {},
        {
            get: function get(_, prop) {
                throw new Error(
                    `Accessing '${String(
                        prop
                    )}'. This is dummy implementation. If you want to use '${String(
                        prop
                    )}', use real data client.`
                );
            },
        }
    ) as I;
