import { getFont } from '@24i/nxg-sdk-higgs';
import { TermsAndConditionsStylesGetter } from '../types';
import { Breakpoint } from '../../../utils/styles/constants';

export const getTermsAndConditionsPageStyles: TermsAndConditionsStylesGetter = (theme) => {
    return {
        wrapper: { flex: 1 },
        contentContainer: {
            width: 576,
            minHeight: 576,
            alignItems: 'center',
            alignSelf: 'center',
            [`@media (max-width: ${Breakpoint.MD}px)`]: {
                width: '100%',
                paddingHorizontal: 20,
            },
        },
        scrollViewContainerStyle: {},
        scrollViewContentStyle: {},
        bottomGradient: {},
        title: {
            ...getFont(theme, 'h2'),
            color: theme.color.textPrimary,
            paddingBottom: 68,
            [`@media (max-width: ${Breakpoint.MD}px)`]: {
                paddingBottom: 12,
            },
        },
        loaderContainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            position: 'relative',
            width: '100%',
            height: 52,
            bottom: 0,
        },
        buttonContainer: {
            flex: 1,
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: 48,
            paddingBottom: 64,
        },
        onboardingHeaderWrapper: {
            marginBottom: 64,
            [`@media (max-width: ${Breakpoint.MD}px)`]: {
                marginBottom: 3,
            },
        },
    };
};
