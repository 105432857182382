import View from './View';

export {
    getBottomPartStyles,
    defaultRenderEpisodePicker,
    defaultRenderPackshotGrid,
    defaultRenderDetailsTab,
} from './View';

export default View;
