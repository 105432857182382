import { TextStyle, ViewStyle } from 'react-native';
import { Theme } from '@24i/nxg-sdk-photon';
import { StylesGetter } from '../../../../utils/styles/types';

export interface DeleteAccountModalStyles {
    main: ViewStyle;
    contentContainer: ViewStyle;
    title: TextStyle;
    description: TextStyle;
    buttonContainer: ViewStyle;
    confirmButton: ViewStyle;
    confirmButtonText: TextStyle;
    cancelButton: ViewStyle;
    cancelButtonText: TextStyle;
    loadingContainer?: ViewStyle;
}

export interface DeleteAccountModalProps {
    isVisible: boolean;
    isLoading?: boolean;
    onCloseModal: () => void;
    openErrorModal: (
        title: string,
        description: string,
        defaultHorizontalButtonLayout?: boolean,
        closeButtonLabel?: string
    ) => void;
    onConfirmDeleteAccount?: () => void;
    styles?: StylesGetter<Theme, DeleteAccountModalStyles>;
}

export enum DELETE_ACCCOUNT_MODAL_TEST_ID {
    CONFIRM_BUTTON = 'testId_delete_account_confirm_button',
    CANCEL_BUTTON = 'testId_delete_account_cancel_button',
}
