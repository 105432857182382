import React from 'react';
import View from './View';
import useViewModel from './viewModel';
import { ManageProfilesScreenProps } from './types';

const Main = (props: ManageProfilesScreenProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default {
    Main,
    View,
    useViewModel,
};
