export enum Breakpoint {
    XXS = 320,
    XS = 400,
    SM = 576,
    MD = 768,
    // This is the breakpoint the top bar is using
    // TODO: Consolidate
    WEIRD_MD = 801,
    L = 992,
    XL = 1200,
    XXL = 1920,
}

export enum BreakpointDirection {
    MAX = 'max',
    MIN = 'min',
}
