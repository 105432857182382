import { QUERY_KEYS, User } from '@24i/nxg-sdk-photon/src';
import { useQuery } from 'react-query';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';

export const useUserQuery = () => {
    const client = useUserData();

    const { isLoading, data, refetch } = useQuery<User | null>(QUERY_KEYS.user, () =>
        client.fetchUser()
    );

    return {
        isLoading,
        user: data,
        refetch,
    };
};
