import { useQuery, UseQueryOptions } from 'react-query';
import { Recording, QUERY_KEYS, Broadcast } from '@24i/nxg-sdk-photon';
import { useRecordings } from '@24i/nxg-sdk-smartott-shared/src/context/Recordings';
import { useAppConfigQuery } from '../useAppConfigQuery';

export const useRecordingAssetQuery = (
    asset?: Broadcast,
    options?: UseQueryOptions<Recording | undefined>
) => {
    const { fetchRecordingForBroadcast } = useRecordings();
    const { appSettings } = useAppConfigQuery();
    const recordingsEnabled = appSettings?.features?.recordings?.enabled;

    const { data, isLoading, error, isFetching } = useQuery<Recording | undefined>(
        [QUERY_KEYS.recordings, asset?.id],
        () => {
            if (recordingsEnabled && asset) {
                return fetchRecordingForBroadcast(asset);
            }
            return undefined;
        },
        options
    );

    return { data, error, isLoading, isFetching };
};
