import { DynamicBottomBarHeight } from '@24i/nxg-core-utils/src/globalSingletons';
import type { TFunction } from 'i18next';
import { PLAYLIST_TYPE } from '@24i/nxg-sdk-photon';

let topBarVisible = false;

export const getAnimatedHeaderScrollProps = (route, animationOffset = 305, onScroll?) => {
    const onScrollWrapper = (e) => {
        onScroll?.(e);
        const { contentOffset } = e.nativeEvent;
        const { fadeIn, fadeOut } = route?.params || {};

        if (contentOffset.y > animationOffset && !topBarVisible) {
            // eslint-disable-next-line no-unused-expressions
            fadeIn?.start();
            topBarVisible = true;
        } else if (contentOffset.y <= animationOffset && topBarVisible) {
            // eslint-disable-next-line no-unused-expressions
            fadeOut?.start();
            topBarVisible = false;
        }
    };

    return { onScroll: onScrollWrapper, scrollEventThrottle: 8 };
};

export const getBottomBarHeight = () => DynamicBottomBarHeight.get() || 0;

export const showBackButton = (navigation) =>
    navigation?.canGoBack() && navigation?.getState()?.index > 0;

export const getLabelForPlaylist = (type: PLAYLIST_TYPE, t: TFunction) => {
    switch (type) {
        case PLAYLIST_TYPE.PURCHASED:
            return t('tvod.manage.bought');
        case PLAYLIST_TYPE.RENTALS:
            return t('tvod.manage.rented');
        case PLAYLIST_TYPE.EXPIRED_RENTALS:
            return t('tvod.manage.expired');
        default:
            return '';
    }
};

export const extractQueryParamsFromAsPath = (asPath: string) => {
    return new URLSearchParams(asPath.slice(asPath.indexOf('?')));
};

export const normalizeQuery = (query: string | string[] | undefined) =>
    Array.isArray(query) ? query[0] : query;
