import { Theme } from '@24i/nxg-sdk-photon';
import { OnboardingSubscriptionButtonsStyles } from './types';

export const getStyles = (theme: Theme): OnboardingSubscriptionButtonsStyles => ({
    content: {
        paddingTop: 40,
    },
    maybeButton: {
        alignSelf: 'center',
    },
    maybeText: {
        color: theme.color.textPrimary,
    },
});
