import { useCallback } from 'react';

import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';

import { PlaybackState } from './playbackState';
import { BuildPlaybackEventFunction } from './buildPlaybackEvent';

export interface AdSkippedPlayerEventParams {
    adName: string;
    adPosition: number;
    adServerUrl?: string;
}

export const useTriggerOnAdSkipped = (buildPlaybackEvent: BuildPlaybackEventFunction) => {
    const triggerOnAdSkipped = useCallback(
        (playbackState: PlaybackState | undefined, params: AdSkippedPlayerEventParams) => {
            const event = buildPlaybackEvent('AD_SKIPPED', playbackState);
            if (!event) return;

            onAnalytics(ANALYTICS_TRIGGERS.AD_SKIPPED, {
                ...event,
                adName: params.adName,
                adPosition: params.adPosition,
            });
        },
        []
    );

    return triggerOnAdSkipped;
};
