import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import getStatusLayoutStyles from './styles';
import { PurchaseModalStatusLayoutStyles } from './styles/types';

interface Props {
    title?: string | null;
    actionButton: {
        title: string | null;
        onPress: () => void;
    };
    cancelButton?: {
        title?: string | null;
        onPress: () => void;
    };
    children: React.ReactNode;
    isModalContent?: boolean;
    styles?: (theme: Theme, isModalContent?: boolean) => PurchaseModalStatusLayoutStyles;
}

const StatusLayout: FC<Props> = ({
    title,
    actionButton,
    cancelButton,
    children,
    isModalContent = true,
    styles: getStyles = getStatusLayoutStyles,
}) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme, isModalContent);

    return (
        <View style={styles.wrapper}>
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>{title}</Text>
            </View>
            <View style={styles.contentWrapper}>
                <Text style={styles.content}>{children}</Text>
            </View>
            <View style={styles.buttonsWrapper}>
                <ActionButton
                    title={actionButton.title}
                    additionalTextStyles={styles.actionButtonText}
                    additionalContainerStyles={styles.actionButtonContainer}
                    onPress={actionButton.onPress}
                />
                {cancelButton && (
                    <View style={styles.cancelButtonWrapper}>
                        <TertiaryButton
                            title={cancelButton.title ?? t('common.cancel')}
                            onPress={cancelButton.onPress}
                            additionalTextStyles={styles.cancelButtonText}
                            additionalContainerStyles={styles.cancelButtonContainer}
                        />
                    </View>
                )}
            </View>
        </View>
    );
};

export default StatusLayout;
