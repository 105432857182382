import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS, Season } from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';

const useAssetSeasonsQuery = (assetId?: string, options?: UseQueryOptions<Season[]>) => {
    const isOptionEnabled = options?.enabled !== undefined ? options.enabled : true;
    const { fetchEpisodes } = useContentData();

    return useQuery<Season[]>(
        [QUERY_KEYS.assets, QUERY_KEYS.assetSeasons, assetId],
        () => fetchEpisodes(assetId),
        {
            ...options,
            enabled: !!assetId && isOptionEnabled,
        }
    );
};

export default useAssetSeasonsQuery;
