import React, { FC } from 'react';
import { NavigationIcon } from '@24i/nxg-sdk-quarks';
import getIconLinkStyles from '../styles';

const isValidIcon = (icon: unknown): icon is { href: string; name: string } =>
    icon != null && typeof icon === 'object' && 'href' in icon && 'name' in icon;

type Props = {
    icon: unknown;
    index: number;
    isLastItem: boolean;
};

const IconLink: FC<Props> = ({ icon, index, isLastItem }) => {
    if (!icon || !isValidIcon(icon)) return null;

    return (
        <a
            key={`${icon.name}${index}`}
            style={{
                marginRight: isLastItem ? 0 : 35,
            }}
            rel="noopener noreferrer"
            target="_blank"
            href={icon?.href}
        >
            <NavigationIcon iconName={icon.name} size={24} />
        </a>
    );
};

export { getIconLinkStyles };
export default IconLink;
