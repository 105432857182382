import { useInfiniteQuery } from 'react-query';
import { QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import { GridItem } from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid/types';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';

const usePlaylistsQuery = (size?: number) => {
    const contentDataClient = useContentData();

    return useInfiniteQuery<GridItem[]>(
        QUERY_KEYS.lastWatchPlaylist,
        ({ pageParam }) => contentDataClient.fetchWatchHistoryPlaylist(pageParam, size),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.length === size) {
                    return pages.length * size;
                }
                return undefined;
            },
        }
    );
};

export default usePlaylistsQuery;
