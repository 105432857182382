import { Theme } from '@24i/nxg-sdk-photon';
import { getFont, getMargins } from '@24i/nxg-sdk-higgs';
import { PlaylistScreenStyles } from '../types';

const playlistStyles = (theme: Theme): PlaylistScreenStyles => ({
    holder: {
        backgroundColor: theme.color.background1,
        flex: 1,
        flexDirection: 'column',
        marginTop: 102,
        ...getMargins(),
    },
    pickerHolder: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.color.background1,
        marginTop: 32,
    },
    sectionTitle: {
        textAlign: 'center',
        ...getFont(theme, 'h2'),
        color: theme?.color?.textPrimary,
    },
});

export default playlistStyles;
