import { createGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const SubtitleSourceGuard = createGuard('SubtitleSources', {
    type: t.keyof({
        srt: null,
        ttml: null,
        vtt: null,
        webvtt: null,
        xml: null,
    }),
    src: t.string,
});

export const SubtitleGuard = createGuard('Subtitles', {
    label: t.string,
    language: t.string,
    sources: t.array(SubtitleSourceGuard),
    forced: t.boolean,
});

export type Subtitle = t.TypeOf<typeof SubtitleGuard>;
export type SubtitleSource = t.TypeOf<typeof SubtitleSourceGuard>;
