import { getFont } from '@24i/nxg-sdk-higgs';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { Theme } from '@24i/nxg-sdk-photon';

const isTablet = DeviceInfo.isTablet();

const styles = (theme: Theme) => ({
    container: {
        marginHorizontal: 15,
        marginTop: isTablet ? 75 : 60,
        flex: 1,
        alignSelf: isTablet ? 'center' : 'auto',
        maxWidth: isTablet ? 550 : 'auto',
        minWidth: isTablet ? 350 : 'auto',
    },
    deviceContainer: {
        marginBottom: 35,
        marginLeft: !isTablet ? 48 : undefined,
    },
    additionalDeviceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    deviceText: {
        marginLeft: 24,
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
    },
});

export default styles;
