import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle, ViewStyle, Platform } from 'react-native';
import { getFont } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { PURCHASE_ITEM_HEIGHT } from '../../components/PurchaseItem/styles';

export interface PurchaseSelectionStyles {
    informationTextWrapper?: ViewStyle;
    informationText?: TextStyle;
    wrapper: ViewStyle;
    modalContainer: ViewStyle;
    scrollContainer: ViewStyle;
    scrollIndicator: ViewStyle;
    hiddenContainer: ViewStyle;
    purchaseItem: ViewStyle;
    itemSeparator: ViewStyle;
    sectionSeparator: ViewStyle;
    loader: ViewStyle;
    errorMessage: TextStyle;
}

export const PADDING_VERTICAL = 24;
const isWeb = Platform.OS === 'web';
const getStyles = (theme: Theme): PurchaseSelectionStyles => ({
    loader: { margin: 25 },
    wrapper: {
        paddingBottom: PURCHASE_ITEM_HEIGHT,
        paddingVertical: PADDING_VERTICAL,
    },
    modalContainer: {
        minHeight: PURCHASE_ITEM_HEIGHT + PADDING_VERTICAL,
        maxHeight: 480,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    scrollContainer: {
        position: 'absolute',
        top: 0,
        right: -18,
        bottom: 0,
        height: '100%',
        width: 3,
    },
    hiddenContainer: {
        opacity: 0,
        position: 'absolute',
        flexDirection: 'row',
        zIndex: -1,
    },
    scrollIndicator: {
        width: 3,
        borderRadius: 1.5,
        backgroundColor: theme.color.contrast3,
        opacity: 0.5,
    },
    purchaseItem: {
        alignItems: 'center',
        width: 576,
    },
    itemSeparator: {
        height: 8,
    },
    sectionSeparator: {
        backgroundColor: theme.color.lighter3,
        height: 0.5,
        marginHorizontal: 20,
        ...(isPlatformWeb && {
            marginTop: 16,
            marginBottom: 16,
        }),
    },
    errorMessage: {
        paddingTop: isWeb ? 40 : '50%',
        color: theme.color.textPrimary,
        textAlign: 'center',
        ...getFont(theme, 'h3'),
    },
});

export default getStyles;
