import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Asset, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useUserQuery } from '../user/useUserQuery';

const useMyFavoritesPlaylistQuery = (
    options?: UseQueryOptions<Asset[]>
): UseQueryResult<Asset[]> => {
    const { fetchFavoritesPlaylist } = useContentData();
    const { user } = useUserQuery();

    return useQuery<Asset[]>(QUERY_KEYS.myFavoritesPlaylist, fetchFavoritesPlaylist, {
        ...options,
        enabled: !!user,
    });
};

export default useMyFavoritesPlaylistQuery;
