import React, { ReactElement } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text } from '@24i/nxg-sdk-quarks';
import PackshotGrid from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import getGenreScreenStyles from '../styles/index.web';
import { GenreWebProps } from '../types';

const GenreScreen = ({
    genreAssets = [],
    selectedGenre = '',
    styles: getStyles = getGenreScreenStyles,
    handlePackshotPress,
    isLoading,
    gridContainerRef,
}: GenreWebProps): ReactElement => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.holder} ref={gridContainerRef}>
            <View style={styles.pickerHolder}>
                <Text style={styles.sectionTitle}>{selectedGenre}</Text>
            </View>
            <View style={styles.gridContainer}>
                <PackshotGrid items={genreAssets} onItemPress={handlePackshotPress} />
                {isLoading && <Loader />}
            </View>
        </View>
    );
};

export default GenreScreen;
