import { NavigationProp, RouteProp, NavigatorScreenParams } from '@react-navigation/native';
import { StackParamList } from '@24i/nxg-sdk-smartott';
import { Theme } from '@24i/nxg-sdk-photon';
import {
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_ROOT_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { StandardButtonsProps } from './components/StandardButtons/types';
import { WrapperProps } from './components/Wrapper/types';
import { SignUpScreenViewStyles } from './styles/types';
import { AUTH_METHOD } from '../SigninScreen/View/types';

export type HomeScreenStack = {
    [SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN]: NavigatorScreenParams<any>;
};

export type SignUpNavigationParamsType = StackParamList & HomeScreenStack;

export interface OnSignUpContext {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export interface SignupModelProps {
    // This is optional since this prop is not present for web.
    navigation?: NavigationProp<SignUpNavigationParamsType>;
    // This is optional since this prop is not present for web.
    route?: RouteProp<StackParamList, SOTT_DEFAULT_SCREENS.SIGN_UP>;
}

export interface SignupViewProps
    extends SignupModelProps,
        Partial<Omit<StandardButtonsProps, 'styles' | 't'>> {
    isLoading?: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    isEmailIncorrect?: boolean;
    isFirstNameIncorrect?: boolean;
    isLastNameIncorrect?: boolean;
    isPasswordIncorrect?: boolean;
    isSamePasswords?: boolean;
    customEmailErrorMessage: string | undefined;
    wrapperProps?: WrapperProps['wrapperProps'];
    browseLinkProps?: {
        as: string;
        href: string;
        passHref: boolean;
    } | null;
    styles?: (theme: Theme, displayErrorUnder?: boolean) => SignUpScreenViewStyles;
    onBack: () => void;
    onFirstNameChange?: (text: string) => void;
    onLastNameChange?: (text: string) => void;
    onEmailChange?: (text: string) => void;
    onPasswordChange?: (text: string) => void;
    onConfirmPasswordChange?: (text: string) => void;
    onSignUpPress?: (data: OnSignUpContext) => void;
    onSignInPress?: () => void;
    renderButtons?: (props: StandardButtonsProps) => JSX.Element[];
    shouldDisableRegisterButton?: (isNotEmpty: boolean) => boolean;
    authMethod: AUTH_METHOD;
}

export enum SIGNUP_TEST_IDS {
    // Inputs
    FIRST_NAME_INPUT = 'first_name_input',
    LAST_NAME_INPUT = 'last_name_input',
    EMAIL_INPUT = 'register_email_input',
    PASSWORD_INPUT = 'register_password_input',
    CONFIRM_PASSWORD_INPUT = 'register_confirm_password_input',
    // Buttons
    SHOW_PASSWORD_BUTTON = 'register_password_input_show_button',
    SHOW_CONFIRM_PASSWORD_BUTTON = 'register_confirm_password_input_show_button',
    CONFIRM_BUTTON = 'register_confirm_button',
    SIGN_IN_BUTTON = 'sign_in_button',
    ERROR_MESSAGE_EMAIL = 'error_message_email',
    ERROR_MESSAGE_PASSWORD = 'error_message_password',
    ERROR_MESSAGE_NAMES = 'error_message_names',
}
