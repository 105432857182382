import { DependencyList, useEffect } from 'react';

export const useEffectWithCancelledFlag = (
    func: ({
        getIsCancelled,
    }: {
        getIsCancelled: () => boolean;
    }) => void | Promise<void> | (() => void),
    deps: DependencyList
): void => {
    useEffect(() => {
        const state = { isCancelled: false };
        const cleanUp = func({
            getIsCancelled: () => state.isCancelled,
        });
        return () => {
            state.isCancelled = true;
            if (typeof cleanUp === 'function') {
                cleanUp();
            }
        };
    }, deps);
};
