import { getFont, getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { SeeAllScreenStyles } from './types';

const seeAllStyles = (theme: Theme): SeeAllScreenStyles => ({
    holder: {
        backgroundColor: theme.color.background1,
        flex: 1,
        flexDirection: 'column',
        ...getMargins(),
    },
    pickerHolder: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.color.background1,
        marginTop: 102,
    },
    sectionTitle: {
        textAlign: 'center',
        ...getFont(theme, 'h2'),
        color: theme?.color?.textPrimary,
    },
});

export default seeAllStyles;
