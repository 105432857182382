import { Theme } from '@24i/nxg-sdk-photon';
import { TextStyle } from 'react-native';
import { FontWeight, ViewStyleWithExtraProps } from '../../../../types';

type MiniPlayerControlsStyles = {
    controlsContainer: ViewStyleWithExtraProps;
    sliderContainer: ViewStyleWithExtraProps;
    sliderLabel: TextStyle;
};

export const getStyles = (theme: Theme): MiniPlayerControlsStyles => ({
    controlsContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 24,
        marginBottom: 6,
    },

    sliderContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 20,
        minHeight: 20,
    },

    sliderLabel: {
        minWidth: 50,
        fontFamily: theme.fonts.S?.h5?.family,
        fontWeight: theme.fonts.S?.h5?.weight as FontWeight,
        fontSize: theme.fonts.S?.h5?.size,
        lineHeight: theme.fonts.S?.h5?.line,
        letterSpacing: -0.16,
        color: 'inherit',
    },
});
