import React from 'react';
import PropTypes from 'prop-types';
import useRouter from '../../hooks/useRouter';

const RoutedPage = ({ url, ...props }) => {
    const router = useRouter();
    const { Component } = router.findByUrl(url);

    return <Component {...props} />;
};

RoutedPage.propTypes = {
    url: PropTypes.string.isRequired,
};

export default RoutedPage;
