/* eslint-disable max-statements */
import React, { useEffect, useState } from 'react';
import { GestureResponderEvent, Linking } from 'react-native';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useImage } from '@24i/nxg-sdk-gluons/src/context/ImageService';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { gradientColors } from '@24i/nxg-core-utils';
import { View, ImageBackground } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import LinearGradient from 'react-native-linear-gradient';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import withErrorModal from '@24i/nxg-sdk-smartott/src/components/GenericModal/hoc/ErrorModal';
import { WEB_SCREEN_MAX_WIDTH } from '@24i/nxg-sdk-gluons/src/utils/constants';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { Asset, Broadcast, isBroadcast, RECORDING_STATUS } from '@24i/nxg-sdk-photon';
import RecordModal from '@24i/nxg-sdk-smartott/src/components/RecordModal';
import DetailsScreenContent from '../components/DetailsScreenContent';
import getDetailsScreenStyles from '../styles';
import {
    actionButtonStyles,
    getErrorModalMesage,
    getToastMessage,
    handleSharePress,
    transformSubtitle,
    handleOnMyListPress,
} from '../viewModel/utils';
import { DetailsViewPropsWithError } from '../types';
import {
    withPurchaseModal,
    WithPurchasesModalProps,
} from '../../../components/PurchasesModal/utils/withPurchasesModal';
import BottomPart from '../components/BottomPart';
import LoadingWrapper from '../../../components/LoadingWrapper';
import useWebPurchaseCheckout from '../../../hooks/useWebPurchaseCheckout';
import {
    withSubscriptionRequiredModal,
    WithSubscriptionRequiredModal,
} from '../../../components/SubscriptionRequiredModal/utils/withSubscriptionRequiredModal';

const DetailsScreen = ({
    asset,
    extraItems,
    seasons = [],
    related = [],
    recordingState,
    styles: getStyles = getDetailsScreenStyles,
    onRemindPress,
    onSharePress,
    onMyListPress,
    isAddedToMyList = false,
    mainButtonsOverride,
    onRelatedPress,
    additionalContentPickerItems = [],
    renderEpisodePicker,
    renderPackshotGrid,
    renderDetailsTab,
    openErrorModal,
    onSetSingleRecordingPress,
    onRemoveSingleRecordingPress,
    onSetMultipleRecordingPress,
    onRemoveMultipleRecordingPress,
    reminderIsSet,
    gradientParams,
    fetchReminder,
    fetchingReminder,
    handleOnPlayPress,
    openPurchaseModal,
    isPurchaseModalVisible,
    closePurchaseModal,
    errorForModal,
    isLoading,
    handlePurchaseAsset,
    openSubscriptionRequiredModal,
}: DetailsViewPropsWithError & WithPurchasesModalProps & WithSubscriptionRequiredModal) => {
    const { setModalChildren, closeModal, setModalProps } = useModal();
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const { getImageUrl } = useImage();
    const deepLinking = useFeature('deepLinking');
    const styles = getStyles(theme);
    const { title, description, still, background, poster } = asset ?? {};

    const bottomGradient = gradientColors(theme.color.background1);

    useWebPurchaseCheckout({
        onTryAgain: () => asset && handlePurchaseAsset(asset, openPurchaseModal),
    });

    const deepLinkingEnabled = deepLinking?.enabled && deepLinking.storeUrls;
    const appStoreUrl = deepLinking?.storeUrls?.ios;
    const playStoreUrl = deepLinking?.storeUrls?.android;
    const { userAgent } = navigator;

    const isIOS = /iPhone|iPad|iPod/.test(userAgent);
    const isAndroid = /Android/.test(userAgent);

    useEffect(() => {
        if (deepLinkingEnabled && document.referrer === '') {
            if (isAndroid && playStoreUrl) {
                Linking.openURL(playStoreUrl);
            } else if (isIOS && appStoreUrl) {
                Linking.openURL(appStoreUrl);
            }
        }
    }, []);

    useEffect(() => {
        if (errorForModal) openErrorModal(errorForModal.title, errorForModal.message, false);
    }, [errorForModal]);

    const [recordOperation, setRecordOperation] = useState(false);
    const [currentRecordingState, setCurrentRecordingState] = useState(recordingState);
    useEffect(() => {
        const isRecordingOperation = recordOperation && !recordingState.loading;
        const hasSuccededRecordingOperation =
            isRecordingOperation &&
            recordingState.status !== currentRecordingState.status &&
            !recordingState.error;
        const hasFailedRecordingOperation = isRecordingOperation && !!recordingState.error;

        if (hasSuccededRecordingOperation) {
            const toastMessage = getToastMessage(
                t,
                recordingState.status,
                currentRecordingState.status
            );
            showToast(toastMessage);
            setRecordOperation(false);
        }
        if (hasFailedRecordingOperation) {
            const modalMessage =
                recordingState.error ||
                getErrorModalMesage(t, asset?.title || '', currentRecordingState.status);
            openErrorModal(t('error.F01.title'), modalMessage, false);
            setRecordOperation(false);
        }
        setCurrentRecordingState(recordingState);
    }, [recordingState]);

    const onPlayPress = async (selectedAsset: Asset, isStartOver = false) => {
        handleOnPlayPress({
            streamAsset: selectedAsset,
            isStartOver,
            onPurchaseAsset: (assetToPurchase) =>
                handlePurchaseAsset(assetToPurchase, openPurchaseModal),
        });
    };

    const handleOnRecordPress = async (recordingStatus) => {
        setRecordOperation(true);
        if (
            ((!!asset?.series || !!(asset as Broadcast)?.seriesId) && !recordingStatus) ||
            !!recordingState.seriesId ||
            recordingStatus === RECORDING_STATUS.COMPLETED
        ) {
            return setModalChildren(
                <RecordModal
                    recordingStatus={recordingStatus}
                    asset={asset as Asset}
                    onSetSingle={() => {
                        closeModal();
                        onSetSingleRecordingPress();
                    }}
                    onRemoveSingle={() => {
                        closeModal();
                        onRemoveSingleRecordingPress();
                    }}
                    onSetMultiple={() => {
                        closeModal();
                        onSetMultipleRecordingPress();
                    }}
                    onRemoveMultiple={() => {
                        closeModal();
                        onRemoveMultipleRecordingPress();
                    }}
                    closeModal={closeModal}
                    setModalProps={setModalProps}
                    seriesId={recordingState.seriesId}
                />
            );
        }
        return recordingStatus ? onRemoveSingleRecordingPress() : onSetSingleRecordingPress();
    };

    const renderBottomPart = () => {
        if (!asset) {
            return <></>;
        }

        return (
            <BottomPart
                asset={asset}
                seasons={seasons}
                related={related}
                extraItems={extraItems}
                onPlay={onPlayPress}
                renderEpisodePicker={renderEpisodePicker}
                renderPackshotGrid={renderPackshotGrid}
                renderDetailsTab={renderDetailsTab}
                onRelatedPress={onRelatedPress}
                additionalContentPickerItems={additionalContentPickerItems}
            />
        );
    };

    const onPlayCurrentAsset = (_event?: GestureResponderEvent) => asset && onPlayPress(asset);
    const onReplayCurrentAsset = () => asset && onPlayPress(asset, true);
    const isMobileWidth = window.innerWidth < WEB_SCREEN_MAX_WIDTH.XS;
    const getResponsiveImagePath = () =>
        isMobileWidth ? poster || background || still || '' : background || still || '';
    const imageURL = getImageUrl(
        {
            imagePath: getResponsiveImagePath(),
            width: Math.min(window.innerWidth, WEB_SCREEN_MAX_WIDTH.L),
            height: Math.min(window.innerHeight, 1080),
        },
        isMobileWidth
            ? asset?.images?.poster || asset?.images?.background || asset?.images?.still
            : asset?.images?.background || asset?.images?.still
    );
    return (
        <LoadingWrapper
            isLoading={isLoading}
            wrapperStyle={{ ...styles.holder, ...(!!isLoading && styles.loader) }}
            loaderProps={{ color: theme.color.textPrimary, size: 'large', style: styles.loader }}
        >
            <View style={{ width: '100%' }}>
                <ImageBackground source={{ uri: imageURL }} style={styles.imageBackground} />
                <View style={{ width: '100%' }} />
                <LinearGradient colors={bottomGradient} style={styles.bottomGradient} />
                <View style={styles.contentContainer}>
                    {asset && (
                        <DetailsScreenContent
                            asset={asset}
                            seasons={seasons}
                            title={title}
                            recordingState={currentRecordingState}
                            subtitle={transformSubtitle(asset, seasons, t)}
                            description={description}
                            actionButtonStyles={actionButtonStyles}
                            image={background || still}
                            onPlayPress={onPlayCurrentAsset}
                            onRemindPress={() => isBroadcast(asset) && onRemindPress(asset)}
                            onSharePress={() =>
                                onSharePress && handleSharePress(asset, onSharePress)
                            }
                            onMyListPress={() =>
                                onMyListPress &&
                                handleOnMyListPress(isAddedToMyList, t, onMyListPress)
                            }
                            onRecordPress={(isRecordingSet) => handleOnRecordPress(isRecordingSet)}
                            onReplayPress={onReplayCurrentAsset}
                            isAddedToMyList={isAddedToMyList}
                            mainButtonsOverride={mainButtonsOverride}
                            reminderIsSet={reminderIsSet}
                            gradientParams={gradientParams}
                            fetchReminder={fetchReminder}
                            fetchingReminder={fetchingReminder}
                            openPurchaseModal={openSubscriptionRequiredModal}
                            isPurchaseModalVisible={isPurchaseModalVisible}
                            closePurchaseModal={closePurchaseModal}
                        />
                    )}
                </View>
            </View>
            {renderBottomPart()}
        </LoadingWrapper>
    );
};

export { getDetailsScreenStyles };
export default overridable(
    withErrorModal(withPurchaseModal(withSubscriptionRequiredModal(DetailsScreen))),
    'DetailsScreen'
);
