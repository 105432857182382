import { useState } from 'react';
import { ScreenScrollMilestone } from '@24i/nxg-sdk-photon/src/analytics/navigation';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_PREFIXES,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useCommonNavEventParams } from '../useCommonNavEventParams';
import { useListScrollListener } from '../../../hooks/useListScrollListener';
import { useTriggerOncePersistent } from '../../hooks/useTriggerOncePersistent';

export const useTriggerOnScroll = (milestones = [25, 50, 75, 90, 100]) => {
    const [initialMilestones, setInitialMilestones] = useState({});
    const event = useCommonNavEventParams();
    const { triggerMonitoringEvent } = useMonitoring();
    const { triggerOnce } = useTriggerOncePersistent<ScreenScrollMilestone>(
        (e) => `${e.value}${e.pageId}${e.sessionId}`,
        triggerMonitoringEvent,
        undefined,
        !!initialMilestones
    );

    const triggerMilestones = (currentValue: number) => {
        milestones.forEach((milestone) => {
            if (milestone <= currentValue) {
                onAnalytics(ANALYTICS_TRIGGERS[`${ANALYTICS_PREFIXES.SCROLL}${milestone}`], event);
                triggerOnce({ ...event, value: milestone, type: 'SCREEN_SCROLL_MILESTONE' });
            }
        });
    };

    const getInitialMilestones = (firstValue: number) => {
        const milestonesCopy = [...milestones];
        while (firstValue < milestonesCopy[0]) {
            milestonesCopy.shift();
        }
        return milestonesCopy.reduce((acc, value) => {
            acc[value] = true;
            return acc;
        }, {});
    };

    const updateMilestonesState = (percentage) => {
        if (!initialMilestones) setInitialMilestones(getInitialMilestones(percentage));
    };

    const { onScroll } = useListScrollListener((percentage: number) => {
        triggerMilestones(percentage);
        updateMilestonesState(percentage);
    });

    return { onScroll };
};
