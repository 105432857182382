import { Theme } from '@24i/nxg-sdk-photon/src';
import { ChannelOverlayStylesWeb } from '../types';

const channelOverlayStyles = (theme: Theme): ChannelOverlayStylesWeb => ({
    bigChannelLogoWrapper: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(${[
            `${theme.color.background1}0F`,
            `${theme.color.background1}7F`,
            `${theme.color.background1}BF`,
        ]})`,
        borderRadius: 8,
    },
    smallChannelLogoWrapper: {
        zIndex: 2,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 10,
        bottom: '14%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    channelLogo: {
        zIndex: 2,
        width: '30%',
        height: '30%',
    },
    bigPackshotChannelLogo: {
        zIndex: 2,
        width: '25%',
        height: '25%',
    },
    portraitLogo: {
        bottom: 0,
        right: 15,
    },
    portraitLogoLive: {
        bottom: 8,
        right: 12,
    },
    landscapeLogo: {
        bottom: '14%',
    },
    landscapeLogoLive: {
        bottom: 21,
        right: -5,
    },
});

export default channelOverlayStyles;
