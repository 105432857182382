import { useState } from 'react';
import { DefaultCellModelProps, DefaultCellProps } from '../types';

const useViewModel = (props: DefaultCellModelProps): DefaultCellProps => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [newTimelinePosition, setNewTimelinePosition] = useState<number>(-1);
    const [elapsed, setElapsed] = useState<number>(-1);

    const handleFocus = (): void => {
        setIsFocused(true);
    };

    const handleBlur = (): void => {
        setIsFocused(false);
    };

    const setTimelinePosition = (position: number): void => {
        setNewTimelinePosition(position);
    };

    const setElapsedValue = (value: number): void => {
        setElapsed(value);
    };

    return {
        ...props,
        isFocused,
        newTimelinePosition,
        elapsed,
        handleFocus,
        handleBlur,
        setTimelinePosition,
        setElapsedValue,
    };
};

export { useViewModel };
