import { ReactNode } from 'react';
import { Subscription } from '@24i/nxg-sdk-photon';
import { WithWebHeaderProps } from '../../../../navigation/components/withWebHeader/types';
import { MySubscriptionsViewProps } from '../../types';
import { WithErrorModalProps } from '../../../../components/GenericModal/hoc/ErrorModal';

export enum Step {
    Current,
    AddSelection,
    AddConfirm,
    ChangeSelection,
    ChangeConfirm,
    Cancel,
    CancelSuccess,
    Success,
    UnableChange,
}

export interface MySubscriptionsContentProps
    extends MySubscriptionsViewProps,
        WithWebHeaderProps,
        Partial<WithErrorModalProps> {
    isCancelConfirmModalVisible: boolean;
    subscriptionToCancel?: Subscription;
    getCurrentStep: () => ReactNode;
    closeCancelSubscriptionConfirmModal: () => void;
    handleOnSubscriptionCancelConfirm: () => Promise<void>;
}
