import { OfferType } from '@24i/nxg-sdk-photon';

type BaseProps = {
    offerType: OfferType;
};

export type PaymentResultProps =
    | (BaseProps & {
          onContinueUrl: string;
          onCancelUrl?: never;
          onTryAgainUrl?: never;
      })
    | (BaseProps & {
          onCancelUrl: string;
          onTryAgainUrl: string;
          onContinueUrl?: never;
      });

export type PaymentOutcome = 'success' | 'failure';

type Nullify<T> = {
    [K in keyof T]: null;
};

export type NoOpProps = Pick<PaymentResultProps, 'offerType'> &
    Nullify<Pick<PaymentResultProps, 'onCancelUrl' | 'onContinueUrl' | 'onTryAgainUrl'>>;

export const areValidResultProps = (props: any): props is PaymentResultProps => {
    const isSuccess = 'onContinueUrl' in props;
    const isError = 'onCancelUrl' in props && 'onTryAgainUrl' in props;
    return 'offerType' in props && (isSuccess || isError);
};
