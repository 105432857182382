import React from 'react';
import { StyleSheet, Share as RNShare } from 'react-native';
import { Interactable } from '@24i/nxg-sdk-quarks';
import ReactDOM from 'react-dom';
import { Facebook, Twitter } from './shareButtons';

// eslint-disable-next-line import/no-mutable-exports
let ShareModule;

export const SHARE_WRAPPER_TEST_ID = 'shareWrapperTestID';
export const SHARE_ICON_TEST_ID = 'shareIconTestID';

const styles = StyleSheet.create({
    wrapper: {
        position: 'absolute',
        width: 500,
        height: 250,
        bottom: 0,
        marginLeft: '50%',
        transform: [{ translateX: '-50%' }],
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
});

function getWrapperElement() {
    const foundTarget = document.getElementById(SHARE_WRAPPER_TEST_ID);
    if (foundTarget) return foundTarget;

    const portalTarget = document.createElement('div');
    portalTarget.setAttribute('id', SHARE_WRAPPER_TEST_ID);
    document.body.appendChild(portalTarget);
    return portalTarget;
}

class Share {
    static dismissedAction = 'dismissedAction';

    static sharedAction = 'sharedAction';

    static share({ url = '', message = '', title = '', wrapperStyle = {} } = {}) {
        const resultPromise = new Promise((resolve) => {
            function onDismiss() {
                if (typeof document !== 'undefined') {
                    const foundTarget = document.getElementById(SHARE_WRAPPER_TEST_ID);
                    if (foundTarget) foundTarget.remove();
                    resolve({ action: Share.dismissedAction });
                }
            }
            function onShare() {
                if (typeof document !== 'undefined') {
                    const foundTarget = document.getElementById(SHARE_WRAPPER_TEST_ID);
                    if (foundTarget) foundTarget.remove();
                    resolve({ action: Share.sharedAction });
                }
            }

            const buttonProps = {
                url,
                message,
                title,
                onShare,
                testID: SHARE_ICON_TEST_ID,
            };

            const renderTarget = typeof document !== 'undefined' && getWrapperElement();
            ReactDOM.render(
                <Interactable
                    style={[StyleSheet.absoluteFillObject, { position: 'fixed' }]}
                    onPress={onDismiss}
                >
                    <Interactable
                        activeOpacity={1}
                        style={[styles.wrapper, wrapperStyle]}
                        testID={SHARE_WRAPPER_TEST_ID}
                    >
                        <Facebook {...buttonProps} />
                        <Twitter {...buttonProps} />
                    </Interactable>
                </Interactable>,
                renderTarget
            );
        });
        return resultPromise;
    }
}

if (typeof window !== 'undefined' && typeof window.navigator.share !== 'undefined') {
    ShareModule = RNShare;
} else {
    ShareModule = Share;
}

export { ShareModule as Share };
