import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Interactable, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Link } from '@24i/nxg-core-router';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import getSettingsScreenContentStyles from '../styles';

const SettingsScreenContent = ({
    router,
    styles: getStyles,
    options,
    customComponentBeforeOptions,
    customComponentAfterOptions,
    versionString,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const selectedOption = Object.values(options).find(
        (obj) =>
            router?.asPath === obj?.linkProps?.as ||
            obj?.title?.toUpperCase?.() === router.query?.type?.toUpperCase()
    );

    return (
        <View style={styles.pageContainer}>
            <View style={styles.scrollContainer}>
                <View style={styles.optionContainer}>
                    {Object.keys(options)?.length > 0 && !selectedOption && (
                        <>
                            {customComponentBeforeOptions}
                            {Object.values(options).map((item, index) => {
                                const Wrapper = item.onPress ? Interactable : Link;
                                return (
                                    !item?.disabled && (
                                        <Wrapper
                                            testID={`${item.title.toLowerCase()}_button`}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`${item.title}-${index}`}
                                            {...(item?.linkProps ? item.linkProps : {})}
                                            onPress={item?.onPress}
                                        >
                                            <View style={styles.optionButton}>
                                                <View style={styles.buttonContainer}>
                                                    {item?.icon && (
                                                        <ThemeIcon
                                                            iconName={item.icon.iconName}
                                                            size={40}
                                                            iconStyles={styles.optionIcon}
                                                        />
                                                    )}
                                                    <Text style={styles.subheaderText}>
                                                        {item.title}
                                                    </Text>
                                                </View>
                                            </View>
                                        </Wrapper>
                                    )
                                );
                            })}
                            {customComponentAfterOptions}
                        </>
                    )}
                </View>
                {selectedOption && selectedOption.renderDetail
                    ? selectedOption?.renderDetail({
                          selectedOption,
                          onBack: router.back,
                      })
                    : null}
            </View>
            {versionString ? (
                <Text style={{ color: theme.color.textPrimary }}>{versionString}</Text>
            ) : null}
        </View>
    );
};

SettingsScreenContent.propTypes = {
    router: PropTypes.shape({
        query: PropTypes.oneOfType([PropTypes.object]),
        back: PropTypes.func,
    }).isRequired,
    styles: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            disabled: PropTypes.bool,
            onPress: PropTypes.func,
            icon: { iconFont: PropTypes.string, iconName: PropTypes.string },
        })
    ),
    headerTitle: PropTypes.string,
    customHeader: PropTypes.node,
    onBack: PropTypes.func,
    customComponentBeforeOptions: PropTypes.node,
    customComponentAfterOptions: PropTypes.node,
    versionString: PropTypes.string,
};

SettingsScreenContent.defaultProps = {
    styles: getSettingsScreenContentStyles,
    theme: {},
    options: [],
    headerTitle: null,
    customHeader: null,
    onBack: null,
    customComponentBeforeOptions: null,
    customComponentAfterOptions: null,
    versionString: null,
};

export { getSettingsScreenContentStyles };
export default overridable(SettingsScreenContent, 'SettingsScreenContent');
