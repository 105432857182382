/*
@PRODUCT_FEATURE: G 3.0.0
*/
import View from './View';
import { IconButtonProps } from '../IconButton/types';

export { default as View, getBackButtonStyles } from './View';
export const useViewModel = (props: IconButtonProps): IconButtonProps => props;

export default View;
