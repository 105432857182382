import { createGuard, decodeModelWith, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { RECORDING_STATUS_GUARD, RECORDING_STATUS } from '../enums';

export const RecordingGuard = createGuard('Recording', {
    status: RECORDING_STATUS_GUARD,
    id: t.string,
    startTime: t.number,
    endTime: t.number,
    assetId: t.string,
    channelId: t.string,
    seriesId: nullable(t.string),
    expirationTime: t.number,
    profileId: nullable(t.string),
});

export type Recording = t.TypeOf<typeof RecordingGuard>;

export interface RecordingStatus {
    status?: RECORDING_STATUS;
    error?: string;
    loading?: boolean;
    seriesId?: string | null;
}

export const createRecording = (data: unknown): Recording | never => {
    return decodeModelWith(RecordingGuard, data, 'Recording');
};

export const isRecording = (data: unknown): data is Recording => {
    try {
        return !!decodeModelWith(RecordingGuard, data, 'Recording', { disableErrorLog: true });
    } catch {
        return false;
    }
};
