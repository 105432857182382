import React from 'react';
import { ActivityIndicator, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { LoadingWrapperProps } from '../types';

const overlayStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10000,
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
};

const LoadingWrapper = (props: LoadingWrapperProps): JSX.Element | null => {
    const { theme } = useTheme();
    const {
        children,
        shouldBeVisible = true,
        isLoading = false,
        loaderProps,
        wrapperStyle,
        wrapperTestId,
        placeholderStyle,
        shouldOverlay,
    } = props;

    if (!shouldBeVisible) return placeholderStyle ? <View style={placeholderStyle} /> : null;

    const getContent = () => {
        if (shouldOverlay) {
            return (
                <>
                    {isLoading && (
                        <View style={overlayStyle}>
                            <ActivityIndicator color={theme.color.textPrimary} {...loaderProps} />
                        </View>
                    )}
                    {children}
                </>
            );
        }

        if (isLoading) {
            return <ActivityIndicator color={theme.color.textPrimary} {...loaderProps} />;
        }
        return children;
    };

    return (
        <View style={wrapperStyle} testId={wrapperTestId}>
            {getContent()}
        </View>
    );
};

LoadingWrapper.displayName = 'LoadingWrapper';

export default LoadingWrapper;
