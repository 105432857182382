/* eslint-disable react/sort-comp */
import React from 'react';
import { View, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation, ParamListBase } from '@react-navigation/native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import CornerLogo from '@24i/nxg-sdk-gluons/src/components/ui/CornerLogo';
import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import { TopBarDynaRowProps } from '../../types';

export const CORNER_LOGO_DYNAROW_TEST_ID = 'corner-logo-dynarow';

const styles = StyleSheet.create({
    topGradient: {
        height: '150%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
});

const TopBarDynaRow = ({ headerTransparent }: TopBarDynaRowProps) => {
    const { theme } = useTheme();
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const topGradient = theme.color.gradient3.colors;
    return (
        <View>
            <LinearGradient colors={topGradient} style={styles.topGradient} angle={0} useAngle />
            <TopBarPrimary
                startsTransparent={headerTransparent}
                navigation={navigation}
                buttonLeft={<CornerLogo testID={CORNER_LOGO_DYNAROW_TEST_ID} />}
            />
        </View>
    );
};

export default TopBarDynaRow;
