import { Theme } from '@24i/nxg-sdk-photon';
import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { TextStyle, ViewStyle, Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

export interface PurchaseItemStyles {
    wrapper: ViewStyle;
    contentWrapper: ViewStyle;
    nameWrapper: ViewStyle;
    name: TextStyle;
    priceWrapper: ViewStyle;
    price: TextStyle;
    description: TextStyle;
    iconWrapper: ViewStyle;
}

export const PURCHASE_ITEM_HEIGHT = 112;
export const PURCHASE_ITEM_MAX_WIDTH = 560;

// this is a workaround for ConfirmPurchase Modal.
// We cannot align wrapped items without static width
export const PURCHASE_ITEM_WIDTH =
    width > PURCHASE_ITEM_MAX_WIDTH ? PURCHASE_ITEM_MAX_WIDTH : '100%';

const getStyles = (theme: Theme): PurchaseItemStyles => ({
    wrapper: {
        backgroundColor: theme.color.lighter4,
        borderColor: theme.color.labelPrimary,
        borderWidth: 1,
        minHeight: PURCHASE_ITEM_HEIGHT,
        padding: 16,
        width: PURCHASE_ITEM_WIDTH,
        display: 'flex',
        flexDirection: 'row',
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    contentWrapper: {
        flex: 1,
    },
    nameWrapper: {
        marginBottom: 8,
    },
    name: {
        ...getFont(theme, 'h4'),
        color: theme.color.textPrimary,
    },
    priceWrapper: {
        marginBottom: 8,
    },
    price: {
        ...getFont(theme, 'h5'),
        color: theme.color.contrast2,
    },
    description: {
        ...getFont(theme, 'body2'),
        color: theme.color.textPrimary,
        opacity: 0.6,
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 8,
    },
});

export default getStyles;
