/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';

import View from './View';
import { useViewModel } from './viewModel';
import * as prerenderFunctions from '../../pages/health';
import { NextHealthScreenProps } from './types';

export default {
    Main: (props: NextHealthScreenProps): ReactElement => {
        const viewModelProps = useViewModel(props);
        return <View {...viewModelProps} />;
    },
    View,
    useViewModel,
    page: {
        health: prerenderFunctions,
    },
};
