import { Asset } from '@24i/nxg-sdk-photon/src';

export const getInitialProgress = (asset: Asset, episodeToWatch?: Asset | null): number => {
    if (episodeToWatch) {
        return (episodeToWatch.continueWatchingOffset || 0) / (episodeToWatch.duration || 1);
    }

    if (asset.progress) return asset.progress;

    return (asset.continueWatchingOffset || 0) / (asset.duration || 1);
};
