import React from 'react';
import PropTypes from 'prop-types';
import { View, ActivityIndicator } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import getLoaderStyles from '../styles';

const Loader = ({ styles: getStyles, additionalStyles, size, color }) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    if (!isPlatformWeb) {
        return (
            <View
                activeOpacity={1}
                style={[{ flex: 1, zIndex: 9999 }, styles.container, additionalStyles]}
            >
                <ActivityIndicator size={size} color={color} testID="loader-indicator" />
            </View>
        );
    }
    return (
        <View style={[styles.webIndicatorContainer, additionalStyles]}>
            <ActivityIndicator size={size} color={color} testID="loader-indicator" />
        </View>
    );
};

Loader.propTypes = {
    text: PropTypes.string,
    styles: PropTypes.func,
    additionalStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    additionalImageBackgroundStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    disableImageBackground: PropTypes.bool,
};

Loader.defaultProps = {
    styles: getLoaderStyles,
    additionalStyles: {},
    additionalImageBackgroundStyles: {},
    size: 'large',
    color: '#FFFFFF',
    disableImageBackground: false,
};

export { getLoaderStyles };
export default overridable(Loader, 'Loader');
