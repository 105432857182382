import {
    DeleteProfileResolutionStep,
    Profile,
    ProfileImage,
    isUndeletableProfile,
} from '@24i/nxg-sdk-photon';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useSwitchProfile } from '@24i/nxg-sdk-smartott/src/hooks/useSwitchProfile';
import { useEffect } from 'react';
import { useProfilePictureHelpers } from './useProfilePictureHelpers';

interface UseDeleteProfileProps {
    profileImages: ProfileImage[];
}

export const useDeleteProfile = ({ profileImages }: UseDeleteProfileProps) => {
    const userDataClient = useUserData();
    const { profiles, setProfiles } = useStore();
    const { findProfileImage } = useProfilePictureHelpers();
    const { switchProfileAsync } = useSwitchProfile();

    const updateLocalProfilesState = (toUpdate: { newDefault: Profile; oldDefault: Profile }) => {
        if (!profiles) {
            throw new Error(
                `The profiles data were not found in the application store! Please fetch the profile data first!`
            );
        }

        const newDefaultProfileIndex = profiles.findIndex(
            (profile) => profile.id === toUpdate.newDefault.id
        );
        const oldDefaultIndex = profiles.findIndex(
            (profile) => profile.id === toUpdate.oldDefault.id
        );

        if (newDefaultProfileIndex < 0 || oldDefaultIndex < 0) {
            throw new Error(`One of the profiles was not found in the current profiles array!`);
        }

        const newProfiles = [...profiles]
            .splice(newDefaultProfileIndex, 1, toUpdate.newDefault)
            .splice(oldDefaultIndex, 1, {
                ...toUpdate.oldDefault,
                defaultProfile: false,
            });
        setProfiles(newProfiles);
    };

    const updateNewDefaultProfile = async (newDefaultProfile: Profile) => {
        const currentDefaultProfile = profiles?.find((profile) => profile.defaultProfile);
        if (!currentDefaultProfile) {
            throw new Error(`Could not find any default profile to update!`);
        }

        // A "PATCH" method for the profile would be nice, so we don't have to reset all properties and do the following:
        const profileImage = findProfileImage(newDefaultProfile.image || '', profileImages);
        if (!profileImage) {
            throw new Error(
                `Could not update profile. The image was not found in the available images!`
            );
        }
        const newDefaultProfileUpdated = await userDataClient.updateProfile(newDefaultProfile.id, {
            ...newDefaultProfile,
            age: newDefaultProfile.age ?? null,
            name: newDefaultProfile.name || '__undefined__',
            imageId: profileImage.id,
            defaultProfile: true,
        });

        updateLocalProfilesState({
            newDefault: newDefaultProfileUpdated,
            oldDefault: currentDefaultProfile,
        });

        return newDefaultProfileUpdated;
    };

    const tryToResolveDeletion = async (resolutionSteps: DeleteProfileResolutionStep[]) => {
        return resolutionSteps.reduce((promiseChain, step) => {
            return promiseChain.then(async () => {
                if (step.action === 'SELECT_NEW_DEFAULT') {
                    await updateNewDefaultProfile(step.profile);
                }

                if (step.action === 'SWITCH_TO_PROFILE') {
                    await switchProfileAsync(step.profile);
                }
            });
        }, Promise.resolve());
    };

    const deleteProfile = async (id: string) => {
        const canDeleteResult = await userDataClient.canDeleteProfile(id, {
            profiles: profiles ?? [],
        });

        if (isUndeletableProfile(canDeleteResult)) {
            if (!canDeleteResult.canDelete && canDeleteResult.resolutionSteps.length) {
                await tryToResolveDeletion(canDeleteResult.resolutionSteps);
            } else if (!canDeleteResult.canDelete) {
                throw canDeleteResult.cause;
            }
        }

        await userDataClient.deleteProfile(id);
    };

    // TODO: This should probably not be handled ad-hoc
    // TODO: After every profile change the data should be refreshed
    // TODO: This is a performance issue
    useEffect(() => {
        if (!profiles || !profiles.length) return;

        // We always need to know the latest profile and selected profile data
        const refetchProfilesAndSetSelectedProfile = async () => {
            const upToDateProfiles = await userDataClient.fetchUserProfiles();

            setProfiles(upToDateProfiles);
        };

        refetchProfilesAndSetSelectedProfile();
    }, []);

    return { deleteProfile };
};
