import { useTranslation } from 'react-i18next';
import { usePaymentMethodScreen } from '../../context/SubscriptionsHandlerProvider';

export default {
    Main: () => {
        const { Main } = usePaymentMethodScreen();
        const { t } = useTranslation();

        return Main({ webHeaderTitle: t('account.paymentMethod.title') });
    },

    View: () => {
        const { View } = usePaymentMethodScreen();
        return View;
    },
};
