import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const UserCodeDef = t.string;

export const UserCodeGuard = createGuard('UserCode', UserCodeDef);

export const UserCodeResponseDefRequired = {
    code: UserCodeDef,
    url: t.string,
};

export const UserCodeResponseDefPartial = {
    urlComplete: nullable(t.string),
};

export const UserCodeResponseGuard = createGuard(
    'UserCodeResponse',
    UserCodeResponseDefRequired,
    UserCodeResponseDefPartial
);

export type UserCode = t.TypeOf<typeof UserCodeGuard>;
export type UserCodeResponse = t.TypeOf<typeof UserCodeResponseGuard>;
