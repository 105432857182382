import React from 'react';
import { TopBarMenuDropdown } from '../../TopBarMenu';
import { GuestDropdownViewProps } from './types';

export const GuestDropdown = (props: GuestDropdownViewProps) => {
    const { dropdownItems, handleItemClick, menuItem } = props;

    return (
        <TopBarMenuDropdown
            {...menuItem}
            title={menuItem.menuTitle}
            icon={menuItem.icon ?? ''}
            items={dropdownItems}
            onListItemPress={handleItemClick}
        />
    );
};

export default GuestDropdown;
