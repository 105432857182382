import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { View, Interactable, Text } from '@24i/nxg-sdk-quarks';
import { useTheme, getFont } from '@24i/nxg-sdk-higgs';
import { Link } from '@24i/nxg-core-router';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';

import PickerItem from '../components/PickerItem';
import getModalPickerStyles from '../styles';

const ModalPicker = ({
    options,
    selectedValue,
    color,
    additionalContainerStyles,
    additionalTextStyles,
    optionsContainerStyles,
    viewContainerStyles,
    buttonRef,
    isDropdown,
    pickerLink,
    styles: getStyles,
    isModalOnOpen = true,
    ...props
}) => {
    const { setModalChildren, closeModal } = useModal();
    const { theme } = useTheme();
    const [isOptionsBoxOpen, setIsOptionsBoxOpen] = useState(false);

    const styles = optionsContainerStyles ?? getStyles(theme);

    const onValueChange = (value) => {
        if (isModalOnOpen) {
            closeModal();
        } else {
            setIsOptionsBoxOpen(false);
        }
        props.onValueChange(options.indexOf(value));
    };

    const OptionsModal = () => (
        <View style={styles.container}>
            {options.map((option, i) => {
                const isSelected = option === selectedValue;
                const content = (
                    <Interactable
                        key={`_pickerOption_${option}`}
                        title={option}
                        onPress={() => onValueChange(option)}
                        style={styles.itemContainer ?? {}}
                        testID={`season_${i + 1}`}
                    >
                        <Text style={[isSelected ? styles.selectedItem : styles.item]}>
                            {option}
                        </Text>
                    </Interactable>
                );
                return pickerLink ? (
                    <Link
                        key={`_pickerOption_${option}`}
                        href={pickerLink && pickerLink.href}
                        as={pickerLink && pickerLink.as + option}
                    >
                        <a>{content}</a>
                    </Link>
                ) : (
                    content
                );
            })}
        </View>
    );

    return (
        <View testID="season_picker" style={viewContainerStyles || styles.viewContainer}>
            <PickerItem
                onPress={() =>
                    isModalOnOpen
                        ? setModalChildren(OptionsModal)
                        : setIsOptionsBoxOpen(!isOptionsBoxOpen)
                }
                buttonRef={buttonRef}
                title={selectedValue}
                style={{
                    color: color || theme?.color?.textPrimary,
                    textAlign: 'left',
                    ...getFont(theme, 'h2'),
                }}
                color={color || theme?.color?.textPrimary}
                additionalContainerStyles={additionalContainerStyles}
                additionalTextStyles={additionalTextStyles}
                isDropdown={isDropdown}
            />
            {isOptionsBoxOpen && <OptionsModal />}
        </View>
    );
};

ModalPicker.defaultProps = {
    selectedValue: '',
    options: [],
    color: '#fff',
    additionalContainerStyles: {},
    additionalTextStyles: {},
    buttonRef: () => {},
    isDropdown: true,
    styles: getModalPickerStyles,
};

ModalPicker.propTypes = {
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.string,
    additionalContainerStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    additionalTextStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    buttonRef: PropTypes.func,
    styles: PropTypes.func,
};

export { getModalPickerStyles };
export default overridable(ModalPicker, 'ModalPicker');
