import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { BlockedModalStyles } from './types';

const getBlockedModalStyles = (theme: Theme): BlockedModalStyles => ({
    container: {
        padding: 20,
        backgroundColor: theme.color.primary1,
        alignSelf: 'center',
        alignItems: 'flex-start',
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    title: {
        color: theme.color.textPrimary,
        marginBottom: 16,
        textAlign: 'left',
        marginLeft: 24,
        marginRight: 10,
        ...getFont(theme, 'h3'),
    },
    subtitle: {
        color: theme.color.textPrimary,
        textAlign: 'left',
        marginLeft: 24,
        marginRight: 30,
        alignSelf: 'flex-start',
        opacity: 0.6,
        ...getFont(theme, 'body2'),
    },
    buttonsContainer: {
        alignSelf: 'flex-end',
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
        marginTop: 32,
    },
    actionButtonContainer: {
        alignSelf: 'center',
        backgroundColor: theme.color.buttonPrimary,
    },
    closeButtonContainer: {
        marginRight: 16,
    },
    actionButtonText: {
        color: theme.color.textButtonPrimary,
    },
});

export default getBlockedModalStyles;
