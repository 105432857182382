import React from 'react';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { NavScreenConfig } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { useMenuItemPress } from '../../../../../navigation/utils';
import { TopBarMenuItem } from '../../../../MenuItem';
import getTextLinkStyles from '../styles';
import { TextLinkStylesGetter } from '../styles/types';
import { Link } from '../../../types';

interface TextLinkProps {
    link: Link | NavScreenConfig;
    index: number;
    styles?: TextLinkStylesGetter;
}

const TextLink = ({ link, index, styles: getStyles = getTextLinkStyles }: TextLinkProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const onPress = useMenuItemPress();

    const isLink = (link as Link)?.href;

    return (
        <View style={styles.item}>
            {isLink ? (
                <a
                    key={`${link}${index}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={(link as Link)?.href}
                >
                    <Text style={styles.title}>{(link as Link).title}</Text>
                </a>
            ) : (
                <TopBarMenuItem
                    key={`${link}${index}`}
                    title={(link as Link)?.title}
                    index={index}
                    onPress={() => onPress(link as NavScreenConfig)}
                    styles={() => styles}
                    breakText
                />
            )}
        </View>
    );
};

export { getTextLinkStyles };
export default TextLink;
