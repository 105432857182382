import { analytics, A } from '@24i/appstage-shared-analytics';
import { AFunction, AnalyticsEventsMap } from '@24i/appstage-shared-analytics/dist/interface';
import { Storage } from '@24i/nxg-sdk-quantum';
import {
    ASYNC_STORAGE_KEY_IS_FIRST_TIME_LOADED,
    ASYNC_STORAGE_KEY_LAST_PURCHASE,
} from '@24i/nxg-core-utils/src/constants';
import { AnyPayload } from '@24i/nxg-sdk-photon/src/clients/AnalyticsClient';
import { getDeviceInfo } from '../utils';
import { ANALYTICS_TRIGGERS, ANALYTICS_EVENTS } from '../constants';
import { UserInfo } from '../types';

const TRUE = 'true';
const purchaseTriggers = [
    ANALYTICS_TRIGGERS.PURCHASE_START,
    ANALYTICS_TRIGGERS.PURCHASE_CANCEL,
    ANALYTICS_TRIGGERS.PURCHASE_COMPLETE,
];

let skipAnalytics = false;
let sentAnalytic = '';

export const onPurchaseAnalytics = async (
    trigger: ANALYTICS_TRIGGERS,
    payload: AnyPayload
): Promise<void> => {
    if (skipAnalytics) {
        skipAnalytics = false;
        return;
    }
    let a: AFunction;
    if (
        [ANALYTICS_TRIGGERS.PURCHASE_CANCEL, ANALYTICS_TRIGGERS.PURCHASE_COMPLETE].includes(trigger)
    ) {
        const purchasePayload = await Storage.getItem(ASYNC_STORAGE_KEY_LAST_PURCHASE);
        if (!purchasePayload) return;
        const finalPayload = JSON.parse(purchasePayload);
        a = A.of({ trigger, payload: finalPayload });
        await Storage.removeItem(ASYNC_STORAGE_KEY_LAST_PURCHASE);
    } else {
        a = A.of({ trigger, payload });
        await Storage.setItem(ASYNC_STORAGE_KEY_LAST_PURCHASE, JSON.stringify(payload));
    }
    a(trigger as keyof AnalyticsEventsMap);
};

export const onAnalytics = (trigger: ANALYTICS_TRIGGERS, payload: AnyPayload): void => {
    const finalPayload = { ...payload, timestamp: Date.now() };
    if (purchaseTriggers.includes(trigger)) {
        onPurchaseAnalytics(trigger, { payload: finalPayload });
        return;
    }
    const a = A.of({ trigger, payload: finalPayload });
    a(trigger as keyof AnalyticsEventsMap);
};

export const skipNextPurchaseAnalytics = (): void => {
    skipAnalytics = true;
};

export const generalTriggers = async (): Promise<void> => {
    const wasAppLoadedBefore = await Storage.getItem(ASYNC_STORAGE_KEY_IS_FIRST_TIME_LOADED);
    if (!wasAppLoadedBefore) {
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, { event: ANALYTICS_EVENTS.INSTALLED_APP });
        await Storage.setItem(ASYNC_STORAGE_KEY_IS_FIRST_TIME_LOADED, TRUE);
    }
    if (sentAnalytic !== ANALYTICS_EVENTS.LOADED_APP) {
        onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, { event: ANALYTICS_EVENTS.LOADED_APP });
        sentAnalytic = ANALYTICS_EVENTS.LOADED_APP;
    }
};

export const setAppInfo = async (): Promise<void> => {
    analytics.setAppInfo(await getDeviceInfo());
};

export const setUserInfo = (userInfo: UserInfo | null): void | undefined => {
    if (!userInfo) {
        analytics.setUserInfo({ userId: undefined, userProfileId: undefined });
        return;
    }
    const { userId, userProfileId } = userInfo;
    if (userId) {
        analytics.setUserInfo({ userId });
    } else if (userProfileId) {
        analytics.setUserInfo({ userProfileId });
    } else {
        analytics.setUserInfo({ userId: undefined, userProfileId: undefined });
    }
};

export const loadAnalytics = async (userId?: string): Promise<void> => {
    await setAppInfo();
    if (userId) {
        setUserInfo({ userId });
    }
    await generalTriggers();
};
