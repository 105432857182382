import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { log } from '@24i/nxg-core-utils/src/logger';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { Storage } from '@24i/nxg-sdk-quantum';
import { ASYNC_STORAGE_KEY_MOCK_DEVICES } from '@24i/nxg-core-utils/src/constants';
import {
    onAnalytics,
    setUserInfo,
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useStartPageNavigation } from '../../context/AppStartContext/hooks';

export interface UseUserSessionProps {
    isLoading: boolean;
    signInUser: (email: string, password: string) => Promise<{ login: boolean; message?: string }>;
    signOutUser: () => Promise<void>;
}

const useUserSession = (): UseUserSessionProps => {
    const { login } = useStartPageNavigation();
    const userDataClient = useUserData();
    const { setUserData, setSelectedUserProfile, setProfilesAndPossiblySelectOne } = useStore();
    const { recordError } = useFirebase();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const signInUser = async (
        email: string,
        password: string
    ): Promise<{ login: boolean; message?: string }> => {
        setIsLoading(true);
        try {
            await userDataClient.login(email, password);
            const user = await userDataClient.fetchUser();
            if (user) {
                const profiles = await userDataClient.fetchUserProfiles();
                setProfilesAndPossiblySelectOne(profiles);
                setUserInfo({ userId: user.id as string });
                onAnalytics(ANALYTICS_TRIGGERS.LOGIN, { event: ANALYTICS_EVENTS.LOGGED_IN });
            }

            setUserData(user);
            setIsLoading(false);
            await login();
            return { login: true };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            recordError(error);
            log(error);
            setIsLoading(false);
            if (error.error || error instanceof Error) {
                if (error.error === 'BAD_CREDENTIALS') {
                    error.message = t('error.E00.body');
                }
                return { login: false, message: error.message };
            }
            return { login: false, message: JSON.stringify(error) };
        }
    };

    const signOutUser = async () => {
        setIsLoading(true);

        try {
            await Storage.removeItem(ASYNC_STORAGE_KEY_MOCK_DEVICES); // TODO - Remove when mocked behaviour not necessary anymore
            await userDataClient.logout();
            setUserData(null);
            setSelectedUserProfile(null);
        } catch (error) {
            recordError(error);
            log(error);
            /* eslint-disable no-alert */
            alert(JSON.stringify(error) || t('error.A00.title'));
        } finally {
            setIsLoading(false);
        }
    };

    return { isLoading, signInUser, signOutUser };
};

export default useUserSession;
