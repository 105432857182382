// TODO: need to make it focusable for TVs because drawer doesn't work properly when only this single component is rendered in the screen
import React from 'react';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import getErrorMessageStyles from '../styles';
import { ErrorMessageProps } from '../types';

const ErrorMessage = ({
    styles: getStyles = getErrorMessageStyles,
    message,
    color,
}: ErrorMessageProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.container}>
            <Text style={[styles.messageText, color ? { color } : undefined]}>{message}</Text>
        </View>
    );
};

export default overridable(ErrorMessage, 'ErrorMessage');
