/* eslint-disable no-param-reassign */
import { useCallback } from 'react';
import { PlaybackMilestoneReached } from '@24i/nxg-sdk-photon/src/analytics/player';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_PREFIXES,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { BuildPlaybackEventFunction } from './buildPlaybackEvent';
import { PlaybackState } from './playbackState';

export const useTriggerOnPlaybackMilestone = (
    buildPlaybackEvent: BuildPlaybackEventFunction,
    milestones: Array<PlaybackMilestoneReached['value']> = [10, 25, 50, 75, 90]
) => {
    const { triggerMonitoringEvent } = useMonitoring();

    const triggerOnPlaybackMilestone = useCallback((playbackState?: PlaybackState): void => {
        if (!playbackState) return;
        const { currentTime = 0, duration = 0, lastMilestone = 0 } = playbackState;
        if (currentTime < 0 || duration <= 0) return;
        const percent = (currentTime / duration) * 100;
        let reachedMilestone = 0;
        milestones.forEach((milestone) => {
            if (milestone <= percent) reachedMilestone = milestone;
        });
        if (reachedMilestone <= lastMilestone) return;
        playbackState.lastMilestone = reachedMilestone;
        const event = buildPlaybackEvent('PLAYBACK_MILESTONE_REACHED', playbackState);
        if (!event) return;
        onAnalytics(ANALYTICS_TRIGGERS[`${ANALYTICS_PREFIXES.PLAYBACK}${reachedMilestone}`], event);
        triggerMonitoringEvent({
            ...event,
            value: reachedMilestone,
        } as PlaybackMilestoneReached);
    }, []);

    return triggerOnPlaybackMilestone;
};
