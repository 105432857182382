import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, SafeAreaView, View, NavigationIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import { isPlatformWeb } from 'renative';
import getUnavailableSubscriptionsStyles from './styles';
import { SubscriptionsUnavailableProps } from './types';

const SubscriptionsUnavailable = ({
    onBackPress,
    styles: getStyles = getUnavailableSubscriptionsStyles,
}: SubscriptionsUnavailableProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation();
    const iconSize = isPlatformWeb ? 80 : 72;

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.wrapper}>
                <View style={styles.icon}>
                    <NavigationIcon iconName="subscription" size={iconSize} />
                </View>
                <Text style={styles.title}>{t('svod.add.noData.title')}</Text>
                <Text style={styles.description}>{t('svod.add.noData.description')}</Text>
                <SubactionButton
                    onPress={onBackPress}
                    title={t('common.back')}
                    additionalContainerStyles={{ marginHorizontal: 0, ...styles.backButtonWrapper }}
                    additionalTextStyles={styles.backButtonText}
                />
            </View>
        </SafeAreaView>
    );
};

export default SubscriptionsUnavailable;
