import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text, Interactable } from '@24i/nxg-sdk-quarks';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import useDimensions from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions';

import { Breakpoint } from '../../../../../utils/styles/constants';
import { DeleteAccountModalProps, DELETE_ACCCOUNT_MODAL_TEST_ID } from '../types';
import getDeleteAccountStyles from '../styles';
import LoadingWrapper from '../../../../../components/LoadingWrapper';

const DeleteAccountModal = ({
    isVisible,
    isLoading,
    onConfirmDeleteAccount,
    onCloseModal,
    styles: getStyles = getDeleteAccountStyles,
}: DeleteAccountModalProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation();
    const { viewPortWidth = 0 } = useDimensions();
    return (
        <Modal
            visible={isVisible}
            onRequestClose={onCloseModal}
            transparent={viewPortWidth > Breakpoint.MD}
        >
            <View style={styles.main}>
                <View style={styles.contentContainer}>
                    <Text style={styles.title}>{t('account.delete.deleteConfirm.title')}</Text>
                    <Text style={styles.description}>
                        {t('account.delete.deleteConfirm.description')}
                    </Text>
                    <LoadingWrapper
                        isLoading={!!isLoading}
                        wrapperStyle={{
                            ...styles.buttonContainer,
                            ...(isLoading && styles.loadingContainer),
                        }}
                    >
                        <Interactable
                            testID={DELETE_ACCCOUNT_MODAL_TEST_ID.CONFIRM_BUTTON}
                            onPress={onConfirmDeleteAccount}
                            style={styles.confirmButton}
                        >
                            <Text style={styles.confirmButtonText} numberOfLines={1}>
                                {t('account.delete.deleteButton')}
                            </Text>
                        </Interactable>
                        <Interactable
                            testID={DELETE_ACCCOUNT_MODAL_TEST_ID.CANCEL_BUTTON}
                            onPress={onCloseModal}
                            style={styles.cancelButton}
                        >
                            <Text style={styles.cancelButtonText} numberOfLines={1}>
                                {t('common.cancel')}
                            </Text>
                        </Interactable>
                    </LoadingWrapper>
                </View>
            </View>
        </Modal>
    );
};

export default DeleteAccountModal;
