import React from 'react';
import getPinInputStyles from './styles';
import { PinInputModalContentProps } from './types';
import View from './View';
import { useViewModel } from './viewModel';

const Main = (props: PinInputModalContentProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
export { getPinInputStyles, View };
