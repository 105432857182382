import {
    AppNavigationClient,
    NavConfigWeb,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { sottDefaultConfigBuilderWeb } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/navConfigBuilder/web/index.web';
import { SottMenuConfig } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/navConfigBuilder/types';
import { buildScreenConfsFromMenu } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/dynamicMenu';
import { SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/screenConfigBuilders';
import { getSottScreensAndStructureWeb } from '@24i/nxg-sdk-smartott/src/navigation/navigationConfig/staticScreensAndStructure/index.web';
import { SottDefaultNavClientGetter } from './types';

export const createSottNavigationClient: SottDefaultNavClientGetter = (
    // These are here for convenience currently, but we can remove them in favor of creating custom client if needed
    screenBuilders = SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS
): AppNavigationClient<SottMenuConfig> => ({
    getNavigationConfig: (menu, menuConfig, context): NavConfigWeb => {
        const parsedMenu = buildScreenConfsFromMenu(menu, screenBuilders, context);

        const { topBarMenu, moreScreen, bottomBarMenu, allNavigationItems, footer } =
            getSottScreensAndStructureWeb(parsedMenu, screenBuilders, context);

        // Secondly, pass parsed items to navigationConfigBuilder which build the final navigationConfig object
        return sottDefaultConfigBuilderWeb(
            {
                footer,
                moreScreen,
                topBarMenu,
                bottomBarMenu,
                allNavigationItems,
            },
            { menuConfig, t: context.t }
        );
    },
});
