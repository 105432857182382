import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS, Broadcast } from '@24i/nxg-sdk-photon/src';

import { useRecordings } from '@24i/nxg-sdk-smartott-shared/src/context/Recordings';

export function useCancelRecordingsForSeries(
    handleError?: (err: unknown) => void
): UseMutateFunction<void, unknown, Broadcast, unknown> {
    const { cancelRecordingsForSeries } = useRecordings();
    const queryClient = useQueryClient();

    const { mutate } = useMutation(cancelRecordingsForSeries, {
        onSuccess: (error, asset) => {
            queryClient.invalidateQueries([QUERY_KEYS.recordings, asset.id]);
            queryClient.invalidateQueries(QUERY_KEYS.recordings);
            queryClient.invalidateQueries(QUERY_KEYS.storageInfo);
        },
        onError: (err) => handleError && handleError(err),
    });

    return mutate;
}
