import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

const textPageChildContent = createGuard('textPageChildContent', {
    title: t.string,
    text: t.string,
});

export const textPageChild = createGuard('textPageChild', {
    title: t.string,
    content: nullable(textPageChildContent),
});

export const textPageContentGuard = createGuard('TextPageContent', {
    id: t.string,
    title: t.string,
    children: t.array(textPageChild),
    createdAt: t.number,
    updatedAt: t.number,
});

export type TextPageContent = t.TypeOf<typeof textPageContentGuard>;

export type TextPageChildContent = t.TypeOf<typeof textPageChild>;
