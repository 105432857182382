import React from 'react';
import { Modal, View } from '@24i/nxg-sdk-quarks';
import PropTypes from 'prop-types';
import { DROPDOWN_MENU_TEST_ID } from '../constants';

const ModalWrapper = ({ children, menuContainerStyle }) => (
    <Modal testID={DROPDOWN_MENU_TEST_ID} visible animationType="slide" transparent>
        <View
            style={[
                { position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 },
                menuContainerStyle,
            ]}
            testID={DROPDOWN_MENU_TEST_ID}
        >
            {children}
        </View>
    </Modal>
);

ModalWrapper.defaultProps = {
    children: null,
    menuContainerStyle: null,
};

ModalWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    menuContainerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]),
};

export default ModalWrapper;
