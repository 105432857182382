import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { usePinProtectedScreen } from '@24i/nxg-sdk-pin-protection/src/hooks/usePinProtectedScreen';
import {
    ChangeableItemType,
    ChangeableTextItemProps,
} from '@24i/nxg-sdk-smartott/src/components/ChangeableText/types';
import { useUpdateAccountPin } from '@24i/nxg-sdk-smartott/src/hooks/query/user';

import { DeviceInfo } from '@24i/nxg-sdk-quantum';

import { isPlatformWeb } from 'renative';

import { ACCOUNT_DETAIL_KEY } from '../../AccountDetails/types';
import { ParentalControlPinProps } from '../types';
import usePaymentsPinProtection from '../../../hooks/usePaymentsPinProtection';

const useViewModel = (props): ParentalControlPinProps => {
    usePinProtectedScreen(props);

    const { isActive, isToggleAvailable, onSetPaymentsPinProtection } = usePaymentsPinProtection();
    const updateAccountPin = useUpdateAccountPin();
    const { t } = useTranslation();

    const [itemToChange, setItemToChange] = useState<ChangeableTextItemProps>();
    const [isModalOpened, setIsModalOpened] = useState(false);

    const openModal = (): void => {
        setIsModalOpened(true);
    };

    const closeModal = (): void => {
        setIsModalOpened(false);
    };

    const handleModalOpen = (item: ChangeableTextItemProps): void => {
        setItemToChange(item);
        openModal();
    };

    const confirmUpdatePin = (inputPin: string) => {
        updateAccountPin(inputPin, {
            onSuccess: closeModal,
            onError: closeModal,
        });
    };

    const items: ChangeableTextItemProps[] = [
        {
            title: t('pin.manage.title'),
            value: DeviceInfo.isTablet() && !isPlatformWeb ? '●●●●' : '••••',
            confirmationHandler: confirmUpdatePin,
            buttonText: t('common.change') as string,
            itemKey: ACCOUNT_DETAIL_KEY.PIN_CHANGE,
            type: ChangeableItemType.PIN,
        },
    ];

    return {
        shouldShowPinToggle: isToggleAvailable,
        pinToggleSettings: {
            defaultValue: isActive,
            title: t('pin.manage.purchasePin.title'),
            description: t('pin.manage.purchasePin.description'),
            withDivider: true,
            onValueChange: onSetPaymentsPinProtection,
        },
        items,
        itemToChange,
        isModalOpened,
        closeModal,
        handleModalOpen,
    };
};

export { useViewModel };
