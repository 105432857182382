import { log } from '@24i/nxg-core-utils/src/logger';
import { PagesResponseGuard } from './guards';
import { mapPageSection } from './mappers';
import { SharedContentDataClient } from './shared';

export class ContentDataClient extends SharedContentDataClient {
    async fetchPage(pageId: string) {
        try {
            return await this.request({
                path: `/pages/${pageId}`,
                method: 'GET',
                query: {
                    includeItems: false,
                },
                guard: PagesResponseGuard,
            }).then((response) => response?.map(mapPageSection) || []);
        } catch (err) {
            log('ERROR FETCHING PAGE', err);

            throw err;
        }
    }
}
