import React from 'react';

import FirebaseContext, { mockContext } from './context';

export { default as FirebaseProvider } from './provider';

// In case the app is not wrapped in FirebaseProvider mockContext needs to be returned
export const useFirebase = () => {
    const contextValues = React.useContext(FirebaseContext);
    if (contextValues) return contextValues;
    return mockContext;
};

// In case the app is not wrapped in FirebaseProvider mockContext needs to be returned
export const withFirebase = (Component) => (props) =>
    (
        <FirebaseContext.Consumer>
            {(firebaseContext) =>
                firebaseContext ? (
                    <Component {...props} {...firebaseContext} />
                ) : (
                    <Component {...props} {...mockContext} />
                )
            }
        </FirebaseContext.Consumer>
    );

export { FirebaseContext };
