import React, { useState } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import { createRating, Rating, RATING_SYSTEM } from '@24i/nxg-sdk-photon/src';
import getRatingSystemStyles from './styles';
import { RatingSystemComponenteProps } from './types';
import { RATING_MODAL_TEST_IDS } from '../../test-utils';
import StarSystem from '../StarSystem';

const RatingSystemComponent = (props: RatingSystemComponenteProps) => {
    const { rating: assetRating, onRate, onRemove } = props;
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getRatingSystemStyles(theme);

    const [userRating, setUserRating] = useState<Rating | undefined>(assetRating);
    const [isRatingDisabled, setRatingDisabled] = useState<boolean>(userRating === undefined);

    const onStarIconPress = (value: number): void => {
        if (userRating) {
            setUserRating(
                value === userRating.rating
                    ? undefined
                    : createRating({ ...userRating, rating: value })
            );
        } else {
            setUserRating(createRating({ rating: value, ratingSystem: RATING_SYSTEM.FIVESTARS }));
        }
        setRatingDisabled(userRating?.rating === value);
    };

    const renderRatingSystem = (rating?: Rating): JSX.Element => {
        switch (rating?.ratingSystem) {
            case RATING_SYSTEM.FOURSTARS:
            case RATING_SYSTEM.THUMBS:
            case RATING_SYSTEM.ONEHUNDRED:
            case RATING_SYSTEM.ONETEN:
                return <></>; // TODO: - Remainder of systems
            default:
                // 5stars will be default
                return <StarSystem withNumber={5} rating={rating} onStarPress={onStarIconPress} />;
        }
    };

    return (
        <View style={styles.container}>
            {renderRatingSystem(userRating)}
            <ActionButton
                testID={RATING_MODAL_TEST_IDS.RATE_BUTTON}
                disableATVonPressLimiter
                title={t('asset.rate.addButton')}
                additionalContainerStyles={{
                    ...styles.rateButton,
                    ...{ opacity: isRatingDisabled ? 0.4 : 1 },
                }}
                onPress={() => (isRatingDisabled || !userRating ? null : onRate(userRating))}
                disabled={isRatingDisabled}
            />
            {!!assetRating?.rating && (
                <TertiaryButtonWithoutBg
                    testID={RATING_MODAL_TEST_IDS.REMOVE_RATING_BUTTON}
                    title={t('asset.rate.deleteButton')}
                    disableATVonPressLimiter
                    additionalContainerStyles={styles.removeRatingButton}
                    onPress={onRemove}
                />
            )}
        </View>
    );
};

export default RatingSystemComponent;
