import {
    GetTestID,
    TestIDsOptions,
    GetInnerTestIDs,
    GetTestIDs,
    TestIDs,
    TestIDsStructure,
} from './types';

const getIsAppendedSegment = (value: unknown): value is string | number =>
    ['number', 'string'].includes(typeof value);

const joinSegments = (joint: string, ...input: unknown[]): string =>
    input.filter(getIsAppendedSegment).join(joint);

const appendSpecifier = (...input: unknown[]): string | null => joinSegments('_', ...input) || null;

const appendScope = (...input: unknown[]): string | null => joinSegments('-', ...input) || null;

const invalidSegmentRegex = /(-|_)/;

const getIsInvalidSegment = (value: string) => invalidSegmentRegex.test(value);

const invalidSegmentHelper = 'Must not contain - or _.';

const validateSpecifier = (specifier?: string | number | null): void => {
    if (!getIsAppendedSegment(specifier)) {
        return;
    }

    if (getIsInvalidSegment(String(specifier))) {
        throw new Error(`Invalid specifier: ${JSON.stringify(specifier)}. ${invalidSegmentHelper}`);
    }
};

const getTestIDGetter =
    (key: string, scope?: string | null): GetTestID =>
    (specifier?: string | number | null): string => {
        validateSpecifier(specifier);
        return appendScope(scope, appendSpecifier(key, specifier)) as string;
    };

const getInnerTestIDsGetter = <T extends TestIDsStructure>(
    structure: T,
    scope: string | null,
    isDisabled: boolean
): GetInnerTestIDs<T> => {
    return (specifier?: string | number | null): TestIDs<T> => {
        validateSpecifier(specifier);
        const specifiedScope = appendSpecifier(scope, specifier);
        const keys = Object.keys(structure);
        return keys.reduce((result, key) => {
            const innerStructure = structure[key];
            if (innerStructure) {
                return Object.assign(result, {
                    [key]: getInnerTestIDsGetter(
                        innerStructure,
                        appendScope(specifiedScope, key),
                        isDisabled
                    ),
                });
            }
            return isDisabled
                ? Object.assign(result, { [key]: () => undefined })
                : Object.assign(result, {
                      [key]: getTestIDGetter(key, specifiedScope),
                  });
        }, {} as TestIDs<T>);
    };
};

/*
 * Dash and underscore have a special meaning in test id. This is the reason
 * why they are not allowed to use in a property name in order to avoid
 * unexpected issues.
 */
const validateStructure = (structure: TestIDsStructure, scope?: string) => {
    Object.keys(structure).forEach((key) => {
        const fullKey = joinSegments('.', scope, key);
        if (getIsInvalidSegment(key)) {
            throw new Error(`Invalid test id structure at: ${fullKey}. ${invalidSegmentHelper}`);
        }

        const value = structure[key];
        if (value !== null) {
            validateStructure(value, fullKey);
        }
    });
};

export const testIDsFactory = <T extends TestIDsStructure>(structure: T): GetTestIDs<T> => {
    validateStructure(structure);
    const getTestIDs = (options: TestIDsOptions = {}): TestIDs<T> => {
        const { scope = null, isDisabled = false } = options;
        return getInnerTestIDsGetter(structure, scope, isDisabled)();
    };
    return getTestIDs;
};

export type TestIDGetterOptions = {
    index?: number;
    scopeIndex?: number;
};

export const createTestIDGetter =
    <S extends string>(scope: S) =>
    <ID extends string>(id: ID, options?: TestIDGetterOptions) => {
        if (!options) {
            // * Not indexed
            return `${scope}_${id}`;
        }

        const { index, scopeIndex } = options;

        // * This will create combinations which might look like this
        // * slider_0_slide_button_0 - this targets play button on first slider
        // * slider_slide_button_0 if rowIndex is missing
        const PREFIX = scopeIndex === undefined ? scope : `${scope}_${scopeIndex}`;
        const SUFFIX = index === undefined ? id : `${id}_${index}`;

        return `${PREFIX}_${SUFFIX}`;
    };
