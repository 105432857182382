import { NavScreenConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { Theme } from '@24i/nxg-sdk-photon';
import { StylesGetter } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';

import { TopBarMenuStyles } from '../styles';

export interface TopBarMenuProps extends TopBarMenuPropsNative, TopBarMenuPropsWeb {}

export interface TopBarMenuPropsNative {
    options?: StackNavigationOptions;
    navigation: NavigationProp<ParamListBase>;
    styles?: StylesGetter<Theme, TopBarMenuStyles>;
}

export interface TopBarMenuPropsWeb {
    styles?: StylesGetter<Theme, TopBarMenuStyles>;
    menuLeft: NavScreenConfigWeb[];
    menuRight: NavScreenConfigWeb[];
    nativeID: string;
}

export enum TOP_BAR_MENU_TEST_IDS {
    LOGO = '24i_logo',
}
