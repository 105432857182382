import { Theme } from '@24i/nxg-sdk-photon/src';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { BannerCarouselStyles } from './types';

const getBannerCarouselStyles = (theme: Theme): BannerCarouselStyles => ({
    indicator: {
        width: '100%',
        height: '100%',
    },
    pagination: {
        minHeight: 32,
        bottom: 280,
        right: 80,
        minWidth: 132,
        zIndex: 101,
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        borderRadius: 16,
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            right: 8,
            bottom: 50,
        },
    },
    paginationInteractable: {
        width: 24,
        height: 24,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dot: {
        width: 3,
        height: 3,
        borderRadius: 1,
        backgroundColor: theme.color.contrast3,
    },
    activeDot: {
        width: 8,
        height: 8,
        borderRadius: 3,
        backgroundColor: theme.color.contrast1,
    },
    hoveredDot: {
        width: 8,
        height: 8,
        borderRadius: 3,
        backgroundColor: theme.color.contrast3,
    },
    navigationLeftArrow: {
        left: 0,
    },
    navigationRightArrow: {
        right: 0,
    },
    navigationArrowWrapper: {
        top: '30%',
        zIndex: 101,
        position: 'absolute',
        width: 48,
        height: 200,
        justifyContent: 'center',
        alignItems: 'center',
    },
    customContainer: {
        [`@media (min-width: ${Breakpoint.SM}px)`]: {
            marginBottom: -240,
        },
    },
    singleItemContainer: {
        height: 750,
        [`@media (min-width: ${Breakpoint.SM}px)`]: {
            marginBottom: -240,
        },
    },
});

export default getBannerCarouselStyles;
