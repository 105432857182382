import { CreateProfileRequest } from '@24i/nxg-sdk-photon';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';

export const useCreateProfile = () => {
    const userDataClient = useUserData();
    const { profiles, setProfiles } = useStore();

    const createProfile = async (profile: CreateProfileRequest) => {
        const createdProfile = await userDataClient.createProfile(
            profile.name,
            profile.imageId,
            profile.age,
            profile.isPinProtected
        );

        // Updating the state to have the latest data without refetching
        setProfiles([...(profiles || []), createdProfile]);
    };

    return { createProfile };
};
