/*
@PRODUCT_FEATURE: H 1.9.1.0
*/
import React, { ReactElement } from 'react';

import { InteractableRefType } from '@24i/nxg-sdk-quarks/src/components/Interactable/types';
import View from './View/index.web';
import { useViewModel } from './viewModel';
import { PackshotPropsWeb } from './types';

export { default as View } from './View/index.web';

export { default as getPackshotStyles } from './styles/index.web';

// Needs to be a forwardRef https://github.com/vercel/next.js/issues/7915
const Main = React.forwardRef<InteractableRefType, PackshotPropsWeb>((props, ref): ReactElement => {
    const viewModelProps = useViewModel<PackshotPropsWeb>(props);

    return <View ref={ref} {...viewModelProps} />;
});

Main.displayName = `Packshot_Gluon_ForwardRef`;

export default Main;
