import { KeyboardAvoidingViewProps } from 'react-native';

export interface CreatePinViewProps {
    pin?: string;
    pinLength: number;
    isLoading?: boolean;
    isPinValidated?: boolean;
    wrapperProps?: {
        behavior?: KeyboardAvoidingViewProps['behavior'];
        keyboardVerticalOffset: 25;
    };
    onPinChange: (input: string) => void;
    onConfirmPress: (inputPin: string) => Promise<void>;
}

export enum CREATE_PIN_TEST_IDS {
    PIN_INPUT = 'pin_input',
    CONFIRM_BUTTON = 'create_pin_confirm_button',
}
