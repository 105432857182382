import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View } from '@24i/nxg-sdk-quarks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { SOTT_DEFAULT_WEB_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import LoadingWrapper from '../../../../LoadingWrapper';
import getConfirmPurchaseStyles from '../../styles';
import { ConfirmPurchaseProps } from '../../types';

const ConfirmPurchase: React.FC<ConfirmPurchaseProps> = ({
    isLoading,
    isDisabled,
    topText,
    middleText,
    bottomText,
    children,
    styles: getStyles = getConfirmPurchaseStyles,
    isModalContent,
    selectedItem,
    assetType,
}) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme, isModalContent);
    const router = useRouter();
    const [isRedirectingToPurchasePage, setIsRedirectingToPurchasePage] = useState(false);

    const { externalAccessValue: selectedItemId } = selectedItem;

    const referralAssetQuery =
        router.query?.type != null && router.query?.id != null
            ? {
                  referralAssetId: router.query.id,
                  referralAssetType: assetType,
              }
            : {};

    const queryForCleengPurchasePage = {
        offerId: selectedItemId,
        ...referralAssetQuery,
    };

    const goToPurchasePage = () => {
        setIsRedirectingToPurchasePage(true);
        const link = SOTT_DEFAULT_WEB_SCREENS.PurchaseSubscription.getLink(
            queryForCleengPurchasePage
        );

        router.push({
            pathname: link.href,
            query: { ...queryForCleengPurchasePage },
        });
    };

    return (
        <View style={styles.wrapper}>
            <View style={styles.informationTextWrapper}>
                <Text style={styles.informationText}>{topText}</Text>
            </View>
            <View>{children}</View>
            <View style={styles.informationTextWrapper}>
                <Text style={styles.informationText}>
                    {middleText}
                    {bottomText}
                </Text>
            </View>
            <LoadingWrapper
                isLoading={!!isLoading || isRedirectingToPurchasePage}
                loaderProps={{ size: 'large' }}
                wrapperStyle={styles.buttonWrapper}
            >
                <ActionButton
                    title={t('vod.add.confirmAndPayButton')}
                    onPress={goToPurchasePage}
                    additionalContainerStyles={styles.confirmButton}
                    disabled={isDisabled}
                />
            </LoadingWrapper>
        </View>
    );
};

export default overridable(ConfirmPurchase, 'ConfirmPurchase');
