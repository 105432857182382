import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import { TopMenu } from '@24i/nxg-sdk-gluons';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import getTopBarMenuSectionStyles from '../styles';

const TopBarMenuSection = ({ styles: getStyles, key, index, section }) => {
    const { theme } = useTheme();
    const sectionStyles = getStyles(theme);

    return (
        <TopMenu
            isSticky
            renderMenu={() => (
                <View
                    nativeID={`section-${key}-${index}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`section-${key}-${index}`}
                    style={[sectionStyles?.section]}
                >
                    {section}
                </View>
            )}
        />
    );
};

TopBarMenuSection.propTypes = {
    styles: PropTypes.func,
};
TopBarMenuSection.defaultProps = {
    styles: getTopBarMenuSectionStyles,
};

const WrappedTopBarMenuSection = ({ index, section }) => {
    // const WrappingView = index === 0 ? ScrollView : React.Fragment;
    const WrappingView = React.Fragment;
    const wrapperProps =
        index === 0
            ? {
                  horizontal: true,
                  showsHorizontalScrollIndicator: false,
              }
            : {};
    TopBarMenuSection.displayName = `MenuSection-${index}`;

    return (
        <WrappingView key={index} {...wrapperProps}>
            <TopBarMenuSection index={index} section={section} />
        </WrappingView>
    );
};

WrappedTopBarMenuSection.propTypes = {
    index: PropTypes.number.isRequired,
    menu: PropTypes.shape({ sections: PropTypes.array }).isRequired,
    section: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export { getTopBarMenuSectionStyles };
export default overridable(WrappedTopBarMenuSection, 'TopBarMenuSection');
