import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteAccountScreenProps, DeleteAccountViewProps } from '../types';

const useViewModel = (props: DeleteAccountScreenProps): DeleteAccountViewProps => {
    const { t } = useTranslation();
    const [isModalVisible, setModalVisible] = useState(false);

    return {
        isModalVisible,
        closeModal: () => setModalVisible(false),
        onPressDeleteAccount: () => setModalVisible(true),
        webHeaderTitle: t('account.delete.title'),
        ...props,
    };
};

export default useViewModel;
