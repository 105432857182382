import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { PLAYBACK_TYPE } from '@24i/nxg-sdk-photon/src/analytics/eventTypes';
import { AppState } from 'react-native';
import { isPlatformNative } from '@24i/nxg-core-utils';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { useRef, useCallback } from 'react';
import { PlaybackStarted } from '@24i/nxg-core-utils/src/globalSingletons';
import { BuildPlaybackEventFunction } from './buildPlaybackEvent';
import { PlaybackState } from './playbackState';

export const useTriggerOnPlayerEvent = (buildPlaybackEvent: BuildPlaybackEventFunction) => {
    const { triggerMonitoringEvent } = useMonitoring();
    const enabledTriggering = useRef(true);

    const triggerOnPlayerEvent = useCallback(
        (playbackState: PlaybackState | undefined, eventType: PLAYBACK_TYPE): void => {
            const event = buildPlaybackEvent(eventType, playbackState);
            if (!event) return;

            triggerMonitoringEvent(event);

            if (!enabledTriggering.current && AppState.currentState === 'active') {
                enabledTriggering.current = true;
            }

            if (
                enabledTriggering.current &&
                AppState.currentState.match(/inactive|background/) &&
                eventType === 'PLAYBACK_PAUSE' &&
                PlaybackStarted.get()
            ) {
                onAnalytics(ANALYTICS_TRIGGERS.APP_CLOSE, event);
                enabledTriggering.current = false;
                return;
            }

            if (!enabledTriggering.current) {
                return;
            }

            if (eventType === 'PLAYBACK_START') {
                if (!PlaybackStarted.get()) {
                    PlaybackStarted.set(true);
                } else {
                    return;
                }
            }

            if (eventType === 'PLAYBACK_PAUSE') {
                const isEnding =
                    event.percentOfPlayback >= 98 &&
                    event.duration - event.currentPlaybackTimeMs < 2000;
                if (PlaybackStarted.get() && !isEnding) {
                    onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_PAUSE, event);
                }
                return;
            }

            if (eventType === 'PLAYBACK_UNPAUSE') {
                if (PlaybackStarted.get()) {
                    onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_UNPAUSE, event);
                }
                return;
            }

            if (eventType === 'PLAYBACK_STOP') {
                if (PlaybackStarted.get()) {
                    onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_STOP, event);
                    onAnalytics(ANALYTICS_TRIGGERS.PLAYER_CLOSE, event);
                    PlaybackStarted.set(false);
                    return;
                }
                return;
            }

            if (
                eventType === 'PLAYBACK_START' &&
                (isPlatformNative || event.asset.type !== ASSET_TYPE.EPISODE)
            ) {
                onAnalytics(ANALYTICS_TRIGGERS.PLAYER_OPEN, event);
                onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_START, event);
                return;
            }

            if (eventType === 'PLAYBACK_START' && event.asset.type === ASSET_TYPE.EPISODE) {
                return;
            }

            if (eventType === 'PLAYBACK_ENDED') {
                if (PlaybackStarted.get()) {
                    onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_STOP, event);
                    onAnalytics(ANALYTICS_TRIGGERS.PLAYER_CLOSE, event);
                    PlaybackStarted.set(false);
                    return;
                }
                return;
            }

            onAnalytics(ANALYTICS_TRIGGERS[eventType], event);
        },
        []
    );

    return triggerOnPlayerEvent;
};
