import { Theme } from '@24i/nxg-sdk-photon';
import { RatingSystemStyles } from './types';

const getRatingSystemStyles = (theme: Theme): RatingSystemStyles => ({
    container: {
        width: '100%',
        alignItems: 'center',
    },
    rateButton: {
        width: 335,
        height: 52,
        marginHorizontal: 20,
        marginTop: 64,
        marginBottom: 24,
        backgroundColor: theme.color.buttonPrimary,
    },
    removeRatingButton: {
        backgroundColor: 'transparent',
    },
});

export default getRatingSystemStyles;
