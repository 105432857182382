import React, { FC } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useSafeAreaFrame } from 'react-native-safe-area-context';
import { getStyles as getPlayerContainerStyles } from '../MiniPlayer/MiniPlayerContainer/styles';
import { usePodcastPlayer } from '../../../../context/PodcastPlayerProvider';
import LoadingWrapper from '../../../LoadingWrapper';
import { ExpandedPlayerContainer } from '../ExpandedPlayer/components/ExpandedPlayerContainer';
import { styles } from './styles';

export const MiniPlayerLoadingSkeleton: FC = () => {
    const { theme } = useTheme();
    const playerContainerStyles = getPlayerContainerStyles(theme);

    return (
        <LoadingWrapper
            isLoading
            loaderProps={{
                holderStyles: {
                    ...playerContainerStyles.container,
                    ...playerContainerStyles.wrapper,
                    justifyContent: 'center',
                },
            }}
        >
            <View />
        </LoadingWrapper>
    );
};

export const ExpandedPlayerLoadingSkeleton: FC = () => {
    const { height } = useSafeAreaFrame();
    const { closeMobileExpandedPlayer } = usePodcastPlayer();

    return (
        <ExpandedPlayerContainer
            show
            onClose={closeMobileExpandedPlayer}
            height={height}
            variant="centered"
        >
            <View style={styles.expandedSkeletonContainer}>
                <LoadingWrapper
                    isLoading
                    loaderProps={{
                        size: 'large',
                    }}
                >
                    <View />
                </LoadingWrapper>
            </View>
        </ExpandedPlayerContainer>
    );
};

export const MobilePodcastPlayerLoadingSkeleton: FC = () => {
    const { isMobilePlayerExpanded } = usePodcastPlayer();

    return isMobilePlayerExpanded ? (
        <ExpandedPlayerLoadingSkeleton />
    ) : (
        <MiniPlayerLoadingSkeleton />
    );
};
