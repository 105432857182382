import React, { FC, ReactNode } from 'react';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { getStyles } from './styles';

interface Props {
    leftLabel: ReactNode;
    rightLabel: ReactNode;
}

export const MiniPlayerProgressSliderContainer: FC<Props> = ({
    children,
    leftLabel,
    rightLabel,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.sliderContainer}>
            <Text
                style={[
                    styles.sliderLabel,
                    {
                        textAlign: 'right',
                    },
                ]}
            >
                {leftLabel}
            </Text>

            {children}

            <Text style={styles.sliderLabel}>{rightLabel}</Text>
        </View>
    );
};
