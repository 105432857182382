import { PaymentInfo } from '@cleeng/mediastore-sdk';
import React, { FC, useEffect } from 'react';
import { CleengComponentWrapper } from '../CleengComponentWrapper';
import { useAdyenConfig } from '../../../hooks/cleeng/useAdyenConfig';
import {
    getPaymentMethodErrorUrl,
    getPaymentMethodSuccessUrl,
    setUpPaypalUrls,
} from '../../../utils/cleeng';

export const CleengPaymentMethod: FC = () => {
    const { adyenConfiguration } = useAdyenConfig();

    useEffect(() => {
        const paypalErrorUrl = getPaymentMethodErrorUrl();
        const paypalSuccessUrl = getPaymentMethodSuccessUrl();

        setUpPaypalUrls({
            purpose: 'payment-method',
            urls: {
                successUrl: paypalSuccessUrl,
                errorUrl: paypalErrorUrl,
            },
        });
    }, [setUpPaypalUrls, getPaymentMethodErrorUrl, getPaymentMethodSuccessUrl]);

    return (
        <CleengComponentWrapper>
            <PaymentInfo displayGracePeriodError adyenConfiguration={adyenConfiguration} />
        </CleengComponentWrapper>
    );
};
