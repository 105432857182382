import { useQuery, UseQueryOptions } from 'react-query';
import { Asset, ASSET_TYPE, Broadcast, QUERY_KEYS } from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useStore } from '../../../context/ApplicationStore';
import { getContinueWatchingOffset } from '../continueWatching/useContinueWatchingQuery';

const useAssetDetailsQuery = (
    {
        assetId,
        assetType,
        continueWatchingPlaylist,
        channelId,
        sectionLabel,
    }: {
        assetId?: string;
        assetType?: ASSET_TYPE;
        continueWatchingPlaylist?: Asset[];
        channelId?: string | null;
        sectionLabel?: string | null;
    },
    options?: UseQueryOptions<Asset | Broadcast>
) => {
    const isOptionEnabled = options?.enabled !== undefined ? options.enabled : true;

    const { userData } = useStore();
    const { fetchAsset } = useContentData();

    const fetchDetails = async (): Promise<Asset> => {
        let cw;
        let asset: Asset;

        if (!assetId || !assetType)
            throw Error('Asset id or type are not provided and it is required to fetch details.');

        if (userData && continueWatchingPlaylist) {
            cw = getContinueWatchingOffset(continueWatchingPlaylist, assetId);
        }

        try {
            const fetchedAsset: Asset = await fetchAsset({
                id: assetId,
                type: assetType,
                channelId,
            });

            asset = {
                ...fetchedAsset,
                ...cw,
                sectionLabel,
            };

            if (!asset) {
                throw Error(`No asset details found with id ${assetId} and type ${assetType}.`);
            }

            return asset;
        } catch (err) {
            throw Error(JSON.stringify(err));
        }
    };

    return useQuery<Asset | Broadcast>([QUERY_KEYS.assets, assetId], fetchDetails, {
        ...options,
        enabled: !!assetId && !!assetType && isOptionEnabled,
    });
};

export default useAssetDetailsQuery;
