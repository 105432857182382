import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import useNavigation from '@24i/nxg-core-router/src/hooks/useNavigation';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { LandingScreenConfig } from '@24i/nxg-sdk-photon';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { isLocalConfig } from '@24i/adobe-primetime/src/adobePrimetimeLocalConfig';
import { adobePrimetimeJSDevDebugLogger } from '@24i/adobe-primetime/src/shared/utils';
import { useAppConfigQuery } from '../../../hooks/query/useAppConfigQuery';
import View from '../View';
import { useRegister } from '../../../hooks/useRegister';
import {
    SOTT_DEFAULT_ROOT_SCREENS,
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
} from '../../../navigation/constants';

type ViewProps = React.ComponentProps<typeof View>;

export interface ViewModelProps {
    forwardGuest?: () => void;
    redirectToSignIn?: () => void;
    redirectToSignUp?: () => void;
}

export interface ViewModelReturnType
    extends Pick<ViewProps, 'onSignInPress' | 'onSignUpPress' | 'onLookAroundPress'> {
    isLoading: boolean;
    landingScreenConfig: LandingScreenConfig | null;
    isSignInEnabled: boolean;
}

export const useLandingScreenViewModel = ({
    forwardGuest: customForwardGuest,
    redirectToSignIn: customRedirectToSignIn,
    redirectToSignUp: customRedirectToSignUp,
}: ViewModelProps): ViewModelReturnType => {
    const { appSettings, isLoading: appSettingsLoading } = useAppConfigQuery();

    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const router = useRouter();
    const { theme } = useTheme();
    const guestModeFeature = useFeature('guestMode');
    const { accountRegistration } = useRegister();

    const defaultSOTTRedirectToSignIn = () => {
        if (!isPlatformWeb) {
            navigation.navigate(SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN, {
                screen: SOTT_DEFAULT_SCREENS.SIGN_IN,
            });
            return;
        }

        const signInLink = SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink();
        router.push(signInLink.href, signInLink.as);
    };

    const defaulSOTTRedirectToSignUp = () => {
        if (!isPlatformWeb) {
            navigation.navigate(SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN, {
                screen: SOTT_DEFAULT_SCREENS.SIGN_UP,
            });
            return;
        }

        const signUpLink = SOTT_DEFAULT_WEB_SCREENS.SignUp.getLink();
        router.push(signUpLink.href, signUpLink.as);
    };

    const defaultSOTTForwardGuest = () => {
        if (!isPlatformWeb) {
            navigation.reset({
                index: 0,
                routes: [
                    {
                        name: SOTT_DEFAULT_ROOT_SCREENS.MENU_STACK_SCREEN,
                        params: {
                            screen: SOTT_DEFAULT_SCREENS.HOME,
                        },
                    },
                ],
            });
            return;
        }

        const homeLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();
        router.push(homeLink.href, homeLink.as);
    };

    const redirectToSignIn = customRedirectToSignIn ?? defaultSOTTRedirectToSignIn;
    const redirectToSignUp = customRedirectToSignUp ?? defaulSOTTRedirectToSignUp;
    const forwardGuest = customForwardGuest ?? defaultSOTTForwardGuest;

    const { adobePrimetime } = appSettings?.features || {};
    const adobePrimetimeEnabled = adobePrimetime?.enabled;

    const handleSignInPress = () => {
        if (adobePrimetimeEnabled) {
            if (isLocalConfig) {
                if (!isPlatformWeb) {
                    const { routes } = navigation.getState();
                    navigation.navigate(SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN, {
                        screen: SOTT_DEFAULT_SCREENS.MVPD_SIGNIN,
                        params: {
                            didInitialise: true,
                            shouldSignOut: false,
                            routeName: routes[routes.length - 1].name,
                        },
                    });
                    return;
                }
                const mvpdSignInLink = SOTT_DEFAULT_WEB_SCREENS.MvpdSignIn.getLink();
                router.push(
                    {
                        pathname: mvpdSignInLink.href,
                        query: { didInitialise: true, shouldSignOut: false },
                    },
                    mvpdSignInLink.as
                );
            } else if (__DEV__) adobePrimetimeJSDevDebugLogger(0);
        } else {
            redirectToSignIn();
        }
    };

    const handleSignUpPress = () => {
        redirectToSignUp();
    };

    const handleLookAroundPress = () => {
        forwardGuest();
    };

    // Override local settings with theme value from the API.
    const getLandingScreenConfigFromTheme = (): LandingScreenConfig | null => {
        const {
            elements: { landingImageLandscape, landingImagePortrait },
        } = theme;

        if (!landingImageLandscape?.value && !landingImagePortrait?.value) return null;

        return {
            ...(appSettings?.features?.landingScreen || {}),
            backgroundImage: {
                landscape: landingImageLandscape?.value?.toString() || '',
                portrait: landingImagePortrait?.value?.toString() || '',
            },
        };
    };

    const isLoading = !appSettings || appSettingsLoading;

    const landingScreenConfig: LandingScreenConfig | null =
        getLandingScreenConfigFromTheme() || appSettings?.features?.landingScreen || null;

    const { signIn } = appSettings?.features || {};

    const isSignInEnabled = signIn?.enabled || signIn === undefined;
    const isGuestModeEnabled = guestModeFeature?.enabled;

    const isExploreAppAllowed = () => {
        if (adobePrimetimeEnabled && !isSignInEnabled && !isGuestModeEnabled) return false;
        if (isGuestModeEnabled) return true;
        if (!isSignInEnabled && !isGuestModeEnabled) return true;
        return false;
    };

    return {
        isLoading,
        landingScreenConfig,
        onSignInPress: handleSignInPress,
        onSignUpPress: accountRegistration ? handleSignUpPress : undefined,
        onLookAroundPress: isExploreAppAllowed() ? handleLookAroundPress : undefined,
        isSignInEnabled,
    };
};
