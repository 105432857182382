import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QUERY_KEYS, Subscription } from '@24i/nxg-sdk-photon/src';
import { log } from '@24i/nxg-core-utils/src/logger';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import { useFirebase } from '../../../context/Firebase';

const useAvailableSubscriptionsQuery = (
    options?: UseQueryOptions<{ subscriptions: Subscription[] }>
): UseQueryResult<{ subscriptions: Subscription[] }> => {
    const { fetchAvailableSubscriptions } = usePurchaseData();
    const { recordError } = useFirebase();

    return useQuery<{ subscriptions: Subscription[] }>(
        QUERY_KEYS.availableSubscriptions,
        () => fetchAvailableSubscriptions(),
        {
            ...options,
            onError: (error) => {
                log(error);
                recordError?.(error);
                options?.onError?.(error);
            },
        }
    );
};

export default useAvailableSubscriptionsQuery;
