import { GetHigherValueProps, GetLowerValueProps, MinMax } from './types';

export const getLowerValue = ({ value, focusStep, min }: GetLowerValueProps): number => {
    const lowerValue = value + focusStep;
    return lowerValue < min ? min : lowerValue;
};

export const getHigherValue = ({ value, focusStep, max }: GetHigherValueProps): number => {
    const higherValue = value - focusStep;
    return higherValue > max ? max : higherValue;
};

export const limitX = (x: number, { minX, maxX }: MinMax): number => {
    if (x < minX) return minX;
    if (x > maxX) return maxX;
    return x;
};

export const stepifyValue = (value: number, step: number, min: number, max: number): number => {
    if (step === 0) return value;
    const steps = Math.round((value - min) / step);
    const stepified = steps * step + min;
    if (stepified <= 0) return 0;
    return stepified <= max ? stepified : max;
};
