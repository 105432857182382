import { IPlayerEngine } from '@24i/player-base';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { ContextType, IPlayerEngineFactory, IPlayerEngineProperty } from './types';

export const defaultEngineFactory: IPlayerEngineFactory = async () => {
    /*
     * Actual implementations of IPlayerEngine need to be loaded asynchronously, otherwise
     * Next.JS fails (Shaka cannot be imported into Node.JS process).
     */
    if (Platform.OS === 'web') {
        const { ShakaPlayerEngine } = await import('@24i/player-shaka-player');
        return new ShakaPlayerEngine();
    }
    const { ReactNativeMainPlayer } = await import('@24i/player-react-native-main');
    return new ReactNativeMainPlayer('main', {});
};

export const usePlayerEngineResolution = (defaultEngine: IPlayerEngineProperty): ContextType => {
    // value of savedEngine could be a function, we need to wrap it to prevent unwanted execution
    const [[savedEngine], setSavedEngine] = useState<IPlayerEngineProperty[]>([defaultEngine]);
    const [resolvedEngine, setResolvedEngine] = useState<IPlayerEngine | undefined>(undefined);

    useEffect(() => {
        let cancelled = false;

        const resolveAsync = async () => {
            if (typeof savedEngine === 'function') {
                const engineFactory: IPlayerEngineFactory = savedEngine;
                const engine = await engineFactory();
                if (!cancelled) {
                    setResolvedEngine(engine);
                }
            } else if (typeof savedEngine === 'object') {
                const engine: IPlayerEngine = savedEngine;
                setResolvedEngine(engine);
            } else {
                setResolvedEngine(undefined);
            }
        };

        resolveAsync();
        return () => {
            cancelled = true;
        };
    }, [savedEngine]);

    const context: ContextType = {
        engine: resolvedEngine,
        setEngine: (newEngine) => {
            setSavedEngine([newEngine]);
        },
    };
    return context;
};
