import { createGuard, decodeModelWith } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const EventGuard = createGuard('Event', undefined, {
    assetId: t.string,
    assetType: t.string,
    action: t.string,
    offset: t.number,
});

export type Event = t.TypeOf<typeof EventGuard>;

export const isEvent = (data: unknown): data is Event => {
    try {
        return !!decodeModelWith(EventGuard, data, 'Event', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createEvent = (data: unknown): Event | never => {
    return decodeModelWith(EventGuard, data, 'Event');
};
