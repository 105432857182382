import React from 'react';
import { FlatList, View } from '@24i/nxg-sdk-quarks';
import { pairUpData } from './utils';
import type { PureDoubleRowProps } from './types';

const PureDoubleRow = (props: PureDoubleRowProps) => {
    const { data, renderItem } = props;

    return (
        <FlatList
            {...props}
            data={pairUpData(data)}
            renderItem={({ item: [itemOne, itemTwo], index, separators }) => (
                <View style={{ flexDirection: 'column' }}>
                    {renderItem({ item: itemOne, index: index * 2, separators })}
                    {itemTwo && renderItem({ item: itemTwo, index: index * 2 + 1, separators })}
                </View>
            )}
            horizontal
        />
    );
};

export default PureDoubleRow;
