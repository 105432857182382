import { useTheme } from '@24i/nxg-sdk-higgs';
import { TimelineStyles } from '../types';

const getStyles = (theme: any): TimelineStyles => {
    const { theme: appTheme } = useTheme();

    return {
        collection: {
            backgroundColor: appTheme.color.background1,
            overflow: 'hidden',
            overflowX: 'hidden',
            overflowY: 'hidden',
            left: theme.sidebar.container.width,
            right: 0,
            top: 0,
        },
        text: {
            textAlign: 'center',
            position: 'absolute',
            width: '100%',
            left: '-50%',
        },
        arrows: {
            opacity: 0.6,
            ':hover': { opacity: 1, fontSize: 34 },
        },
        leftArrowContainer: {},
        rightArrowContainer: {
            marginLeft: 'auto',
        },
        arrowsWrapper: {
            position: 'absolute',
            left: theme.sidebar.container.width - 15,
            flexDirection: 'row',
        },
        columnContainer: {
            justifyContent: 'flex-start',
            display: 'flex',
            backgroundColor: theme.timeline.container.backgroundColor,
        },
        currentTimeLabelContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            zIndex: 10,
            ...theme.currentTime.label,
        },
        currentTimeLabelText: {
            textAlign: 'center',
            ...theme.currentTime.labelText,
        },
    };
};

export default getStyles;
