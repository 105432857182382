import React from 'react';
import { Text, Image, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import AssetLabel from '@24i/nxg-sdk-smartott/src/components/AssetLabel';

import getClassificationsStyles from './styles';
import { ClassificationsProps } from './types';
import { SEPARATORS } from '../TitleView/components/TextWithSeparator';

const renderClassificationImage = (indicator, index, styles) => {
    const image = indicator.images?.find(
        (classificationImage) => classificationImage.size.width === 32
    );
    return image ? (
        <View style={styles.imageView} key={index}>
            <Image source={image.url} style={styles.image} />
        </View>
    ) : (
        <View style={styles.labelView} key={index}>
            <AssetLabel text={indicator.label} />
        </View>
    );
};

const Classifications = (props: ClassificationsProps) => {
    const {
        classifications,
        separator,
        styles: getStyles = getClassificationsStyles,
        children,
    } = props;

    const { theme } = useTheme();

    const styles = getStyles(theme);
    const usedSeparator = separator || SEPARATORS.DOT;
    const showIndicators = (classifications?.indicators?.length || 0) > 0;
    const showRatingLabel = !showIndicators && !!classifications?.rating;

    return (
        <View style={styles.row}>
            {children}
            {children && (showIndicators || showRatingLabel) ? (
                <Text style={styles.separator}> {usedSeparator} </Text>
            ) : null}
            {showIndicators &&
                classifications.indicators?.map((indicator, i) =>
                    renderClassificationImage(indicator, i, styles)
                )}
            {showRatingLabel && <AssetLabel text={classifications.rating?.toString()} />}
        </View>
    );
};

export { getClassificationsStyles };
export * from './types';

export default Classifications;
