// Replace :id parameters in url with 1
const replaceParams = (urlToReplace, params) => {
    let url = urlToReplace;

    Object.keys(params || {}).forEach((param) => {
        const value = params[param];
        url = url.replace(`:${param}`, value);
    });

    return url;
};

export default replaceParams;
