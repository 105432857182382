import React from 'react';
import { MvpdSignInContentProps } from './types';
import { MvpdSignInContent as View } from './View';
import { useViewModel } from './viewModel';

const MvpdSignInContent = (props: MvpdSignInContentProps) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default MvpdSignInContent;
