/*
@PRODUCT_FEATURE: C 1.7.0
*/
import View from './View';

export { getSignOutStyles } from './View';
export { default as withSignoutModal } from './withSignoutModal';
export type { SignoutModalProps } from './withSignoutModal';

export default View;
