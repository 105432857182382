import { ViewStyle, TextStyle } from 'react-native';
import { Profile } from '@24i/nxg-sdk-photon';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { NewProfileHandler } from '../MoreScreen/components/ProfilePicker/types';

export enum ManageProfilesScreenModes {
    VIEW_AND_EDIT,
    EDIT_ONLY,
}

export enum MANAGE_PROFILE_MODES {
    EDIT = 'EDIT',
    CREATE = 'CREATE',
}

export enum MANAGE_PROFILE_TEST_IDS {
    DONE = 'done',
    MANAGE = 'manage_profiles',
}

export interface FlatGridDimension {
    contentContainerDimensions: number;
    spacing: number;
    itemDimensions: number;
}

export interface ManageProfileScreenHeaderProps {
    editing: boolean;
    allowModeSwitch: boolean;
    shouldDisplayBackArrow: boolean;
    onBack: () => void;
    onEditPress: () => void;
}

export interface ManageProfilesModelProps {
    mode?: ManageProfilesScreenModes;
}

export type ManageProfilesScreenProps = ManageProfilesModelProps & Partial<ScreenProps>;

export interface ManageProfilesViewProps extends ManageProfilesScreenProps {
    profiles: (Profile | NewProfileHandler)[];
    editing: boolean;
    isLoading: boolean;
    allowModeSwitch: boolean;
    shouldDisplayBackArrow: boolean;
    contentContainerDimensions: number;
    spacing: number;
    itemDimensions: number;
    onBack: () => void;
    onItemPress: (item: Profile) => void;
    onEditPress: () => void;
    onSelectPress: (newSelectedProfile: Profile) => void;
}

export interface ManageProfilesScreenStyles {
    screenContainer: ViewStyle;
    title: TextStyle;
    scrollViewContentContainer: ViewStyle;
    contentContainer: ViewStyle;
    flatGridContainer: ViewStyle;
    manageBtn: ViewStyle;
    manageBtnIcon: ViewStyle;
    manageBtnText: TextStyle;
    doneButton: ViewStyle;
    logo: ViewStyle;
    loader: ViewStyle;
}

export interface ManageProfileHeaderStyles {
    headerContainer: ViewStyle;
    headerText: TextStyle;
    goBackButtonContainer: ViewStyle;
    editButtonContainer: ViewStyle;
}
