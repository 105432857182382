import { AbstractImageServiceClient } from '@24i/nxg-sdk-gluons/src/clients/AbstractImageServiceClient';

const BASE_URI = 'http://placekitten.com';

export class ImageServiceClient extends AbstractImageServiceClient {
    constructor({ baseUri = BASE_URI }) {
        super({});
        this.baseUri = baseUri;
    }

    getImageUrl = (opts = {}, images = []) => {
        const url = `${this.baseUri}/${opts.width}/${opts.height}`;

        if (images) return images[0].url;

        return url;
    };

    getImageSource = () =>
        /* Stubs means fake data and this property is not required
           so not to just copy and paste functionality from the real implementation
           return null
        */
        null;

    getBetterQualityAsset = (url) => url;
}
