import { useEffect } from 'react';
import { useRefreshOnFocus } from '@24i/nxg-sdk-smartott-shared/src/hooks/useRefreshOnFocus';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useOnMaybeLaterPress } from '../../../hooks/subscriptions';
import useAvailableSubscriptionsQuery from '../../../hooks/query/subscriptions/useAvailableSubscriptions';
import { AddSubscriptionViewProps } from '../View/types';

const useViewModel = (): AddSubscriptionViewProps => {
    const { onMaybeLaterPress, isLoading: isLoadingOnMaybeLater } = useOnMaybeLaterPress();

    const { data, isLoading, refetch } = useAvailableSubscriptionsQuery();

    useRefreshOnFocus(refetch);

    useEffect(() => {
        if (data) {
            onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, data);
        }
    }, [data]);

    return {
        isLoading: isLoading || isLoadingOnMaybeLater,
        availableSubscriptions: data?.subscriptions,
        onMaybeLaterPress,
    };
};

export { useViewModel };
