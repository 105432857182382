import { QUERY_KEYS } from '@24i/nxg-sdk-photon/src';
import { TextPageContent } from '@24i/nxg-sdk-photon/src/models/textPageContent';
import { useQuery, useMutation, UseQueryOptions } from 'react-query';
import { useAppSettingsData } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { log } from '@24i/nxg-core-utils/src/logger';

export const useTermsAndConditions = (options?: UseQueryOptions<TextPageContent | undefined>) => {
    const { client } = useAppSettingsData();
    return useQuery<TextPageContent | undefined>(
        QUERY_KEYS.termsAndConditions,
        () => client.fetchTermsAndConditions(),
        {
            ...options,
            onError: (error) => {
                log(error);
            },
        }
    );
};

export const useAcceptTermsAndConditions = () => {
    const { client } = useAppSettingsData();

    return useMutation(
        (params: { userId: string | number; termsAndConditions: TextPageContent }) =>
            client.acceptTermsAndConditions(params.userId, params.termsAndConditions),
        {
            onError: (error) => {
                log(error);
            },
        }
    );
};
