import { createGuard, decodeModelWith } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';

export const TokenGuard = createGuard('Token', {
    token: t.string,
});

export type Token = t.TypeOf<typeof TokenGuard>;

export const isToken = (data: unknown): data is Token => {
    try {
        return !!decodeModelWith(TokenGuard, data, 'Token', { disableErrorLog: true });
    } catch {
        return false;
    }
};

export const createToken = (data: unknown): Token | never => {
    return decodeModelWith(TokenGuard, data, 'Token');
};
