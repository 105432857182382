import { useTheme } from '@24i/nxg-sdk-higgs';

export const useThemingForCleeng = () => {
    const { theme } = useTheme();

    const fontsDataForWeb = theme.fonts.L || theme.fonts.M || theme.fonts.S;
    const appFont = fontsDataForWeb?.body1?.family;
    const colors = theme.color;
    const { elements } = theme;

    const heading2 = fontsDataForWeb?.h2;
    const heading3 = fontsDataForWeb?.h3;
    const heading4 = fontsDataForWeb?.h4;
    const heading5 = fontsDataForWeb?.h5;
    const body1 = fontsDataForWeb?.body1;
    const body2 = fontsDataForWeb?.body2;
    const textError = fontsDataForWeb?.error;
    const buttonPrimary = fontsDataForWeb?.primaryButton;
    const buttonTertiary = fontsDataForWeb?.tertiaryButton;
    const generalTileRadius = elements['generalTile-cornerRadius']?.value;

    return {
        appFont,
        heading2,
        heading3,
        heading4,
        heading5,
        body1,
        body2,
        buttonPrimary,
        buttonTertiary,
        generalTileRadius,
        colors,
        elements,
        textError,
    };
};
