import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, CloseProgressResult } from '../types';
import { mapVideoProgress } from './mapVideoProgress';

export const mapCloseProgress = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): CloseProgressResult => ({
    ...mapVideoProgress(triggerName, payload),
    video_percent: Math.round(payload.payload.percentOfPlayback),
    video_current_time: Math.round((payload.payload.currentPlaybackTimeMs || 0) / 1000),
});
