/*
@PRODUCT_FEATURE: G 1.3.0
*/
import View from './View';

export { default as View, getTopBarMenuStyles } from './View';
export { default as TopBarMenuDropdown } from './components/TopBarMenuDropdown/index.web';
export { default as TopBarMenuSection } from './components/TopBarMenuSection';

export default View;
