import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../buttons/Button';
import { DROPDOWN_PICKER_ITEM_TEST_ID } from '../constants';

const Item = ({ onPress, label, value, onValueChange, index }) => {
    const onButtonPress = () => {
        if (onValueChange) {
            onValueChange(value, index);
        }
        onPress();
    };

    return <Button title={label} onPress={onButtonPress} testID={DROPDOWN_PICKER_ITEM_TEST_ID} />;
};

Item.defaultProps = {
    onPress: () => {},
    label: '',
    value: '',
    index: 0,
    onValueChange: () => {},
};

Item.propTypes = {
    onPress: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.number,
    onValueChange: PropTypes.func,
};

export default Item;
