import { createGuard, nullable, withEmptyArray } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { AssetImagesGuard } from './image';

export const PersonGuard = createGuard('Person', undefined, {
    id: nullable(t.string),
    label: nullable(t.string),
    bio: nullable(t.unknown),
    trivia: nullable(t.UnknownArray),
    quotes: nullable(t.UnknownArray),
    images: withEmptyArray(AssetImagesGuard),
    createdAt: nullable(t.union([t.string, t.number])),
    updatedAt: nullable(t.string),
    role: nullable(t.string),
});

export type Person = t.TypeOf<typeof PersonGuard>;
