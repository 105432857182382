import { useRef, useState } from 'react';
import { dateDiffInDays, dayText } from '@24i/nxg-core-utils';
import { SetSelectedDate } from '../View/types';

type UseSelectionDateParams = {
    onSelectionPress: (offset: number) => void;
    datePickerRange: [start: number, end: number];
    initialDate?: Date;
};

type UseSelectionDateResult = {
    selectedDate: { name: string; date: Date };
    pickerDates: number[];
    changeSelectedDate: (offset: number) => void;
    resetDateToCurrentDate: () => void;
};

const useSelectedDate = (params: UseSelectionDateParams): UseSelectionDateResult => {
    const { datePickerRange = [-2, 3], initialDate = new Date() } = params;

    const getInitiaSelectedDate = (date = new Date()) => ({
        name: dayText(dateDiffInDays(new Date(), date)),
        date: initialDate ?? new Date(),
    });

    const [selectedDate, setSelectedDate] = useState<SetSelectedDate>(
        getInitiaSelectedDate(initialDate)
    );

    const resetDateToCurrentDate = () => {
        setSelectedDate(getInitiaSelectedDate(new Date()));
    };

    const generateDayOffsetsForThePicker = () => {
        const result: number[] = [];
        for (let i = datePickerRange[0]; i <= datePickerRange[1]; i++) {
            result.push(i);
        }
        return result;
    };

    const dayValue = useRef(generateDayOffsetsForThePicker());

    const changeSelectedDate = (offset: number) => {
        const dayAlteration = dayValue.current[offset];
        const selectedDay = new Date();
        selectedDay.setDate(selectedDay.getDate() + dayAlteration);
        setSelectedDate({
            name: dayText(dayAlteration),
            date: selectedDay,
        });
    };

    return {
        selectedDate,
        pickerDates: dayValue.current,
        changeSelectedDate,
        resetDateToCurrentDate,
    };
};

export { useSelectedDate };
