import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import getAccountAddSubscriptionStyles from '../styles';
import LoadingWrapper from '../../../../../components/LoadingWrapper';
import AccountSubscriptionContent from '../../AccountSubscriptionContent';

const AccountAddSubscription = ({
    styles: getStyles,
    additionalDescription,
    isLoading,
    ...props
}) => {
    const { t } = useTranslation(['sott'], {
        wait: true,
    });
    const purchaseDataClient = usePurchaseData();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const getExternalCheckoutLink = (id) =>
        purchaseDataClient
            .fetchExternalCheckoutLink(id)
            .then((url) => url.url)
            .catch((err) => {
                if (err?.message) alert(err.message);
                else alert('Something went wrong... Try Again');
            });

    return (
        <LoadingWrapper isLoading={isLoading} loaderProps={{ size: 'large' }}>
            <View>
                <Text
                    numberOfLines={2}
                    style={[styles.explanationText, { opacity: 0.6, marginBottom: 32 }]}
                >{`${t('svod.add.description')}. ${additionalDescription}`}</Text>
            </View>
            <AccountSubscriptionContent
                {...props}
                handleOnRequestSubscription={async (id) => {
                    const url = await getExternalCheckoutLink(id);
                    if (url) {
                        window.open(url);
                    }
                }}
            />
        </LoadingWrapper>
    );
};

AccountAddSubscription.propTypes = {
    styles: PropTypes.func,
    onBack: PropTypes.func,
    plans: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
        })
    ).isRequired,
    currentPlan: PropTypes.number,
    setCurrentPlan: PropTypes.func,
    additionalDescription: PropTypes.string,
    isLoading: PropTypes.bool,
};

AccountAddSubscription.defaultProps = {
    onBack: null,
    currentPlan: 0,
    setCurrentPlan: () => {},
    additionalDescription: '',
    isLoading: true,
    styles: getAccountAddSubscriptionStyles,
};

export { getAccountAddSubscriptionStyles };
export default overridable(AccountAddSubscription, 'AccountAddSubscription');
