import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View, Interactable, ActivityIndicator } from '@24i/nxg-sdk-quarks';
import { useOnMaybeLaterPress } from '@24i/nxg-sdk-smartott/src/hooks/subscriptions';
import { isPlatformWeb } from 'renative';
import { SubscriptionFlowStep } from '@24i/nxg-sdk-smartott/src/components/subscriptions/AddSubscriptionModal/viewModel/types';
import { getStyles } from './styles';
import { OnboardingSubscriptionButtonsProps } from './types';

const OnboardingSubscriptionButtons = ({
    step,
    closeSubscriptionModal,
}: OnboardingSubscriptionButtonsProps) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { onMaybeLaterPress, isLoading } = useOnMaybeLaterPress();
    const onSafeMaybeLaterPress = useCallback(() => {
        closeSubscriptionModal?.();
        onMaybeLaterPress();
    }, [closeSubscriptionModal, onMaybeLaterPress]);

    const showButton =
        (!isPlatformWeb && step === SubscriptionFlowStep.Selection && !isLoading) ||
        (isPlatformWeb && !isLoading);
    const showLoader =
        (!isPlatformWeb && step === SubscriptionFlowStep.Selection && isLoading) ||
        (isPlatformWeb && isLoading);

    return (
        <View style={styles.content}>
            {showButton && (
                <Interactable style={styles.maybeButton} onPress={onSafeMaybeLaterPress}>
                    <Text style={styles.maybeText}>{t('onboarding.skipButton')}</Text>
                </Interactable>
            )}
            {showLoader && <ActivityIndicator size="small" color={theme.color.textPrimary} />}
        </View>
    );
};

export default OnboardingSubscriptionButtons;
