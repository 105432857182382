/*
@PRODUCT_FEATURE: C 1.1.0
*/
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import React from 'react';
import View, { DefaultLogoSection, DefaultTextSection, DefaultButtonSection } from './View';
import { useLandingScreenViewModel, ViewModelProps, ViewModelReturnType } from './viewModel';

export { default as LandingScreenView } from './View';

type ViewProps = React.ComponentProps<typeof View>;

interface Props
    extends Omit<ViewProps, keyof ViewModelReturnType>,
        ViewModelProps,
        Partial<ScreenProps> {}

const Main = ({ forwardGuest, redirectToSignIn, redirectToSignUp, ...props }: Props) => {
    const {
        isLoading,
        landingScreenConfig,
        onSignInPress,
        onSignUpPress,
        onLookAroundPress,
        isSignInEnabled,
    } = useLandingScreenViewModel({
        forwardGuest,
        redirectToSignIn,
        redirectToSignUp,
    });

    return (
        <View
            {...props}
            isLoading={isLoading}
            landingScreenConfig={landingScreenConfig}
            onSignInPress={onSignInPress}
            onSignUpPress={onSignUpPress}
            onLookAroundPress={onLookAroundPress}
            isSignInEnabled={isSignInEnabled}
        />
    );
};

export default {
    Main,
    DefaultLogoSection,
    DefaultTextSection,
    DefaultButtonSection,
};
