import { useRef } from 'react';

export const useTriggerOnce = <T>(
    getEventId: (event: T) => number | string,
    triggerFunction: (event: T) => void,
    initialIds: Record<number | string, boolean> = {},
    enabled = true
) => {
    const eventRef = useRef(initialIds);
    const triggerOnce = (event: T) => {
        const id = getEventId(event);
        if (!eventRef.current[id] && enabled) triggerFunction?.(event);
        eventRef.current[id] = true;
    };
    return { triggerOnce };
};
