import React, { createContext, useContext } from 'react';
import { ImageService } from '../../clients/AbstractImageServiceClient';

export type ImageServiceContext = ImageService;

const ImageServiceContext = createContext<ImageService | undefined>(undefined);
export interface ImageServiceProviderProps {
    client: ImageService;
    children: React.ReactElement;
}

export const ImageServiceProvider = ({ client, children }: ImageServiceProviderProps) => (
    <ImageServiceContext.Provider
        value={{
            ...client,
        }}
    >
        {children}
    </ImageServiceContext.Provider>
);

export const useImage = () => {
    const context = useContext(ImageServiceContext);
    if (context === undefined) {
        throw new Error('useImage must be used within a ImageServiceProvider');
    }

    return context;
};

// eslint-disable-next-line react/display-name
export const withImageService = (Component: React.ComponentType) => (props) =>
    (
        <ImageServiceContext.Consumer>
            {(imageServiceContext) => <Component {...props} {...imageServiceContext} />}
        </ImageServiceContext.Consumer>
    );

export default ImageServiceProvider;
