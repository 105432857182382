import React from 'react';
import { Text } from '@24i/nxg-sdk-quarks/src';
import { View } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs/src';
import { Theme } from '@24i/nxg-sdk-photon/src';
import getDefaultStyles from './styles';
import { ToastStyles, ToastTypes } from './styles/types';
import { TOAST_TEST_IDS } from '../../types';

type ToastProps = {
    text: string;
    getStyles?: (theme: Theme, type?: ToastTypes) => ToastStyles;
    isVisible?: boolean;
    type?: ToastTypes;
};

const Toast = ({ text, getStyles = getDefaultStyles, isVisible, type }: ToastProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme, type);
    if (!isVisible) return null;
    return (
        <View style={styles.container} testID={TOAST_TEST_IDS.TOAST_CONTAINER}>
            <Text style={styles.text}>{text}</Text>
            <View style={styles.bottomStripe} />
        </View>
    );
};

export default Toast;
