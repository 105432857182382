import React from 'react';
// DONT CHANGE IMPORT PATHS BELOW OR NEXT WILL STOP BUILDING ROUTES CORRECTLY
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { AppVersion } from '@24i/nxg-core-utils/src/globalSingletons';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { WithWebHeaderProps } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader/types';
import SettingsWrapper from '../../SettingsWrapper';
import getSettingsTechnicalInfoStyles from '../styles';

export interface SettingsTechnicalInfoProps extends WithWebHeaderProps {
    title: string;
    isLandscape: boolean;
    isDetailOpened: boolean;
    onBack: null;
    setOpenedDetail: () => void;
}

const Wrapper = SettingsWrapper;

const SettingsTechnicalInfo = ({
    title = '',
    isLandscape = false,
    isDetailOpened = false,
    onBack = null,
    setOpenedDetail = () => {},
}: SettingsTechnicalInfoProps) => {
    const { theme } = useTheme();
    const styles = getSettingsTechnicalInfoStyles(theme);
    const { t } = useTranslation(['sott']);

    return (
        <Wrapper
            title={title}
            onBack={onBack}
            isDetailOpened={isDetailOpened}
            setOpenedDetail={setOpenedDetail}
        >
            <Text
                style={[styles.description, isLandscape && styles.descriptionLandscape]}
                numberOfLines={1}
            >
                {t('settings.technicalInfo.appVersion')} {AppVersion.get()}
            </Text>
        </Wrapper>
    );
};

export { getSettingsTechnicalInfoStyles };
export default withWebHeader(SettingsTechnicalInfo);
