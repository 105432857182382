import { useTheme } from '@24i/nxg-sdk-higgs';
import { ThemeIcon, Text, View } from '@24i/nxg-sdk-quarks';
import React, { useRef } from 'react';
import { TextInput } from 'react-native';
import { isPlatformIos } from 'renative';
import { SIGNUP_TEST_IDS } from '../../types';
import SignupInput from '../SignupInput';
import { WithError } from '../WithError';
import { StandardButtonsProps } from './types';

type RenderErrorType = { numberOfLines?: number } | undefined;

export const StandardButtons = ({
    t,
    styles,
    isSamePasswords,
    isEmailIncorrect,
    maxPasswordLength,
    displayErrorUnder,
    isPasswordIncorrect,
    isLastNameIncorrect,
    isFirstNameIncorrect,
    activeTextInputColor,
    inactiveTextInputColor,
    customNameErrorMessage,
    customEmailErrorMessage,
    customPasswordErrorMessage,
    onEmailChange,
    onPasswordChange,
    onLastNameChange,
    onFirstNameChange,
    onConfirmPasswordChange,
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
}: StandardButtonsProps): JSX.Element[] => {
    const { theme } = useTheme();

    const secondPasswordInputRef = useRef<TextInput>(null);

    const activeTextInputColorWithFallback = activeTextInputColor || theme.color.lighter1;
    const inactiveTextInputColorWithFallback = inactiveTextInputColor || theme.color.lighter3;

    const renderErrorMessage = (
        errorMessage: string | null | undefined,
        { numberOfLines }: RenderErrorType = { numberOfLines: 2 },
        testId?: string
    ) => (
        <View style={styles.inputErrorContainer}>
            {!displayErrorUnder && (
                <ThemeIcon
                    iconName="onPrimary-error"
                    iconStyles={styles.inputErrorIcon}
                    size={14}
                />
            )}
            <Text numberOfLines={numberOfLines} style={styles.inputErrorText} testID={testId}>
                {errorMessage}
            </Text>
        </View>
    );

    const incorrectPasswordMessage = isPasswordIncorrect
        ? customPasswordErrorMessage || t('error.E15.body')
        : null;
    const arePasswordsSameError = !isSamePasswords ? t('error.E14.body') : null;
    const passwordErrorText = arePasswordsSameError ?? incorrectPasswordMessage;

    // This should instruct the keyboard to stay longer, we are expecting another input to be focused
    const blurOnSubmit = false;

    return [
        <WithError
            key="email"
            errorMessage={renderErrorMessage(
                customEmailErrorMessage || t('error.E10.body'),
                undefined,
                SIGNUP_TEST_IDS.ERROR_MESSAGE_EMAIL
            )}
            isErrorVisible={Boolean(isEmailIncorrect)}
            displayErrorUnder={Boolean(displayErrorUnder)}
            innerWrapperStyles={styles.signupEmailWrapper}
        >
            <SignupInput
                testID={SIGNUP_TEST_IDS.EMAIL_INPUT}
                additionalContainerStyles={[
                    styles.inputContainer,
                    styles.signupEmailContainer,
                    isEmailIncorrect && {
                        borderColor: theme.color.error,
                        borderWidth: 1,
                        marginBottom: 8,
                    },
                ]}
                activeTextInputColor={activeTextInputColorWithFallback}
                inactiveTextInputColor={inactiveTextInputColorWithFallback}
                additionalTextInputStyles={styles.signupInputText}
                placeholder={t('account.accountDetails.emailAddress')}
                placeholderTextColor={`${theme.color.textPrimary}4D`}
                selectionColor={`${theme.color.textPrimary}66`}
                value={email}
                onChangeText={(input) => onEmailChange(input)}
            />
        </WithError>,
        <WithError
            key="names"
            errorMessage={renderErrorMessage(
                customNameErrorMessage || t('error.E13.body'),
                undefined,
                SIGNUP_TEST_IDS.ERROR_MESSAGE_NAMES
            )}
            isErrorVisible={Boolean(isFirstNameIncorrect || isLastNameIncorrect)}
            displayErrorUnder={Boolean(displayErrorUnder)}
        >
            <SignupInput
                testID={SIGNUP_TEST_IDS.FIRST_NAME_INPUT}
                additionalContainerStyles={[
                    styles.inputContainer,
                    styles.signupInputContainer,
                    isFirstNameIncorrect && {
                        borderColor: theme.color.error,
                        borderWidth: 1,
                    },
                ]}
                activeTextInputColor={activeTextInputColorWithFallback}
                inactiveTextInputColor={inactiveTextInputColorWithFallback}
                additionalTextInputStyles={styles.signupInputText}
                placeholder={t('account.accountDetails.firstName')}
                placeholderTextColor={`${theme.color.textPrimary}4D`}
                selectionColor={`${theme.color.textPrimary}66`}
                value={firstName}
                onChangeText={(input) => onFirstNameChange(input)}
                blurOnSubmit={blurOnSubmit}
                capitalize
            />
            <SignupInput
                testID={SIGNUP_TEST_IDS.LAST_NAME_INPUT}
                additionalContainerStyles={[
                    styles.inputContainer,
                    styles.signupInputContainer,
                    isLastNameIncorrect && {
                        borderColor: theme.color.error,
                        borderWidth: 1,
                        marginBottom: 8,
                    },
                ]}
                activeTextInputColor={activeTextInputColorWithFallback}
                inactiveTextInputColor={inactiveTextInputColorWithFallback}
                additionalTextInputStyles={styles.signupInputText}
                placeholder={t('account.accountDetails.lastName')}
                placeholderTextColor={`${theme.color.textPrimary}4D`}
                selectionColor={`${theme.color.textPrimary}66`}
                value={lastName}
                onChangeText={(input) => onLastNameChange(input)}
                blurOnSubmit={blurOnSubmit}
                capitalize
            />
        </WithError>,
        <WithError
            key="passwords"
            errorMessage={renderErrorMessage(
                passwordErrorText,
                {
                    numberOfLines: 3,
                },
                SIGNUP_TEST_IDS.ERROR_MESSAGE_PASSWORD
            )}
            isErrorVisible={isPasswordIncorrect || isSamePasswords === false}
            displayErrorUnder={Boolean(displayErrorUnder)}
            innerWrapperStyles={styles.passwordsWrapper}
        >
            <SignupInput
                testID={SIGNUP_TEST_IDS.PASSWORD_INPUT}
                additionalContainerStyles={[
                    styles.inputContainer,
                    styles.passwordInputContainer,
                    (isPasswordIncorrect || isSamePasswords === false) && {
                        borderColor: theme.color.error,
                        borderWidth: 1,
                    },
                ]}
                activeTextInputColor={activeTextInputColorWithFallback}
                inactiveTextInputColor={inactiveTextInputColorWithFallback}
                additionalTextInputStyles={styles.signupInputText}
                placeholder={t('account.accountDetails.password')}
                placeholderTextColor={`${theme.color.textPrimary}4D`}
                selectionColor={`${theme.color.textPrimary}66`}
                isPasswordField
                addAdditionalAction
                additionalActionContainerStyles={styles.actionContainer}
                additionalActionTextStyles={styles.actionText}
                value={password}
                onChangeText={(input) => onPasswordChange(input)}
                maxPasswordLength={maxPasswordLength}
                blurOnSubmit={blurOnSubmit}
            />

            {/**
             * IOS strong password issues fix
             * Refer to these threads:
             * - facebook/react-native#21911
             * - https://stackoverflow.com/questions/45452170/ios-11-disable-password-autofill-accessory-view-option
             * - https://stackoverflow.com/questions/59444093/ios-uikeyboard-flickering-when-using-secure-textfields-within-a-uitableview
             * - https://stackoverflow.com/questions/59038086/react-native-securetextentry-disable-ios-13-strong-password-behavior
             * - https://github.com/facebook/react-native/issues/21572
             */}
            {isPlatformIos && (
                <TextInput
                    style={{ height: 1, width: 1, backgroundColor: 'transparent' }}
                    onFocus={() => secondPasswordInputRef.current?.focus()}
                    blurOnSubmit={blurOnSubmit}
                />
            )}

            <SignupInput
                testID={SIGNUP_TEST_IDS.CONFIRM_PASSWORD_INPUT}
                additionalContainerStyles={[
                    styles.inputContainer,
                    styles.passwordInputContainer,
                    (isPasswordIncorrect || isSamePasswords === false) && {
                        borderColor: theme.color.error,
                        borderWidth: 1,
                        marginBottom: 8,
                    },
                ]}
                placeholderTextColor={`${theme.color.textPrimary}4D`}
                selectionColor={`${theme.color.textPrimary}66`}
                activeTextInputColor={activeTextInputColorWithFallback}
                inactiveTextInputColor={inactiveTextInputColorWithFallback}
                additionalTextInputStyles={styles.signupInputText}
                placeholder={t('password.add.confirm.title')}
                isPasswordField
                addAdditionalAction
                additionalActionContainerStyles={styles.actionContainer}
                additionalActionTextStyles={styles.actionText}
                value={confirmPassword}
                onChangeText={(input) => onConfirmPasswordChange(input)}
                maxPasswordLength={maxPasswordLength}
            />
        </WithError>,
    ];
};
