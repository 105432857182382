import { createGuard, decodeModelWith } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ASSET_TYPE_GUARD } from '../enums';

export const ReminderGuard = createGuard('Reminder', {
    id: t.string,
    assetId: t.string,
    profileId: t.string,
    channelId: t.string,
    start: t.number,
    end: t.number,
    title: t.string,
    assetType: ASSET_TYPE_GUARD,
});

export type Reminder = t.TypeOf<typeof ReminderGuard>;

export const createReminder = (data: unknown): Reminder | never => {
    return decodeModelWith(ReminderGuard, data, 'Reminder');
};
