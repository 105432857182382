import { WebRouter, useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useTranslation } from 'react-i18next';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { WithPurchaseSubscriptionScreenQuery } from '../types';
import { WithWebHeaderProps } from '../../../navigation/components/withWebHeader/types';
import { goToAssetDetailsPage, goToMySubscriptionsPage } from '../../../utils/navigation';

type Props = WithPurchaseSubscriptionScreenQuery & Partial<ScreenProps>;

export const useViewModel = (props: Props): Props & WithWebHeaderProps => {
    const router = useRouter();
    const { t } = useTranslation();

    const onBackClick = () => {
        if (props.query?.referralAssetId && props.query.referralAssetType) {
            goToAssetDetailsPage({
                router: router as WebRouter,
                referralAssetType: props.query.referralAssetType,
                referralAssetId: props.query.referralAssetId,
            });
        } else {
            goToMySubscriptionsPage(router as WebRouter, 'back');
        }
    };

    return {
        webHeaderTitle: t('account.purchase.title'),
        backButtonTitle: t('common.backGo'),
        onBackPressOverride: onBackClick,
        query: props.query,
    };
};
