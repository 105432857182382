import React, { ComponentType, FC, useState } from 'react';
import SubscriptionRequiredModal from '..';
import { createHocDisplayName } from '../../../utils/createHocDisplayName';

export interface WithSubscriptionRequiredModal {
    openSubscriptionRequiredModal: () => void;
}

export const withSubscriptionRequiredModal = <P extends WithSubscriptionRequiredModal>(
    Component: ComponentType<P>
) => {
    const WrappedComponent: FC<Omit<P, keyof WithSubscriptionRequiredModal>> = (props) => {
        const [isModalVisible, setIsModalVisible] = useState(false);

        const closeSubscriptionRequiredModal = () => {
            setIsModalVisible(false);
        };

        const openSubscriptionRequiredModal = () => {
            setIsModalVisible(true);
        };

        WrappedComponent.displayName = createHocDisplayName(
            Component,
            'withSubscriptionRequiredModal'
        );

        return (
            <>
                <Component
                    {...(props as P)}
                    openSubscriptionRequiredModal={openSubscriptionRequiredModal}
                />
                <SubscriptionRequiredModal
                    isVisible={isModalVisible}
                    closeModal={closeSubscriptionRequiredModal}
                    onModalClose={closeSubscriptionRequiredModal}
                />
            </>
        );
    };

    return WrappedComponent;
};
