import { getDummyClient, PlayerDataClient } from '@24i/nxg-sdk-photon';
import React, { createContext, useContext } from 'react';

const defaultClient = getDummyClient<PlayerDataClient>();

export const PlayerDataContext = createContext<PlayerDataClient>(defaultClient);

export const PlayerDataProvider = ({ client = defaultClient, children }) => (
    <PlayerDataContext.Provider value={client}>{children}</PlayerDataContext.Provider>
);

export const usePlayerData = () => useContext(PlayerDataContext);
